import { z } from 'zod';

export const editBikerSchema = z.object({
	id: z.number().or(z.string()).optional(),
	rgImg: z.string().optional(),
	rgBackImg: z.string().optional(),
	cpfImg: z.string().optional(),
});

export type EditBikerSchemaType = z.infer<typeof editBikerSchema>;
