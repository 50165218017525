import { toast } from 'react-toastify';
import { api } from '../../../services/angular/axios';
import { FormHeader, Select } from '../../FormElements';
interface Tag {
	label: string;
	value: number;
}
interface TagProps {
	entity: string;
	entityToUpdate: string;
	id: string | undefined;
	defaultValue: Tag[];
	tags: any[];
}

export function Tags({
	entity,
	tags,
	entityToUpdate,
	defaultValue,
	id,
}: TagProps) {
	const handleUpdate = async (tagsIds: any[]) => {
		try {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			await api.post(`${entityToUpdate}/${id}/tags`, {
				tagsIds,
			});
			toast.success('Tags atualizadas com sucesso!');
		} catch {
			toast.error(
				'Erro ao atualizar tags! Recarregue a página e tente novamente.'
			);
		}
	};

	return (
		<div className="flex flex-col gap-2">
			<FormHeader
				title={
					entity.slice(-1) === 'a' ? 'Tags da ' + entity : 'Tags do ' + entity
				}
			/>
			<Select
				defaultValue={defaultValue}
				isMulti
				className="w-auto"
				options={tags?.map((tag: any) => {
					return {
						label: tag.name,
						value: tag.id,
					};
				})}
				onChange={(e: any) => {
					const tagsIds = e.map((tag: any) => tag.value);
					void handleUpdate(tagsIds);
				}}
			/>
		</div>
	);
}
