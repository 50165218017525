import L from "leaflet";

export function customMarkerIcon(color: string, name: string) : L.DivIcon {
    const svgTemplate = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="marker">
        <title>Custom Marker</title>
        <path fill="${color}" stroke="#000" d="M15.938 32S6 17.938 6 11.938C6 .125 15.938 0 15.938 0S26 .125 26 11.875C26 18.062 15.938 32 15.938 32zM16"/>
        <text x="16" y="16" text-anchor="middle" fill="#000">${name}</text> <!-- Descrição -->
    </svg>`;
    return new L.DivIcon({
        className: "test",
        html: svgTemplate,
        iconSize: [40, 40],
        iconAnchor: [12, 24],
        popupAnchor: [7, -16],
    });
}

export function iconDefault() : L.DivIcon {
    const svgTemplate = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class="marker">
        <title>Custom Marker</title>
        <!-- Marcador de localização -->
        <path fill="#ea4335" stroke="#000" d="M16 0C10.477 0 6 4.477 6 10C6 16 16 32 16 32C16 32 26 16 26 10C26 4.477 21.523 0 16 0Z"/>
        <!-- Furo no meio -->
        <circle cx="16" cy="10" r="4" fill="#000"/>
    </svg>`;
    return new L.DivIcon({
        className: "test",
        html: svgTemplate,
        iconSize: [40, 40],
        iconAnchor: [12, 24],
        popupAnchor: [7, -16],
    });
}


export function driverIcon(angle: number ) : L.DivIcon {
    const keyframesPulse = `
    @keyframes pulse {
      0% {
        transform: translate(-50%, -50%) scale(0.1);
        opacity: 0.0;
      }
      50% {
        transform: translate(-50%, -50%) scale(1.5);
        opacity: 0.5;
      }
      100% {
        transform: translate(-50%, -50%) scale(2.5);
        opacity: 0.0;
      }
    }
  `;

  const pulsatingStyle = `
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgba(0, 155, 255, 0.5);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: pulse 2s infinite;
  `;

  const rotatingStyle = `
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(${angle}deg);
  `;

  return L.divIcon({
    html: `
      <style>${keyframesPulse}</style>
      <div style="position: relative;">
        <div style="${pulsatingStyle}"></div>
        <img src="/bee.png" style="${rotatingStyle}" />
      </div>
    `,
    iconSize: [60, 60],
    className: ''
  });
}