import { z } from 'zod';

export const EditGlobalSystemSchema = z.object({
	timeToExpire: z.number().min(0).or(z.string()),
	timeToExpireScheduled: z.number().min(0).or(z.string()),
	currentAppVersion: z.string(),
	minimumAppVersion: z.string(),
	minutesToConsiderOnline: z.number().min(0).or(z.string()),
});

export type EditGlobalSystemSchemaType = z.infer<typeof EditGlobalSystemSchema>;
