import { z } from 'zod';
import { dateFilterSchema } from '../../../../../../helpers/dateFilterSchema';

export const IndicatorsFilterSchema = dateFilterSchema.extend({
	query: z.string().optional(),
	geofenceId: z
		.array(
			z.object({
				value: z.number(),
				label: z.string().optional(),
			})
		)
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
	categories: z
		.array(
			z.object({
				value: z.number(),
				label: z.string().optional(),
			})
		)
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
	driverId: z
		.object({
			value: z.number(),
			label: z.string().optional(),
		})

		.optional()
		.nullable()
		.transform((value) => value?.value),
	operation: z
		.object({
			value: z.string().optional(),
			label: z.string().optional(),
		})
		.optional()
		.nullable()
		.transform((value) => value?.value),
});

export type IndicatorsFilterSchemaType = z.infer<typeof IndicatorsFilterSchema>;
