import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../services/angular/axios';
import { Modal } from '../../Modal';
import { Protocol } from '../../../services/angular/types/Order';
import { Root, Image as RadixImage } from '@radix-ui/react-avatar';

interface FreightImageProps {
	protocols?: Protocol[];
}

export const FreightImage = ({ protocols = [] }: FreightImageProps) => {
	const [open, setOpen] = useState(false);
	const [src, setSrc] = useState('');

	const { data } = useQuery(
		['freight-image', protocols.map((protocol) => protocol.id).join('-')],
		async () =>
			await Promise.all(
				protocols.map(async (protocol) => {
					if (!protocol.asset) return;

					const { data } = await api.get('/assets', {
						params: {
							imageName: protocol.asset,
						},
					});

					const imageUrl: string = data?.url;

					return imageUrl;
				})
			)
	);

	return (
		<div className="flex flex-wrap justify-end gap-1">
			{data?.map((image, index) => (
				<span key={index}>
					{image && (
						<img
							onClick={() => {
								setOpen(true);
								setSrc(image);
							}}
							className="h-20 w-20 cursor-zoom-in object-cover md:h-20 md:w-20"
							src={image}
							alt="Foto do protocolo"
						/>
					)}
				</span>
			))}

			<Modal
				className="max-h-[95vh]"
				title="Visualizar imagem"
				open={open}
				setOpen={setOpen}
			>
				<div className="flex items-center justify-center">
					<Root className="inline-flex h-full w-full select-none items-center justify-center overflow-hidden bg-neutral-100 align-middle focus:outline-none">
						<RadixImage src={src} className="h-full w-full object-cover" />
					</Root>
				</div>
			</Modal>
		</div>
	);
};
