import { z } from 'zod';
import { ErrorsMessageHelper } from '../../../../../helpers/errors/ErrorsMessageHelper';
import { RegexHelper } from '../../../../../helpers/regex/RegexHelper';

export const AngularAddUserSchema = z.object({
	cpf: z
		.string()
		.min(1, { message: ErrorsMessageHelper.required })
		.min(14, { message: ErrorsMessageHelper.min(11) })
		.max(14, { message: ErrorsMessageHelper.max(11) })
		.regex(RegexHelper.cpf, {
			message: ErrorsMessageHelper.cpf,
		}),
	isAdmin: z
		.object({
			value: z.boolean(),
			label: z.string().optional(),
		})
		.transform((value) => Boolean(value.value)),
});
