import React from 'react';

const FormImage: React.FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<g id="freepik--Floor--inject-4">
				<ellipse
					id="freepik--floor--inject-4"
					cx="251.11"
					cy="326.51"
					rx="230.89"
					ry="133.31"
					fill="#f5f5f5"
				></ellipse>
			</g>
			<g id="freepik--Form--inject-4">
				<g id="freepik--Questionary--inject-4">
					<path
						d="M84.55,410.85,81.41,409a4,4,0,0,1-1.79-3.11V173.16a4,4,0,0,1,1.79-3.1l144.4-83.38a4,4,0,0,1,3.59,0l3.14,1.82a4,4,0,0,1,1.8,3.1V324.37a4,4,0,0,1-1.8,3.11L88.14,410.85A4,4,0,0,1,84.55,410.85Z"
						fill="#37474f"
					></path>
					<path
						d="M234.32,91.31c-.13-.92-.88-1.26-1.78-.74L88.14,173.94a3.62,3.62,0,0,0-1.27,1.33l-6.73-3.88a3.7,3.7,0,0,1,1.27-1.33l144.4-83.38a4,4,0,0,1,3.59,0l3.14,1.82A4,4,0,0,1,234.32,91.31Z"
						fill="#455a64"
					></path>
					<path
						d="M87.82,411a.21.21,0,0,1-.1,0l-.19.07-.07,0c-.11,0-.22.06-.33.09h0A4.06,4.06,0,0,1,85,411a2.87,2.87,0,0,1-.4-.18L81.41,409a4,4,0,0,1-1.79-3.11V173.16a3.72,3.72,0,0,1,.52-1.77l6.73,3.88a3.65,3.65,0,0,0-.53,1.77V409.82a1.52,1.52,0,0,0,.27.94,1,1,0,0,0,1,.31Z"
						fill="#263238"
					></path>
					<path
						d="M138.45,163.82a1.41,1.41,0,0,0-.32-.66l-.12-.1a.71.71,0,0,0-.67-.1.77.77,0,0,0-.25.11,2.82,2.82,0,0,0-1,1.23,4.35,4.35,0,0,0-.42,1.8,1.79,1.79,0,0,0,.4,1.29.79.79,0,0,0,1,.08,2.12,2.12,0,0,0,.56-.49,4.27,4.27,0,0,0,.38-.59,4.49,4.49,0,0,0,.49-1.94A3,3,0,0,0,138.45,163.82Zm23.48-52.47a3.34,3.34,0,0,0-1.72-.46,6.78,6.78,0,0,0-3.24,1,14.65,14.65,0,0,0-6,7.17c-.19.42-.37.86-.53,1.29s-.33.87-.47,1.32-.3.9-.43,1.35c-.05.15-.09.31-.14.45l-.26.91a.65.65,0,0,1,0,.13c-.15.55-.29,1.12-.45,1.68-.08.31-.15.6-.23.9l-.23.89c-.32,1.19-.64,2.33-1,3.42l-.23.66c-.11.31-.22.62-.34.92s-.19.46-.28.67c-.15.37-.31.72-.48,1.06s-.21.43-.32.64c-2.53,4.86-5.62,6.76-7.41,9.26-1.57,2.18-2,3.92-4.12,7.37-.67,1.1-1.51,2.37-2.61,3.89-3.05,4.2-4.14,6.28-4.51,7.54a.42.42,0,0,0,0,.1c0,.1,0,.19-.07.28a.37.37,0,0,0,0,.09,2.4,2.4,0,0,0,0,.24,5,5,0,0,0-.05.74v9.34l3.36,1.94v-9.35a4.51,4.51,0,0,1,.21-1.44c.37-1.26,1.45-3.33,4.51-7.53,1.1-1.53,1.94-2.8,2.61-3.9,2.1-3.44,2.55-5.19,4.12-7.35,0,0,0,0,0,0,1.79-2.51,4.88-4.4,7.41-9.27A22.92,22.92,0,0,0,150,135c1.62-4,2.35-9.39,4.34-13.92a14.56,14.56,0,0,1,6-7.15c2.05-1.18,3.55-1.28,4.7-.74Zm14.73,11.94h0a3.6,3.6,0,0,0-.89-.36c-1.27-.3-3.16.44-5.06.22,2.67,3.45,6.38,1.23,8.42,1.72a3.15,3.15,0,0,1,.9.37h0Zm7.12,12.83a1.86,1.86,0,0,0-.28.21l-.14.14-.13.15a4.21,4.21,0,0,0-.88,2.53,1.86,1.86,0,0,0,.39,1.28,1.27,1.27,0,0,0,.12.1.69.69,0,0,0,.66.1.85.85,0,0,0,.26-.11c.1-.06.19-.14.29-.21a2.87,2.87,0,0,0,.39-.45,4.3,4.3,0,0,0,.75-2.36C185.21,136.29,184.57,135.66,183.78,136.12Zm2.2-8.45a11,11,0,0,0-3.47-1.23c-.52-.12-1-.25-1.39-.38a9.75,9.75,0,0,0,4.74,2.31,13.62,13.62,0,0,1,3.1,1Z"
						fill="#FFC100"
					></path>
					<path
						d="M189.23,129.54l-.27-.16a13.62,13.62,0,0,0-3.1-1,9.71,9.71,0,0,1-4.74-2.31,6.76,6.76,0,0,0-1.09-.82,3.19,3.19,0,0,0-.9-.36c-2-.49-5.75,1.73-8.41-1.72l-.09-.11c-1.86-2.51-2.54-8.11-5.33-9.75l-.28-.16c-1.15-.54-2.65-.44-4.7.74-7.39,4.27-7.64,14.41-10.3,21.08s-6.44,8.76-8.49,11.63-2.19,5-6.74,11.26-4.72,7.8-4.72,9v9.34l60.52-35v-9.34A2.36,2.36,0,0,0,189.23,129.54Zm-52.14,37.93c-.79.46-1.42-.15-1.42-1.37a3.77,3.77,0,0,1,1.42-3c.79-.46,1.42.16,1.42,1.38A3.75,3.75,0,0,1,137.09,167.47Zm23.23-28.14a3,3,0,0,1-4.66-1.43,8.61,8.61,0,0,1-.73-3.76c0-4.59,2.41-9.7,5.39-11.42a4.27,4.27,0,0,1,1.3-.51c2.36-.47,4.09,1.79,4.09,5.7C165.71,132.5,163.3,137.61,160.32,139.33Zm23.46,1.18c-.79.46-1.43-.15-1.43-1.36a3.78,3.78,0,0,1,1.43-3c.79-.45,1.43.16,1.43,1.38A3.74,3.74,0,0,1,183.78,140.51Z"
						fill="#FFC100"
					></path>
					<path
						d="M189.23,129.54l-.27-.16a13.62,13.62,0,0,0-3.1-1,9.71,9.71,0,0,1-4.74-2.31,6.76,6.76,0,0,0-1.09-.82,3.19,3.19,0,0,0-.9-.36c-2-.49-5.75,1.73-8.41-1.72l-.09-.11c-1.86-2.51-2.54-8.11-5.33-9.75l-.28-.16c-1.15-.54-2.65-.44-4.7.74-7.39,4.27-7.64,14.41-10.3,21.08s-6.44,8.76-8.49,11.63-2.19,5-6.74,11.26-4.72,7.8-4.72,9v9.34l60.52-35v-9.34A2.36,2.36,0,0,0,189.23,129.54Zm-52.14,37.93c-.79.46-1.42-.15-1.42-1.37a3.77,3.77,0,0,1,1.42-3c.79-.46,1.42.16,1.42,1.38A3.75,3.75,0,0,1,137.09,167.47Zm23.23-28.14a3,3,0,0,1-4.66-1.43,8.61,8.61,0,0,1-.73-3.76c0-4.59,2.41-9.7,5.39-11.42a4.27,4.27,0,0,1,1.3-.51c2.36-.47,4.09,1.79,4.09,5.7C165.71,132.5,163.3,137.61,160.32,139.33Zm23.46,1.18c-.79.46-1.43-.15-1.43-1.36a3.78,3.78,0,0,1,1.43-3c.79-.45,1.43.16,1.43,1.38A3.74,3.74,0,0,1,183.78,140.51Z"
						opacity="0.1"
					></path>
					<path
						d="M130.28,165.39a4.51,4.51,0,0,0-.21,1.44v9.35l-3.36-1.94V164.9a5,5,0,0,1,.05-.74,2.4,2.4,0,0,1,0-.24.37.37,0,0,1,0-.09c0-.09,0-.18.07-.28l0-.1h0Z"
						opacity="0.25"
					></path>
					<path
						d="M141.52,146.61c-1.57,2.16-2,3.91-4.12,7.35L134.05,152c2.1-3.45,2.55-5.19,4.12-7.37h0Z"
						opacity="0.25"
					></path>
					<path
						d="M154.36,121c-2,4.53-2.72,9.87-4.34,13.92a22.92,22.92,0,0,1-1.08,2.37l-3.36-1.93c.11-.21.22-.43.32-.64s.33-.69.48-1.06c.09-.21.19-.44.28-.67s.23-.61.34-.92l.23-.66c.36-1.09.68-2.23,1-3.42l.23-.89c.08-.3.15-.59.23-.9.16-.56.3-1.13.45-1.68a.65.65,0,0,0,0-.13l.26-.91c.05-.14.09-.3.14-.45.13-.45.28-.9.43-1.35s.3-.88.47-1.32.34-.87.53-1.29Z"
						opacity="0.25"
					></path>
					<path
						d="M136.08,164.3a4.35,4.35,0,0,0-.42,1.8,1.79,1.79,0,0,0,.4,1.29,1.85,1.85,0,0,1-.39-1.29A4.51,4.51,0,0,1,136.08,164.3Zm2.44.15a3,3,0,0,0-.07-.63,3,3,0,0,1,.06.63,4.48,4.48,0,0,1-.48,1.94A4.49,4.49,0,0,0,138.52,164.45Zm44.34-23.92a.69.69,0,0,0,.66.1A.76.76,0,0,1,182.86,140.53Z"
						fill="#FFC100"
					></path>
					<path
						d="M165.72,127.91c0,4.59-2.42,9.7-5.4,11.42a3.05,3.05,0,0,1-4.66-1.42,4.26,4.26,0,0,0,1.31-.52c3-1.73,5.39-6.83,5.39-11.42a8.47,8.47,0,0,0-.74-3.76C164,121.74,165.72,124,165.72,127.91Z"
						fill="#FFC100"
					></path>
					<path
						d="M165.72,127.91c0,4.59-2.42,9.7-5.4,11.42a3.05,3.05,0,0,1-4.66-1.42,4.26,4.26,0,0,0,1.31-.52c3-1.73,5.39-6.83,5.39-11.42a8.47,8.47,0,0,0-.74-3.76C164,121.74,165.72,124,165.72,127.91Z"
						opacity="0.30000000000000004"
					></path>
					<path
						d="M138,166.39a3.12,3.12,0,0,1-.37.59,2.12,2.12,0,0,1-.56.49.79.79,0,0,1-1-.08,1.79,1.79,0,0,1-.4-1.29,4.35,4.35,0,0,1,.42-1.8,2.82,2.82,0,0,1,1-1.23.77.77,0,0,1,.25-.11.74.74,0,0,1,.79.2,1.41,1.41,0,0,1,.32.66,3,3,0,0,1,.07.63A4.24,4.24,0,0,1,138,166.39Z"
						fill="#37474f"
					></path>
					<path
						d="M185.21,137.5a4.3,4.3,0,0,1-.75,2.36,2.87,2.87,0,0,1-.39.45c-.1.07-.19.15-.29.21a.85.85,0,0,1-.26.11.74.74,0,0,1-.78-.2,1.86,1.86,0,0,1-.39-1.28,4.21,4.21,0,0,1,.88-2.53l.13-.15.14-.14a1.86,1.86,0,0,1,.28-.21C184.57,135.66,185.21,136.29,185.21,137.5Z"
						fill="#37474f"
					></path>
					<path
						d="M97.51,195.71l125.66-72.55a2.39,2.39,0,0,1,2.15,0l1.21.7a2.41,2.41,0,0,1,1.08,1.87v191.7a2.38,2.38,0,0,1-1.08,1.86L100.87,391.84a2.33,2.33,0,0,1-2.15,0l-1.21-.7a2.37,2.37,0,0,1-1.08-1.86V197.58A2.38,2.38,0,0,1,97.51,195.71Z"
						fill="#f5f5f5"
					></path>
					<path
						d="M227.57,125.4c-.14-.44-.55-.58-1-.3L100.87,197.65a2.2,2.2,0,0,0-.76.81l-3.36-1.94a2,2,0,0,1,.76-.8l125.66-72.55a2.31,2.31,0,0,1,2.14,0l1.22.69A2.4,2.4,0,0,1,227.57,125.4Z"
						fill="#fafafa"
					></path>
					<path
						d="M100.62,392a1.8,1.8,0,0,1-.4.1l-.21,0a2.84,2.84,0,0,1-.65,0,1.67,1.67,0,0,1-.4-.1l-.24-.12-1.21-.7a2.35,2.35,0,0,1-1.08-1.86V197.58a2.11,2.11,0,0,1,.32-1.06l3.36,1.94a2.26,2.26,0,0,0-.31,1.06v191.7C99.8,391.81,100.14,392.1,100.62,392Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M150.26,151.68l-.17.28a7,7,0,0,0-.86,2.12c0,.06,0,.09,0,.12a.18.18,0,0,0,0,.05l-2.05,11.54-.64,3.69c0,.15-.07.3-.11.46a4.43,4.43,0,0,1-.14.44c0,.1-.07.2-.11.3l3.36,1.94a5.68,5.68,0,0,0,.35-1.2l.66-3.69,2-11.54a6.22,6.22,0,0,1,1.05-2.57Z"
						fill="#FFC100"
					></path>
					<path
						d="M127.12,188.91c-.34.54-.66,1.08-1,1.62-.14.26-.28.52-.41.78s-.32.67-.46,1-.32.74-.46,1.12-.28.76-.41,1.14-.29.89-.41,1.34c-.08.29-.16.58-.24.89q-.28,1.06-.51,2.16c0,.25-.11.51-.15.76s-.13.62-.18.92c0,0,0,0,0,.05,0,.24,0,.4.12.47a.12.12,0,0,0,.1,0h0L126,198.9l.47-.37.23-.17.25-.2.2-.15.17-.14a25.47,25.47,0,0,1,4.15-8.47l-3.36-1.94C127.78,187.94,127.44,188.43,127.12,188.91Z"
						fill="#FFC100"
					></path>
					<path
						d="M163.48,126.29c-.16.26-.3.52-.44.78a3.21,3.21,0,0,0-.24.45c-.12.21-.22.43-.33.65s-.18.35-.26.53c-.17.34-.32.68-.46,1s-.32.74-.46,1.11a0,0,0,0,0,0,0c-.14.33-.25.66-.36,1-.18.5-.33,1-.47,1.51-.08.27-.14.53-.21.79a.35.35,0,0,1,0,.06c0,.14-.07.27-.09.41-.08.31-.14.63-.19.94s-.1.49-.17.74-.13.52-.21.78-.16.49-.24.74c0,0,0,0,0,.07-.1.27-.21.55-.33.83s-.22.55-.36.84a2.18,2.18,0,0,0-.09.23c-.1.21-.21.42-.31.64l-.53,1c-.12.24-.24.46-.37.69l3.37,1.94a22.79,22.79,0,0,0,2.62-6.58,28.91,28.91,0,0,1,3.54-9.28Z"
						fill="#FFC100"
					></path>
					<path
						d="M183.47,133.77a1.28,1.28,0,0,0-.55-.18,4.11,4.11,0,0,0,3,1.9,2.18,2.18,0,0,1,.84.18Z"
						fill="#FFC100"
					></path>
					<path
						d="M183.47,133.77a1.28,1.28,0,0,0-.55-.18,4.11,4.11,0,0,0,3,1.9,2.18,2.18,0,0,1,.84.18Z"
						fill="#fff;opacity:0.4"
					></path>
					<path
						d="M196,148.38a3.62,3.62,0,0,0-3.37.1c-3.91,1.72-5.6,2.5-7.62,3.34-.39,2.6,1.14,2.84,1.9,2.53,2.84-1.12,4.29-1.81,9.09-3.92a3.63,3.63,0,0,1,3.34-.11Z"
						fill="#FFC100"
					></path>
					<path
						d="M196,148.38a3.62,3.62,0,0,0-3.37.1c-3.91,1.72-5.6,2.5-7.62,3.34-.39,2.6,1.14,2.84,1.9,2.53,2.84-1.12,4.29-1.81,9.09-3.92a3.63,3.63,0,0,1,3.34-.11Z"
						fill="#fff;opacity:0.4"
					></path>
					<path
						d="M149.52,172.62a13.75,13.75,0,0,1-3,4.22L136,184.75a28,28,0,0,0-4.52,4.65l-3.36-1.94a27.65,27.65,0,0,1,4.52-4.66l10.54-7.9a14,14,0,0,0,2.95-4.22Z"
						fill="#FFC100"
					></path>
					<path
						d="M149.52,172.62a13.75,13.75,0,0,1-3,4.22L136,184.75a28,28,0,0,0-4.52,4.65l-3.36-1.94a27.65,27.65,0,0,1,4.52-4.66l10.54-7.9a14,14,0,0,0,2.95-4.22Z"
						fill="#fff;opacity:0.4"
					></path>
					<path
						d="M160.68,144.1a35.82,35.82,0,0,1-5.32,7.4,12.64,12.64,0,0,0-1.74,2.12l-3.36-1.94a11.47,11.47,0,0,1,1.74-2.12,35.83,35.83,0,0,0,5.31-7.4Z"
						fill="#FFC100"
					></path>
					<path
						d="M160.68,144.1a35.82,35.82,0,0,1-5.32,7.4,12.64,12.64,0,0,0-1.74,2.12l-3.36-1.94a11.47,11.47,0,0,1,1.74-2.12,35.83,35.83,0,0,0,5.31-7.4Z"
						fill="#fff;opacity:0.4"
					></path>
					<path
						d="M179.66,119.27c-1.24-.65-2.89-.54-4.83.48-2.88,1.51-5.78,4.71-8,8.49l-3.36-2c2.2-3.78,5.1-7,8-8.49a6.77,6.77,0,0,1,3.08-.87,3.69,3.69,0,0,1,1.85.47Z"
						fill="#FFC100"
					></path>
					<path
						d="M179.66,119.27c-1.24-.65-2.89-.54-4.83.48-2.88,1.51-5.78,4.71-8,8.49l-3.36-2c2.2-3.78,5.1-7,8-8.49a6.77,6.77,0,0,1,3.08-.87,3.69,3.69,0,0,1,1.85.47Z"
						fill="#fff;opacity:0.4"
					></path>
					<path
						d="M131.51,189.4a25.47,25.47,0,0,0-4.15,8.47l-.17.14-.2.15-.25.2-.23.17-.47.37-2.85,2.28h0a.12.12,0,0,1-.1,0c-.11-.07-.16-.23-.12-.47,0,0,0,0,0-.05,0-.3.11-.61.18-.92s.1-.51.15-.76q.23-1.09.51-2.16c.08-.31.16-.6.24-.89.12-.45.26-.89.41-1.34s.26-.76.41-1.14.29-.75.46-1.12.3-.69.46-1,.27-.52.41-.78c.29-.54.61-1.08,1-1.62s.66-1,1-1.45Z"
						opacity="0.15"
					></path>
					<path
						d="M153.62,153.62a6.22,6.22,0,0,0-1.05,2.57l-2,11.54-.66,3.69a5.68,5.68,0,0,1-.35,1.2l-3.36-1.94c0-.1.08-.2.11-.3a4.43,4.43,0,0,0,.14-.44c0-.16.08-.31.11-.46l.64-3.69,2.05-11.54a.18.18,0,0,1,0-.05s0-.06,0-.12a7,7,0,0,1,.86-2.12l.17-.28Z"
						opacity="0.15"
					></path>
					<path
						d="M166.84,128.24a28.91,28.91,0,0,0-3.54,9.28,22.79,22.79,0,0,1-2.62,6.58l-3.37-1.94c.13-.23.25-.45.37-.69l.53-1c.1-.22.21-.43.31-.64a2.18,2.18,0,0,1,.09-.23c.13-.28.25-.57.36-.84s.23-.56.33-.83c0,0,0-.05,0-.07.08-.25.17-.49.24-.74s.15-.52.21-.78.12-.5.17-.74.11-.63.19-.94c0-.14.06-.27.09-.41a.35.35,0,0,0,0-.06c.07-.26.13-.52.21-.79.14-.51.29-1,.47-1.51.11-.34.22-.67.36-1a0,0,0,0,1,0,0c.14-.37.3-.74.46-1.11s.29-.68.46-1c.08-.18.17-.35.26-.53s.21-.44.33-.65a3.21,3.21,0,0,1,.24-.45c.14-.26.28-.52.44-.78Z"
						opacity="0.15"
					></path>
					<path
						d="M123.16,201.18h0L126,198.9C124.87,199.81,123.33,201,123.16,201.18Z"
						fill="#FFC100"
					></path>
					<path
						d="M199.35,150.34l0,0a3.67,3.67,0,0,0-3.35.1c-4.79,2.12-6.24,2.81-9.08,3.94-.76.3-2.29.06-1.9-2.54a1.22,1.22,0,0,1,0-.17l.65-3.69,1.82-10.06s.41-1.59-.64-2.18l-.08,0a1.76,1.76,0,0,0-.83-.18,4.12,4.12,0,0,1-3-1.92,7.56,7.56,0,0,1-1.13-5.75c.77-4.32-.07-7.34-2-8.46l-.15-.1c-1.24-.65-2.89-.54-4.83.48-5.11,2.68-10.27,10.63-11.53,17.77-.79,4.5-5.32,11.43-7.94,14s-2.79,4.69-2.79,4.69l-2,11.54-.66,3.69c-.48,2.76-3.31,5.43-3.31,5.43L136,184.75a25.39,25.39,0,0,0-8.67,13.12s-4,3.06-4.2,3.31h0l1.35-.77,72.72-41.81s.84-.45,1-.57,3.11-2.88,3.11-2.88C201.26,152.77,200.57,151.07,199.35,150.34Zm-21-21.21c-.78,4.39-3.94,9.28-7.08,10.93-2.17,1.13-3.76.43-4.29-1.57a8.37,8.37,0,0,1,0-3.4c.77-4.39,3.95-9.28,7.09-10.92a5.3,5.3,0,0,1,.91-.39C177.55,123,179,125.19,178.33,129.13Z"
						fill="#FFC100"
					></path>
					<path
						d="M123.17,201.19l74.08-42.58,1-.57c.18-.11,3.11-2.89,3.11-2.89l-74,42.72S122.88,201.35,123.17,201.19Z"
						fill="#FFC100"
					></path>
					<path
						d="M123.17,201.19l74.08-42.58,1-.57c.18-.11,3.11-2.89,3.11-2.89l-74,42.72S122.88,201.35,123.17,201.19Z"
						opacity="0.30000000000000004"
					></path>
					<path
						d="M175,123.78a5.3,5.3,0,0,0-.91.39c-3.14,1.64-6.32,6.53-7.09,10.92a8.37,8.37,0,0,0,0,3.4c.53,2,2.12,2.7,4.29,1.57,3.14-1.65,6.3-6.54,7.08-10.93C179,125.19,177.55,123,175,123.78Zm-7.8,14.63a8.19,8.19,0,0,1,0-3.28c.77-4.34,3.9-9.17,7-10.78A5.93,5.93,0,0,1,175,124a8.41,8.41,0,0,1,0,3.2c-.77,4.39-3.94,9.28-7.09,10.93a5.29,5.29,0,0,1-.71.31Z"
						fill="#FFC100"
					></path>
					<path
						d="M175,123.78a5.3,5.3,0,0,0-.91.39c-3.14,1.64-6.32,6.53-7.09,10.92a8.37,8.37,0,0,0,0,3.4c.53,2,2.12,2.7,4.29,1.57,3.14-1.65,6.3-6.54,7.08-10.93C179,125.19,177.55,123,175,123.78Zm-7.8,14.63a8.19,8.19,0,0,1,0-3.28c.77-4.34,3.9-9.17,7-10.78A5.93,5.93,0,0,1,175,124a8.41,8.41,0,0,1,0,3.2c-.77,4.39-3.94,9.28-7.09,10.93a5.29,5.29,0,0,1-.71.31Z"
						opacity="0.4"
					></path>
					<path
						d="M116.67,257.69a.89.89,0,0,1-.45-.12,1.06,1.06,0,0,1-.48-1V252.3a2.37,2.37,0,0,1,1.1-1.91l3.73-2.15a1,1,0,0,1,1.55.89v4.31a2.35,2.35,0,0,1-1.1,1.91l-3.73,2.16A1.25,1.25,0,0,1,116.67,257.69Zm4.52-8.74a.78.78,0,0,0-.17.07l-3.73,2.15a1.48,1.48,0,0,0-.65,1.13v4.31a.33.33,0,0,0,0,.18.52.52,0,0,0,.17-.06l3.73-2.16a1.48,1.48,0,0,0,.65-1.13v-4.31A.57.57,0,0,0,121.19,249Z"
						fill="#455a64"
					></path>
					<path
						d="M125.29,247.62l14.7-8.49c.49-.28.88-.06.88.5v1.15a1.93,1.93,0,0,1-.88,1.51l-14.7,8.49c-.48.28-.88.06-.88-.5v-1.15A1.93,1.93,0,0,1,125.29,247.62Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M153.1,236.66a.87.87,0,0,1-.45-.12,1.06,1.06,0,0,1-.49-1v-4.31a2.38,2.38,0,0,1,1.11-1.91l3.73-2.15a1,1,0,0,1,1.07-.06,1,1,0,0,1,.48.95v4.31a2.35,2.35,0,0,1-1.1,1.91l-3.73,2.16A1.25,1.25,0,0,1,153.1,236.66Zm4.51-8.74a1.37,1.37,0,0,0-.16.07l-3.73,2.15a1.49,1.49,0,0,0-.66,1.13v4.31a.28.28,0,0,0,0,.18.62.62,0,0,0,.17-.06l3.73-2.16a1.46,1.46,0,0,0,.65-1.13V228.1A.31.31,0,0,0,157.61,227.92Z"
						fill="#455a64"
					></path>
					<path
						d="M161.72,226.59l14.7-8.49c.49-.28.88-.06.88.5v1.15a1.93,1.93,0,0,1-.88,1.51l-14.7,8.49c-.49.28-.88.06-.88-.5V228.1A2,2,0,0,1,161.72,226.59Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M189.52,215.63a.89.89,0,0,1-.45-.12,1.08,1.08,0,0,1-.48-1v-4.31a2.38,2.38,0,0,1,1.1-1.91h0l3.73-2.15a1,1,0,0,1,1.56.9v4.3a2.36,2.36,0,0,1-1.11,1.91l-3.73,2.16A1.23,1.23,0,0,1,189.52,215.63Zm.62-6.52a1.51,1.51,0,0,0-.65,1.13v4.31a.28.28,0,0,0,0,.18.35.35,0,0,0,.16-.06l3.73-2.16a1.49,1.49,0,0,0,.66-1.13v-4.3a.33.33,0,0,0,0-.19s-.06,0-.17.07Z"
						fill="#455a64"
					></path>
					<path
						d="M198.15,205.56l14.7-8.49c.48-.28.88-.06.88.5v1.15a2,2,0,0,1-.88,1.51l-14.7,8.49c-.49.28-.88.06-.88-.5v-1.15A1.93,1.93,0,0,1,198.15,205.56Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M125.33,226.58v1.68c0,.92.64,1.29,1.43.83l86-49.61a3.18,3.18,0,0,0,1.43-2.49V175.3c0-.91-.64-1.28-1.43-.83l-86,49.62A3.18,3.18,0,0,0,125.33,226.58Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M118.78,213.46v1.68c0,.92.64,1.29,1.44.83l85.95-49.61a3.16,3.16,0,0,0,1.44-2.49v-1.69c0-.91-.64-1.28-1.44-.83L120.22,211A3.16,3.16,0,0,0,118.78,213.46Z"
						fill="#455a64"
					></path>
					<path
						d="M125.33,236.75v1.69c0,.91.64,1.28,1.43.83l86-49.61a3.18,3.18,0,0,0,1.43-2.49v-1.69c0-.91-.64-1.28-1.43-.83l-86,49.62A3.18,3.18,0,0,0,125.33,236.75Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M115.91,237.62c2.25-2.23,2.81-3.18,2.81-4s-.33-1.26-1.34-.68a3.46,3.46,0,0,0-1.67,2.4l-2.46.55a9.2,9.2,0,0,1,4.21-6.3c2.46-1.42,3.89-.36,3.89,2a9.13,9.13,0,0,1-2.8,6.05v1.95l-2.64,1.52Z"
						fill="#455a64"
					></path>
					<path
						d="M115.88,244.1a2.76,2.76,0,0,1,1.34-2.39c.88-.51,1.33-.06,1.33.85a2.77,2.77,0,0,1-1.33,2.41C116.33,245.48,115.88,245,115.88,244.1Z"
						fill="#455a64"
					></path>
					<path
						d="M117.25,252.3l1.1.83-1,1.9a.67.67,0,0,0,0,.8c.19.14.46,0,.62-.3l1-1.89,1.1.83c.16.12.4,0,.54-.26a.58.58,0,0,0,0-.69l-1.1-.83,1-1.9a.67.67,0,0,0,0-.8c-.19-.14-.46,0-.62.3l-1,1.9-1.1-.83c-.16-.12-.4,0-.54.25A.58.58,0,0,0,117.25,252.3Z"
						fill="#FFC100"
					></path>
					<path
						d="M116.67,295.66a.88.88,0,0,1-.45-.11,1.06,1.06,0,0,1-.48-1v-4.31a2.35,2.35,0,0,1,1.1-1.91l3.73-2.15a1,1,0,0,1,1.55.89v4.31a2.35,2.35,0,0,1-1.1,1.91l-3.73,2.15A1.25,1.25,0,0,1,116.67,295.66Zm4.52-8.73a.44.44,0,0,0-.17.07l-3.73,2.15a1.48,1.48,0,0,0-.65,1.13v4.31a.33.33,0,0,0,0,.18.52.52,0,0,0,.17-.06l3.73-2.16a1.48,1.48,0,0,0,.65-1.13v-4.31A.57.57,0,0,0,121.19,286.93Z"
						fill="#455a64"
					></path>
					<path
						d="M125.29,285.59l14.7-8.48c.49-.28.88-.06.88.5v1.14a1.93,1.93,0,0,1-.88,1.52l-14.7,8.49c-.48.28-.88.05-.88-.51v-1.14A1.94,1.94,0,0,1,125.29,285.59Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M153.1,274.63a.86.86,0,0,1-.45-.11,1.06,1.06,0,0,1-.49-1v-4.31a2.36,2.36,0,0,1,1.11-1.91l3.73-2.15a1,1,0,0,1,1.07-.06,1,1,0,0,1,.48.95v4.31a2.35,2.35,0,0,1-1.1,1.91l-3.73,2.15A1.25,1.25,0,0,1,153.1,274.63Zm4.51-8.73a.52.52,0,0,0-.16.07l-3.73,2.15a1.49,1.49,0,0,0-.66,1.13v4.31a.28.28,0,0,0,0,.18.62.62,0,0,0,.17-.06l3.73-2.16a1.48,1.48,0,0,0,.65-1.13v-4.31A.31.31,0,0,0,157.61,265.9Z"
						fill="#455a64"
					></path>
					<path
						d="M161.72,264.56l14.7-8.48c.49-.28.88-.06.88.5v1.14a1.93,1.93,0,0,1-.88,1.52l-14.7,8.49c-.49.28-.88,0-.88-.51v-1.14A2,2,0,0,1,161.72,264.56Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M189.52,253.6a.88.88,0,0,1-.45-.11,1.08,1.08,0,0,1-.48-1v-4.31a2.38,2.38,0,0,1,1.1-1.91h0l3.73-2.15a1,1,0,0,1,1.56.89v4.31a2.36,2.36,0,0,1-1.11,1.91l-3.73,2.15A1.23,1.23,0,0,1,189.52,253.6Zm.62-6.51a1.51,1.51,0,0,0-.65,1.13v4.31a.28.28,0,0,0,0,.18.64.64,0,0,0,.16-.06l3.73-2.16a1.49,1.49,0,0,0,.66-1.13v-4.31a.31.31,0,0,0,0-.18.62.62,0,0,0-.17.07Z"
						fill="#455a64"
					></path>
					<path
						d="M198.15,243.53l14.7-8.48c.48-.28.88-.06.88.5v1.14a2,2,0,0,1-.88,1.52l-14.7,8.49c-.49.28-.88.05-.88-.51v-1.14A1.93,1.93,0,0,1,198.15,243.53Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M125.33,264.55v1.69c0,.91.64,1.28,1.43.83l86-49.61a3.18,3.18,0,0,0,1.43-2.49v-1.69c0-.91-.64-1.28-1.43-.83l-86,49.62A3.18,3.18,0,0,0,125.33,264.55Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M125.33,274.73v1.69c0,.91.64,1.28,1.43.83l86-49.62a3.14,3.14,0,0,0,1.43-2.48v-1.69c0-.92-.64-1.29-1.43-.83l-86,49.62A3.18,3.18,0,0,0,125.33,274.73Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M115.91,275.6c2.25-2.23,2.81-3.18,2.81-4s-.33-1.26-1.34-.68a3.46,3.46,0,0,0-1.67,2.4l-2.46.55a9.2,9.2,0,0,1,4.21-6.3c2.46-1.42,3.89-.36,3.89,2.05a9.13,9.13,0,0,1-2.8,6.05v1.95l-2.64,1.52Z"
						fill="#455a64"
					></path>
					<path
						d="M115.88,282.08a2.76,2.76,0,0,1,1.34-2.39c.88-.51,1.33-.06,1.33.85a2.78,2.78,0,0,1-1.33,2.41C116.33,283.46,115.88,283,115.88,282.08Z"
						fill="#455a64"
					></path>
					<path
						d="M153.68,269.21l1.1.83-1,1.9a.67.67,0,0,0,0,.8c.18.14.46,0,.62-.3l1-1.89,1.1.83c.16.12.4,0,.53-.26a.58.58,0,0,0,0-.69l-1.1-.83,1-1.9a.66.66,0,0,0-.05-.8c-.18-.13-.46,0-.62.3l-1,1.9-1.1-.83c-.15-.12-.39,0-.53.25A.57.57,0,0,0,153.68,269.21Z"
						fill="#FFC100"
					></path>
					<path
						d="M116.67,333.64a.89.89,0,0,1-.45-.12,1,1,0,0,1-.48-.95v-4.31a2.35,2.35,0,0,1,1.1-1.91l3.73-2.15a1,1,0,0,1,1.55.89v4.31a2.35,2.35,0,0,1-1.1,1.91l-3.73,2.15A1.25,1.25,0,0,1,116.67,333.64Zm4.52-8.73a.44.44,0,0,0-.17.07l-3.73,2.15a1.48,1.48,0,0,0-.65,1.13v4.31a.36.36,0,0,0,0,.18.44.44,0,0,0,.17-.07l3.73-2.15a1.48,1.48,0,0,0,.65-1.13v-4.31A.57.57,0,0,0,121.19,324.91Z"
						fill="#455a64"
					></path>
					<path
						d="M125.29,323.57l14.7-8.49c.49-.28.88-.05.88.51v1.14a1.93,1.93,0,0,1-.88,1.52l-14.7,8.49c-.48.28-.88.05-.88-.51v-1.14A1.94,1.94,0,0,1,125.29,323.57Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M153.1,312.61a.87.87,0,0,1-.45-.12,1,1,0,0,1-.49-.95v-4.31a2.36,2.36,0,0,1,1.11-1.91l3.73-2.15a1.06,1.06,0,0,1,1.07-.07,1.08,1.08,0,0,1,.48,1v4.31a2.35,2.35,0,0,1-1.1,1.91l-3.73,2.15A1.25,1.25,0,0,1,153.1,312.61Zm4.51-8.73a.52.52,0,0,0-.16.07l-3.73,2.15a1.49,1.49,0,0,0-.66,1.13v4.31a.31.31,0,0,0,0,.18.51.51,0,0,0,.17-.07L157,309.5a1.48,1.48,0,0,0,.65-1.13v-4.31A.28.28,0,0,0,157.61,303.88Z"
						fill="#455a64"
					></path>
					<path
						d="M161.72,302.54l14.7-8.49c.49-.27.88-.05.88.51v1.14a1.93,1.93,0,0,1-.88,1.52l-14.7,8.49c-.49.28-.88.05-.88-.51v-1.14A2,2,0,0,1,161.72,302.54Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M189.52,291.58a.89.89,0,0,1-.45-.12,1,1,0,0,1-.48-.95V286.2a2.35,2.35,0,0,1,1.1-1.91h0l3.73-2.15A1,1,0,0,1,195,283v4.31a2.36,2.36,0,0,1-1.11,1.91l-3.73,2.15A1.23,1.23,0,0,1,189.52,291.58Zm.62-6.51a1.51,1.51,0,0,0-.65,1.13v4.31a.31.31,0,0,0,0,.18.52.52,0,0,0,.16-.07l3.73-2.15a1.49,1.49,0,0,0,.66-1.13V283a.31.31,0,0,0,0-.18.77.77,0,0,0-.17.06Z"
						fill="#455a64"
					></path>
					<path
						d="M198.15,281.51l14.7-8.49c.48-.27.88,0,.88.51v1.14a2,2,0,0,1-.88,1.52l-14.7,8.49c-.49.28-.88.05-.88-.51V283A1.93,1.93,0,0,1,198.15,281.51Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M125.33,302.53v1.69c0,.91.64,1.28,1.43.83l86-49.62a3.14,3.14,0,0,0,1.43-2.48v-1.69c0-.91-.64-1.29-1.43-.83l-86,49.62A3.18,3.18,0,0,0,125.33,302.53Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M125.33,312.71v1.69c0,.91.64,1.28,1.43.82l86-49.61a3.16,3.16,0,0,0,1.43-2.48v-1.69c0-.92-.64-1.29-1.43-.83l-86,49.62A3.16,3.16,0,0,0,125.33,312.71Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M115.91,313.58c2.25-2.23,2.81-3.18,2.81-4s-.33-1.27-1.34-.68a3.46,3.46,0,0,0-1.67,2.4l-2.46.54a9.2,9.2,0,0,1,4.21-6.29c2.46-1.42,3.89-.36,3.89,2a9.13,9.13,0,0,1-2.8,6.05v1.95l-2.64,1.52Z"
						fill="#455a64"
					></path>
					<path
						d="M115.88,320.06a2.77,2.77,0,0,1,1.34-2.4c.88-.5,1.33-.05,1.33.86a2.79,2.79,0,0,1-1.33,2.41C116.33,321.44,115.88,321,115.88,320.06Z"
						fill="#455a64"
					></path>
					<path
						d="M190.11,286.16l1.09.83-1,1.9a.66.66,0,0,0,0,.8c.18.14.46,0,.62-.3l1-1.9,1.1.83c.16.12.39,0,.53-.25a.57.57,0,0,0,0-.69l-1.1-.83,1-1.9a.69.69,0,0,0-.05-.8c-.18-.14-.46,0-.62.3l-1,1.9-1.1-.84c-.16-.12-.4,0-.53.26A.57.57,0,0,0,190.11,286.16Z"
						fill="#FFC100"
					></path>
					<path
						d="M116.67,371.62a.89.89,0,0,1-.45-.12,1,1,0,0,1-.48-.95v-4.31a2.35,2.35,0,0,1,1.1-1.91l3.73-2.16a1.09,1.09,0,0,1,1.07-.06,1.06,1.06,0,0,1,.48,1v4.31a2.38,2.38,0,0,1-1.1,1.91l-3.73,2.15A1.25,1.25,0,0,1,116.67,371.62Zm4.52-8.73a.52.52,0,0,0-.17.06l-3.73,2.16a1.48,1.48,0,0,0-.65,1.13v4.31a.36.36,0,0,0,0,.18.78.78,0,0,0,.17-.07l3.73-2.15a1.48,1.48,0,0,0,.65-1.13v-4.31A.5.5,0,0,0,121.19,362.89Z"
						fill="#455a64"
					></path>
					<path
						d="M125.29,361.55l14.7-8.49c.49-.28.88-.05.88.51v1.14a1.93,1.93,0,0,1-.88,1.52l-14.7,8.49c-.48.28-.88,0-.88-.51v-1.14A1.94,1.94,0,0,1,125.29,361.55Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M153.1,350.59a.87.87,0,0,1-.45-.12,1,1,0,0,1-.49-1v-4.31a2.36,2.36,0,0,1,1.11-1.91l3.73-2.16a1,1,0,0,1,1.55.9v4.31a2.35,2.35,0,0,1-1.1,1.91l-3.73,2.15A1.25,1.25,0,0,1,153.1,350.59Zm4.51-8.73a.64.64,0,0,0-.16.06l-3.73,2.16a1.49,1.49,0,0,0-.66,1.13v4.31a.31.31,0,0,0,0,.18,1.1,1.1,0,0,0,.17-.07l3.73-2.15a1.48,1.48,0,0,0,.65-1.13V342A.28.28,0,0,0,157.61,341.86Z"
						fill="#455a64"
					></path>
					<path
						d="M161.72,340.52l14.7-8.49c.49-.28.88,0,.88.51v1.14a1.93,1.93,0,0,1-.88,1.52l-14.7,8.49c-.49.28-.88.05-.88-.51V342A2,2,0,0,1,161.72,340.52Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M189.52,329.56a.89.89,0,0,1-.45-.12,1,1,0,0,1-.48-.95v-4.31a2.35,2.35,0,0,1,1.1-1.91h0l3.73-2.16a1.11,1.11,0,0,1,1.08-.06,1.08,1.08,0,0,1,.48,1v4.31a2.38,2.38,0,0,1-1.11,1.91l-3.73,2.15A1.23,1.23,0,0,1,189.52,329.56Zm.62-6.51a1.51,1.51,0,0,0-.65,1.13v4.31a.31.31,0,0,0,0,.18,1.37,1.37,0,0,0,.16-.07l3.73-2.15a1.49,1.49,0,0,0,.66-1.13V321a.28.28,0,0,0,0-.18.77.77,0,0,0-.17.06Z"
						fill="#455a64"
					></path>
					<path
						d="M198.15,319.49l14.7-8.49c.48-.28.88-.05.88.51v1.14a2,2,0,0,1-.88,1.52l-14.7,8.49c-.49.28-.88,0-.88-.51V321A1.93,1.93,0,0,1,198.15,319.49Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M125.33,340.51v1.69c0,.91.64,1.28,1.43.82l86-49.61a3.16,3.16,0,0,0,1.43-2.48v-1.69c0-.92-.64-1.29-1.43-.83l-86,49.62A3.16,3.16,0,0,0,125.33,340.51Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M125.33,350.69v1.69c0,.91.64,1.28,1.43.82l86-49.61a3.18,3.18,0,0,0,1.43-2.48v-1.69c0-.92-.64-1.29-1.43-.83l-86,49.62A3.16,3.16,0,0,0,125.33,350.69Z"
						fill="#e0e0e0"
					></path>
					<path
						d="M115.91,351.56c2.25-2.23,2.81-3.18,2.81-4s-.33-1.27-1.34-.68a3.46,3.46,0,0,0-1.67,2.39l-2.46.55a9.2,9.2,0,0,1,4.21-6.29c2.46-1.42,3.89-.36,3.89,2.05a9.13,9.13,0,0,1-2.8,6.05v1.95l-2.64,1.52Z"
						fill="#455a64"
					></path>
					<path
						d="M115.88,358a2.77,2.77,0,0,1,1.34-2.4c.88-.5,1.33-.06,1.33.86a2.76,2.76,0,0,1-1.33,2.4C116.33,359.42,115.88,359,115.88,358Z"
						fill="#455a64"
					></path>
					<path
						d="M190.11,324.3l1.09.83-1,1.9a.66.66,0,0,0,0,.8c.18.14.46,0,.62-.3l1-1.9,1.1.84c.16.12.39,0,.53-.26a.57.57,0,0,0,0-.69l-1.1-.83,1-1.9a.69.69,0,0,0-.05-.8c-.18-.14-.46,0-.62.3l-1,1.9-1.1-.84c-.16-.11-.4,0-.53.26A.57.57,0,0,0,190.11,324.3Z"
						fill="#FFC100"
					></path>
				</g>
			</g>
			<g id="freepik--Pencil--inject-4">
				<g id="freepik--pencil--inject-4">
					<path
						d="M359.58,283.5l35.05-6.87a1.09,1.09,0,0,1,1,1.79l-23.77,26.92Z"
						fill="#ffa8a7"
					></path>
					<path
						d="M395.65,278.42a1.09,1.09,0,0,0-1-1.79L387.79,278h0a6,6,0,0,1,2.82,5.59l0,.62Z"
						fill="#FFC100"
					></path>
					<path
						d="M241.19,380.29l2.86-1.65,3.47-2,124.35-71.33a16,16,0,0,0-1-11.62c-2.49-5.56-7.31-10-11.31-10.21L235.31,354.8l-6.61,3.79C233.12,356.37,245.59,378,241.19,380.29Z"
						fill="#37474f"
					></path>
					<path
						d="M252.12,374.06l6.4-3.68c1.89-1,.81-5.65-1.4-10.45-2.93-6.38-8.68-12.56-11.19-11.23l-4.72,2.71-1.67,1c2.51-1.33,8.27,4.84,11.19,11.23,2.11,4.6,3.19,9.07,1.58,10.29h0A.43.43,0,0,1,252.12,374.06Z"
						fill="#ebebeb"
					></path>
					<path
						d="M253.71,373.13c4.39-2.33-8.18-24-12.58-21.66l-6.41,3.66c4.39-2.32,17,19.33,12.57,21.66Z"
						fill="#FFC100"
					></path>
					<path
						d="M241.19,380.29l6.33-3.62c4.33-2.26-7.63-23.26-12.21-21.87a.77.77,0,0,0-.26.1l-6.35,3.69C233.12,356.37,245.59,378,241.19,380.29Z"
						fill="#ebebeb"
					></path>
					<path
						d="M395.81,277.24l-1.18-.61L369.2,290.54,237.7,366c-.25-.39-.45-.42-.61-.15,3.74,5.83,6.56,13.17,4.1,14.45l2.86-1.65,3.47-2,124.35-71.33h0l23.77-26.92A1,1,0,0,0,395.81,277.24Z"
						opacity="0.15"
					></path>
					<path
						d="M214.32,395.66l26.87-15.37c1.88-1,.84-5.58-1.33-10.35-2.91-6.39-8.64-12.63-11.16-11.35h0L201.72,374c-5.36,3.09-.43,14.71,5.54,19.91C209.48,395.9,212,396.77,214.32,395.66Z"
						fill="#f28f8f"
					></path>
					<path
						d="M215.92,387.33c-1.54-6.06-6.44-12-10.95-13.27a4.4,4.4,0,0,0-4,.55c-4,3.75.67,14.41,6.32,19.33,2.17,1.92,4.6,2.78,6.92,1.77C216.19,394.53,217,391.43,215.92,387.33Z"
						opacity="0.1"
					></path>
				</g>
			</g>
		</svg>
	);
};

export default FormImage;
