const TermoEntregador = () => {
	const html = `<html>
	<head>
		<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
		<meta http-equiv="Content-Style-Type" content="text/css" />
		<meta name="generator" content="Aspose.Words for .NET 23.9.0" />
		<title></title>
		<style type="text/css">
			body {
				font-size: 12pt;
				margin: 0pt;
				padding: 0pt;
				font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen',
					'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
					sans-serif;
				color: #5a5a5a;
			}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p {
				margin: 0pt;
			}
			li {
				margin-top: 0pt;
				margin-bottom: 0pt;
			}
			h1 {
				margin-top: 24pt;
				margin-bottom: 6pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: Calibri;
				font-size: 24pt;
				font-weight: bold;
				color: #000000;
			}
			h2 {
				margin-top: 18pt;
				margin-bottom: 4pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: Calibri;
				font-size: 18pt;
				font-weight: bold;
				color: #000000;
			}
			h3 {
				margin-top: 14pt;
				margin-bottom: 4pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: Calibri;
				font-size: 14pt;
				font-weight: bold;
				color: #000000;
			}
			h4 {
				margin-top: 12pt;
				margin-bottom: 2pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: Calibri;
				font-size: 12pt;
				font-weight: bold;
				font-style: normal;
				color: #000000;
			}
			h5 {
				margin-top: 11pt;
				margin-bottom: 2pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: Calibri;
				font-size: 11pt;
				font-weight: bold;
				color: #000000;
			}
			h6 {
				margin-top: 10pt;
				margin-bottom: 2pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: Calibri;
				font-size: 10pt;
				font-weight: bold;
				color: #000000;
			}
			.BalloonText {
				font-family: 'Times New Roman';
				font-size: 9pt;
			}
			.CommentText {
				font-size: 10pt;
			}
			.Footer {
				font-size: 12pt;
			}
			.Header {
				font-size: 12pt;
			}
			.ListParagraph {
				margin-left: 36pt;
				font-size: 12pt;
			}
			.NormalWeb {
				margin-top: 5pt;
				margin-bottom: 5pt;
				font-family: 'Times New Roman';
				font-size: 12pt;
			}
			.Subtitle {
				margin-top: 18pt;
				margin-bottom: 4pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: Georgia;
				font-size: 24pt;
				font-style: italic;
				color: #666666;
			}
			.Title {
				margin-top: 24pt;
				margin-bottom: 6pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-size: 36pt;
				font-weight: bold;
			}
			span.CommentReference {
				font-size: 8pt;
			}
			span.Hyperlink {
				text-decoration: underline;
				color: #0563c1;
				-aw-style-name: hyperlink;
			}
			span.TextodebaloChar {
				font-family: 'Times New Roman';
				font-size: 9pt;
			}
			span.TextodecomentrioChar {
				font-family: Calibri;
				font-size: 10pt;
			}
			span.UnresolvedMention {
				color: #605e5c;
				background-color: #e1dfdd;
			}
			.TableGrid {
			}
			.TableNormal0 {
			}
			.awlist1 {
				list-style: none;
				counter-reset: awlistcounter1_0;
			}
			.awlist1 > li:before {
				content: counter(awlistcounter1_0, lower-latin) ')';
				counter-increment: awlistcounter1_0;
			}
			.awlist2 {
				list-style: none;
				counter-reset: awlistcounter2_0;
			}
			.awlist2 > li:before {
				content: '(' counter(awlistcounter2_0, lower-roman) ')';
				counter-increment: awlistcounter2_0;
			}
			.awlist3 {
				list-style: none;
				counter-reset: awlistcounter3_0;
			}
			.awlist3 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.awlist4 {
				list-style: none;
				counter-reset: awlistcounter3_1;
			}
			.awlist4 > li:before {
				content: counter(awlistcounter3_0) '.' counter(awlistcounter3_1) '.';
				counter-increment: awlistcounter3_1;
			}
			.awlist5 {
				list-style: none;
				counter-reset: awlistcounter3_0 1;
			}
			.awlist5 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.awlist6 {
				list-style: none;
				counter-reset: awlistcounter3_0 2;
			}
			.awlist6 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.awlist7 {
				list-style: none;
				counter-reset: awlistcounter3_0 3;
			}
			.awlist7 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.awlist8 {
				list-style: none;
				counter-reset: awlistcounter3_2;
			}
			.awlist8 > li:before {
				content: counter(awlistcounter3_0) '.' counter(awlistcounter3_1) '.'
					counter(awlistcounter3_2) '.';
				counter-increment: awlistcounter3_2;
			}
			.awlist9 {
				list-style: none;
				counter-reset: awlistcounter3_0 4;
			}
			.awlist9 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.awlist10 {
				list-style: none;
				counter-reset: awlistcounter3_0 5;
			}
			.awlist10 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.awlist11 {
				list-style: none;
				counter-reset: awlistcounter3_0 6;
			}
			.awlist11 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.awlist12 {
				list-style: none;
				counter-reset: awlistcounter3_0 7;
			}
			.awlist12 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.awlist13 {
				list-style: none;
				counter-reset: awlistcounter3_2 3;
			}
			.awlist13 > li:before {
				content: '8.5.' counter(awlistcounter3_2) '.';
				counter-increment: awlistcounter3_2;
			}
			.awlist14 {
				list-style: none;
				counter-reset: awlistcounter3_1 5;
			}
			.awlist14 > li:before {
				content: '8.' counter(awlistcounter3_1) '.';
				counter-increment: awlistcounter3_1;
			}
			.awlist15 {
				list-style: none;
				counter-reset: awlistcounter3_2;
			}
			.awlist15 > li:before {
				content: '8.' counter(awlistcounter3_1) '.' counter(awlistcounter3_2)
					'.';
				counter-increment: awlistcounter3_2;
			}
			.awlist16 {
				list-style: none;
				counter-reset: awlistcounter3_3;
			}
			.awlist16 > li:before {
				content: '8.' counter(awlistcounter3_1) '.' counter(awlistcounter3_2)
					'.' counter(awlistcounter3_3) '.';
				counter-increment: awlistcounter3_3;
			}
			.awlist17 {
				list-style: none;
				counter-reset: awlistcounter3_0 8;
			}
			.awlist17 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.awlist18 {
				list-style: none;
				counter-reset: awlistcounter3_0 9;
			}
			.awlist18 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.awlist19 {
				list-style: none;
				counter-reset: awlistcounter3_0 10;
			}
			.awlist19 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.awlist20 {
				list-style: none;
				counter-reset: awlistcounter3_0 11;
			}
			.awlist20 > li:before {
				content: counter(awlistcounter3_0) '.';
				counter-increment: awlistcounter3_0;
			}
			.bg {
				background-color: #fcf366;
				background-position: center;
				height: 128px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.paddingDiv {
				display: flex;
					max-width: 100vw;
					flex-direction: column;
					padding: 24px 140px;
			}

			@media (max-width: 1024px){
				.paddingDiv {
					padding: 24px 24px;
			}
		</style>
	</head>
	<body>
		<div style="display: flex; flex-direction: column; max-width: 100vw; overflow: hidden">
			<div
				class="bg"
				style="-aw-headerfooter-type: header-primary; clear: both"
			>
				<p style="text-align: center; font-size: 16pt">
					<span style="font-weight: bold; font-size: 42px; color: #393939"
						>Termos de Uso</span
					>
				</p>
				<p><span style="-aw-import: ignore">&#xa0;</span></p>
			</div>
			<br />
			<div
			class="paddingDiv"
			>
				<span
					style="
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						text-align: center;
						border: 1px solid #5a5a5a;
						border-radius: 16px;
					padding: 16px;

					"
				>
					<h2>Seja bem-vindo(a) a BeeBee, sua solução de entregas!</h2>
					<p>
						Neste documento você saberá como operamos, bem como os direitos e
						obrigações que você <b>Entregador</b> e a <b>BeeBee</b> possuem ao
						utilizar a nossa plataforma. Por esse motivo, todas as informações
						são muito importantes, e pedimos que tenha bastante atenção!
					</p>
					<p>
						Se mesmo após a leitura você estiver com alguma dúvida, entre em
						contato conosco através do e-mail
						<span style="text-decoration: underline; color: blue"
							>suporte@beebee.com.br</span
						>, que teremos o maior prazer em esclarecê-las!
					</p>
					<br />
				</span>
				<br />
				<p style="text-align: justify; widows: 0; orphans: 0">
					<span style="font-weight: bold; background-color: #ffff00"
						>RAZÃO SOCIAL</span
					><span style="background-color: #ffff00"
						>, pessoa jurídica de direito privado, inscrita no CNPJ de nº
						26.032.017/0001-14, estabelecida na Avenida Saturnino Rangel Mauro,
						n° 283 Bairro Pontal de Camburi, Vitória/ES, CEP 29.062-030</span
					><span>, (“</span><span style="font-weight: bold">BeeBee</span
					><span>”), estabelece em conjunto com você </span
					><span style="font-weight: bold">Entregador</span
					><span>
						(que prestará os serviços de entrega diretamente) para os </span
					><span style="font-weight: bold">Usuários/Clientes</span
					><span>, os seguintes </span
					><span style="font-weight: bold">Termos de Uso </span><span>(“</span
					><span style="font-weight: bold">Termo(s)</span
					><span>”), sob as condições abaixo estabelecidas, </span
					><span style="font-weight: bold">CONSIDERANDO QUE</span><span>:</span>
				</p>
				<p style="text-align: justify; widows: 0; orphans: 0">
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol type="a" class="awlist1" style="margin: 0pt; padding-left: 0pt">
					<li
						style="
							margin-left: 36pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 8.34pt;
						"
					>
						<span
							style="
								width: 8.34pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-weight: normal">A </span><span>BeeBee</span
						><span style="font-weight: normal">
							propõe um ambiente virtual que facilite a intermediação e
							aproximação de negócios entre usuários empresariais
							demandantes/embarcadores (</span
						><span>Usuário/Clientes</span
						><span style="font-weight: normal"
							>), e prestadores autônomos de serviços de frete (</span
						><span>Entregadores</span
						><span style="font-weight: normal">);</span>
					</li>
					<br />
					<li
						style="
							margin-left: 36pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 7.82pt;
						"
					>
						<span
							style="
								width: 7.82pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-weight: normal"
							>Sempre que nos referirmos à “</span
						><span>Você</span
						><span style="font-weight: normal"
							>”, de maneira genérica, trataremos do conjunto de potenciais
							prestadores de serviços que acessam nossa plataforma com o
							objetivo específico de transportar determinada mercadoria;</span
						>
					</li>
					<br />
					<li
						style="
							margin-left: 36pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 9.24pt;
						"
					>
						<span
							style="
								width: 9.24pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-weight: normal"
							>Ao ingressar em nossa solução, ou nela se cadastrar, você declara
							e ratifica que leu, compreendeu e está de acordo com a
							integralidade dos termos aqui estabelecidos, bem como aqueles
							presentes em nossa </span
						><span>Política de Privacidade </span
						><span style="font-weight: normal">[</span
						><a
							href="https://api.beebee.com.br/api/v1/privacidade"
							style="text-decoration: none"
							><span class="Hyperlink" style="font-weight: normal"
								>link</span
							></a
						><span style="font-weight: normal"
							>], e caso você não concorde com alguma regra, ou esteja
							impossibilitado de cumpri-la, você aceita que, infelizmente, não
							poderá utilizar a nossa solução;</span
						>
					</li>
					<br />
					<li
						style="
							margin-left: 36pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 7.82pt;
						"
					>
						<span
							style="
								width: 7.82pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-weight: normal">Para utilizar a plataforma </span
						><span>BeeBee </span
						><span style="font-weight: normal"
							>você precisa atender alguns requisitos, e desde já você garante
							que:</span
						>
					</li>
					<br />
				</ol>
				<ol type="i" class="awlist2" style="margin: 0pt; padding-left: 0pt">
					<li
						style="
							margin-left: 63.8pt;
							text-indent: -24.5pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 14.47pt;
						"
					>
						<span
							style="
								width: 14.47pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span>Possui 18 (dezoito) anos ou mais; </span>
					</li>
					<br />
					<li
						style="
							margin-left: 63.8pt;
							text-indent: -24.5pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 11.71pt;
						"
					>
						<span
							style="
								width: 11.71pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span>Não está legalmente impedido de celebrar um contrato; </span>
					</li>
					<br />
					<li
						style="
							margin-left: 63.8pt;
							text-indent: -24.5pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 8.96pt;
						"
					>
						<span
							style="
								width: 8.96pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span
							>Possui carteira de motorista válida e adequada à categoria de
							veículo que dirige;</span
						>
					</li>
					<br />
					<li
						style="
							margin-left: 63.8pt;
							text-indent: -24.5pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 9.05pt;
						"
					>
						<span
							style="
								width: 9.05pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span
							>Possui um computador e/ou um smartphone com sistema operacional
							iOS ou Android, com acesso à internet banda larga, 4G ou 5G</span
						>
					</li>
					<br />
					<li
						style="
							margin-left: 63.8pt;
							text-indent: -24.5pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 11.8pt;
						"
					>
						<span
							style="
								width: 11.8pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span
							>Irá encaminhar somente informações precisas e completas, devendo
							corrigi-las sempre que ocorram alterações, ou seja constatado
							algum equívoco.</span
						>
					</li>
					<br />
				</ol>
				<p style="text-align: justify; widows: 0; orphans: 0">
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol type="1" class="awlist3" style="margin: 0pt; padding-left: 0pt">
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 8.71pt;
						"
					>
						<span
							style="
								width: 8.71pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-size: 28px; color: #393939">DEFINIÇÕES</span
						><br /><br /><span style="font-weight: normal"
							>Para facilitar sua leitura e compreensão, vamos listar abaixo os
							significados de algumas palavras que constam nesse </span
						><span>Termo</span
						><span style="font-weight: normal"
							>, para quando você as encontrar, possa saber que é sobre isso que
							estamos falando, e para ficar mais fácil de consultá-los, sempre
							que achar necessário.</span
						>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span style="font-weight: bold">Concordo</span
								><span>: É o ato de o </span
								><span style="font-weight: bold">Usuário</span><span> ou </span
								><span style="font-weight: bold">Entregador </span
								><span
									>clicar na caixa “Li e aceito os Termos de Uso e Política de
									Privacidade” dispostos no site ou em nosso aplicativo. Tal ato
									implica na confirmação do seu consentimento prévio, expresso e
									informado, em relação a todo o disposto em ambos os
									documentos, abrangendo, especialmente, a solicitação e a
									concessão de autorização para a constituição e armazenamento
									de base de dados composta por dados pessoais, entre outras
									informações;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span style="font-weight: bold"
									>Solução/Plataforma/Aplicativo/Site</span
								><span
									>: Refere-se ao nosso software, que poderá ser utilizado por
									meio de nosso website ou aplicativo, os quais foram adaptados
									e desenvolvidos para operar em browsers, telefones celulares e
									tablets que tenham o sistema operacional iOS ou Android, com
									acesso à internet banda larga, 4G ou 5G;
								</span>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span style="font-weight: bold">Conta</span
								><span
									>: Corresponde a uma conta para utilização da plataforma, por
									meio da qual o </span
								><span style="font-weight: bold">Entregador</span
								><span> poderá transportar determinada mercadoria;</span>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span style="font-weight: bold">Dados Pessoais</span
								><span
									>: Correspondem a todo e quaisquer dados disponibilizados pelo </span
								><span style="font-weight: bold">Usuário </span
								><span>e/ou pelo </span
								><span style="font-weight: bold">Entregador</span
								><span
									>, que de alguma forma identifiquem pessoas físicas, como, por
									exemplo: Nome, endereço, CPF, número de telefone, endereço de
									e-mail, ou quaisquer outras informações solicitadas pelas
									regulamentações aplicáveis;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span style="font-weight: bold"
									>Malware e/ou Práticas Nocivas</span
								><span
									>: Software malicioso e/ou a prática de atividades nocivas a
									qualquer pessoa ou entidade. São exemplos de Malwares os
									worms, Cavalos de Troia e demais tipos de vírus; e são
									exemplos de práticas nocivas e indesejadas os exploits,
									spamming, flooding, spoofing, crashing e root kits;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span style="font-weight: bold">Usuário/Cliente</span
								><span
									>: Toda e qualquer pessoa jurídica por intermédio de
									representante legal devidamente habilitada, com plena
									capacidade de contratar, que acesse nosso website ou
									aplicativo e realize o seu cadastro, de modo a usufruir das
									funcionalidades oferecidas pela </span
								><span style="font-weight: bold">BeeBee</span
								><span>, aceitando, assim, as condições do presente </span
								><span style="font-weight: bold">Termo</span><span>;</span>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span style="font-weight: bold">Entregador</span
								><span
									>: Toda e qualquer pessoa jurídica por intermédio de
									representante legal devidamente habilitada, com plena
									capacidade de contratar, que acesse nosso website ou
									aplicativo e realize o seu cadastro, de modo a prestar os
									serviços de entrega e transporte de mercadorias requeridos
									pelos </span
								><span style="font-weight: bold">Usuários/Clientes</span
								><span>, aceitando, assim, as condições do presente </span
								><span style="font-weight: bold">Termo</span><span>.</span>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<p style="text-align: justify; widows: 0; orphans: 0">
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol
					start="2"
					type="1"
					class="awlist5"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 8.71pt;
						"
					>
						<span
							style="
								width: 8.71pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-size: 28px; color: #393939"
							>ADESÃO E MODIFICAÇÃO DO TERMO</span
						><br /><br /><span style="font-weight: normal"
							>Para começar a utilizar a plataforma </span
						><span>BeeBee</span><span style="font-weight: normal">, o </span
						><span>Entregador </span
						><span style="font-weight: normal"
							>deverá fazer o seu download no Google Play ou App Store conforme
							sistema operacional de seu smartphone, ou ir até o nosso site,
							para realizar seu cadastro, preenchendo os dados solicitados e
							seguindo as instruções, concluindo, ao final, com o aceite este </span
						><span>Termo de Uso </span
						><span style="font-weight: normal">e da nossa </span
						><span>Política de Privacidade</span
						><span style="font-weight: normal"
							>, após o qual você receberá no e-mail indicado as informações
							referentes à aprovação do seu cadastro.</span
						>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span>Mas antes de utilizar a plataforma </span
								><span style="font-weight: bold">BeeBee</span
								><span
									>, você entende e concorda que é obrigatório a leitura do
									presente </span
								><span style="font-weight: bold">Termo</span
								><span>, bem como de nossa </span
								><span style="font-weight: bold">Política de Privacidade</span
								><span
									>, e declara, por meio do seu aceite, que leu, aceitou, sem
									qualquer objeção, todas as disposições constantes nestes
									documentos;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span
									>Estes Termos podem ser revisados de tempos em tempos para
									melhor refletir: (a) mudanças na legislação; (b) novos
									requisitos regulamentares, ou; (c) melhorias ou aprimoramentos
									feitos em nossa solução;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span
									>Caso uma atualização afete seu uso ou seus direitos legais
									como usuário, vamos notificá-lo com antecedência da data de
									efetivação da atualização, entrando em contato pelo endereço
									de e-mail associado à sua conta ou por uma notificação direta.
									Todavia, esta liberalidade não afasta sua responsabilidade de
									verificar periodicamente estes Termos e Política de Uso e de
									Privacidade;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span
									>Caso você não concorde com as atualizações que fizermos,
									cancele sua conta antes da efetivação. Quando aplicável,
									especificamente no caso de alguma funcionalidade paga, mas não
									disponível por problemas alheios à sua vontade, a </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									lhe oferecerá um reembolso proporcional com base nas quantias
									pagas anteriormente, na data de cancelamento de sua conta.
									Caso você continue a acessar ou usar nossa solução após as
									atualizações entrarem em vigor, fica entendido que você
									concorda em seguir os Termos revisados;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span
									>Para qualquer assunto relacionado às condições estabelecidas
									nestes documentos, bem como à utilização do nosso site ou
									aplicativo, você deverá entrar em contato, exclusivamente, por
									meio dos nossos canais oficiais de comunicação.</span
								>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<p style="text-align: justify; widows: 0; orphans: 0">
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol
					start="3"
					type="1"
					class="awlist6"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 8.71pt;
						"
					>
						<span
							style="
								width: 8.71pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-size: 28px; color: #393939">CADASTRAMENTO</span
						><br /><br /><span style="font-weight: normal"
							>Você não poderá ter acesso às funcionalidades da plataforma </span
						><span>BeeBee</span
						><span style="font-weight: normal">
							sem se cadastrar, portanto, você se compromete em fornecer todas
							as informações precisas e completas, e a manter essas informações
							exatas e atualizadas.
						</span>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span style="font-weight: bold"
									>Informações cadastrais do Entregador</span
								><span
									>: (i) Razão Social; (ii) CNPJ; (iii) Nome Completo , CPF e
									CNH; (iv) Endereço e; (v) Telefones de Contato; (vi) E-mails,
									e; (vii) Cópia das Documentações;</span
								><br /><span style="font-weight: bold"
									>Informações sensíveis</span
								><span>: A </span><span style="font-weight: bold">BeeBee</span
								><span>
									coleta dados sensíveis, como, por exemplo, foto e imagem.
									Essas informações são essenciais para cumprir etapas de
									segurança e confirmação da identidade. Elas serão tratadas com
									o nível de segurança e sigilo adequados para atender sua
									exclusiva finalidade. Ao fazer o upload, tirar voluntariamente
									uma foto </span
								><span style="font-style: italic">selfie</span
								><span>, você consente expressa e especificamente que a </span
								><span style="font-weight: bold">BeeBee</span
								><span> utilize seus dados sensíveis;</span>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span style="font-weight: bold"
									>Informações coletadas pela navegação</span
								><span
									>: (i) Características do dispositivo de acesso e do
									navegador, informações de bloqueio e desbloqueio; (ii)
									Protocolo de Internet com data, hora e origem; (iii)
									Informações sobre páginas acessadas e cliques, hábitos de
									navegação; (iv) A página seguinte, acessada após encerrar a
									sessão do site e/ou aplicativo; (v) Termos digitados no site
									e/ou aplicativo; (vi) Geolocalização; (vii) Dados do perfil de
									redes sociais, e; (viii) Sinais de Wi-Fi e de rede;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span
									>A criação de um nome vulgar, obsceno, ou de terceiro,
									constituirá uma violação destes </span
								><span style="font-weight: bold">Termos</span
								><span
									>, que pode resultar na rescisão imediata de sua conta, o que
									não nos exime de ingressar administrativamente ou em juízo,
									por qualquer ato lesivo ou contrário a Lei vigente em nosso
									país;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span>A </span><span style="font-weight: bold">BeeBee </span
								><span
									>poderá revalidar periodicamente os documentos e informações
									constantes do cadastro. Nestes casos, se for constatada
									qualquer inconsistência, a </span
								><span style="font-weight: bold">BeeBee </span
								><span
									>poderá solicitar a retificação ou complementação das
									informações e/ou documentos necessários ou, ainda, suspender
									temporariamente ou definitivamente seu cadastro, por motivos
									de segurança;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span>A </span><span style="font-weight: bold">BeeBee </span
								><span
									>se reserva no direito de recusar o registro ou cancelar um
									cadastro a seu exclusivo critério, sendo você único
									responsável pela atividade que ocorre em sua conta, e por
									manter a confidencialidade de sua senha;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span
									>Você jamais deverá usar a conta de outro usuário, e deverá
									avisar a </span
								><span style="font-weight: bold">BeeBee </span
								><span
									>imediatamente, de qualquer uso não autorizado de sua conta,
									ou violação de segurança da conta da qual você tenha
									conhecimento;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span
									>Você representa e garante que, se for pessoa física, possui
									maioridade para firmar um contrato vinculativo, ou se estiver
									efetuando um cadastro em nome de uma entidade, você está
									autorizado a firmar e vincular a mesmo nos </span
								><span style="font-weight: bold">Termos</span
								><span> aqui presentes; </span>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span>A </span><span style="font-weight: bold">BeeBee </span
								><span
									>pode, a seu exclusivo critério, recusar a oferecer
									determinada funcionalidade a qualquer usuário ou entidade,
									assim como pode mudar seu critério de elegibilidade a qualquer
									momento;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span>Você é</span><span style="font-weight: bold"> </span
								><span>unicamente responsável por garantir que este </span
								><span style="font-weight: bold">Termo </span
								><span
									>esteja de acordo com todas as leis, regras e regulamentos
									aplicáveis a ele e que o direito de acesso à solução é
									revogado onde estes termos de uso são proibidos e, em tais
									circunstâncias, você concorda em não usar ou acessar nossa
									plataforma, aplicativo e/ou site.</span
								>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<p style="text-align: justify; widows: 0; orphans: 0">
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol
					start="4"
					type="1"
					class="awlist7"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 8.71pt;
						"
					>
						<span
							style="
								width: 8.71pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-size: 28px; color: #393939">PLATAFORMA</span
						><br /><br /><span style="font-weight: normal">A </span
						><span>BeeBee</span
						><span style="font-weight: normal">
							é uma plataforma online que promove a intermediação entre o </span
						><span>Usuário/Cliente</span
						><span style="font-weight: normal"
							>, pessoa jurídica, que quer receber ou enviar determinada
							mercadoria, e o </span
						><span>Entregador</span
						><span style="font-weight: normal"
							>, pessoa jurídica, que quer transportá-la.</span
						>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span>A </span><span style="font-weight: bold">BeeBee</span
								><span>
									tem orgulho da confiança que nossos usuários depositam em nós.
									Em troca, confiamos que você usará nossos serviços com
									responsabilidade. Nós disponibilizamos um ambiente virtual
									(site e aplicativos) cuja finalidade é intermediar a prestação
									de serviços de frete e outros serviços adjuntos a entrega
									como, por exemplo, pagamento de contas, recolhimento de
									assinaturas, compra para o cliente, dentre outros, que serão
									demandamos pelos </span
								><span style="font-weight: bold">Usuários/Clientes</span
								><span>
									aos Entregadores (prestadores de serviços autônomos e/ou
									empresas que prestem tal modalidade de serviço);
								</span>
								<ol
									type="1"
									class="awlist8"
									style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
								>
									<br />
									<li
										style="
											margin-left: 24.2pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span
											>As características básicas desse ambiente virtual são:
											(a) Disponibilização de plataforma tecnológica para
											intermediação dos serviços de frete; (b) Agenciamento,
											recebimento por conta e ordem de terceiros; (c) Cobrança e
											gestão de pagamentos a serem realizados pelos </span
										><span style="font-weight: bold">Usuários/Clientes</span
										><span> aos </span
										><span style="font-weight: bold">Entregadores</span
										><span
											>; (d) Gestão, recebimento, desconto e pagamento, e; (e)
											Mecanismo de avaliação de desempenho recíproca;</span
										>
									</li>
									<br />
								</ol>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span>A </span><span style="font-weight: bold">BeeBee</span
								><span>
									se compromete em fornecer seu software de forma séria, dentro
									dos primados da boa-fé negocial, da livre-iniciativa,
									respeitando os princípios da leal concorrência, todos
									basilares ao Estado Democrático de Direito do qual esse faz
									parte;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span
									>Por isso, sempre que houver qualquer erro no funcionamento da
									solução, nós nos comprometemos com nossos usuários e/ou
									associados por adotar as medidas razoáveis, o mais breve
									possível;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span>A </span><span style="font-weight: bold">BeeBee</span
								><span
									>, no intuito de oferecer a você uma solução satisfatória,
									resguarda para si o direito de </span
								><span style="font-weight: bold"
									>ALTERAR, A QUALQUER MOMENTO</span
								><span
									>, suas configurações, eliminando ou modificando seu banco de
									dados, recursos disponíveis, regulamentos e contratos, bem
									como as disposições deste </span
								><span style="font-weight: bold">Termo</span><span>;</span>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span
									>É razoável, para ambas as partes, que é tecnicamente
									improvável um software ser totalmente isento de erros e,
									partindo de tal premissa, quando da ocorrência de qualquer
									erro no sistema objeto do presente instrumento, você deverá
									oportunizar a resolução de tal erro, antes que lhe seja
									atribuída qualquer responsabilidade legal, contratual,
									judicial ou extrajudicial, ou que se lhe requeira qualquer
									ressarcimento por perdas e danos e/ou indenização de qualquer
									tipo ou monta;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span>A </span><span style="font-weight: bold">BeeBee </span
								><span
									>afirma e enfatiza que não é um prestador de serviços de
									transporte de cargas e serviços de frete e que, portanto, não
									se sujeita às responsabilidades e deveres inerentes a esse
									tipo de atividade, uma vez que, como já foi dito, nós
									disponibilizamos somente uma plataforma web;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span>A </span><span style="font-weight: bold">BeeBee</span
								><span> informa a você, que qualquer relação entre o </span
								><span style="font-weight: bold">Usuário/Cliente</span
								><span> e o </span
								><span style="font-weight: bold">Entregador</span
								><span
									>, não está sujeita à sua responsabilização e/ou moderação, e
									que, portanto, </span
								><span style="font-weight: bold"
									>A BEEBEE NÃO SE RESPONSABILIZA</span
								><span>
									pela legalidade dessas possíveis relações, bem como a toda e
									qualquer lide que derive ou possa vir a ser derivada das
									mesmas;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span
									>Desde já as partes reconhecem que o presente instrumento não
									constitui, no todo ou em parte, contrato de prestação de
									serviço ou de grupo econômico, tão pouco cria qualquer vínculo
									empregatício entre nenhuma das partes, bem como não assumem,
									todos, por força deste contrato, expressa ou implicitamente,
									qualquer responsabilidade mercantil, tributária, fiscal ou
									previdenciária, durante ou após a utilização de nossa
									plataforma;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span>Ratificamos que a </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									atua como intermediária facilitadora entre os atores dos
									serviços de frete – o demandante e o prestador do serviço –,
									de forma que, não há que se reclamar nossa responsabilidade
									por eventuais desvios ou prejuízos decorrentes da efetiva
									prestação dos mesmos, ou mesmo pela conduta das partes.
								</span>
								<ol
									type="1"
									class="awlist8"
									style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
								>
									<br />
									<li
										style="
											margin-left: 21.25pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span
											>Nós nos limitamos a facilitar o estabelecimento de
											vínculo entre as partes para que o serviço de frete possa
											ser realizado. Os </span
										><span style="font-weight: bold">Entregadores</span
										><span>
											que disponibilizam seus serviços através de nossa
											plataforma não possuem qualquer vínculo empregatício,
											hierárquico, dependência ou de subordinação à </span
										><span style="font-weight: bold">BeeBee</span
										><span
											>, inclusive podendo livremente prestar serviços fora da
											nossa plataforma, para quem desejar, não havendo
											exclusividade, atuando por conta própria e utilizando seus
											próprios recursos (veículos, acessórios, serviços
											complementares, telefones celulares e serviços de
											comunicação, entre outros) para prover os serviços de
											frete;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 21.25pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span>O </span
										><span style="font-weight: bold">Usuário/Cliente</span
										><span>
											terá a opção de avaliar a qualidade dos serviços prestados
											diretamente em nossa plataforma, a qual permitirá, ainda,
											a inserção de comentários acerca dos mesmos, e a </span
										><span style="font-weight: bold">Beebee</span
										><span>
											considerará a desativação e/ou exclusão do cadastro de </span
										><span style="font-weight: bold">Entregadores</span
										><span>
											que tiverem notas inferiores à 3.5, mediante procedimento
											interno a ser comunicado entre esses;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 21.25pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span
											>Apesar de nossas políticas de monitoramento de satisfação
											e controle de qualidade, somente podemos implementar
											medidas corretivas a partir de indicadores de avaliação
											e/ou operacionais informados na plataforma e em nosso
											Serviço de Relacionamento por nossos </span
										><span style="font-weight: bold">Usuários/Clientes</span
										><span
											>, indicadores esses que nos esforçamos para mantê-los
											saudáveis – para o benefício de todos;</span
										>
									</li>
									<br />
								</ol>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span>Este </span><span style="font-weight: bold">Termo</span
								><span>
									não se presta, nem prevê no todo ou em parte, a venda ou
									qualquer tipo de cessão dos direitos patrimoniais da
									plataforma </span
								><span style="font-weight: bold">BeeBee</span
								><span>, mas, sim, à concessão do </span
								><span style="font-weight: bold"
									>Direito de Uso de Licença de Software</span
								><a name="_ftnref1"></a
								><a href="#_ftn1" style="text-decoration: none"
									><span
										style="
											font-size: 8pt;
											vertical-align: super;
											color: #000000;
										"
										>[1]</span
									></a
								><span
									>, nos limites da Legislação Brasileira e do Presente
									Contrato;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span>Portanto, a </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									não se obriga legal ou contratualmente na exclusividade da
									presente licença, podendo ceder livremente, sem que o </span
								><span style="font-weight: bold">Usuário</span
								><span>
									possua qualquer tipo de ingerência sobre tal cessão -
									incluindo, mas não se limitando - à propriedade intelectual,
									industrial, autoral ou qualquer outra que seja ou possa vir a
									ser reconhecida como de direito da </span
								><span style="font-weight: bold">BeeBee</span
								><span> sob o software objeto deste </span
								><span style="font-weight: bold">Termo</span><span>; </span>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span>Você firma o presente </span
								><span style="font-weight: bold">Termo </span
								><span
									>ciente de que nossa solução é acessada através da rede
									mundial de computadores (Internet), e, portanto, é um sistema
									em </span
								><span style="font-weight: bold">Cloud Computing</span
								><a name="_ftnref2"></a
								><a href="#_ftn2" style="text-decoration: none"
									><span
										style="
											font-size: 8pt;
											vertical-align: super;
											color: #000000;
										"
										>[2]</span
									></a
								><span>, de maneira que a </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									não presta serviço de internet banda larga ou móvel; logo a
									esse não poderá ser legado nenhum tipo de responsabilidade
									e/ou dano advindo do não acesso ao sistema por inefetiva e/ou
									má prestação daquele serviço.</span
								>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<p style="text-align: justify">
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol
					start="5"
					type="1"
					class="awlist9"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 8.71pt;
						"
					>
						<span
							style="
								width: 8.71pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-size: 28px; color: #393939"
							>MODALIDADES, UTILIZAÇÃO E PAGAMENTOS</span
						><br /><br /><span style="font-weight: normal">Atualmente, a </span
						><span>BeeBee</span
						><span style="font-weight: normal">
							possui 2 (duas) modalidades, sendo elas:</span
						>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span style="font-weight: bold">BeeBee Rota Fixa:</span
								><span>&#xa0;</span
								><span
									>Trata-se de um serviço de entrega preferencial, ponto a
									ponto, cuja definição da rota fica a critério do solicitante
									assim como a definição do início do serviço que pode ser
									imediato ou agendado (para data futura) mediante escolha do
									usuário solicitante. O Entregador Profissional será
									selecionado através de aceitação de sua parte, mediante
									despacho automático feito pelo sistema, que busca algum
									entregador interessado em atender o serviço nas proximidades
									do ponto de coleta. O entregador que aceite o pedido realizará
									a entrega/serviço assim como o registro de evidências da mesma
									pelo aplicativo.</span
								>
								<ol
									type="1"
									class="awlist8"
									style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
								>
									<br />
									<li
										style="
											margin-left: 24.2pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span
											>O produto BeeBee Rota Fixa possui precificação variável
											de acordo com variáveis que compõem a calculadora. Para se
											alcançar o valor final, são considerados fatores ligados
											à: (i) tipo de veículo (bandeirada e frete mínimo); (ii)
											quilometragem; (iii) número de pontos de entrega; (iv)
											adicional de espera (em alguns casos); (v) horário e
											trânsito; (vi) condições climáticas; (vii) tempo do
											percurso; (viii) outros adicionais opcionais (quando
											disponível);</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 24.2pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span
											>O preço final é informado no momento do chamado do frete
											levando em conta os fatores mencionados acima.
										</span>
									</li>
									<br />
									<li
										style="
											margin-left: 24.2pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span>O adicional de espera mencionado acima</span
										><span>&#xa0;</span
										><span style="font-weight: bold"
											>pode ou não se aplicar</span
										><span>&#xa0;</span
										><span
											>e está sujeito a análise por parte de nossos operadores.
											Pode se aplicar para: "MOTO" e “MINIVAN” sempre que o
											prazo de 15 e 30 (quinze e trinta minutos) minutos
											(respectivamente) de espera na coleta for ultrapassado;
											ocorrida tal situação, a partir do 16º e 31º (décimo sexto
											ou trigésimo primeiro) minuto poderá ser cobrada uma taxa
											a cada minuto; para: “VAN” e “CAMINHÃO 3/4” sempre que o
											prazo de 45 (quarenta e cinco) minutos de espera em cada
											ponto for ultrapassado; ocorrida tal situação, a partir do
											46º (quadragésimo sexto) minuto, é cobrada uma taxa por
											minuto.</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 24.2pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span
											>Ex.: um CAMINHÃO ¾ chega ao local de coleta às 07:00 am.
											A título de espera gratuita contemplada no frete, há
											tolerância até às 07:45 am. A partir das 07:46am é cobrado
											o adicional de espera a cada minuto.</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 24.2pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span
											>A plataforma gera registro quando tais situações
											ocorrerem. O cliente também poderá ser informado quando
											tais situações acontecerem. Estamos melhorando nossa
											plataforma para que fique cada vez mais transparente e
											automatizada tais cobranças.</span
										>
									</li>
									<br />
								</ol>
							</li>
							<br />
							<li
								style="
									margin-left: 21.6pt;
									text-indent: -21.6pt;
									font-weight: normal;
									-aw-list-padding-sml: 3.38pt;
								"
							>
								<span
									style="
										width: 3.38pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0; </span
								><span style="font-weight: bold">BeeBee Rota Livre:</span
								><span>&#xa0;</span
								><span
									>Trata-se de um serviço de entrega facilitado, cuja definição
									da rota fica a critério do entregador cabendo ao solicitante
									apenas definir o local de coleta e o número de locais de
									destino das mercadorias/serviços. O </span
								><span style="font-weight: bold">Entregador</span
								><span>
									será selecionado através de aceitação de sua parte, mediante
									despacho automático feito pelo sistema, que busca algum </span
								><span style="font-weight: bold">Entregador</span
								><span>
									interessado em atender o serviço, preferencialmente nas
									proximidades do ponto de coleta;</span
								>
								<ol
									type="1"
									class="awlist8"
									style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
								>
									<br />
									<li
										style="
											margin-left: 21.6pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>O produto BeeBee Rota Livre possui precificação variável
											de acordo com variáveis que compõem a calculadora. Para se
											alcançar o valor final, são considerados fatores ligados
											à: (i) tipo de veículo (bandeirada e frete mínimo); (ii)
											quilometragem; (iii) número de pontos de entrega; (iv)
											adicional de espera (em alguns casos); (v) horário e
											trânsito; (vi) condições climáticas; (vii) tempo do
											percurso, e; (viii) outros adicionais opcionais (quando
											disponível).
										</span>
									</li>
									<br />
									<li
										style="
											margin-left: 21.6pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>O preço final é informado ao final do frete levando em
											conta a produtividade do entregador e quilometragem
											percorrida para realização do serviço, dentre outros
											fatores mencionados acima.
										</span>
									</li>
									<br />
									<li
										style="
											margin-left: 21.6pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span>O </span
										><span style="font-weight: bold">Entregador</span
										><span>
											que aceitar o pedido realizará a entrega/serviço assim
											como o registro de evidências da mesma (por meio de foto)
											pelo aplicativo já que nesta modalidade de contratação de
											frete o entregador tem maior liberdade/flexibilidade para
											definir sua rota e em contrapartida registra as evidências
											da realização do serviço em cada ponto.</span
										>
									</li>
									<br />
								</ol>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<p
					style="margin-left: 18pt; text-align: justify; widows: 0; orphans: 0"
				>
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol
					start="6"
					type="1"
					class="awlist10"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 8.71pt;
						"
					>
						<span
							style="
								width: 8.71pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-size: 28px; color: #393939"
							>LIMITAÇÃO DE RESPONSABILIDADE</span
						><br /><br /><span style="font-weight: normal">Estes </span
						><span>Termos</span
						><span style="font-weight: normal">
							apresentam ostensivamente as funcionalidades disponíveis a você,
							suas consequências, limites, formas de interação, aspectos gerais
							e específicos, medidas de segurança e respeito às respectivas
							legislações aplicáveis, motivo pelo qual a </span
						><span>BEEBEE NÃO SE RESPONSABILIZA</span
						><span style="font-weight: normal">:</span>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span
									>Por qualquer informação errônea disponibilizada por todo e
									qualquer </span
								><span style="font-weight: bold">Usuário</span
								><span
									>, que possam ser utilizadas para fins diversos, ou que sejam
									oriundas de mercadorias e/ou serviços negociados com o </span
								><span style="font-weight: bold">Entregador</span
								><span
									>, bem como pelo prazo de entrega, disponibilidade, defeito
									oculto, garantia, descrição inadequada ou o não cumprimento do
									que se pretende a contento, ou ainda por qualquer risco
									inerente ao ambiente digital, qual seja, eventuais crimes
									ocorridos em meio eletrônico;</span
								>
								<br />
								<br />
								<span
									style="
										display: flex;
										flex-direction: column;
										align-items: center;
										justify-content: center;
										text-align: center;
										border: 1px solid #5a5a5a;
										border-radius: 16px;
										width: 50%;
									"
								>
									<h1 style="color: #5a5a5a"><b>Atenção!</b></h1>
									<p style="max-width: 80%">
										Você deve manter sob sua guarda e sigilo as senhas, de forma
										que
										<b> A BEEBEE NÃO SERÁ, EM HIPÓTESE ALGUMA, RESPONSÁVEL</b>
										por quaisquer prejuízos causados a você ou a terceiros, que
										sejam decorrentes de sua divulgação e utilização indevidas
									</p>
									<br />
								</span>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span
									>Por quaisquer danos, prejuízos ou perdas, de qualquer forma
									causados, inclusive decorrentes de caso fortuito ou força
									maior, por falhas na plataforma, no servidor, na internet e
									por quaisquer vírus, cavalos de tróia, </span
								><span style="font-style: italic">worms</span
								><span>
									ou outras rotinas de programas de computador que possam
									danificar, interferir adversamente, e que possam ser
									infiltrados nos seus dispositivos, em decorrência do acesso ou
									como consequência da transferência de dados, arquivos,
									imagens, textos ou quaisquer
								</span>
								<span>outros conteúdos; </span>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span
									>Por quaisquer danos, punitivos, incidentais, especiais ou
									emergentes indireta ou diretamente causados ao ou pelo
									dispositivo eletrônico do usuário, incluindo, sem limitação,
									danos por perda de uso, dados ou lucros, decorrentes ou de
									qualquer forma relacionados com o uso ou desempenho de nossa
									plataforma, aplicativo e/ou site;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span
									>Por qualquer conteúdo de cunho racista, sexista, antissemita,
									homofóbico ou que envolva de maneira direta ou indireta,
									expressões preconceituosas, que possam dar azo a qualquer tipo
									de calúnia, injúria ou difamação, eventualmente publicadas
									pelos </span
								><span style="font-weight: bold">Usuários</span
								><span> e/ou </span
								><span style="font-weight: bold">Entregadores</span
								><span> em qualquer canal on-line, mídias sociais, </span
								><span style="font-style: italic">blogs</span><span>, </span
								><span style="font-style: italic">vlogs</span><span>, </span
								><span style="font-style: italic">podcasts</span
								><span> e outros;</span>
								<ol
									type="1"
									class="awlist8"
									style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
								>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span>O </span
										><span style="font-weight: bold">Cliente/Usuário</span
										><span> e/ou </span
										><span style="font-weight: bold">Entregador</span
										><span>
											que se sentir diretamente prejudicado na sua esfera
											pessoal em razão de qualquer comentário publicado e/ou
											acessível pela plataforma, poderá requerer da </span
										><span style="font-weight: bold">BeeBee</span
										><span>
											sua exclusão, a qual será precedida, internamente, de uma
											análise jurídica e informada à parte que a inseriu;</span
										>
									</li>
									<br />
								</ol>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span
									>Por qualquer utilização incorreta, ou correta, mas sem o
									devido zelo, das funcionalidades de nossa solução, através de
									qualquer </span
								><span style="font-style: italic">device</span
								><span>
									que permitam sua execução, inclusive perante as informações
									ali cadastradas, e que possam ser subtraídas por qualquer
									meio, por consequência de tais práticas;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span
									>Todo e qualquer conteúdo de terceiros, seja ele veiculado
									publicamente ou transmitido privativamente. A </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									não pode garantir a autenticidade de qualquer conteúdo ou
									dados que vocês fornecem sobre si mesmos, sobre as mercadorias
									ou serviços disponibilizados na plataforma;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span>Para fins deste </span
								><span style="font-weight: bold">Termo</span
								><span
									>, a expressão “conteúdo” inclui, sem limitação, qualquer
									informação sobre a localização, vídeos, clipes de áudio,
									comentários, informações, dados, textos, fotografias,
									software, script’s, gráficos e recursos interativos gerados,
									fornecidos ou tornados acessíveis de outra forma no ambiente
									da solução da </span
								><span style="font-weight: bold">BeeBee</span><span>.</span>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<p style="text-align: justify">
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol
					start="7"
					type="1"
					class="awlist11"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							font-weight: bold;
							-aw-list-padding-sml: 8.71pt;
						"
					>
						<span
							style="
								width: 8.71pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-size: 28px; color: #393939"
							>OBRIGAÇÕES E RESPONSABILIDADES DO ENTREGADOR</span
						><br /><br /><span style="font-weight: normal"></span><span> </span
						><span style="font-weight: normal">O</span><span> Entregador </span
						><span style="font-weight: normal"
							>é o único responsável pelo adequado uso da </span
						><span>BeeBee</span
						><span style="font-weight: normal"
							>, os quais não poderão responsabilizar a </span
						><span>BeeBee </span
						><span style="font-weight: normal"
							>pelo uso incorreto e/ou indevido do mesmo, bem como por quaisquer
							danos, prejuízos, responsabilização, reclamações, processos,
							perdas, demandas ou despesas, incluindo, mas não se limitando, aos
							honorários advocatícios, custas judiciais e ônus de sucumbência
							dali decorrentes, </span
						><span
							>principalmente nos casos em que forem constatados que tais
							ocorrências se deram por culpa exclusiva do Entregador</span
						><span style="font-weight: normal">, ademais:</span>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span style="font-weight: bold"
									>O ENTREGADOR DESDE JÁ SE RESPONSABILIZA</span
								><span
									>: (a) Por todas as informações fornecidas como sendo
									verdadeiras e exatas; (b) Em não permitir que terceiros
									utilizem sua conta; (c) Em não divulgar sua senha, ou
									informações relativas à sua conta, bem como pelas
									consequências decorrentes do seu acesso e utilização da nossa
									solução – com ou sem seu conhecimento ou aprovação; (d) Por
									não utilizar nossos meios de comunicação e soluções para
									transmitir, divulgar ou transportar material ilícito, proibido
									ou difamatório, que viole a privacidade ou direito de
									terceiros (incluindo os de propriedade intelectual, autoral,
									industrial, dentre outros), ou que seja abusivo, ameaçador,
									discriminatório, injurioso ou calunioso, que incentive
									discriminação ou violência, e; (e) Por notificar imediatamente
									a </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									sobre qualquer uso não autorizado da sua senha ou qualquer
									quebra de segurança de que tome conhecimento, por meio dos
									nossos canais de comunicação;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span style="font-weight: bold">Operações</span
								><span>: Toda e qualquer operação realizada pelo </span
								><span style="font-weight: bold">Entregador</span
								><span>, através do plataforma </span
								><span style="font-weight: bold">BeeBee</span><span>, </span
								><span style="font-weight: bold"
									>DEVERÁ SER CUIDADOSAMENTE ANALISADA PELO MESMO</span
								><span>, uma vez que </span
								><span style="font-weight: bold"
									>A BEEBEE CONTA COM SISTEMAS DE SEGURANÇA DA INFORMAÇÃO COM
									TECNOLOGIA AVANÇADA E NOS PADRÕES REQUERIDOS PELO
									MERCADO</span
								><span
									>, de forma que toda e qualquer operação financeira, seja de
									contratação, pagamentos, transferências, dentre outras,
									contará com a confirmação de segurança de senhas e demais
									informações de acesso, as quais você entende, concorda e
									ratifica, desde já, que se comprovada como oriundas da
									legítima utilização, </span
								><span style="font-weight: bold"
									>SERÃO DE INTEIRA E EXCLUSIVA RESPONSABILIDADE DO
									ENTREGADOR</span
								><span
									>, não cabendo seu ressarcimento por qualquer motivo;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span style="font-weight: bold">Canais de Comunicação</span
								><span>: Os canais de comunicação oficial utilizados pela </span
								><span style="font-weight: bold">BeeBee</span
								><span> são aqueles definidos no item 7.4., de forma que </span
								><span style="font-weight: bold">VOCÊ NÃO DEVE RESPONDER</span
								><span>
									a qualquer tipo de solicitação de envio de dados pessoais, de
									sua conta na </span
								><span style="font-weight: bold">BeeBee</span
								><span
									>, número de cartão, token, senha e outros que permitam sua
									utilização em nossa plataforma ou qualquer outra, sejam esses
									requeridos fora dos nossos canais oficiais,
									responsabilizando-se o </span
								><span style="font-weight: bold">Entregador</span
								><span
									>, única e exclusivamente pelos danos dali decorrentes;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span style="font-weight: bold"
									>Identificação dos Canais Oficiais</span
								><span>: Nosso site oficial inicia com </span
								><span style="font-weight: bold">HTTPS</span
								><span
									>, que representa um protocolo de transferência de informações
									seguras com nossos </span
								><span style="font-weight: bold">Usuários</span
								><span>, e nosso endereço web é </span
								><span style="font-weight: bold">https://beebee.com.br</span
								><span>, de forma que </span
								><span style="font-weight: bold">NENHUMA VARIAÇÃO</span
								><span>
									do mesmo é considerada válida, e nossos canais oficiais podem
									ser encontradas nos seguintes endereços:</span
								>
								<ol
									type="1"
									class="awlist8"
									style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
								>
									<br />
									<li
										style="
											margin-left: 42.5pt;
											text-indent: -39.35pt;
											-aw-list-padding-sml: 12.02pt;
										"
									>
										<span
											style="
												width: 12.02pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span style="font-weight: bold">Facebook</span
										><span>: https://www.facebook.com/beebeeoficial;</span>
									</li>
									<br />
									<li
										style="
											margin-left: 42.5pt;
											text-indent: -39.35pt;
											-aw-list-padding-sml: 12.02pt;
										"
									>
										<span
											style="
												width: 12.02pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span style="font-weight: bold">Instagram</span
										><span>: </span
										><span style="background-color: #ffffff"
											>@beebeeoficial</span
										><span>;</span>
									</li>
									<br />
									<li
										style="
											margin-left: 42.5pt;
											text-indent: -39.35pt;
											-aw-list-padding-sml: 12.02pt;
										"
									>
										<span
											style="
												width: 12.02pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span style="font-weight: bold">LinkedIn</span
										><span
											>: https://www.linkedin.com/company/beebeeoficial/;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 42.5pt;
											text-indent: -39.35pt;
											-aw-list-padding-sml: 12.02pt;
										"
									>
										<span
											style="
												width: 12.02pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span style="font-weight: bold">E-mail</span
										><span>: suporte@beebee.com.br;</span>
									</li>
									<br />
									<li
										style="
											margin-left: 42.5pt;
											text-indent: -39.35pt;
											-aw-list-padding-sml: 12.02pt;
										"
									>
										<span
											style="
												width: 12.02pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span style="font-weight: bold">Telefone/WhatsApp</span
										><span>: +55 (27) 3180-0851.</span>
									</li>
									<br />
								</ol>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<p style="text-align: justify; widows: 0; orphans: 0">
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol
					start="8"
					type="1"
					class="awlist12"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 8.71pt;
						"
					>
						<span
							style="
								width: 8.71pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-size: 28px; color: #393939"
							>USO DA PLATAFORMA PELO ENTREGADOR</span
						><br /><br /><span style="font-weight: normal"
							>Ao aderir à Plataforma, os </span
						><span>Entregadores</span
						><span style="font-weight: normal">
							automaticamente declaram e reconhecem que são autônomos, sem
							qualquer vínculo de qualquer natureza com a </span
						><span>BeeBee</span
						><span style="font-weight: normal"> e/ou com os </span
						><span>Usuários/Clientes</span
						><span style="font-weight: normal">
							que contratam serviços através da plataforma.
						</span>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span
									>Contamos com sua colaboração e transparência quanto à
									veracidade e regularidade dos documentos e informações que
									você insere em nossa plataforma. Podemos transmitir ou
									disponibilizar informações de sua conta para terceiros, como
									forma de assegurar uma prestação de serviços mais precisa e
									conveniente às partes;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span
									>Estas informações podem se referir a alguns dados pessoais,
									fotos, dados e informações de veículos, número de telefone,
									e-mail, número da CNH e RG, ou outras consideradas como
									imprescindíveis para a prestação do serviço. Asseguramos que
									no âmbito da </span
								><span style="font-weight: bold">BeeBee</span
								><span
									>, utilizaremos seus dados e informações somente para cumprir
									com a finalidade original da nossa plataforma. Orientamos
									nossos parceiros a proceder da mesma forma, mas não podemos
									assegurar que assim o façam;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span>A </span><span style="font-weight: bold">BeeBee</span
								><span>
									não pode garantir a veracidade das informações fornecidas pelo </span
								><span style="font-weight: bold">Usuário/Cliente</span
								><span> e/ou </span
								><span style="font-weight: bold">Entregador</span
								><span>
									uma vez que foram fornecidas pelo próprio, mas se dispõe a
									analisá-las formalmente visando a qualidade do serviço e o
									respeito a legislação;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span>De forma a usufruir das nossas funcionalidades, o </span
								><span style="font-weight: bold">Entregador</span
								><span>
									deverá realizar seu cadastro em nossa plataforma e configurar
									seu perfil segundo sua necessidade. Neste tópico descrevemos
									algumas especificidades referentes ao </span
								><span style="font-weight: bold">Entregador</span
								><span
									>. Ressaltamos que o cadastro em nossa plataforma não gera
									qualquer ônus, mas para utilizá-la, solicitaremos sua
									identificação mediante usuário e senha. Apenas são passíveis
									de cobrança pedidos efetivados através da nossa
									plataforma;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span>O </span><span style="font-weight: bold">Entregador</span
								><span>
									deverá disponibilizar na plataforma os seguintes documentos,
									de forma a comprovar sua regularidade, na forma atualmente
									exigida pela legislação vigente:
								</span>
								<ol
									type="1"
									class="awlist8"
									style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
								>
									<br />
									<li
										style="
											margin-left: 21.25pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span
											>Se Motociclista : CNPJ válido em nome próprio, ou de
											sociedade da qual faça parte; motocicleta devidamente
											adequada para o transporte de produtos conforme
											regulamentado pela Lei 12.009/2009; CNH; CRLV do veículo
											em seu nome ou contrato de arrendamento no nome do
											entregador com o proprietário do veículo;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 21.25pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span
											>Se Motorista (Carro/Minivan/Caminhão): CNPJ válido em
											nome próprio, ou de sociedade da qual faça parte; CNH
											constando “apto a atividade remunerada”; CRVL do veículo
											em seu nome ou contrato de arrendamento no nome do
											entregador com o proprietário do veículo;
										</span>
									</li>
									<br />
									<li
										style="
											margin-left: 21.25pt;
											text-indent: -27.8pt;
											-aw-list-padding-sml: 0.47pt;
										"
									>
										<span
											style="
												width: 0.47pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span>Os CNAES aceitos são:</span>
									</li>
									<br />
								</ol>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<ul type="disc" style="margin: 0pt; padding-left: 0pt">
					<li
						class="ListParagraph"
						style="
							margin-left: 84.67pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							padding-left: 7.48pt;
							font-family: serif;
							-aw-font-family: 'Symbol';
							-aw-font-weight: normal;
							-aw-number-format: '';
						"
					>
						<span style="font-family: Calibri"
							>4930-2/01: Transporte rodoviário de carga, exceto produtos
							perigosos e mudanças, municipal;</span
						>
					</li>
					<br />
					<li
						class="ListParagraph"
						style="
							margin-left: 84.67pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							padding-left: 7.48pt;
							font-family: serif;
							-aw-font-family: 'Symbol';
							-aw-font-weight: normal;
							-aw-number-format: '';
						"
					>
						<span style="font-family: Calibri"
							>4930-2/02: Transporte rodoviário de carga, exceto produtos
							perigosos e mudanças, intermunicipal, interestadual e
							internacional;</span
						>
					</li>
					<br />
					<li
						class="ListParagraph"
						style="
							margin-left: 84.67pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							padding-left: 7.48pt;
							font-family: serif;
							-aw-font-family: 'Symbol';
							-aw-font-weight: normal;
							-aw-number-format: '';
						"
					>
						<span style="font-family: Calibri"
							>5229-0/99: Outras atividades auxiliares dos transportes
							terrestres não especificadas anteriormente;</span
						>
					</li>
					<br />
					<li
						class="ListParagraph"
						style="
							margin-left: 84.67pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							padding-left: 7.48pt;
							font-family: serif;
							-aw-font-family: 'Symbol';
							-aw-font-weight: normal;
							-aw-number-format: '';
						"
					>
						<span style="font-family: Calibri"
							>5320-2/01: Serviços de malote não realizados pelo correio
							nacional;</span
						>
					</li>
					<br />
					<li
						class="ListParagraph"
						style="
							margin-left: 84.67pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							padding-left: 7.48pt;
							font-family: serif;
							-aw-font-family: 'Symbol';
							-aw-font-weight: normal;
							-aw-number-format: '';
						"
					>
						<span style="font-family: Calibri"
							>5320-2/02: Serviços de entrega rápida.</span
						>
						<ol
							start="4"
							type="1"
							class="awlist13"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: -28.35pt;
									text-indent: -27.8pt;
									font-family: Calibri;
									-aw-list-padding-sml: 0.47pt;
								"
							>
								<span
									style="
										width: 0.47pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span
									>Para ambos poderão ser solicitados outros documentos a
									qualquer tempo pela BeeBee, como: (i) conta bancária válida;
									(ii) certidão de antecedentes criminais; (iii) ANTT. Também
									propiciamos aos Entregadores a faculdade de, em uma mesma
									conta cadastral associada a um CNPJ, a vinculação de um ou
									mais veículos e motoristas, restando claro que essa vinculação
									também os obriga a observarem as minúcias destes Termos e
									Políticas de uso da plataforma </span
								><span style="font-weight: bold">BeeBee</span><span>;</span>
							</li>
							<br />
							<li
								style="
									margin-left: -28.35pt;
									text-indent: -27.8pt;
									font-family: Calibri;
									-aw-list-padding-sml: 0.47pt;
								"
							>
								<span
									style="
										width: 0.47pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span
									>Pedidos de fretes só podem ser aceitos e consequentemente
									realizados através de um </span
								><span style="font-weight: bold">Entregador</span
								><span> vinculado a uma </span
								><span style="font-weight: bold">Pessoa Jurídica</span
								><span
									>, e desta forma, tais fretes serão computados em favor do
									CNPJ ao qual o </span
								><span style="font-weight: bold">Entregador</span
								><span> esteja vinculado no momento do aceite do frete;</span>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ul>
				<ol
					start="6"
					type="1"
					class="awlist14"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 42.55pt;
							text-indent: -24.55pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 6.33pt;
						"
					>
						<span
							style="
								width: 6.33pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0; </span
						><span>O </span><span style="font-weight: bold">Entregador</span
						><span>
							assume que concorda com a tabela de preços vigente, sendo esta
							utilizada como base de cálculo do frete em nossa plataforma, de
							acordo com suas próprias necessidades. Entende que tal
							precificação é meio para trazer agilidade e precisão aos negócios,
							assim como a viabilizar a contratação imediata entre as partes. E
							ainda, concorda que os preços trazidos a você nas novas demandas
							de fretes não são de forma nenhuma impositivos, cabendo única e
							exclusivamente ao </span
						><span style="font-weight: bold">Entregador</span
						><span>
							optar ou não em realizar o frete/tarefa requerido pelo </span
						><span style="font-weight: bold">Usuário/Cliente</span
						><span> contratante;</span>
					</li>
					<br />
					<li
						style="
							margin-left: 42.55pt;
							text-indent: -24.55pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 6.33pt;
						"
					>
						<span
							style="
								width: 6.33pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0; </span
						><span>O </span><span style="font-weight: bold">Entregador</span
						><span>
							está ciente de que o valor informado poderá sofrer alterações nos
							casos de cancelamento e/ou incidentes no trajeto. As regras de
							cancelamento seguem da forma explicitada na parte que se refere a
							usuários </span
						><span style="font-weight: bold">Usuários/Clientes</span
						><span>, quais sejam:</span>
						<ol
							type="1"
							class="awlist15"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 21.25pt;
									text-indent: -27.8pt;
									-aw-list-padding-sml: 0.47pt;
								"
							>
								<span
									style="
										width: 0.47pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span style="font-weight: bold">Regras de Cancelamento:</span
								><span>&#xa0;</span
								><span
									>Uma vez que sua solicitação de serviço de frete tenha sido
									registrada em nossa plataforma, temos algumas regras de
									cancelamento e resolução de incidentes. Estas regras visam
									basicamente manter saudável o relacionamento entre os </span
								><span style="font-weight: bold">Usuário</span><span>, </span
								><span style="font-weight: bold">Entregadores</span
								><span> e a </span><span style="font-weight: bold">BeeBee</span
								><span>, conforme descritas a seguir:</span>
								<ol
									type="1"
									class="awlist16"
									style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
								>
									<br />
									<li
										style="
											margin-left: 28.35pt;
											text-indent: -38.1pt;
											-aw-list-padding-sml: 1.65pt;
										"
									>
										<span
											style="
												width: 1.65pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span
											>Caso o cancelamento seja realizado quando ainda não
											houver sido expressamente aceita a contratação por
											qualquer Entregador profissional, não haverá qualquer
											valor devido em relação a esta contratação;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 28.35pt;
											text-indent: -38.1pt;
											-aw-list-padding-sml: 1.65pt;
										"
									>
										<span
											style="
												width: 1.65pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span
											>Caso o cancelamento seja realizado após o expresso aceite
											pelo </span
										><span style="font-weight: bold">Entregador</span
										><span>
											serão tratados como incidentes. O montante devido pelo </span
										><span style="font-weight: bold">Usuário</span
										><span>
											levará em consideração o ponto de destino seguinte ao
											ponto do cancelamento, haja vista que, por segurança e em
											respeito ao Código Brasileiro de Trânsito, não é possível
											avisar o </span
										><span style="font-weight: bold">Entregador</span
										><span>
											sobre o cancelamento quando ele estiver se deslocando
											entre um ponto e outro. De forma a melhor explicar esta
											hipótese de cancelamento e suas consequências, seguem
											breves exemplos: em uma corrida de 03 (três) pontos (A, B
											e C), se a entrega for cancelada entre o ponto A e o B, a
											cobrança é feita até o ponto B e não será cobrado o
											deslocamento até o ponto C, que efetivamente não ocorrerá;
											porém, caso o Entregador profissional já tenha se retirado
											do ponto B em direção ao ponto C, será cobrado o valor
											total da entrega. Caso a entrega seja cancelada antes da
											chegada no ponto A,</span
										><span>&#xa0;</span
										><span style="font-weight: bold">poderá</span
										><span>&#xa0;</span
										><span
											>ser cobrada a taxa de cancelamento no valor mínimo
											de</span
										><span>&#xa0;</span
										><span style="font-weight: bold">R$ 5</span
										><span>&#xa0;</span
										><span
											>podendo ser proporcional ao deslocamento (caso o
											deslocamento seja significante) em alguns casos, e sempre
											baseado na calculadora de fretes em vigor na sua região no
											momento da contratação do </span
										><span style="font-weight: bold">Entregador</span
										><span>;</span>
									</li>
									<br />
									<li
										style="
											margin-left: 28.35pt;
											text-indent: -38.1pt;
											-aw-list-padding-sml: 1.65pt;
										"
									>
										<span
											style="
												width: 1.65pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span>Nossa plataforma possibilita que o </span
										><span style="font-weight: bold">Usuário</span
										><span>
											tenha acesso a toda e qualquer contratação realizada em
											seu favor. Eventuais incongruências da prestação do
											serviço deverão ser informadas à </span
										><span style="font-weight: bold">BeeBee</span
										><span>
											até o último dia útil do mês do fechamento do
											faturamento;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 28.35pt;
											text-indent: -38.1pt;
											-aw-list-padding-sml: 1.65pt;
										"
									>
										<span
											style="
												width: 1.65pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
										>
										</span
										><span>O silêncio do </span
										><span style="font-weight: bold">Usuário/Cliente</span
										><span>
											quanto quaisquer possíveis alterações no prazo acima
											mencionado será considerado como anuência às informações
											constantes em seu faturamento.</span
										>
									</li>
									<br />
								</ol>
							</li>
							<br />
						</ol>
					</li>
					<br />
					<li
						style="
							margin-left: 42.55pt;
							text-indent: -24.55pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 6.33pt;
						"
					>
						<span
							style="
								width: 6.33pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0; </span
						><span
							>A recusa no atendimento a qualquer frete não desqualifica o </span
						><span style="font-weight: bold">Entregador</span
						><span
							>, nem lhe acarreta nenhuma penalidade ou prejuízo, o qual atua de
							forma autônoma, na medida de sua disponibilidade e interesse, sem
							qualquer obrigação de meio ou forma, quantidade de horas,
							quantidade de entregas, quantidade de dias, ou qualquer outra
							forma de controle de quantitativo relacionado ao uso de nossa
							solução;</span
						>
					</li>
					<br />
					<li
						style="
							margin-left: 42.55pt;
							text-indent: -24.55pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 6.33pt;
						"
					>
						<span
							style="
								width: 6.33pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0; </span
						><span>O compromisso que a </span
						><span style="font-weight: bold">BeeBee</span
						><span> estabelece com o </span
						><span style="font-weight: bold">Entregador</span
						><span>
							é perante o cancelamento de um pedido já aceito, o qual é passível
							de cobrança de taxa de cancelamento de R$ 5,00 (cinco reais) e
							será tratado como incidente. O </span
						><span style="font-weight: bold">Entregador</span
						><span>
							poderá sofrer sanções como suspensões, capacitações de reciclagem
							e, havendo repetições em número igual ou superior a 3 (três)
							ocorrências, até ser banido da plataforma;</span
						>
					</li>
					<br />
					<li
						style="
							margin-left: 49.65pt;
							text-indent: -31.65pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 7.35pt;
						"
					>
						<span
							style="
								width: 7.35pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span>Qualquer tipo de incidente deve ser informado à </span
						><span style="font-weight: bold">BeeBee</span><span> e ao </span
						><span style="font-weight: bold">Usuário/Cliente</span
						><span>
							para evitar prejuízos. Incidentes que não comprometam a
							continuidade e conclusão do serviço serão analisados caso a caso e
							junto às partes envolvidas podendo ou não gerar alteração de
							valores;</span
						>
					</li>
					<br />
					<li
						style="
							margin-left: 49.65pt;
							text-indent: -31.65pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 7.35pt;
						"
					>
						<span
							style="
								width: 7.35pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span
							>Julgamos ser prudente alertá-lo que, ao juntar-se à nossa
							plataforma, você declara conhecer as particularidades, riscos,
							premissas e condições mínimas para a realização de serviços de
							frete. Não temos meios para muni-lo de instrumentos (físicos,
							teóricos, legais, morais, financeiros, ou de qualquer outra
							natureza) mínimos para a prestação de serviços de frete e nem é
							nosso dever fazê-lo. Nosso instrumento para apoiá-lo é
							exclusivamente nossa plataforma. Ao aderir à nossa solução, você
							declara ser plenamente capaz de assumir as responsabilidades pelo
							trabalho que se propõe a fazer, não imputando qualquer
							responsabilidade sobre os resultados que obtiver, presente ou
							futura, sobre a </span
						><span style="font-weight: bold">BeeBee</span><span>. </span>
					</li>
					<br />
					<li
						style="
							margin-left: 49.65pt;
							text-indent: -31.65pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 7.35pt;
						"
					>
						<span
							style="
								width: 7.35pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span
							>Apenas para sua ciência, é de sua responsabilidade dispor de
							recursos para, ao menos:</span
						>
						<ol
							type="1"
							class="awlist15"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 28.35pt;
									text-indent: -42pt;
									-aw-list-padding-sml: 8.58pt;
								"
							>
								<span
									style="
										width: 8.58pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
								><span
									>dispor dos ativos, equipamentos técnicos e operacionais
									necessários para realizar o Frete, incluindo pessoa ajudante
									("chapa") para auxiliar na prestação do frete;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 28.35pt;
									text-indent: -42pt;
									-aw-list-padding-sml: 8.58pt;
								"
							>
								<span
									style="
										width: 8.58pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
								><span
									>arcar com todas as despesas, custos, taxas, impostos e
									contribuições relativas à manutenção e operação de seu
									veículo, incluindo gastos com combustível, limpeza,
									ferramentas, equipamentos, impostos, vistorias, consertos,
									revisões, além de estacionamentos e/ou qualquer outra taxa
									decorrente da prestação do serviço;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 28.35pt;
									text-indent: -42pt;
									-aw-list-padding-sml: 8.58pt;
								"
							>
								<span
									style="
										width: 8.58pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
								><span
									>dispor e operar os equipamentos técnicos e operacionais
									necessários para acessar a Plataforma </span
								><span style="font-weight: bold">BeeBee</span
								><span
									>, tais como aparelho de telefone celular de modelo que seja
									compatível com a Plataforma, conexão à internet móvel, pacote
									de dados, GPS, entre outros;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 28.35pt;
									text-indent: -42pt;
									-aw-list-padding-sml: 8.58pt;
								"
							>
								<span
									style="
										width: 8.58pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
								><span
									>cumprir com todas as obrigações exigidas por lei relativas ao
									transporte de cargas, incluindo, mas não se limitando,
									vestimenta adequada e legalmente requerida, por manter sua
									documentação regularizada e atualizada para a prestação dos
									serviços de frete;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 28.35pt;
									text-indent: -42pt;
									-aw-list-padding-sml: 8.58pt;
								"
							>
								<span
									style="
										width: 8.58pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
								><span
									>manter totalmente atualizados seus dados cadastrais e
									documentações perante a </span
								><span style="font-weight: bold">BeeBee</span
								><span
									>, seja pelo esgotamento da vigência dos mesmos, aquisição de
									novo veículo, linha telefônica móvel, mudança de endereço,
									entre outros, sendo que, enquanto durar a ausência de
									renovação de qualquer informação, sob pena de suspensão até a
									total renovação;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 28.35pt;
									text-indent: -42pt;
									-aw-list-padding-sml: 8.58pt;
								"
							>
								<span
									style="
										width: 8.58pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
								><span
									>responsabilizar-se pelo objeto do Frete, bem como por
									qualquer valor disponibilizado pelo Usuário ao Entregador
									Profissional com relação ao frete, incluindo, mas não se
									limitando, a valores a serem utilizados para pagamento de
									taxas em cartórios e correios.</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 28.35pt;
									text-indent: -42pt;
									-aw-list-padding-sml: 8.58pt;
								"
							>
								<span
									style="
										width: 8.58pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
								><span
									>responsabilizar-se por toda e qualquer infração de trânsito
									ou dano que ocorra em decorrência direta ou indireta do Frete,
									incluindo, mas sem se limitar a: (i) multas; (ii) pontuação na
									CNH; (iii) acidentes; (iv) processos judiciais ou
									administrativos; (v) danos e/ou extravio dos produtos
									entregues para o serviço de Frete; entre outros, deverão ser
									arcados única e exclusivamente pelo </span
								><span style="font-weight: bold">Entregador</span
								><span>, sem que a </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									seja de qualquer forma responsabilizada por esses
									eventos;</span
								>
							</li>
							<br />
						</ol>
					</li>
					<br />
					<li
						style="
							margin-left: 49.65pt;
							text-indent: -31.65pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 7.35pt;
						"
					>
						<span
							style="
								width: 7.35pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span style="font-weight: bold"
							>Em nenhuma hipótese a BeeBee arcará com custos dos Entregadores
							para a realização de entregas, incluindo, mas não se limitando a
							custos de abastecimento, pagamento de quaisquer pedágios,
							estacionamentos, tributos, bem como os custos decorrentes da
							obtenção e manutenção de licença para condução do veículo eleito
							pelo entregador ou pagamento de multas por infrações cometidas
							pelo motorista</span
						><span>;</span>
					</li>
					<br />
					<li
						style="
							margin-left: 49.65pt;
							text-indent: -31.65pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 7.35pt;
						"
					>
						<span
							style="
								width: 7.35pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span
							>Eventualmente poderemos fornecer certos insumos aos Entregadores
							habilitados para prestar serviços de frete através da nossa
							plataforma, tais como carregador portátil, malote, suporte para
							celular, cabo USB, caneta para smartphone, camisetas, bonés,
							adesivos, jaqueta, plotagem total ou parcial do veículo, ou
							outros, a nosso critério. Seu uso será opcional, e visa fortalecer
							o vínculo de imagem entre os </span
						><span style="font-weight: bold">Entregadores</span><span>, </span
						><span style="font-weight: bold">Usuários/Clientes</span
						><span> e a </span><span style="font-weight: bold">BeeBee</span
						><span>;</span>
					</li>
					<br />
					<li
						style="
							margin-left: 49.65pt;
							text-indent: -31.65pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 7.35pt;
						"
					>
						<span
							style="
								width: 7.35pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span>Vale lembrar que a </span
						><span style="font-weight: bold">BeeBee</span
						><span> tem por valor a atitude proativa por parte do </span
						><span style="font-weight: bold">Entregador</span
						><span
							>, a cooperação e o auxílio na carga e descarga de materiais fazem
							parte do serviço de frete e da mesma forma transparecemos tal
							abordagem para os Usuários da plataforma. Não obrigamos tal
							conduta por parte do </span
						><span style="font-weight: bold">Entregador</span
						><span>
							uma vez que este não é nosso subordinado. Entretanto para as boas
							práticas, desenvolvimento e evolução do negócio sugerimos tal
							conduta;</span
						>
					</li>
					<br />
					<li
						style="
							margin-left: 49.65pt;
							text-indent: -31.65pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 7.35pt;
						"
					>
						<span
							style="
								width: 7.35pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span
							>Por fim, o uso que você faz de nossa plataforma é exclusivamente
							critério seu. Os momentos em que você se conecta à nossa
							plataforma, quanto tempo e em que horários se dispõe para
							trabalhar, o aceite ou não de pedidos de frete, dentre outras, são
							suas prerrogativas.
						</span>
						<ol
							type="1"
							class="awlist15"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 28.35pt;
									text-indent: -42pt;
									-aw-list-padding-sml: 8.58pt;
								"
							>
								<span
									style="
										width: 8.58pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
								><span>A </span><span style="font-weight: bold">BeeBee</span
								><span>
									não é legalmente responsável pelas obrigações fiscais que a
									legislação tributária indique como de responsabilidade do
									prestador do serviço (</span
								><span style="font-weight: bold">Entregador</span
								><span
									>), como por exemplo, recolhimento de tributos e emissão de
									Notas fiscais. Estas ficam a cargo do Entregador profissional
									assim como qualquer ônus advindo do não cumprimento de tais
									obrigações;</span
								>
							</li>
							<br />
						</ol>
					</li>
					<br />
					<li
						style="
							margin-left: 49.65pt;
							text-indent: -31.65pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 7.35pt;
						"
					>
						<span
							style="
								width: 7.35pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span>Por meio deste Termo de Uso,</span><span>&#xa0;</span
						><span style="font-weight: bold"
							>o Entregador assume total e exclusiva responsabilidade pelo
							transporte da Carga de sua Origem até seu Destino, na forma
							indicada na Plataforma, responsabilizando-se para que a Carga
							chegue em seu Destino no mesmo estado e condição em que lhe foi
							entregue pelo Cliente quando do início da Entrega</span
						><span>. O </span><span style="font-weight: bold">Entregador</span
						><span> deve indenizar a </span
						><span style="font-weight: bold">BeeBee</span><span> e/ou o </span
						><span style="font-weight: bold">Usuário/Cliente</span
						><span> por falhas no cumprimento de uma Entrega, podendo a </span
						><span style="font-weight: bold">BeeBee</span
						><span
							>, a seu exclusivo critério, mediante aviso prévio que sinalize os
							motivos e dê possibilidade de resposta por parte do entregador,
							bloquear ou descontar valores devidos ao </span
						><span style="font-weight: bold">Entregador</span
						><span>
							caso seja constatada uma falha (dano ou extravio de um produto) na
							realização da Entrega;</span
						>
					</li>
					<br />
					<li
						style="
							margin-left: 49.65pt;
							text-indent: -31.65pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							-aw-list-padding-sml: 7.35pt;
						"
					>
						<span
							style="
								width: 7.35pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span>Quando a </span><span style="font-weight: bold">BeeBee</span
						><span> receber, em nome do </span
						><span style="font-weight: bold">Entregador</span
						><span
							>, determinados valores que serão futuramente repassados à título
							de pagamento pelos serviços de frete, ela o fará em conta própria,
							com a designação contábil de destinação à terceiros, e o </span
						><span style="font-weight: bold">Entregador</span
						><span
							>, desde já, para todos os fins de direito, que até o momento do
							efetivo repasse, autoriza que a </span
						><span style="font-weight: bold">BeeBee</span
						><span>
							promova a utilização desses numerários, a que título for, desde
							que essa se comprometa, irrestritamente, no provisionamento e
							saldo disponíveis no ato do efetivo repasse.</span
						>
					</li>
					<br />
				</ol>
				<p
					style="
						margin-left: 49.65pt;
						text-align: justify;
						widows: 0;
						orphans: 0;
					"
				>
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol
					start="9"
					type="1"
					class="awlist17"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 8.71pt;
						"
					>
						<span
							style="
								width: 8.71pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
						><span>SUSPENSÃO, BLOQUEIO OU BANIMENTO DO ENTREGADOR</span
						><span style="font-weight: normal"
							>: O Entregador poderá ter sua conta suspensa, bloqueada ou
							banida, a critério da </span
						><span>BeeBee</span
						><span style="font-weight: normal">
							após a devida análise das ocorrências abaixo listadas, sempre
							mediante notificações e avisos prévios para apuração e
							esclarecimento dos fatos:
						</span>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span style="font-weight: bold">Suspensão</span
								><span>: Nos casos de problemas com as entregas, a </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									fará duas tentativas de contato telefônico através do número
									de celular cadastrado na plataforma, os quais, caso não sejam
									atendidos, acarretarão a suspensão de sua conta, e você terá
									até 3 (três) dias úteis para contatar a </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									para apresentar esclarecimento sobre os fatos e, após esse
									período, na ausência de esclarecimento, sua conta será
									bloqueada.
								</span>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 6.33pt;
								"
							>
								<span
									style="
										width: 6.33pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
									>&#xa0;&#xa0;&#xa0; </span
								><span style="font-weight: bold">Bloqueio ou Banimento</span
								><span
									>: Toda e qualquer ação abaixo poderá levar ao bloqueio ou
									imediato banimento da plataforma, a depender do tipo de
									conduta, para as quais o </span
								><span style="font-weight: bold">Entregador</span
								><span>
									declara e garante estar ciente da gravidade, senão
									vejamos:</span
								>
								<ol
									type="1"
									class="awlist8"
									style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
								>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span>Identificação do uso da conta por terceiros;</span>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>Utilização de aplicativos com a finalidade de burlar a
											localização, também conhecido como GPS Fake;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>Prática de qualquer infração de trânsito ou conduta que
											implique risco de segurança do trânsito;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>Score de avaliação dos usuários/clientes inferior a
											3;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>Apropriação indevida do objeto que deveria ser entregue
											ao consumidor final;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>Apropriação indevida de dinheiro ou máquina de cartão que
											deveria ser entregue ao usuário/cliente;</span
										><span>&#xa0;</span>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>Condutas reiteradas de más práticas com os
											usuários/clientes, através da verificação de reclamações
											constantes;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 8.67pt;
										"
									>
										<span
											style="
												width: 8.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>Em função de ordem judicial ou requisição legal por
											autoridade pública competente;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 35.45pt;
											text-indent: -42pt;
											-aw-list-padding-sml: 14.67pt;
										"
									>
										<span
											style="
												width: 14.67pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>Em razão de divergência ou fraude quanto aos dados e às
											informações prestadas quando do cadastro na
											plataforma;</span
										><span>&#xa0;</span>
									</li>
									<br />
									<li
										style="
											margin-left: 35.45pt;
											text-indent: -42pt;
											-aw-list-padding-sml: 8.58pt;
										"
									>
										<span
											style="
												width: 8.58pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>Causar danos e/ou prejuízos, diretos ou indiretos, a
											terceiros ou a própria </span
										><span style="font-weight: bold">BeeBee</span
										><span
											>, devido a atos ou omissões na utilização da Plataforma
											ou na realização das entregas;</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 35.45pt;
											text-indent: -42pt;
											-aw-list-padding-sml: 8.58pt;
										"
									>
										<span
											style="
												width: 8.58pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
										><span
											>Descumprimento dos termos estabelecidos neste contrato,
											incluindo, mas não se limitando, àquelas que estejam
											ligadas a ações ou omissões que possam configurar assédio,
											incitação à violência, atitudes racistas, homofóbicas, de
											intolerância religiosa ou política, machismo, capacitistas
											ou qualquer ação que diminua ou ofenda alguém.</span
										>
									</li>
									<br />
								</ol>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<p
					style="margin-left: 18pt; text-align: justify; widows: 0; orphans: 0"
				>
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol
					start="10"
					type="1"
					class="awlist18"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							widows: 0;
							orphans: 0;
							font-weight: bold;
							-aw-list-padding-sml: 2.63pt;
						"
					>
						<span
							style="
								width: 2.63pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0; </span
						><span style="font-size: 28px; color: #393939"
							>PROPRIEDADE INTELECTUAL</span
						><br /><br /><span style="font-weight: normal"
							>Salvo disposição em contrário, todos os softwares da </span
						><span>BeeBee </span
						><span style="font-weight: normal"
							>são destinados somente para uso pessoal e não comercial por
							sub-rogação ou qualquer ato em seu nome.
						</span>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span>Desde já, a </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									toma para si o direito sobre a propriedade da marca, de seu
									website, aplicativo e de suas tecnologias,</span
								><span style="font-weight: bold"> </span
								><span
									>de forma a não permitir nenhuma ingerência por terceiros que
									tangenciem tais propriedades, exceto aquelas negociadas em
									documento próprio para tanto, bem como as bases elencadas
									nestes </span
								><span style="font-weight: bold">Termos</span><span>;</span>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span>A </span><span style="font-weight: bold">BeeBee</span
								><span>
									declara que o conteúdo, aparência, organização e estrutura do
									seu website e do seu aplicativo não infringem qualquer Lei ou
									Ato Normativo a qual estejam subordinados, contrato, documento
									ou acordo do qual faça parte;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span
									>Você não poderá copiar, reproduzir, distribuir, duplicar,
									compilar, criar obra derivada, alterar, combinar, modificar,
									adaptar, traduzir, ampliar, mesclar, decodificar, recriar ou
									realizar a engenharia reversa de qualquer componente de nosso
									website e aplicativo;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span
									>Você se compromete por meio deste em não fazer uso de
									qualquer informação contida na plataforma </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									com o intuito de infringir a lei, seja ela qual for, bem como
									respeitar direitos de propriedade industrial, patente ou
									intelectual/autoral aqui contidos, na forma de seus textos,
									softwares, sons, imagens de qualquer natureza, ou outro meio
									possível de ser produzido/reproduzido, inabilitando-o no
									requerimento da autoria ou direito de uso de quaisquer desses
									dados ou recursos para qualquer fim;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span style="font-weight: bold">É VEDADO</span
								><span> a você</span><span style="font-weight: bold"> </span
								><span
									>se utilizar de trechos, imagens ou outra forma de
									manifestação audiovisual, por qualquer técnica de engenharia
									reversa no desenvolvimento ou criação de outros trabalhos que
									permita ter acesso à sua constituição; além de ser vedado,
									também, a disponibilização do acesso pela internet em qualquer
									endereço e de arquivos não autorizados;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span>As soluções apresentadas pela </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									possuem conteúdo protegido por direitos autorais, marcas,
									marcas de serviço, patentes, sigilo comercial ou outros
									direitos protegidos pela legislação aplicável, de forma que
									você deve seguir e manter todos os avisos de direitos
									autorais, informações e restrições contidos em qualquer
									conteúdo acessado por meio de nossas plataformas;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span
									>Qualquer uso, reprodução, modificação, distribuição ou
									armazenamento de conteúdo que não seja para uso pessoal e/ou
									não comercial é expressamente proibido sem a prévia permissão
									por escrito da </span
								><span style="font-weight: bold">BeeBee</span
								><span>
									ou do detentor dos direitos autorais identificado no aviso
									desse conteúdo, quando aplicável;</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 2;
									orphans: 2;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span
									>Você não deve vender, licenciar, alugar ou, de qualquer outro
									modo, usar ou explorar nenhum conteúdo para fins comerciais
									(com ou sem fins lucrativos) ou de qualquer forma que viole os
									direitos da </span
								><span style="font-weight: bold">BeeBee </span
								><span>ou de terceiros.</span>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<p style="text-align: justify">
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<ol
					start="11"
					type="1"
					class="awlist19"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							font-weight: bold;
							-aw-list-padding-sml: 2.63pt;
						"
					>
						<span
							style="
								width: 2.63pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0; </span
						><span>RESCISÃO</span
						><span style="font-weight: normal"
							>: Você pode parar de usar nossa solução a qualquer momento.
						</span>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span
									>Nós nos reservamos o direito de suspender ou encerrar seu
									acesso, mediante notificação ou não, caso:</span
								>
								<ol
									type="1"
									class="awlist8"
									style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
								>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 2.58pt;
										"
									>
										<span
											style="
												width: 2.58pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0; </span
										><span>você esteja violando estes Termos,</span>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 2.58pt;
										"
									>
										<span
											style="
												width: 2.58pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0; </span
										><span
											>você esteja usando nossa plataforma de maneira que possam
											causar risco real de perigo ou prejuízo a nós, nossos
											parceiros ou a outros usuários,</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 2.58pt;
										"
									>
										<span
											style="
												width: 2.58pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0; </span
										><span
											>você não tenha utilizado nossa solução por 12 meses
											consecutivos, ou</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 2.58pt;
										"
									>
										<span
											style="
												width: 2.58pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0; </span
										><span
											>a avaliação de desempenho de seu uso de nossa plataforma,
											coletada por meio de formulários de avaliação eletrônicos
											preenchidos por parte do </span
										><span style="font-weight: bold">Usuário/Cliente</span
										><span
											>, assim como a análise de ocorrência por parte de nossa
											equipe operacional que qualifica </span
										><span style="font-weight: bold">Entregadores</span
										><span>
											por sua, cordialidade, performance, comprometimento e
											segurança. Quando isso não for condizente com nossos
											critérios de qualidade ou uso aceitável;</span
										>
									</li>
									<br />
								</ol>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span
									>Vamos efetuar uma notificação prévia com tempo razoável pelo
									endereço de e-mail ou número de </span
								><span style="font-style: italic">WhatsApp</span
								><span>
									associado à sua conta para que você possa ter ciência e
									eventualmente adequar sua conduta com relação a estes termos.
									Caso após essa notificação você não tome as providências
									solicitadas, rescindiremos ou suspenderemos seu acesso aos
									Serviços.</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span
									>Não forneceremos notificação antes da rescisão quando:</span
								>
								<ol
									type="1"
									class="awlist8"
									style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
								>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 2.58pt;
										"
									>
										<span
											style="
												width: 2.58pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0; </span
										><span
											>você estiver cometendo uma violação destes Termos</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 2.58pt;
										"
									>
										<span
											style="
												width: 2.58pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0; </span
										><span
											>fazê-lo causaria responsabilidade legal ou comprometeria
											nossa capacidade de fornecer nossa solução a outros
											usuários, ou</span
										>
									</li>
									<br />
									<li
										style="
											margin-left: 18.65pt;
											text-indent: -25.2pt;
											list-style-position: inside;
											-aw-list-padding-sml: 2.58pt;
										"
									>
										<span
											style="
												width: 2.58pt;
												font: 7pt 'Times New Roman';
												display: inline-block;
												-aw-import: ignore;
											"
											>&#xa0; </span
										><span>formos proibidos por lei de fazê-lo.</span>
									</li>
									<br />
								</ol>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<p style="margin-left: 18pt; text-align: justify">
					<span style="font-weight: bold; -aw-import: ignore">&#xa0;</span>
				</p>
				<ol
					start="12"
					type="1"
					class="awlist20"
					style="margin: 0pt; padding-left: 0pt"
				>
					<li
						style="
							margin-left: 18pt;
							text-indent: -18pt;
							text-align: justify;
							font-weight: bold;
							-aw-list-padding-sml: 2.63pt;
						"
					>
						<span
							style="
								width: 2.63pt;
								font: 7pt 'Times New Roman';
								display: inline-block;
								-aw-import: ignore;
							"
							>&#xa0; </span
						><span style="font-size: 28px; color: #393939"
							>DISPOSIÇÕES FINAIS</span
						><br /><br /><span style="font-weight: normal"
							>Independentemente de qualquer aviso ou notificação prévia, a </span
						><span>BeeBee </span
						><span style="font-weight: normal"
							>poderá, a seu exclusivo critério, modificar, suspender ou
							descontinuar seus produtos ou serviços, a qualquer tempo e por
							qualquer motivo, não tendo o </span
						><span>Usuário</span
						><span style="font-weight: normal">
							direito a qualquer indenização ou compensação.</span
						>
						<ol
							type="1"
							class="awlist4"
							style="margin-right: 0pt; margin-left: 0pt; padding-left: 0pt"
						>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span style="font-weight: bold">Vigência</span
								><span>: Estes </span
								><span style="font-weight: bold">Termos</span
								><span>
									vigerão por prazo indeterminado a partir do aceite do </span
								><span style="font-weight: bold">Usuário</span
								><span
									>, podendo ser rescindido unilateralmente por qualquer das
									partes a qualquer tempo, sem ônus, mediante comunicação junto
									aos canais de atendimento.
								</span>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span style="font-weight: bold">Tolerância</span><span>:</span
								><span style="-aw-import: spaces">&#xa0; </span
								><span
									>A eventual tolerância quanto ao inexato ou impontual
									cumprimento das obrigações dispostas nestes </span
								><span style="font-weight: bold">Termos</span
								><span>
									valerão tão somente de forma isolada, não constituindo
									renúncia ou novação de qualquer espécie.</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span style="font-weight: bold">Renúncia</span><span>:</span
								><span style="font-family: Lato; color: #919191"> </span
								><span
									>Qualquer falha para impor ou exercer qualquer direito,
									prerrogativa ou faculdade assegurados por estes </span
								><span style="font-weight: bold">Termos</span
								><span>
									ou pela legislação aplicável, incluindo direitos conexos, não
									constitui renúncia a tais direitos, prerrogativas ou
									faculdades.</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span style="font-weight: bold">Nulidade ou Ineficácia</span
								><span>: Caso qualquer disposição destes </span
								><span style="font-weight: bold">Termos</span
								><span>
									se torne nula ou ineficaz, a validade ou eficácia das
									disposições restantes não serão afetadas, permanecendo em
									pleno vigor e efeito.</span
								>
							</li>
							<br />
							<li
								style="
									margin-left: 24.55pt;
									text-indent: -24.55pt;
									widows: 0;
									orphans: 0;
									font-weight: normal;
									-aw-list-padding-sml: 0.25pt;
								"
							>
								<span
									style="
										width: 0.25pt;
										font: 7pt 'Times New Roman';
										display: inline-block;
										-aw-import: ignore;
									"
								>
								</span
								><span style="font-weight: bold"
									>Resolução de Disputas, Lei e Foro</span
								><span
									>: Vamos tentar resolver as coisas primeiro. Queremos atender
									as suas preocupações, sem a necessidade de um processo
									judicial. Vamos tentar resolver qualquer ponto de divergência
									via e-mail (</span
								><a
									href="mailto:suporte@beebee.com.br"
									style="text-decoration: none"
									><span class="Hyperlink">suporte@beebee.com.br</span></a
								><span
									>). Contudo, se não for possível, então, estes Termos serão
									regidos e interpretados de acordo com a legislação da
									República Federativa do Brasil, e no caso de qualquer
									reivindicação ou controvérsia decorrente destes Termos, ou a
									eles relacionada, as Partes elegem desde já o foro da Comarca
									de Vitória, Estado de Espírito Santo, para a solução da
									reivindicação ou controvérsia, com a exclusão de qualquer
									outro, por mais privilegiado que seja.</span
								>
							</li>
							<br />
						</ol>
					</li>
					<br />
				</ol>
				<p
					style="
						margin-left: 42.55pt;
						text-align: justify;
						widows: 0;
						orphans: 0;
					"
				>
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<p style="text-align: center; widows: 0; orphans: 0">
					<span>Vitória (ES), 22 de julho de 2020</span>
				</p>
				<p style="text-align: center; widows: 0; orphans: 0">
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<p style="text-align: center; widows: 0; orphans: 0">
					<span style="-aw-import: ignore">&#xa0;</span>
				</p>
				<p style="text-align: center; widows: 0; orphans: 0">
					<span style="font-weight: bold">BeeBee</span>
				</p>
			</div>
			<hr
				style="
					width: 33%;
					height: 1px;
					text-align: center;
					-aw-footnote-numberstyle: 0;
					-aw-footnote-startnumber: 1;
					-aw-footnote-type: 0;
				"
			/>
			<div
				id="_ftn1"
				style="
					-aw-footnote-isauto: 1;
					padding: 0px 16px;
					font-size: 12px;
					font-style: italic;
				"
			>
				<p style="text-align: justify">
					<a href="#_ftnref1" style="text-decoration: none"
						><span style="font-size: 8pt; vertical-align: super; color: #000000"
							>[1]</span
						></a
					><span> </span
					><span style="font-weight: bold; font-variant: small-caps"
						>DIREITO DE USO DE LICENÇA DE SOFTWARE</span
					><span
						>: Direito não exclusivo e intransferível de usar o sistema com a
						finalidade para a qual o mesmo foi desenvolvido, qual seja, o
						gerenciamento e processamento de seus dados e de seus clientes,
						limitado à proposta comercial.</span
					>
				</p>
			</div>
			<div
				id="_ftn2"
				style="
					-aw-footnote-isauto: 1;
					padding: 0px 16px;
					font-size: 12px;
					font-style: italic;
				"
			>
				<p style="text-align: justify">
					<a href="#_ftnref2" style="text-decoration: none"
						><span style="font-size: 8pt; vertical-align: super; color: #000000"
							>[2]</span
						></a
					><span> </span
					><span style="font-weight: bold; font-style: italic"
						>CLOUD COMPUTING</span
					><span style="font-weight: bold">: </span
					><span
						>É a utilização da memória e das capacidades de armazenamento e
						cálculo de computadores e servidores compartilhados e interligados
						por meio da Internet, seguindo o princípio da computação em
						grade.</span
					>
				</p>
			</div>
		</div>
	</body>
</html>`;

	return (
		<div>
			<div
				dangerouslySetInnerHTML={{
					__html: html,
				}}
			/>
		</div>
	);
};

export default TermoEntregador;
