import { useQuery } from '@tanstack/react-query';
import { api } from '../../../services/angular/axios';
import { OrderResponseApi } from '../../../services/angular/types/Order';
import { Avatar } from '../../Avatar';
import { FormHeader } from '../../FormElements';
import WhiteContainer from '../../WhiteContainer/WhiteContainer';

interface VehicleProps {
	order?: OrderResponseApi;
}

export const VehicleDetails = ({ order }: VehicleProps) => {
	const { data: vehicleFrontAvatar } = useQuery(
		['vehicleFrontAvatar', order?.vehicle?.front],
		async () => {
			if (!order?.vehicle?.front) return;
			const response = await api.get(`/assets?`, {
				params: {
					imageName: order.vehicle.front,
				},
			});
			return response;
		},
		{
			enabled: !!order?.vehicle?.front,
		}
	);

	return (
		<WhiteContainer
			className="h-full gap-4"
			divProps={{
				className: 'w-full',
			}}
		>
			<FormHeader title="Veículo" />
			<div className="flex items-center gap-4">
				<span className="h-20 w-20">
					<Avatar
						src={vehicleFrontAvatar?.data.url}
						avatarProps={{
							className: 'h-20 w-20',
						}}
					/>
				</span>

				<div className="flex flex-col text-sm">
					<span className="text-base font-bold">
						{order?.vehicle.make} {order?.vehicle.model}
					</span>
					<span>Placa: {order?.vehicle.plate}</span>
					<span>Ano: {order?.vehicle.year}</span>
				</div>
			</div>
		</WhiteContainer>
	);
};
