import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const MailCheckIcon: React.FC<SvgProps> = ({ color = '#FCF366', size }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M36.6668 21.6665V9.99984C36.6668 9.11578 36.3156 8.26794 35.6905 7.64281C35.0654 7.01769 34.2176 6.6665 33.3335 6.6665H6.66683C5.78277 6.6665 4.93493 7.01769 4.30981 7.64281C3.68469 8.26794 3.3335 9.11578 3.3335 9.99984V29.9998C3.3335 31.8332 4.8335 33.3332 6.66683 33.3332H20.0002"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M36.6668 11.6665L21.7168 21.1665C21.2023 21.4889 20.6074 21.6599 20.0002 21.6599C19.393 21.6599 18.798 21.4889 18.2835 21.1665L3.3335 11.6665"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M26.6665 31.6668L29.9998 35.0002L36.6665 28.3335"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default MailCheckIcon;
