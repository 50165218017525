import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { toastMessage } from '../toastMessage';

export const ToastError = (error: any): void => {
	if (error instanceof AxiosError) {
		const data = error.response?.data;

		if (typeof data === 'string') toast.error(data);
		else if ('error' in data && typeof data.error === 'string')
			toast.error(data.error);
		else if (
			'errors' in data &&
			Array.isArray(data.errors) &&
			!!data.errors.length
		)
			toast.error(data.errors[0]);
		else if ('message' in data && typeof data.message === 'string')
			toast.error(data.message);
		else toast.error(toastMessage.error.default);
	}
};
