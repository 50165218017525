import React from 'react';
import ReactInputVerificationCode, {
	ReactInputVerificationCodeProps,
} from 'react-input-verification-code';
import './styles.css';

interface InputCodeProps extends ReactInputVerificationCodeProps {}

export const InputCode: React.FC<InputCodeProps> = ({ ...props }) => {
	return (
		<div className="flex items-center justify-center custom-styles">
			<ReactInputVerificationCode placeholder="" {...props} />
		</div>
	);
};
