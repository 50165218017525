import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { z } from 'zod';
import LoginImageMan from '../../../../assets/svg/LoginImageMan';
import Button from '../../../../components/Button/Button';
import { Form, Input, useZodForm } from '../../../../components/FormElements';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { cpfMask } from '../../../../helpers/mask/cpfMask';
import cpfValidation from '../../../../helpers/validation/cpfValidation';
import { api } from '../../../../services/angular/axios';
import ForgotVerificationForm from './ForgotVerificationForm';

const AngularForgot: React.FC = () => {
	const [verificationForm, setVerificationForm] = useState(false);
	const [cellphone, setCellphone] = useState('');
	const [cpf, setCpf] = useState('');
	const ForgotSchema = useZodForm({
		schema: z.object({
			cpf: z.string().refine(cpfValidation, {
				message: 'CPF inválido',
			}),
		}),
	});

	const {
		setValue,
		register,
		formState: { errors },
	} = ForgotSchema;

	const onSubmit = async (data: {
		cpf: string;
		cellphone: string;
	}): Promise<void> => {
		try {
			const response = await api.post('/forgot', {
				...data,
			});

			setCpf(response.data.cpf);
			setVerificationForm(true);
			setCellphone(response.data.cellphone);

			toast.success('Código enviado com sucesso');
		} catch (error) {
			toast.error('Erro ao enviar código');
		}
	};

	return (
		<div className="flex items-center justify-center gap-12">
			<div className="hidden gap-4 md:flex md:flex-col">
				<div className="text-left text-5xl font-bold text-white">
					Um <span className="text-yellow-500">jeito novo</span>
					<br />
					de fazer <span className="text-yellow-500">entregas</span>
				</div>
				<LoginImageMan />
			</div>
			{verificationForm ? (
				<ForgotVerificationForm cpf={cpf} phone={cellphone} />
			) : (
				<WhiteContainer className="w-[90vw] sm:w-[70vw] md:w-96">
					<Form
						form={ForgotSchema}
						onSubmit={async (data) => await onSubmit(data)}
						className="flex flex-col gap-4"
					>
						<h1 className="text-start text-2xl font-bold text-neutral-900">
							Recuperação de senha
						</h1>
						<p className="text-start text-sm text-neutral-700">
							Por favor, informe seu CPF e lhe enviaremos um SMS com o código de
							verificação usado para redefinir sua senha.
						</p>
						<Input
							label="CPF"
							{...register('cpf', {
								onChange: (event) => {
									setValue('cpf', cpfMask(event.target.value));
									setCpf(event.target.value);
								},
							})}
							errorMessage={errors.cpf?.message}
						/>
						<Button type="submit">Enviar</Button>
					</Form>
				</WhiteContainer>
			)}
		</div>
	);
};

export default AngularForgot;
