import { PageHeader } from '../../../../components/PageHeader';
import { api } from '../../../../services/angular/axios';
import { useQuery } from '@tanstack/react-query';
import { FiltersTab } from '../../../../components/FiltersTab';
import {
	CompaniesFilterSchema,
	CompaniesFilterSchemaType,
} from './schemas/CompaniesFilterSchema';
import { useEffect, useState } from 'react';
import { Input, Select, useZodForm } from '../../../../components/FormElements';
import { SortOptions } from '../../../../services/angular/types/Params';
import { Table, TableActions } from '../../../../components/Table/Table';
import useUserState, {
	CompanyModel,
} from '../../../../services/angular/angularUserState';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { Avatar } from '../../../../components/Avatar';
import Pusher from 'pusher-js';
// @ts-expect-error
import clientWaiting from '../../../../assets/sounds/clientWaiting.wav';
import { useStore } from 'zustand';
import { appStateStore } from '../../../../store/appState';
import { toast } from 'react-toastify';
import { toastMessage } from '../../../../helpers/toastMessage';
import { useAlert } from '../../../../contexts/AlertContext';
import { Tooltip } from '../../../../components/Tooltip';
import { TbSteeringWheel } from 'react-icons/tb';
import dayjs from 'dayjs';
import { BsBuildingFill } from 'react-icons/bs';
import { phoneMask, removePhoneMask } from '../../../../helpers/mask/phoneMask';
import { useCopy } from '../../../../hooks/useCopy';
import { twMerge } from 'tailwind-merge';

const Companies = () => {
	const [query, setQuery] = useState<CompaniesFilterSchemaType>();
	const token = useUserState((state) => state.token);
	const { config } = useStore(appStateStore);
	const alert = useAlert();
	const copy = useCopy();

	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 50,
	});

	useEffect(() => {
		const pusher = new Pusher(config.pusherAppKey, {
			cluster: config.pusherCluster,
			authEndpoint: config.pusherAuth,
			auth: {
				headers: {
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					Authorization: `Bearer ${token}`,
				},
			},
		});

		const channel = pusher.subscribe('private-CLIENTS_AWAITING_ADMIN');
		channel.bind('NOTIFY_ADMIN_AWAITING_CLIENT', function (data: any) {
			const audio = new Audio(clientWaiting);
			void audio.play();
		});
		return () => {
			pusher.unsubscribe('private-CLIENTS_AWAITING_ADMIN');
			pusher.disconnect();
		};
	}, []);

	const form = useZodForm({
		schema: CompaniesFilterSchema,
	});

	const { data, isLoading, isFetching, refetch } = useQuery(
		['companies', query, params],
		async () =>
			await api.get('/companies?include=tags', {
				params: {
					...query,
					...params,
					pageSize: params.take,
					sort: params.sortBy
						? `${params.sortBy?.id}:${params.sortBy?.order}`
						: undefined,
				},
			}),
		{
			refetchOnMount: 'always',
		}
	);

	const handleDelete = (idToDelete: number, company: string) => {
		alert.onCustom({
			title: 'Atenção',
			message: `Deseja mesmo excluir a empresa ${company}?`,
			confirm: {
				onClick: async () => {
					try {
						await api.delete(`/companies/${idToDelete}`);
						toast.success(toastMessage.success.deleted);
						void refetch();
					} catch (err) {
						toast.error(toastMessage.error.deleted);
					}
				},
			},
		});
	};

	const { data: tags } = useQuery(
		['tags'],
		async () =>
			await api.get('/tags', {
				params: {
					filterBy: 'company',
				},
			})
	);

	return (
		<div>
			<PageHeader
				title="Empresas"
				description="Relação de empresas cadastradas"
			/>
			<FiltersTab
				form={form}
				onSubmit={(values) => {
					setQuery(values);
				}}
				clearFilters={() => {
					form.reset({
						companyTypes: [],
						status: [],
						tagsIds: null,
					});
					setQuery(undefined);
				}}
			>
				<Input
					variant="light"
					className="w-full md:w-auto"
					label="Nome"
					{...form.register('tradingName')}
				/>
				<Input
					variant="light"
					className="w-full md:w-auto"
					label="CNPJ"
					{...form.register('cnpj')}
				/>
				<Select
					variant="light"
					className="w-full md:w-auto"
					label="Tipo de empresa"
					isMulti
					controller={{
						control: form.control,
						name: 'companyTypes',
					}}
					options={[
						{ label: 'Cliente', value: 0 },
						{ label: 'Fretista', value: 1 },
						{ label: 'Todos', value: undefined },
					]}
				/>
				<Select
					variant="light"
					className="md:w-auto md:min-w-[128px]"
					label="Status"
					isMulti
					controller={{
						control: form.control,
						name: 'status',
					}}
					options={[
						{ label: 'Aguardando aprovação', value: 1 },
						{ label: 'Rejeitado', value: 2 },
						{ label: 'Aprovado', value: 3 },
					]}
				/>
				<Select
					variant="light"
					className="md:w-auto md:min-w-[128px]"
					label="Tags"
					controller={{ control: form.control, name: 'tagsIds' }}
					options={tags?.data.map((tag: { id: number; name: string }) => ({
						label: tag.name,
						value: tag.id,
					}))}
				/>
			</FiltersTab>

			<WhiteContainer>
				<Table<CompanyModel>
					data={data?.data.data || []}
					isLoading={isLoading || isFetching}
					total={data?.data.meta.pagination.total || 0}
					params={params}
					className="text-sm"
					onParamsChange={(params) => {
						setParams(params);
					}}
					disabledSort={{
						avatarUrl: true,
						requestFreights: true,
						approved: true,
					}}
					columns={[
						{
							Header: '',
							accessor: 'requestFreights',
							width: '0%',
							Cell: ({ row }) => {
								const { makeFreights, requestFreights } = row.original;
								return (
									<div className="flex flex-col gap-1 text-orange">
										{requestFreights && (
											<Tooltip message="Empresa">
												<BsBuildingFill className="text-empresa" size={20} />
											</Tooltip>
										)}
										{makeFreights && (
											<Tooltip message="Fretista">
												<TbSteeringWheel className="text-fretista" size={20} />
											</Tooltip>
										)}
									</div>
								);
							},
						},
						{
							Header: '',
							accessor: 'avatarUrl',
							width: '0%',
							Cell: ({ row }) => {
								const { avatarUrl, tradingName, companyName } = row.original;
								const { data: avatar } = useQuery(
									['avatar', avatarUrl],
									async () =>
										await api.get(
											avatarUrl?.substring(avatarUrl.indexOf('/companies'))
										),
									{
										enabled: !!avatarUrl,
									}
								);
								return (
									<Avatar
										avatarProps={{ className: 'w-10 h-10' }}
										name={tradingName || companyName}
										src={avatar?.data.url}
										isLoading={isLoading || isFetching}
									/>
								);
							},
						},
						{
							Header: 'Empresa',
							accessor: 'tradingName',
							Cell: ({ row }) => {
								const { tradingName, companyName, tags } = row.original;

								return (
									<div className="flex flex-col gap-1 ">
										<p className="line-clamp-2">{tradingName || companyName}</p>
										<div className="flex flex-wrap gap-1.5 p-px">
											{tags?.map(({ name, id }) => (
												<span
													key={id}
													className="rounded bg-neutral-100 px-1 text-2xs text-neutral-900 ring-1 ring-neutral-200"
												>
													{name}
												</span>
											))}
										</div>
									</div>
								);
							},
						},
						{
							Header: 'CNPJ',
							accessor: 'cnpj',
						},
						{
							Header: 'Telefone',
							accessor: 'cellphone',
							Cell: ({ row }) => {
								const { cellphone, phone } = row.original;
								return (
									<div
										className="flex cursor-copy"
										onClick={() => {
											void copy(removePhoneMask(cellphone || phone, '+55'));
										}}
									>
										{phoneMask(removePhoneMask(cellphone || phone))}
									</div>
								);
							},
						},
						{
							Header: 'Região',
							accessor: 'city',
						},
						{
							accessor: 'observation',
							Header: 'Observação',
							Cell: ({ value }) => (
								<div className="max-w-[192px]">
									<p className="truncate">{value || '-'}</p>
								</div>
							),
						},
						{
							Header: 'Criado em',
							accessor: 'created_at',
							Cell: ({ value }) => (
								<>{dayjs(value).format('DD/MM/YYYY HH:mm')}</>
							),
						},
						{
							Header: 'Atualizado em',
							accessor: 'updated_at',
							Cell: ({ value }) => (
								<>{dayjs(value).format('DD/MM/YYYY HH:mm')}</>
							),
						},
						{
							Header: 'Status',
							accessor: 'status',
							Cell: ({ row }) => {
								const { status } = row.original;

								const statusObj =
									status === 1
										? {
												message: `Aguardando aprovação`,
												className: 'bg-orange',
										  }
										: status === 2
										? {
												message: `Rejeitado`,
												className: 'bg-terracota-500',
										  }
										: {
												message: `Aprovado`,
												className: 'bg-green',
										  };

								return (
									<div
										className={twMerge(
											'w-min rounded px-1.5 py-1 text-center text-xs leading-3 text-white',
											statusObj.className
										)}
									>
										{statusObj.message}
									</div>
								);
							},
						},
						{
							accessor: 'approved',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => (
								<TableActions
									row={row}
									actions={[
										{
											icon: 'grid',
											label: 'Dashboard',
											href: `/empresas/dashboard/${row?.original?.id}`,
										},
										{
											icon: 'edit',
											label: 'Editar',
											href: `/empresas/${row?.original?.id}`,
										},
										{
											icon: 'trash',
											label: 'Excluir',
											onAction: (data) => {
												handleDelete(data?.id, data?.tradingName);
											},
										},
										
									]}
								/>
							),
						},
					]}
				/>
			</WhiteContainer>
		</div>
	);
};

export default Companies;
