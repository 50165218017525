// ! With React.RestrictedMode, couses the following error:
// ! When polygon is completed, it doesn't hide the drawing manager and his polygon
// ! When editing a polygon, it doesn't show the polygon

/* eslint-disable no-undef */
import React, {
	useState,
	useRef,
	useCallback,
	useEffect,
	Dispatch,
} from 'react';
import { Input } from '../../../../components/FormElements/Input';
import {
	GoogleMap,
	DrawingManagerF,
	PolygonF,
	Autocomplete,
	MarkerF,
} from '@react-google-maps/api';
import Button from '../../../../components/Button/Button';

import { MdCenterFocusStrong } from 'react-icons/md';
import { Icon } from '../../../../components/Icon/Icon';
import { useGoogleLoaded } from '../../../../components/GoogleMap/useGoogleLoaded';

const containerStyle = {
	width: '100%',
	height: '100%',
};

export interface Path {
	lat: number;
	lng: number;
}

interface GeoMapProps {
	paths: Path[];
	setPaths: Dispatch<React.SetStateAction<Path[]>>;
	center: Path | undefined;
	setCenter: Dispatch<React.SetStateAction<Path | undefined>>;
	drawMode?: boolean;
}

const GeoMap: React.FC<GeoMapProps> = ({
	paths,
	setPaths,
	center,
	setCenter,
	drawMode = false,
}) => {
	const mapRef = useRef<google.maps.Map>();
	const polygonRef = useRef<google.maps.Polygon>();
	const listenerRef = useRef<google.maps.MapsEventListener[]>();
	const drawingManagerRef = useRef<google.maps.drawing.DrawingManager>();
	const [position, setPosition] = useState<Path>({
		lat: -20.304263349939177,
		lng: -40.32035833997175,
	});
	const [autocomplete, setAutocomplete] =
		useState<google.maps.places.Autocomplete | null>(null);

	const centerMap = (): void => {
		if (mapRef.current && paths.length >= 2) {
			const bounds = new google.maps.LatLngBounds();
			paths.forEach((path) => {
				bounds.extend(new google.maps.LatLng(path.lat, path.lng));
			});
			mapRef.current?.fitBounds(bounds);
		} else {
			mapRef.current?.setCenter(position);
		}
	};

	useEffect(() => {
		if (paths.length <= 0)
			navigator.geolocation.getCurrentPosition(
				(position) => {
					setPosition({
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					});
				},
				(err) => {
					console.error(err);
				}
			);
	}, []);

	const isLoaded = useGoogleLoaded();

	const onAutocompleteLoad = useCallback(
		(autocomplete: google.maps.places.Autocomplete): void => {
			setAutocomplete(autocomplete);
		},
		[]
	);

	const onPlaceChanged = useCallback(() => {
		if (autocomplete) {
			const viewport = autocomplete.getPlace().geometry?.viewport;
			if (viewport) mapRef.current?.fitBounds(viewport);
		}
	}, []);

	const onPolygonComplete = useCallback((poly: google.maps.Polygon) => {
		const polyArray = poly.getPath().getArray();
		const newPaths: Path[] = [];
		polyArray.forEach(function (path) {
			newPaths.push({ lat: path.lat(), lng: path.lng() });
		});
		setPaths(newPaths);

		const bounds = new google.maps.LatLngBounds();
		for (let i = 0; i < newPaths.length; i++) {
			bounds.extend(newPaths[i]);
		}

		const center = bounds.getCenter();
		setCenter({ lat: center.lat(), lng: center.lng() });

		poly.setMap(null);

		if (drawingManagerRef.current)
			drawingManagerRef.current.setDrawingMode(null);
	}, []);

	const onEdit = useCallback(() => {
		if (polygonRef.current) {
			const newPaths = polygonRef.current
				.getPath()
				.getArray()
				.map((latlng) => {
					return { lat: latlng.lat(), lng: latlng.lng() };
				});
			setPaths(newPaths);

			const bounds = new google.maps.LatLngBounds();
			for (let i = 0; i < newPaths.length; i++) {
				bounds.extend(newPaths[i]);
			}

			const center = bounds.getCenter();
			setCenter({ lat: center.lat(), lng: center.lng() });
		}
	}, [setPaths]);

	const onPolygonLoad = useCallback((polygon: google.maps.Polygon) => {
		polygonRef.current = polygon;
		const path = polygon.getPath();
		listenerRef.current = [
			path.addListener('set_at', onEdit),
			path.addListener('insert_at', onEdit),
			path.addListener('remove_at', onEdit),
		];

		if (!center) {
			const newPaths = path.getArray().map((latlng) => {
				return { lat: latlng.lat(), lng: latlng.lng() };
			});

			const bounds = new google.maps.LatLngBounds();
			for (let i = 0; i < newPaths.length; i++) {
				bounds.extend(newPaths[i]);
			}

			const center = bounds.getCenter();
			setCenter({ lat: center.lat(), lng: center.lng() });
		}
	}, []);

	const onMapLoad = useCallback((map: google.maps.Map) => {
		mapRef.current = map;
		centerMap();
	}, []);

	const onDrawingManagerLoad = useCallback(
		(drawingManager: google.maps.drawing.DrawingManager) => {
			drawingManagerRef.current = drawingManager;
		},
		[]
	);

	return (
		<>
			{isLoaded && (
				<GoogleMap
					mapContainerStyle={containerStyle}
					center={position}
					zoom={12}
					options={{
						mapTypeControl: false,
						streetViewControl: false,
					}}
					onLoad={onMapLoad}
				>
					<div className="absolute bottom-0 flex">
						<Button className="w-auto m-2" onClick={centerMap}>
							<MdCenterFocusStrong size={20} />
							Centralizar
						</Button>
						{drawMode && (
							<Button
								variant="red"
								className="w-auto m-2"
								onClick={() => {
									setPaths([]);
									setCenter(undefined);
								}}
							>
								<Icon name="backspace" size={20} />
							</Button>
						)}
					</div>
					{drawMode && (
						<>
							<Autocomplete
								onLoad={onAutocompleteLoad}
								onPlaceChanged={onPlaceChanged}
							>
								<div className="flex justify-center px-5">
									<Input className="absolute max-w-xs m-2 lg:max-w-xl" />
								</div>
							</Autocomplete>
							<DrawingManagerF
								drawingMode={google.maps.drawing.OverlayType.POLYGON}
								options={{
									drawingControl: true,
									drawingControlOptions: {
										drawingModes: [google.maps.drawing.OverlayType.POLYGON],
									},
									polygonOptions: {
										fillColor: 'gray',
										fillOpacity: 0.4,
										strokeColor: 'black',
										strokeWeight: 2,
										clickable: true,
										editable: true,
										draggable: true,
									},
								}}
								onPolygonComplete={onPolygonComplete}
								onLoad={onDrawingManagerLoad}
							/>
						</>
					)}

					<PolygonF
						options={{
							fillColor: 'gray',
							fillOpacity: 0.4,
							strokeColor: 'black',
							strokeWeight: 2,
							clickable: true,
							draggable: false,
							editable: true,
							geodesic: false,
							zIndex: 1,
						}}
						editable
						path={paths}
						onLoad={onPolygonLoad}
						onMouseUp={onEdit}
					/>
					{center && <MarkerF position={center} />}
				</GoogleMap>
			)}
		</>
	);
};

export default React.memo(GeoMap);
