import { FC } from 'react';
import PlusIcon from './svg/PlusIcon';
import ArrowDownIcon from './svg/ArrowDownIcon';
import BarcodeIcon from './svg/BarcodeIcon';
import BellIcon from './svg/BellIcon';
import BuildingIcon from './svg/BuildingIcon';
import CalendarIcon from './svg/CalendarIcon';
import CargoIcon from './svg/CargoIcon';
import CarIcon from './svg/CarIcon';
import ChevronDownIcon from './svg/ChevronDownIcon';
import ChevronLeft from './svg/ChevronLeftIcon';
import ChevronRight from './svg/ChevronRightIcon';
import CopyIcon from './svg/CopyIcon';
import CreditCardIcon from './svg/CreditCardIcon';
import EditIcon from './svg/EditIcon';
import ExclamationCircleIcon from './svg/ExclamationCircleIcon';
import ExternalLinkIcon from './svg/ExternalLinkIcon';
import EyeOffIcon from './svg/EyeOffIcon';
import FileDownloadIcon from './svg/FileDownloadIcon';
import GridIcon from './svg/GridIcon';
import HamburgerIcon from './svg/HamburgerIcon';
import MailCheckIcon from './svg/MailCheckIcon';
import MessageSquareIcon from './svg/MessageSquareIcon';
import MotorcycleIcon from './svg/MotorcycleIcon';
import PixIcon from './svg/PixIcon';
import { SvgProps } from './svg/SvgPropsInterface';
import TrashIcon from './svg/TrashIcon';
import TruckIcon from './svg/TruckIcon';
import UploadIcon from './svg/UploadIcon';
import UserIcon from './svg/UserIcon';
import VanIcon from './svg/VanIcon';
import WalletIcon from './svg/WalletIcon';
import MoreIcon from './svg/MoreIcon';
import ManWithBagIcon from './svg/ManWithBagIcon';
import MotorcycleWithBoxIcon from './svg/MotorcycleWithBoxIcon';
import VanBoxOpenIcon from './svg/VanBoxOpenIcon';
import VanBoxCloseIcon from './svg/VanBoxCloseIcon';
import AdjustmentsIcon from './svg/AdjustmentsIcon';
import XMarkIcon from './svg/XMarkIcon';
import FilterIcon from './svg/FilterIcon';
import BackspaceIcon from './svg/BackspaceIcon';
import ProcessIcon from './svg/ProcessIcon';

export type IconsNames =
	| 'process'
	| 'truck'
	| 'building'
	| 'user'
	| 'upload'
	| 'bell'
	| 'eye-off'
	| 'Hamburger'
	| 'calendar'
	| 'message-square'
	| 'wallet'
	| 'chevron-right'
	| 'chevron-left'
	| 'chevron-down'
	| 'arrow-down'
	| 'trash'
	| 'external-link'
	| 'edit'
	| 'grid'
	| 'motorcycle'
	| 'car'
	| 'van'
	| 'cargo'
	| 'pix'
	| 'barcode'
	| 'credit-card'
	| 'copy'
	| 'mail-check'
	| 'file-download'
	| 'exclamation-circle'
	| 'plus'
	| 'more'
	| 'man-with-bag'
	| 'motorcycle-with-box'
	| 'van-box-open'
	| 'van-box-close'
	| 'adjustments'
	| 'x-mark'
	| 'filter'
	| 'backspace';

export type IconsInterface = {
	[key in IconsNames]: FC<SvgProps>;
};

export const Icons: IconsInterface = {
	process: ProcessIcon,
	truck: TruckIcon,
	building: BuildingIcon,
	user: UserIcon,
	upload: UploadIcon,
	bell: BellIcon,
	'eye-off': EyeOffIcon,
	Hamburger: HamburgerIcon,
	calendar: CalendarIcon,
	wallet: WalletIcon,
	'message-square': MessageSquareIcon,
	'chevron-right': ChevronRight,
	'chevron-left': ChevronLeft,
	'chevron-down': ChevronDownIcon,
	'arrow-down': ArrowDownIcon,
	edit: EditIcon,
	trash: TrashIcon,
	'external-link': ExternalLinkIcon,
	grid: GridIcon,
	motorcycle: MotorcycleIcon,
	car: CarIcon,
	van: VanIcon,
	cargo: CargoIcon,
	pix: PixIcon,
	barcode: BarcodeIcon,
	'credit-card': CreditCardIcon,
	copy: CopyIcon,
	'file-download': FileDownloadIcon,
	'mail-check': MailCheckIcon,
	'exclamation-circle': ExclamationCircleIcon,
	plus: PlusIcon,
	more: MoreIcon,
	'man-with-bag': ManWithBagIcon,
	'motorcycle-with-box': MotorcycleWithBoxIcon,
	'van-box-open': VanBoxOpenIcon,
	'van-box-close': VanBoxCloseIcon,
	adjustments: AdjustmentsIcon,
	'x-mark': XMarkIcon,
	filter: FilterIcon,
	backspace: BackspaceIcon,
};

export interface IconProps {
	color: string;
	size: number;
}
