import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { phoneMask, removePhoneMask } from '../../../helpers/mask/phoneMask';
import { useCopy } from '../../../hooks/useCopy';
import { api } from '../../../services/angular/axios';
import { OrderResponseApi } from '../../../services/angular/types/Order';
import { Avatar } from '../../Avatar';
import { FormHeader } from '../../FormElements';
import WhiteContainer from '../../WhiteContainer/WhiteContainer';

interface ClientProps {
	order?: OrderResponseApi;
}

export const ClientDetails = ({ order }: ClientProps) => {
	const copy = useCopy();

	const { data: requesterAvatar } = useQuery(
		['requesterAvatar', order?.requester?.avatarUrl],
		async () => {
			if (!order?.requester?.avatarUrl) return;
			return await api.get(`${order?.requester?.avatarUrl}`);
		},
		{
			enabled: !!order?.requester?.avatarUrl,
		}
	);

	const { data: requesterCompanyAvatar } = useQuery(
		['requesterCompanyAvatar', order?.requesterCompany?.avatarUrl],
		async () => {
			if (!order?.requesterCompany?.avatarUrl) return;
			return await api.get(`${order?.requesterCompany?.avatarUrl}`);
		},
		{
			enabled: !!order?.requesterCompany?.avatarUrl,
		}
	);

	return (
		<WhiteContainer
			className="h-full gap-4"
			divProps={{
				className: 'w-full ',
			}}
		>
			<FormHeader
				title="Cliente"
				description={
					order?.requestDate &&
					`Pediu em ${dayjs(order?.requestDate).format('DD/MM/YYYY HH:mm')}`
				}
			/>
			<div className="flex w-full gap-4">
				<span className="h-20 w-20 ">
					<Avatar
						src={requesterCompanyAvatar?.data.url}
						avatarProps={{
							className: 'h-20 w-20',
						}}
					/>
				</span>

				<div className="flex w-full flex-col text-sm">
					<span className="text-base font-bold">
						{order?.requesterCompany?.tradingName ||
							order?.requesterCompany?.companyName}
					</span>
					<span>{order?.requesterCompany?.cnpj}</span>
					<span
						className="cursor-copy"
						onClick={() => {
							void copy(removePhoneMask(order?.requesterCompany?.phone, '+55'));
						}}
					>
						{phoneMask(removePhoneMask(order?.requesterCompany?.phone))}
					</span>
					<span className="truncate">{order?.requesterCompany?.email}</span>
				</div>
			</div>
			<hr className="text-neutral-200" />
			<div className="flex gap-4">
				<span className="h-20 w-20 ">
					<Avatar
						src={requesterAvatar?.data.url}
						avatarProps={{
							className: ' h-20 w-20',
						}}
					/>
				</span>
				<div className="flex flex-col text-sm">
					<span className="text-base font-bold">{order?.requester?.name}</span>
					<span className="mb-1 truncate">{order?.requester?.email}</span>
				</div>
			</div>
		</WhiteContainer>
	);
};
