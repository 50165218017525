import React, { useState } from 'react';
import { PageHeader } from '../../../../components/PageHeader';
import { Input, Select, useZodForm } from '../../../../components/FormElements';
import { oldIcons, VehicleIcon } from '../../../../components/Icon/vehicles';
import { Tooltip } from '../../../../components/Tooltip';
import {
	vehicleFilterSchema,
	VehicleFilterSchemaType,
} from './schema/vehicleFilterSchema';
import { useQuery } from '@tanstack/react-query';
import { SortOptions } from '../../../../services/angular/types/Params';
import { api } from '../../../../services/angular/axios';
import { FiltersTab } from '../../../../components/FiltersTab';
import { appState, useAppState } from '../../../../store/appState';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { Table, TableActions } from '../../../../components/Table/Table';
import { twMerge } from 'tailwind-merge';
import useUserState from '../../../../services/angular/angularUserState';
import { toastMessage } from '../../../../helpers/toastMessage';
import { toast } from 'react-toastify';
import { useAlert } from '../../../../contexts/AlertContext';
import { Vehicle } from '../../../../services/angular/types/Order';

export const Vehicles: React.FC = () => {
	const [query, setQuery] = useState<VehicleFilterSchemaType>();
	const geofence = useAppState((appState) => appState.geofence);
	const alert = useAlert();
	const user = useUserState((userState) => userState.user);
	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});
	const form = useZodForm({
		schema: vehicleFilterSchema,
	});

	const {
		data: vehicles,
		isLoading,
		isFetching,
		refetch,
	} = useQuery(
		['vehicles', query, params, user?.id],
		async () =>
			await api.get(
				'/vehicles?include=activeOrders,currentDriver,category,company',
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
						...query,
					},
				}
			),
		{
			enabled: !!user?.id,
		}
	);

	const { data: vehicleCategories, refetch: vehicleRefetch } = useQuery(
		['vehicle-category', form.watch('geofenceId')],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/categories`,
				{
					params: {
						geofenceId: form.watch('geofenceId')?.value,
					},
				}
			)
	);
	const handleDelete = (idToDelete: number, vehicle: string, plate: string) => {
		alert.onCustom({
			title: 'Atenção',
			message: `Deseja mesmo excluir o veículo ${vehicle} - ${plate}?`,
			confirm: {
				onClick: async () => {
					try {
						await api.delete(`/vehicles/${idToDelete}`);
						toast.success(toastMessage.success.deleted);
						void refetch();
					} catch (err) {
						toast.error(toastMessage.error.deleted);
					}
				},
			},
		});
	};

	return (
		<div className="w-full flex-col text-neutral-900">
			<PageHeader
				title="Veículos"
				description="Relação de veículos cadastrados"
			/>
			<FiltersTab
				form={form}
				clearFilters={() => {
					form.reset({
						geofenceId: null,
						categoryId: [],
						status: [],
						availability: [],
					});
					setQuery(undefined);
				}}
				onSubmit={(data) => {
					setQuery(data);
				}}
			>
				<Input
					variant="light"
					label="Placa"
					className="md:w-auto md:min-w-[128px]"
					{...form.register('plate')}
				/>
				<Select
					variant="light"
					label="Geofence"
					className="md:w-auto md:min-w-[128px]"
					controller={{
						control: form.control,
						name: 'geofenceId',
					}}
					options={geofence?.map((state) => ({
						label: state.name,
						value: state.id,
					}))}
					onChange={() => {
						void vehicleRefetch();
					}}
				/>
				<Select
					label="Categoria de veículo"
					variant="light"
					isMulti
					className="md:w-auto md:min-w-[128px]"
					controller={{
						control: form.control,
						name: 'categoryId',
					}}
					options={vehicleCategories?.data.map(
						(category: { name: string; id: number }) => ({
							label: category.name,
							value: category.id,
						})
					)}
				/>
				<Select
					label="Status"
					variant="light"
					isMulti
					className="md:w-auto md:min-w-[128px]"
					controller={{
						control: form.control,
						name: 'status',
					}}
					options={Object.values(appState.approvalStatus).map((opt) => ({
						label: opt.label,
						value: opt.value,
					}))}
				/>
				<Select
					className="md:w-auto md:min-w-[128px]"
					label="Disponibilidade do veículo"
					variant="light"
					isMulti
					controller={{
						control: form.control,
						name: 'availability',
					}}
					options={Object.values(appState.availability).map((opt) => ({
						label: opt.label,
						value: opt.value,
					}))}
				/>
			</FiltersTab>
			<WhiteContainer className="text-sm">
				<Table<Vehicle>
					triggerPageChange={form.formState.isSubmitting}
					isLoading={isLoading || isFetching}
					data={vehicles?.data.data || []}
					total={vehicles?.data.meta.pagination.total || 0}
					disabledSort={{
						categoryId: true,
						available: true,
						updated_at: true,
					}}
					onParamsChange={(params) => {
						setParams(params);
					}}
					columns={[
						{
							accessor: 'categoryId',
							Header: 'Categoria',
							Cell: ({ row }) => {
								const { category, featureOneActive, featureTwoActive } =
									row.original;
								return (
									<div className="flex gap-2">
										<VehicleIcon
											/* @ts-expect-error */
											name={oldIcons[category.icon]}
											size={36}
										/>
										<div>
											{category.name}
											<div className="text-xs text-neutral-600">
												{featureOneActive
													? category.typeOneDescription
													: featureTwoActive
													? category.typeTwoDescription
													: '-'}
											</div>
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'model',
							Header: 'Veículo',
						},
						{
							accessor: 'plate',
							Header: 'Placa',
							Cell: ({ value }) => {
								return (
									<div className="w-min rounded-md border-[1px] p-1 text-xs">
										{value}
									</div>
								);
							},
						},
						{
							accessor: 'companyId',
							Header: 'Empresa',
							Cell: ({ row }) => {
								const { company } = row.original;
								return (
									<div className="flex">
										<div className="flex flex-col gap-1">
											{company.companyName}
											<div className="text-xs text-neutral-600">
												{company.cellphone}
											</div>
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'company',
							Header: 'Região',
							Cell: ({ row }) => {
								const { company } = row.original;
								return (
									<div className="flex flex-col gap-1">
										{company.district}
										<div className="text-xs text-neutral-600">
											{company.city}
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'available',
							Header: 'Disponibilidade',
							Cell: ({ row }) => {
								const { activeOrders, available, currentDriver } = row.original;

								const tooltip = currentDriver
									? available
										? currentDriver.online
											? {
													message: `Veículo com motorista online`,
													className: 'bg-green',
											  }
											: {
													message: `Veículo com motorista offline`,
													className: 'bg-blue',
											  }
										: {
												message: `Entregando pedido ${activeOrders?.[0]?.id}`,
												className: 'bg-yellow-500',
										  }
									: {
											message: `Veículo sem motorista`,
											className: 'bg-terracota-500',
									  };

								return (
									<div className="flex">
										<Tooltip message={tooltip.message}>
											<div
												className={twMerge(
													'h-4 w-4 rounded-full shadow-inner shadow-neutral-700',
													tooltip.className
												)}
											/>
										</Tooltip>
									</div>
								);
							},
						},
						{
							accessor: 'status',
							Header: 'Status',
							Cell: ({ row }) => {
								const { status } = row.original;

								const statusObj =
									status === 1
										? {
												message: `Aguardando aprovação`,
												className: 'bg-orange',
										  }
										: status === 2
										? {
												message: `Rejeitado`,
												className: 'bg-terracota-500',
										  }
										: {
												message: `Aprovado`,
												className: 'bg-green',
										  };

								return (
									<div
										className={twMerge(
											'w-min rounded px-1.5 py-1 text-center text-xs leading-3 text-white',
											statusObj.className
										)}
									>
										{statusObj.message}
									</div>
								);
							},
						},
						{
							accessor: 'observation',
							Header: 'Observação',
							Cell: ({ value }) => (
								<div className="max-w-[192px]">
									<p className="truncate">{value || '-'}</p>
								</div>
							),
						},
						{
							accessor: 'updated_at',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => (
								<TableActions
									row={row}
									actions={[
										{
											icon: 'edit',
											label: 'Editar',
											href: `/veiculos/${row.original.id}`,
										},
										{
											icon: 'trash',
											label: 'Excluir',
											onAction: (data) => {
												handleDelete(
													data?.id,
													data?.category.name,
													data?.plate
												);
											},
										},
									]}
								/>
							),
						},
					]}
				/>
			</WhiteContainer>
		</div>
	);
};
