import { z } from 'zod';

export const PersonalInfoSchema = z.object({
	name: z.string().min(1),
	email: z.string().email({ message: 'Email inválido' }),
	cellphone: z.string().min(1),
	cpf: z.string().min(1),
	pix: z.string().optional(),
});

export type PersonalInfoSchemaType = z.infer<typeof PersonalInfoSchema>;
