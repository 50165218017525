import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const ChevronRightIcon: React.FC<SvgProps> = ({
	color = '#393939',
	size,
	stroke = '3.33333',
}) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_764_436553)">
				<path
					d="M13.3331 8L11.4531 9.88L17.5598 16L11.4531 22.12L13.3331 24L21.3331 16L13.3331 8Z"
					fill="currentColor"
				/>
			</g>
		</svg>
	);
};

export default ChevronRightIcon;
