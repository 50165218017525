import { CompanyModel, Role } from '../services/angular/angularUserState';

export const canBuyCredits = (company?: CompanyModel) =>
	!!company &&
	(company.type === 'Fretista' ||
		company.type === 'Empresa' ||
		company.type === 'Usuário Admin') &&
	!company.allowBankingBill &&
	company.approved;

export const canSeeReceipt = (company?: CompanyModel) =>
	!!company &&
	(company.type === 'Fretista' ||
		(company.type === 'Usuário Admin' && company.makeFreights));

export const canRequestFreight = (company?: CompanyModel) =>
	!!company && company.requestFreights && company.type !== 'Motorista';

export const canSeeWallet = (company?: CompanyModel) =>
	!!company &&
	(company.type === 'Empresa' ||
		company.type === 'Usuário Admin' ||
		company.type === 'Fretista');

export const canManageCompany = (company?: CompanyModel) =>
	!!company &&
	(company.type === 'Empresa' ||
		company.type === 'Usuário Admin' ||
		company.type === 'Fretista');

export const canManageCompanyDrivers = (company?: CompanyModel) =>
	!!company &&
	(company.type === 'Empresa' ||
		company.type === 'Usuário Admin' ||
		company.type === 'Fretista') &&
	company?.makeFreights;

export const canSeeMyBusiness = (company?: CompanyModel) =>
	canManageCompany(company) || canManageCompanyDrivers(company);

export const canSeeBalance = (company?: CompanyModel) =>
	!!company &&
	(company.type === 'Empresa' ||
		company.type === 'Usuário Admin' ||
		company.type === 'Fretista') &&
	company.requestFreights &&
	!company.allowBankingBill &&
	company.approved;

export const canSeeFavorites = (company?: CompanyModel) =>
	!!company &&
	(company.type === 'Empresa' ||
		company.type === 'Usuário Admin' ||
		company.type === 'Fretista') &&
	company.allowFavoriteDriver;
export const canSeeDashboard = (company?: CompanyModel) =>
	!!company &&
	(company.type === 'Empresa' ||
		company.type === 'Fretista' ||
		company.type === 'Usuário Admin') &&
	company.requestFreights;

export const isSuperAdmin = (role?: Role) => !!role && role.name === 'sadmin';
