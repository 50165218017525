import React from 'react';

interface ChipProps {
	label: string;
	backgroundColor: string;
	textColor?: string;
}

const Chip: React.FC<ChipProps> = ({ label, backgroundColor, textColor }) => {
	return (
		<div
			className={`w-fit items-center rounded-md px-3 text-sm font-medium ${
				backgroundColor || ''
			} ${textColor || ''}`}
		>
			<span>{label}</span>
		</div>
	);
};

export default Chip;
