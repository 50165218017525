import React from 'react';
import { Root, Trigger, Portal, Content } from '@radix-ui/react-tooltip';
import { twMerge } from 'tailwind-merge';

interface TooltipProps {
	message?: string | React.ReactNode;
	children: React.ReactNode;
	sideOffset?: number;
	className?: string;
	triggerClassName?: string;
	onClick?: () => void;
}

export const Tooltip: React.FC<TooltipProps> = ({
	message,
	children,
	sideOffset = 2,
	className,
	triggerClassName,
	onClick,
}) => {
	const [open, setOpen] = React.useState(false);

	return (
		<Root open={open}>
			<Trigger
				asChild
				onMouseEnter={() => {
					setOpen(true);
				}}
				onMouseLeave={() => {
					setOpen(false);
				}}
				onFocus={() => {
					setOpen(true);
				}}
				onBlur={() => {
					setOpen(false);
				}}
				onClick={onClick}
			>
				<div
					className={twMerge(
						'max-w-fit cursor-default self-center',
						triggerClassName
					)}
				>
					{children}
				</div>
			</Trigger>
			<Portal>
				{message && (
					<Content
						tabIndex={0}
						className={twMerge(
							'z-50 mx-2 px-2 py-1 text-xs font-bold text-neutral-1',
							'max-w-[192px] select-none break-words rounded bg-neutral-700 shadow',
							className
						)}
						sideOffset={sideOffset}
					>
						{typeof message === 'string' ? <span>{message}</span> : message}
					</Content>
				)}
			</Portal>
		</Root>
	);
};
