import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FormHeader } from '../../../../../components/FormElements';
import { Table } from '../../../../../components/Table/Table';
import { useAlert } from '../../../../../contexts/AlertContext';
import useUserState from '../../../../../services/angular/angularUserState';
import { api } from '../../../../../services/angular/axios';
import { SortOptions } from '../../../../../services/angular/types/Params';
import { CompanyUsers } from '../../CompanyUsers';

const AngularCompanyUsersTable: React.FC = () => {
	const alert = useAlert();
	const [total, setTotal] = useState(0);
	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});
	const company = useUserState((state) => state.company);
	const [companyUsers, setCompanyUsers] = useState([]);

	useEffect(() => {
		const getCompanyUsers = async () => {
			const response = await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/companies/${company?.id}/users`,
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
					},
				}
			);
			setTotal(response.data.meta.pagination.total);
			setCompanyUsers(response.data.data);
		};
		void getCompanyUsers();
	}, [company, params]);

	const deleteCompanyUser = async (id: number) => {
		alert.onCustom({
			title: 'Excluir usuário',
			message: 'Deseja realmente excluir o usuário?',
			confirm: {
				label: 'Excluir',
				onClick: async () => {
					try {
						await api.delete(`/companies/${company?.id || ''}/users/${id}`);
						toast.success('Usuário excluído com sucesso');
					} catch (error) {
						toast.error('Erro ao excluir usuário');
					}
				},
			},
		});
	};

	return (
		<div className="min-w-full pr-4">
			<FormHeader title="Usuários" />
			<Table<CompanyUsers>
				data={companyUsers || []}
				total={total}
				sort
				onParamsChange={(params) => {
					setParams(params);
				}}
				onRowClick={(item) => {}}
				actions={[
					{
						icon: 'trash',
						label: 'Excluir',
						onAction: (data) => {
							void deleteCompanyUser(data.id);
						},
					},
				]}
				columns={[
					{
						accessor: 'name',
						Header: 'Nome',
						Cell: ({ value }) => (
							<div className="flex items-center justify-center">{value}</div>
						),
					},
					{
						accessor: 'cpf',
						Header: 'CPF',
						Cell: ({ value }) => (
							<div className="flex items-center justify-center">{value}</div>
						),
					},

					{
						accessor: 'isAdmin',
						Header: 'Perfil',
						width: '10%',
						Cell: ({ value }) => (
							<span>
								<div className="text-xs text-white">
									{value ? (
										<div className="rounded bg-blue text-center">
											<span className="">Administrador</span>
										</div>
									) : (
										<div className="rounded bg-neutral-400 text-center">
											<span>Usuário</span>
										</div>
									)}
								</div>
							</span>
						),
					},
				]}
			/>
		</div>
	);
};

export default AngularCompanyUsersTable;
