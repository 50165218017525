const ptBr = {
	name: 'pt-BR',
	months: [
		['Janeiro', 'Jan'],
		['Fevereiro', 'Fev'],
		['Março', 'Mar'],
		['Abril', 'Abr'],
		['Maio', 'Mai'],
		['Junho', 'Jun'],
		['Julho', 'Jul'],
		['Agosto', 'Ago'],
		['Setembro', 'Set'],
		['Outubro', 'Out'],
		['Novembro', 'Nov'],
		['Dezembro', 'Dez'],
	],
	weekDays: [
		['Sábado', 'Sáb'],
		['Domingo', 'Dom'],
		['Segunda', 'Seg'],
		['Terça', 'Ter'],
		['Quarta', 'Qua'],
		['Quinta', 'Qui'],
		['Sexta', 'Sex'],
	],
	digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
	meridiems: [
		['AM', 'am'],
		['PM', 'pm'],
	],
};

export default ptBr;
