import { twMerge } from 'tailwind-merge';
import './styles.css';

interface EllipsisLoadingProps {
	className?: string;
	dotClassName?: string;
}

export const EllipsisLoading = ({
	className,
	dotClassName,
}: EllipsisLoadingProps) => {
	return (
		<div
			className={twMerge('loader-dots relative mt-2 block h-5 w-20', className)}
		>
			<div
				className={twMerge(
					'absolute top-0 mt-1 h-3 w-3 rounded-full bg-neutral-500',
					dotClassName
				)}
			/>
			<div
				className={twMerge(
					'absolute top-0 mt-1 h-3 w-3 rounded-full bg-neutral-500',
					dotClassName
				)}
			/>
			<div
				className={twMerge(
					'absolute top-0 mt-1 h-3 w-3 rounded-full bg-neutral-500',
					dotClassName
				)}
			/>
			<div
				className={twMerge(
					'absolute top-0 mt-1 h-3 w-3 rounded-full bg-neutral-500',
					dotClassName
				)}
			/>
		</div>
	);
};
