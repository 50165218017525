import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../../services/angular/axios';
import { useEffect, useState } from 'react';
import { oldIcons, VehicleIcon } from '../../../../../components/Icon/vehicles';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import DriverOnline from './driverOnline';
import { twMerge } from 'tailwind-merge';
import { Loading } from '../../../../../components/Loading';
import useUserState from '../../../../../services/angular/angularUserState';
import { Category } from '../../../../../services/angular/types/Order';

const OnlineByCategory = () => {
	const geofences = useUserState((state) => state.geofences);
	const [categoryId, setCategoryId] = useState<number>();
	const [selectedGeofence, setSelectedGeofence] = useState<number | undefined>(
		geofences?.[0]?.id
	);

	useEffect(() => {
		if (!geofences.length) setSelectedGeofence(undefined);
		if (!selectedGeofence) setSelectedGeofence(geofences?.[0]?.id);
	}, [geofences]);

	const { data: olineByCategory } = useQuery(
		[
			'onlineByCategory',
			geofences
				.map((g) => g.id)
				.sort((a, b) => a - b)
				.join(','),
		],
		async () =>
			(
				await api.get<
					Array<{ category: Category; onlineDriversCount: number }>
				>('/drivers/onlineByCategory', {
					params: {
						geofenceId: geofences?.map((g) => g.id).join(','),
					},
				})
			).data,
		{
			refetchInterval: 20 * 1000, // 20 seconds
		}
	);

	const { data: driversOnline, isFetching } = useQuery(
		['drivers-online', categoryId],
		async () =>
			await api.get(`/drivers/online`, {
				params: {
					categoryId,
					include: 'vehicle.category',
				},
			}),
		{
			enabled: !!categoryId,
		}
	);

	return (
		<div className="flex w-full flex-col justify-end">
			<div className="flex select-none flex-row flex-wrap items-center justify-end">
				{geofences
					?.sort((a, b) => a.id - b.id)
					.map((geofence) => (
						<div
							key={geofence.id}
							className={twMerge(
								'w-auto cursor-pointer rounded-t border-b border-neutral-400 px-2 py-1',
								selectedGeofence === geofence.id
									? 'border-x border-b-0 border-t bg-neutral-100/80'
									: 'bg-neutral-50'
							)}
							onClick={() => {
								setSelectedGeofence(geofence.id);
							}}
						>
							{geofence.name}
						</div>
					))}
			</div>
			<div className="flex w-auto select-none flex-row items-center justify-end gap-2 p-2">
				{olineByCategory
					?.filter(
						({ category }) =>
							category?.geofenceId === selectedGeofence && geofences.length
					)
					.map(({ category, onlineDriversCount }) => (
						<DropdownMenu.Root
							key={category?.id}
							onOpenChange={(open) => {
								if (open) {
									setCategoryId(category?.id);
								}
							}}
						>
							<DropdownMenu.Trigger className="rounded focus:outline-none hover:underline">
								<div className="relative flex w-auto cursor-pointer flex-col items-center rounded-lg p-1 pt-1.5 hover:bg-yellow-500/50">
									<VehicleIcon
										// @ts-expect-error
										name={oldIcons[category?.icon]}
										size={40}
									/>
									<span className="absolute right-0.5 top-0.5 h-4 w-auto rounded-full bg-terracota-500 px-1 text-center text-xs text-white">
										{Number(onlineDriversCount)}
									</span>
								</div>
							</DropdownMenu.Trigger>
							<DropdownMenu.Portal>
								<DropdownMenu.Content className="mr-2 flex max-h-[40vh] w-[calc(100vw-32px)] min-w-[384px] flex-col gap-2 overflow-scroll rounded bg-white p-4 text-neutral-0 shadow shadow-neutral-0 md:ml-0 md:w-auto">
									<h1 className="mb-4 border-b-2 border-neutral-500 font-bold">
										Entregadores com {category?.name}
									</h1>
									{isFetching ? (
										<div className="flex justify-center">
											<Loading className="h-16" />
										</div>
									) : driversOnline?.data?.length ? (
										driversOnline?.data.map((driver: any) => (
											<DriverOnline driver={driver} key={driver.id} />
										))
									) : (
										<p>Nenhum entregador online</p>
									)}
								</DropdownMenu.Content>
							</DropdownMenu.Portal>
						</DropdownMenu.Root>
					))}
			</div>
		</div>
	);
};

export default OnlineByCategory;
