import React, { memo } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Avatar } from '../../Avatar';
import { BsWhatsapp } from 'react-icons/bs';
import { Tooltip } from '../../Tooltip';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../services/angular/axios';
import { phoneMask, removePhoneMask } from '../../../helpers/mask/phoneMask';
import { OrderResponseApi } from '../../../services/angular/types/Order';
import useUserState from '../../../services/angular/angularUserState';
import { twMerge } from 'tailwind-merge';
import { TbHeartFilled } from 'react-icons/tb';
import { AiFillStar } from 'react-icons/ai';
import { AdminTags } from '../AdminTags';
import { useCopy } from '../../../hooks/useCopy';
import { isEqual } from 'lodash';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface DriverDropdownProps {
	order: OrderResponseApi;
}

export const DriverDropdown = memo(function DriverDropdown({
	order,
}: DriverDropdownProps) {
	const copy = useCopy();
	const role = useUserState((state) => state.userRole);

	const {
		data: driverAvatar,
		refetch: refetchAvatar,
		isFetched,
	} = useQuery(
		['user-avatar', order.driver?.avatar],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: order.driver.avatar,
				},
			});
		},
		{
			enabled: false,
		}
	);

	const {
		data: companyAvatar,
		refetch: refetchCompanyAvatar,
		isFetched: isCompanyFetched,
	} = useQuery(
		['company-avatar', order.driverCompany?.avatar],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: order.driverCompany.avatar,
				},
			});
		},
		{
			enabled: false,
		}
	);

	return (
		<div className="flex items-center pr-4">
			{order.driver && order.driverCompany ? (
				<DropdownMenu.Root
					onOpenChange={() => {
						if (!isFetched && order?.driver?.avatar) void refetchAvatar();

						if (!isCompanyFetched && order?.driverCompany?.avatar)
							void refetchCompanyAvatar();
					}}
				>
					<DropdownMenu.Trigger className="group w-full rounded focus:outline-none">
						<AdminTags
							tags={role?.name !== 'user' ? order?.driver?.tags : []}
							triggerClassName="cursor-pointer"
						>
							<div className="flex flex-col items-start">
								<div
									className={twMerge(
										'line-clamp-3 text-left text-sm font-medium leading-none group-hover:underline',
										role?.name !== 'user' &&
											order?.isFirstFreight &&
											'text-blue'
									)}
								>
									{order.driver.name}
								</div>
								<p className="text-xs text-neutral-500">Fretista</p>

								{role?.name !== 'user' && order?.isFirstFreight && (
									<Tooltip
										message="Primeiro frete"
										triggerClassName="text-blue self-start"
									>
										<TbHeartFilled size={16} />
									</Tooltip>
								)}
							</div>
						</AdminTags>
					</DropdownMenu.Trigger>
					<DropdownMenu.Portal>
						<DropdownMenu.Content className="ml-2 w-[calc(100vw-32px)] min-w-[256px] rounded bg-white p-4 text-neutral-0 shadow shadow-neutral-0 md:ml-0 md:w-auto">
							<div className="flex items-end justify-between font-bold">
								<h1 className="text-lg ">Fretista</h1>
								<p className="text-lg text-orange">#{order.id}</p>
							</div>
							<hr className="my-2 text-neutral-500" />
							<div className="flex items-center gap-4">
								<span className="h-16 w-16 ">
									<Avatar
										src={companyAvatar?.data.url}
										avatarProps={{
											className: ' h-16 w-16',
										}}
									/>
								</span>
								<div className="flex flex-col">
									<div className="flex max-w-xs items-center justify-between gap-2 font-bold">
										{order.driverCompany?.tradingName ||
											order.driverCompany?.companyName}
										<Link
											className="cursor-pointer hover:scale-125"
											to={`/empresas/${order.driverCompany.id}`}
										>
											<FiExternalLink />
										</Link>
									</div>
									<span className="truncate pb-1 text-xs">
										{order.driverCompany?.email ||
											order.driverCompany?.supportEmail}
									</span>
									<span
										className="cursor-copy text-sm"
										onClick={() => {
											void copy(
												removePhoneMask(
													order.driverCompany?.cellphone ||
														order.driverCompany?.phone
												)
											);
										}}
									>
										{phoneMask(
											removePhoneMask(
												order.driverCompany?.cellphone ||
													order.driverCompany?.phone
											)
										)}
									</span>
								</div>
							</div>
							<hr className="mb-2 mt-1 text-neutral-500" />
							<div className="flex items-center gap-4">
								<span className="relative h-16 w-16 ">
									{order?.isFavoriteDriver && (
										<Tooltip
											message="Motorista favorito"
											triggerClassName="absolute -top-2 -right-2"
										>
											<AiFillStar className="text-yellow-submenu" size={24} />
										</Tooltip>
									)}
									<Avatar
										src={driverAvatar?.data.url}
										avatarProps={{
											className: ' h-16 w-16',
										}}
									/>
								</span>

								<div className="flex flex-col text-sm">
									<div className="flex max-w-xs items-center justify-between gap-2 font-bold">
										{order.driver.name}
										<Link
											className="cursor-pointer hover:scale-125"
											to={`/usuarios/${order.driver.id}`}
										>
											<FiExternalLink />
										</Link>
									</div>
									<span className="truncate pb-1 text-xs">
										{order.driver.email}
									</span>
									<span className="flex gap-2">
										<span
											className="cursor-copy"
											onClick={() => {
												void copy(
													removePhoneMask(order.driver?.cellphone, '+55')
												);
											}}
										>
											{phoneMask(removePhoneMask(order.driver?.cellphone))}
										</span>
										{order.driver?.cellphone && (
											<Tooltip message="Abrir whatsapp">
												<BsWhatsapp
													onClick={() => {
														window.open(
															`https://api.whatsapp.com/send?phone=55${order.driver.cellphone.replace(
																/\D/g,
																''
															)}&text=${encodeURI(
																`Olá ${order.driver.name.split(' ')[0]}`
															)}`,
															'_blank'
														);
													}}
													className="cursor-pointer text-green"
													size={16}
												/>
											</Tooltip>
										)}
									</span>
								</div>
							</div>
						</DropdownMenu.Content>
					</DropdownMenu.Portal>
				</DropdownMenu.Root>
			) : (
				<></>
			)}
		</div>
	);
},
isEqual);
