import dayjs from 'dayjs';
import { z } from 'zod';
import { zodMessage } from '../../../../services/reactQuery/helper/zodMessage';

export const DriverInfoSchema = z.object({
	driverLicense: z.string().min(1, { message: zodMessage.required }),
	driverLicenseExpiration: z
		.any()
		// .min(1, { message: zodMessage.required })
		.transform((value) => dayjs(value).toISOString()),
	rg: z.string().optional(),
	driverLicenseImg: z.string().min(1),
});

export type DriverInfoSchemaType = z.infer<typeof DriverInfoSchema>;
