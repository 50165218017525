import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { api } from './axios';
import { AxiosResponse } from 'axios';
import { ProfileType } from './angularUserState';
import { OrderResponseApi } from './types/Order';

interface Data {
	companyId?: number;
	companyType?: ProfileType;
	status: 'historic' | 'scheduled' | 'progress' | 'draft';
	page: number;
	take: number;
	sortBy?: {
		id: string;
		order: 'asc' | 'desc';
	};
	query?: object;
}

export const useGetFreights = (
	data: Data,
	options?: Omit<
		UseQueryOptions<unknown, unknown, AxiosResponse<RootObject>, any[]>,
		'queryKey' | 'queryFn'
	>
) =>
	useQuery(
		['freight', { ...data }],
		async () => {
			if (!data.companyId || !data.companyType) return;

			return await api.get(
				`/orders/schedules/companies/${data.companyId}?include=locations,locations.protocols,incident,status,paymentType,requesterCompany,requester,driver,driverCompany,category,integrationOrder`,
				{
					params: {
						status: data.status,
						profile: data.companyType,
						page: data.page,
						pageSize: data.take,
						sort: data.sortBy
							? `${data.sortBy?.id}:${data.sortBy?.order}`
							: undefined,
						...data.query,
					},
				}
			);
		},
		{
			...options,
		}
	);

interface Pagination {
	total: number;
	count: number;
	per_page: string;
	current_page: string;
	total_pages: number;
	links: any[];
}

interface Meta {
	pagination: Pagination;
}

export interface RootObject {
	data: OrderResponseApi[];
	meta: Meta;
}
