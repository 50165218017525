import dayjs from 'dayjs';
import { useMemo } from 'react';
import { api } from '../../../../../services/angular/axios';
import { useQuery } from '@tanstack/react-query';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {
	OrderType,
	OrderTypeIcon,
} from '../../../../../components/Icon/OrderTypeIcon';
import { twMerge } from 'tailwind-merge';
import { appState } from '../../../../../store/appState';
import { GoogleMap } from '../../../../../components/GoogleMap';
import { LocationsAccordion } from '../../../../../components/Order/LocationsAccordion';
import { MarkerF } from '@react-google-maps/api';
import { useGoogleLoaded } from '../../../../../components/GoogleMap/useGoogleLoaded';
import { OrderResponseApi } from '../../../../../services/angular/types/Order';
import { LeafletMap } from '../../../../../components/Leaflet';
import { Marker } from 'react-leaflet';
import { customMarkerIcon } from '../../../../../components/Leaflet/icons/customIcon';
import { useNewMaps } from '../../../../../hooks/useMaps';

interface LocationsComponentProps {
	order: OrderResponseApi;
}

const LocationsComponent = ({ order }: LocationsComponentProps) => {
	const isLoaded = useGoogleLoaded();
	const { toggleMaps } = useNewMaps();
	const bounds = useMemo(() => {
		if (!order?.locations || !isLoaded) return;
		// eslint-disable-next-line no-undef
		const bounds = new google.maps.LatLngBounds();
		order?.locations?.forEach((point) => {
			bounds.extend(
				// eslint-disable-next-line no-undef
				new google.maps.LatLng(point.lat, point.lng)
			);
		});
		return bounds;
	}, [order?.locations, isLoaded]);

	const distance = useMemo(
		() => `${order.distance > 1 ? Math.round(order.distance) : 1}Km`,
		[order.distance]
	);

	const duration = useMemo(
		() => dayjs.duration(order.duration, 'seconds'),
		[order.duration]
	);

	const {
		data: orderTypeData,
		refetch: orderTypeRefetch,
		isFetched,
	} = useQuery(
		['orderTypeData', order.id],
		async () =>
			await api.get(
				`/orders/${order.id}?include=category,status,locations,locations.protocols,locations.receiverType,optionals,requesterCompany,requester,driver,driverCompany.owner`,
				{
					params: {
						companyId: order.requesterCompanyId,
					},
				}
			),
		{
			enabled: false,
		}
	);

	return (
		<DropdownMenu.Root
			onOpenChange={() => {
				if (!isFetched) void orderTypeRefetch();
			}}
		>
			<DropdownMenu.Trigger
				className={twMerge('flex w-full gap-1 rounded focus:outline-none')}
			>
				<OrderTypeIcon type={String(order.orderTypeId) as OrderType} />
				<div
					className={twMerge(
						'flex w-min flex-col text-sm text-neutral-800',
						order.statusId !== appState.orderStatus.Finalizado.id && 'leading-3'
					)}
				>
					<div className="flex gap-1 font-medium">
						{order.orderTypeId ===
						Number(appState.orderTypes['Rota livre'].value)
							? 'ROTA LIVRE'
							: distance}
						{!!order.invoiceLimit && (
							<p
								className={twMerge(
									'border-l border-neutral-500 pl-1 font-medium',
									order.totalInvoicesRegistered === order.invoiceLimit
										? 'text-green'
										: 'text-orange'
								)}
							>
								{`${order.totalInvoicesRegistered}/${order.invoiceLimit}`}
							</p>
						)}
					</div>
					{order.statusId === appState.orderStatus.Finalizado.id ? (
						<div className="flex gap-1 self-start text-sm text-neutral-600">
							{order.orderTypeId ===
								Number(appState.orderTypes['Rota livre'].value) && (
								<>
									{distance}
									{' - '}
								</>
							)}
							{duration.format(
								duration.days() > 0
									? 'D[d] H[h]'
									: duration.hours() > 0
									? 'H[h] m[min]'
									: 'm[min]'
							)}
						</div>
					) : (
						<>
							{order.orderTypeId ===
							Number(appState.orderTypes['Rota livre'].value) ? (
								''
							) : (
								<p className="text-start text-2xs text-neutral-500">
									Distância aproximada
								</p>
							)}
						</>
					)}
				</div>
			</DropdownMenu.Trigger>

			<DropdownMenu.Portal>
				<DropdownMenu.Content className="mr-4 flex w-[calc(100vw-32px)] flex-col gap-4 overflow-y-scroll rounded bg-white p-4 text-neutral-0 shadow shadow-neutral-0 md:w-auto md:flex-row">
					<div className="max-h-[35vh] overflow-scroll md:max-w-[25vw]">
						<LocationsAccordion order={order} />
					</div>
					<div className="sticky top-0 h-[35vh] w-[80vw] rounded md:h-[50vh] md:max-h-[35vh] md:w-[35vw]">
						{ 
						!toggleMaps?.useLeaflet ? (
							<GoogleMap
								options={{
									mapTypeControl: false,
									streetViewControl: false,
									fullscreenControl: false,
									keyboardShortcuts: false,
									zoomControl: false,
									disableDefaultUI: true,
									clickableIcons: false,
									disableDoubleClickZoom: true,
									panControl: false,
									gestureHandling: "none",
								}}
								center={bounds}
								legs={orderTypeData?.data.routes?.[0]?.directions?.legs}
							>
								{order.locations.map((location, index) => (
									<MarkerF
										key={index}
										clickable={false}
										position={{
											lat: location.lat,
											lng: location.lng,
										}}
										label={{
											text: `${location.sequence + 1}`,
											fontWeight: 'bold',
											color: 'black',
											className:
												'absolute top-[38px] left-[50%] transform -translate-x-1/2',
										}}
										icon={{
											url:
												location.checkinDate && location.checkoutDate
													? '/markerGreen.png'
													: location.checkinDate && location.reasonDate
													? '/markerRed.png'
													: location.checkinDate
													? '/markerYellow.png'
													: '/markerBlue.png',
										}}
									/>
								))}
							</GoogleMap>
						) : (
							<LeafletMap
								center={order?.locations}
								polylineString={orderTypeData?.data.routes?.[0]?.directions?.overview_polyline.points ?? ""}
								dynamicMap={false}
								layers={toggleMaps.layers}
							>
							{order?.locations.map((item) => (
								<Marker
									key={item.id}
									position={[item.lat, item.lng]}
									icon={
									item.checkinDate && item.checkoutDate
										? customMarkerIcon("#00FF00", `${item.sequence + 1}`)
										: item.checkinDate && item.reasonDate
										? customMarkerIcon("#FF0000", `${item.sequence + 1}`)
										: item.checkinDate
										? customMarkerIcon("#FFFF00", `${item.sequence + 1}`)
										: customMarkerIcon("#00FFFF", `${item.sequence + 1}`)
									}
									draggable={false}
								>
								</Marker>
								))}
							</LeafletMap>
						)}
					</div>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

export default LocationsComponent;
