import { useQuery } from '@tanstack/react-query';
import { BsWhatsapp } from 'react-icons/bs';
import { Avatar } from '../../../../../components/Avatar';
import { Tooltip } from '../../../../../components/Tooltip';
import { dateFormat } from '../../../../../helpers/format/dateFormat';
import {
	phoneMask,
	removePhoneMask,
} from '../../../../../helpers/mask/phoneMask';
import { useCopy } from '../../../../../hooks/useCopy';
import { api } from '../../../../../services/angular/axios';
import { Driver } from '../../../../../services/angular/types/Order';

interface DriverOnlineProps {
	driver: Driver;
}

const DriverOnline = ({ driver }: DriverOnlineProps) => {
	const copy = useCopy();

	const { data: driverData } = useQuery(
		['user-avatar', driver.avatar],
		async () => {
			if (!driver?.avatar) return;
			return await api.get(`/assets?`, {
				params: {
					imageName: driver?.avatar,
				},
			});
		},
		{
			enabled: !!driver?.avatar,
		}
	);

	return (
		<div className="flex items-center gap-4" key={driver.id}>
			<div>
				{driver.online && driver.awaitingFreight ? (
					<div className="h-4 w-4 rounded-full bg-green shadow-inner shadow-neutral-700" />
				) : driver.online && !driver.awaitingFreight ? (
					<div className="h-4 w-4 rounded-full bg-orange shadow-inner shadow-neutral-700" />
				) : !driver.online && driver.awaitingFreight ? (
					<div className="h-4 w-4 rounded-full bg-blue shadow-inner shadow-neutral-700" />
				) : (
					<div className="h-4 w-4 rounded-full bg-orange shadow-inner shadow-neutral-700" />
				)}
			</div>
			<div className="h-16 w-16 ">
				<Avatar
					src={driverData?.data.url}
					avatarProps={{
						className: ' h-16 w-16',
					}}
				/>
			</div>
			<div className="flex w-full flex-col gap-1 text-xs">
				<span className="text-base font-bold">{driver?.name}</span>
				{(driver?.vehicle?.featureOneActive ||
					driver?.vehicle?.featureTwoActive) && (
					<>
						{driver?.vehicle?.featureOneActive
							? driver?.vehicle?.category?.typeOneDescription
							: driver?.vehicle?.category?.typeTwoDescription}
					</>
				)}
				<span className="flex justify-between text-neutral-500">
					<span
						className="cursor-copy"
						onClick={() => {
							void copy(removePhoneMask(driver?.cellphone, '+55'));
						}}
					>
						{phoneMask(removePhoneMask(driver?.cellphone))}
					</span>
					{driver?.cellphone && (
						<Tooltip message="Abrir whatsapp">
							<BsWhatsapp
								onClick={() => {
									window.open(
										`https://api.whatsapp.com/send?phone=55${driver.cellphone.replace(
											/\D/g,
											''
										)}&text=${encodeURI(`Olá ${driver.name.split(' ')[0]}`)}`,
										'_blank'
									);
								}}
								className="cursor-pointer text-green"
								size={14}
							/>
						</Tooltip>
					)}
				</span>
				<span className="text-neutral-500">
					Online em: {dateFormat(driver?.latestOnline)}
				</span>
			</div>
		</div>
	);
};

export default DriverOnline;
