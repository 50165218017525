import React from 'react';
// @ts-expect-error
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { Link } from 'react-router-dom';
import { BiMenuAltLeft, BiMenu } from 'react-icons/bi';
import DropDownMenu from './DropDownMenuHeader/DropDownMenu';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { api } from '../../services/angular/axios';
import useUserState from '../../services/angular/angularUserState';
import { useQuery } from '@tanstack/react-query';
import { sideBarAtom } from '../SideBar';
import { useAtom } from 'jotai';
import { canSeeBalance } from '../../helpers/permission';

interface HeaderProps {
	followComponent?: React.ReactNode;
}

const Header = ({ followComponent }: HeaderProps) => {
	const [isSidebarOpen, setIsSidebarOpen] = useAtom(sideBarAtom);

	const showBalance = useUserState((state) => state.showBalance);
	const toggleShowBalance = useUserState((state) => state.toggleShowBalance);
	const company = useUserState((state) => state.company);
	const role = useUserState((state) => state.userRole);

	const { data } = useQuery(
		['balance', company?.id],
		async () =>
			await api.get(
				`/companies/${company?.id || ''}/walletSummary/get-balance`
			),
		{
			enabled: !!company?.id && (company.isAdminUser || company.isOwner),
		}
	);

	return (
		<div className="fixed z-10 flex h-16 w-full select-none items-center justify-between bg-neutral-900 px-3">
			{followComponent ? (
				<>{followComponent}</>
			) : (
				<div className="flex items-center gap-4">
					{isSidebarOpen ? (
						<BiMenuAltLeft
							size={32}
							className="cursor-pointer rounded text-yellow-200 sm:hover:bg-yellow-200 sm:hover:text-neutral-0"
						/>
					) : (
						<BiMenu
							size={32}
							className="cursor-pointer rounded text-yellow-200 sm:hover:bg-yellow-200 sm:hover:text-neutral-0"
							onClick={() => {
								setIsSidebarOpen(true);
							}}
						/>
					)}

					{canSeeBalance(company) && data && (
						<div className="hidden flex-col rounded-lg px-3 text-start text-yellow-200 ring-1 ring-yellow-200 md:flex">
							<button
								className="flex min-h-[1.75rem] cursor-pointer items-center justify-center gap-2 text-lg font-semibold"
								onClick={(e) => {
									e.stopPropagation();

									toggleShowBalance();
								}}
							>
								{showBalance ? (
									<>
										<BsFillEyeFill />
										{data?.data.toLocaleString('pt-br', {
											style: 'currency',
											currency: 'BRL',
										})}
									</>
								) : (
									<>
										<BsFillEyeSlashFill /> R$ • • • •
									</>
								)}
							</button>
							<Link
								to="/carteira/comprar-creditos"
								target="_blank"
								className="cursor-pointer text-center text-xs font-medium hover:underline"
							>
								Adicionar saldo
							</Link>
						</div>
					)}
				</div>
			)}

			<Link
				to={
					role
						? role?.name === 'user'
							? '/negocios/gerenciador'
							: '/operacoes'
						: '/login'
				}
				className="fixed left-1/2 -translate-x-1/2 cursor-pointer text-yellow-400"
			>
				<Logo height={48} />
			</Link>

			<DropDownMenu />
		</div>
	);
};

export default Header;
