import { useQuery } from "@tanstack/react-query";
import { integrationApi } from "../services/angular/axios";

export const useNewMaps = () => {
    const { data, refetch, isLoading, ...rest } = useQuery({
        queryKey: ['googleMapsStatus'],
        queryFn: async () => await integrationApi.get('/dynamicmaps'),
        select: ({ data }) => data,
    })

    if (rest.error) console.log('useUser', rest.error)

    return {
        toggleMaps: data,
        refetchMaps: refetch,
        isLoadingMaps: isLoading,
    }
}
