import React, { useState } from 'react';
import HexBtnCompany from '../../../../assets/svg/HexBtnCompany';
import HexBtnDriver from '../../../../assets/svg/HexBtnFreighter';
import { FormHeader } from '../../../../components/FormElements';
import { Modal } from '../../../../components/Modal';

interface RegisterSelectProps {
	profile: 'client' | 'driver' | undefined;
	setProfile: (profile: 'client' | 'driver' | undefined) => void;
}

const RegisterSelect = ({ profile, setProfile }: RegisterSelectProps) => {
	const [open, setOpen] = useState(false);

	return (
		<>
			<FormHeader title="Escolha como quer começar:" />
			<div className="w-1/2">
				<div className="flex flex-col sm:flex-row items-center justify-center gap-4">
					<div
						className="w-32 cursor-pointer hover:brightness-110 md:w-56"
						onClick={() => {
							setProfile('client');
						}}
					>
						{profile === 'client' ? (
							<HexBtnCompany
								backgroundColor="#fcf366"
								borderColor="#393939"
								textColor="#393939"
							/>
						) : (
							<HexBtnCompany />
						)}
					</div>
					<div
						className="w-32 cursor-pointer hover:brightness-110 md:w-56"
						onClick={() => {
							setProfile('driver');
						}}
					>
						{profile === 'driver' ? (
							<HexBtnDriver
								backgroundColor="#fcf366"
								borderColor="#393939"
								textColor="#393939"
							/>
						) : (
							<HexBtnDriver />
						)}
					</div>
				</div>
				<Modal
					open={open}
					setOpen={setOpen}
					title="Fazer entregas nunca foi tão fácil"
					description="Para acessar como fretista você precisa se cadastrar pelo aplicativo BeeBee"
				>
					<img
						className="w-36 cursor-pointer hover:opacity-90"
						src={require('../../../../assets/images/google-play.png')}
					/>
				</Modal>
			</div>
		</>
	);
};

export default RegisterSelect;
