export const defaultMenu = [
	{
		name: 'Todos',
		value: 5,
		selected: true,
	},
	{
		name: 'Motorista',
		value: 0,
		selected: false,
	},
	{
		name: 'Pessoa física',
		value: 3,
		selected: false,
	},
	{
		name: 'Empresa',
		value: 1,
		selected: false,
	},
	{
		name: 'Fretista',
		value: 2,
		selected: false,
	},
] as const;

export const availability = [
	{ value: '0', label: 'Indisponível' },
	{ value: '1', label: 'Fazendo frete' },
	{ value: '2', label: 'Disponível e offline' },
	{ value: '3', label: 'Disponível e online' },
] as const;

export const blockedOptions = {
	false: 'Desbloqueado',
	true: 'Bloqueado',
} as const;

export const wasTrainedOptions = {
	true: 'Treinado',
	false: 'Sem Treinamento',
} as const;

export const roleIdOptions = {
	1: 'Super',
	2: 'Admin',
	3: 'Usuário',
} as const;

export const activeOptions = {
	true: 'Ativa',
	false: 'Inativa',
} as const;

export const driverStatusOptions = {
	1: 'Aguardando',
	2: 'Rejeitado',
	3: 'Aprovado',
} as const;
