/* eslint-disable no-undef */
import { useMemo } from 'react';
import { RequestFreightForm } from '..';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { MarkerF } from '@react-google-maps/api';
import { GoogleMap } from '../../../../../components/GoogleMap';
import { Order } from '../../../../../components/Order';
import { OrderResponseApi } from '../../../../../services/angular/types/Order';
import { LeafletMap } from '../../../../../components/Leaflet';
import { Marker } from 'react-leaflet';
import { customMarkerIcon } from '../../../../../components/Leaflet/icons/customIcon';
import { useNewMaps } from '../../../../../hooks/useMaps';

interface ConfirmationStepProps {
	form: RequestFreightForm;
	draftOrder?: OrderResponseApi;
}

export const ConfirmationStep = ({
	form,
	draftOrder,
}: ConfirmationStepProps) => {

	const { toggleMaps } = useNewMaps(); // Variável para controlar o uso do Google Maps

	const bounds = useMemo(() => {
		if (!draftOrder?.locations) return;
		const bounds = new google.maps.LatLngBounds();
		draftOrder?.locations?.forEach((point) => {
			bounds.extend(new google.maps.LatLng(point.lat, point.lng));
		});
		return bounds;
	}, [draftOrder?.locations]);

	return (
		<span className="mb-14 flex flex-col gap-4">
			<Order.FreightSummary form={form} order={draftOrder} />
			<WhiteContainer className="flex gap-4 lg:flex-row ">
				<div className="w-full lg:w-1/2">
					<Order.LocationsAccordion header order={draftOrder} />
				</div>
				<div className="h-[35vh] w-full rounded md:h-[50vh]">
				{
				!toggleMaps?.useLeaflet ? (
						<GoogleMap
							center={bounds}
							legs={draftOrder?.routes[0]?.directions?.legs}
							options={{
								mapTypeControl: false,
								streetViewControl: false,
								fullscreenControl: false,
								keyboardShortcuts: false,
								zoomControl: false,
								disableDefaultUI: true,
								clickableIcons: false,
								disableDoubleClickZoom: true,
								panControl: false,
								gestureHandling: 'none',
							}}
						>
							{draftOrder?.locations.map((item, index) => (
								<MarkerF
									key={index}
									position={{
										lat: item.lat,
										lng: item.lng,
									}}
									label={{
										text: `${item.sequence + 1}`,
										fontWeight: 'bold',
										color: 'black',
										className:
											'absolute top-[38px] left-[50%] transform -translate-x-1/2',
									}}
									icon={{
										url: '/markerBlue.png',
									}}
								/>
							))}
						</GoogleMap>

					) : (
					<LeafletMap 
						center={draftOrder?.locations}
						polylineString={draftOrder?.routes[0]?.directions?.overview_polyline.points ?? ""}
						dynamicMap={false}
						layers={toggleMaps.layers}
					>       
						{draftOrder?.locations.map((item, index) => (
							<Marker
								key={index}
								position={[item.lat,item.lng]}
								icon={customMarkerIcon("#00FFFF", `${item.sequence + 1}`)}
								draggable={false}
							>
							</Marker>
						))}
					</LeafletMap>
				)}

				</div>
			</WhiteContainer>
			<div className="md:w-1/3 md:min-w-fit">
				<Order.ClientDetails order={draftOrder} />
			</div>
		</span>
	);
};
