import React from 'react';
import { AiOutlineFieldTime } from 'react-icons/ai';
// @ts-expect-error
import { ReactComponent as MapPin } from '../../assets/Map.svg';
// @ts-expect-error
import { ReactComponent as Route } from '../../assets/Route.svg';

export type OrderType = '1' | '2' | '3';

export const OrderTypeIcon: React.FC<{
	type: '1' | '2' | '3';
	size?: number;
}> = ({ type, size = 40 }) => {
	const icons = {
		'1': <Route width={size} height={size} />,
		'2': <MapPin width={size} height={size} />,
		'3': <AiOutlineFieldTime size={size} />,
	};

	return <div className="flex items-center justify-center">{icons[type]}</div>;
};
