import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const ChevronLeftIcon: React.FC<SvgProps> = ({
	color = '#393939',
	size,
	stroke = '3.33333',
}) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_764_432512)">
				<path
					d="M20.5465 9.88L18.6665 8L10.6665 16L18.6665 24L20.5465 22.12L14.4398 16L20.5465 9.88Z"
					fill="currentColor"
				/>
			</g>
		</svg>
	);
};

export default ChevronLeftIcon;
