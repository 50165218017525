import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { IoOptions } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';
import { oldIcons, VehicleIcon } from '../../../../../components/Icon/vehicles';
import { Tooltip } from '../../../../../components/Tooltip';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../../services/angular/axios';

interface OperationsFilterProps {
	categoriesForm: any;
	categories: any;
}

export const OperationsFilter = ({
	categories,
	categoriesForm,
}: OperationsFilterProps) => {
	const [openFilter, setOpenFilter] = useState(false);

	const { data } = useQuery(
		['geofenceselect'],
		async () => await api.get('/geofence')
	);

	return (
		<div className="absolute bottom-20 right-24 z-50 flex items-end justify-end gap-2 md:right-32">
			<div className="w-full overflow-hidden">
				<Transition
					show={openFilter}
					enter="transition ease-out duration-300 transform"
					enterFrom="translate-x-full"
					enterTo="-translate-x-0"
					leave="transition ease-in duration-300 transform"
					leaveFrom="-translate-x-0"
					leaveTo="translate-x-full"
				>
					<Controller
						name="categoryId"
						control={categoriesForm.control}
						render={({ field: { onChange, value } }) => (
							<div className="flex flex-wrap items-center gap-2 rounded bg-white py-2 pl-4 md:pl-16">
								{categories?.data
									?.sort((a: any, b: any) => a?.geofenceId - b?.geofenceId)
									.map((category: any, index: number, array: any[]) => (
										<Tooltip
											message={category.name}
											key={category.id}
											onClick={() => {
												onChange(
													value
														? value?.includes(category.id)
															? value.filter((v: any) => v !== category.id)
															: [...value, category.id]
														: [category.id]
												);
											}}
											triggerClassName="flex items-center gap-2 font-bold"
										>
											{index ===
												array.findIndex(
													(c) => c.geofenceId === category.geofenceId
												) && (
												<>
													{
														data?.data?.find(
															(g: any) => g.id === category.geofenceId
														)?.name
													}
												</>
											)}
											<div
												className={twMerge(
													'cursor-pointer rounded border border-neutral-500 bg-white p-1 shadow hover:bg-yellow-500/50',
													value?.includes(category.id) && 'bg-yellow-500/50'
												)}
											>
												{/* @ts-expect-error */}
												<VehicleIcon name={oldIcons[category.icon]} size={36} />
											</div>
										</Tooltip>
									))}
							</div>
						)}
					/>
				</Transition>
			</div>
			<div className="w-11">
				<IoOptions
					size={44}
					className="cursor-pointer rounded-full border border-neutral-0 bg-yellow-500 p-2 text-neutral-0"
					onClick={() => setOpenFilter(!openFilter)}
				/>
			</div>
		</div>
	);
};
