import { z } from 'zod';

export const categorySchema = z.object({
	id: z.coerce.number().optional(),
	active: z.boolean().default(false),
	name: z
		.string()
		.min(1, {
			message: 'Obrigatório',
		})
		.max(50),
	icon: z.string({
		required_error: 'Obrigatório',
		invalid_type_error: 'Obrigatório',
	}),
	width: z.coerce.number(),
	height: z.coerce.number(),
	length: z.coerce.number(),
	weight: z.coerce.number(),
	weightDetails: z.string().optional().nullable(),
	typeOneActive: z.boolean().default(false),
	typeTwoActive: z.boolean().default(false),
	matchBikers: z.boolean().default(false),
	noComissionOnMinValue: z.boolean().default(false),
	allowScheduled: z.boolean().default(false),
	allowImmediate: z.boolean().default(false),
	scheduledDiscount: z.coerce.number(),
	typeOneDescription: z.string().min(1, {
		message: 'Obrigatório',
	}),
	typeOneIcon: z.string(),
	typeOneTooltip: z.string().min(1, {
		message: 'Obrigatório',
	}),
	typeTwoDescription: z.string().min(1, {
		message: 'Obrigatório',
	}),
	typeTwoIcon: z.string(),
	typeTwoTooltip: z.string().min(1, {
		message: 'Obrigatório',
	}),
	costs: z.array(
		z.object({
			orderTypeId: z.coerce.number(),
			costPerKm: z.coerce.number(),
			startFare: z.coerce.number(),
			farePerRoute: z.coerce.number(),
			minValue: z.coerce.number(),
		})
	),
	comission: z.coerce.number(),
	scheduledComission: z.coerce.number(),
	scheduleAdvance: z.coerce.number(),
	stoppedTimeTolerance: z.coerce.number(),
	costPerStoppedTime: z.coerce.number(),
	returnTolerance: z.coerce.number(),
	returnPercentage: z.coerce.number(),
	requesterMultiplier: z.coerce.number(),
	driverBonus: z.coerce.number(),
	longDistanceTolerance: z.coerce.number(),
	longDistancePercentage: z.coerce.number(),
	minValue: z.coerce.number().default(0),
	geofenceId: z
		.object(
			{
				label: z.string().optional(),
				value: z.number(),
			},
			{
				invalid_type_error: 'Geofence é obrigatório',
				required_error: 'Geofence é obrigatório',
			}
		)
		.transform((value) => value?.value),
});

export type CategorySchemaType = z.infer<typeof categorySchema>;
