import { IoArrowForward } from 'react-icons/io5';
import Button from '../../../components/Button/Button';
import Chip from '../../../components/Chip';
import { Form, Input, useZodForm } from '../../../components/FormElements';
import ImageInput from '../../../components/ImageInputCamera/ImageInputCamera';
import { z } from 'zod';
import { zodMessage } from '../../../services/reactQuery/helper/zodMessage';
import { toast } from 'react-toastify';
import { cnpjMask } from '../../../helpers/mask/cnpjMask';
import { RegexHelper } from '../../../helpers/regex/RegexHelper';
import { api } from '../../../services/angular/axios';
import { useState } from 'react';
import VehicleStep from './components/vehicleStep';
import { match } from 'ts-pattern';
import useUserState, { User } from '../../../services/angular/angularUserState';
import { twMerge } from 'tailwind-merge';
import cnpjValidation from '../../../helpers/validation/cnpjValidation';
import { useQuery } from '@tanstack/react-query';
import { Vehicle } from '../../../services/angular/types/Order';
import { useNavigate } from 'react-router-dom';
// @ts-expect-error
import { ReactComponent as Logo } from '../../../assets/Logo.svg';
import PdfUploader from '../../../components/PdfUploader/pdfUploader';

type Step =
	// | 'tipo de conta'
	'pendencies' | 'empresa-mei' | 'motorista-cnh' | 'veiculo';

export const DriverInfoSchema = z.object({
	driverLicense: z.string().min(1, { message: zodMessage.required }),
	driverLicenseImg: z.string().min(1),
});

export const CompanySchema = z.object({
	cnpj: z.string().min(1).min(18).max(18).regex(RegexHelper.cnpj),
	companyName: z.string().min(1, { message: zodMessage.required }),
	avatar: z.string().optional(),
});

export default function Pendencies() {
	const [step, setStep] = useState<Step>('pendencies');
	const userId = useUserState((state) => state.userId);
	const token = useUserState((state) => state.token);
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(true);

  
	const closeModal = () => {
	  setIsOpen(false);
	};
	const { data: user, refetch } = useQuery(
		['current-user', userId],
		async () => await api.get<User>(`users/${userId!}?include=companies`),
		{
			select: ({ data }) => data,
			enabled: !!userId,
			staleTime: 10 * 1000,
			refetchOnWindowFocus: true,
		}
	);

	const driverForm = useZodForm({
		schema: DriverInfoSchema,
		values: user
			? {
					driverLicense: user?.driverLicense,
					driverLicenseImg: user?.driverLicenseImg,
			  }
			: undefined,
	});

	const companyForm = useZodForm({
		schema: CompanySchema,
		values: user?.companies.length
			? {
					cnpj: user?.companies[0]?.cnpj,
					companyName: user?.companies[0]?.companyName,
					avatar: user?.companies[0]?.avatar,
			  }
			: undefined,
	});

	const onSubmitDriver = async (
		companyData: z.infer<typeof DriverInfoSchema>
	) => {
		try {
			await api.put(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/users/${userId}`,
				companyData,
				{
					headers: {
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						Authorization: `Bearer ${token}`,
					},
				}
			);
			toast.success('CNH cadastrada com sucesso');
			void refetch();
			setStep('pendencies');
		} catch (error: any) {
			if (typeof error?.response?.data?.error === 'string') {
				toast.error(error.response?.data.error);
			} else {
				toast.error('Erro ao realizar cadastro');
			}
		}
	};

	const onSubmitCompany = async (
		companyData: z.infer<typeof CompanySchema>
	) => {
		try {
			if (!user?.companies.length) {
				const response = await api.post(
					'/companies',
					{ ...companyData, makeFreights: true, requestFreights: false },
					{
						headers: {
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							Authorization: `Bearer ${token}`,
						},
					}
				);
				await api.post(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`/companies/${response?.data?.id}/drivers`,
					{
						cpf: user?.cpf,
					},
					{
						headers: {
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							Authorization: `Bearer ${token}`,
						},
					}
				);
				toast.success('Empresa cadastrada com sucesso');
				navigate('/');
			} else {
				await api.put(
					`/companies/${user?.companies[0]?.id}`,
					{
						...user?.companies[0],
						...companyData,
					},
					{
						headers: {
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							Authorization: `Bearer ${token}`,
						},
					}
				);

				toast.success('Empresa atualizada om sucesso');
				setStep('pendencies');
			}
			void refetch();
		} catch (error: any) {
			if (typeof error?.response?.data?.error === 'string') {
				toast.error(error.response?.data.error);
			} else {
				toast.error('Erro ao realizar cadastro');
			}
		}
	};

	const { data: companyVehicles, refetch: refetchVehicles } = useQuery(
		['companyVehicles', user?.companies[0]?.id],
		async () =>
			await api.get<Vehicle[]>(
				`/companies/${user!.companies[0].id}/vehicles?include=category,company`,
				{
					headers: {
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						Authorization: `Bearer ${token}`,
					},
				}
			),
		{
			enabled: !!user?.companies?.[0]?.id,
		}
	);

	const { data: driverLicenseSrc } = useQuery(
		['driverLicenseSrc', user?.driverLicenseImg],
		async () =>
			await api.get('/assets', {
				params: {
					imageName: user!.driverLicenseImg,
				},
			}),
		{
			enabled: !!user?.driverLicenseImg,
		}
	);

	const { data: companyLogo } = useQuery(
		['driverLicenseSrc', user?.companies[0]?.avatar],
		async () =>
			await api.get('/assets', {
				params: {
					imageName: user?.companies[0]?.avatar,
				},
			}),
		{
			enabled: !!user?.companies?.[0]?.avatar,
		}
	);

	return (
		<div className="flex min-h-screen w-screen flex-col items-center justify-between bg-gradient-to-t from-yellow-50 to-yellow-400 to-75% px-8">
			<Logo className="mt-8 h-20 min-h-[80px] w-56 min-w-[192px] text-neutral-0" />
			<div className="max-w-lg flex-1 py-10">
				<h1 className="mb-4 w-full self-center text-center text-xl font-bold text-neutral-900">
					{match(step)
						// .with('tipo de conta', () => 'Tipo de conta')
						.with('pendencies', () => 'Pendências')
						.with('empresa-mei', () => 'Empresa/MEI')
						.with('motorista-cnh', () => 'Motorista - CNH')
						.otherwise(() => '')}
				</h1>
				{step === 'pendencies' && (
					<div className="mb-8 flex flex-col gap-8">
						<p>
							Para ser um <b>motorista BeeBee</b> você precisa resolver as
							pendências do seu cadastro
						</p>

						<div
							className="flex cursor-pointer items-center justify-between"
							onClick={() => setStep('motorista-cnh')}
						>
							<div className="flex items-center gap-2">
								<span className="h-16 w-16">
									<img src="cnh.svg" alt="cnh" />
								</span>

								<div className="ml-2 flex flex-col">
									<b>CNH</b>
									<Chip
										label={
											!user?.driverLicenseImg
												? 'Pendente'
												: user?.driverStatus === 1
												? 'Aguardando aprovação'
												: user?.driverStatus === 2
												? 'Reprovado'
												: user?.driverStatus === 3
												? 'Aprovado'
												: 'Pendente'
										}
										backgroundColor={
											!user?.driverLicenseImg
												? 'bg-red'
												: user?.driverStatus === 1
												? 'bg-neutral-500'
												: user?.driverStatus === 2
												? 'bg-red'
												: user?.driverStatus === 3
												? 'bg-green'
												: 'bg-red'
										}
										textColor="text-white"
									/>
								</div>
							</div>
							<IoArrowForward />
						</div>

						<div
							className="flex cursor-pointer items-center justify-between"
							onClick={() => {
								setStep('empresa-mei');
							}}
						>
							<div className="flex items-center gap-2">
								<span className="h-16 w-16">
									<img src="company.svg" alt="empresa" />
								</span>
								<div className="ml-2 flex flex-col">
									<b>Empresa/MEI</b>
									<Chip
										label={
											user?.companies.length
												? user?.companies?.[0].status === 1
													? 'Aguardando aprovação'
													: user?.companies?.[0].status === 2
													? 'Reprovado'
													: user?.companies?.[0].status === 3
													? 'Aprovado'
													: 'Pendente'
												: 'Pendente'
										}
										backgroundColor={
											user?.companies.length
												? user?.companies?.[0].status === 1
													? 'bg-neutral-500'
													: user?.companies?.[0].status === 2
													? 'bg-red'
													: user?.companies?.[0].status === 3
													? 'bg-green'
													: 'bg-red'
												: 'bg-red'
										}
										textColor="text-white"
									/>
								</div>
							</div>
							<IoArrowForward />
						</div>

						<div
							className={twMerge(
								'flex cursor-pointer items-center justify-between',
								user?.companies?.length ? '' : 'opacity-20'
							)}
							onClick={() => {
								user?.companies?.length
									? setStep('veiculo')
									: toast.error('Você precisa cadastrar uma empresa');
							}}
						>
							<div className="flex items-center gap-2">
								<span className="h-16 w-16">
									<img src="carro.svg" alt="veiculo" />
								</span>

								<div className="ml-2 flex flex-col">
									<b>Veículo</b>
									<Chip
										label={
											!companyVehicles?.data.length
												? 'Pendente'
												: companyVehicles.data.some(
														(vehicle) => vehicle.status === 3
												  )
												? 'Aprovado'
												: companyVehicles.data.some(
														(vehicle) => vehicle.status === 2
												  )
												? 'Reprovado'
												: companyVehicles.data.some(
														(vehicle) => vehicle.status === 1
												  )
												? 'Aguardando aprovação'
												: 'Pendente'
										}
										backgroundColor={
											!companyVehicles?.data.length
												? 'bg-red'
												: companyVehicles.data.some(
														(vehicle) => vehicle.status === 3
												  )
												? 'bg-green'
												: companyVehicles.data.some(
														(vehicle) => vehicle.status === 2
												  )
												? 'bg-red'
												: companyVehicles.data.some(
														(vehicle) => vehicle.status === 1
												  )
												? 'bg-neutral-500'
												: 'bg-red'
										}
										textColor="text-white"
									/>
								</div>
							</div>
							<IoArrowForward />
						</div>
						{(user?.driverStatus === 1 || user?.driverStatus === 3) &&
							user?.companies?.length &&
							(user?.companies?.[0]?.status === 1 || user?.companies?.[0]?.status === 3) &&
							(companyVehicles?.data?.some((vehicle) => vehicle?.status === 1) || 
							companyVehicles?.data?.some((vehicle) => vehicle?.status === 3)) && 
							isOpen ? (
								<div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 sm:hidden" onClick={closeModal}>
								<div className="bg-white p-6 rounded-lg shadow-lg">
									<div className="flex flex-col gap-4">
									<h3 className="text-center font-bold">
										Você concluiu todas etapas de cadastro!
									</h3>
									<p className="text-center">
										Agora, faça o login no aplicativo para acompanhar sua aprovação e logo logo começar as fazer fretes
									</p>
									<a
										href="beebee-driver://"
										className="flex items-center justify-center bg-blue hover:bg-blue text-black font-bold py-2 px-4 rounded shadow-lg transition duration-200 ease-in-out transform hover:scale-105"
									>
										Abrir app
									</a>
									</div>
								</div>
								</div>
							) : null}
					</div>
				)}

				{step === 'empresa-mei' && (
					<Form
						onSubmit={onSubmitCompany}
						form={companyForm}
						className="flex flex-col gap-4"
					>
						<Input
							autoFocus
							type='text'
							label="Razão social"
							{...companyForm.register('companyName')}
						/>

						<Input
							className="w-full"
							label="CNPJ"
							type='tel'
							{...companyForm.register('cnpj', {
								onChange: (event) => {
									companyForm.setValue('cnpj', cnpjMask(event.target.value));
									if (RegexHelper.cnpj.test(event.target.value)) {
										cnpjValidation(event.target.value)
											? companyForm.setError('cnpj', {
													message: '',
											  })
											: companyForm.setError('cnpj', {
													message: 'CNPJ inválido',
											  });
									}
								},
							})}
						/>

						<p className="text-center">
							Clique na imagem para selecionar uma logomarca
						</p>
						<ImageInput
							setImageName={(name: string) => {
								companyForm.setValue('avatar', name);
								void companyForm.trigger();
							}}
							token={token || ''}
							src={companyLogo?.data.url}
						/>

						<div className="mt-2 flex flex-col gap-2">
							<Button type="submit" disabled={!companyForm.formState.isValid}>
								Enviar
							</Button>
							<Button onClick={() => setStep('pendencies')} variant="secondary">
								Voltar
							</Button>
						</div>
					</Form>
				)}

				{step === 'motorista-cnh' && (
					<div className="w-full min-w-fit">
						<Form
							form={driverForm}
							onSubmit={onSubmitDriver}
							className="flex flex-col gap-4"
						>
							<Input
								type='number'
								autoFocus
								label="Nº de registro da CNH"
								{...driverForm.register('driverLicense')}
								defaultValue={
									user?.driverStatus === 2
										? user?.driverLicense
										: driverForm.watch('driverLicense')
								}
							/>
							<p className="text-center">
								Clique na imagem para selecionar a foto da CNH
							</p>
							<span className="flex w-full flex-col items-center">
								<span
									className={twMerge(
										'block',
										driverForm?.watch('driverLicenseImg')?.includes('.pdf') &&
											'hidden'
									)}
								>
									<ImageInput
										setImageName={(name: string) => {
											driverForm.setValue('driverLicenseImg', name);
											void driverForm.trigger();
										}}
										token={token || ''}
										src={driverLicenseSrc?.data.url}
									/>
								</span>

								<PdfUploader
									unsetPreview={() => {
										driverForm.setValue('driverLicenseImg', '');
										void driverForm.trigger();
									}}
									setImageName={(name: string) => {
										driverForm.setValue('driverLicenseImg', name);
										void driverForm.trigger();
									}}
								/>
							</span>
							<a
								href="https://www.gov.br/pt-br/apps/carteira-digital-de-transito-1"
								className="text-center text-sm text-blue underline"
							>
								Clique se quiser emitir sua CNH digital
							</a>

							<div className="mt-2 flex flex-col gap-2">
								<Button type="submit" disabled={!driverForm.formState.isValid}>
									Enviar
								</Button>
								<Button
									onClick={() => setStep('pendencies')}
									variant="secondary"
								>
									Voltar
								</Button>
							</div>
						</Form>
					</div>
				)}
				{step === 'veiculo' && (
					<VehicleStep
						companyId={user?.companies[0]?.id || 0}
						refetch={() => {
							void refetchVehicles();
						}}
						onBack={() => {
							setStep('pendencies');
						}}
						vehicles={companyVehicles?.data}
					/>
				)}

				{step === 'pendencies' && (
					<div>
						<p className="p-4 text-center text-xs text-neutral-600">
							Após cadastro e aprovação você será liberado a fazer entregas
						</p>
					</div>
				)}
			</div>
			<div className="mb-10 self-center text-center text-sm text-neutral-0 md:mb-20">
				<a
					className="cursor-pointer font-bold hover:underline"
					href="https://beebeelogistica.freshdesk.com/support/home"
					target="_blank"
					rel="noreferrer"
				>
					Precisa de ajuda? Entre em contato com o suporte
				</a>
			</div>
		</div>
	);
}
