import { useLoadScript } from '@react-google-maps/api';
import { useStore } from 'zustand';
import { appStateStore } from '../../store/appState';

const libs = ['places', 'drawing', 'geometry', 'visualization'];

export const useGoogleLoaded = () => {
	const { config } = useStore(appStateStore);

	const { isLoaded } = useLoadScript({
		id: 'script-loader',
		version: 'weekly',
		googleMapsApiKey: config.googleMaps.apiKey,
		// @ts-expect-error
		libraries: libs,
	});

	return isLoaded;
};
