import { z } from 'zod';
import { dateFilterSchema } from '../../../../../../helpers/dateFilterSchema';

export const AuditFilterSchema = dateFilterSchema.extend({
	categoryId: z
		.array(
			z.object({
				value: z.number().nullable(),
				label: z.string().optional(),
			})
		)
		.nullable()
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
	adminId: z
		.object({
			label: z.string().optional(),
			value: z.number(),
		})
		.optional()
		.nullable()
		.transform((value) => value?.value),
	geofenceId: z
		.array(
			z.object({
				value: z.number().nullable(),
				label: z.string().optional(),
			})
		)
		.nullable()
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
	orderTypeId: z
		.object({
			label: z.string().optional(),
			value: z.number().optional(),
		})
		.nullable()
		.optional()
		.transform((value) => value?.value),
	changedField: z
		.object({
			label: z.string().optional(),
			value: z.string(),
		})
		.nullable()
		.optional()
		.transform((value) => value?.value),
});

export type AuditFilterSchemaType = z.infer<typeof AuditFilterSchema>;
