/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { BsWhatsapp, BsXCircle } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Tooltip } from '../../../../../components/Tooltip';
import { useAlert } from '../../../../../contexts/AlertContext';
import { dateFormat } from '../../../../../helpers/format/dateFormat';
import {
	phoneMask,
	removePhoneMask,
} from '../../../../../helpers/mask/phoneMask';
import { api } from '../../../../../services/angular/axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Avatar } from '../../../../../components/Avatar';
import React, { useMemo } from 'react';
import {
	OrderResponseApi,
	Driver,
	Vehicle,
} from '../../../../../services/angular/types/Order';
import { match, P } from 'ts-pattern';
import { useCopy } from '../../../../../hooks/useCopy';
import { ToastError } from '../../../../../helpers/errors/ToastError';

interface ClosestDriverModalProps {
	onlineDriver: {
		distanceToOrigin: number;
		rejectedOrder: boolean;
		user: Driver;
		vehicle: Vehicle;
	};
	order?: OrderResponseApi;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloseDriver = ({
	onlineDriver,
	order,
	setOpen,
}: ClosestDriverModalProps) => {
	const alert = useAlert();
	const copy = useCopy();
	const queryClient = useQueryClient();

	const { data: driverData } = useQuery(
		['user-avatar', onlineDriver],
		async () => {
			if (!onlineDriver?.user.avatar) return;
			return await api.get(`/assets?`, {
				params: {
					imageName: onlineDriver?.user.avatar,
				},
			});
		},
		{
			enabled: !!onlineDriver?.user.avatar,
		}
	);

	const { mutate: clearRejection } = useMutation(
		async ({ orderId, driverId }: { orderId: number; driverId: number }) =>
			await api.delete(
				`/orders/${orderId}/users/${driverId}/clear-single-rejection`
			),
		{
			onSuccess: () => {
				toast.success('Rejeição removida com sucesso');
				void queryClient.invalidateQueries({
					queryKey: ['closest-drivers'],
					exact: false,
				});
			},
			onError: (error) => {
				ToastError(error);
			},
		}
	);

	const distance = useMemo(() => {
		if (!onlineDriver?.distanceToOrigin) return;
		if (onlineDriver?.distanceToOrigin < 1000) {
			return `${onlineDriver?.distanceToOrigin} m`;
		}

		return `${(onlineDriver?.distanceToOrigin / 1000).toFixed(2)} km`;
	}, [onlineDriver?.distanceToOrigin]);

	if (!order?.id) return null;

	return (
		<div
			className="flex cursor-pointer gap-2 rounded p-2 shadow-md shadow-neutral-100 ring-1 ring-neutral-100 transition-all hover:bg-neutral-50 hover:shadow-neutral-200 hover:ring-2"
			onClick={(e) => {
				e.stopPropagation();
				if (!order) return;

				let titleLabel = 'Atribuir entregador?';
				let messageLabel = `Deseja atribuir o entregador ${onlineDriver.user.name} à esta entrega?`;
				let toastSuccessLabel = 'Entregador atribuído com sucesso';
				let toastErrorLabel = 'Erro ao atribuir entregador';

				if (onlineDriver.user.activeFreights?.length) {
					titleLabel = 'Enviar tela de Match?';
					messageLabel = `Deseja enviar tela de match para o entregador ${onlineDriver.user.name}?`;
					toastSuccessLabel = 'Tela de match enviado com sucesso.';
					toastErrorLabel = 'Erro ao enviar tela de Match.';
				}

				alert.onCustom({
					title: titleLabel,
					message: messageLabel,
					confirm: {
						onClick: async () => {
							try {
								await api.put(
									`/orders/${order.id}/addDriver/${onlineDriver.user.id}?include=driver.tags`
								);
								toast.success(toastSuccessLabel);
								setOpen(false);
							} catch (error) {
								toast.error(toastErrorLabel);
							}
						},
					},
				});
			}}
		>
			<span className="flex items-center gap-1">
				<span>
					{match(onlineDriver.user)
						.with(
							{
								activeFreights: P.when(
									(activeFreights) => activeFreights.length > 0
								),
							},
							() => (
								<div className="h-3 w-3 rounded-full bg-yellow-500 shadow-inner shadow-neutral-700" />
							)
						)
						.with(
							{
								awaitingFreight: true,
								online: true,
								activeFreights: P.when(
									(activeFreights) => activeFreights.length <= 0
								),
							},
							() => (
								<div className="h-3 w-3 rounded-full bg-green shadow-inner shadow-neutral-700" />
							)
						)
						.with(
							{
								awaitingFreight: true,
								online: false,
								activeFreights: P.when(
									(activeFreights) => activeFreights.length <= 0
								),
							},
							() => (
								<div className="h-3 w-3 rounded-full bg-blue shadow-inner shadow-neutral-700" />
							)
						)
						.otherwise(() => (
							<div className="h-3 w-3 rounded-full bg-neutral-400 shadow-inner shadow-neutral-700" />
						))}
				</span>
				<Avatar
					src={driverData?.data?.url}
					avatarProps={{
						className: 'h-12 w-12',
					}}
				/>
			</span>
			<span className="flex flex-col gap-1 text-xs">
				<span className="text-sm font-bold">{onlineDriver?.user.name}</span>
				{(onlineDriver?.vehicle?.featureOneActive ||
					onlineDriver?.vehicle?.featureTwoActive) && (
					<>
						{onlineDriver?.vehicle?.featureOneActive
							? onlineDriver?.vehicle?.category?.typeOneDescription
							: onlineDriver?.vehicle?.category?.typeTwoDescription}
					</>
				)}
				<span className="flex justify-between text-neutral-500">
					<span
						className="cursor-copy"
						onClick={() => {
							void copy(removePhoneMask(onlineDriver?.user.cellphone, '+55'));
						}}
					>
						{phoneMask(removePhoneMask(onlineDriver?.user.cellphone))}
					</span>
					{onlineDriver?.user.cellphone && (
						<Tooltip message="Abrir whatsapp">
							<BsWhatsapp
								onClick={() => {
									window.open(
										`https://api.whatsapp.com/send?phone=55${onlineDriver?.user.cellphone.replace(
											/\D/g,
											''
										)}&text=${encodeURI(
											`Olá ${onlineDriver?.user.name.split(' ')[0]}`
										)}`,
										'_blank'
									);
								}}
								className="cursor-pointer text-green"
								size={14}
							/>
						</Tooltip>
					)}
				</span>
				<span className="text-neutral-500">
					Online em: {dateFormat(onlineDriver?.user.latestOnline)}
				</span>
			</span>
			<span className="flex flex-col items-center justify-evenly">
				<h1 className="w-full rounded p-1 text-center text-xs ring-1 ring-neutral-0">
					{distance}
				</h1>
				{onlineDriver?.rejectedOrder && (
					<button
						className="flex cursor-pointer items-center gap-1 rounded p-1 text-sm text-terracota-500 ring-1 ring-terracota-500 transition-all hover:bg-terracota-500 hover:text-white"
						onClick={async (e) => {
							e.stopPropagation();

							alert.onCustom({
								title: 'Remover rejeição?',
								message: `Deseja remover a rejeição do entregador ${onlineDriver.user.name} à esta entrega?`,
								confirm: {
									onClick: async () => {
										clearRejection({
											driverId: onlineDriver.user.id,
											orderId: order?.id,
										});
									},
								},
							});
						}}
					>
						Rejeitou <BsXCircle />
					</button>
				)}
			</span>
		</div>
	);
};

export default CloseDriver;
