import React, { useState } from 'react';
import { Modal } from '../../../components/Modal';
import { PageHeader } from '../../../components/PageHeader';
import WhiteContainer from '../../../components/WhiteContainer/WhiteContainer';
import { Path } from './components/GeoMap';
import GeoPolygon from './components/GeoPolygon';
import { GeofenceCoordinatesParser } from './schemas/GeofenceCoordinatesParser';

import { GeofenceForm } from './components/GeofenceForm';
import { RegisterButton } from '../../../components/Button/RegisterButton';
import { Table } from '../../../components/Table/Table';
import { api } from '../../../services/angular/axios';
import { useQuery } from '@tanstack/react-query';
import { Geofence } from '../../../store/appState';

export const GeofencePage: React.FC = () => {
	const [openGeofenceForm, setOpenGeofenceForm] = useState(false);
	const [editGeofence, setEditGeofence] = useState<Geofence>();

	const [openGeofencePreview, setOpenGeofencePreview] = useState(false);
	const [geofencePreview, setGeofencePreview] = useState<{
		name: string;
		paths: Path[];
	}>({
		name: 'Geofence',
		paths: [],
	});

	const { data, isLoading, refetch } = useQuery(
		['geofence-list'],
		async () => await api.get('/geofence'),
		{
			keepPreviousData: true,
		}
	);

	return (
		<div className="w-full">
			<div className="flex items-center justify-between">
				<PageHeader
					title="Geofence"
					description={`${
						openGeofenceForm
							? editGeofence
								? 'Editar Geofence'
								: 'Cadastrar Geofence'
							: 'Lista de Regiões e respectivos geofences para definir áreas de atuação'
					}`}
				/>
				{!editGeofence && !openGeofenceForm && (
					<RegisterButton
						className="w-auto"
						onClick={() => setOpenGeofenceForm(true)}
					/>
				)}
			</div>

			<WhiteContainer>
				<>
					{openGeofenceForm ? (
						<GeofenceForm
							refetch={() => {
								void refetch();
							}}
							geofence={editGeofence}
							closeForm={() => {
								setOpenGeofenceForm(false);
								setEditGeofence(undefined);
							}}
						/>
					) : (
						<Table<Geofence>
							pagination={false}
							data={data?.data || []}
							isLoading={isLoading}
							disabledSort={{
								name: true,
								state: true,
							}}
							onRowClick={(item) => {
								setGeofencePreview({
									name: item.name,
									paths:
										GeofenceCoordinatesParser.parse(item.coordinates) || [],
								});
								setOpenGeofencePreview(true);
							}}
							actions={[
								{
									icon: 'edit',
									label: 'Editar',
									onAction: (data) => {
										setEditGeofence(data);
										setOpenGeofenceForm(true);
									},
								},
							]}
							columns={[
								{
									accessor: 'name',
									Header: 'Nome',
								},
								{
									accessor: 'description',
									Header: 'Descrição',
								},
								{
									accessor: 'state',
									Header: 'Estado',
									Cell: ({ value }) => {
										return <>{value?.abbr}</>;
									},
								},
							]}
						/>
					)}
				</>
			</WhiteContainer>

			<Modal
				title={geofencePreview?.name}
				open={openGeofencePreview}
				setOpen={setOpenGeofencePreview}
			>
				<div className="h-screen max-h-[calc(100vh-40vh)] w-full rounded">
					<GeoPolygon paths={geofencePreview?.paths} />
				</div>
			</Modal>
		</div>
	);
};
