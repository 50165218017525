import { atom, useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { BiSend } from 'react-icons/bi';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button/Button';
import {
	FormHeader,
	Input,
	InputCode,
} from '../../../../components/FormElements';
import { registerPhoneMask } from '../../../../helpers/mask/cellphoneRegistryMask';
import { api } from '../../../../services/angular/axios';

export const crateUserToken = atom<string | undefined>(undefined);

interface PhoneVerificationProps {
	setPhoneNextButtonDisabled: (disabled: boolean) => void;
}

export function PhoneVerification({
	setPhoneNextButtonDisabled,
}: PhoneVerificationProps) {
	const [seconds, setSeconds] = useState<number>(0);
	const [hasSent, setHasSent] = useState<boolean>(false);
	const [phone, setPhone] = useState<string>();
	const [userId, setUserId] = useState<string>();
	const [, setToken] = useAtom(crateUserToken);

	useEffect(() => {
		let interval: any = null;
		if (seconds > 0) {
			interval = setInterval(() => {
				setSeconds((prev) => prev - 1);
			}, 1000);
		} else {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [seconds]);

	const handleResendCode = async (): Promise<void> => {
		try {
			const sendCode = await api.post(`/register`, {
				bikerStatus: 0,
				cellphone: phone,
				clientStatus: 1,
				driverStatus: 0,
			});

			toast.success('Código enviado com sucesso');
			setUserId(sendCode.data.id);
			setHasSent(true);
			setSeconds(60);
		} catch (error) {
			toast.error('Erro ao reenviar código');
		}
	};
	const handlePhoneVerification = async () => {
		try {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			const verifyPhone = await api.get(`/verify-phone/${phone}`);
			if (verifyPhone.data.active === false) {
				const sendCode = await api.post(`/register`, {
					bikerStatus: 0,
					cellphone: phone,
					clientStatus: 1,
					driverStatus: 0,
				});

				toast.success('Código enviado com sucesso');
				setUserId(sendCode.data.id);
				setHasSent(true);
				setSeconds(60);
			} else {
				toast.error('Este número já está cadastrado');
			}
		} catch (error) {
			toast.error('Erro ao enviar código');
		}
	};

	const handleConfirmCode = async (code: string) => {
		try {
			const confirmCode = await api.post(`/confirm`, {
				code,
				id: userId,
			});
			toast.success('Código confirmado com sucesso');
			setPhoneNextButtonDisabled(false);
			setToken(confirmCode.data.token);
		} catch (error) {
			toast.error('Erro ao confirmar código');
		}
	};

	return (
		<>
			<FormHeader
				title="Qual o número do seu celular?"
				description="Ele será usado caso haja algum imprevisto com sua entrega"
			/>
			<span className="flex items-end w-full gap-4">
				<Input
					label="Celular"
					className="mt-4 "
					maxLength={15}
					onChange={(e) => {
						e.target.value = registerPhoneMask(e.target.value);
						setPhone(e.target.value);
					}}
				/>
				<Button
					// eslint-disable-next-line @typescript-eslint/no-misused-promises
					onClick={async () => await handlePhoneVerification()}
					className="w-auto text-xs"
				>
					Enviar
					<BiSend />
				</Button>
			</span>
			{hasSent && (
				<>
					Digite o codigo enviado para o seu celular
					<InputCode
						autoFocus
						length={4}
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						onCompleted={async (value) => {
							await handleConfirmCode(value);
						}}
					/>
					<div className="flex justify-center">
						{seconds ? (
							<span className="text-neutral-400">
								Reenviar o código SMS em {seconds} segundos
							</span>
						) : (
							<span
								// eslint-disable-next-line @typescript-eslint/no-misused-promises
								onClick={async () => await handleResendCode()}
								className="font-bold cursor-pointer text-neutral-0 hover:text-neutral-700" // eslint-disable-next-line @typescript-eslint/no-misused-promises
							>
								Reenviar o código SMS
							</span>
						)}
					</div>
				</>
			)}
		</>
	);
}
