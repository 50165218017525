import React, { ChangeEvent } from 'react';
import { Cropper, CropperRef } from 'react-advanced-cropper';
import { BsFillEyeFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Button from '../../../../../../components/Button/Button';
import { Label } from '../../../../../../components/FormElements';
import { Icon } from '../../../../../../components/Icon/Icon';
import { Modal } from '../../../../../../components/Modal';
import { api } from '../../../../../../services/angular/axios';
import './updateAvatar.css';
import { Root, Image as RadixImage } from '@radix-ui/react-avatar';
import Bee from '../../../../../../assets/svg/Bee';
import { twMerge } from 'tailwind-merge';
import { validateFileSize } from '../../../../../../helpers/validateFileSize';

interface PopImageInputProps {
	className?: string;
	onImageLoad?: (imageUrl: string) => void;
	label: string;
	currentImage?: string;
}

interface Image {
	type?: string;
	src: string;
}

export const PopImageInput: React.FC<PopImageInputProps> = ({
	onImageLoad,
	label,
	className,
	currentImage,
}) => {
	const cropperRef = React.useRef<CropperRef>(null);
	const [image, setImage] = React.useState<Image>();
	const [open, setOpen] = React.useState<boolean>(false);
	const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
	const [cropped, setCropped] = React.useState<string | undefined>();

	const onLoadImage = (event: ChangeEvent<HTMLInputElement>): void => {
		const { files } = event.target;

		if (files?.[0]) {
			if (!files[0].type.match('image.*')) {
				toast.error('Arquivo inválido, selecione uma imagem');
				event.target.value = '';
				return;
			}
			const blob = URL.createObjectURL(files[0]);

			setImage({ src: blob, type: files[0].type });
			setOpen(true);
		}
		event.target.value = '';
	};

	const onCrop = () => {
		if (cropperRef.current) {
			cropperRef.current.getCanvas()?.toBlob(
				(blob) => {
					if (blob && !validateFileSize(blob)) {
						return;
					}

					const formData = new FormData();
					formData.append('image', blob as Blob);

					void handleUpdate(formData);
					setOpen(false);
				},
				'image/jpeg',
				0.95
			);
		}
	};

	const handleUpdate = async (file: any) => {
		try {
			const response = await api.post('/assets', file, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response?.data?.imageName) {
				const croppedImage = await api.get(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`/assets?imageName=${response.data.imageName}`
				);
				setCropped(croppedImage.data.url);

				onImageLoad?.(response.data.imageName);
			}
		} catch (error) {
			toast.error('Erro ao atualizar imagem');
		}
	};

	return (
		<div className={twMerge('h-full w-full', className)}>
			<Label label={label} variant="primary" />
			<div className="relative h-full w-full rounded-lg ">
				{cropped || currentImage ? (
					<>
						{currentImage?.includes('.pdf') ? (
							<embed
								src={cropped || currentImage}
								type="application/pdf"
								className="h-full w-full rounded object-contain ring-1 ring-neutral-50"
							/>
						) : (
							<img
								src={cropped || currentImage}
								alt=""
								className="h-full w-full rounded object-contain ring-1 ring-neutral-50"
							/>
						)}
					</>
				) : (
					<div className="h-full w-full rounded bg-neutral-100 p-4 text-white">
						<Bee />
					</div>
				)}

				<label className="absolute bottom-1 right-1 flex cursor-pointer gap-2 rounded bg-neutral-700 p-0.5 text-white transition-all hover:text-yellow-500">
					<Icon name="edit" size={20} />
					<input
						onChange={onLoadImage}
						type="file"
						accept="image/png, image/gif, image/jpeg"
						className="hidden"
					/>
				</label>
				{!currentImage?.includes('.pdf') && (
					<span
						onClick={() => setPreviewOpen(true)}
						className="absolute bottom-1 left-1 flex cursor-pointer gap-2 rounded bg-neutral-700 p-0.5 text-white transition-all hover:text-yellow-500"
					>
						<BsFillEyeFill size={20} />
					</span>
				)}
			</div>

			<Modal
				className="max-h-[95vh]"
				title="Visualizar imagem"
				open={previewOpen}
				setOpen={setPreviewOpen}
			>
				<div className="flex items-center justify-center">
					<Root className="inline-flex h-full w-full select-none items-center justify-center overflow-hidden bg-neutral-100 align-middle focus:outline-none">
						<RadixImage
							src={cropped || currentImage}
							className="h-full w-full object-cover"
						/>
					</Root>
				</div>
			</Modal>
			<Modal title="Cortar imagem" open={open} setOpen={setOpen}>
				<div className="flex max-h-[65vh] flex-col align-bottom">
					<Cropper
						ref={cropperRef}
						src={image?.src}
						stencilProps={{
							grid: true,
						}}
					/>
					<Button
						className="mt-4 w-auto self-end"
						variant="blue-primary"
						onClick={onCrop}
					>
						Salvar
					</Button>
				</div>
			</Modal>
		</div>
	);
};
