/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as RadioGroup from '@radix-ui/react-radio-group';
import React, { useEffect, useState } from 'react';
import {
	Checkbox,
	Form,
	FormButtons,
	FormHeader,
	Input,
	Select,
	Textarea,
	useZodForm,
} from '../../../../components/FormElements';
import { PageHeader } from '../../../../components/PageHeader';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { Modal } from '../../../../components/Modal';
import cnpjValidation from '../../../../helpers/validation/cnpjValidation';
import useUserState, {
	CompanyModel,
} from '../../../../services/angular/angularUserState';
import { STATES } from '../../../../helpers/states';
import { api } from '../../../../services/angular/axios';
import { Tooltip } from '../../../../components/Tooltip';
import HexBtnCompany from '../../../../assets/svg/HexBtnCompany';
import HexBtnFreighter from '../../../../assets/svg/HexBtnFreighter';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastMessage } from '../../../../helpers/toastMessage';
import { RegexHelper } from '../../../../helpers/regex/RegexHelper';
import { cnpjMask } from '../../../../helpers/mask/cnpjMask';
import { cepMask } from '../../../../helpers/mask/cepMask';
import { phoneMask } from '../../../../helpers/mask/phoneMask';
import { CompanySchema, CompanySchemaType } from './schema/CompanySchema';
import { Controller } from 'react-hook-form';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { PopImageInput } from '../../OperationPages/Administration/InformativePopUp/components/ImageInput';
import { z } from 'zod';
import { Tags } from '../../../../components/Angular/Tags';
import { appState } from '../../../../store/appState';
import { Loading } from '../../../../components/Loading';
import { twMerge } from 'tailwind-merge';
import Button from '../../../../components/Button/Button';
import { CompanyTables } from './components/companyTables';

const entityStatus = [
	{
		value: 1,
		label: 'Aprovação pendente',
	},
	{
		value: 2,
		label: 'Empresa não aprovada',
	},
	{
		value: 3,
		label: 'Empresa aprovada',
	},
];

const statusSchema = z.object({
	status: z.string(),
});

type StatusFormType = z.infer<typeof statusSchema>;

const AngularNewCompanyRegister = () => {
	const role = useUserState((state) => state.userRole);
	const navigate = useNavigate();
	const triggerRefetch = useUserState((state) => state.triggerRefetch);

	const { id } = useParams();
	const [initialRender, setInitialRender] = useState(true);
	const [isInvalidFileModalOpen, setIsInvalidFileModalOpen] =
		React.useState(false);

	const form = useZodForm({
		schema: CompanySchema,
	});

	const {
		register,
		setValue,
		setError,
		clearErrors,
		control,
		handleSubmit,
		watch,
		reset,
		formState: { errors, isDirty },
	} = form;

	const statusForm = useZodForm({
		schema: statusSchema,
	});

	const { data, isFetching, refetch } = useQuery(
		['edit-company', id],
		async () =>
			await api.get<CompanyModel>(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`companies/${id}?include=vehicles.category,vehicles.activeOrders,vehicles.currentDriver,favoriteDrivers`
			),
		{
			enabled: role?.id !== Number(appState.userRoles.user.value) && !!id,
		}
	);

	const { data: avatar } = useQuery(
		['company-avatar', data?.data.avatar],
		async () => {
			if (!data?.data?.avatar) throw new Error('No avatar');
			return await api.get('/assets', {
				params: {
					imageName: data?.data.avatar,
				},
			});
		},
		{
			enabled: !!data?.data.avatar,
		}
	);

	const { data: segments, isFetching: fetchingSegments } = useQuery(
		['segments', id],
		async () => await api.get('/companies/segments'),
		{
			enabled: role?.id !== Number(appState.userRoles.user.value) && !!id,
		}
	);

	const { data: tags, isFetching: fetchingTags } = useQuery(
		['tags', id],
		async () => await api.get(`/tags?filterBy=company`),
		{
			enabled: role?.id !== Number(appState.userRoles.user.value) && !!id,
		}
	);

	const { data: leads, isFetching: fetchingLeads } = useQuery(
		['leads', id],
		async () => await api.get('/companies/leads'),
		{
			enabled: role?.id !== Number(appState.userRoles.user.value) && !!id,
		}
	);

	useEffect(() => {
		if (
			data &&
			role?.id !== Number(appState.userRoles.user.value) &&
			!(isFetching || fetchingSegments || fetchingTags || fetchingLeads)
		) {
			// filter all properties from data?.data, removing the ones that are null
			const filteredData = Object.fromEntries(
				Object.entries(data?.data).filter(([, value]) => value)
			);

			reset({
				...filteredData,
				phone: phoneMask(filteredData?.phone),
				cellphone: phoneMask(filteredData?.cellphone),
				stateId: {
					value: filteredData?.stateId,
					label: filteredData?.state?.abbr,
				},
				leadId: {
					value: filteredData?.leadId,
					label: leads?.data.find(
						(lead: any) => lead.id === filteredData?.leadId
					)?.leadOrigin,
				},
				businessSegmentId: {
					value: filteredData?.businessSegmentId,
					label: segments?.data.find(
						(segment: any) => segment.id === filteredData?.businessSegmentId
					)?.businessSegment,
				},
				dynamicRouteEnabled: !!filteredData?.dynamicRouteEnabled,
				allowFavoriteDriver: !!filteredData?.allowFavoriteDriver,
				allowBankingBill: !!filteredData?.allowBankingBill,
				storeProtocolsInStaticRoute:
					!!filteredData?.storeProtocolsInStaticRoute,
			});
			statusForm.reset({
				status: String(filteredData?.status),
			});
		}
	}, [data, isFetching, fetchingSegments, fetchingTags, fetchingLeads]);

	const addNewCompany = async (data: CompanySchemaType) => {
		try {
			const { leadId, businessSegmentId, ...rest } = data;
			await api.post('/companies', {
				...rest,
			});

			triggerRefetch();
			navigate('/negocios/perfil');
			toast.success(toastMessage.success.created);
		} catch (error) {
			toast.error(toastMessage.error.created);
		}
	};

	const handleUpdate = async (data: CompanySchemaType) => {
		try {
			await api.put(`/companies/${id}`, {
				...data,
			});

			toast.success(toastMessage.success.updated);
			void refetch();
		} catch (error) {
			toast.error(toastMessage.error.updated);
		}
	};

	const handleAccessControlStatus = async ({ status }: StatusFormType) => {
		try {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			await api.put(`companies/${id}/status`, {
				status: Number(status),
			});
			toast.success('Status alterado com sucesso!');
			statusForm.reset({
				status,
			});
		} catch (err) {
			toast.error('Algo deu errado.');
		}
	};

	const { makeFreights, requestFreights } = watch();

	useEffect(() => {
		if (initialRender) {
			setInitialRender(false);
			return;
		}
		if (!makeFreights && !requestFreights) {
			setError('makeFreights', {
				message: 'Selecione ao menos uma opção de serviço.',
			});
		} else {
			clearErrors('makeFreights');
		}
	}, [makeFreights, requestFreights, errors]);

	if (isFetching || fetchingSegments || fetchingTags || fetchingLeads)
		return <Loading title="Buscando dados da empresa..." />;

	return (
		<div className="w-full text-neutral-900">
			<PageHeader
				title={
					role?.id !== Number(appState.userRoles.user.value)
						? 'Edição de Empresa'
						: 'Meu Negócio'
				}
				description={
					role?.id !== Number(appState.userRoles.user.value)
						? 'Gerencie o perfil da empresa'
						: 'Informações da empresa.'
				}
			/>
			<div className="flex flex-col gap-4 lg:flex-row">
				<WhiteContainer
					className="flex gap-4"
					divProps={{
						className: twMerge(
							'w-full',
							role?.id !== Number(appState.userRoles.user.value) && 'lg:w-3/4'
						),
					}}
				>
					<div>
						<FormHeader
							className="mb-4"
							title="Perfil da Empresa"
							description="A empresa usará a BeeBee para pedir ou fazer fretes?"
						/>

						<div className="flex gap-4">
							<Controller
								control={control}
								name="requestFreights"
								render={({ field }) => (
									<Tooltip message="Se sua empresa utiliza nossos serviços para Pedir Fretes">
										<div
											className="w-32 min-w-[128px] hover:opacity-80"
											onClick={() => {
												field.onChange(!field.value);
											}}
										>
											<HexBtnCompany
												backgroundColor={
													requestFreights ? '#1671E4' : '#868686'
												}
												textColor="#fff"
												borderColor={requestFreights ? '#1671E4' : '#868686'}
											/>
										</div>
									</Tooltip>
								)}
							/>
							<Controller
								control={control}
								name="makeFreights"
								render={({ field }) => (
									<Tooltip message="Coloque seu veículo para fazer fretes pela BeeBee">
										<div
											className="w-32 min-w-[128px] hover:opacity-80"
											onClick={() => {
												field.onChange(!field.value);
											}}
										>
											<HexBtnFreighter
												backgroundColor={makeFreights ? '#fcf366' : '#868686'}
												textColor={makeFreights ? '#393939' : '#fff'}
												borderColor={makeFreights ? '#fcf366' : '#868686'}
											/>
										</div>
									</Tooltip>
								)}
							/>
						</div>
						{errors?.makeFreights?.message && (
							<p className="mt-1 text-xs text-terracota-500">
								{errors?.makeFreights?.message}
							</p>
						)}
					</div>

					<FormHeader title="Dados cadastrais" />
					<div className="flex flex-col gap-4 md:flex-row ">
						{role?.id !== Number(appState.userRoles.user.value) && (
							<div className="max-h-[200px] min-w-[200px] max-w-[200px]">
								<PopImageInput
									label="Logo"
									onImageLoad={(imageName) => {
										setValue('avatar', imageName);

										void handleSubmit(handleUpdate)();
									}}
									currentImage={avatar?.data.url}
								/>
							</div>
						)}
						<div className="flex w-full flex-col gap-4 ">
							<Input
								className="w-full"
								label="CNPJ"
								{...register('cnpj', {
									onChange: (event) => {
										setValue('cnpj', cnpjMask(event.target.value));
										if (RegexHelper.cnpj.test(event.target.value)) {
											cnpjValidation(event.target.value)
												? setError('cnpj', {
														message: '',
												  })
												: setError('cnpj', {
														message: 'CNPJ inválido',
												  });
										}
									},
								})}
								errorMessage={errors.cnpj?.message}
							/>
							<Input
								className="w-full"
								label="Nome Fantasia"
								{...register('tradingName')}
								errorMessage={errors.tradingName?.message}
							/>
							<Input
								className="w-full"
								label="Razão Social"
								{...register('companyName')}
								errorMessage={errors.companyName?.message}
							/>
						</div>
					</div>

					<FormHeader title="Localização" />
					<div className="flex flex-col gap-4 break-words">
						<Input
							className="w-full md:w-1/5"
							label="CEP"
							{...register('zipcode', {
								onChange: (e) => {
									e.target.value = cepMask(e.target.value);
									if (e.target.value.length === 9)
										axios
											.get(`https://viacep.com.br/ws/${e.target.value}/json/`)
											.then((res) => {
												setValue('address', res.data.logradouro);
												setValue('district', res.data.bairro);
												setValue('city', res.data.localidade);
												setValue(
													'stateId',
													STATES.find((state) => state.label === res.data.uf)
												);
											})
											.catch(() => {
												setError('zipcode', {
													message: 'CEP inválido',
												});
											});
								},
							})}
							errorMessage={errors.zipcode?.message}
						/>
						<div className="flex flex-col gap-4 md:flex-row md:items-center">
							<Input
								label="Endereço"
								{...register('address')}
								errorMessage={errors.address?.message}
							/>
							<div className="flex w-full items-center gap-2">
								<Input
									className="w-1/2"
									label="Número"
									{...register('number')}
									errorMessage={errors.number?.message}
								/>
								<Input
									label="Complemento"
									{...register('complement')}
									errorMessage={errors.complement?.message}
								/>
							</div>
						</div>
						<div className="flex flex-col gap-2 md:flex-row md:items-center">
							<Input
								className="md:1/3"
								label="Bairro"
								{...register('district')}
								errorMessage={errors.district?.message}
							/>
							<div className="flex items-center gap-2 md:w-full">
								<Input
									className="w-2/3 md:w-1/2"
									label="Cidade"
									{...register('city')}
									errorMessage={errors.city?.message}
								/>
								<Select
									label="Estado"
									className="w-1/3 md:w-1/2"
									controller={{
										control,
										name: 'stateId',
									}}
									options={STATES}
									errorMessage={errors.stateId?.message}
								/>
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<FormHeader title="Informações de Contato" />

						<div className="flex w-full flex-col gap-4">
							<div className="flex gap-2">
								<Input
									className="w-1/2"
									label="Email para faturamento"
									{...register('email')}
									errorMessage={errors.email?.message}
								/>
								<Input
									className="w-1/2"
									label="Email da Operação e Suporte"
									{...register('supportEmail')}
									errorMessage={errors.supportEmail?.message}
								/>
							</div>
							<div className="flex gap-2">
								<Input
									className="w-1/2 md:w-1/3"
									label="Telefone"
									{...register('phone', {
										onChange: (e) => {
											e.target.value = phoneMask(e.target.value);
										},
									})}
									errorMessage={errors.phone?.message}
								/>
								<Input
									className="w-1/2 md:w-1/3"
									label="Celular"
									{...register('cellphone', {
										onChange: (e) => {
											e.target.value = phoneMask(e.target.value);
										},
									})}
									errorMessage={errors.cellphone?.message}
								/>
							</div>
						</div>
						<FormHeader title="Metas" />
						<Input
							leftInputIcon={
								<span className="font-medium text-neutral-600">R$</span>
							}
							className="w-full md:w-1/4"
							label="Meta de gastos com pedidos"
							type="number"
							{...register('spendingGoal')}
							errorMessage={errors.spendingGoal?.message}
						/>
					</div>
					{role?.id !== Number(appState.userRoles.user.value) && (
						<div className="flex flex-col gap-4">
							<FormHeader
								title="Uso Interno"
								description="Disponível apenas para Admins"
							/>
							<Select
								label="Lead"
								controller={{
									control,
									name: 'leadId',
								}}
								errorMessage={errors.leadId?.message}
								options={leads?.data.map((l: any) => {
									return {
										label: l.leadOrigin,
										value: l.id,
									};
								})}
							/>
							<Select
								controller={{
									control,
									name: 'businessSegmentId',
								}}
								errorMessage={errors.businessSegmentId?.message}
								label="Segmento de mercado"
								options={segments?.data.map((s: any) => {
									return {
										label: s.businessSegment,
										value: s.id,
									};
								})}
							/>
							<Textarea label="Observação" {...register('observation')} />
						</div>
					)}

					<FormButtons
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						onSave={handleSubmit(
							role?.id !== Number(appState.userRoles.user.value)
								? handleUpdate
								: addNewCompany
						)}
						onCancel={() => navigate('/empresas')}
						saveProps={{
							disabled: !isDirty,
						}}
					/>
				</WhiteContainer>
				{role?.id !== Number(appState.userRoles.user.value) && (
					<div className="flex w-full flex-col lg:w-1/4">
						<div className="sticky top-0 flex w-full flex-col gap-2">
							<Form
								form={statusForm}
								onSubmit={handleAccessControlStatus}
								className="w-auto"
							>
								<div
									className={twMerge(
										'relative mb-4 flex w-full flex-col justify-between gap-4 rounded bg-neutral-1 px-4 py-4 text-neutral-900 shadow transition-all hover:opacity-100',
										`ring-2`,
										statusForm.watch('status') === '1' && 'ring-yellow-800',
										statusForm.watch('status') === '2' && 'ring-terracota-500',
										statusForm.watch('status') === '3' && 'ring-green'
									)}
								>
									<span>
										<h1 className="font-semibold">Controle de acesso</h1>
										<hr className="text-neutral-200" />
									</span>
									<Controller
										control={statusForm.control}
										name="status"
										render={({ field }) => (
											<RadioGroup.Root
												value={String(field.value)}
												className="flex w-full flex-col  gap-[10px]"
												onValueChange={field.onChange}
											>
												{entityStatus?.map((status) => (
													<div
														className="flex items-center gap-2"
														key={status.value}
													>
														<RadioGroup.Item
															className="h-4 w-4 rounded-full bg-white shadow shadow-neutral-500 hover:bg-neutral-100"
															value={String(status.value)}
															id={String(status.value)}
														>
															<RadioGroup.Indicator className="relative flex h-full w-full items-center justify-center after:block after:h-[11px] after:w-[11px] after:rounded-full after:bg-neutral-0" />
														</RadioGroup.Item>
														<label
															htmlFor={String(status.value)}
															className="tex-neutral-600 relative inline-flex cursor-pointer items-center text-sm"
														>
															{status.label}
														</label>
													</div>
												))}
											</RadioGroup.Root>
										)}
									/>
									<Button
										type="submit"
										disabled={!statusForm.formState.isDirty}
									>
										Alterar
									</Button>
								</div>
							</Form>

							<WhiteContainer>
								<Tags
									id={id}
									defaultValue={
										data?.data?.tags?.map((t: any) => {
											return {
												value: t.id,
												label: t.name,
											};
										}) || []
									}
									entityToUpdate="companies"
									entity="empresa"
									tags={tags?.data}
								/>
							</WhiteContainer>

							<WhiteContainer className="flex gap-4">
								<div className="flex flex-col gap-4">
									<FormHeader title="Configurações" />
									<Input
										leftInputIcon={
											<span className="font-medium text-neutral-600">%</span>
										}
										label="Desconto em fretes"
										type="number"
										{...register('discount')}
									/>
									<Input
										leftInputIcon={
											<span className="font-medium text-neutral-600">%</span>
										}
										label="Acréscimo em fretes"
										type="number"
										{...register('increase')}
									/>
								</div>

								<div className="mt-2 flex flex-col gap-4">
									<FormHeader title="Beebee log" />

									<Checkbox
										controller={{
											control,
											name: 'dynamicRouteEnabled',
										}}
										label="Rota livre"
									/>
									<Checkbox
										controller={{
											control,
											name: 'allowFavoriteDriver',
										}}
										label="Habilitar motoristas favoritos"
									/>
									<Checkbox
										controller={{
											control,
											name: 'allowBankingBill',
										}}
										label="Permitir pagamento por boleto"
									/>
									<Checkbox
										controller={{
											control,
											name: 'storeProtocolsInStaticRoute',
										}}
										label="Protocolos em rota fixa"
									/>
								</div>

								<FormButtons
									// eslint-disable-next-line @typescript-eslint/no-misused-promises
									onSave={handleSubmit(handleUpdate)}
									onCancel={() => reset()}
									saveProps={{
										disabled: !isDirty,
									}}
								/>
							</WhiteContainer>
						</div>
					</div>
				)}
			</div>

			{role?.id !== Number(appState.userRoles.user.value) && (
				<CompanyTables data={data?.data} />
			)}

			<Modal
				title="Arquivo inválido"
				className="w-1/2"
				open={isInvalidFileModalOpen}
				setOpen={setIsInvalidFileModalOpen}
			>
				<p>
					O arquivo enviado não é um arquivo de imagem válido. Por favor, envie
					um arquivo de imagem no formato PNG ou JPG.
				</p>
			</Modal>
		</div>
	);
};

export default AngularNewCompanyRegister;
