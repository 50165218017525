import { z } from 'zod';

export const NotificationSchema = z
	.object({
		formType: z
			.union([z.literal('users'), z.literal('phones')])
			.default('users'),
		types: z
			.array(
				z.object({
					value: z.number(),
					label: z.string(),
				})
			)
			.transform((data) => data.map(({ value }) => value)),
		title: z.string().min(1, 'Obrigatório'),
		message: z.string().min(1, 'Obrigatório'),
		phones: z
			.string()
			.transform((value) =>
				value
					.replace(/[^0-9,]/g, '')
					.split(',')
					.map((phone) => Number(phone))
			)
			.optional(),
		active: z
			.object({
				value: z.boolean(),
				label: z.string(),
			})
			.transform(({ value }) => value)
			.optional(),
		blocked: z
			.object({
				value: z.string(),
				label: z.string(),
			})
			.transform(({ value }) => (value === '' ? null : value === 'blocked'))
			.optional(),
		signupStatus: z
			.object({
				value: z.number(),
				label: z.string(),
			})
			.transform(({ value }) => (value === -1 ? null : value))
			.optional(),
		geofenceId: z
			.object({
				value: z.number(),
				label: z.string(),
			})
			.transform(({ value }) => value)
			.optional(),
		userTypes: z
			.array(
				z.object({
					value: z.number(),
					label: z.string(),
				})
			)
			.transform((data) => data.map(({ value }) => value))
			.optional(),
		userAvailabilities: z
			.array(
				z.object({
					value: z.number(),
					label: z.string(),
				})
			)
			.transform((data) => data.map(({ value }) => value))
			.optional(),
		wasTrained: z
			.object({
				value: z.number(),
				label: z.string(),
			})
			.transform(({ value }) => (value === -1 ? null : !!value))
			.optional(),
		categories: z
			.array(
				z.object({
					value: z.number(),
					label: z.string(),
				})
			)
			.transform((data) => data.map(({ value }) => value))
			.optional(),
		categoryTypeOne: z.boolean().nullable().optional(),
		categoryTypeTwo: z.boolean().nullable().optional(),
		subCategory: z
			.object({
				value: z.number(),
				label: z.string(),
			})
			.transform(({ value }) => (value === -1 ? null : value))
			.optional()
			.nullable(),
	})
	.transform(({ formType, ...data }) => {
		const { phones, message, title, types, subCategory, categories, ...rest } =
			data;

		const categoryTypeOne =
			categories?.length !== 1
				? null
				: subCategory === -1 || subCategory === null
				? null
				: subCategory === 1;

		return formType === 'phones'
			? { formType, phones, message, title, types }
			: {
					formType,
					message,
					title,
					types,
					categories,
					...rest,
					categoryTypeOne,
					categoryTypeTwo: categoryTypeOne === null ? null : !categoryTypeOne,
			  };
	});

export type NotificationSchemaType = z.infer<typeof NotificationSchema>;
