import React from 'react';
import { twMerge } from 'tailwind-merge';
import { variants, Variants } from './variants';

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
	label: string | React.ReactNode;
	variant: Variants;
}

export const Label: React.FC<LabelProps> = ({
	label,
	variant,
	className,
	...props
}) => {
	return (
		<label
			className={twMerge(
				`mb-1 flex w-full justify-start ${variants[variant].label}`,
				className
			)}
			{...props}
		>
			{label}
		</label>
	);
};
