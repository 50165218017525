import dayjs from 'dayjs';
import { useState } from 'react';
import { FaExclamationCircle, FaShare } from 'react-icons/fa';
import { TbClock, TbTruckDelivery } from 'react-icons/tb';
import { Driver } from '../../../../components/Angular/FreightsDetail/Driver';
import { FiltersTab } from '../../../../components/FiltersTab';
import {
	Date,
	Input,
	Select,
	useZodForm,
} from '../../../../components/FormElements';
import { PageHeader } from '../../../../components/PageHeader';
import { Table, TableActions } from '../../../../components/Table/Table';
import { Tooltip } from '../../../../components/Tooltip';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import useUserState from '../../../../services/angular/angularUserState';
import { SortOptions } from '../../../../services/angular/types/Params';
import { useGetFreights } from '../../../../services/angular/useOrders';
import { FilterSchema, FilterSchemaType } from './schemas/Filter';
import { Distance } from './Distance';
import { Row } from 'react-table';
import { twMerge } from 'tailwind-merge';
import { OrderResponseApi } from '../../../../services/angular/types/Order';
import { StatusTooltip } from '../../../../components/Angular/StatusTooltip';
import { AiFillStar } from 'react-icons/ai';
import { appState } from '../../../../store/appState';
import { CategoryType } from '../../../../components/Order/CategoryType';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { api } from '../../../../services/angular/axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import Button from '../../../../components/Button/Button';
import { ImDownload3 } from 'react-icons/im';
import { downloadFile } from '../../../../helpers/downloadFile';
import { ToastError } from '../../../../helpers/errors/ToastError';

export const Historic = () => {
	const company = useUserState((state) => state.company);
	const [query, setQuery] = useState<FilterSchemaType>();

	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});

	const { data, isInitialLoading, isFetching } = useGetFreights(
		{
			companyId: company?.id,
			companyType: company?.type,
			status: 'historic',
			query,
			...params,
		},
		{
			enabled: !!company,
			refetchOnMount: 'always',
		}
	);

	const { data: totals } = useQuery(
		['totals', params, query, company?.id],
		async () => {
			const response = await api.get<{
				total: number;
				driverSubTotal: number;
			}>(`/orders/schedules/companies/${company!.id}/totals`, {
				params: {
					page: params.page,
					pageSize: params.take,
					status: 'historic',
					profile: company?.type,
					...query,
				},
			});

			return response.data;
		},
		{
			enabled: !!company?.id,
			refetchOnMount: 'always',
		}
	);

	const form = useZodForm({
		schema: FilterSchema,
	});

	const { mutate: exportTableData, isLoading: downloading } = useMutation(
		async () =>
			await api.get(`/orders/schedules/companies/${company!.id}/export`, {
				params: {
					status: 'historic',
					profile: company?.type,
					...query,
				},
				responseType: 'blob',
			}),
		{
			onSuccess: async ({ data }) => {
				await downloadFile({
					blobFile: data,
					fileName: `historico-${dayjs().format('DD/MM/YYYY_HH:mm')}.xlsx`,
				});
			},
			onError: (error) => {
				ToastError(error);
			},
		}
	);

	return (
		<div>
			<PageHeader
				title="Histórico de fretes"
				description="Lista de fretes realizados"
			>
				<div className="flex items-center gap-4 text-center ">
					{(company?.makeFreights || company?.isDriver) && (
						<div className="flex flex-col rounded p-2 shadow-md shadow-neutral-200">
							<h3 className="text-xs text-green">Entregas</h3>
							<p className="text-2xl font-medium text-green">
								{totals?.driverSubTotal.toLocaleString('pt-br', {
									style: 'currency',
									currency: 'BRL',
								})}
							</p>
							<p className="text-2xs text-neutral-600">
								Total de acordo com o filtro
							</p>
						</div>
					)}
					{!company?.isDriver && (
						<div className="flex flex-col rounded p-2 shadow-md shadow-neutral-200">
							<h3 className="text-xs text-terracota-600">Pedidos</h3>
							<p className="text-2xl font-medium text-terracota-600">
								{totals?.total.toLocaleString('pt-br', {
									style: 'currency',
									currency: 'BRL',
								})}
							</p>
							<p className="text-2xs text-neutral-600">
								Total de acordo com o filtro
							</p>
						</div>
					)}
				</div>
			</PageHeader>

			<FiltersTab
				form={form}
				clearFilters={() => {
					form.reset();
					setQuery(undefined);
				}}
				onSubmit={(data) => {
					setQuery(data);
				}}
				actionsChildren={
					<Button
						loading={downloading}
						type="button"
						className="w-auto self-end py-1"
						onClick={() => {
							void exportTableData();
						}}
					>
						<ImDownload3 size={17} />
						Exportar
					</Button>
				}
			>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="initialDate"
					label="De"
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="endDate"
					label="Até"
				/>
				<Select
					variant="light"
					className="w-full md:w-auto md:min-w-[128px]"
					label="Data"
					controller={{
						control: form.control,
						name: 'operation',
						defaultValue: null,
					}}
					options={[
						{
							label: 'Imediato',
							value: 'immediate',
						},
						{
							label: 'Agendado',
							value: 'scheduled',
						},
					]}
				/>
				<Select
					variant="light"
					className="w-full md:w-auto md:min-w-[128px]"
					label="Tipo"
					controller={{
						control: form.control,
						name: 'orderTypeId',
						defaultValue: null,
					}}
					options={[
						{
							label: 'Por hora',
							value: '3',
						},
						{
							label: 'Rota fixa',
							value: '1',
						},
						{
							label: 'Rota livre',
							value: '2',
						},
					]}
				/>
				<Select
					variant="light"
					className="w-full md:w-auto md:min-w-[128px]"
					label="Status"
					isMulti
					controller={{
						control: form.control,
						name: 'statusId',
						defaultValue: null,
					}}
					options={[
						{
							label: 'Finalizado',
							value: '6',
						},
						{
							label: 'Cancelado',
							value: '7',
						},
					]}
				/>
				<Input
					className="w-full md:w-auto"
					variant="light"
					label="Pesquisar"
					{...form.register('query')}
				/>
			</FiltersTab>

			<WhiteContainer>
				<Table<OrderResponseApi>
					isLoading={isInitialLoading || isFetching}
					columns={[
						{
							accessor: 'id',
							Header: 'ID',
							Cell: ({ row }) => {
								const {
									id,
									searchOnlyFavoriteDrivers,
									integrationOrder,
									status,
								} = row.original;

								return (
									<div className="flex flex-col">
										<div className="flex items-center justify-around">
											{searchOnlyFavoriteDrivers && (
												<Tooltip message="Somente motoristas favoritos">
													<AiFillStar
														className="text-yellow-submenu"
														size={22}
													/>
												</Tooltip>
											)}
											{integrationOrder && (
												<Tooltip
													message="Pedido de integração"
													triggerClassName="text-neutral-700 flex flex-row gap-x-2 items-center"
												>
													<BsFillInfoCircleFill size={18} />
													{integrationOrder?.displayId}
												</Tooltip>
											)}
										</div>
										<div className="text-lg font-bold text-orange">
											#{String(id).padStart(5, '0')}
										</div>
										<span className="text-xs text-neutral-600">
											{status.name}
										</span>
									</div>
								);
							},
						},
						{
							accessor: 'orderDate',
							Header: 'Data',
							Cell: ({ row }) => {
								const { orderDate, scheduleDate } = row.original;
								const date = dayjs(orderDate).format('DD/MM/YYYY HH:mm');

								return (
									<div className="flex gap-1">
										{scheduleDate ? (
											<TbClock size={30} />
										) : (
											<TbTruckDelivery size={30} />
										)}
										<div className="flex flex-col">
											<span className="font-bold text-neutral-800">
												{scheduleDate ? 'Agendado' : 'Imediato'}
											</span>
											<span className="text-sm text-neutral-500">{date}</span>
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'completionDate',
							Header: 'Término',
							Cell: ({ value }) => {
								const date = dayjs(value).format('DD/MM/YYYY HH:mm');
								return (
									<div className="flex flex-col text-sm">
										<span>{date}</span>
										<span className="text-xs text-neutral-500">Conclusão</span>
									</div>
								);
							},
						},
						{
							// @ts-expect-error
							accessor: 'driverCompany.id',
							Header: 'Motorista',
							Cell: ({ row }: { row: Row<OrderResponseApi> }) => {
								return (
									<>
										{row.original.driver && row.original.driverCompany ? (
											<Driver
												orderId={row.original.id}
												driver={row.original.driver}
												driverCompany={row.original.driverCompany}
											>
												<div className="flex flex-col items-start">
													<div className="line-clamp-2 text-left font-medium leading-none">
														{row.original.driver.name}
													</div>
													<span className="mt-1 text-xs text-neutral-600">
														Fretista
													</span>
												</div>
											</Driver>
										) : (
											<div className="flex flex-col">-</div>
										)}
									</>
								);
							},
						},
						{
							accessor: 'categoryId',
							Header: 'Veículo',
							Cell: ({ row }) => (
								<CategoryType
									category={row.original.category}
									categoryType={row.original.categoryType}
									isFavoriteDriver={row.original.isFavoriteDriver}
									defrauded={row.original.defrauded}
								/>
							),
						},
						{
							accessor: 'distance',
							Header: 'Distância',
							Cell: ({ row }) => (
								<div className="flex justify-center gap-1">
									<Distance order={row.original} />
								</div>
							),
						},
						{
							accessor: 'total',
							Header: 'Valor',
							Cell: ({ row }) => {
								const { total, delivery, statusId, driverSubTotal } =
									row.original;
								return (
									<div className="flex flex-col justify-center">
										{statusId === appState.orderStatus.Cancelado.id ? (
											'-'
										) : (
											<span
												className={twMerge(
													delivery ? 'text-green' : 'text-terracota-500'
												)}
											>
												{delivery
													? driverSubTotal.toLocaleString('pt-br', {
															style: 'currency',
															currency: 'BRL',
													  })
													: total.toLocaleString('pt-br', {
															style: 'currency',
															currency: 'BRL',
													  })}
											</span>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'statusId',
							Header: 'Status',
							Cell: ({ row }) => {
								const { incident, status } = row.original;

								return (
									<div className="flex">
										<StatusTooltip order={row.original}>
											<div className="flex justify-center text-sm font-semibold">
												{incident ? (
													<span className="flex items-center gap-1 text-terracota-500">
														<FaExclamationCircle size={16} />
														Incidente
													</span>
												) : (
													status.name
												)}
											</div>
										</StatusTooltip>
									</div>
								);
							},
						},
						{
							accessor: 'created_at',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => (
								<TableActions
									row={row}
									actions={[
										{
											icon: <FaExclamationCircle size={16} />,
											label: 'Detalhes',
											href: `/fretes/detalhes/${row.original.id}`,
										},
										{
											icon: <FaShare size={16} />,
											label: 'Pedir novamente',
											href: `/fretes/pedir?ref=${row.original.id}`,
										},
									]}
								/>
							),
						},
					]}
					disabledSort={{
						created_at: true,
					}}
					data={data?.data.data || []}
					total={data?.data.meta.pagination.total || 0}
					onParamsChange={(params) => {
						setParams(params);
					}}
					defaultTake={25}
					triggerPageChange={form.formState.isSubmitting}
				/>
			</WhiteContainer>
		</div>
	);
};
