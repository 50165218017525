import { ClientDetails } from './ClientDetails';
import { DriverDetails } from './DriverDetails';
import { FreightSummary } from './FreightSummary';
import { LocationsAccordion } from './LocationsAccordion';
import { VehicleDetails } from './VehicleDetails';

export const Order = {
	ClientDetails,
	DriverDetails,
	FreightSummary,
	LocationsAccordion,
	VehicleDetails,
};
