// import Button from '../../../../../components/Button/Button';
import {
	oldIcons,
	VehicleIcon,
} from '../../../../../../components/Icon/vehicles';
import { HiTrash } from 'react-icons/hi';
import { MdModeEdit } from 'react-icons/md';
import { Tooltip } from '../../../../../../components/Tooltip';
import { api } from '../../../../../../services/angular/axios';
import { toast } from 'react-toastify';
import { useAlert } from '../../../../../../contexts/AlertContext';
import { BsFillEyeSlashFill } from 'react-icons/bs';
import { Category } from '../../../../../../services/angular/types/Order';
import { Link } from 'react-router-dom';

interface CategoryProps {
	category: Category;
	refetch: () => void;
}

export const CategoryCard = ({
	category: { active: isActive, icon, name, height, width, weight, id },
	refetch,
}: CategoryProps) => {
	const alert = useAlert();

	const handleDelete = async (id: number) => {
		alert.onCustom({
			title: 'Excluir categoria',
			message: `Deseja realmente excluir a categoria #${id}?`,
			confirm: {
				label: 'Excluir',
				onClick: async () => {
					try {
						await api.delete(`/categories/${id}`);
						toast.success('Categoria excluída com sucesso!');
						refetch();
					} catch (error: any) {
						toast.error(error?.response.data.error);
					}
				},
			},
		});
	};

	return (
		<div className={!isActive ? ' opacity-50' : 'none'} key={name}>
			<div className="group relative flex h-full gap-4 rounded bg-neutral-1 px-4 py-4 text-neutral-900 shadow hover:opacity-100 hover:ring-2 hover:ring-[#1671e4] md:px-8">
				<div className="flex items-center rounded-md bg-neutral-100 px-2 ">
					{/* @ts-expect-error  */}
					<VehicleIcon name={oldIcons[icon]} size={80} />
				</div>

				<div className="w-full text-sm">
					<h1 className="text-base font-bold text-neutral-0">{name}</h1>
					<p>Dimensões aproximada: </p>
					<p>
						{height} x {width} x {weight}
					</p>
					<p>Capacidade máxima: {weight}kg</p>
				</div>

				<div className="flex flex-col justify-end gap-2 transition-all">
					{!isActive && (
						<Tooltip
							message="Esta categoria está desativada e não aparecerá para novos pedidos."
							triggerClassName="p-1"
						>
							<BsFillEyeSlashFill size={20} />
						</Tooltip>
					)}

					<div className="mt-auto flex flex-col gap-2 transition-all">
						<Tooltip message="Editar">
							<Link to={`/configuracoes/veiculos/${id}`}>
								<div className="items-end rounded-full bg-neutral-400 p-1 text-neutral-1">
									<MdModeEdit
										size={18}
										className="cursor-pointer hover:opacity-80"
									/>
								</div>
							</Link>
						</Tooltip>

						<Tooltip message="Deletar">
							<div
								className="items-end rounded-full bg-terracota-0 p-1 text-neutral-1"
								onClick={() => {
									void handleDelete(id);
								}}
							>
								<HiTrash
									size={18}
									className="cursor-pointer hover:opacity-80"
								/>
							</div>
						</Tooltip>
					</div>
				</div>
			</div>
		</div>
	);
};
