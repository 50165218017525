import { z } from 'zod';

export const vehicleFilterSchema = z.object({
	plate: z.string().nullable().optional(),
	geofenceId: z
		.object({
			value: z.number(),
			label: z.string().optional(),
		})
		.nullable()
		.optional()
		.transform((value) => value?.value),
	categoryId: z
		.array(
			z.object({
				value: z.number(),
				label: z.string().optional(),
			})
		)
		.nullable()
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
	availability: z
		.array(
			z.object({
				value: z.string(),
				label: z.string().optional(),
			})
		)
		.nullable()
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
	status: z
		.array(
			z.object({
				value: z.string(),
				label: z.string().optional(),
			})
		)
		.nullable()
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
});

export type VehicleFilterSchemaType = z.infer<typeof vehicleFilterSchema>;
