import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const FileDownloadIcon: React.FC<SvgProps> = ({ color = '#FCF366', size }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M24.1665 3.3335H9.99984C9.11578 3.3335 8.26794 3.68469 7.64281 4.30981C7.01769 4.93493 6.6665 5.78277 6.6665 6.66683V33.3335C6.6665 34.2176 7.01769 35.0654 7.64281 35.6905C8.26794 36.3156 9.11578 36.6668 9.99984 36.6668H29.9998C30.8839 36.6668 31.7317 36.3156 32.3569 35.6905C32.982 35.0654 33.3332 34.2176 33.3332 33.3335V12.5002L24.1665 3.3335Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M23.3335 3.3335V13.3335H33.3335"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M20 30V20"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 25L20 30L25 25"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default FileDownloadIcon;
