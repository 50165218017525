import React, { useEffect } from 'react';
import Button from '../../../../../components/Button/Button';
import { STATES } from '../../../../../helpers/states';
import { useZodForm } from '../../../../../components/FormElements/Form';
import { Input } from '../../../../../components/FormElements/Input';
import { Select } from '../../../../../components/FormElements/Select';
import { SwitchButton } from '../../../../../components/FormElements/SwitchButton';

import {
	CardPaymentSchema,
	CardPaymentSchemaType,
} from '../schemas/CardPaymentSchema';
import * as RadioGroup from '@radix-ui/react-radio-group';
import useUserState from '../../../../../services/angular/angularUserState';
import { api } from '../../../../../services/angular/axios';
import { cepMask } from '../../../../../helpers/mask/cepMask';
import { useQuery } from '@tanstack/react-query';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FormHeader } from '../../../../../components/FormElements';
import axios from 'axios';
import { cpfMask } from '../../../../../helpers/mask/cpfMask';
import { phoneMask } from '../../../../../helpers/mask/phoneMask';
import cpfValidation from '../../../../../helpers/validation/cpfValidation';
import { RegexHelper } from '../../../../../helpers/regex/RegexHelper';
import { BsTrashFill } from 'react-icons/bs';
import { useAlert } from '../../../../../contexts/AlertContext';
import { twMerge } from 'tailwind-merge';

interface CardPaymentProps {
	value: number;
}
interface CreditCard {
	id: number;
	cardHolderName: string;
	companyId: number;
	userId: number;
	lastFourDigits: number;
	brand: string;
	mainCard: boolean;
}

const CardPayment: React.FC<CardPaymentProps> = ({ value }) => {
	const company = useUserState((state) => state.company);
	const alert = useAlert();

	useEffect(() => {
		form.setValue('value', value);
	}, [value]);

	const form = useZodForm({
		schema: CardPaymentSchema,
		defaultValues: {
			cardId: 'new-card',
		},
	});
	const {
		control,
		register,
		handleSubmit,
		watch,
		setError,
		setValue,
		formState: { errors },
	} = form;

	const { data, isLoading } = useQuery(
		['credit-card', company?.id],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${company?.id}/payment/credit-card`
			)
	);

	useEffect(() => {
		if (data)
			form.setValue(
				'cardId',
				String(data.data.find((item: CreditCard) => item.mainCard)?.id)
			);
	}, [data, isLoading]);

	const handlePayment = async (data: CardPaymentSchemaType) => {
		try {
			await api.post(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${company?.id}/payment/credit-card`,
				{
					...data,
					brand: 'Mastercard',
				}
			);
			toast.success('Pagamento realizado com sucesso');
		} catch (error) {
			toast.error('Erro ao realizar pagamento');
		}
	};

	const handlePaymentExistingCard = async () => {
		try {
			await api.post(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${company?.id}/payment/credit-card/${form.watch('cardId')}`,
				{
					value,
					cardId: form.watch('cardId'),
					brand: 'Mastercard',
				}
			);
			toast.success('Pagamento realizado com sucesso');
		} catch (error) {
			toast.error('Erro ao realizar pagamento');
		}
	};

	return (
		<div className="align-center flex w-full flex-col justify-center">
			<div className="flex flex-col items-center">
				<h1 className="mb-2 text-xl font-bold">Pagar com Cartão de Crédito</h1>
				<h3 className="mb-2">
					Valor:{' '}
					<span className="text-xl font-bold text-green ">
						R${value.toFixed(2).replace('.', ',')}
					</span>
				</h3>
			</div>

			<span className="mb-4 w-full text-left font-bold text-neutral-900">
				Informe o cartão de Crédito desejado:
			</span>
			<Controller
				control={control}
				name="cardId"
				render={({ field }) => (
					<RadioGroup.Root
						value={field.value}
						className="mb-6 flex w-full flex-col items-start gap-[10px] rounded"
						onValueChange={field.onChange}
					>
						{data?.data.map((item: CreditCard) => (
							<div
								key={item.id}
								className={`flex w-full items-center gap-4 rounded border border-neutral-200 p-2 ${
									field.value === String(item.id) ? 'bg-yellow-500/50' : ''
								}`}
							>
								<RadioGroup.Item
									className="h-6 w-6  rounded-full bg-white shadow shadow-neutral-500 hover:bg-neutral-100"
									value={String(item.id)}
									id={String(item.id)}
								>
									<RadioGroup.Indicator className="relative flex h-full w-full items-center justify-center after:block after:h-[11px] after:w-[11px] after:rounded-full after:bg-neutral-0" />
								</RadioGroup.Item>
								<label
									htmlFor={String(item.id)}
									className="tex-neutral-600 relative inline-flex cursor-pointer items-center text-sm"
								>
									<div className="flex items-center gap-2">
										<img
											src={`/card-brands/${item.brand}.svg`}
											alt={item.brand}
											className="mr-4 h-8"
										/>
										<div className="flex flex-col">
											<span className=" text-neutral-900">
												**** **** **** {item.lastFourDigits}
											</span>
											<span className="font-bold">{item.cardHolderName}</span>
										</div>
										{item.mainCard && (
											<span className="rounded bg-blue px-1 text-sm text-white">
												Principal
											</span>
										)}
									</div>
								</label>
								<div
									className="rounded p-1 text-terracota-500 hover:opacity-50"
									// eslint-disable-next-line @typescript-eslint/no-misused-promises
									onClick={async () => {
										alert.onCustom({
											title: 'Deseja realmente excluir o cartão?',
											message: 'Essa ação não poderá ser desfeita',
											confirm: {
												label: 'Excluir',
												onClick: async () => {
													try {
														await api.delete(
															// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
															`/company/${company?.id}/credit-card/${item.id}`
														);
														toast.success('Cartão excluído com sucesso');
													} catch (error) {
														toast.error('Erro ao excluir cartão');
													}
												},
											},
										});
									}}
								>
									<BsTrashFill />
								</div>
							</div>
						))}

						<div
							className={twMerge(
								'flex w-full items-center gap-4 rounded border border-neutral-200 p-2',
								field.value === 'new-card' && 'bg-yellow-500/50'
							)}
						>
							<RadioGroup.Item
								value="new-card"
								className="h-6 w-6 rounded-full bg-white shadow shadow-neutral-500 hover:bg-neutral-100"
								id="new-card"
							>
								<RadioGroup.Indicator className="relative flex h-full w-full items-center justify-center after:block after:h-[11px] after:w-[11px] after:rounded-full after:bg-neutral-0" />
							</RadioGroup.Item>
							<label
								htmlFor="new-card"
								className="tex-neutral-600 relative inline-flex cursor-pointer items-center text-sm"
							>
								Adicionar novo cartão
							</label>
						</div>
					</RadioGroup.Root>
				)}
			/>

			{watch('cardId') === 'new-card' && (
				<>
					<div className="w-full">
						<Input
							className="mb-2"
							label="Nome do Titular"
							{...register('cardHolderName')}
							errorMessage={errors.cardHolderName?.message}
						/>
						<Input
							className="mb-8"
							label="Número do Cartão"
							{...register('cardNumber', {
								onChange: (e) => {
									const value = e.target.value;
									const mask = value
										.replace(/\D/g, '')
										.replace(/(\d{4})(\d)/, '$1 $2')
										.replace(/(\d{4})(\d)/, '$1 $2')
										.replace(/(\d{4})(\d)/, '$1 $2');
									e.target.value = mask;
								},
							})}
							errorMessage={errors.cardNumber?.message}
							maxLength={19}
						/>

						<FormHeader title="Data de vencimento" />
						<div className="mb-8 flex items-center gap-2">
							<Input
								max={12}
								label="Mês"
								{...register('expirationMonth', {
									onChange: (e) => {
										const value = e.target.value;
										const mask = value
											.replace(/\D/g, '')
											.replace(/(\d{2})(\d)/, '$1/$2');
										e.target.value = mask;

										if (e.target.value.length > 2) {
											e.target.value = e.target.value.substring(0, 2);
										}
									},
								})}
								errorMessage={errors.expirationMonth?.message}
							/>
							<Input
								className=""
								label="Ano"
								maxLength={2}
								{...register('expirationYear', {
									onChange: (e) => {
										const value = e.target.value;
										const mask = value
											.replace(/\D/g, '')
											.replace(/(\d{2})(\d)/, '$1/$2');
										e.target.value = mask;

										if (e.target.value.length > 2) {
											e.target.value = e.target.value.substring(0, 2);
										}
									},
								})}
								errorMessage={errors.expirationYear?.message}
							/>
							<Input
								className=""
								label="CVV"
								maxLength={3}
								{...register('securityCode', {
									onChange: (e) => {
										const value = e.target.value;
										const mask = value.replace(/\D/g, '');
										e.target.value = mask;

										if (e.target.value.length > 3) {
											e.target.value = e.target.value.substring(0, 3);
										}
									},
								})}
								errorMessage={errors.securityCode?.message}
							/>
						</div>
					</div>
					<FormHeader title="Informe seus dados pessoais" />
					<div className="mb-8 w-full">
						<Input
							className="mb-2"
							label="Nome"
							{...form.register('name')}
							errorMessage={errors.name?.message}
						/>
						<Input
							className="mb-2"
							label="Sobrenome"
							{...register('lastName')}
							errorMessage={errors.lastName?.message}
						/>
						<Input
							className="mb-2"
							label="E-mail"
							{...register('email')}
							errorMessage={errors.email?.message}
						/>

						<div className="mb-2 grid grid-flow-row-dense grid-cols-2 grid-rows-1 gap-y-2">
							<Input
								className="mb-2 pr-2"
								label="Celular"
								{...register('cellphone', {
									onChange: (e) => {
										e.target.value = phoneMask(e.target.value);
									},
								})}
								errorMessage={errors.cellphone?.message}
							/>
							<Input
								className="mb-2"
								label="CPF"
								{...register('cpf', {
									onChange: (event) => {
										setValue('cpf', cpfMask(event.target.value));
										if (RegexHelper.cpf.test(event.target.value)) {
											cpfValidation(event.target.value)
												? setError('cpf', {
														message: '',
												  })
												: setError('cpf', {
														message: 'CPF inválido',
												  });
										}
									},
								})}
								errorMessage={errors.cpf?.message}
							/>
						</div>
					</div>
					<FormHeader title="Endereço de cobrança" />
					<div className="mb-4 w-full">
						<Input
							className="mb-2 w-1/2 pr-2"
							label="CEP"
							{...register('zipcode', {
								onChange: (e) => {
									e.target.value = cepMask(e.target.value);
									if (e.target.value.length === 9)
										axios
											// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
											.get(`https://viacep.com.br/ws/${e.target.value}/json/`)
											.then((res) => {
												form.setValue('address', res.data.logradouro);
												form.setValue('district', res.data.bairro);
												form.setValue('city', res.data.localidade);
												form.setValue(
													'stateId',
													STATES.find((state) => state.label === res.data.uf)
												);
											})
											.catch(() => {
												form.setError('zipcode', {
													message: 'CEP inválido',
												});
											});
								},
							})}
							errorMessage={errors.zipcode?.message}
						/>
						<Input
							className="mb-2"
							label="Endereço"
							{...form.register('address')}
							errorMessage={form.formState.errors.address?.message}
						/>
						<div className="flex gap-2">
							<Input
								className="mb-2 w-1/4"
								label="Número"
								{...register('number')}
								errorMessage={errors.number?.message}
							/>
							<Input
								className="mb-2"
								label="Complemento"
								{...register('complement')}
								errorMessage={errors.complement?.message}
							/>
						</div>

						<Input
							className="mb-2"
							label="Bairro"
							{...register('district')}
							errorMessage={errors.district?.message}
						/>
						<div className="flex flex-row justify-between">
							<Input
								className="mb-2 pr-2"
								label="Cidade"
								{...register('city')}
								errorMessage={errors.city?.message}
							/>
							<Select
								label="Estado"
								className="w-2/5"
								controller={{
									control,
									name: 'stateId',
								}}
								options={STATES}
								errorMessage={errors.stateId?.message}
							/>
						</div>
					</div>
					<div className="flex w-full flex-col gap-2">
						<SwitchButton
							label="Salvar este cartão"
							{...register('saveCardData')}
						/>
						<SwitchButton
							label="Tornar esse meu cartão principal"
							{...register('mainCard')}
						/>
					</div>
				</>
			)}

			<Button
				variant="primary"
				disabled={form.formState.isSubmitting}
				className="mt-4 border-none bg-blue px-3 py-2 hover:bg-neutral-100 active:bg-neutral-50"
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				onClick={
					watch('cardId') === 'new-card'
						? handleSubmit(handlePayment)
						: handlePaymentExistingCard
				}
			>
				FINALIZAR PAGAMENTO
			</Button>
		</div>
	);
};

export default CardPayment;
