import {
	Checkbox,
	FormButtons,
	Input,
	Select,
	SwitchButton,
} from '../../../../../../components/FormElements';
import {
	Form,
	useZodForm,
} from '../../../../../../components/FormElements/Form';
import { PageHeader } from '../../../../../../components/PageHeader';
import WhiteContainer from '../../../../../../components/WhiteContainer/WhiteContainer';
import { appState, useAppState } from '../../../../../../store/appState';
import { icons, VehicleIcon } from '../../../../../../components/Icon/vehicles';
import { useCallback, useState } from 'react';
import { api } from '../../../../../../services/angular/axios';
import { useQuery } from '@tanstack/react-query';
import { Controller, useFieldArray } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { Category } from '../../../../../../services/angular/types/Order';
import {
	categorySchema,
	CategorySchemaType,
} from '../schema/CreateCategorySchema';
import { Loading } from '../../../../../../components/Loading';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ToastError } from '../../../../../../helpers/errors/ToastError';

interface CategoryFormProps {
	categoryId?: number;
}

export const CategoryForm = ({ categoryId }: CategoryFormProps) => {
	const navigate = useNavigate();
	const [selectedIcon, setSelectedIcon] = useState<any>();
	const geofence = useAppState((state) => state.geofence);
	const form = useZodForm({
		schema: categorySchema,
		defaultValues: {
			costs: [
				{
					orderTypeId: 1,
					costPerKm: 0,
					farePerRoute: 0,
					minValue: 0,
					startFare: 0,
				},
				{
					orderTypeId: 2,
					costPerKm: 0,
					farePerRoute: 0,
					minValue: 0,
					startFare: 0,
				},
			],
		},
	});

	const { fields } = useFieldArray({
		control: form.control,
		name: 'costs',
	});

	const onSubmit = async (formData: CategorySchemaType) => {
		try {
			categoryId
				? await api.put(`/categories/${categoryId}`, formData)
				: await api.post('/categories', formData);

			toast.success(
				`Categoria ${categoryId ? 'atualizada' : 'criada'} com sucesso!`
			);
			navigate(-1);
		} catch (error) {
			if (error)
				toast.error(
					`Houve algum problema ao  ${
						categoryId ? 'atualizar' : 'criar'
					} categoria.`
				);
		}
	};

	const { isFetching } = useQuery(
		['edit-vehicle', categoryId],
		async () => {
			const { data } = await api.get<Category>(`/categories/${categoryId!}`);
			return data;
		},
		{
			enabled: !!categoryId,
			cacheTime: 0,
			staleTime: 0,
			onSuccess: (data) => {
				setSelectedIcon(icons.find((i) => i.apiReqIcon === data.icon));
				form.reset({
					...data,
					geofenceId: {
						value: data?.geofenceId,
						label: handleGeofence(data?.geofenceId) || '',
					},
				});
			},
			onError: (error) => {
				ToastError(error);
				navigate('/configuracoes/veiculos');
			},
		}
	);

	const handleGeofence = (geofenceId: number) => {
		const value = geofence.find((g) => {
			return g.id === geofenceId ? g.name : '';
		});
		return value?.name;
	};

	const maxLength = useCallback((value: string) => value.slice(0, 10), []);

	if (isFetching) return <Loading title="Carregando dados do veículo..." />;

	return (
		<Form form={form} onSubmit={onSubmit}>
			<WhiteContainer className="flex flex-col gap-4">
				<PageHeader title="Informações Gerais" />
				<SwitchButton label="Categoria ativa?" {...form.register('active')} />

				<div className="flex flex-wrap items-center gap-4 ">
					<Controller
						name="icon"
						control={form.control}
						render={({ field }) => (
							<div className="flex flex-col gap-1">
								<p className="text-sm font-bold text-neutral-700">
									Tipo de categoria:{' '}
									<span className="text-terracota-500">
										{form.formState.errors.icon?.message}
									</span>
								</p>
								<div className="flex flex-wrap gap-2">
									{icons.map((icon, index) => (
										<div
											key={`${index}-${icon.id}`}
											className={twMerge(
												'flex  cursor-pointer items-center justify-between rounded border px-2 py-1 transition-all hover:bg-neutral-100',
												icon?.apiReqIcon === field.value &&
													' bg-neutral-0 text-white hover:bg-neutral-800'
											)}
											onClick={() => {
												field.onChange(icon.apiReqIcon);
												setSelectedIcon(icon);
											}}
										>
											<VehicleIcon name={icon.svgReference} size={40} />
										</div>
									))}
								</div>
							</div>
						)}
					/>
				</div>
				<div className="flex gap-2">
					<Input
						className="md:w-[calc(33.33%-16px)] md:min-w-[128px]"
						label="Nome da categoria:"
						{...form.register('name')}
						errorMessage={form.formState.errors.name?.message}
						maxLength={50}
					/>

					<Select
						label="Geofence:"
						className="md:w-[calc(33.33%-16px)] md:min-w-[128px]"
						controller={{
							control: form.control,
							name: 'geofenceId',
						}}
						options={geofence?.map((state) => ({
							label: state.name,
							value: state.id,
						}))}
						errorMessage={form.formState.errors.geofenceId?.message}
					/>
				</div>
				<div className="flex flex-col flex-wrap gap-2">
					{/* <h3>Dimensões máximas:</h3> */}
					<div className="flex flex-wrap items-center gap-4">
						<Input
							className="md:w-[calc(20%-16px)] md:min-w-[128px]"
							label="Largura:"
							{...form.register('width', {
								onChange: (e) => {
									e.target.value = maxLength(e.target.value);
								},
							})}
							type="number"
							step="any"
						/>
						<Input
							className="md:w-[calc(20%-16px)] md:min-w-[128px]"
							label="Altura:"
							{...form.register('height', {
								onChange: (e) => {
									e.target.value = maxLength(e.target.value);
								},
							})}
							type="number"
							step="any"
						/>
						<Input
							className="md:w-[calc(20%-16px)] md:min-w-[128px]"
							label="Comprimento:"
							{...form.register('length', {
								onChange: (e) => {
									e.target.value = maxLength(e.target.value);
								},
							})}
							type="number"
							step="any"
						/>
						<Input
							className="md:w-[calc(20%-16px)] md:min-w-[128px]"
							label="Capacidade máxima:"
							{...form.register('weight', {
								onChange: (e) => {
									e.target.value = maxLength(e.target.value);
								},
							})}
							type="number"
							step="any"
						/>
						<Input
							className="md:w-[calc(20%-16px)] md:min-w-[128px]"
							label="Detalhes da carga:"
							{...form.register('weightDetails')}
						/>
					</div>
				</div>

				{selectedIcon && (
					<>
						<hr className="mt-4 text-neutral-200" />
						<PageHeader title="Subcategoria" />
						<div className="flex w-full items-center gap-4">
							<div className="flex w-[50%] flex-col  gap-4">
								<Checkbox
									label="Ativa?"
									controller={{
										name: 'typeOneActive',
										control: form.control,
									}}
								/>
								<Input
									label="Descrição:"
									{...form.register('typeOneDescription')}
									errorMessage={
										form.formState.errors.typeOneDescription?.message
									}
								/>

								<Controller
									control={form.control}
									name="typeOneIcon"
									render={({ field }) => (
										<div className="flex flex-col gap-1">
											<p className="text-sm font-bold text-neutral-700">
												Código do ícone:{' '}
												<span className="text-terracota-500">
													{form.formState.errors.typeOneIcon?.message}
												</span>
											</p>
											<div className="flex gap-2">
												{icons
													.filter((value) => value.id === selectedIcon?.id)
													.map((icon, index) => (
														<div
															key={`${icon.id}-${index}`}
															className="flex gap-2"
														>
															<div
																className={twMerge(
																	'flex h-fit w-full cursor-pointer items-center justify-between rounded border px-2 py-1 transition-all hover:bg-neutral-100',

																	selectedIcon.apiReqIcon === field.value &&
																		'bg-neutral-0 text-white hover:bg-neutral-800'
																)}
																onClick={() => {
																	field.onChange(icon.apiReqIcon);
																}}
															>
																<VehicleIcon
																	size={40}
																	name={icon.svgReference}
																/>
															</div>

															{selectedIcon?.subIcon && (
																<div
																	className={twMerge(
																		'flex h-fit w-full cursor-pointer items-center justify-between rounded border px-2 py-1 transition-all hover:bg-neutral-100',
																		selectedIcon.subIcon.apiReqIcon ===
																			field.value &&
																			' bg-neutral-0 text-white hover:bg-neutral-800'
																	)}
																	onClick={() => {
																		field.onChange(icon.subIcon.apiReqIcon);
																	}}
																>
																	<VehicleIcon
																		size={40}
																		name={icon.subIcon?.svgReference}
																	/>
																</div>
															)}
														</div>
													))}
											</div>
										</div>
									)}
								/>

								<Input label="Tooltip:" {...form.register('typeOneTooltip')} />
							</div>
							<div className="flex w-[50%] flex-col gap-4">
								<Checkbox
									label="Ativa?"
									controller={{
										name: 'typeTwoActive',
										control: form.control,
									}}
								/>

								<Input
									{...form.register('typeTwoDescription')}
									label="Descrição:"
									errorMessage={
										form.formState.errors.typeTwoDescription?.message
									}
								/>
								<Controller
									control={form.control}
									name="typeTwoIcon"
									render={({ field }) => (
										<div className="flex flex-col gap-1">
											<p className="text-sm font-bold text-neutral-700">
												Código do ícone:{' '}
												<span className="text-terracota-500">
													{form.formState.errors.typeTwoIcon?.message}
												</span>
											</p>
											<div className="flex gap-2">
												{icons
													.filter((value) => value.id === selectedIcon?.id)
													.map((icon, index) => (
														<div
															key={`${index}-${icon.id}`}
															className="flex gap-2"
														>
															<div
																className={twMerge(
																	'flex h-fit w-full cursor-pointer items-center justify-between rounded border px-2 py-1 transition-all hover:bg-neutral-100',

																	selectedIcon.apiReqIcon === field.value &&
																		'bg-neutral-0 text-white hover:bg-neutral-800'
																)}
																onClick={() => {
																	field.onChange(icon.apiReqIcon);
																}}
															>
																<VehicleIcon
																	size={40}
																	name={icon.svgReference}
																/>
															</div>

															{selectedIcon?.subIcon && (
																<div
																	className={twMerge(
																		'flex h-fit w-full cursor-pointer items-center justify-between rounded border px-2 py-1 transition-all hover:bg-neutral-100',
																		selectedIcon.subIcon.apiReqIcon ===
																			field.value &&
																			' bg-neutral-0 text-white hover:bg-neutral-800'
																	)}
																	onClick={() => {
																		field.onChange(icon.subIcon.apiReqIcon);
																	}}
																>
																	<VehicleIcon
																		size={40}
																		name={icon.subIcon?.svgReference}
																	/>
																</div>
															)}
														</div>
													))}
											</div>
										</div>
									)}
								/>
								<Input label="Tooltip:" {...form.register('typeTwoTooltip')} />
							</div>
						</div>
					</>
				)}

				<hr className="mt-4 text-neutral-200" />
				<PageHeader title="Cálculo de Fretes (mvp)" />
				<div className="flex w-full flex-col gap-4">
					<div className="flex w-full gap-4">
						{fields.map((field, index) => (
							<div
								key={index}
								className="flex w-full flex-col gap-4 md:w-[50%]"
							>
								<h3 className="mb-1 font-semibold">
									{field.orderTypeId ===
									Number(appState.orderTypes['Rota fixa'].value)
										? 'Rota fixa'
										: 'Rota livre'}
								</h3>
								<Input
									className="w-full "
									{...form.register(`costs.${index}.costPerKm`)}
									errorMessage={
										form.formState.errors.costs?.[index]?.costPerKm?.message
									}
									label="Valor por km:"
									leftInputIcon={
										<span className="font-neutral-500 text-xs">R$</span>
									}
									type="number"
									step="any"
								/>
								<Input
									{...form.register(`costs.${index}.startFare`)}
									label="Bandeirada:"
									leftInputIcon={
										<span className="font-neutral-500 text-xs">R$</span>
									}
									type="number"
									step="any"
								/>
								<Input
									{...form.register(`costs.${index}.farePerRoute`)}
									label="Protocolo/NF:"
									leftInputIcon={
										<span className="font-neutral-500 text-xs">R$</span>
									}
									type="number"
									step="any"
								/>
								<Input
									{...form.register(`costs.${index}.minValue`)}
									label="Valor mínimo:"
									leftInputIcon={
										<span className="font-neutral-500 text-xs">R$</span>
									}
									type="number"
									step="any"
								/>
							</div>
						))}
					</div>
					<h3 className="font-semibold ">Geral</h3>
					<div className="flex flex-col gap-2 md:w-full md:flex-row">
						<div className="flex  w-full flex-col gap-4 md:w-[50%]">
							<Input
								label="Comissão Beebee:"
								leftInputIcon={
									<span className="font-neutral-500 text-xs">%</span>
								}
								type="number"
								step="any"
								{...form.register('comission', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								errorMessage={form.formState.errors.comission?.message}
							/>
							<Input
								{...form.register('scheduledComission', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								label="Comissão Beebee em fretes agendados:"
								leftInputIcon={
									<span className="font-neutral-500 text-xs">%</span>
								}
								type="number"
								step="any"
								errorMessage={form.formState.errors.scheduledComission?.message}
							/>
							<Input
								{...form.register('scheduledDiscount', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								label="Desconto em fretes agendados:"
								leftInputIcon={
									<span className="font-neutral-500 text-xs">%</span>
								}
								type="number"
								step="any"
								errorMessage={form.formState.errors.scheduledDiscount?.message}
							/>
							<Input
								{...form.register('scheduleAdvance', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								label="Antecedência mínima (em minutos):"
								leftInputIcon={
									<span className="font-neutral-500 text-xs">🕑</span>
								}
								type="number"
								step="any"
								errorMessage={form.formState.errors.scheduleAdvance?.message}
							/>
							<Input
								{...form.register('stoppedTimeTolerance', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								label="Tolerância de tempo parado (em minutos):"
								leftInputIcon={
									<span className="font-neutral-500 text-xs">🕑</span>
								}
								type="number"
								step="any"
								errorMessage={
									form.formState.errors.stoppedTimeTolerance?.message
								}
							/>
							<Input
								{...form.register('returnTolerance', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								label="Tolerância do adicional de retorno (em km):"
								leftInputIcon={
									<span className="font-neutral-500 text-xs">〰</span>
								}
								type="number"
								step="any"
								errorMessage={form.formState.errors.returnTolerance?.message}
							/>
						</div>
						<div className="flex w-full  flex-col gap-4 md:w-[50%]">
							<Input
								{...form.register('returnPercentage', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								label="Porcentagem do valor do adicional de retorno:"
								leftInputIcon={
									<span className="font-neutral-500 text-xs">%</span>
								}
								type="number"
								step="any"
								errorMessage={form.formState.errors.returnPercentage?.message}
							/>
							<Input
								{...form.register('costPerStoppedTime', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								label="Custo por minuto parado:"
								leftInputIcon={
									<span className="font-neutral-500 text-xs">R$</span>
								}
								type="number"
								step="any"
								errorMessage={form.formState.errors.costPerStoppedTime?.message}
							/>
							<Input
								{...form.register('driverBonus', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								label="Dinâmico (Motorista):"
								leftInputIcon={<span className="text-sm text-neutral-500">R$</span>}
								type="number"
								step="any"
								errorMessage={form.formState.errors.driverBonus?.message}
							/>
							<Input
								{...form.register('requesterMultiplier', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								label="Multiplicador para clientes:"
								leftInputIcon={
									<span className="font-neutral-500 text-xs">%</span>
								}
								type="number"
								step="any"
								errorMessage={
									form.formState.errors.requesterMultiplier?.message
								}
							/>
							<Input
								{...form.register('longDistanceTolerance', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								label="Tolerância para longas distâncias (em km):"
								leftInputIcon={
									<span className="font-neutral-500 text-xs">〰</span>
								}
								type="number"
								step="any"
								errorMessage={
									form.formState.errors.longDistanceTolerance?.message
								}
							/>
							<Input
								{...form.register('longDistancePercentage', {
									onChange: (e) => {
										e.target.value = maxLength(e.target.value);
									},
								})}
								label="Porcentagem de desconto no KM longa distância:"
								leftInputIcon={
									<span className="font-neutral-500 text-xs">%</span>
								}
								type="number"
								step="any"
								errorMessage={
									form.formState.errors.longDistancePercentage?.message
								}
							/>
						</div>
					</div>
					<Checkbox
						label="Isentar comissão no frete mínimo?"
						controller={{
							control: form.control,
							name: 'noComissionOnMinValue',
						}}
					/>
					<Checkbox
						label="Match bikers?"
						controller={{
							control: form.control,
							name: 'matchBikers',
						}}
					/>
					<Checkbox
						label="Permite fretes agendados?"
						controller={{
							control: form.control,
							name: 'allowScheduled',
						}}
					/>
					<Checkbox
						label="Permite fretes imediatos?"
						controller={{
							control: form.control,
							name: 'allowImmediate',
						}}
					/>
				</div>
			</WhiteContainer>
			<FormButtons className="mt-4" onCancel={() => navigate(-1)} />
		</Form>
	);
};
