/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-undef */
import { useState, useEffect, useMemo, useRef } from 'react';
import { MarkerF } from '@react-google-maps/api';
import Button from '../../../../../../components/Button/Button';
import { TiPlus } from 'react-icons/ti';
import {
	DndContext,
	closestCenter,
	useSensor,
	useSensors,
	DragEndEvent,
	PointerSensor,
	DragOverlay,
	UniqueIdentifier,
} from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableItem } from './SortableItem';
import { useStore } from 'zustand';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { appStateStore } from '../../../../../../store/appState';
import { api } from '../../../../../../services/angular/axios';
import { AiFillStar } from 'react-icons/ai';
import {
	RequestFreightForm,
	locationIndexAtom,
	locationModalAtom,
	optimizeRouteAtom,
	OrderMutation,
	updateRouteAtom,
} from '../..';
import { LocationModal } from './LocationModal';
import { FaMagic } from 'react-icons/fa';
import useUserState from '../../../../../../services/angular/angularUserState';
import { Tooltip } from '../../../../../../components/Tooltip';
import { BiTrash, BiTable } from 'react-icons/bi';
import { useAlert } from '../../../../../../contexts/AlertContext';
import { ImportRoute } from './ImportRoute';
import { useAtom } from 'jotai';
import { GoogleMap } from '../../../../../../components/GoogleMap';
import { OrderResponseApi } from '../../../../../../services/angular/types/Order';
import { LeafletMap } from '../../../../../../components/Leaflet';
import { Marker } from 'react-leaflet';
import { customMarkerIcon } from '../../../../../../components/Leaflet/icons/customIcon';
import { useNewMaps } from '../../../../../../hooks/useMaps';

export interface Path {
	lat: number;
	lng: number;
}

interface PathStepProps {
	form: RequestFreightForm;
	draftOrder?: OrderResponseApi;
	orderMutation: OrderMutation;
}

export const PathStep = ({
	form,
	draftOrder,
	orderMutation,
}: PathStepProps) => {
	const queryClient = useQueryClient();
	const alert = useAlert();
	const [updateDraft, triggerUpdateDraft] = useAtom(updateRouteAtom);
	const [, setOptimizeRoute] = useAtom(optimizeRouteAtom);
	const [isOpen, setIsOpen] = useAtom(locationModalAtom);
	const [, setLocationIndex] = useAtom(locationIndexAtom);

	const company = useUserState((state) => state.company);
	const sensors = useSensors(useSensor(PointerSensor));
	const { config } = useStore(appStateStore);
	const [activeId, setActiveId] = useState<UniqueIdentifier>();
	const [editForm, setEditForm] = useState(false);
	const { toggleMaps } = useNewMaps();

	const locationsIdsOrder = useMemo(
		() =>
			form
				.watch('locations')
				?.map((location) => location?.lat)
				.join(', '),
		[form.watch('locations')]
	);

	useEffect(() => {
		if (draftOrder) return;
		if (
			form.watch('orderTypeId') === 2 &&
			form.watch('locations')?.length === 1
		) {
			void form.handleSubmit((data) => {
				// @ts-expect-error - handleSubmit getting the wrong zod
				orderMutation.mutate(data);
			})();
		}

		if (form.watch('locations')?.length === 2) {
			void form.handleSubmit((data) => {
				// @ts-expect-error - handleSubmit getting the wrong zod
				orderMutation.mutate(data);
			})();
		}
	}, [locationsIdsOrder]);

	const { data: favoriteLocation, refetch: refetchFavoriteLocation } = useQuery(
		['favorite-locations', company?.id],
		async () => await api.get('/favorite-locations')
	);

	const handleDragEnd = (event: DragEndEvent) => {
		setActiveId(undefined);
		const { active, over } = event;

		if (over?.id && active.id !== over.id) {
			const currentLocations = form.getValues('locations');

			if (!currentLocations) return;

			const oldIndex = currentLocations.findIndex(
				(item) => item.id === active.id
			);
			const newIndex = currentLocations.findIndex(
				(item) => item.id === over.id
			);

			const newArray = arrayMove(currentLocations, oldIndex, newIndex).map(
				(item, index) => ({
					...item,
					sequence: index,
				})
			);

			form.setValue('locations', newArray);
			triggerUpdateDraft(!updateDraft);
		}
	};

	const showButton = () => {
		const orderTypeId = form.getValues('orderTypeId');

		if (orderTypeId === 1) return true;

		if (orderTypeId === 2) {
			const currentLocations = form.getValues('locations');

			if (!currentLocations?.length) return true;

			return false;
		}

		return true;
	};

	const showFavoriteButton = () => {
		if (!favoriteLocation?.data.length) return false;

		const currentLocations = form.getValues('locations');

		if (!currentLocations?.length) return true;

		return false;
	};

	const previousBounds = useRef<google.maps.LatLngBounds>();

	const bounds = useMemo(() => {
		const formLocations = form.getValues('locations');

		if (formLocations?.length === 1)
			return new google.maps.LatLngBounds(
				new google.maps.LatLng(formLocations[0].lat!, formLocations[0].lng)
			);

		if (!draftOrder?.locations?.length) return previousBounds.current;

		const bounds = new google.maps.LatLngBounds();
		draftOrder?.locations?.forEach((point) => {
			bounds.extend(new google.maps.LatLng(point.lat, point.lng));
		});
		previousBounds.current = bounds;
		return bounds;
	}, [draftOrder?.locations]);


	return (
		<div className="relative">
			<div className="mb-0 h-[calc(100vh-112px)] max-h-[1280px] md:mb-4">
			{
			!toggleMaps?.useLeaflet ? (
					<GoogleMap
						center={bounds}
						legs={draftOrder?.routes[0]?.directions?.legs}
						options={{
							mapTypeControl: false,
							streetViewControl: false,
							fullscreenControl: false,
							clickableIcons: false,
							gestureHandling: window.innerWidth < 768 ? 'cooperative' : 'greedy',
						}}
					>
						{form.getValues('locations')?.map((location, index) => (
							<span key={index}>
								{location.lat && location.lng && (
									<MarkerF
										key={index}
										label={{
											text: `${location.sequence! + 1}`,
											fontWeight: 'bold',
											color: 'black',
											className:
												'absolute top-[38px] left-[50%] transform -translate-x-1/2',
										}}
										position={{
											lat: location.lat,
											lng: location.lng,
										}}
										icon={{
											url: '/markerBlue.png',
										}}
									/>
								)}
							</span>
						))}
					</GoogleMap>

					) : (
					<LeafletMap 
						center={draftOrder?.locations ?? null}
						polylineString={draftOrder?.routes[0]?.directions?.overview_polyline.points ?? ""}
						dynamicMap={true}
						layers={toggleMaps.layers}
					>       
						{form.getValues('locations')?.map((location, index) => (
							<span key={index}>
								{location.lat && location.lng && (
									<Marker
										key={index}
										position={[location.lat,location.lng]}
										icon={customMarkerIcon("#00FFFF", `${location.sequence! + 1}`)}
										draggable={false}
									>
									</Marker>
								)}
							</span>
						))}
					</LeafletMap>
				)}
				
			</div>

			<div className="pointer-events-none mb-12 flex w-full flex-col-reverse items-start justify-start gap-2 py-4 md:absolute md:top-0 md:mb-0 md:max-h-[80%] md:flex-row md:p-4">
				<div className="pointer-events-auto flex w-full flex-col gap-2 rounded bg-white p-4 md:w-1/2 lg:max-w-sm">
					{showButton() && (
						<Button
							onClick={() => {
								setIsOpen(true);
								setEditForm(false);
								setLocationIndex(undefined);
							}}
							disabled={
								String(form.getValues('locations')?.length) ===
								config.freights.maxLocationsLength
							}
						>
							<TiPlus size={20} />{' '}
							{form.watch('locations')?.length
								? 'Adicionar destino'
								: 'Adicionar ponto de partida'}
						</Button>
					)}
					{showFavoriteButton() && (
						<Button
							variant="blue-primary"
							onClick={() => {
								form.setValue(
									'locations',
									(form.getValues('locations') ?? []).concat({
										...favoriteLocation?.data[0],
										sequence: form.getValues('locations')?.length || 0,
									})
								);

								triggerUpdateDraft(!updateDraft);
							}}
						>
							<AiFillStar size={20} />
							Usar ponto de partida
						</Button>
					)}
					<div className="grid max-w-full grid-flow-row-dense auto-rows-max gap-4 overflow-y-auto p-px md:max-h-96 md:pr-2">
						<DndContext
							sensors={sensors}
							collisionDetection={closestCenter}
							onDragEnd={handleDragEnd}
							onDragStart={(event) => {
								setActiveId(event.active.id);
							}}
							modifiers={[restrictToVerticalAxis]}
						>
							<SortableContext
								// @ts-expect-error
								items={form.getValues('locations') || []}
								strategy={verticalListSortingStrategy}
								disabled={form.getValues('locations')?.length < 2}
							>
								{form.getValues('locations')?.map((location, index) => (
									<SortableItem
										id={location.id!}
										location={location}
										key={location.id}
										index={index}
										onActionsClick={({ locationId, type }) => {
											if (type === 'delete') {
												alert.onCustom({
													title: 'Remover local',
													message: 'Deseja remover esse local da rota?',
													confirm: {
														label: 'Remover',
														onClick: () => {
															const newLocations = form
																.getValues('locations')
																.filter(
																	(location) => location.id !== locationId
																);
															form.setValue(
																'locations',
																newLocations.map((location, index) => ({
																	...location,
																	sequence: index,
																}))
															);
															triggerUpdateDraft(!updateDraft);
														},
													},
													cancel: {
														label: 'Cancelar',
													},
												});
												return;
											}
											type === 'editInstruction'
												? setEditForm(true)
												: setEditForm(false);
											setIsOpen(true);
											setLocationIndex(index);
										}}
									/>
								))}
							</SortableContext>
							<DragOverlay>
								{activeId ? (
									<SortableItem
										className="shadow-lg shadow-neutral-0"
										id={activeId}
										// @ts-expect-error
										location={form
											.getValues('locations')
											.find((location) => location.id === activeId)}
										index={form
											.getValues('locations')
											?.findIndex((location) => location.id === activeId)}
									/>
								) : null}
							</DragOverlay>
						</DndContext>
					</div>
				</div>
				<div className="flex flex-wrap gap-2">
					{form.watch('locations')?.length >= 4 &&
						form.getValues('statusId') === 1 && (
							<Tooltip
								message="O primeiro e o último ponto não serão alterados, apenas os pontos intermediários"
								triggerClassName="bg-white rounded pointer-events-auto"
							>
								<Button
									className="pointer-events-auto w-auto gap-4 border-none bg-white py-3.5 text-neutral-0 shadow enabled:hover:bg-neutral-100 enabled:active:bg-neutral-100"
									onClick={() => {
										setOptimizeRoute(true);
										triggerUpdateDraft(!updateDraft);
									}}
								>
									Otimizar rota
									<FaMagic size={20} />
								</Button>
							</Tooltip>
						)}
					{form.watch('locations')?.length > 1 && (
						<Tooltip
							message="Limpar a rota"
							triggerClassName="self-start bg-white rounded pointer-events-auto"
						>
							<Button
								className="w-auto gap-4 border-none bg-white py-2.5 text-neutral-0 shadow enabled:hover:bg-neutral-100 enabled:active:bg-neutral-100"
								onClick={() => {
									alert.onCustom({
										title: 'Limpar trajeto',
										message: 'Deseja realmente limpar a rota?',
										confirm: {
											label: 'Limpar',
											onClick: () => {
												form.setValue('locations', []);
												if (draftOrder) {
													queryClient.setQueryData(
														['draft-order', String(draftOrder.id)],
														{
															...draftOrder,
															locations: [],
															routes: [],
														}
													);
												}
											},
										},
										cancel: {
											label: 'Cancelar',
										},
									});
								}}
							>
								<BiTrash size={28} />
							</Button>
						</Tooltip>
					)}
					{form.watch('orderTypeId') !== 2 && (
						<>
							<ImportRoute form={form} orderMutation={orderMutation} />
							{!form.watch('locations')?.length && (
								<Tooltip
									message="Download da planilha modelo"
									triggerClassName="self-start bg-white rounded pointer-events-auto"
								>
									<a
										href="https://beebee-cdn.s3-sa-east-1.amazonaws.com/assets/templates/Beebee_planilha_modelo_rota_pt.xlsx"
										download
										className="flex w-auto items-center justify-center gap-4 rounded border-none bg-white px-3 py-2.5 align-text-bottom text-sm text-neutral-0 shadow hover:bg-neutral-100 active:bg-neutral-100"
									>
										<BiTable size={28} />
									</a>
								</Tooltip>
							)}
						</>
					)}
				</div>
			</div>

			<LocationModal
				orderForm={form}
				count={form.watch('locations')?.length || 0}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				editForm={editForm}
				favoritePositionId={favoriteLocation?.data?.[0]?.id}
				refetchFavoriteLocation={refetchFavoriteLocation}
			/>
		</div>
	);
};
