import React from 'react';
import { Root, Indicator, CheckboxProps } from '@radix-ui/react-checkbox';
import { IoCheckmark } from 'react-icons/io5';
import { Label } from './Label';
import { Controller, UseControllerProps } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';

interface CheckBoxProps<T extends object> extends CheckboxProps {
	controller?: UseControllerProps<T>;
	label: string | React.ReactNode;
	className?: string;
	link?: string;
	labelClassName?: string;
	goTo?: string;
}

export function Checkbox<T extends object>({
	controller,
	label,
	className,
	link,
	labelClassName,
	goTo,
	...props
}: CheckBoxProps<T>): JSX.Element {
	const id = `checkbox-${
		controller?.name || Math.random().toString(36).slice(2, 9)
	}`;
	const navigate = useNavigate();

	return (
		<div className={twMerge('flex w-fit items-center', className)}>
			{controller ? (
				<Controller
					{...controller}
					render={({ field }) => (
						<Root
							id={id}
							className="mr-2 flex h-5 w-5 min-w-[20px] items-center justify-center rounded border border-neutral-200 bg-neutral-1 data-[state=checked]:bg-blue hover:bg-neutral-100 data-[state=checked]:hover:bg-blue/80"
							{...field}
							checked={field.value}
							onCheckedChange={field.onChange}
							{...props}
						>
							<Indicator className="text-neutral-1">
								<IoCheckmark size={20} />
							</Indicator>
						</Root>
					)}
				/>
			) : (
				<Root
					id={id}
					className="mr-2 flex h-5 w-5 min-w-[20px] items-center justify-center rounded border border-neutral-200 bg-neutral-1 data-[state=checked]:bg-blue hover:bg-neutral-100 data-[state=checked]:hover:bg-blue/80"
					{...props}
				>
					<Indicator className="text-neutral-1">
						<IoCheckmark size={20} />
					</Indicator>
				</Root>
			)}

			{link ? (
				<a href={link} target="_blank" rel="noreferrer">
					{label}
				</a>
			) : goTo ? (
				<div onClick={() => navigate(`/${goTo}`)}>{label}</div>
			) : (
				<Label
					label={label}
					variant="primary"
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					className={`mb-0 cursor-pointer ${labelClassName}`}
					htmlFor={id}
				/>
			)}
		</div>
	);
}
