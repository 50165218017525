import { Fragment, useEffect, useState } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Listbox, Transition } from '@headlessui/react';
import { IoCheckmark } from 'react-icons/io5';
import { HiOutlineChevronUpDown } from 'react-icons/hi2';
import { twMerge } from 'tailwind-merge';
// @ts-expect-error
import { ReactComponent as Hex } from '../../../assets/yellowHex.svg';
import { Geofence, useAppState } from '../../../store/appState';
import useUserState from '../../../services/angular/angularUserState';
import { Checkbox } from '../../FormElements';

export const SelectGeofence = () => {
	const geofence = useAppState((state) => state.geofence);

	const setGeofences = useUserState((state) => state.setGeofences);
	const geofences = useUserState((state) => state.geofences);

	const [geofenceState, setGeofenceState] = useState<Geofence[]>(
		geofence.filter((geofence) => {
			return geofences.find((geofenceUser) => geofenceUser.id === geofence.id);
		})
	);

	useEffect(() => {
		if (geofences.length === 0 && geofence?.[0]) {
			setGeofences([geofence[0]]);
			setGeofenceState([geofence[0]]);
		}
	}, []);

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger
				className={twMerge(
					'absolute bottom-20 right-5 h-12 w-12 scale-75 md:h-16 md:w-20 md:scale-100',
					'flex flex-col items-center justify-center text-neutral-0 outline-none',
					'cursor-pointer transition-all duration-300 ease-in-out md:hover:scale-105'
				)}
			>
				<span className="absolute -z-10">
					<Hex />
				</span>
				<span className="text-xs font-bold">Geofence</span>
				<span className="mb-1 overflow-hidden text-2xl font-black">
					{geofences.length > 1
						? `*${geofences.length}`
						: geofences[0]?.name.slice(0, 3)}
				</span>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content
					sideOffset={8}
					alignOffset={8}
					className="mr-2 flex w-full max-w-xs flex-col justify-center gap-4 rounded bg-white p-4 text-neutral-0 shadow shadow-neutral-0 md:ml-0 md:w-auto"
				>
					<p className="text-lg font-bold leading-5 text-neutral-0">
						Selecione Geofence
					</p>
					<div className="w-72">
						<Listbox
							value={geofenceState}
							onChange={(array) => {
								// if there is a object duplicate, remove both
								const filteredArray = array.filter((item, index) => {
									return array.indexOf(item) === index;
								});

								setGeofenceState(filteredArray);
								setGeofences(filteredArray);
							}}
							multiple
						>
							<div className="relative">
								<Listbox.Button className="border-gray-300 focus:shadow-outline-blue focus:border-blue-300 relative w-full cursor-default rounded border bg-white py-2 pl-3 pr-10 text-left transition duration-150 ease-in-out focus:outline-none sm:text-sm sm:leading-5">
									<span className="block truncate">
										{geofenceState.length < 1
											? 'Selecione um geofence'
											: geofenceState.length === 1
											? geofenceState[0].name
											: `Geofence selecionados (${geofenceState.length})`}
									</span>
									<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
										<HiOutlineChevronUpDown
											className="text-neutral-500"
											size={20}
										/>
									</span>
								</Listbox.Button>
								<Transition
									as={Fragment}
									leave="transition ease-in duration-100"
									leaveFrom="opacity-100"
									leaveTo="opacity-0"
								>
									<Listbox.Options className="absolute bottom-10 mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
										{geofence.map((geofence) => (
											<Listbox.Option
												className={({ active, selected }) =>
													twMerge(
														'relative cursor-default select-none py-2 pl-10 pr-4 font-medium',
														selected && 'bg-secondary-50',
														active
															? 'bg-secondary-100 text-neutral-900'
															: 'text-neutral-900'
													)
												}
												key={geofence.id}
												value={geofence}
											>
												{({ selected }) => (
													<>
														<span
															className={twMerge(
																'block truncate',
																selected ? 'font-semibold' : 'font-medium'
															)}
														>
															{geofence.name}
														</span>
														{selected ? (
															<span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue">
																<IoCheckmark size={20} />
															</span>
														) : null}
													</>
												)}
											</Listbox.Option>
										))}
									</Listbox.Options>
								</Transition>
							</div>
						</Listbox>
					</div>
					<Checkbox
						label={
							geofenceState.length === geofence.length
								? 'Desmarcar todos'
								: 'Selecionar todos'
						}
						checked={geofenceState.length === geofence.length}
						onCheckedChange={(e) => {
							const value = e.valueOf();

							if (value === true) {
								setGeofenceState(geofence);
								setGeofences(geofence);
							} else {
								setGeofenceState([]);
								setGeofences([]);
							}
						}}
					/>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};
