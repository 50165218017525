import { z } from 'zod';
import { ErrorsMessageHelper } from '../../../../helpers/errors/ErrorsMessageHelper';

export const VerificationCodeSchema = z.object({
	cpf: z
		.string()
		.min(1, { message: ErrorsMessageHelper.required })
		.min(14, { message: ErrorsMessageHelper.min(14) })
		.max(14, { message: ErrorsMessageHelper.max(14) })
		.optional(),
	code: z
		.string()
		.min(1, { message: ErrorsMessageHelper.required })
		.min(4, { message: ErrorsMessageHelper.min(4) })
		.max(4, { message: ErrorsMessageHelper.max(4) }),
	password: z
		.string()
		.min(1, { message: ErrorsMessageHelper.required })
		.min(6, { message: ErrorsMessageHelper.min(6) })
		.max(20, { message: ErrorsMessageHelper.max(20) }),
	passwordConfirmation: z
		.string()
		.min(1, { message: ErrorsMessageHelper.required })
		.min(6, { message: ErrorsMessageHelper.min(6) })
		.max(20, { message: ErrorsMessageHelper.max(20) }),
});

export type VerificationCodeSchemaType = z.infer<typeof VerificationCodeSchema>;
