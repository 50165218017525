import React, { useRef, ChangeEvent, useState } from 'react';
import { IoMdPhotos } from 'react-icons/io';
import { twMerge } from 'tailwind-merge';
import Bee from '../../assets/svg/Bee';
import { api } from '../../services/angular/axios';
import { toast } from 'react-toastify';
import { validateFileSize } from '../../helpers/validateFileSize';

interface ImageInputProps {
	token: string;
	setImageName: (name: string, file?: File) => void;
	file?: File;
	src?: string;
	capture?: boolean | 'user' | 'environment' | undefined;
}

const ImageInput = ({
	token,
	setImageName,
	src,
	file,
	capture = false,
}: ImageInputProps) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [loading, setLoading] = useState(false);
	const [image, setImage] = useState<File | undefined>(file);

	const handleCaptureClick = () => {
		if (inputRef.current) {
			inputRef.current.click();
		}
	};

	const postImage = async (file: File) => {
		try {
			const formData = new FormData();
			formData.append('image', file);

			if (!validateFileSize(file)) {
				return;
			}

			const response = await api.post('/assets', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
					Authorization: `Bearer ${token}`,
				},
			});

			setImage(file);
			setImageName(response.data.imageName, file);
		} catch (error: any) {
			toast.error(
				'Não foi possível enviar a imagem, tente novamente mais tarde.'
			);
		} finally {
			setLoading(false);
		}
	};

	const handleImageChange = async (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];

		if (file) await postImage(file);
	};

	return (
		<div
			className={twMerge(
				'relative max-h-40 max-w-[160px] self-center  rounded border-[8px] border-double border-white bg-neutral-100',
				(image || src) && 'border-none'
			)}
		>
			<input
				type="file"
				accept="image/png, image/gif, image/jpeg"
				ref={inputRef}
				className="hidden"
				capture={capture}
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				onChange={handleImageChange}
			/>

			{image ? (
				<img
					className="h-40 max-h-40 w-40 max-w-[160px] self-center rounded-md border-[8px] border-double border-white bg-neutral-100 object-cover object-center"
					src={URL.createObjectURL(image) || src}
					alt=""
				/>
			) : src ? (
				<>
					{src.includes('.pdf') ? (
						<embed
							className="h-40 max-h-40 w-40 max-w-[160px] self-center rounded-md border-[8px] border-double border-white bg-neutral-100 object-cover object-center"
							src={`${src}#toolbar=0&navpanes=0&scrollbar=0`}
							type="application/pdf"
							style={{ borderRadius: '6px' }}
						/>
					) : (
						<img
							className="h-40 max-h-40 w-40 max-w-[160px] self-center rounded-md border-[8px] border-double border-white bg-neutral-100 object-cover object-center"
							src={src}
							alt=""
						/>
					)}
				</>
			) : (
				<div className="p-4 text-white">
					<Bee />
				</div>
			)}
			<button
				type="button"
				className="absolute -right-2 bottom-2 z-10 rounded border bg-white/80 p-0.5 text-neutral-0"
				onClick={handleCaptureClick}
			>
				<IoMdPhotos size={20} />
			</button>

			{loading && (
				<div className="absolute inset-0 flex items-center justify-center bg-neutral-100 bg-opacity-80">
					<div className="h-32 w-32 animate-spin rounded-full border-b-2 border-neutral-0"></div>
				</div>
			)}
		</div>
	);
};

export default ImageInput;
