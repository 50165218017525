import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { toast } from 'react-toastify';
import Button from '../../../../../components/Button/Button';
import { Icon } from '../../../../../components/Icon/Icon';
import { api } from '../../../../../services/angular/axios';
import { IoReload } from 'react-icons/io5';
import { Tooltip } from '../../../../../components/Tooltip';
import useUserState from '../../../../../services/angular/angularUserState';
import { twMerge } from 'tailwind-merge';

interface PixPaymentProps {
	value: number;
}

const PixPayment: React.FC<PixPaymentProps> = ({ value }) => {
	const company = useUserState((state) => state.company);
	const [regenerateTime, setRegenerateTime] = useState<boolean>(true);
	const [timer, setTimer] = useState<number>(120);
	const [copiedMessage, setCopiedMessage] = useState<boolean>(false);
	const [regenerateButton, setRegenerateButton] = useState<boolean>(false);
	const [pixError, setPixError] = useState<boolean>(false);

	const [qrCode, setQrCode] = useState<string>('');

	const getQrcode = async () => {
		if (!company) return;
		try {
			const response = await api.post(`company/${company?.id}/payment/pix`, {
				value,
			});
			setQrCode(response.data.qrCode);
			setRegenerateTime(true);
			setTimer(120);
			setRegenerateButton(false);
			setCopiedMessage(false);
		} catch (error) {
			toast.error(
				'A rede do sistema de pagamentos não está respondendo. Aguarde um momento e tente novamente.'
			);
			setPixError(true);
		}
	};

	useEffect(() => {
		setQrCode('');
	}, [value]);

	useEffect(() => {
		if (regenerateTime) {
			const interval = setInterval(() => {
				setTimer(timer - 1);
			}, 1000);
			if (timer === 0) {
				setRegenerateTime(false);
				setTimer(120);
				setRegenerateButton(true);
			}
			return () => clearInterval(interval);
		}
	}, [regenerateTime, timer]);

	return (
		<div className="align-center flex w-full flex-col items-center justify-center">
			<h1 className="mb-2 text-xl font-bold">Pagar com PIX</h1>
			<h3 className="mb-2">
				Valor:{' '}
				<span className="text-xl font-bold text-green ">
					R${value.toFixed(2).replace('.', ',')}
				</span>
			</h3>

			{qrCode ? (
				<>
					<span className="mb-6 text-lg text-neutral-400">
						Use o APP do seu banco para ler o QR Code abaixo:
					</span>
					<QRCode value={qrCode} />
					<span className="mb-4 mt-8 text-lg text-neutral-400">
						Ou use a opção <b className="text-neutral-0">Copia e Cola</b>:
					</span>
					<div className="flex rounded-md bg-neutral-50 p-4 text-xs text-neutral-400">
						<span className="break-all">{qrCode}</span>
						<div
							className="ml-4 cursor-pointer"
							onClick={() => {
								void navigator.clipboard.writeText(qrCode);
							}}
						>
							<Tooltip message="Copiar código">
								<div className="flex items-center">
									<Icon
										name="copy"
										size={20}
										color={copiedMessage ? 'yellow' : 'neutral-400'}
										className="hover:text-yellow-500"
										onClick={() => setCopiedMessage(true)}
									/>
									{copiedMessage && (
										<span className="ml-2 text-xs">Copiado!</span>
									)}
								</div>
							</Tooltip>
						</div>
					</div>
					<span className="mt-4 flex flex-col items-center gap-2">
						<h1
							className={twMerge(
								'text-lg font-bold text-terracota-500',
								regenerateButton && 'hidden'
							)}
						>
							O código irá expirar em: {Math.floor(timer / 60)}:
							{timer < 10 && '0'}
							{timer % 60}
							{timer === 60 || (timer === 120 && '0')}
						</h1>
						<Button
							disabled={!regenerateButton}
							className="w-auto"
							variant="blue-primary"
							onClick={() => {
								void getQrcode();
							}}
						>
							Gerar novamente <IoReload />
						</Button>
					</span>
				</>
			) : (
				<Button
					variant="blue-primary"
					onClick={() => {
						void getQrcode();
					}}
				>
					Finalizar pagamento
				</Button>
			)}
			{pixError && (
				<span className="mt-4 flex text-center text-terracota-500">
					A rede do sistema de pagamentos não está respondendo. Aguarde um
					momento e tente novamente.
				</span>
			)}
		</div>
	);
};

export default PixPayment;
