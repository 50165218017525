import React from 'react';
import { twMerge } from 'tailwind-merge';
import Button from '../Button/Button';
import { Icon } from '../Icon/Icon';

export interface WhiteContainerMenuInterface {
	name: string;
	selected: boolean;
}

export interface ActionButtonInterface {
	label: string;
	onClick: () => void;
}

interface WhiteContainerProps extends React.HTMLAttributes<HTMLDivElement> {
	children?: React.ReactNode | false;
	menu?: WhiteContainerMenuInterface[];
	topRightButton?: ActionButtonInterface;
	onClickMenu?: (index: number) => void;
	onClickTopRightButton?: () => void;
	divProps?: React.HTMLAttributes<HTMLDivElement>;
}

const WhiteContainer: React.FC<WhiteContainerProps> = ({
	children,
	menu,
	topRightButton,
	className,
	onClickMenu,
	divProps,
	...props
}) => {
	return (
		<div {...divProps}>
			{(topRightButton || menu) && (
				<div className="flex w-auto justify-between">
					<div className="flex flex-wrap-reverse items-end gap-px">
						{menu?.map((menuItem, index) => (
							<div
								key={`menu-${menuItem.name}-${index}`}
								className={`mr-2 cursor-pointer rounded-t-lg px-3 py-1 text-left font-medium text-neutral-900 ${
									menuItem.selected
										? 'cursor-default bg-neutral-1'
										: 'bg-neutral-100 hover:bg-neutral-200 active:bg-neutral-1'
								}`}
								onClick={() => {
									if (!menuItem.selected) onClickMenu?.(index);
								}}
							>
								<span>{menuItem.name}</span>
							</div>
						))}
					</div>
					{topRightButton && (
						<Button
							className="mb-2 w-auto"
							variant="blue-primary"
							onClick={topRightButton.onClick}
						>
							<Icon name="grid" size={12} />
							{topRightButton.label}
						</Button>
					)}
				</div>
			)}
			<div
				className={twMerge(
					`relative flex flex-col rounded bg-neutral-1 px-4 py-4 text-neutral-900 md:px-8 ${
						menu ? 'rounded-tl-none' : ''
					}`,
					className
				)}
				{...props}
			>
				{children}
			</div>
		</div>
	);
};

export default WhiteContainer;
