import { ReactNode } from 'react';
import { Tag } from '../../../services/angular/types/Order';
import { Tooltip } from '../../Tooltip';
import { FaTags } from 'react-icons/fa';

interface AdminTagsProps {
	tags: Tag[] | undefined;
	children?: ReactNode;
	triggerClassName?: string;
}

export const AdminTags = ({
	tags,
	children,
	triggerClassName,
}: AdminTagsProps) => {
	return (
		<>
			{tags?.length ? (
				<Tooltip
					className="flex max-w-fit  justify-start bg-white ring-1 ring-neutral-500"
					triggerClassName={`relative ${triggerClassName || ''}`}
					message={
						<div className="my-1 flex w-full max-w-[192px] flex-wrap gap-2 px-[2px] py-1 text-[10px]  hover:no-underline">
							{tags.map((tag) => (
								<h1
									key={tag.id}
									className="rounded-lg bg-neutral-100  px-2 text-neutral-700 ring-1 ring-neutral-500 hover:no-underline"
								>
									{tag.name}
								</h1>
							))}
						</div>
					}
				>
					{children}
					<FaTags
						className="absolute bottom-[2px] left-11 text-neutral-300"
						size={12}
					/>
				</Tooltip>
			) : (
				children
			)}
		</>
	);
};
