import { PageHeader } from '../../../../../components/PageHeader';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../../services/angular/axios';
import { useRef, useState } from 'react';
import { SortOptions } from '../../../../../services/angular/types/Params';
import {
	AuditFilterSchema,
	AuditFilterSchemaType,
} from './schemas/AuditFilterSchema';
import {
	Date,
	Select,
	useZodForm,
} from '../../../../../components/FormElements';
import { FiltersTab } from '../../../../../components/FiltersTab';
import { useAppState } from '../../../../../store/appState';
import { fieldOptions } from './FieldsOptions';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { Table } from '../../../../../components/Table/Table';
import { AuditModel } from './AuditModel';
import {
	AiFillCheckCircle,
	AiOutlineArrowDown,
	AiOutlineArrowUp,
} from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import dayjs from 'dayjs';

const Audit = () => {
	const [query, setQuery] = useState<AuditFilterSchemaType>();
	const [inputValue, setInputValue] = useState('');

	const geofence = useAppState((state) => state.geofence);

	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});

	const form = useZodForm({
		schema: AuditFilterSchema,
	});
	const { watch, control } = form;

	const { data, isLoading, isFetching } = useQuery(
		['audit', query, params],
		async () =>
			await api.get('/categories/changes', {
				params: {
					...query,
					page: params.page,
					pageSize: params.take,
				},
			}),
		{
			refetchOnMount: 'always',
		}
	);

	const { data: vehicleCategories, refetch: vehicleRefetch } = useQuery(
		['vehicle-category', form.watch('geofenceId')],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/categories`,
				{
					params: {
						geofenceId: watch('geofenceId')
							?.map((state) => state.value)
							.join(','),
					},
				}
			)
	);

	const { data: searchedUsers, refetch: usersRefetch } = useQuery(
		['searched-users', inputValue],
		async () =>
			await api.get('/users/admins?', {
				params: {
					name: inputValue,
				},
			})
	);

	const timerRef = useRef<any>(null);

	const handleSearch = (value: string) => {
		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			setInputValue(value);
			void usersRefetch();
		}, 1000);
	};

	return (
		<>
			<PageHeader
				title="Auditoria de valores"
				description="Auditoria das variáveis da calculadora de fretes"
			/>
			<FiltersTab
				form={form}
				onSubmit={(values) => {
					setQuery(values);
				}}
				clearFilters={() => {
					form.reset({
						adminId: null,
						initialDate: null,
						endDate: null,
						geofenceId: [],
						categoryId: [],
						changedField: null,
						orderTypeId: null,
					});
					setQuery(undefined);
				}}
			>
				<Select
					className="w-full md:w-1/4"
					variant="light"
					label="Usuário"
					controller={{
						control,
						name: 'adminId',
					}}
					options={searchedUsers?.data.map(
						(user: { name: string; id: number }) => ({
							label: user.name,
							value: user.id,
						})
					)}
					onInputChange={(e) => {
						handleSearch(e);
					}}
				/>

				<Date
					variant="light"
					label="De"
					control={control}
					name="initialDate"
					className="w-full md:w-1/5"
				/>
				<Date
					variant="light"
					label="Até"
					control={control}
					name="endDate"
					className="w-full md:w-1/5"
				/>
				<Select
					variant="light"
					label="Geofence"
					className="w-auto min-w-[192px]"
					controller={{
						control,
						name: 'geofenceId',
					}}
					isMulti
					options={geofence?.map((state) => ({
						label: state.name,
						value: state.id,
					}))}
					onChange={() => {
						void vehicleRefetch();
					}}
				/>
				<Select
					variant="light"
					label="Categoria de veículo"
					isMulti
					className="w-auto min-w-[192px]"
					controller={{
						control,
						name: 'categoryId',
					}}
					options={vehicleCategories?.data.map(
						(category: { name: string; id: number }) => ({
							label: category.name,
							value: category.id,
						})
					)}
				/>

				<Select
					variant="light"
					label="Campo"
					className="w-full md:w-1/4"
					controller={{
						control,
						name: 'changedField',
					}}
					options={fieldOptions.map((field) => ({
						label: field.label,
						value: field.value,
					}))}
				/>
				<Select
					variant="light"
					label="Rota"
					className="w-full md:w-[15%]"
					controller={{
						control,
						name: 'orderTypeId',
					}}
					options={[
						{ label: 'Rota fixa', value: 1 },
						{ label: 'Rota livre', value: 2 },
						{ label: 'geral', value: 3 },
						{ label: 'Todos', value: undefined },
					]}
				/>
			</FiltersTab>
			<WhiteContainer>
				<Table<AuditModel>
					data={data?.data.data || []}
					total={data?.data.meta.pagination?.total}
					triggerPageChange={form.formState.isSubmitting}
					isLoading={isLoading || isFetching}
					onParamsChange={(params) => {
						setParams(params);
					}}
					disabledSort={{
						categoryId: true,
						userId: true,
						geofenceId: true,
						orderTypeId: true,
						changedField: true,
						updated_at: true,
						oldValue: true,
						newValue: true,
					}}
					columns={[
						{
							Header: 'Categoria',
							accessor: 'categoryId',
						},
						{
							Header: 'Usuário',
							accessor: 'userId',
						},
						{
							Header: 'Geofence',
							accessor: 'geofenceId',
						},
						{
							Header: 'Tipo de rota',
							accessor: 'orderTypeId',
						},
						{
							Header: 'Campo editado',
							accessor: 'changedField',
						},
						{
							Header: 'Editado em',
							accessor: 'updated_at',
							Cell: ({ value }) => {
								return <>{dayjs(value).format('DD/MM/YYYY HH:mm')}</>;
							},
						},
						{
							Header: 'Valor antigo',
							accessor: 'oldValue',
							Cell: ({ row }) => {
								const { mask, oldValue } = row.original;

								return mask === 'checkbox' ? (
									<>
										{oldValue === '1' ? (
											<AiFillCheckCircle className="text-green" size={20} />
										) : (
											<MdCancel className="text-terracota-500" size={20} />
										)}
									</>
								) : mask === 'money' ? (
									<>
										{Number(oldValue).toLocaleString('pt-br', {
											style: 'currency',
											currency: 'BRL',
										})}
									</>
								) : (
									<>{oldValue}%</>
								);
							},
						},
						{
							Header: 'Valor novo',
							accessor: 'newValue',
							Cell: ({ row }) => {
								const { mask, newValue, oldValue } = row.original;

								return mask === 'checkbox' ? (
									<span className="flex">
										{newValue === '1' ? (
											<AiFillCheckCircle className="text-green" size={20} />
										) : (
											<MdCancel className="text-terracota-500" size={20} />
										)}
									</span>
								) : mask === 'money' ? (
									<span className="flex">
										{newValue > oldValue ? (
											<AiOutlineArrowUp className="text-green" />
										) : (
											<AiOutlineArrowDown className="text-terracota-500" />
										)}
										{Number(newValue).toLocaleString('pt-br', {
											style: 'currency',
											currency: 'BRL',
										})}
									</span>
								) : (
									<span className="flex">
										{newValue > oldValue ? (
											<AiOutlineArrowUp className="text-green" />
										) : (
											<AiOutlineArrowDown className="text-terracota-500" />
										)}
										{newValue}%
									</span>
								);
							},
						},
					]}
				/>
			</WhiteContainer>
		</>
	);
};

export default Audit;
