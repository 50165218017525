import { toast } from 'react-toastify';

const MAX_FILE_SIZE = 20; // 20MB

export const validateFileSize = (
	file: File | Blob,
	maxSizeFileMB = MAX_FILE_SIZE
): boolean => {
	const maxSizeFile = maxSizeFileMB * 1024 * 1024;

	if (file.size > maxSizeFile) {
		toast.error(
			`Arquivo muito grande, o tamanho máximo é de ${maxSizeFileMB}MB`
		);
		return false;
	}

	return true;
};
