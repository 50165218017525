import RouteIcon from '../../../../../components/RouteIcon/RouteIcon';
import { dateFormat } from '../../../../../helpers/format/dateFormat';
import { getPreviousCompletedLocation } from '../../../../../helpers/utils';
import { OrderResponseApi } from '../../../../../services/angular/types/Order';

interface CurrentLocationProps {
	order: OrderResponseApi;
}

const CurrentLocation = ({ order }: CurrentLocationProps) => {
	const { locations } = order;
	const completedLocation = getPreviousCompletedLocation(order);

	return (
		<div className="flex min-w-max max-w-fit flex-col justify-center p-1">
			{completedLocation ? (
				<div className="flex">
					<RouteIcon
						number={completedLocation ? completedLocation?.sequence + 1 : 1}
						checkoutDate={completedLocation?.checkoutDate}
						checkinDate={completedLocation?.checkinDate}
						reasonDate={completedLocation?.reasonDate}
					/>
					<div className="flex min-w-[6rem] flex-col">
						<span className="text-xs font-medium">
							{completedLocation.reason ? 'Não entregue' : 'Checkout'}
							{' - '}
							{completedLocation ? completedLocation?.sequence + 1 : 1}/
							{locations.length}
						</span>
						<span className="text-xs">
							{dateFormat(
								completedLocation?.checkoutDate || completedLocation?.reasonDate
							)}
						</span>
					</div>
				</div>
			) : (
				<div className="text-center text-xs font-medium">-</div>
			)}
			<span className="w-full pt-1 text-left text-xs font-medium">
				Parada anterior
			</span>
		</div>
	);
};

export default CurrentLocation;
