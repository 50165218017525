import { toast } from 'react-toastify';
import {
	Checkbox,
	FormHeader,
	Input,
} from '../../../../components/FormElements';
import { cpfMask } from '../../../../helpers/mask/cpfMask';
import validateCpf from '../../../../helpers/validation/cpfValidation';
import { api } from '../../../../services/angular/axios';
import { RegisterForm } from '../CompanyRegister';

interface RegisterSelectProps {
	form: RegisterForm;
}

const PersonalData = ({ form }: RegisterSelectProps) => {
	const handleCPFValidation = async (cpf: string) => {
		try {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			const response = await api.get(`verify-cpf/${cpf}`);
			if (response.data.active === false) {
				toast.success('CPF disponível');
			} else {
				toast.error('CPF já cadastrado');
			}
		} catch (error: any) {
			if (typeof error?.response?.data?.error === 'string') {
				toast.error(error.response?.data.error);
			} else {
				toast.error('Erro ao verificar CPF');
			}
		}
	};

	return (
		<>
			<FormHeader
				title="Só faltam alguns dados básicos"
				description="Insira as informações do responsável da empresa"
			/>
			<Input
				{...form.register('name')}
				errorMessage={form.formState.errors.name?.message}
				label="Nome completo"
			/>
			<Input
				label="CPF"
				maxLength={14}
				{...form.register('cpf', {
					onChange: (e) => {
						e.target.value = cpfMask(e.target.value);
						if (validateCpf(e.target.value))
							void handleCPFValidation(e.target.value);
					},
				})}
				errorMessage={form.formState.errors.cpf?.message}
			/>
			<Input
				{...form.register('email')}
				errorMessage={form.formState.errors.email?.message}
				label="E-mail"
			/>
			<Input
				{...form.register('password')}
				errorMessage={form.formState.errors.password?.message}
				type="password"
				autoComplete="new-password"
				label="Senha"
			/>
			<Input
				{...form.register('confirm')}
				errorMessage={form.formState.errors.confirm?.message}
				type="password"
				autoComplete="new-password"
				label="Confirme a senha"
			/>
			<Checkbox
				className="self-start"
				controller={{
					control: form.control,
					name: 'useTerms',
				}}
				goTo="termos-usuarios"
				label="Li e aceito os termos de uso"
			/>
			<Checkbox
				className="self-start"
				controller={{
					control: form.control,
					name: 'privacyTerms',
				}}
				goTo="politica-privacidade"
				label="Li e aceito a política de privacidade"
			/>
		</>
	);
};

export default PersonalData;
