import { create } from 'zustand';

interface ApiVersionStore {
	version: 'v1' | 'v2';
	setVersion: (version: 'v1' | 'v2') => void;
}

export const useApiVersionStore = create<ApiVersionStore>((set) => ({
	version: 'v1',
	setVersion: (version) => set({ version }),
}));
