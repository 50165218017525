import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const ArrowDownIcon: React.FC<SvgProps> = ({
	color = '#393939',
	size,
	stroke = '3.33333',
}) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_764_43252)">
				<path d="M7 10L12 15L17 10H7Z" fill="currentColor" />
			</g>
		</svg>
	);
};

export default ArrowDownIcon;
