import React, { useState } from 'react';
import Button from '../../../../components/Button/Button';
import { Icon } from '../../../../components/Icon/Icon';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PixPayment from './components/PixPayment';
import { PageHeader } from '../../../../components/PageHeader';
import CardPayment from './components/CardPayment';
import { twMerge } from 'tailwind-merge';
import { useQuery } from '@tanstack/react-query';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

const values = [100, 200, 300, 400, 500, 1000, 2000, 3000, 4000, 5000];

const NextArrow = (props: any) => {
	const { currentSlide, slideCount, ...remainingProps } = props;
	return (
		<div {...remainingProps}>
			<Icon
				name="chevron-right"
				size={20}
				className="absolute top-0 z-50 cursor-pointer rounded-full bg-neutral-100 text-neutral-0 hover:bg-neutral-200"
			/>
		</div>
	);
};

const PrevArrow = (props: any) => {
	const { currentSlide, slideCount, ...remainingProps } = props;
	return (
		<div {...remainingProps}>
			<Icon
				name="chevron-left"
				size={20}
				className="absolute top-0 z-50 cursor-pointer rounded-full bg-neutral-100 text-neutral-0 hover:bg-neutral-200"
			/>
		</div>
	);
};

const AddCredits: React.FC = () => {
	const showBalance = useUserState((state) => state.showBalance);
	const toggleShowBalance = useUserState((state) => state.toggleShowBalance);
	const [value, setValue] = useState(values[1]);
	const [paymentMethod, setPaymentMethod] = useState('pix');
	const settings: Settings = {
		dots: true,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
	};

	const company = useUserState((state) => state.company);

	const { data } = useQuery(
		['balance', company?.id],
		async () =>
			await api.get(
				`/companies/${company?.id || ''}/walletSummary/get-balance`
			),
		{
			enabled: !!company?.id && (company.isAdminUser || company.isOwner),
		}
	);

	return (
		<div className="w-full">
			<PageHeader
				title="Adicionar Créditos"
				description="Adicione créditos antecipadamente"
			>
				<div className="flex flex-col rounded-lg bg-neutral-900 px-4 py-1 text-start text-white md:hidden">
					<h1 className="text-sm font-medium">Saldo atual:</h1>
					<button
						className="flex min-h-[1.75rem] cursor-pointer items-center justify-center gap-2 text-lg font-semibold"
						onClick={(e) => {
							e.stopPropagation();

							toggleShowBalance();
						}}
					>
						{showBalance ? (
							<>
								<BsFillEyeFill />
								{data?.data.toLocaleString('pt-br', {
									style: 'currency',
									currency: 'BRL',
								})}
							</>
						) : (
							<>
								<BsFillEyeSlashFill /> R$ • • • •
							</>
						)}
					</button>
				</div>
			</PageHeader>
			<div className="flex flex-col gap-4 text-left md:flex-row">
				<div className="flex w-full flex-col gap-2 md:w-1/2">
					<WhiteContainer className="mb-2 w-full">
						<div className="w-full">
							<h1 className="mb-2 font-bold text-neutral-900">
								Selecione o valor desejado
							</h1>

							<Slider {...settings} arrows className="mb-6 w-full items-center">
								{values.map((v, idx) => (
									<div className="px-4" key={idx}>
										<Button
											className="mr-2 pb-4 text-xl"
											variant={value === v ? 'primary' : 'secondary'}
											onClick={() => setValue(v)}
										>
											<span
												className={`${
													value === v ? 'text-yellow-500' : ''
												} flex h-full w-full flex-col items-center text-3xl`}
											>
												<span className="self-start text-sm leading-none">
													R$
												</span>
												{v}
											</span>
										</Button>
									</div>
								))}
							</Slider>
						</div>
						<h1 className="mt-4 font-bold text-neutral-900">Pague com:</h1>
						<Button
							className={twMerge(
								'px-8 text-2xl font-bold',
								paymentMethod === 'pix' ? 'text-yellow-500' : 'text-neutral-400'
							)}
							variant={paymentMethod === 'pix' ? 'primary' : 'secondary'}
							onClick={() => setPaymentMethod('pix')}
						>
							<Icon name="pix" size={35} />
							<span className="mx-auto">PIX</span>
						</Button>
						<Button
							className={twMerge(
								'mt-2 px-8 text-2xl font-bold',
								paymentMethod === 'card'
									? 'text-yellow-500'
									: 'text-neutral-400'
							)}
							variant={paymentMethod === 'card' ? 'primary' : 'secondary'}
							onClick={() => setPaymentMethod('card')}
						>
							<Icon name="credit-card" size={35} />
							<span className="mx-auto">Cartão de Crédito</span>
						</Button>
					</WhiteContainer>
					<WhiteContainer className="mt-4 flex flex-row items-center justify-between">
						<div className="flex flex-col">
							<div className="flex w-1/2 items-center gap-2">
								<img
									className="h-10 w-10"
									src={require('../../../../assets/mastercardLogo.png')}
									alt="mastercard"
								/>
								<img
									className="h-5 w-12"
									src={require('../../../../assets/eloLogo.png')}
									alt="Elo"
								/>
								<img
									className="h-10 w-10"
									src={require('../../../../assets/logo-hipercard-256.png')}
									alt="hipercard"
								/>
								<img
									className="h-10 w-10"
									src={require('../../../../assets/logo-visa-256.png')}
									alt="visa"
								/>
								<img
									className="h-10 w-10"
									src={require('../../../../assets/americanExpressLogo.png')}
									alt="american express"
								/>
							</div>
							<div className="flex w-1/2 items-center gap-4">
								<img
									className="h-5 "
									src={require('../../../../assets/pixLogo.png')}
									alt="Pix"
								/>
								<img
									className="h-12"
									src={require('../../../../assets/boletoLogo.png')}
									alt="Boleto"
								/>
							</div>
						</div>
						<div className="flex items-center gap-4">
							<img
								className="w-24 cursor-pointer"
								src={require('../../../../assets/safeBrowsingMinimal.png')}
								alt="safeBrowsing"
								onClick={() => {
									window.open(
										'https://transparencyreport.google.com/safe-browsing/search?url=beebee.app'
									);
								}}
							/>
							<img
								className="mt-2 w-20"
								src={require('../../../../assets/selossl.png')}
								alt="seloSSL"
							/>
						</div>
					</WhiteContainer>
				</div>

				<div className="w-full md:w-1/2 ">
					<WhiteContainer>
						<>
							{paymentMethod === 'pix' && <PixPayment value={value} />}

							{paymentMethod === 'card' && <CardPayment value={value} />}
						</>
					</WhiteContainer>
				</div>
			</div>
		</div>
	);
};

export default AddCredits;
