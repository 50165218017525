import { twMerge } from 'tailwind-merge';
import { BsHexagonFill } from 'react-icons/bs';
import { Steps } from '..';
import useUserState from '../../../../../services/angular/angularUserState';

interface StepperProps {
	steps: Steps | number[];
	step: keyof Steps | number;
}

export const Stepper = ({ step, steps }: StepperProps) => {
	const company = useUserState((state) => state.company);

	if (!company && typeof step !== 'number') return null;

	const stepKeys = Object.keys(steps).filter(
		(s) => s !== 'Tipo' || company?.dynamicRouteEnabled
	);

	return (
		<div className="relative flex flex-row justify-between w-full text-neutral-500">
			{stepKeys.map((s, index) => (
				<div
					key={index}
					className={twMerge(
						'flex items-center justify-center',
						stepKeys.length - 1 !== index &&
							'after:border-1 flex w-full items-center after:mx-2 after:mb-4 after:inline-block after:h-1 after:w-full after:border-b after:border-neutral-200 after:content-[""] md:after:mb-0 xl:after:mx-4'
					)}
				>
					<div className="flex flex-col items-center justify-center md:flex-row">
						<div className="relative flex items-center justify-center select-none md:mr-2">
							<BsHexagonFill
								className={twMerge(
									'h-8 w-8 rotate-90 md:h-10 md:w-10',
									s === step ? 'text-[#e8b21a]' : 'text-neutral-500'
								)}
							/>
							<span className="absolute text-sm font-black text-white md:text-base">
								{index + 1}
							</span>
						</div>
						<span className="text-xs font-bold md:text-sm">{s}</span>
					</div>
				</div>
			))}
		</div>
	);
};
