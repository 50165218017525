import React from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { Tooltip } from '.';

interface InfoTooltipProps {
	message: string;
	offset?: number;
}

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
	message,
	offset,
}) => {
	return (
		<Tooltip sideOffset={offset} message={message}>
			<FiHelpCircle className="pb-px" />
		</Tooltip>
	);
};
