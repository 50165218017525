import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface Prediction {
	result: SearchResult[];
}

export interface SearchResult {
	placeId: string;
	date: Date;
	placeName: string;
	address: string;
	provider: string;
	historic?: boolean;
}

interface LocationState {
	results: Record<string, SearchResult[]>;
	addResult: (key: string, result: SearchResult) => void;
	clearResults: (key: string) => void;
}

export const useLocationState = create(
	persist<LocationState>(
		(set) => ({
			results: {},
			addResult: (key: string, result: SearchResult) => {
				set((state) => {
					const record = state.results[key];

					if (record) {
						const results = record.filter(
							(item) => item.placeId !== result.placeId
						);

						results.unshift(result);

						state.results[key] = results;
					} else {
						state.results[key] = [result];
					}

					return state;
				});
			},
			clearResults: (key: string) => {
				set((state) => {
					// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
					delete state.results[key];

					return state;
				});
			},
		}),
		{
			name: 'autocomplete-results',
		}
	)
);
