import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { BsWhatsapp } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { useAlert } from '../../../contexts/AlertContext';
import { phoneMask, removePhoneMask } from '../../../helpers/mask/phoneMask';
import { useCopy } from '../../../hooks/useCopy';
import useUserState from '../../../services/angular/angularUserState';
import { api } from '../../../services/angular/axios';
import { OrderResponseApi } from '../../../services/angular/types/Order';
import { Avatar } from '../../Avatar';
import { Checkbox, FormHeader } from '../../FormElements';
import { Tooltip } from '../../Tooltip';
import WhiteContainer from '../../WhiteContainer/WhiteContainer';

interface DriverProps {
	order?: OrderResponseApi;
}

export const DriverDetails = ({ order }: DriverProps) => {
	const alert = useAlert();
	const copy = useCopy();
	const company = useUserState((state) => state.company);
	const [check, setCheck] = useState(false);

	const { data: driverAvatar } = useQuery(
		['driver-avatar', order?.driver?.avatar],
		async () => {
			if (!order?.driver?.avatar) return;
			return await api.get(`/assets?`, {
				params: {
					imageName: order?.driver?.avatar,
				},
			});
		},
		{
			enabled: !!order?.driver?.avatar,
		}
	);

	const { data: driverCompanyAvatar } = useQuery(
		['company-avatar', order?.driverCompany?.avatar],
		async () => {
			if (!order?.driverCompany?.avatar) return;
			return await api.get(`/assets?`, {
				params: {
					imageName: order?.driverCompany?.avatar,
				},
			});
		},
		{
			enabled: !!order?.driverCompany?.avatar,
		}
	);

	const { data: favoriteDrivers, isFetching } = useQuery(
		['favorite-drivers', company?.id],
		async () =>
			await api.get<
				Array<{
					id: number;
				}>
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			>(`/companies/${company?.id}/favorites/drivers`),
		{
			enabled: !!company?.id && company?.allowFavoriteDriver,
		}
	);

	const favoriteDriver = useMemo(() => {
		const driver = favoriteDrivers?.data.find(
			(favorite) => favorite.id === order?.driver?.id
		);

		if (driver) setCheck(true);

		return driver;
	}, [favoriteDrivers, order?.driver?.id]);

	return (
		<WhiteContainer
			className="h-full gap-4"
			divProps={{
				className: 'w-full',
			}}
		>
			<FormHeader
				title="Fretista"
				description={
					order?.acceptanceDate &&
					`Aceitou em ${dayjs(order?.acceptanceDate).format(
						'DD/MM/YYYY HH:mm'
					)}`
				}
			/>
			<div className="flex items-center gap-4">
				<span className="h-20 w-20 ">
					<Avatar
						src={driverCompanyAvatar?.data.url}
						avatarProps={{
							className: ' h-20 w-20',
						}}
					/>
				</span>
				<div className="flex flex-col text-sm">
					<span className="text-base font-bold">
						{order?.driverCompany?.tradingName ||
							order?.driverCompany?.companyName}
					</span>
					<span className="truncate">
						{order?.driverCompany?.email || order?.driverCompany?.supportEmail}
					</span>
					<span
						className="cursor-copy"
						onClick={() => {
							void copy(
								removePhoneMask(order?.driverCompany?.cellphone, '+55')
							);
						}}
					>
						{phoneMask(removePhoneMask(order?.driverCompany?.cellphone))}
					</span>
					<span
						className="cursor-copy"
						onClick={() => {
							void copy(removePhoneMask(order?.driverCompany?.phone));
						}}
					>
						{phoneMask(removePhoneMask(order?.driverCompany?.phone))}
					</span>
				</div>
			</div>
			<hr className="text-neutral-200" />
			<div className="relative flex items-center gap-4">
				{order?.isFavoriteDriver && (
					<Tooltip
						message="Motorista favorito"
						triggerClassName="absolute top-0 right-0"
					>
						<AiFillStar className="text-yellow-submenu" size={24} />
					</Tooltip>
				)}
				<span className="h-20 w-20 ">
					<Avatar
						src={driverAvatar?.data.url}
						avatarProps={{
							className: ' h-20 w-20',
						}}
					/>
				</span>

				<div className="flex flex-col text-sm">
					<span className="text-base font-bold">{order?.driver.name}</span>
					<span className="mb-1 truncate">{order?.driver.email}</span>
					<span className="flex gap-2">
						<span
							className="cursor-copy"
							onClick={() => {
								void copy(removePhoneMask(order?.driver.cellphone, '+55'));
							}}
						>
							{phoneMask(removePhoneMask(order?.driver.cellphone))}
						</span>
						{order?.driver.cellphone && (
							<Tooltip message="Abrir whatsapp">
								<BsWhatsapp
									onClick={() => {
										window.open(
											`https://api.whatsapp.com/send?phone=55${order?.driver.cellphone.replace(
												/\D/g,
												''
											)}&text=${encodeURI(`Olá ${order?.driver.name || ''}`)}`,
											'_blank'
										);
									}}
									className="cursor-pointer text-green"
									size={16}
								/>
							</Tooltip>
						)}
					</span>
					<span>CNH: {order?.driver.driverLicense}</span>

					{!isFetching &&
						favoriteDrivers &&
						company?.id &&
						(company.type === 'Empresa' ||
							company.type === 'Fretista' ||
							company.type === 'Usuário Admin') && (
							<div className="mt-4 flex items-center justify-center rounded bg-neutral-50 p-2">
								<Checkbox
									label="Motorista favorito?"
									checked={check}
									onCheckedChange={(checked) => {
										alert.onCustom({
											title: `Deseja ${
												checked ? 'adicionar' : 'remover'
											} o motorista dos favoritos?`,
											message: `Ao confirmar, o motorista será  ${
												checked ? 'adicionado nos' : 'removido dos'
											} favoritos.`,
											confirm: {
												onClick: async () => {
													try {
														check
															? await api.delete(
																	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																	`/companies/${company?.id}/favorites/drivers/${favoriteDriver?.id}`
															  )
															: await api.post(
																	// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																	`/companies/${company?.id}/favorites/drivers/${order?.driver?.id}`
															  );

														toast.success(
															`Motorista ${
																check ? 'removido' : 'adicionado'
															} dos favoritos com sucesso`
														);
														setCheck(!check);
													} catch {
														toast.error('Erro ao atualizar favorito');
													}
												},
											},
											cancel: {
												onClick: () => {
													setCheck(check);
												},
											},
										});
									}}
								/>
							</div>
						)}
				</div>
			</div>
		</WhiteContainer>
	);
};
