export const phoneRegex = /^(\(\d{2}\) \d{4}-\d{4}|\(\d{2}\) \d{5}-\d{4})$/;
export const cellphoneOnlyRegex = /^(\(\d{2}\) \d{5}-\d{4})$/;

export const phoneMask = (value: string | undefined): string => {
	if (!value) return '';
	return value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '($1) $2')
		.replace(/(\d{4})(\d)/, '$1-$2')
		.replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
		.replace(/(-\d{4})\d+?$/, '$1');
};

export const cellPhoneOnlyMask = (value: string | undefined): string => {
	if (!value) return '';
	return value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '($1) $2')
		.replace(/(\d{5})(\d)/, '$1-$2')
		.replace(/(-\d{4})\d+?$/, '$1');
};

export const removePhoneMask = (
	value: string | undefined,
	addPrefix?: string
): string => {
	if (!value) return '';

	const text = value.replace(/\D/g, '');

	if (addPrefix) return addPrefix + text;

	return text;
};
