import { PageHeader } from '../../../../../components/PageHeader';
import { CategoryForm } from './components/CategoryForm';
import { useParams } from 'react-router-dom';

export const EditCategory = () => {
	const { id } = useParams();

	return (
		<div>
			<PageHeader
				title="Categoria de Veículo"
				description="Definições referentes à categoria de veículo"
			/>
			<CategoryForm categoryId={Number(id)} />
		</div>
	);
};
