import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { Input, useZodForm } from '../../../../components/FormElements';
import Button from '../../../../components/Button/Button';
import { IoCheckmark, IoClose } from 'react-icons/io5';
import { z } from 'zod';
import { useInviteCampaignStore } from '../inviteCampaignStore';
import { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

const passwordRegex =
	/^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;

const schema = z
	.object({
		password: z
			.string({
				required_error: 'Senha obrigatória',
			})
			.min(8)
			.regex(passwordRegex),
		confirm: z.string({
			required_error: 'Confirmação de senha obrigatória',
		}),
	})
	.superRefine(({ confirm, password }, ctx) => {
		if (confirm !== password) {
			ctx.addIssue({
				code: 'custom',
				message: 'As senhas não conferem',
			});
		}
	});

export const PasswordStep = () => {
	const setStep = useInviteCampaignStore((state) => state.setStep);
	const formState = useInviteCampaignStore((state) => state.formState);
	const upsertForm = useInviteCampaignStore((state) => state.upsertForm);

	const [showPassword, setShowPassword] = useState(false);

	const form = useZodForm({
		schema,
		defaultValues:
			'password' in formState
				? {
						password: formState?.password,
						confirm: formState?.confirm,
				  }
				: undefined,
	});

	if (formState.step !== 'senha') return null;

	const onSubmit = form.handleSubmit((data) => {
		upsertForm({
			...formState,
			...data,
			step: 'foto',
		});
		setStep('foto');
	});

	return (
		<div className="flex flex-col gap-2">
			<h2 className="text-center">
				Essa senha é para o seu acesso ao app, crie algo fácil de lembrar e que
				só você sabe
			</h2>
			<Input
				defaultValue={''}
				{...form.register('password')}
				type={showPassword ? 'text' : 'password'}
				label="Senha"
				inputIcon={
					showPassword ? (
						<BsFillEyeFill onClick={() => setShowPassword(false)} />
					) : (
						<BsFillEyeSlashFill onClick={() => setShowPassword(true)} />
					)
				}
			/>
			<Input
				defaultValue={''}
				{...form.register('confirm')}
				type={showPassword ? 'text' : 'password'}
				label="Confirmar senha"
				inputIcon={
					showPassword ? (
						<BsFillEyeFill onClick={() => setShowPassword(false)} />
					) : (
						<BsFillEyeSlashFill onClick={() => setShowPassword(true)} />
					)
				}
			/>
			<PasswordCheckList
				confirm={form.watch('confirm')}
				password={form.watch('password')}
			/>
			<div className="flex flex-col items-center gap-4">
				<Button
					type="submit"
					disabled={!form.formState.isValid}
					// eslint-disable-next-line @typescript-eslint/no-misused-promises
					onClick={onSubmit}
				>
					Avançar
				</Button>
				<Button onClick={() => setStep('info')} variant="secondary">
					Voltar
				</Button>
			</div>
		</div>
	);
};

interface PasswordCheckProps {
	password: string;
	confirm: string;
}

const PasswordCheckList = ({ confirm, password }: PasswordCheckProps) => {
	const passwordCheckList = useMemo(
		() => [
			{
				label: 'Letra minúscula',
				isValid: /[a-z]/.test(password) && password !== '',
			},
			{
				label: 'Letra maiúscula',
				isValid: /[A-Z]/.test(password),
			},
			{
				label: 'Número',
				isValid: /[0-9]/.test(password),
			},
			{
				label: 'Caractere especial',
				isValid: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(password),
			},
			{
				label: 'Comprimento mínimo',
				isValid: password?.length >= 8,
			},
			{
				label: 'Senhas iguais',
				isValid: password === confirm && password !== '',
			},
		],
		[password, confirm]
	);

	return (
		<div className="my-4 grid grid-cols-2">
			{passwordCheckList.map((item, index) => (
				<p
					key={index}
					className={twMerge(
						'flex items-center text-xs',
						item.isValid ? 'text-green' : 'text-red'
					)}
				>
					{item.isValid ? <IoCheckmark /> : <IoClose />}
					{item.label}
				</p>
			))}
		</div>
	);
};
