import React from 'react';
import { Outlet } from 'react-router-dom';

const PublicLayout: React.FC = () => {
	return (
		<div className="fixed flex min-h-screen w-full flex-auto flex-shrink-0 flex-col overflow-hidden antialiased">
			<div className="fixed flex h-full w-full flex-col items-center bg-[url('/src/assets/MobileBackground.svg')] bg-cover bg-center bg-no-repeat sm:justify-center md:bg-[url('/src/assets/BackgroundMoreHexs.svg')]">
				<div className="w-full overflow-y-auto p-4 portrait:pt-[10%] portrait:sm:py-0">
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default PublicLayout;
