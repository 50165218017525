import React, { useState } from 'react';
import { IoClose, IoCheckmark } from 'react-icons/io5';
import PasswordChecklist from 'react-password-checklist';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import {
	Form,
	FormHeader,
	Input,
	useZodForm,
} from '../../../../components/FormElements';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import Button from '../../../../components/Button/Button';
import {
	ChangePasswordSchema,
	ChangePasswordSchemaType,
} from '../schemas/ChangePasswordSchema';
import { toast } from 'react-toastify';
import { api } from '../../../../services/angular/axios';
import useUserState from '../../../../services/angular/angularUserState';

interface ChangePasswordProps {
	className?: string;
}

export const ChangePassword: React.FC<ChangePasswordProps> = ({
	className,
}) => {
	const { user, login } = useUserState();
	const [checkListOpen, setCheckListOpen] = useState(false);
	const [showPassword, setShowPassword] = useState({
		currentPassword: false,
		password: false,
		confirmPassword: false,
	});

	const form = useZodForm({
		schema: ChangePasswordSchema,
		defaultValues: {
			currentPassword: '',
			password: '',
			confirmPassword: '',
		},
	});

	const { register, watch } = form;

	const handleSubmit = async (data: ChangePasswordSchemaType) => {
		try {
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			const response = await api.put(`/users/${user?.id}/password`, {
				currentPassword: data.currentPassword,
				password: data.password,
				userId: user?.id,
			});
			form.reset();
			login(response?.data.token);
			toast.success('Senha alterada com sucesso');
		} catch (error) {
			toast.error('Erro ao alterar senha');
		}
	};

	return (
		<div className={className}>
			<WhiteContainer>
				<Form form={form} onSubmit={handleSubmit}>
					<FormHeader title="Alterar senha" />
					<Input
						{...register('currentPassword')}
						type={showPassword.currentPassword ? 'text' : 'password'}
						label="Senha atual"
						className="mt-2"
						inputIcon={
							showPassword.currentPassword ? (
								<BsFillEyeFill
									onClick={() =>
										setShowPassword({
											...showPassword,
											currentPassword: false,
										})
									}
								/>
							) : (
								<BsFillEyeSlashFill
									onClick={() =>
										setShowPassword({
											...showPassword,
											currentPassword: true,
										})
									}
								/>
							)
						}
					/>
					<Input
						onFocus={() => {
							setCheckListOpen(true);
						}}
						{...register('password', {
							onBlur: () => {
								setCheckListOpen(false);
							},
						})}
						type={showPassword.password ? 'text' : 'password'}
						label="Nova senha"
						className="mt-2"
						inputIcon={
							showPassword.password ? (
								<BsFillEyeFill
									onClick={() =>
										setShowPassword({
											...showPassword,
											password: false,
										})
									}
								/>
							) : (
								<BsFillEyeSlashFill
									onClick={() =>
										setShowPassword({
											...showPassword,
											password: true,
										})
									}
								/>
							)
						}
					/>
					<Input
						onFocus={() => {
							setCheckListOpen(true);
						}}
						{...register('confirmPassword', {
							onBlur: () => {
								setCheckListOpen(false);
							},
						})}
						type={showPassword.confirmPassword ? 'text' : 'password'}
						label="Confirmar nova senha"
						className="mt-2"
						inputIcon={
							showPassword.confirmPassword ? (
								<BsFillEyeFill
									onClick={() =>
										setShowPassword({
											...showPassword,
											confirmPassword: false,
										})
									}
								/>
							) : (
								<BsFillEyeSlashFill
									onClick={() =>
										setShowPassword({
											...showPassword,
											confirmPassword: true,
										})
									}
								/>
							)
						}
					/>
					{checkListOpen && (
						<PasswordChecklist
							iconSize={14}
							iconComponents={{
								ValidIcon: (
									<IoCheckmark className="mr-1 self-end text-green" size={18} />
								),
								InvalidIcon: (
									<IoClose
										className="mr-1 self-end text-terracota-500"
										size={18}
									/>
								),
							}}
							className="text-sm"
							rules={[
								'lowercase',
								'capital',
								'number',
								'specialChar',
								'minLength',
								'match',
							]}
							minLength={8}
							value={watch('password')}
							valueAgain={watch('confirmPassword')}
							messages={{
								minLength: 'Mínimo 8 caracteres',
								specialChar: 'Deve conter carácter especial',
								number: 'Deve conter número',
								capital: 'Deve conter letra maiúscula',
								lowercase: 'Deve conter letra minúscula',
								match: 'Senha devem ser iguais',
							}}
						/>
					)}
					<Button
						type="submit"
						className="mt-4 w-auto"
						variant="primary"
						disabled={!form.formState.isDirty}
					>
						<IoCheckmark size={20} />
						Alterar senha
					</Button>
				</Form>
			</WhiteContainer>
		</div>
	);
};
