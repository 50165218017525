import React from 'react';
import { TiPlus } from 'react-icons/ti';
import Button, { ButtonVariants } from './Button';

interface RegisterButtonProps extends React.ComponentPropsWithoutRef<'button'> {
	variant?: ButtonVariants;
	loading?: boolean;
	label?: string;
}

export const RegisterButton: React.FC<RegisterButtonProps> = ({
	label = 'Cadastrar',
	variant = 'blue-primary',
	...props
}) => {
	return (
		<Button variant={variant} {...props} aria-label={label || 'Adicionar'}>
			{label}
			<TiPlus size={20} />
		</Button>
	);
};
