import React from 'react';
import { IoClose } from 'react-icons/io5';
import { BsCheck } from 'react-icons/bs';
import { ButtonVariants } from '../components/Button/Button';
import { Icon } from '../components/Icon/Icon';
import { Alert } from '../components/Modal/Alert';

export interface AlertBody {
	title?: string;
	message: React.ReactNode;
	cancel?: {
		label?: string;
		variant?: ButtonVariants;
		icon?: React.ReactNode;
		onClick?: () => void | Promise<void>;
	};
	confirm?: {
		label?: string;
		variant?: ButtonVariants;
		icon?: React.ReactNode;
		onClick?: () => void | Promise<void>;
	};
}

interface AlertContextProps {
	body: AlertBody;
	onCustom: (props: AlertBody) => void;
	onConfirmDelete: (onDelete: () => void, props?: AlertBody) => void;
	onConfirmCancel: (onCancel: () => void, props?: AlertBody) => void;
}

const AlertContext = React.createContext<AlertContextProps | null>(null);

interface AlertProviderProps {
	children: React.ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
	const [open, setOpen] = React.useState(false);
	const [body, setBody] = React.useState<AlertBody>({
		title: '',
		message: '',
		cancel: {
			label: '',
			variant: 'primary',
			icon: <IoClose size={20} />,
		},
	});

	const onConfirmDelete = (onClick: () => void, data?: AlertBody): void => {
		setBody({
			title: data?.title || 'Tem certeza que deseja excluir o registro?',
			message: data?.message || 'Essa ação não poderá ser desfeita.',
			cancel: {
				label: data?.cancel?.label || 'Cancelar',
				variant: data?.cancel?.variant || 'primary',
				icon: data?.cancel?.icon || <IoClose size={20} />,
			},
			confirm: {
				label: data?.confirm?.label || 'Excluir',
				variant: data?.confirm?.variant || 'red',
				onClick,
				icon: data?.confirm?.icon || <Icon name="trash" size={20} />,
			},
		});
		setOpen(true);
	};

	const onConfirmCancel = (onClick: () => void, data?: AlertBody): void => {
		setBody({
			title: data?.title || 'Tem certeza que deseja continuar?',
			message:
				data?.message ||
				'Os dados serão perdidos e não poderão ser recuperados.',
			cancel: {
				label: data?.cancel?.label || 'Não',
				variant: data?.cancel?.variant || 'primary',
				icon: data?.cancel?.icon || <IoClose size={20} />,
			},
			confirm: {
				label: data?.confirm?.label || 'Sim',
				variant: data?.confirm?.variant || 'blue-primary',
				icon: data?.confirm?.icon || <BsCheck size={20} />,
				onClick,
			},
		});
		setOpen(true);
	};

	const onCustom = (data: AlertBody): void => {
		setBody({
			...data,
			cancel: {
				label: data?.cancel?.label || 'Cancelar',
				variant: data?.cancel?.variant || 'secondary',
				icon: data?.cancel?.icon || <IoClose size={20} />,
				onClick: data?.cancel?.onClick,
			},
			confirm: data?.confirm && {
				label: data.confirm.label || 'Confirmar',
				variant: data?.confirm?.variant || 'blue-primary',
				icon: data?.confirm?.icon || <BsCheck size={20} />,
				onClick: data?.confirm?.onClick,
			},
		});
		setOpen(true);
	};

	return (
		<AlertContext.Provider
			value={{
				body,
				onCustom,
				onConfirmDelete,
				onConfirmCancel,
			}}
		>
			<Alert open={open} setOpen={setOpen} body={body} />
			{children}
		</AlertContext.Provider>
	);
};

export const useAlert = (): AlertContextProps => {
	const context = React.useContext(AlertContext);

	if (context === null) {
		throw new Error('useAlert must be used within a AlertProvider');
	}

	return context;
};
