import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import Button from '../../../../../components/Button/Button';

export interface AlertErrorBody {
	title: string;
	cancel?: {
		label: string;
		icon?: React.ReactNode;
		onClick?: () => void;
	};
	confirm: {
		label: string;
		icon?: React.ReactNode;
		onClick?: () => void;
	};
	content: React.ReactNode;
}

interface ErrorAlertProps {
	body?: AlertErrorBody;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ErrorAlert = ({ body, open, setOpen }: ErrorAlertProps) => {
	return (
		<AlertDialog.Root open={open} onOpenChange={setOpen}>
			<AlertDialog.Portal forceMount>
				<Transition.Root show={open}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-50"
						leave="ease-in duration-200"
						leaveFrom="opacity-50"
						leaveTo="opacity-0"
					>
						<AlertDialog.Overlay className="fixed inset-0 z-50 bg-neutral-0 opacity-50" />
					</Transition.Child>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<AlertDialog.Content
							onEscapeKeyDown={(e) => e.preventDefault()}
							className={`fixed top-1/3 left-1/2 z-50 flex max-h-[85vh] w-fit max-w-lg -translate-x-1/2 -translate-y-1/2 flex-col gap-4 overflow-auto rounded-md bg-white p-4 shadow outline-none sm:top-1/2`}
						>
							<AlertDialog.Title className="text-center text-2xl font-bold text-neutral-900 ">
								{body?.title}
							</AlertDialog.Title>

							<AlertDialog.Description className="text-center text-lg text-neutral-800">
								{body?.content}
							</AlertDialog.Description>

							<div className="flex flex-wrap justify-center gap-4">
								{body?.cancel && (
									<AlertDialog.Cancel asChild>
										<Button className="w-auto" onClick={body.cancel.onClick}>
											{body.cancel?.label}
											{body.cancel?.icon}
										</Button>
									</AlertDialog.Cancel>
								)}
								<AlertDialog.Action asChild>
									<Button
										className="w-auto"
										variant="blue-primary"
										onClick={body?.confirm.onClick}
									>
										{body?.confirm?.label}
										{body?.confirm?.icon}
									</Button>
								</AlertDialog.Action>
							</div>
						</AlertDialog.Content>
					</Transition.Child>
				</Transition.Root>
			</AlertDialog.Portal>
		</AlertDialog.Root>
	);
};
