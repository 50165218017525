/* eslint-disable @typescript-eslint/no-misused-promises */
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import {
	TbClock,
	TbMap2,
	TbTruckDelivery,
	TbListDetails,
	TbArrowBackUp,
	TbReload,
} from 'react-icons/tb';
import { FiltersTab } from '../../../../components/FiltersTab';
import {
	Date,
	Input,
	Select,
	useZodForm,
} from '../../../../components/FormElements';
import { oldIcons, VehicleIcon } from '../../../../components/Icon/vehicles';
import { PageHeader } from '../../../../components/PageHeader';
import {
	Actions,
	Table,
	TableActions,
} from '../../../../components/Table/Table';
import { Tooltip } from '../../../../components/Tooltip';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { api } from '../../../../services/angular/axios';
import { Distance } from '../../Freights/Historic/Distance';
import { appState } from '../../../../store/appState';
import { OrdersFilterSchema } from './schemas/OrdersFilterSchema';
import { Controller } from 'react-hook-form';
import { OrderTypeIcon } from '../../../../components/Icon/OrderTypeIcon';
import { twMerge } from 'tailwind-merge';
import { FaExclamationCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import { OrderResponseApi } from '../../../../services/angular/types/Order';
import { useAlert } from '../../../../contexts/AlertContext';
import { toast } from 'react-toastify';
import Button from '../../../../components/Button/Button';
import { ImBlocked, ImDownload3 } from 'react-icons/im';
import { downloadFile } from '../../../../helpers/downloadFile';
import { ClientDropdown } from '../../../../components/Order/ClientDropdown';
import { StatusTooltip } from '../../../../components/Angular/StatusTooltip';
import { BsExclamationOctagonFill, BsFillInfoCircleFill } from 'react-icons/bs';
import { RequestFreightSchema } from '../../Freights/Request/schemas/RequestFreight';
import { DriverDropdown } from '../../../../components/Order/DriverDropdown';
import { SelectGeofence } from '../../../../components/Angular/SelectGeofence';
import useUserState from '../../../../services/angular/angularUserState';
import { canCancelOrder } from '../../../../helpers/canCancelOrder';
import { CancelOrderModal } from '../../../../components/Order/CancelOrderModal';
import { CategoryType } from '../../../../components/Order/CategoryType';
import { canSendToPool } from '../../../../helpers/utils';
import { useOrdersFilterState } from './schemas/ordersFilterState';

const OrdersPage = () => {
	const alert = useAlert();
	const navigate = useNavigate();
	const geofences = useUserState((state) => state.geofences);
	const [orderId, setOrderId] = useState<number>();
	const [cancelModalReason, setCancelModalReason] = useState(false);

	const { params, setParams, clearOrdersFilter, setOrdersFilter, ...query } =
		useOrdersFilterState();

	const form = useZodForm({
		schema: OrdersFilterSchema,
		defaultValues: {
			...query,
			id: query.id ? query.id : undefined,
			statusId: query.statusId
				? Object.values(appState.orderStatus)
						.filter((s) => query.statusId?.includes(String(s.id)))
						.map((s) => {
							return {
								label: s.name,
								value: s.id,
							};
						})
				: [],
		},
	});

	const {
		data: orders,
		refetch,
		isFetching,
	} = useQuery(
		[
			'orders',
			query,
			params,
			geofences
				?.map((g) => g.id)
				.sort((a, b) => a - b)
				.join(','),
		],
		async () => {
			const { sortBy, page, take } = params;

			return await api.get('/orders', {
				params: {
					...query,
					page,
					pageSize: take,
					sort: sortBy ? `${sortBy?.id}:${sortBy?.order}` : undefined,
					geofenceId: geofences?.map((g) => g.id).join(','),
					include:
						'incident,locations,locations.protocols,locations.receiverType,category,driver,driver.tags,driverCompany,requester,requesterCompany,requesterCompany.tags,status,cancellations,integrationOrder,integrationOrder.integrationCompany',
				},
			});
		}
	);

	const exportTableData = async () => {
		try {
			const response = await api.get(
				'/orders/export?includeStatus=incident,locations,locations.protocols,locations.receiverType,category,driver,driver.tags,driverCompany,requester,requesterCompany,requesterCompany.tags,status,cancellations,integrationOrder,integrationOrder.integrationCompany',
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
						...query,
					},
					responseType: 'blob',
				}
			);

			await downloadFile({
				blobFile: response?.data,
				fileName: `pedidos-${dayjs().format('DD/MM/YYYY_HH:mm')}.xlsx`,
			});
		} catch (err) {
			toast.error('Erro ao gerar arquivo');
		}
	};

	const { data: categories } = useQuery<any>(
		['categories'],
		async () => await api.get('/categories')
	);

	return (
		<>
			<PageHeader title="Pedidos" description="Relação de pedidos" />
			<FiltersTab
				className="w-[350px]"
				dropDownFilter
				form={form}
				clearFilters={() => {
					form.reset({
						statusId: [],
						operation: null,
						categoryId: null,
						id: undefined,
						endDate: null,
						geofenceId: null,
						initialDate: null,
						orderDate: null,
						orderTypeId: null,
						query: undefined,
					});
					clearOrdersFilter();
				}}
				onSubmit={(data) => {
					setOrdersFilter(data);
					setParams({
						...params,
						page: 1,
					});
				}}
				actionsChildren={
					<Button
						type="button"
						className="w-auto self-end py-1"
						onClick={() => {
							void exportTableData();
						}}
					>
						<ImDownload3 size={17} />
						Exportar
					</Button>
				}
			>
				<Input
					variant="light"
					className="w-auto"
					label="Nº do frete"
					type="number"
					{...form.register('id')}
				/>
				<Select
					variant="light"
					className="w-auto"
					label="Status"
					controller={{
						control: form.control,
						name: 'statusId',
					}}
					isMulti
					options={Object.values(appState.orderStatus).map((opt) => ({
						label: opt.name,
						value: opt.id,
					}))}
				/>
				<Input
					variant="light"
					className="w-auto"
					label="Pesquisar"
					{...form.register('query')}
				/>
				<h1 className="mt-4 text-base font-bold text-neutral-500">
					Data de conclusão
				</h1>
				<Date
					variant="light"
					className="w-auto"
					label="De"
					control={form.control}
					name="initialDate"
				/>
				<Date
					control={form.control}
					name="endDate"
					variant="light"
					className="w-auto"
					label="Até"
				/>
				<h1 className="mt-4 text-base font-bold text-neutral-500">
					Data do pedido
				</h1>
				<Date
					className="mb-4 w-auto"
					label="Feitos em"
					variant="light"
					control={form.control}
					name="orderDate"
				/>

				<Controller
					control={form.control}
					name="operation"
					render={({ field }) => (
						<div className="flex flex-col">
							<h1 className="text-sm font-bold text-neutral-800">Início</h1>
							<div className="flex items-center gap-4">
								<Tooltip message="Agendado" triggerClassName="max-w-full w-1/2">
									<div
										className={twMerge(
											'flex cursor-pointer justify-center rounded border-[1px] border-neutral-500 bg-white p-2',
											field.value === 'scheduled'
												? 'bg-neutral-0 text-white hover:bg-neutral-800'
												: 'text-neutral-0 hover:bg-neutral-50'
										)}
										onClick={() => {
											field.value === 'scheduled'
												? field.onChange(undefined)
												: field.onChange('scheduled');
										}}
									>
										<TbClock size={25} />
									</div>
								</Tooltip>
								<Tooltip message="Imediato" triggerClassName="max-w-full w-1/2">
									<div
										className={twMerge(
											'flex cursor-pointer justify-center rounded border-[1px] border-neutral-500 bg-white p-2',
											field.value === 'immediate'
												? 'bg-neutral-0 text-white hover:bg-neutral-800'
												: 'text-neutral-0 hover:bg-neutral-50'
										)}
										onClick={() => {
											field.value === 'immediate'
												? field.onChange(undefined)
												: field.onChange('immediate');
										}}
									>
										<TbTruckDelivery size={25} />
									</div>
								</Tooltip>
							</div>
						</div>
					)}
				/>
				<Controller
					control={form.control}
					name="orderTypeId"
					render={({ field }) => (
						<div className="flex flex-col">
							<h1 className="text-sm font-bold text-neutral-800">Rota</h1>
							<div className="flex items-center gap-4">
								<Tooltip
									message="Rota fixa"
									triggerClassName="max-w-full w-1/2"
								>
									<div
										className={twMerge(
											'flex cursor-pointer justify-center rounded border-[1px] border-neutral-500 bg-white p-2 hover:bg-neutral-50',
											field.value === 1
												? 'bg-neutral-0 text-white hover:bg-neutral-800'
												: 'text-black hover:bg-neutral-50'
										)}
										onClick={() => {
											field.value === 1
												? field.onChange(undefined)
												: field.onChange(1);
										}}
									>
										<OrderTypeIcon type="1" size={25} />
									</div>
								</Tooltip>
								<Tooltip
									message="Rota livre"
									triggerClassName="max-w-full w-1/2"
								>
									<div
										className={twMerge(
											'flex cursor-pointer justify-center rounded border-[1px] border-neutral-500 bg-white p-2 hover:bg-neutral-50',
											field.value === 2
												? 'bg-neutral-0 text-white hover:bg-neutral-800'
												: 'text-black hover:bg-neutral-50'
										)}
										onClick={() => {
											field.value === 2
												? field.onChange(undefined)
												: field.onChange(2);
										}}
									>
										<OrderTypeIcon type="2" size={25} />
									</div>
								</Tooltip>
							</div>
						</div>
					)}
				/>
				<Controller
					name="categoryId"
					control={form.control}
					render={({ field }) => (
						<div className="flex flex-col">
							<h1 className="text-sm font-bold text-neutral-800">Veículos</h1>
							<div className="flex flex-wrap items-center gap-2 ">
								{categories?.data?.map((category: any) => (
									<Tooltip
										message={category.name}
										key={
											// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
											category.id
										}
									>
										<div
											onClick={() => {
												field.value === category.id
													? field.onChange(undefined)
													: field.onChange(category.id);
											}}
											className={twMerge(
												'cursor-pointer rounded border-[1px] border-neutral-500 bg-white p-1',
												field.value === category.id
													? 'bg-neutral-0 text-white hover:bg-neutral-800'
													: 'text-neutral-0 hover:bg-yellow-500'
											)}
										>
											<VehicleIcon
												/* @ts-expect-error */
												name={oldIcons[category.icon]}
												size={46}
											/>
										</div>
									</Tooltip>
								))}
							</div>
						</div>
					)}
				/>
			</FiltersTab>
			<WhiteContainer>
				<Table<OrderResponseApi>
					params={params}
					onParamsChange={(params) => {
						setParams(params);
					}}
					data={orders?.data.data || []}
					total={orders?.data.meta.pagination.total || 0}
					isLoading={isFetching}
					columns={[
						{
							Header: 'Nº do frete',
							accessor: 'id',
							width: '0%',
							Cell: ({ row }) => {
								const {
									id,
									searchOnlyFavoriteDrivers,
									status,
									incident,
									cancellations,
									integrationOrder,
								} = row.original;

								const canceledByClient = cancellations?.some((cancellation) =>
									appState.clientCancellationsIds.includes(
										cancellation.cancelReasonId
									)
								);

								return (
									<div className="flex flex-col">
										<div className="flex gap-1">
											{searchOnlyFavoriteDrivers && (
												<Tooltip
													message="Somente motoristas favoritos"
													triggerClassName="self-start"
												>
													<AiFillStar
														className="text-yellow-submenu"
														size={22}
													/>
												</Tooltip>
											)}
											{integrationOrder && (
												<Tooltip
													message={`Pedido de integração ${
														integrationOrder?.displayId
															? `- ${integrationOrder?.displayId ?? ''}`
															: ''
													}`}
													triggerClassName="text-neutral-700 flex gap-1 items-center"
												>
													<BsFillInfoCircleFill size={16} />
													<p className="max-w-[5rem] truncate">
														{integrationOrder?.displayId}
													</p>
												</Tooltip>
											)}
										</div>
										<div className="flex items-center gap-2 text-lg font-bold text-orange">
											#{String(id).padStart(5, '0')}
										</div>
										<StatusTooltip
											order={row.original}
											triggerClassName="self-start"
										>
											<div className="flex flex-col">
												{canceledByClient && (
													<div className="flex gap-1 text-xs text-terracota-0">
														<BsExclamationOctagonFill size={14} /> Cancelado
													</div>
												)}
												{incident && (
													<div className="flex gap-1 text-xs text-terracota-0">
														<FaExclamationCircle size={14} /> Incidente
													</div>
												)}
												{!canceledByClient && !incident && (
													<span className="text-xs text-neutral-600">
														{status.name}
													</span>
												)}
											</div>
										</StatusTooltip>
									</div>
								);
							},
						},
						{
							Header: 'Solicitação',
							accessor: 'requestDate',
							Cell: ({ value }) => {
								return (
									<div className="flex flex-col text-sm">
										{value ? (
											<p>{dayjs(value).format('DD/MM/YYYY HH:mm')}</p>
										) : (
											<p className="text-neutral-900">-</p>
										)}
										<p className="text-xs text-neutral-500">
											Data da solicitação
										</p>
									</div>
								);
							},
						},
						{
							accessor: 'orderDate',
							Header: 'Data do frete',
							Cell: ({ row }) => {
								const { orderDate, scheduleDate } = row.original;
								const date = useMemo(
									() => dayjs(orderDate).format('DD/MM/YYYY HH:mm'),
									[orderDate]
								);

								const schedule = useMemo(
									() => dayjs(scheduleDate).format('DD/MM/YYYY HH:mm'),
									[scheduleDate]
								);

								return (
									<div className="flex items-center gap-1">
										{scheduleDate ? (
											<TbClock size={30} />
										) : (
											<TbTruckDelivery size={30} />
										)}
										<div className="flex flex-col">
											<span className=" text-neutral-800">
												{scheduleDate ? 'Agendado' : 'Imediato'}
											</span>
											<span className="text-sm text-neutral-500">
												{scheduleDate ? schedule : date}
											</span>
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'completionPrevisionDate',
							Header: 'Prev. Término',
							Cell: ({ value }) => {
								const date = value
									? dayjs(value).format('DD/MM/YYYY HH:mm')
									: '-';
								return (
									<div className="flex flex-col text-sm">
										<span>{date}</span>
										<span className="text-xs text-neutral-500">
											Previsão de término
										</span>
									</div>
								);
							},
						},
						{
							accessor: 'completionDate',
							Header: 'Conclusão',
							Cell: ({ value }) => {
								const date = value
									? dayjs(value).format('DD/MM/YYYY HH:mm')
									: '-';
								return (
									<div className="flex flex-col text-sm">
										<span>{date}</span>
										<span className="text-xs text-neutral-500">Conclusão</span>
									</div>
								);
							},
						},
						{
							accessor: 'requesterCompany',
							Header: 'Cliente',
							Cell: ({ row }) => (
								<ClientDropdown
									orderId={row.original.id}
									companyAvatar={row.original.requesterCompany?.avatar}
									companyId={row.original.requesterCompany?.id}
									companyName={row.original.requesterCompany?.companyName}
									companyPhone={row.original.requesterCompany?.phone}
									companyTags={row.original.requesterCompany?.tags}
									companyTradingName={
										row.original.requesterCompany?.tradingName
									}
									isFirstRequest={row.original.isFirstRequest}
									userId={row.original.requester?.id}
									userAvatar={row.original.requester?.avatar}
									userCellphone={row.original.requester?.cellphone}
									userName={row.original.requester?.name}
									userPhone={row.original.requester?.phone}
								/>
							),
						},
						{
							accessor: 'driver',
							Header: 'Fretista',
							Cell: ({ row }) => <DriverDropdown order={row.original} />,
						},
						{
							accessor: 'categoryId',
							Header: 'Veículo',
							Cell: ({ row }) => (
								<CategoryType
									category={row.original.category}
									categoryType={row.original.categoryType}
									isFavoriteDriver={row.original.isFavoriteDriver}
									defrauded={row.original.defrauded}
								/>
							),
						},
						{
							accessor: 'orderTypeId',
							Header: 'Tipo',
							Cell: ({ row }) => {
								return <Distance order={row.original} />;
							},
						},
						{
							accessor: 'created_at',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => {
								const { original } = row;

								const actions: Array<Actions<typeof original> | undefined> = [
									{
										icon: <TbListDetails size={16} />,
										label: 'Detalhes',
										href: `/pedidos/${original.id}`,
									},
									row.original.statusId === appState.orderStatus.Finalizado.id
										? {
												icon: <TbReload size={18} />,
												label: 'Reabrir',
												onAction: (data) => {
													alert.onCustom({
														title: 'Reabrir pedido',
														message: `Deseja mesmo reabrir o frete #${data?.id}?`,
														confirm: {
															label: 'Sim',
															onClick: async () => {
																try {
																	await api.put(`/orders/${data?.id}/reopen`);
																	toast.success(
																		<>
																			<h1 className="font-semibold">
																				Frete #{data.id}
																			</h1>
																			<p>O pedido foi reaberto</p>
																		</>
																	);
																	void refetch();
																} catch (err) {
																	toast.error('Erro ao reabrir pedido!');
																}
															},
														},
													});
												},
										  }
										: undefined,
									{
										icon: 'copy',
										label: 'Duplicar',
										// eslint-disable-next-line @typescript-eslint/no-misused-promises
										onAction: async (data) => {
											alert.onCustom({
												title: 'Duplicar pedido',
												message: `Deseja duplicar o frete #${data?.id}?`,
												confirm: {
													label: 'Sim',
													onClick: async () => {
														try {
															const dataToCreate =
																RequestFreightSchema.parse(data);

															const response = await api.post('/orders', {
																...dataToCreate,
																requesterCompanyId: data?.requesterCompanyId,
																scheduleDate: data?.scheduleDate
																	? dayjs().toISOString()
																	: undefined,
																statusId: 1,
															});
															if (response?.data.id)
																navigate(
																	`/pedidos/editar/${
																		response?.data.id as string
																	}?duplicatedOrder=true`
																);
															toast.success('Frete duplicado com sucesso!');
														} catch (err) {
															toast.error('Erro ao duplicar frete!');
														}
													},
												},
											});
										},
									},
									{
										icon: 'edit',
										label: 'Editar',
										href: `/pedidos/editar/${original.id}`,
									},
									{
										icon: <TbMap2 size={18} />,
										label: 'Acompanhar',
										href: `/pedidos/acompanhar/${original.id}`,
									},
									canSendToPool(row.original)
										? {
												icon: <TbArrowBackUp size={18} />,
												label: 'Enviar para pool',
												// eslint-disable-next-line @typescript-eslint/no-misused-promises
												onAction: async (data): Promise<void> => {
													alert.onCustom({
														title: 'Enviar para pool',
														message: 'Deseja enviar o frete para a pool?',
														confirm: {
															label: 'Sim',
															onClick: async () => {
																try {
																	await api.put(
																		`/orders/${data.id}/backToPool`
																	);
																	toast.success('Frete enviado para a pool!');
																} catch (err) {
																	toast.error('Erro ao enviar para a pool!');
																}
															},
														},
													});
												},
										  }
										: undefined,
									canCancelOrder(row.original)
										? {
												icon: <ImBlocked size={16} />,
												label: 'Cancelar',
												// eslint-disable-next-line @typescript-eslint/no-misused-promises
												onAction: async (data) => {
													setCancelModalReason(true);
													setOrderId(data.id);
												},
										  }
										: undefined,
								];

								return (
									<TableActions
										row={row}
										actions={
											actions.filter((action) => action) as Array<
												Actions<typeof original>
											>
										}
									/>
								);
							},
						},
					]}
					disabledSort={{
						requesterCompany: true,
						completionPrevisionDate: true,
						driver: true,
						created_at: true,
					}}
				/>
			</WhiteContainer>

			<CancelOrderModal
				open={cancelModalReason}
				setOpen={setCancelModalReason}
				orderId={orderId}
			/>

			<SelectGeofence />
		</>
	);
};

export default OrdersPage;
