import { Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { IoCloseSharp } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';

interface ModalProps {
	triggerButton?: React.ReactNode;
	children: React.ReactNode;
	description?: string;
	title?: string;
	titleClassName?: string;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	className?: string;
	onClose?: () => void;
	headless?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
	open,
	setOpen,
	triggerButton,
	description,
	title,
	titleClassName,
	className,
	children,
	onClose,
	headless,
}) => {
	return (
		<Dialog.Root open={open} onOpenChange={setOpen}>
			<Dialog.Trigger asChild>{triggerButton}</Dialog.Trigger>
			<Dialog.Portal forceMount>
				<Transition.Root show={open}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-50"
						leave="ease-in duration-200"
						leaveFrom="opacity-50"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 z-40 bg-neutral-0 opacity-50" />
					</Transition.Child>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Content
							onCloseAutoFocus={onClose}
							className={twMerge(
								'fixed left-1/2 top-1/2 z-40 max-h-[85vh] w-11/12 max-w-2xl -translate-x-1/2 -translate-y-1/2 overflow-y-auto rounded-md bg-white shadow outline-none',
								className
							)}
						>
							<div
								className={twMerge(
									'rounded-t bg-yellow-500 bg-cover',
									titleClassName,
									headless && 'hidden'
								)}
							>
								<Dialog.Title className="p-4 text-xl font-bold text-neutral-900">
									{title}
								</Dialog.Title>
								<div className="h-0.5 w-full rounded bg-neutral-600" />
							</div>

							<div className="flex-1 p-4">
								{description && (
									<Dialog.Description className="mb-2 text-neutral-500 md:mb-4">
										{description}
									</Dialog.Description>
								)}
								{children}
							</div>

							<Dialog.Close asChild>
								<button className="absolute right-2 top-2 inline-flex w-auto items-center rounded-full p-1 text-neutral-900 focus:outline-none hover:text-neutral-800">
									<IoCloseSharp size={32} />
								</button>
							</Dialog.Close>
						</Dialog.Content>
					</Transition.Child>
				</Transition.Root>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

interface CloseModalProps {
	children: React.ReactNode;
}

export const CloseModal: React.FC<CloseModalProps> = ({ children }) => {
	return <Dialog.Close asChild>{children}</Dialog.Close>;
};
