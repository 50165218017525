import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useQuery } from '@tanstack/react-query';
import { BsWhatsapp } from 'react-icons/bs';
import { TbHeartFilled } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';
import { phoneMask, removePhoneMask } from '../../../helpers/mask/phoneMask';
import { useCopy } from '../../../hooks/useCopy';
import useUserState from '../../../services/angular/angularUserState';
import { api } from '../../../services/angular/axios';
// import { OrderResponseApi } from '../../../services/angular/types/Order';
import { Avatar } from '../../Avatar';
import { Tooltip } from '../../Tooltip';
import { AdminTags } from '../AdminTags';
import { memo } from 'react';
import { isEqual } from 'lodash';
import { Tag } from '../../../services/angular/types/Order';
import { FiExternalLink } from 'react-icons/fi';
import { Link } from 'react-router-dom';

interface ClientDropdownProps {
	orderId: number;
	companyId: number;
	companyName: string;
	companyTradingName?: string;
	companyAvatar: string;
	companyPhone: string;
	userId?: number;
	userName?: string;
	userAvatar: string;
	userPhone: string;
	userCellphone: string;
	companyTags?: Tag[];
	isFirstRequest: boolean;
}

export const ClientDropdown = memo(function ClientDropdown({
	orderId,
	companyName,
	companyId,
	companyTradingName,
	companyAvatar,
	companyPhone,
	userId,
	userName,
	userAvatar,
	userPhone,
	userCellphone,
	companyTags,
	isFirstRequest,
}: ClientDropdownProps) {
	const copy = useCopy();
	const role = useUserState((state) => state.userRole);

	const {
		data: requesterAvatar,
		refetch: refetchAvatar,
		isFetched,
	} = useQuery(
		['user-avatar', userAvatar],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: userAvatar,
				},
			});
		},
		{
			enabled: false,
		}
	);

	const {
		data: requesterCompanyAvatar,
		refetch: refetchCompanyAvatar,
		isFetched: isCompanyFetched,
	} = useQuery(
		['company-avatar', companyAvatar],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: companyAvatar,
				},
			});
		},
		{
			enabled: false,
		}
	);

	return (
		<DropdownMenu.Root
			onOpenChange={() => {
				if (!isFetched) void refetchAvatar();

				if (!isCompanyFetched) void refetchCompanyAvatar();
			}}
		>
			<DropdownMenu.Trigger className="group w-full rounded focus:outline-none">
				<AdminTags
					tags={role?.name !== 'user' ? companyTags : []}
					triggerClassName="flex w-full flex-col items-start cursor-pointer"
				>
					<div
						className={twMerge(
							'line-clamp-3 text-left text-sm font-medium leading-none group-hover:underline',
							role?.name !== 'user' && isFirstRequest && 'text-blue'
						)}
					>
						{companyTradingName || companyName}
					</div>
					<p className="text-left text-xs text-neutral-500">Cliente</p>
					{role?.name !== 'user' && isFirstRequest && (
						<Tooltip
							message="Primeiro pedido"
							triggerClassName="text-blue self-start"
						>
							<TbHeartFilled size={16} />
						</Tooltip>
					)}
				</AdminTags>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content className="ml-2 w-[calc(100vw-32px)] min-w-[256px] rounded bg-white p-4 text-neutral-0 shadow shadow-neutral-0 md:ml-0 md:w-auto">
					<div className="flex items-end justify-between font-bold">
						<h1 className="text-lg ">Cliente</h1>
						<p className="text-lg text-orange">#{orderId}</p>
					</div>
					<hr className="my-2 text-neutral-500" />
					<div className="flex items-center gap-4">
						<span className="h-16 w-16 ">
							<Avatar
								src={requesterCompanyAvatar?.data.url}
								avatarProps={{
									className: 'h-16 w-16',
								}}
							/>
						</span>
						<div className="flex flex-col">
							<div className="flex max-w-xs items-center justify-between gap-2 font-bold">
								{companyTradingName || companyName}
								<Link
									className="cursor-pointer hover:scale-125"
									to={`/empresas/${companyId}`}
								>
									<FiExternalLink />
								</Link>
							</div>
							<span
								className="cursor-copy"
								onClick={() => {
									void copy(removePhoneMask(companyPhone, '+55'));
								}}
							>
								{phoneMask(removePhoneMask(companyPhone))}
							</span>
						</div>
					</div>
					<hr className="my-2 text-neutral-500" />
					<div className="flex items-center gap-4">
						<span className="h-16 w-16 ">
							<Avatar
								src={requesterAvatar?.data.url}
								avatarProps={{
									className: 'h-16 w-16',
								}}
							/>
						</span>
						<div className="flex flex-col">
							<div className="flex max-w-xs items-center justify-between gap-2 font-bold">
								{userName || '-'}
								{userId && (
									<Link
										className="cursor-pointer hover:scale-125"
										to={`/usuarios/${userId}`}
									>
										<FiExternalLink />
									</Link>
								)}
							</div>
							{(userPhone || userCellphone) && (
								<span className="flex items-center gap-4 text-sm">
									<span
										className="cursor-copy"
										onClick={() => {
											void copy(removePhoneMask(userPhone || userCellphone));
										}}
									>
										{phoneMask(removePhoneMask(userPhone || userCellphone))}
									</span>
									<span className="text-green">
										<BsWhatsapp
											onClick={() => {
												window.open(
													`https://api.whatsapp.com/send?phone=55${userCellphone.replace(
														/\D/g,
														''
													)}&text=${encodeURI(
														`Olá ${userName ? userName.split(' ')[0] : ''}`
													)}`,
													'_blank'
												);
											}}
											className="cursor-pointer text-green"
											size={16}
										/>
									</span>
								</span>
							)}
						</div>
					</div>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
},
isEqual);
