import { Location, OrderResponseApi } from '../services/angular/types/Order';
import { appState } from '../store/appState';

export const allowUndoCheckout = (
	order: OrderResponseApi,
	location: Location
) => {
	if (!isInCourse(order)) return false;

	return (
		(isStaticRoute(order) && hasCheckout(location)) ||
		(isFreeRoute(order) && isStartLocation(location) && hasCheckout(location))
	);
};

export const allowUndoCheckin = (
	order: OrderResponseApi,
	location: Location
) => {
	if (!isInCourse(order)) return false;

	if (isFreeRoute(order))
		return (
			isStartLocation(location) &&
			hasCheckin(location) &&
			!hasCheckout(location)
		);

	return isStaticRoute(order) && hasCheckin(location) && !hasCheckout(location);
};

export const allowCheckin = (order: OrderResponseApi, location: Location) => {
	if (
		isFreeRoute(order) ||
		order.statusId === appState.orderStatus.Agendado.id
	) {
		return isStartLocation(location) && !hasCheckin(location);
	}

	if (!isInCourse(order)) return false;

	return isStaticRoute(order) && !hasCheckin(location);
};

export const hasCheckin = (location: Location): boolean => {
	return !!location.checkinDate;
};

export const hasCheckout = (location: Location): boolean => {
	return !!location.checkoutDate || !!location.reasonDate;
};

export const isStartLocation = (location: Location): boolean =>
	!!location && location.sequence === 0;

export const canBeDeleted = (order: OrderResponseApi, location: Location) => {
	return (
		isFreeRoute(order) &&
		isInCourse(order) &&
		hasCheckout(location) &&
		!isStartLocation(location)
	);
};

export const isScheduled = (order: OrderResponseApi): boolean =>
	order.statusId === appState.orderStatus.Agendado.id;

export const isInCourse = (order: OrderResponseApi) => {
	return order.statusId === appState.orderStatus['Em andamento'].id;
};

export const isFreeRoute = (order: OrderResponseApi): boolean => {
	return order.orderTypeId === Number(appState.orderTypes['Rota livre'].value);
};

export const isStaticRoute = (order: OrderResponseApi): boolean => {
	return order.orderTypeId === Number(appState.orderTypes['Rota fixa'].value);
};

export const isPending = (location: Location): boolean => {
	return !hasCheckin(location) || !hasCheckout(location);
};

export const isCompleted = (location: Location): boolean => {
	return !isPending(location);
};

export const getPreviousCompletedLocation = (order: OrderResponseApi) => {
	return order.locations
		.sort((a, b) => a.sequence - b.sequence)
		.reverse()
		.find(isCompleted);
};

export const getCurrentPendingLocation = (order: OrderResponseApi) => {
	return order.locations
		.sort((a, b) => a.sequence - b.sequence)
		.find(isPending);
};

export const canBeFinished = (order: OrderResponseApi): boolean => {
	return (
		isFreeRoute(order) &&
		isInCourse(order) &&
		order.locations.length >= 2 &&
		order.locations.every((l) => isCompleted(l))
	);
};

export const canSendToPool = (order?: OrderResponseApi) => {
	if (!order) return false;

	if (order.statusId === appState.orderStatus['Aguardando motorista'].id)
		return false;

	return (
		!!order?.locations?.length &&
		!hasCheckout(order?.locations.sort((a, b) => a.sequence - b.sequence)[0])
	);
};

export const canEditStopsGoal = (order?: OrderResponseApi) => {
	if (!order) return false;

	if (
		!isFreeRoute(order) ||
		order.statusId === appState.orderStatus.Cancelado.id ||
		order.statusId === appState.orderStatus.Rascunho.id ||
		order.statusId === appState.orderStatus.Finalizado.id
	)
		return false;

	return true;
};
