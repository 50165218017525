import { toast } from 'react-toastify';
import {
	FormButtons,
	Input,
	Select,
	useZodForm,
} from '../../../../../components/FormElements';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { cpfMask } from '../../../../../helpers/mask/cpfMask';

import useUserState from '../../../../../services/angular/angularUserState';
import { api } from '../../../../../services/angular/axios';
import { selectBanks } from '../../../../../store/bankCodes';
import { AddReceiptFormSchema } from '../schemas/AddReceiptFormSchema';

interface AddReceiptFormProps {
	setAddReceiptForm: (value: boolean) => void;
}

const AddReceiptForm = ({ setAddReceiptForm }: AddReceiptFormProps) => {
	const company = useUserState((state) => state.company);
	const form = useZodForm({
		schema: AddReceiptFormSchema,
	});

	return (
		<div className="flex w-1/2 flex-col gap-4">
			<WhiteContainer className="flex  flex-col gap-4">
				<Input
					label="Título para referencia"
					placeholder='"Ex: Sr./Sra."'
					{...form.register('title')}
					errorMessage={form.formState.errors.title?.message}
				/>
				<div className="flex items-center gap-4">
					<Input
						label="Nome do titular"
						errorMessage={form.formState.errors.accountOwner?.message}
						{...form.register('accountOwner')}
					/>
					<Input
						className="w-2/5"
						label="CPF"
						{...form.register('accountOwnerCpf', {
							onChange: (event) => {
								event.target.value = cpfMask(event.target.value);
							},
						})}
						errorMessage={form.formState.errors.accountOwnerCpf?.message}
					/>
				</div>
				<div className="flex items-center gap-4">
					<Select
						label="Número do banco"
						errorMessage={form.formState.errors.bankNumber?.message}
						controller={{
							control: form.control,
							name: 'bankNumber',
						}}
						options={selectBanks}
					/>

					<Input
						label="Agência"
						maxLength={5}
						className="w-2/5"
						type="number"
						{...form.register('agency', {
							onChange: (e) => {
								if (e.target.value.length > 4) {
									e.target.value = e.target.value.slice(0, 4);
								}
							},
						})}
						errorMessage={form.formState.errors.agency?.message}
					/>
				</div>
				<div className="flex items-center gap-4">
					<Input
						label="Conta"
						className="w-3/5"
						{...form.register('account', {
							onChange: (e) => {
								if (e.target.value.length > 12) {
									e.target.value = e.target.value.slice(0, 12);
								}
							},
						})}
						errorMessage={form.formState.errors.account?.message}
					/>
					<Input
						className="w-1/5"
						label="Digito"
						{...form.register('digit', {
							onChange: (e) => {
								if (e.target.value.length > 1) {
									e.target.value = e.target.value.slice(0, 1);
								}
							},
						})}
						errorMessage={form.formState.errors.digit?.message}
					/>
				</div>
			</WhiteContainer>
			<FormButtons
				onCancel={() => {
					form.reset();
					setAddReceiptForm(false);
				}}
				onSave={() => {
					void form.handleSubmit(async (data) => {
						try {
							await api.post(
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								`/companies/${company?.id}/receipt-accounts`,
								data
							);
							toast.success('Meio de recebimento cadastrado com sucesso');
							setAddReceiptForm(false);
						} catch (error) {
							toast.error('Erro ao cadastrar meio de recebimento');
						}
					})();
				}}
			/>
		</div>
	);
};

export default AddReceiptForm;
