import React, { useMemo } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';
import { OrderResponseApi } from '../../../services/angular/types/Order';
import { appState, useAppState } from '../../../store/appState';
import { Tooltip } from '../../Tooltip';
import { EllipsisLoading } from '../../EllipsisLoading';

interface StatusTooltipProps {
	order?: OrderResponseApi;
	children?: React.ReactNode;
	className?: string;
	triggerClassName?: string;
}

export const StatusTooltip = ({
	order,
	children,
	className,
	triggerClassName,
}: StatusTooltipProps) => {
	const cancelReasons = useAppState((state) => state.cancelReasons);

	const cancellations = useMemo(() => {
		if (!order?.cancellations?.length && !order?.incident) return undefined;
		return (
			<div className="flex flex-col gap-2 p-1 text-sm">
				{order?.incident?.newOrderId && (
					<>
						<div className="flex flex-row gap-1 text-base">
							<span className="font-bold">Novo frete:</span>
							<span>#{order?.incident.newOrderId}</span>
						</div>
						<hr className="w-full text-neutral-300" />
					</>
				)}
				{order?.incident && (
					<div className="flex flex-row gap-1">
						<span className="font-bold">Motivo do incidente:</span>
						<span>{order?.incident.reason}</span>
					</div>
				)}
				{!!order?.cancellations?.length && (
					<span>
						<span className="font-bold">Motivo do cancelamento:</span>
						{order?.cancellations.map((cancellation) => {
							const message =
								cancelReasons?.find(
									(reason) => reason.id === cancellation.cancelReasonId
								)?.description || 'Não informado';

							return (
								<div
									key={cancellation.id}
									className="flex flex-row gap-1 indent-4"
								>
									{message}
									{cancellation?.observations && (
										<span className="text-sm italic">
											Observação: {cancellation.observations}
										</span>
									)}
								</div>
							);
						})}
					</span>
				)}
			</div>
		);
	}, [order?.cancellations]);

	return (
		<Tooltip
			triggerClassName={triggerClassName}
			className="max-w-full font-normal"
			message={cancellations}
		>
			{children ? (
				<>{children}</>
			) : (
				<>
					<div
						className={twMerge(
							'flex w-fit items-center justify-center rounded px-3 py-1.5 font-bold text-white',
							order?.incident ||
								order?.statusId === appState.orderStatus.Cancelado.id
								? 'bg-terracota-0'
								: order?.statusId === appState.orderStatus.Rascunho.id
								? 'bg-neutral-600'
								: order?.statusId ===
										appState.orderStatus['Aguardando motorista'].id ||
								  order?.statusId === appState.orderStatus.Agendado.id
								? 'bg-orange'
								: order?.statusId === appState.orderStatus['Em andamento'].id
								? 'bg-blue'
								: order?.statusId === appState.orderStatus.Finalizado.id
								? 'bg-green'
								: 'bg-neutral-0',
							className
						)}
					>
						{order?.incident ? (
							<div className="flex flex-row items-center gap-1">
								<FaExclamationCircle /> Incidente
								{order.incident.newOrderId && (
									<span className="font-bold">
										#{order.incident.newOrderId}
									</span>
								)}
							</div>
						) : (
							<>
								FRETE #{order?.id}: {order?.status?.name}
								{(order?.statusId === appState.orderStatus['Aguardando motorista'].id) && (
									<EllipsisLoading
										className="mt-0 w-16"
										dotClassName="bg-white w-2.5 h-2.5"
									/>
								)}
							</>
						)}
					</div>
				</>
			)}
		</Tooltip>
	);
};
