export const banks = [
	{
		ispb: '00000000',
		name: 'BCO DO BRASIL S.A.',
		code: 1,
		fullName: 'Banco do Brasil S.A.',
	},
	{
		ispb: '00000208',
		name: 'BRB - BCO DE BRASILIA S.A.',
		code: 70,
		fullName: 'BRB - BANCO DE BRASILIA S.A.',
	},
	{
		ispb: '00038121',
		name: 'Selic',
		code: null,
		fullName: 'Banco Central do Brasil - Selic',
	},
	{
		ispb: '00038166',
		name: 'Bacen',
		code: null,
		fullName: 'Banco Central do Brasil',
	},
	{
		ispb: '00122327',
		name: 'SANTINVEST S.A. - CFI',
		code: 539,
		fullName: 'SANTINVEST S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTOS',
	},
	{
		ispb: '00204963',
		name: 'CCR SEARA',
		code: 430,
		fullName: 'COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA',
	},
	{
		ispb: '00250699',
		name: 'AGK CC S.A.',
		code: 272,
		fullName: 'AGK CORRETORA DE CAMBIO S.A.',
	},
	{
		ispb: '00315557',
		name: 'CONF NAC COOP CENTRAIS UNICRED',
		code: 136,
		fullName:
			'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS UNICRED LTDA. - UNICRED DO BRASI',
	},
	{
		ispb: '00329598',
		name: 'ÍNDIGO INVESTIMENTOS DTVM LTDA.',
		code: 407,
		fullName:
			'ÍNDIGO INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
	},
	{
		ispb: '00360305',
		name: 'CAIXA ECONOMICA FEDERAL',
		code: 104,
		fullName: 'CAIXA ECONOMICA FEDERAL',
	},
	{
		ispb: '00394460',
		name: 'STN',
		code: null,
		fullName: 'Secretaria do Tesouro Nacional - STN',
	},
	{
		ispb: '00416968',
		name: 'BANCO INTER',
		code: 77,
		fullName: 'Banco Inter S.A.',
	},
	{
		ispb: '00460065',
		name: 'COLUNA S.A. DTVM',
		code: 423,
		fullName: 'COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS',
	},
	{
		ispb: '00517645',
		name: 'BCO RIBEIRAO PRETO S.A.',
		code: 741,
		fullName: 'BANCO RIBEIRAO PRETO S.A.',
	},
	{
		ispb: '00556603',
		name: 'BANCO BARI S.A.',
		code: 330,
		fullName: 'BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.',
	},
	{
		ispb: '00558456',
		name: 'BCO CETELEM S.A.',
		code: 739,
		fullName: 'Banco Cetelem S.A.',
	},
	{
		ispb: '00714671',
		name: 'EWALLY IP S.A.',
		code: 534,
		fullName: 'EWALLY INSTITUIÇÃO DE PAGAMENTO S.A.',
	},
	{
		ispb: '00795423',
		name: 'BANCO SEMEAR',
		code: 743,
		fullName: 'Banco Semear S.A.',
	},
	{
		ispb: '00806535',
		name: 'PLANNER CV S.A.',
		code: 100,
		fullName: 'Planner Corretora de Valores S.A.',
	},
	{
		ispb: '00954288',
		name: 'FDO GARANTIDOR CRÉDITOS',
		code: 541,
		fullName: 'FUNDO GARANTIDOR DE CREDITOS - FGC',
	},
	{
		ispb: '00997185',
		name: 'BCO B3 S.A.',
		code: 96,
		fullName: 'Banco B3 S.A.',
	},
	{
		ispb: '01023570',
		name: 'BCO RABOBANK INTL BRASIL S.A.',
		code: 747,
		fullName: 'Banco Rabobank International Brasil S.A.',
	},
	{
		ispb: '01027058',
		name: 'CIELO IP S.A.',
		code: 362,
		fullName: 'CIELO S.A. - INSTITUIÇÃO DE PAGAMENTO',
	},
	{
		ispb: '01073966',
		name: 'CCR DE ABELARDO LUZ',
		code: 322,
		fullName:
			'Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz',
	},
	{
		ispb: '01181521',
		name: 'BCO COOPERATIVO SICREDI S.A.',
		code: 748,
		fullName: 'BANCO COOPERATIVO SICREDI S.A.',
	},
	{
		ispb: '01330387',
		name: 'CREHNOR LARANJEIRAS',
		code: 350,
		fullName:
			'COOPERATIVA DE CRÉDITO RURAL DE PEQUENOS AGRICULTORES E DA REFORMA AGRÁRIA DO CE',
	},
	{
		ispb: '01522368',
		name: 'BCO BNP PARIBAS BRASIL S A',
		code: 752,
		fullName: 'Banco BNP Paribas Brasil S.A.',
	},
	{
		ispb: '01658426',
		name: 'CECM COOPERFORTE',
		code: 379,
		fullName:
			'COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE FUNCIONÁRIOS DE INSTITU',
	},
	{
		ispb: '01701201',
		name: 'KIRTON BANK',
		code: 399,
		fullName: 'Kirton Bank S.A. - Banco Múltiplo',
	},
	{
		ispb: '01852137',
		name: 'BCO BRASILEIRO DE CRÉDITO S.A.',
		code: 378,
		fullName: 'BANCO BRASILEIRO DE CRÉDITO SOCIEDADE ANÔNIMA',
	},
	{
		ispb: '01858774',
		name: 'BCO BV S.A.',
		code: 413,
		fullName: 'BANCO BV S.A.',
	},
	{
		ispb: '02038232',
		name: 'BANCO SICOOB S.A.',
		code: 756,
		fullName: 'BANCO COOPERATIVO SICOOB S.A. - BANCO SICOOB',
	},
	{
		ispb: '02276653',
		name: 'TRINUS CAPITAL DTVM',
		code: 360,
		fullName:
			'TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
	},
	{
		ispb: '02318507',
		name: 'BCO KEB HANA DO BRASIL S.A.',
		code: 757,
		fullName: 'BANCO KEB HANA DO BRASIL S.A.',
	},
	{
		ispb: '02332886',
		name: 'XP INVESTIMENTOS CCTVM S/A',
		code: 102,
		fullName:
			'XP INVESTIMENTOS CORRETORA DE CÂMBIO,TÍTULOS E VALORES MOBILIÁRIOS S/A',
	},
	{
		ispb: '02398976',
		name: 'SISPRIME DO BRASIL - COOP',
		code: 84,
		fullName: 'SISPRIME DO BRASIL - COOPERATIVA DE CRÉDITO',
	},
	{
		ispb: '02685483',
		name: 'CM CAPITAL MARKETS CCTVM LTDA',
		code: 180,
		fullName:
			'CM CAPITAL MARKETS CORRETORA DE CÂMBIO, TÍTULOS E VALORES MOBILIÁRIOS LTDA',
	},
	{
		ispb: '02801938',
		name: 'BCO MORGAN STANLEY S.A.',
		code: 66,
		fullName: 'BANCO MORGAN STANLEY S.A.',
	},
	{
		ispb: '02819125',
		name: 'UBS BRASIL CCTVM S.A.',
		code: 15,
		fullName:
			'UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
	},
	{
		ispb: '02992317',
		name: 'TREVISO CC S.A.',
		code: 143,
		fullName: 'Treviso Corretora de Câmbio S.A.',
	},
	{
		ispb: '02992335',
		name: 'CIP SA Siloc',
		code: null,
		fullName: 'CIP S.A',
	},
	{
		ispb: '03012230',
		name: 'HIPERCARD BM S.A.',
		code: 62,
		fullName: 'Hipercard Banco Múltiplo S.A.',
	},
	{
		ispb: '03017677',
		name: 'BCO. J.SAFRA S.A.',
		code: 74,
		fullName: 'Banco J. Safra S.A.',
	},
	{
		ispb: '03046391',
		name: 'UNIPRIME COOPCENTRAL LTDA.',
		code: 99,
		fullName:
			'UNIPRIME CENTRAL NACIONAL - CENTRAL NACIONAL DE COOPERATIVA DE CREDITO',
	},
	{
		ispb: '03215790',
		name: 'BCO TOYOTA DO BRASIL S.A.',
		code: 387,
		fullName: 'Banco Toyota do Brasil S.A.',
	},
	{
		ispb: '03311443',
		name: 'PARATI - CFI S.A.',
		code: 326,
		fullName: 'PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
	},
	{
		ispb: '03323840',
		name: 'BCO ALFA S.A.',
		code: 25,
		fullName: 'Banco Alfa S.A.',
	},
	{
		ispb: '03532415',
		name: 'BCO ABN AMRO S.A.',
		code: 75,
		fullName: 'Banco ABN Amro S.A.',
	},
	{
		ispb: '03609817',
		name: 'BCO CARGILL S.A.',
		code: 40,
		fullName: 'Banco Cargill S.A.',
	},
	{
		ispb: '03751794',
		name: 'TERRA INVESTIMENTOS DTVM',
		code: 307,
		fullName:
			'Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
	},
	{
		ispb: '03844699',
		name: 'CECM DOS TRAB.PORT. DA G.VITOR',
		code: 385,
		fullName:
			'COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS TRABALHADORES PORTUARIOS DA GRANDE V',
	},
	{
		ispb: '03881423',
		name: 'SOCINAL S.A. CFI',
		code: 425,
		fullName: 'SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
	},
	{
		ispb: '03973814',
		name: 'SERVICOOP',
		code: 190,
		fullName:
			'SERVICOOP - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS ESTADUAIS E MUNICIPAI',
	},
	{
		ispb: '04062902',
		name: 'OZ CORRETORA DE CÂMBIO S.A.',
		code: 296,
		fullName: 'OZ CORRETORA DE CÂMBIO S.A.',
	},
	{
		ispb: '04184779',
		name: 'BANCO BRADESCARD',
		code: 63,
		fullName: 'Banco Bradescard S.A.',
	},
	{
		ispb: '04257795',
		name: 'NOVA FUTURA CTVM LTDA.',
		code: 191,
		fullName: 'Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
	},
	{
		ispb: '04307598',
		name: 'FIDUCIA SCMEPP LTDA',
		code: 382,
		fullName:
			'FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L',
	},
	{
		ispb: '04332281',
		name: 'GOLDMAN SACHS DO BRASIL BM S.A',
		code: 64,
		fullName: 'GOLDMAN SACHS DO BRASIL BANCO MULTIPLO S.A.',
	},
	{
		ispb: '04391007',
		name: 'CAMARA INTERBANCARIA DE PAGAMENTOS - CIP',
		code: null,
		fullName: 'CIP S.A',
	},
	{
		ispb: '04546162',
		name: 'CCM SERV. PÚBLICOS SP',
		code: 459,
		fullName:
			'COOPERATIVA DE CRÉDITO MÚTUO DE SERVIDORES PÚBLICOS DO ESTADO DE SÃO PAULO - CRE',
	},
	{
		ispb: '04632856',
		name: 'CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.',
		code: 97,
		fullName: 'Credisis - Central de Cooperativas de Crédito Ltda.',
	},
	{
		ispb: '04715685',
		name: 'CCM DESP TRÂNS SC E RS',
		code: 16,
		fullName:
			'COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI',
	},
	{
		ispb: '04814563',
		name: 'BCO AFINZ S.A. - BM',
		code: 299,
		fullName: 'BANCO AFINZ S.A. - BANCO MÚLTIPLO',
	},
	{
		ispb: '04831810',
		name: 'CECM SERV PUBL PINHÃO',
		code: 471,
		fullName:
			'COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS SERVIDORES PUBLICOS DE PINHÃO - CRES',
	},
	{
		ispb: '04862600',
		name: 'PORTOSEG S.A. CFI',
		code: 468,
		fullName: 'PORTOSEG S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTO',
	},
	{
		ispb: '04866275',
		name: 'BANCO INBURSA',
		code: 12,
		fullName: 'Banco Inbursa S.A.',
	},
	{
		ispb: '04902979',
		name: 'BCO DA AMAZONIA S.A.',
		code: 3,
		fullName: 'BANCO DA AMAZONIA S.A.',
	},
	{
		ispb: '04913129',
		name: 'CONFIDENCE CC S.A.',
		code: 60,
		fullName: 'Confidence Corretora de Câmbio S.A.',
	},
	{
		ispb: '04913711',
		name: 'BCO DO EST. DO PA S.A.',
		code: 37,
		fullName: 'Banco do Estado do Pará S.A.',
	},
	{
		ispb: '05192316',
		name: 'VIA CERTA FINANCIADORA S.A. - CFI',
		code: 411,
		fullName:
			'Via Certa Financiadora S.A. - Crédito, Financiamento e Investimentos',
	},
	{
		ispb: '05351887',
		name: 'ZEMA CFI S/A',
		code: 359,
		fullName: 'ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A',
	},
	{
		ispb: '05442029',
		name: 'CASA CREDITO S.A. SCM',
		code: 159,
		fullName: 'Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
	},
	{
		ispb: '05463212',
		name: 'COOPCENTRAL AILOS',
		code: 85,
		fullName: 'Cooperativa Central de Crédito - Ailos',
	},
	{
		ispb: '05491616',
		name: 'COOP CREDITAG',
		code: 400,
		fullName:
			'COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DO CENTRO OESTE - CREDIT',
	},
	{
		ispb: '05676026',
		name: 'CREDIARE CFI S.A.',
		code: 429,
		fullName: 'Crediare S.A. - Crédito, financiamento e investimento',
	},
	{
		ispb: '05684234',
		name: 'PLANNER SOCIEDADE DE CRÉDITO DIRETO',
		code: 410,
		fullName: 'PLANNER SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '05790149',
		name: 'CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO',
		code: 114,
		fullName:
			'Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP',
	},
	{
		ispb: '05841967',
		name: 'CECM FABRIC CALÇADOS SAPIRANGA',
		code: 328,
		fullName:
			'COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES DE CALÇADOS DE SAPIRANGA',
	},
	{
		ispb: '06271464',
		name: 'BCO BBI S.A.',
		code: 36,
		fullName: 'Banco Bradesco BBI S.A.',
	},
	{
		ispb: '07138049',
		name: 'LIGA INVEST DTVM LTDA.',
		code: 469,
		fullName: 'LIGA INVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
	},
	{
		ispb: '07207996',
		name: 'BCO BRADESCO FINANC. S.A.',
		code: 394,
		fullName: 'Banco Bradesco Financiamentos S.A.',
	},
	{
		ispb: '07237373',
		name: 'BCO DO NORDESTE DO BRASIL S.A.',
		code: 4,
		fullName: 'Banco do Nordeste do Brasil S.A.',
	},
	{
		ispb: '07253654',
		name: 'HEDGE INVESTMENTS DTVM LTDA.',
		code: 458,
		fullName:
			'HEDGE INVESTMENTS DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
	},
	{
		ispb: '07450604',
		name: 'BCO CCB BRASIL S.A.',
		code: 320,
		fullName: 'China Construction Bank (Brasil) Banco Múltiplo S/A',
	},
	{
		ispb: '07512441',
		name: 'HS FINANCEIRA',
		code: 189,
		fullName: 'HS FINANCEIRA S/A CREDITO, FINANCIAMENTO E INVESTIMENTOS',
	},
	{
		ispb: '07652226',
		name: 'LECCA CFI S.A.',
		code: 105,
		fullName: 'Lecca Crédito, Financiamento e Investimento S/A',
	},
	{
		ispb: '07656500',
		name: 'BCO KDB BRASIL S.A.',
		code: 76,
		fullName: 'Banco KDB do Brasil S.A.',
	},
	{
		ispb: '07679404',
		name: 'BANCO TOPÁZIO S.A.',
		code: 82,
		fullName: 'BANCO TOPÁZIO S.A.',
	},
	{
		ispb: '07693858',
		name: 'HSCM SCMEPP LTDA.',
		code: 312,
		fullName:
			'HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LT',
	},
	{
		ispb: '07799277',
		name: 'VALOR SCD S.A.',
		code: 195,
		fullName: 'VALOR SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '07945233',
		name: 'POLOCRED SCMEPP LTDA.',
		code: 93,
		fullName:
			'PÓLOCRED   SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORT',
	},
	{
		ispb: '08240446',
		name: 'CCR DE IBIAM',
		code: 391,
		fullName: 'COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM',
	},
	{
		ispb: '08253539',
		name: 'CCR DE SÃO MIGUEL DO OESTE',
		code: 273,
		fullName:
			'Cooperativa de Crédito Rural de São Miguel do Oeste - Sulcredi/São Miguel',
	},
	{
		ispb: '08357240',
		name: 'BCO CSF S.A.',
		code: 368,
		fullName: 'Banco CSF S.A.',
	},
	{
		ispb: '08561701',
		name: 'PAGSEGURO INTERNET IP S.A.',
		code: 290,
		fullName: 'PAGSEGURO INTERNET INSTITUIÇÃO DE PAGAMENTO S.A.',
	},
	{
		ispb: '08609934',
		name: 'MONEYCORP BCO DE CÂMBIO S.A.',
		code: 259,
		fullName: 'MONEYCORP BANCO DE CÂMBIO S.A.',
	},
	{
		ispb: '08673569',
		name: 'F D GOLD DTVM LTDA',
		code: 395,
		fullName: "F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
	},
	{
		ispb: '09089356',
		name: 'EFÍ S.A. - IP',
		code: 364,
		fullName: 'EFÍ S.A. - INSTITUIÇÃO DE PAGAMENTO',
	},
	{
		ispb: '09105360',
		name: 'ICAP DO BRASIL CTVM LTDA.',
		code: 157,
		fullName: 'ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
	},
	{
		ispb: '09210106',
		name: 'SOCRED SA - SCMEPP',
		code: 183,
		fullName:
			'SOCRED S.A. - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO P',
	},
	{
		ispb: '09274232',
		name: 'STATE STREET BR S.A. BCO COMERCIAL',
		code: 14,
		fullName: 'STATE STREET BRASIL S.A. - BANCO COMERCIAL',
	},
	{
		ispb: '09313766',
		name: 'CARUANA SCFI',
		code: 130,
		fullName:
			'CARUANA S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
	},
	{
		ispb: '09464032',
		name: 'MIDWAY S.A. - SCFI',
		code: 358,
		fullName: 'MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
	},
	{
		ispb: '09512542',
		name: 'CODEPE CVC S.A.',
		code: 127,
		fullName: 'Codepe Corretora de Valores e Câmbio S.A.',
	},
	{
		ispb: '09516419',
		name: 'PICPAY BANK - BANCO MÚLTIPLO S.A',
		code: 79,
		fullName: 'PICPAY BANK - BANCO MÚLTIPLO S.A',
	},
	{
		ispb: '09526594',
		name: 'MASTER BI S.A.',
		code: 141,
		fullName: 'BANCO MASTER DE INVESTIMENTO S.A.',
	},
	{
		ispb: '09554480',
		name: 'SUPERDIGITAL I.P. S.A.',
		code: 340,
		fullName: 'SUPERDIGITAL INSTITUIÇÃO DE PAGAMENTO S.A.',
	},
	{
		ispb: '10264663',
		name: 'BANCOSEGURO S.A.',
		code: 81,
		fullName: 'BancoSeguro S.A.',
	},
	{
		ispb: '10371492',
		name: 'BCO YAMAHA MOTOR S.A.',
		code: 475,
		fullName: 'Banco Yamaha Motor do Brasil S.A.',
	},
	{
		ispb: '10398952',
		name: 'CRESOL CONFEDERAÇÃO',
		code: 133,
		fullName:
			'CONFEDERAÇÃO NACIONAL DAS COOPERATIVAS CENTRAIS DE CRÉDITO E ECONOMIA FAMILIAR E',
	},
	{
		ispb: '10573521',
		name: 'MERCADO PAGO IP LTDA.',
		code: 323,
		fullName: 'MERCADO PAGO INSTITUIÇÃO DE PAGAMENTO LTDA.',
	},
	{
		ispb: '10664513',
		name: 'BCO AGIBANK S.A.',
		code: 121,
		fullName: 'Banco Agibank S.A.',
	},
	{
		ispb: '10690848',
		name: 'BCO DA CHINA BRASIL S.A.',
		code: 83,
		fullName: 'Banco da China Brasil S.A.',
	},
	{
		ispb: '10853017',
		name: 'GET MONEY CC LTDA',
		code: 138,
		fullName: 'Get Money Corretora de Câmbio S.A.',
	},
	{
		ispb: '10866788',
		name: 'BCO BANDEPE S.A.',
		code: 24,
		fullName: 'Banco Bandepe S.A.',
	},
	{
		ispb: '11165756',
		name: 'GLOBAL SCM LTDA',
		code: 384,
		fullName:
			'GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO',
	},
	{
		ispb: '11285104',
		name: 'NEON FINANCEIRA - CFI S.A.',
		code: 426,
		fullName: 'NEON FINANCEIRA - CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
	},
	{
		ispb: '11476673',
		name: 'BANCO RANDON S.A.',
		code: 88,
		fullName: 'BANCO RANDON S.A.',
	},
	{
		ispb: '11495073',
		name: 'OM DTVM LTDA',
		code: 319,
		fullName: 'OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
	},
	{
		ispb: '11581339',
		name: 'BMP SCMEPP LTDA',
		code: 274,
		fullName:
			'BMP SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LTDA.',
	},
	{
		ispb: '11703662',
		name: 'TRAVELEX BANCO DE CÂMBIO S.A.',
		code: 95,
		fullName: 'Travelex Banco de Câmbio S.A.',
	},
	{
		ispb: '11758741',
		name: 'BANCO FINAXIS',
		code: 94,
		fullName: 'Banco Finaxis S.A.',
	},
	{
		ispb: '11760553',
		name: 'GAZINCRED S.A. SCFI',
		code: 478,
		fullName:
			'GAZINCRED S.A. SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
	},
	{
		ispb: '11970623',
		name: 'BCO SENFF S.A.',
		code: 276,
		fullName: 'BANCO SENFF S.A.',
	},
	{
		ispb: '12392983',
		name: 'MIRAE ASSET CCTVM LTDA',
		code: 447,
		fullName:
			'MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CORRETORA DE CÂMBIO, TÍTULOS E VALORES MO',
	},
	{
		ispb: '13009717',
		name: 'BCO DO EST. DE SE S.A.',
		code: 47,
		fullName: 'Banco do Estado de Sergipe S.A.',
	},
	{
		ispb: '13059145',
		name: 'BEXS BCO DE CAMBIO S.A.',
		code: 144,
		fullName: 'BEXS BANCO DE CÂMBIO S/A',
	},
	{
		ispb: '13140088',
		name: 'ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO',
		code: 332,
		fullName: 'ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE PAGAMENTO',
	},
	{
		ispb: '13203354',
		name: 'FITBANK IP',
		code: 450,
		fullName: 'FITBANK INSTITUIÇÃO DE PAGAMENTOS ELETRÔNICOS S.A.',
	},
	{
		ispb: '13220493',
		name: 'BR PARTNERS BI',
		code: 126,
		fullName: 'BR Partners Banco de Investimento S.A.',
	},
	{
		ispb: '13293225',
		name: 'ÓRAMA DTVM S.A.',
		code: 325,
		fullName: 'Órama Distribuidora de Títulos e Valores Mobiliários S.A.',
	},
	{
		ispb: '13370835',
		name: 'DOCK IP S.A.',
		code: 301,
		fullName: 'DOCK INSTITUIÇÃO DE PAGAMENTO S.A.',
	},
	{
		ispb: '13486793',
		name: 'BRL TRUST DTVM SA',
		code: 173,
		fullName: 'BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
	},
	{
		ispb: '13673855',
		name: 'FRAM CAPITAL DTVM S.A.',
		code: 331,
		fullName:
			'Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.',
	},
	{
		ispb: '13720915',
		name: 'BCO WESTERN UNION',
		code: 119,
		fullName: 'Banco Western Union do Brasil S.A.',
	},
	{
		ispb: '13884775',
		name: 'HUB IP S.A.',
		code: 396,
		fullName: 'HUB INSTITUIÇÃO DE PAGAMENTO S.A.',
	},
	{
		ispb: '13935893',
		name: 'CELCOIN IP S.A.',
		code: 509,
		fullName: 'CELCOIN INSTITUICAO DE PAGAMENTO S.A.',
	},
	{
		ispb: '14190547',
		name: 'CAMBIONET CC LTDA',
		code: 309,
		fullName: 'CAMBIONET CORRETORA DE CÂMBIO LTDA.',
	},
	{
		ispb: '14388334',
		name: 'PARANA BCO S.A.',
		code: 254,
		fullName: 'PARANÁ BANCO S.A.',
	},
	{
		ispb: '14511781',
		name: 'BARI CIA HIPOTECÁRIA',
		code: 268,
		fullName: 'BARI COMPANHIA HIPOTECÁRIA',
	},
	{
		ispb: '15111975',
		name: 'IUGU IP S.A.',
		code: 401,
		fullName: 'IUGU INSTITUIÇÃO DE PAGAMENTO S.A.',
	},
	{
		ispb: '15114366',
		name: 'BCO BOCOM BBM S.A.',
		code: 107,
		fullName: 'Banco Bocom BBM S.A.',
	},
	{
		ispb: '15124464',
		name: 'BANCO BESA S.A.',
		code: 334,
		fullName: 'BANCO BESA S.A.',
	},
	{
		ispb: '15173776',
		name: 'SOCIAL BANK S/A',
		code: 412,
		fullName: 'SOCIAL BANK BANCO MÚLTIPLO S/A',
	},
	{
		ispb: '15357060',
		name: 'BCO WOORI BANK DO BRASIL S.A.',
		code: 124,
		fullName: 'Banco Woori Bank do Brasil S.A.',
	},
	{
		ispb: '15581638',
		name: 'FACTA S.A. CFI',
		code: 149,
		fullName: 'Facta Financeira S.A. - Crédito Financiamento e Investimento',
	},
	{
		ispb: '16501555',
		name: 'STONE IP S.A.',
		code: 197,
		fullName: 'STONE INSTITUIÇÃO DE PAGAMENTO S.A.',
	},
	{
		ispb: '16695922',
		name: 'ID CTVM',
		code: 439,
		fullName: 'ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
	},
	{
		ispb: '16927221',
		name: 'AMAZÔNIA CC LTDA.',
		code: 313,
		fullName: 'AMAZÔNIA CORRETORA DE CÂMBIO LTDA.',
	},
	{
		ispb: '16944141',
		name: 'BROKER BRASIL CC LTDA.',
		code: 142,
		fullName: 'Broker Brasil Corretora de Câmbio Ltda.',
	},
	{
		ispb: '17079937',
		name: 'PINBANK IP',
		code: 529,
		fullName: 'PINBANK BRASIL INSTITUIÇÃO DE PAGAMENTO S.A.',
	},
	{
		ispb: '17184037',
		name: 'BCO MERCANTIL DO BRASIL S.A.',
		code: 389,
		fullName: 'Banco Mercantil do Brasil S.A.',
	},
	{
		ispb: '17298092',
		name: 'BCO ITAÚ BBA S.A.',
		code: 184,
		fullName: 'Banco Itaú BBA S.A.',
	},
	{
		ispb: '17351180',
		name: 'BCO TRIANGULO S.A.',
		code: 634,
		fullName: 'BANCO TRIANGULO S.A.',
	},
	{
		ispb: '17352220',
		name: 'SENSO CCVM S.A.',
		code: 545,
		fullName: 'SENSO CORRETORA DE CAMBIO E VALORES MOBILIARIOS S.A',
	},
	{
		ispb: '17453575',
		name: 'ICBC DO BRASIL BM S.A.',
		code: 132,
		fullName: 'ICBC do Brasil Banco Múltiplo S.A.',
	},
	{
		ispb: '17772370',
		name: 'VIPS CC LTDA.',
		code: 298,
		fullName: "Vip's Corretora de Câmbio Ltda.",
	},
	{
		ispb: '17826860',
		name: 'BMS SCD S.A.',
		code: 377,
		fullName: 'BMS SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '18188384',
		name: 'CREFAZ SCMEPP LTDA',
		code: 321,
		fullName:
			'CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT',
	},
	{
		ispb: '18236120',
		name: 'NU PAGAMENTOS - IP',
		code: 260,
		fullName: 'NU PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO',
	},
	{
		ispb: '18394228',
		name: 'CDC SCD S.A.',
		code: 470,
		fullName: 'CDC SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '18520834',
		name: 'UBS BRASIL BI S.A.',
		code: 129,
		fullName: 'UBS Brasil Banco de Investimento S.A.',
	},
	{
		ispb: '19307785',
		name: 'BRAZA BANK S.A. BCO DE CÂMBIO',
		code: 128,
		fullName: 'BRAZA BANK S.A. BANCO DE CÂMBIO',
	},
	{
		ispb: '19324634',
		name: 'LAMARA SCD S.A.',
		code: 416,
		fullName: 'LAMARA SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '19540550',
		name: 'ASAAS IP S.A.',
		code: 461,
		fullName: 'ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A.',
	},
	{
		ispb: '20155248',
		name: 'PARMETAL DTVM LTDA',
		code: 194,
		fullName: 'PARMETAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
	},
	{
		ispb: '20855875',
		name: 'NEON PAGAMENTOS S.A. IP',
		code: 536,
		fullName: 'NEON PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO',
	},
	{
		ispb: '21018182',
		name: 'EBANX IP LTDA.',
		code: 383,
		fullName: 'EBANX INSTITUICAO DE PAGAMENTOS LTDA.',
	},
	{
		ispb: '21332862',
		name: 'CARTOS SCD S.A.',
		code: 324,
		fullName: 'CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '22610500',
		name: 'VORTX DTVM LTDA.',
		code: 310,
		fullName: 'VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
	},
	{
		ispb: '22896431',
		name: 'PICPAY',
		code: 380,
		fullName: 'PICPAY INSTITUIçãO DE PAGAMENTO S.A.',
	},
	{
		ispb: '23862762',
		name: 'WILL FINANCEIRA S.A.CFI',
		code: 280,
		fullName: 'WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
	},
	{
		ispb: '24074692',
		name: 'GUITTA CC LTDA',
		code: 146,
		fullName: 'GUITTA CORRETORA DE CAMBIO LTDA.',
	},
	{
		ispb: '24537861',
		name: 'FFA SCMEPP LTDA.',
		code: 343,
		fullName:
			'FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
	},
	{
		ispb: '26563270',
		name: 'COOP DE PRIMAVERA DO LESTE',
		code: 279,
		fullName: 'PRIMACREDI COOPERATIVA DE CRÉDITO DE PRIMAVERA DO LESTE',
	},
	{
		ispb: '27098060',
		name: 'BANCO DIGIO',
		code: 335,
		fullName: 'Banco Digio S.A.',
	},
	{
		ispb: '27214112',
		name: 'AL5 S.A. CFI',
		code: 349,
		fullName: 'AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
	},
	{
		ispb: '27302181',
		name: 'CRED-UFES',
		code: 427,
		fullName:
			'COOPERATIVA DE CREDITO DOS SERVIDORES DA UNIVERSIDADE FEDERAL DO ESPIRITO SANTO',
	},
	{
		ispb: '27351731',
		name: 'REALIZE CFI S.A.',
		code: 374,
		fullName: 'REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
	},
	{
		ispb: '27652684',
		name: 'GENIAL INVESTIMENTOS CVM S.A.',
		code: 278,
		fullName: 'Genial Investimentos Corretora de Valores Mobiliários S.A.',
	},
	{
		ispb: '27842177',
		name: 'IB CCTVM S.A.',
		code: 271,
		fullName: 'IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
	},
	{
		ispb: '28127603',
		name: 'BCO BANESTES S.A.',
		code: 21,
		fullName: 'BANESTES S.A. BANCO DO ESTADO DO ESPIRITO SANTO',
	},
	{
		ispb: '28195667',
		name: 'BCO ABC BRASIL S.A.',
		code: 246,
		fullName: 'Banco ABC Brasil S.A.',
	},
	{
		ispb: '28650236',
		name: 'BS2 DTVM S.A.',
		code: 292,
		fullName: 'BS2 Distribuidora de Títulos e Valores Mobiliários S.A.',
	},
	{
		ispb: '28719664',
		name: 'Balcão B3',
		code: null,
		fullName: 'Sistema do Balcão B3',
	},
	{
		ispb: '29011780',
		name: 'CIP SA C3',
		code: null,
		fullName: 'CIP S.A',
	},
	{
		ispb: '29030467',
		name: 'SCOTIABANK BRASIL',
		code: 751,
		fullName: 'Scotiabank Brasil S.A. Banco Múltiplo',
	},
	{
		ispb: '29162769',
		name: 'TORO CTVM S.A.',
		code: 352,
		fullName: 'TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
	},
	{
		ispb: '30306294',
		name: 'BANCO BTG PACTUAL S.A.',
		code: 208,
		fullName: 'Banco BTG Pactual S.A.',
	},
	{
		ispb: '30680829',
		name: 'NU FINANCEIRA S.A. CFI',
		code: 386,
		fullName:
			'NU FINANCEIRA S.A. - Sociedade de Crédito, Financiamento e Investimento',
	},
	{
		ispb: '30723886',
		name: 'BCO MODAL S.A.',
		code: 746,
		fullName: 'Banco Modal S.A.',
	},
	{
		ispb: '31597552',
		name: 'BCO CLASSICO S.A.',
		code: 241,
		fullName: 'BANCO CLASSICO S.A.',
	},
	{
		ispb: '31749596',
		name: 'IDEAL CTVM S.A.',
		code: 398,
		fullName: 'IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
	},
	{
		ispb: '31872495',
		name: 'BCO C6 S.A.',
		code: 336,
		fullName: 'Banco C6 S.A.',
	},
	{
		ispb: '31880826',
		name: 'BCO GUANABARA S.A.',
		code: 612,
		fullName: 'Banco Guanabara S.A.',
	},
	{
		ispb: '31895683',
		name: 'BCO INDUSTRIAL DO BRASIL S.A.',
		code: 604,
		fullName: 'Banco Industrial do Brasil S.A.',
	},
	{
		ispb: '32062580',
		name: 'BCO CREDIT SUISSE S.A.',
		code: 505,
		fullName: 'Banco Credit Suisse (Brasil) S.A.',
	},
	{
		ispb: '32402502',
		name: 'QI SCD S.A.',
		code: 329,
		fullName: 'QI Sociedade de Crédito Direto S.A.',
	},
	{
		ispb: '32648370',
		name: 'FAIR CC S.A.',
		code: 196,
		fullName: 'FAIR CORRETORA DE CAMBIO S.A.',
	},
	{
		ispb: '32997490',
		name: 'CREDITAS SCD',
		code: 342,
		fullName: 'Creditas Sociedade de Crédito Direto S.A.',
	},
	{
		ispb: '33042151',
		name: 'BCO LA NACION ARGENTINA',
		code: 300,
		fullName: 'Banco de la Nacion Argentina',
	},
	{
		ispb: '33042953',
		name: 'CITIBANK N.A.',
		code: 477,
		fullName: 'Citibank N.A.',
	},
	{
		ispb: '33132044',
		name: 'BCO CEDULA S.A.',
		code: 266,
		fullName: 'BANCO CEDULA S.A.',
	},
	{
		ispb: '33147315',
		name: 'BCO BRADESCO BERJ S.A.',
		code: 122,
		fullName: 'Banco Bradesco BERJ S.A.',
	},
	{
		ispb: '33172537',
		name: 'BCO J.P. MORGAN S.A.',
		code: 376,
		fullName: 'BANCO J.P. MORGAN S.A.',
	},
	{
		ispb: '33264668',
		name: 'BCO XP S.A.',
		code: 348,
		fullName: 'Banco XP S.A.',
	},
	{
		ispb: '33466988',
		name: 'BCO CAIXA GERAL BRASIL S.A.',
		code: 473,
		fullName: 'Banco Caixa Geral - Brasil S.A.',
	},
	{
		ispb: '33479023',
		name: 'BCO CITIBANK S.A.',
		code: 745,
		fullName: 'Banco Citibank S.A.',
	},
	{
		ispb: '33603457',
		name: 'BCO RODOBENS S.A.',
		code: 120,
		fullName: 'BANCO RODOBENS S.A.',
	},
	{
		ispb: '33644196',
		name: 'BCO FATOR S.A.',
		code: 265,
		fullName: 'Banco Fator S.A.',
	},
	{
		ispb: '33657248',
		name: 'BNDES',
		code: 7,
		fullName: 'BANCO NACIONAL DE DESENVOLVIMENTO ECONOMICO E SOCIAL',
	},
	{
		ispb: '33775974',
		name: 'ATIVA S.A. INVESTIMENTOS CCTVM',
		code: 188,
		fullName: 'ATIVA INVESTIMENTOS S.A. CORRETORA DE TÍTULOS, CÂMBIO E VALORES',
	},
	{
		ispb: '33862244',
		name: 'BGC LIQUIDEZ DTVM LTDA',
		code: 134,
		fullName:
			'BGC LIQUIDEZ DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
	},
	{
		ispb: '33885724',
		name: 'BANCO ITAÚ CONSIGNADO S.A.',
		code: 29,
		fullName: 'Banco Itaú Consignado S.A.',
	},
	{
		ispb: '33886862',
		name: 'MASTER S/A CCTVM',
		code: 467,
		fullName: 'MASTER S/A CORRETORA DE CâMBIO, TíTULOS E VALORES MOBILIáRIOS',
	},
	{
		ispb: '33923798',
		name: 'BANCO MASTER',
		code: 243,
		fullName: 'BANCO MASTER S/A',
	},
	{
		ispb: '34088029',
		name: 'LISTO SCD S.A.',
		code: 397,
		fullName: 'LISTO SOCIEDADE DE CREDITO DIRETO S.A.',
	},
	{
		ispb: '34111187',
		name: 'HAITONG BI DO BRASIL S.A.',
		code: 78,
		fullName: 'Haitong Banco de Investimento do Brasil S.A.',
	},
	{
		ispb: '34265629',
		name: 'INTERCAM CC LTDA',
		code: 525,
		fullName: 'INTERCAM CORRETORA DE CÂMBIO LTDA.',
	},
	{
		ispb: '34335592',
		name: 'ÓTIMO SCD S.A.',
		code: 355,
		fullName: 'ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '34711571',
		name: 'VITREO DTVM S.A.',
		code: 367,
		fullName: 'VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
	},
	{
		ispb: '34829992',
		name: 'REAG DTVM S.A.',
		code: 528,
		fullName: 'REAG DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
	},
	{
		ispb: '35551187',
		name: 'PLANTAE CFI',
		code: 445,
		fullName: 'PLANTAE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
	},
	{
		ispb: '35977097',
		name: 'UP.P SEP S.A.',
		code: 373,
		fullName: 'UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
	},
	{
		ispb: '36113876',
		name: 'OLIVEIRA TRUST DTVM S.A.',
		code: 111,
		fullName:
			'OLIVEIRA TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIARIOS S.A.',
	},
	{
		ispb: '36266751',
		name: 'FINVEST DTVM',
		code: 512,
		fullName: 'FINVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
	},
	{
		ispb: '36583700',
		name: 'QISTA S.A. CFI',
		code: 516,
		fullName: 'QISTA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
	},
	{
		ispb: '36586946',
		name: 'BONUSPAGO SCD S.A.',
		code: 408,
		fullName: 'BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '36864992',
		name: 'MAF DTVM SA',
		code: 484,
		fullName: 'MAF DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
	},
	{
		ispb: '36947229',
		name: 'COBUCCIO S.A. SCFI',
		code: 402,
		fullName:
			'COBUCCIO S/A - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTOS',
	},
	{
		ispb: '37229413',
		name: 'SCFI EFÍ S.A.',
		code: 507,
		fullName: 'SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO EFÍ S.A.',
	},
	{
		ispb: '37241230',
		name: 'SUMUP SCD S.A.',
		code: 404,
		fullName: 'SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '37414009',
		name: 'ZIPDIN SCD S.A.',
		code: 418,
		fullName: 'ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A',
	},
	{
		ispb: '37526080',
		name: 'LEND SCD S.A.',
		code: 414,
		fullName: 'LEND SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '37555231',
		name: 'DM',
		code: 449,
		fullName: 'DM SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '37679449',
		name: 'MERCADO CRÉDITO SCFI S.A.',
		code: 518,
		fullName:
			'MERCADO CRÉDITO SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
	},
	{
		ispb: '37715993',
		name: 'ACCREDITO SCD S.A.',
		code: 406,
		fullName: 'ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '37880206',
		name: 'CORA SCD S.A.',
		code: 403,
		fullName: 'CORA SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '38129006',
		name: 'NUMBRS SCD S.A.',
		code: 419,
		fullName: 'NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '38224857',
		name: 'DELCRED SCD S.A.',
		code: 435,
		fullName: 'DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '38429045',
		name: 'FÊNIX DTVM LTDA.',
		code: 455,
		fullName: 'FÊNIX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
	},
	{
		ispb: '39343350',
		name: 'CC LAR CREDI',
		code: 421,
		fullName: 'LAR COOPERATIVA DE CRÉDITO - LAR CREDI',
	},
	{
		ispb: '39416705',
		name: 'CREDIHOME SCD',
		code: 443,
		fullName: 'CREDIHOME SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '39519944',
		name: 'MARU SCD S.A.',
		code: 535,
		fullName: 'MARÚ SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '39587424',
		name: 'UY3 SCD S/A',
		code: 457,
		fullName: 'UY3 SOCIEDADE DE CRÉDITO DIRETO S/A',
	},
	{
		ispb: '39664698',
		name: 'CREDSYSTEM SCD S.A.',
		code: 428,
		fullName: 'CREDSYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '39669186',
		name: 'HEMERA DTVM LTDA.',
		code: 448,
		fullName: 'HEMERA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
	},
	{
		ispb: '39676772',
		name: 'CREDIFIT SCD S.A.',
		code: 452,
		fullName: 'CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '39738065',
		name: 'FFCRED SCD S.A.',
		code: 510,
		fullName: 'FFCRED SOCIEDADE DE CRÉDITO DIRETO S.A..',
	},
	{
		ispb: '39908427',
		name: 'STARK SCD S.A.',
		code: 462,
		fullName: 'STARK SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '40083667',
		name: 'CAPITAL CONSIG SCD S.A.',
		code: 465,
		fullName: 'CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '40303299',
		name: 'PORTOPAR DTVM LTDA',
		code: 306,
		fullName: 'PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
	},
	{
		ispb: '40434681',
		name: 'AZUMI DTVM',
		code: 463,
		fullName: 'AZUMI DISTRIBUIDORA DE TíTULOS E VALORES MOBILIáRIOS LTDA.',
	},
	{
		ispb: '40475846',
		name: 'J17 - SCD S/A',
		code: 451,
		fullName: 'J17 - SOCIEDADE DE CRÉDITO DIRETO S/A',
	},
	{
		ispb: '40654622',
		name: 'TRINUS SCD S.A.',
		code: 444,
		fullName: 'TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '40768766',
		name: 'LIONS TRUST DTVM',
		code: 519,
		fullName:
			'LIONS TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
	},
	{
		ispb: '41592532',
		name: 'MÉRITO DTVM LTDA.',
		code: 454,
		fullName: 'MÉRITO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
	},
	{
		ispb: '42047025',
		name: 'UNAVANTI SCD S/A',
		code: 460,
		fullName: 'UNAVANTI SOCIEDADE DE CRÉDITO DIRETO S/A',
	},
	{
		ispb: '42066258',
		name: 'RJI',
		code: 506,
		fullName: 'RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA',
	},
	{
		ispb: '42259084',
		name: 'SBCASH SCD',
		code: 482,
		fullName: 'SBCASH SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '42272526',
		name: 'BNY MELLON BCO S.A.',
		code: 17,
		fullName: 'BNY Mellon Banco S.A.',
	},
	{
		ispb: '43180355',
		name: 'PEFISA S.A. - C.F.I.',
		code: 174,
		fullName: 'PEFISA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
	},
	{
		ispb: '43599047',
		name: 'SUPERLÓGICA SCD S.A.',
		code: 481,
		fullName: 'SUPERLÓGICA SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '44019481',
		name: 'PEAK SEP S.A.',
		code: 521,
		fullName: 'PEAK SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
	},
	{
		ispb: '44077014',
		name: 'BR-CAPITAL DTVM S.A.',
		code: 433,
		fullName: 'BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
	},
	{
		ispb: '44189447',
		name: 'BCO LA PROVINCIA B AIRES BCE',
		code: 495,
		fullName: 'Banco de La Provincia de Buenos Aires',
	},
	{
		ispb: '44292580',
		name: 'HR DIGITAL SCD',
		code: 523,
		fullName: 'HR DIGITAL - SOCIEDADE DE CRÉDITO DIRETO S/A',
	},
	{
		ispb: '44478623',
		name: 'ATICCA SCD S.A.',
		code: 527,
		fullName: 'ATICCA - SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '44683140',
		name: 'MAGNUM SCD',
		code: 511,
		fullName: 'MAGNUM SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '44728700',
		name: 'ATF CREDIT SCD S.A.',
		code: 513,
		fullName: 'ATF CREDIT SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '45246410',
		name: 'BANCO GENIAL',
		code: 125,
		fullName: 'BANCO GENIAL S.A.',
	},
	{
		ispb: '45745537',
		name: 'EAGLE SCD S.A.',
		code: 532,
		fullName: 'EAGLE SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '45756448',
		name: 'MICROCASH SCMEPP LTDA.',
		code: 537,
		fullName:
			'MICROCASH SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE',
	},
	{
		ispb: '45854066',
		name: 'WNT CAPITAL DTVM',
		code: 524,
		fullName: 'WNT CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
	},
	{
		ispb: '46026562',
		name: 'MONETARIE SCD',
		code: 526,
		fullName: 'MONETARIE SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '46518205',
		name: 'JPMORGAN CHASE BANK',
		code: 488,
		fullName: 'JPMorgan Chase Bank, National Association',
	},
	{
		ispb: '47593544',
		name: 'RED SCD S.A.',
		code: 522,
		fullName: 'RED SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '47873449',
		name: 'SER FINANCE SCD S.A.',
		code: 530,
		fullName: 'SER FINANCE SOCIEDADE DE CRÉDITO DIRETO S.A.',
	},
	{
		ispb: '48795256',
		name: 'BCO ANDBANK S.A.',
		code: 65,
		fullName: 'Banco AndBank (Brasil) S.A.',
	},
	{
		ispb: '50579044',
		name: 'LEVYCAM CCV LTDA',
		code: 145,
		fullName: 'LEVYCAM - CORRETORA DE CAMBIO E VALORES LTDA.',
	},
	{
		ispb: '50585090',
		name: 'BCV - BCO, CRÉDITO E VAREJO S.A.',
		code: 250,
		fullName: 'BCV - BANCO DE CRÉDITO E VAREJO S.A.',
	},
	{
		ispb: '52937216',
		name: 'BEXS CC S.A.',
		code: 253,
		fullName: 'Bexs Corretora de Câmbio S/A',
	},
	{
		ispb: '53518684',
		name: 'BCO HSBC S.A.',
		code: 269,
		fullName: 'BANCO HSBC S.A.',
	},
	{
		ispb: '54403563',
		name: 'BCO ARBI S.A.',
		code: 213,
		fullName: 'Banco Arbi S.A.',
	},
	{
		ispb: '54641030',
		name: 'Câmara B3',
		code: null,
		fullName: 'Câmara B3',
	},
	{
		ispb: '55230916',
		name: 'INTESA SANPAOLO BRASIL S.A. BM',
		code: 139,
		fullName: 'Intesa Sanpaolo Brasil S.A. - Banco Múltiplo',
	},
	{
		ispb: '57839805',
		name: 'BCO TRICURY S.A.',
		code: 18,
		fullName: 'Banco Tricury S.A.',
	},
	{
		ispb: '58160789',
		name: 'BCO SAFRA S.A.',
		code: 422,
		fullName: 'Banco Safra S.A.',
	},
	{
		ispb: '58497702',
		name: 'BCO LETSBANK S.A.',
		code: 630,
		fullName: 'BANCO LETSBANK S.A.',
	},
	{
		ispb: '58616418',
		name: 'BCO FIBRA S.A.',
		code: 224,
		fullName: 'Banco Fibra S.A.',
	},
	{
		ispb: '59109165',
		name: 'BCO VOLKSWAGEN S.A',
		code: 393,
		fullName: 'Banco Volkswagen S.A.',
	},
	{
		ispb: '59118133',
		name: 'BCO LUSO BRASILEIRO S.A.',
		code: 600,
		fullName: 'Banco Luso Brasileiro S.A.',
	},
	{
		ispb: '59274605',
		name: 'BCO GM S.A.',
		code: 390,
		fullName: 'BANCO GM S.A.',
	},
	{
		ispb: '59285411',
		name: 'BANCO PAN',
		code: 623,
		fullName: 'Banco Pan S.A.',
	},
	{
		ispb: '59588111',
		name: 'BCO VOTORANTIM S.A.',
		code: 655,
		fullName: 'Banco Votorantim S.A.',
	},
	{
		ispb: '60394079',
		name: 'BCO ITAUBANK S.A.',
		code: 479,
		fullName: 'Banco ItauBank S.A.',
	},
	{
		ispb: '60498557',
		name: 'BCO MUFG BRASIL S.A.',
		code: 456,
		fullName: 'Banco MUFG Brasil S.A.',
	},
	{
		ispb: '60518222',
		name: 'BCO SUMITOMO MITSUI BRASIL S.A.',
		code: 464,
		fullName: 'Banco Sumitomo Mitsui Brasileiro S.A.',
	},
	{
		ispb: '60701190',
		name: 'ITAÚ UNIBANCO S.A.',
		code: 341,
		fullName: 'ITAÚ UNIBANCO S.A.',
	},
	{
		ispb: '60746948',
		name: 'BCO BRADESCO S.A.',
		code: 237,
		fullName: 'Banco Bradesco S.A.',
	},
	{
		ispb: '60814191',
		name: 'BCO MERCEDES-BENZ S.A.',
		code: 381,
		fullName: 'BANCO MERCEDES-BENZ DO BRASIL S.A.',
	},
	{
		ispb: '60850229',
		name: 'OMNI BANCO S.A.',
		code: 613,
		fullName: 'Omni Banco S.A.',
	},
	{
		ispb: '60889128',
		name: 'BCO SOFISA S.A.',
		code: 637,
		fullName: 'BANCO SOFISA S.A.',
	},
	{
		ispb: '60934221',
		name: 'Câmbio B3',
		code: null,
		fullName: 'Câmara de Câmbio B3',
	},
	{
		ispb: '61024352',
		name: 'BANCO VOITER',
		code: 653,
		fullName: 'BANCO VOITER S.A.',
	},
	{
		ispb: '61033106',
		name: 'BCO CREFISA S.A.',
		code: 69,
		fullName: 'Banco Crefisa S.A.',
	},
	{
		ispb: '61088183',
		name: 'BCO MIZUHO S.A.',
		code: 370,
		fullName: 'Banco Mizuho do Brasil S.A.',
	},
	{
		ispb: '61182408',
		name: 'BANCO INVESTCRED UNIBANCO S.A.',
		code: 249,
		fullName: 'Banco Investcred Unibanco S.A.',
	},
	{
		ispb: '61186680',
		name: 'BCO BMG S.A.',
		code: 318,
		fullName: 'Banco BMG S.A.',
	},
	{
		ispb: '61348538',
		name: 'BCO C6 CONSIG',
		code: 626,
		fullName: 'BANCO C6 CONSIGNADO S.A.',
	},
	{
		ispb: '61384004',
		name: 'AVENUE SECURITIES DTVM LTDA.',
		code: 508,
		fullName:
			'AVENUE SECURITIES DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
	},
	{
		ispb: '61444949',
		name: 'SAGITUR CC',
		code: 270,
		fullName: 'SAGITUR CORRETORA DE CÂMBIO S.A.',
	},
	{
		ispb: '61533584',
		name: 'BCO SOCIETE GENERALE BRASIL',
		code: 366,
		fullName: 'BANCO SOCIETE GENERALE BRASIL S.A.',
	},
	{
		ispb: '61723847',
		name: 'NEON CTVM S.A.',
		code: 113,
		fullName: 'NEON CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
	},
	{
		ispb: '61747085',
		name: 'TULLETT PREBON BRASIL CVC LTDA',
		code: 131,
		fullName: 'TULLETT PREBON BRASIL CORRETORA DE VALORES E CÂMBIO LTDA',
	},
	{
		ispb: '61809182',
		name: 'C.SUISSE HEDGING-GRIFFO CV S/A',
		code: 11,
		fullName: 'CREDIT SUISSE HEDGING-GRIFFO CORRETORA DE VALORES S.A',
	},
	{
		ispb: '61820817',
		name: 'BCO PAULISTA S.A.',
		code: 611,
		fullName: 'Banco Paulista S.A.',
	},
	{
		ispb: '62073200',
		name: 'BOFA MERRILL LYNCH BM S.A.',
		code: 755,
		fullName: 'Bank of America Merrill Lynch Banco Múltiplo S.A.',
	},
	{
		ispb: '62109566',
		name: 'CREDISAN CC',
		code: 89,
		fullName: 'CREDISAN COOPERATIVA DE CRÉDITO',
	},
	{
		ispb: '62144175',
		name: 'BCO PINE S.A.',
		code: 643,
		fullName: 'Banco Pine S.A.',
	},
	{
		ispb: '62169875',
		name: 'NU INVEST CORRETORA DE VALORES S.A.',
		code: 140,
		fullName: 'NU INVEST CORRETORA DE VALORES S.A.',
	},
	{
		ispb: '62232889',
		name: 'BCO DAYCOVAL S.A',
		code: 707,
		fullName: 'Banco Daycoval S.A.',
	},
	{
		ispb: '62237649',
		name: 'CAROL DTVM LTDA.',
		code: 288,
		fullName: 'CAROL DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
	},
	{
		ispb: '62285390',
		name: 'SINGULARE CTVM S.A.',
		code: 363,
		fullName: 'SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
	},
	{
		ispb: '62287735',
		name: 'RENASCENCA DTVM LTDA',
		code: 101,
		fullName: 'RENASCENCA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
	},
	{
		ispb: '62331228',
		name: 'DEUTSCHE BANK S.A.BCO ALEMAO',
		code: 487,
		fullName: 'DEUTSCHE BANK S.A. - BANCO ALEMAO',
	},
	{
		ispb: '62421979',
		name: 'BANCO CIFRA',
		code: 233,
		fullName: 'Banco Cifra S.A.',
	},
	{
		ispb: '65913436',
		name: 'GUIDE',
		code: 177,
		fullName: 'Guide Investimentos S.A. Corretora de Valores',
	},
	{
		ispb: '67030395',
		name: 'TRUSTEE DTVM LTDA.',
		code: 438,
		fullName: 'TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
	},
	{
		ispb: '68757681',
		name: 'SIMPAUL',
		code: 365,
		fullName: 'SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS  S.A.',
	},
	{
		ispb: '68900810',
		name: 'BCO RENDIMENTO S.A.',
		code: 633,
		fullName: 'Banco Rendimento S.A.',
	},
	{
		ispb: '71027866',
		name: 'BCO BS2 S.A.',
		code: 218,
		fullName: 'Banco BS2 S.A.',
	},
	{
		ispb: '71590442',
		name: 'LASTRO RDV DTVM LTDA',
		code: 293,
		fullName: 'Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.',
	},
	{
		ispb: '71677850',
		name: 'FRENTE CC S.A.',
		code: 285,
		fullName: 'FRENTE CORRETORA DE CÂMBIO S.A.',
	},
	{
		ispb: '73622748',
		name: 'B&T CC LTDA.',
		code: 80,
		fullName: 'B&T CORRETORA DE CAMBIO LTDA.',
	},
	{
		ispb: '74828799',
		name: 'NOVO BCO CONTINENTAL S.A. - BM',
		code: 753,
		fullName: 'Novo Banco Continental S.A. - Banco Múltiplo',
	},
	{
		ispb: '75647891',
		name: 'BCO CRÉDIT AGRICOLE BR S.A.',
		code: 222,
		fullName: 'BANCO CRÉDIT AGRICOLE BRASIL S.A.',
	},
	{
		ispb: '76461557',
		name: 'CCR COOPAVEL',
		code: 281,
		fullName: 'Cooperativa de Crédito Rural Coopavel',
	},
	{
		ispb: '76543115',
		name: 'BANCO SISTEMA',
		code: 754,
		fullName: 'Banco Sistema S.A.',
	},
	{
		ispb: '76641497',
		name: 'DOURADA CORRETORA',
		code: 311,
		fullName: 'DOURADA CORRETORA DE CÂMBIO LTDA.',
	},
	{
		ispb: '78157146',
		name: 'CREDIALIANÇA CCR',
		code: 98,
		fullName: 'Credialiança Cooperativa de Crédito Rural',
	},
	{
		ispb: '78626983',
		name: 'BCO VR S.A.',
		code: 610,
		fullName: 'Banco VR S.A.',
	},
	{
		ispb: '78632767',
		name: 'BCO OURINVEST S.A.',
		code: 712,
		fullName: 'Banco Ourinvest S.A.',
	},
	{
		ispb: '80271455',
		name: 'BCO RNX S.A.',
		code: 720,
		fullName: 'BANCO RNX S.A.',
	},
	{
		ispb: '81723108',
		name: 'CREDICOAMO',
		code: 10,
		fullName: 'CREDICOAMO CREDITO RURAL COOPERATIVA',
	},
	{
		ispb: '82096447',
		name: 'CREDIBRF COOP',
		code: 440,
		fullName: 'CREDIBRF - COOPERATIVA DE CRÉDITO',
	},
	{
		ispb: '87963450',
		name: 'MAGNETIS - DTVM',
		code: 442,
		fullName: 'MAGNETIS - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
	},
	{
		ispb: '89960090',
		name: 'RB INVESTIMENTOS DTVM LTDA.',
		code: 283,
		fullName:
			'RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LIMITADA',
	},
	{
		ispb: '90400888',
		name: 'BCO SANTANDER (BRASIL) S.A.',
		code: 33,
		fullName: 'BANCO SANTANDER (BRASIL) S.A.',
	},
	{
		ispb: '91884981',
		name: 'BANCO JOHN DEERE S.A.',
		code: 217,
		fullName: 'Banco John Deere S.A.',
	},
	{
		ispb: '92702067',
		name: 'BCO DO ESTADO DO RS S.A.',
		code: 41,
		fullName: 'Banco do Estado do Rio Grande do Sul S.A.',
	},
	{
		ispb: '92856905',
		name: 'ADVANCED CC LTDA',
		code: 117,
		fullName: 'ADVANCED CORRETORA DE CÂMBIO LTDA',
	},
	{
		ispb: '92874270',
		name: 'BCO DIGIMAIS S.A.',
		code: 654,
		fullName: 'BANCO DIGIMAIS S.A.',
	},
	{
		ispb: '92875780',
		name: 'WARREN CVMC LTDA',
		code: 371,
		fullName: 'WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.',
	},
	{
		ispb: '92894922',
		name: 'BANCO ORIGINAL',
		code: 212,
		fullName: 'Banco Original S.A.',
	},
	{
		ispb: '94968518',
		name: 'EFX CC LTDA.',
		code: 289,
		fullName: 'EFX CORRETORA DE CÂMBIO LTDA.',
	},
	{
		ispb: '',
		code: null,
	},
	{
		ispb: '',
		code: null,
	},
];

export const selectBanks = banks
	.filter((bank) => bank.code !== null)
	.map((bank) => ({
		value: bank.code,
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		label: `${String(bank.code).padStart(3, '0')} - ${bank.name}`,
	}));
