/* eslint-disable no-undef */
import { MarkerF, OverlayViewF } from '@react-google-maps/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Pusher from 'pusher-js';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TiCancel } from 'react-icons/ti';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { useStore } from 'zustand';
import { Marker, Popup, useMap } from 'react-leaflet';
import L from "leaflet";
import Button from '../../../../components/Button/Button';
import { CancelOrderModal } from '../../../../components/Order/CancelOrderModal';
import { GoogleMap } from '../../../../components/GoogleMap';
import { useGoogleLoaded } from '../../../../components/GoogleMap/useGoogleLoaded';
import { Loading } from '../../../../components/Loading';
import { useAlert } from '../../../../contexts/AlertContext';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import { OrderResponseApi } from '../../../../services/angular/types/Order';
import { appStateStore, appState } from '../../../../store/appState';
import { Order } from '../../../../components/Order';
import { CurrentLocation } from './components/currentLocation';
import { StatusTooltip } from '../../../../components/Angular/StatusTooltip';
// @ts-expect-error
import bottle from '../../../../assets/sounds/bottle.wav';
import { Modal } from '../../../../components/Modal';
import { Avatar } from '../../../../components/Avatar';
import { CgToolbox } from 'react-icons/cg';
import { AddIncidentModal } from '../../../../components/AddIncidentModal';
import { LeafletMap } from '../../../../components/Leaflet';
import { customMarkerIcon, driverIcon } from '../../../../components/Leaflet/icons/customIcon';
import { useNewMaps } from '../../../../hooks/useMaps';

interface PulsatingIconProps {
	position: L.LatLngExpression;
	angle: number;
  }


export const Follow = () => {
	const alert = useAlert();
	const loaded = useGoogleLoaded();
	const queryClient = useQueryClient();
	const token = useUserState((state) => state.token);
	const company = useUserState((state) => state.company);
	const user = useUserState((state) => state.user);
	const { config } = useStore(appStateStore);
	const { orderId } = useParams<{ orderId: string }>();
	const markersRef = useRef<google.maps.Marker[]>([]);
	const mapRef = useRef<google.maps.Map>();
	const pusherRef = useRef<Pusher>();
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [addIncidentModal, setAddIncidentModal] = useState(false);
	const [rateModal, setRateModal] = useState<boolean>(false);
	const { toggleMaps } = useNewMaps();

	const {
		data: order,
		isLoading,
		refetch,
	} = useQuery(
		['follow-order', orderId],
		async () => {
			const { data } = await api.get<OrderResponseApi>(`/orders/${orderId!}`, {
				params: {
					include:
						'category,status,locations,locations.protocols,locations.receiverType,optionals,requesterCompany,requester,driver,driverCompany.owner',
					companyId: company?.id,
				},
			});

			return data;
		},
		{
			enabled: !!orderId,
			refetchOnMount: true,
		}
	);

	// Pusher configuration
	useEffect(() => {
		const pusher = new Pusher(config.pusherAppKey, {
			cluster: config.pusherCluster,
			authEndpoint: config.pusherAuth,
			auth: {
				headers: {
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					Authorization: `Bearer ${token}`,
				},
			},
		});

		pusherRef.current = pusher;

		return () => {
			pusher.disconnect();
		};
	}, []);

	// Pusher channel and events
	useEffect(() => {
		if (!pusherRef.current) return;

		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const channel = pusherRef.current.subscribe(`private-ORDER-${orderId!}`);

		channel.bind('UPDATE_DRIVER_POSITION', function (data: SocketResponse) {
			queryClient.setQueryData(['follow-order', orderId], (oldOrder: any) => {
				return {
					...oldOrder,
					driver: {
						...oldOrder?.driver,
						...data.order.driver,
					},
				};
			});
		});
		channel.bind('ORDER_ACCEPTED', function (data: SocketResponse) {
			void refetch();
			void new Audio(bottle).play();
			toast.info(
				<>
					<h1 className="font-semibold">Frete #{data.notId}</h1>
					<p>Frete aceito</p>
				</>
			);
		});
		channel.bind('ORDER_STARTED', function (data: SocketResponse) {
			void refetch();
			toast.info(
				<>
					<h1 className="font-semibold">Frete #{data.notId}</h1>
					<p>Frete iniciado</p>
				</>
			);
		});
		channel.bind('ORDER_ARRIVED', async function (data: SocketResponse) {
			const { data: updatedOrder } = await refetch();

			const location = updatedOrder?.locations
				.sort((a, b) => a.sequence - b.sequence)
				.reverse()
				.find((location) => !!location.checkinDate);

			if (location)
				toast.info(
					<>
						<h1 className="font-semibold">Frete #{data.notId}</h1>
						<p>Entregador chegou no ponto {location.sequence + 1}</p>
					</>
				);
		});
		channel.bind('ORDER_CHECKIN_V2', async function (data: SocketResponse) {
			const { data: updatedOrder } = await refetch();

			const location = updatedOrder?.locations
				.sort((a, b) => a.sequence - b.sequence)
				.reverse()
				.find((location) => !!location.checkinDate);

			if (location)
				toast.info(
					<>
						<h1 className="font-semibold">Frete #{data.notId}</h1>
						<p>Entregador chegou no ponto {location.sequence + 1}</p>
					</>
				);
		});
		channel.bind(
			'ORDER_CHECKOUT_FAILED_V2',
			async function (data: SocketResponse) {
				const { data: updatedOrder } = await refetch();

				const location = updatedOrder?.locations
					.sort((a, b) => a.sequence - b.sequence)
					.reverse()
					.find((location) => !!location?.reason);

				if (location?.reason)
					toast.error(
						<>
							<h1 className="font-semibold">Frete #{data.notId}</h1>
							<p>
								Não foi possível realizar a entrega no ponto{' '}
								{location.sequence + 1}: {location.reason}
							</p>
						</>
					);
			}
		);
		channel.bind('ORDER_CHECKOUT_V2', async function (data: SocketResponse) {
			const { data: updatedOrder } = await refetch();

			const location = updatedOrder?.locations
				.sort((a, b) => a.sequence - b.sequence)
				.reverse()
				.find((location) => !!location.checkoutDate);

			if (location)
				toast.info(
					<>
						<h1 className="font-semibold">Frete #{data.notId}</h1>
						<p>Entrega confirmada no ponto {location.sequence + 1}</p>
					</>
				);
		});
		channel.bind('ORDER_FINISHED', async function (data: SocketResponse) {
			void refetch();
			user?.role.id === Number(appState.userRoles.user.value) &&
				setRateModal(true);
		});
		channel.bind('ORDER_CANCELED', async function (data: SocketResponse) {
			void refetch();
			alert.onCustom({
				title: 'Frete cancelado',
				message: `O frete #${data.notId} foi cancelado`,
				cancel: {
					label: 'Ok',
					icon: <></>,
				},
			});
		});
		channel.bind('ORDER_UNDOCHECKIN', async function (data: SocketResponse) {
			const { data: updatedOrder } = await refetch();

			const location = updatedOrder?.locations
				.sort((a, b) => a.sequence - b.sequence)
				.reverse()
				.find((location) => !!location.checkinDate);

			if (location)
				toast.warn(
					<>
						<h1 className="font-semibold">Frete #{data.notId}</h1>
						<p>Checkin desfeito no ponto {location.sequence + 1}</p>
					</>
				);
		});
		channel.bind('ORDER_UNDOCHECKOUT', async function (data: SocketResponse) {
			const { data: updatedOrder } = await refetch();

			const location = updatedOrder?.locations
				.sort((a, b) => a.sequence - b.sequence)
				.reverse()
				.find((location) => !!location.checkoutDate);

			if (location)
				toast.warn(
					<>
						<h1 className="font-semibold">Frete #{data.notId}</h1>
						<p>Entrega desfeita no ponto {location.sequence + 1}</p>
					</>
				);
		});
		channel.bind('ORDER_TIMEOUT', async function (data: SocketResponse) {
			void refetch();

			toast.info(
				<>
					<h1 className="font-semibold">Frete #{data.notId}</h1>
					<p>O frete foi cancelado por tempo excedido</p>
				</>
			);
		});
		channel.bind('ORDER_DRIVER_REMOVED', async function (data: SocketResponse) {
			void refetch();

			toast.info(
				<>
					<h1 className="font-semibold">Frete #{data.notId}</h1>
					<p>O frete será transferido para outro motorista</p>
				</>
			);
		});
		channel.bind('ORDER_REALLOCATING', async function (data: SocketResponse) {
			void refetch();

			toast.info(
				<>
					<h1 className="font-semibold">Frete #{data.notId}</h1>
					<p>O frete será transferido para outro motorista</p>
				</>
			);
		});
		channel.bind('ORDER_PROTOCOL', async function (data: SocketResponse) {
			void refetch();

			toast.info(
				<>
					<h1 className="font-semibold">Frete #{data.notId}</h1>
					<p>Houve uma alteração nos protocolos do frete</p>
				</>
			);
		});
		channel.bind(
			'ORDER_LOCATION_REMOVED',
			async function (data: SocketResponse) {
				void refetch();

				toast.info(
					<>
						<h1 className="font-semibold">Frete #{data.notId}</h1>
						<p>Um local foi removido do frete</p>
					</>
				);
			}
		);
		channel.bind(
			'ORDER_LOCATIONS_REORDERED',
			async function (data: SocketResponse) {
				void refetch();

				toast.info(
					<>
						<h1 className="font-semibold">Frete #{data.notId}</h1>
						<p>O motorista alterou a ordem dos pontos</p>
					</>
				);
			}
		);

		return () => {
			channel.unbind_all();
		};
	}, [pusherRef.current]);

	const handleLegs = useMemo(() => {
		if (order?.routes?.[0]?.directions?.legs) {
			if (order.statusId === appState.orderStatus['Em andamento'].id) {
				const legs = order.routes[0].directions?.legs;

				const locationIndex = order?.locations?.findIndex(
					(location) => !location.checkoutDate && !location.reasonDate
				);
				if (locationIndex > 0) return [legs[locationIndex - 1]];

				return legs;
			} else {
				return order.routes[0].directions?.legs;
			}
		}
	}, [order]);

	const { data: driverAvatar } = useQuery(
		['driver-avatar', order?.driver?.avatar],
		async () =>
			await api.get('/assets', {
				params: {
					imageName: order?.driver?.avatar,
				},
			}),
		{
			enabled: !!order?.driver?.avatar,
		}
	);

	useEffect(() => {
		if (!order || !markersRef.current.length) return;

		order?.locations
			?.sort((a, b) => a.sequence - b.sequence)
			?.forEach((location, index) => {
				markersRef.current[index].setIcon({
					url:
						location.checkinDate && location.checkoutDate
							? '/markerGreen.png'
							: location.checkinDate && location.reasonDate
							? '/markerRed.png'
							: location.checkinDate
							? '/markerYellow.png'
							: '/markerBlue.png',
				});

				markersRef.current[index].setLabel({
					text: `${location.sequence + 1}`,
					fontWeight: 'bold',
					color: 'black',
					className:
						'absolute top-[38px] left-[50%] transform -translate-x-1/2',
				});

				markersRef.current[index].setPosition({
					lat: location.lat,
					lng: location.lng,
				});
			});
	}, [order]);

	if (isLoading) return <Loading title="Carregando Frete" />;
	
	const PulsatingIcon: React.FC<PulsatingIconProps> = ({ position, angle }) => {
		const map = useMap();
	  
		useEffect(() => {
		  const pulsatingIcon = driverIcon(angle)
		  const marker = L.marker(position, { icon: pulsatingIcon }).addTo(map);
		  marker.bindPopup(`Motorista: ${order?.driver?.name ?? ""}<br/>Categoria: ${order?.category?.name ?? ""} <br/>Placa: ${order?.vehicle?.plate ?? ""}<br/>Modelo: ${order?.vehicle?.model ?? ""}`, { offset: L.point(0, -30) });
		  return () => {
			map.removeLayer(marker);
		  };
		}, [map, position, angle]);
	  
		return null;
	  };
	return (
		<div className="flex w-full flex-col gap-2 md:flex-row">
			<div className="flex h-auto w-full min-w-[320px] flex-col gap-2 pr-1 md:max-w-lg md:overflow-y-auto">
				<div className="flex flex-col gap-2">
					<StatusTooltip
						className="w-full"
						triggerClassName="max-w-full w-full"
						order={order}
					/>
					{order?.statusId !== appState.orderStatus.Cancelado.id &&
						order?.incident === undefined && (
							<div className="flex flex-col gap-2 lg:flex-row">
								{order?.statusId !== appState.orderStatus.Finalizado.id && user?.role.id === Number(appState.userRoles.user.value) && (
									<Button
										onClick={() => setShowCancelModal(true)}
										variant="red-outline"
									>
										<TiCancel size={24} />
										Cancelar Pedido
									</Button>
								)}
								{user?.role.id !== Number(appState.userRoles.user.value) && (
									<>
										<Button
											onClick={() => setShowCancelModal(true)}
											variant="red-outline"
										>
											<TiCancel size={24} />
											Cancelar Pedido
										</Button>
										<Button
											variant="red-outline"
											onClick={() => {
												setAddIncidentModal(true);
											}}
										>
											<CgToolbox size={24} />
											Finalizar com Incidente
										</Button>
									</>
								)}
							</div>
						)}
				</div>
				<Order.FreightSummary
					order={order}
					isAdmin={user?.role.id !== Number(appState.userRoles.user.value)}
				/>
				<div className="rounded bg-white p-4">
					<Order.LocationsAccordion
						header
						order={order}
						isAdmin={user?.role.id !== Number(appState.userRoles.user.value)}
					/>
				</div>
				<Order.ClientDetails order={order} />
				{order?.driver && <Order.DriverDetails order={order} />}
				{order?.vehicle && <Order.VehicleDetails order={order} />}
			</div>

			<div className="relative h-[calc(100vh-112px)] w-full">
			{
			!toggleMaps?.useLeaflet ? (
				loaded && (
					<GoogleMap
					disableCenter
					onMapLoad={(map) => {
						mapRef.current = map;
						const bounds = new google.maps.LatLngBounds();

						order?.locations.forEach((location) => {
						bounds.extend(
							new google.maps.LatLng(location?.lat, location?.lng)
						);
						});

						if (order?.driver?.position)
						bounds.extend(
							new google.maps.LatLng(
							order?.driver?.position?.latitude,
							order?.driver?.position?.longitude
							)
						);

						map.fitBounds(bounds);
					}}
					legs={handleLegs}
					options={{
						gestureHandling:
						window.innerWidth < 768 ? 'cooperative' : 'greedy',
					}}
					>
					<CurrentLocation order={order} />
					{order?.locations
						?.sort((a, b) => a.sequence - b.sequence)
						.map((item) => (
						<MarkerF
							onLoad={(marker) => {
							markersRef.current.push(marker);
							}}
							clickable={false}
							key={item.id}
							position={{
							lat: item.lat,
							lng: item.lng,
							}}
							label={{
							text: `${item.sequence + 1}`,
							fontWeight: 'bold',
							color: 'black',
							className:
								'absolute top-[38px] left-[50%] transform -translate-x-1/2',
							}}
							icon={{
							url:
								item.checkinDate && item.checkoutDate
								? '/markerGreen.png'
								: item.checkinDate && item.reasonDate
								? '/markerRed.png'
								: item.checkinDate
								? '/markerYellow.png'
								: '/markerBlue.png',
							}}
						/>
						))}
					{order?.driver?.position && (
						<OverlayViewF
						mapPaneName="markerLayer"
						position={
							new google.maps.LatLng(
							order?.driver?.position?.latitude,
							order?.driver?.position?.longitude
							)
						}
						>
						<div className="absolute bottom-0 left-0 right-0 flex flex-col items-center">
							<div className="flex h-8 w-8 items-center justify-center rounded-full shadow-lg">
							<div className="absolute h-14 w-14 animate-slow-ping rounded-full bg-blue opacity-50 duration-1000" />
							<img
								src="/bee.png"
								alt="bee"
								className={twMerge(
								'z-10 h-8 w-8',
								order.driver.position?.bearing &&
									`rotate-[${order.driver.position.bearing.toFixed(
									0
									)}deg]`
								)}
							/>
							</div>
						</div>
						</OverlayViewF>
					)}
					</GoogleMap>
				)
				) : (
				<>
				<LeafletMap 
					center={order?.locations} 
					polylineString={order?.routes[0]?.directions?.overview_polyline.points ?? ""}
					layers={toggleMaps.layers}
				>       
				
					{order?.locations
						?.sort((a, b) => a.sequence - b.sequence)
						.map((item) => (     
						<Marker
							key={item.id}
							position={[item.lat,item.lng]}
							icon={
									item.checkinDate && item.checkoutDate
									? customMarkerIcon("#00FF00", `${item.sequence + 1}`)
									: item.checkinDate && item.reasonDate
									? customMarkerIcon("#FF0000", `${item.sequence + 1}`)
									: item.checkinDate
									? customMarkerIcon("#FFFF00", `${item.sequence + 1}`)
									: customMarkerIcon("#00FFFF", `${item.sequence + 1}`)
								}
							draggable={false}
						>
							<Popup>
								Endereço: {item.address}
								<br />
								Responsavel: {item.responsible ?? item.name }
								<br />
								Contato: {item.contact}
							</Popup>

						</Marker>
					))}
					{order?.driver?.position && (
						<>
						<PulsatingIcon position={[order?.driver?.position?.latitude,order?.driver?.position?.longitude]} angle={order?.driver?.position?.bearing ? Number(order.driver.position.bearing.toFixed(0)) : 0} /> 
						</>
					)}
			  </LeafletMap>
			  <CurrentLocation order={order} />
			  </>
			)}

			</div>

			{/* <div className="flex flex-col gap-2 md:hidden">
				<StatusTooltip
					className="w-full"
					triggerClassName="max-w-full w-full"
					order={order}
				/>
				{order?.statusId !== appState.orderStatus.Cancelado.id &&
					user?.role.id === Number(appState.userRoles.user.value) && (
						<Button
							onClick={() => setShowCancelModal(true)}
							variant="red"
							className="text-base font-black"
						>
							<TiCancel size={20} />
							Cancelar Pedido
						</Button>
					)}
			</div> */}

			<CancelOrderModal
				orderId={Number(orderId)}
				open={showCancelModal}
				setOpen={setShowCancelModal}
			/>

			<AddIncidentModal
				onSuccess={() => {
					void refetch();
				}}
				open={addIncidentModal}
				setOpen={setAddIncidentModal}
				orderId={Number(orderId)}
			/>

			<Modal
				title={`Frete finalizado #${order?.id || ''}`}
				open={rateModal}
				setOpen={setRateModal}
			>
				<div className="flex items-center gap-4">
					<Avatar
						src={driverAvatar?.data.url}
						avatarProps={{
							className: 'w-24 h-24',
						}}
					/>
					<div className="flex flex-col gap-4">
						<h1 className="text-lg font-bold">Como foi sua experiência?</h1>
						<h1>
							Avalie o entregador <b>{order?.driver?.name}</b> e nos ajude a
							melhorar cada vez mais!
						</h1>
					</div>
				</div>
				{order && (
					<a
						href={`https://beebeeoficial.typeform.com/to/jcv4Nh?orderId=${order?.id}&userId=${order?.requester.id}`}
						target="_blank"
						className="flex w-auto justify-end"
						rel="noreferrer"
					>
						<Button className="w-auto ">Avaliar</Button>
					</a>
				)}
			</Modal>
		</div>
	);
};

export interface SocketResponse {
	'content-available': number;
	'no-cache': number;
	message: string;
	title: string;
	icon: string;
	notId: number;
	action: string;
	order: OrderResponseApi;
}
