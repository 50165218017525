import dayjs from 'dayjs';
import { z } from 'zod';
import { dateFilterSchema } from '../../../../../helpers/dateFilterSchema';

export const OrdersFilterSchema = dateFilterSchema.extend({
	id: z.coerce.number().optional(),
	statusId: z
		.array(
			z.object({
				value: z.number().nullable(),
				label: z.string().optional(),
			})
		)
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),

	query: z.string().optional(),
	orderDate: z
		.any()
		.optional()
		.transform(
			(value) => value && dayjs(value).hour(0).minute(0).toISOString()
		),
	operation: z.coerce.string().optional().nullable(),
	orderTypeId: z.number().optional().nullable(),
	categoryId: z.number().optional().nullable(),
	geofenceId: z.number().optional().nullable(),
});

export type OrdersFilterSchemaType = z.infer<typeof OrdersFilterSchema>;
