/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import * as RAccordion from '@radix-ui/react-accordion';
import { Icon } from '../Icon/Icon';
import { Link, useLocation } from 'react-router-dom';
import './styles.css';
import { twMerge } from 'tailwind-merge';
import { sideBarAtom } from '../SideBar';
import { useAtom } from 'jotai';

interface AccordionProps {
	items: Array<{
		options: Array<{ path: string; label: string; required?: boolean }>;
		label: string;
		icon?: React.ReactNode;
		required?: boolean;
		path?: string;
	}>;
}

export const Accordion: React.FC<AccordionProps> = ({ items }) => {
	const [isSidebarOpen, setIsSidebarOpen] = useAtom(sideBarAtom);
	const [value, setValue] = React.useState<string>();
	const { pathname } = useLocation();

	useEffect(() => {
		if (!isSidebarOpen) setValue('');
	}, [isSidebarOpen]);

	return (
		<RAccordion.Root
			className="AccordionRoot"
			collapsible
			type="single"
			value={value}
			onValueChange={setValue}
		>
			{items
				.filter((item) => !item.required)
				.map((item, index) => (
					<RAccordion.Item
						key={index}
						className="AccordionItem"
						value={item.label}
						onClick={() => {
							if (!isSidebarOpen && item.options.length) setIsSidebarOpen(true);
						}}
					>
						{item.path ? (
							<Link
								to={item.path}
								className={`flex items-center gap-2 p-4 font-semibold transition-all hover:bg-neutral-900 hover:text-yellow-300 ${
									pathname === item.path ? 'bg-neutral-900 text-yellow-300' : ''
								}`}
								onClick={() => {
									if (isSidebarOpen) setIsSidebarOpen(false);
								}}
							>
								<span className="h-[24px] w-[24px]">{item.icon}</span>
								<p className={isSidebarOpen ? 'truncate' : 'hidden'}>
									{item.label}
								</p>
							</Link>
						) : (
							<>
								<AccordionTrigger
									className={`${
										item.options.some((menu) => menu.path === pathname)
											? 'bg-neutral-900 text-yellow-300'
											: ''
									}`}
								>
									<div className="flex flex-row items-center gap-2">
										{item.icon}
										{isSidebarOpen && (
											<span className="truncate font-semibold">
												{item.label}
											</span>
										)}
									</div>
								</AccordionTrigger>
								<AccordionContent>
									{item.options
										.filter((item) => !item.required)
										.map((menu, index) => (
											<div
												key={index}
												onClick={() => {
													if (isSidebarOpen) setIsSidebarOpen(false);
												}}
											>
												<Link
													to={menu.path}
													onBeforeInputCapture={() => {}}
													className={`duration-400 flex items-center p-4 py-2 font-semibold transition-all hover:bg-neutral-900 hover:text-yellow-300 ${
														pathname === menu.path
															? 'bg-neutral-900 text-yellow-300'
															: ''
													}`}
												>
													<p className="ml-4 truncate">{menu.label}</p>
												</Link>
											</div>
										))}
								</AccordionContent>
							</>
						)}
					</RAccordion.Item>
				))}
		</RAccordion.Root>
	);
};

interface AccordionTriggerProps
	extends React.ComponentPropsWithoutRef<'button'> {
	children: React.ReactNode;
	className?: string;
	side?: 'left' | 'right';
}

export const AccordionTrigger = React.forwardRef<
	HTMLButtonElement,
	AccordionTriggerProps
>(({ children, className, side = 'right', ...props }, forwardedRef) => (
	<RAccordion.Header className="AccordionHeader">
		<RAccordion.Trigger
			className={twMerge(
				'AccordionTrigger flex w-full items-center gap-1 p-4 transition-all hover:bg-neutral-900 hover:text-yellow-300',
				side === 'right' ? 'justify-between' : 'gap-2',
				className
			)}
			{...props}
			ref={forwardedRef}
		>
			{side === 'left' && (
				<Icon name="chevron-down" size={18} className="AccordionChevron" />
			)}
			{children}
			{side === 'right' && (
				<Icon name="chevron-down" size={18} className="AccordionChevron" />
			)}
		</RAccordion.Trigger>
	</RAccordion.Header>
));

interface AccordionContentProps extends React.ComponentPropsWithoutRef<'div'> {
	children: React.ReactNode;
	className?: string;
}

export const AccordionContent = React.forwardRef<
	HTMLDivElement,
	AccordionContentProps
>(({ children, className, ...props }, forwardedRef) => (
	<RAccordion.Content
		className={twMerge(
			'AccordionContent overflow-hidden bg-yellow-200',
			className
		)}
		{...props}
		ref={forwardedRef}
	>
		<div>{children}</div>
	</RAccordion.Content>
));
