import { createStore } from 'zustand';
import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

interface AppState {
	geofenceId?: number;
	geofence: Geofence[];
	cancelReasons?: CancelReasons[];
	setGeofenceId: (geofenceId: number) => void;
	setGeofence: (geofence: Geofence[]) => void;
	setCancelReasons: (cancelReasons: CancelReasons[]) => void;
}

export const useAppState = createWithEqualityFn<AppState>()(
	(set, get) => ({
		geofence: [],
		setGeofenceId: (geofenceId: number) => set({ geofenceId }),
		setGeofence: (geofence: Geofence[]) => set({ geofence }),
		setCancelReasons: (cancelReasons: CancelReasons[]) => {
			cancelReasons.sort((a, b) => {
				if (a.id === 1001) return 1;
				if (b.id === 1001) return -1;
				return 0;
			});

			set({ cancelReasons });
		},
	}),
	shallow
);

const clientOrderCancellations = {
	RequestDeceived: 1000,
	Others: 1001,
	DelayAcceptOrder: 1002,
	DriverTooFar: 1003,
	DriverLateToArrive: 1004,
	DontNeedAnymore: 1005,
	DidntLikeDriver: 1006,
};

export const appState = {
	regions: {
		ES: {
			label: 'ES',
			value: '1',
		},
		RJ: {
			label: 'RJ',
			value: '2',
		},
		BH: {
			label: 'BH',
			value: '3',
		},
		SP: {
			label: 'SP',
			value: '4',
		},
		Campinas: {
			label: 'Campinas',
			value: '5',
		},
		FOR: {
			label: 'FOR',
			value: '6',
		},
		REC: {
			label: 'REC',
			value: '7',
		},
		GYN: {
			label: 'GYN',
			value: '8',
		},
		POA: {
			label: 'POA',
			value: '9',
		},
		CWB: {
			label: 'CWB',
			value: '10',
		},
		Natal: {
			label: 'Natal',
			value: '11',
		},
		RP: {
			label: 'RP',
			value: '12',
		},
		Mexico: {
			label: 'México',
			value: '13',
		},
	} as const,
	isTrained: {
		Treinado: {
			label: 'Treinado',
			value: true,
		},
		'Sem Treinamento': {
			label: 'Sem Treinamento',
			value: false,
		},
	} as const,
	access: {
		'Acesso Bloqueado': {
			label: 'Acesso Bloqueado',
			value: true,
		},
		'Acesso Desbloqueado': {
			label: 'Acesso Desbloqueado',
			value: false,
		},
	} as const,
	bikerStatus: {
		'Biker Aguardando Aprovação': {
			value: '1',
			label: 'Biker aguardando aprovação',
		},
		'Biker Rejeitado': {
			value: '2',
			label: 'Biker Rejeitado',
		},
		'Biker Ativo': {
			value: '3',
			label: 'Biker Aprovado',
		},
	} as const,
	driverStatus: {
		'Motorista Aguardando Aprovação': {
			value: '1',
			label: 'Motorista aguardando aprovação',
		},
		'Motorista Rejeitado': {
			value: '2',
			label: 'Motorista rejeitado',
		},
		'Motorista Ativo': {
			value: '3',
			label: 'Motorista Aprovado',
		},
	} as const,
	accountStatus: {
		'Conta ativa': {
			value: true,
			label: 'Conta ativa',
		},
		'Conta inativa': {
			value: false,
			label: 'Conta inativa',
		},
	} as const,
	tags: {
		'Cartão amarelo': {
			value: '1',
			label: 'Cartão amarelo',
		},
		'Analisado 1': {
			value: '2',
			label: 'Analisado 1',
		},
		'Analisado 2': {
			value: '3',
			label: 'Analisado 2',
		},
		'Analisado 3': {
			value: '4',
			label: 'Analisado 3',
		},
		'Analisado 4': {
			value: '5',
			label: 'Analisado 4',
		},
		'Analisado 5': {
			value: '6',
			label: 'Analisado 5',
		},
		Limbo: {
			value: '7',
			label: 'Limbo',
		},
		'Não atende ao telefone': {
			value: '8',
			label: 'Não atende ao telefone',
		},
		'Atraso acima de 30 mins': {
			value: '9',
			label: 'Atraso acima de 30 mins',
		},
		Ignorancia: {
			value: '10',
			label: 'Ignorância',
		},
		'Saiu da rota': {
			value: '11',
			label: 'Saiu da rota',
		},
		'Não marcou pontos': {
			value: '12',
			label: 'Não marcou pontos',
		},
		'Cancelou corrida': {
			value: '13',
			label: 'Cancelou corrida',
		},
		Banido: {
			value: '14',
			label: 'Banido',
		},
	} as const,
	paymentType: {
		Boleto: {
			id: 1,
			name: 'Boleto',
			description: 'Pagamento com boleto bancário',
		},
		'Cartão de Crédito': {
			id: 2,
			name: 'Cartão de Crédito',
			description: 'Pagamento com cartão de crédito',
		},
		'Cartão de Débito': {
			id: 3,
			name: 'Cartão de Débito',
			description: 'Pagamento com cartão de débito',
		},
		'Ao motorista': {
			id: 4,
			name: 'Ao motorista',
			description: 'Pagamento direto ao motorista',
		},
		'Saldo em Carteira': {
			id: 5,
			name: 'Saldo em Carteira',
			description: 'Pagamento com saldo em carteira',
		},
	} as const,
	orderStatus: {
		Rascunho: {
			id: 1,
			name: 'Rascunho',
			description:
				'Pedido foi criado pelo usuário, mas ainda não foi enviado para o pool',
		},
		'Aguardando motorista': {
			id: 2,
			name: 'Aguardando motorista',
			description: 'Pedido solicitado e aguardando aceite de motorista',
		},
		Agendado: {
			id: 4,
			name: 'Agendado',
			description: 'Pedido agendado',
		},
		'Em andamento': {
			id: 5,
			name: 'Em andamento',
			description: 'Pedido em andamento',
		},
		Finalizado: {
			id: 6,
			name: 'Finalizado',
			description: 'Pedido finalizado',
		},
		Cancelado: {
			id: 7,
			name: 'Cancelado',
			description: 'Pedido cancelado',
		},
	} as const,
	orderTypes: {
		'Rota fixa': { value: '1', label: 'Rota fixa', icon: 'icon-route' },
		'Rota livre': { value: '2', label: 'Rota livre', icon: 'icon-pin-2' },
		'Por hora': { value: '3', label: 'Por hora', icon: 'icon-clock' },
	} as const,
	orderOperations: {
		Imediato: { value: 'immediate', label: 'Imediato', icon: 'icon-truck' },
		Agendado: {
			value: 'scheduled',
			label: 'Agendado',
			icon: 'icon-make-freight',
		},
	} as const,
	userRoles: {
		sadmin: { value: '1', label: 'Super Admin' },
		admin: { value: '2', label: 'Admin' },
		user: { value: '3', label: 'Cliente' },
	} as const,
	approvalStatus: {
		'Aguardando aprovação': { value: '1', label: 'Aguardando aprovação' },
		Rejeitado: { value: '2', label: 'Rejeitado' },
		Aprovado: { value: '3', label: 'Aprovado' },
	} as const,
	availability: {
		Indisponivel: { value: '0', label: 'Indisponível' },
		'Fazendo frete': { value: '1', label: 'Fazendo frete' },
		'Disponivel e offline': { value: '2', label: 'Disponível e offline' },
		'Disponivel e online': { value: '3', label: 'Disponível e online' },
	} as const,
	userProfiles: {
		Motorista: { value: '0', label: 'Motorista' },
		Empresa: { value: '1', label: 'Empresa' },
		Fretista: { value: '2', label: 'Fretista' },
		'Pessoa fisica': { value: '3', label: 'Pessoa física' },
		Biker: { value: '4', label: 'Biker' },
	} as const,
	companyTypes1: {
		Cliente: { value: '0', label: 'Cliente' },
		Fretista: { value: '1', label: 'Fretista' },
	} as const,
	transactionStatus: {
		Canceled: 1, // Transação Cancelada
		Approved: 2, // Transação finalizada, completada,
		Denied: 3, // Transação recusada
		Pending: 4, // Pendente
		Error: 5, // Error durante o processamento
		NotProcessed: 6, // Transação não processada
		Waiting: 7, // Transação aguardando pagamento do cliente
		Expired: 8, // Transação expirada, pix.: exemplo
		Authorized: 9, // Transação de confirmação tardia autorizada
		Confirmed: 10, // Transação de confirmação tardia confirmada
	} as const,
	clientCancellationsIds: Object.values(clientOrderCancellations),
};

interface AppStateStore {
	config: WebConfig;
	setConfig: (config: WebConfig) => void;
}

export const appStateStore = createStore<AppStateStore>((set, get) => ({
	config: {
		envName: 'local',
		api: 'http://localhost:8000/api/v1',
		apiLocations: 'https://api.dev.beebee.com.br/location/v1',
		tokenName: 'bee-jwt-token-dev',
		apiPush: 'https://push.dev.beebee.com.br/api/v1/push',
		googleMaps: {
			apiKey: 'AIzaSyBxGCvSzTrzrdwS0JqpvZo2EPiUCghdFhg',
			preferStaticApi: false,
		},
		pusherAppKey: '54740a98d7fbbcdc3804',
		pusherCluster: 'us2',
		pusherAuth: 'https://api.dev.beebee.com.br/api/v1/broadcast/auth',
		freights: {
			maxLocationsLength: '25',
			maxStopsGoal: 150,
			minStopsGoal: 1,
			minScheduleHoursAntecedence: 180,
		},
	},
	setConfig: (config) => set({ config }),
}));

export interface Geofence {
	id: number;
	name: string;
	centerOfBounds: string[];
	coordinates: string[][];
	description: string;
	state: {
		id: number;
		name: string;
		abbr: string;
	};
}

interface CancelReasons {
	id: number;
	description: string;
	hasObservations: boolean;
	showAdmin: boolean;
	showClient: boolean;
}

interface GoogleMaps {
	apiKey: string;
	preferStaticApi: boolean;
}

interface Freights {
	maxLocationsLength: string;
	minStopsGoal: number;
	maxStopsGoal: number;
	minScheduleHoursAntecedence: number;
}

interface WebConfig {
	envName: string;
	api: string;
	apiLocations: string;
	tokenName: string;
	apiPush: string;
	googleMaps: GoogleMaps;
	pusherAppKey: string;
	pusherCluster: string;
	pusherAuth: string;
	freights: Freights;
}
