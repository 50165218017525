export default function validarCNPJ(cnpj: string): boolean {
	cnpj = cnpj.replace(/[^\d]+/g, '');

	if (cnpj.length !== 14) {
		return false;
	}

	let soma = 0;
	let peso = 2;

	for (let i = 11; i >= 0; i--) {
		soma += Number(cnpj.charAt(i)) * peso;
		peso = peso === 9 ? 2 : peso + 1;
	}

	const digitoVerificador1 = soma % 11 < 2 ? 0 : 11 - (soma % 11);

	soma = 0;
	peso = 2;

	for (let i = 12; i >= 0; i--) {
		soma += Number(cnpj.charAt(i)) * peso;
		peso = peso === 9 ? 2 : peso + 1;
	}

	const digitoVerificador2 = soma % 11 < 2 ? 0 : 11 - (soma % 11);

	return (
		Number(cnpj.charAt(12)) === digitoVerificador1 &&
		Number(cnpj.charAt(13)) === digitoVerificador2
	);
}
