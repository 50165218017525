import React from 'react';
import { toast } from 'react-toastify';
import { IoCheckmark } from 'react-icons/io5';
import Button from '../../../../components/Button/Button';
import {
	Form,
	FormHeader,
	Input,
	useZodForm,
} from '../../../../components/FormElements';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { toastMessage } from '../../../../helpers/toastMessage';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import {
	PersonalInfoSchema,
	PersonalInfoSchemaType,
} from '../schemas/PersonalInfoSchema';
import { UpdateAvatar } from './UpdateAvatar';
import { twMerge } from 'tailwind-merge';

interface PersonalInfoProps {
	className?: string;
}

export const PersonalInfo: React.FC<PersonalInfoProps> = ({ className }) => {
	const user = useUserState((state) => state.user);
	const triggerRefetch = useUserState((state) => state.triggerRefetch);

	const form = useZodForm({
		schema: PersonalInfoSchema,
		defaultValues: {
			name: user?.name,
			email: user?.email,
			cellphone: user?.cellphone,
			cpf: user?.cpf,
			pix: user?.pix || '',
		},
	});

	const {
		register,
		formState: { errors },
	} = form;

	const handleUpdate = async (data: PersonalInfoSchemaType): Promise<void> => {
		try {
			await api.put(`/users/${String(user?.id)}`, {
				id: user?.id,
				roleId: user?.roleId,
				active: user?.active,
				blocked: user?.blocked,
				phone: user?.phone,
				...data,
			});
			toast.success(toastMessage.success.updated);
			triggerRefetch();
		} catch (err) {
			toast.error('Erro ao atualizar os dados.');
		}
	};

	return (
		<WhiteContainer
			divProps={{
				className,
			}}
		>
			<Form form={form} onSubmit={handleUpdate}>
				<FormHeader className="mb-4" title="Informações pessoais" />
				<div className="flex flex-col gap-4 md:flex-row">
					<div className="grid h-fit w-full grid-cols-4 gap-4">
						<Input
							className="col-span-full sm:col-span-2"
							{...register('name')}
							label="Nome"
							disabled
						/>
						<Input
							className="col-span-full sm:col-span-2"
							{...register('email')}
							label="Email"
							errorMessage={errors.email?.message}
						/>
						<div className="col-span-full sm:col-span-2">
							<Input {...register('cpf')} label="CPF" disabled />
							<p className="mt-1.5 text-xs text-neutral-500">
								Por motivos de segurança, não é possível alterar seu CPF
							</p>
						</div>
						<div className="col-span-full sm:col-span-2">
							<Input {...register('cellphone')} label="Celular" disabled />
							<span className="mt-1.5 text-xs text-neutral-500">
								<a
									href="https://beebeelogistica.freshdesk.com/support/home"
									target="_blank"
									className="cursor-pointer underline hover:text-neutral-700"
									rel="noreferrer"
								>
									Entre em contato
								</a>{' '}
								com Bee Bee para alterar o celular
							</span>
						</div>
						<Input
							className={twMerge(
								'col-span-full sm:col-span-2',
								user?.isDriver ? '' : 'hidden'
							)}
							{...register('pix')}
							label="Chave Pix"
						/>
					</div>
					<UpdateAvatar />
				</div>
				<Button
					type="submit"
					className="mt-4 w-auto"
					disabled={!form.formState.isDirty}
				>
					<IoCheckmark size={24} />
					Salvar alterações
				</Button>
			</Form>
		</WhiteContainer>
	);
};
