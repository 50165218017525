import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { OrderResponseApi } from '../../../services/angular/types/Order';
import { LocationsAccordion } from '../../Order/LocationsAccordion';
import { twMerge } from 'tailwind-merge';

interface LocationProps {
	children: React.ReactNode;
	order: OrderResponseApi;
	className?: string;
}

export const Location = ({ children, order, className }: LocationProps) => {
	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger
				className={twMerge(
					'flex w-full items-center gap-1 rounded focus:outline-none',
					className
				)}
			>
				{children}
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content className="mr-4 max-h-[50vh] w-[calc(100vw-32px)] overflow-y-scroll rounded bg-white p-4 text-neutral-0 shadow shadow-neutral-0 md:w-auto">
					<div className="flex items-end justify-between font-bold">
						<h1 className="text-lg ">Rota</h1>
						<p>
							{order.locations.length}
							{order.locations.length > 1 ? ` Locais` : ` Local`}
						</p>
						<p className="text-lg text-orange">#{order.id}</p>
					</div>
					<hr className="mt-1 text-neutral-500" />
					<LocationsAccordion order={order} />
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};
