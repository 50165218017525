import dayjs from 'dayjs';
import { z } from 'zod';

// Sempre que editar esse schema, lembre-se de editar o schema em index.tsx

export const RequestFreightSchema = z.object({
	statusId: z.number().optional(),
	categoryId: z.number(),
	categoryType: z.number(),
	geofenceId: z.number(),
	scheduleDate: z
		.any()
		.nullable()
		.optional()
		.transform((value, ctx) => {
			if (!value) return value;
			if (typeof value === 'object') {
				const date = dayjs(value);

				return date.toISOString();
			}
			return value;
		}),
	locations: z.array(
		z.object({
			id: z.string().or(z.number()).optional(),
			sequence: z.number().optional(),
			lat: z.number().optional(),
			lng: z.number().optional(),
			address: z.string({
				required_error: 'Endereço é obrigatório',
				invalid_type_error: 'Endereço inválido',
			}),
			number: z.string().default('0'),
			name: z.string().optional().nullable(),
			contact: z.string().optional().nullable(),
			responsible: z.string().optional().nullable(),
			observations: z.string().optional().nullable(),
		})
	),
	orderTypeId: z.number().default(1),
	stopsGoal: z.string().or(z.number()).nullable().optional(),
	searchOnlyFavoriteDrivers: z.boolean().default(false),
	paymentTypeId: z.number().nullable().optional(),
});

export type RequestFreightLocation = z.input<
	typeof RequestFreightSchema
>['locations'][0];
