import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { RegisterButton } from '../../../../components/Button/RegisterButton';
import { PageHeader } from '../../../../components/PageHeader';
import { Table, TableActions } from '../../../../components/Table/Table';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import { SortOptions } from '../../../../services/angular/types/Params';
import { useAlert } from '../../../../contexts/AlertContext';
import { Vehicle } from '../../../../services/angular/types/Order';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const AngularVehicles: React.FC = () => {
	const alert = useAlert();
	const navigate = useNavigate();
	const company = useUserState((state) => state.company);

	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});

	const { data, isLoading, isFetching, refetch } = useQuery(
		['vehicles', params, company?.id],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/companies/${company?.id}/vehicles?include=category,company`,
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
					},
				}
			),
		{
			refetchOnMount: 'always',
		}
	);

	const deleteVehicle = async (id: number) => {
		alert.onCustom({
			title: 'Excluir veiculo',
			message: 'Deseja realmente excluir o veículo?',
			confirm: {
				label: 'Excluir',
				onClick: async () => {
					try {
						await api.delete(`/companies/${company?.id || ''}/vehicles/${id}`);
						toast.success('Veículo excluído com sucesso');
						void refetch();
					} catch (error) {
						toast.error('Erro ao excluir veículo');
					}
				},
			},
		});
	};

	return (
		<>
			<PageHeader
				title="Veículos"
				description="Relação de veículos cadastrados"
			>
				<RegisterButton
					className="w-auto"
					onClick={() => navigate('/negocios/veiculos/novo')}
				/>
			</PageHeader>
			<WhiteContainer>
				<Table<Vehicle>
					isLoading={isLoading || isFetching}
					data={data?.data.data || []}
					total={data?.data.meta.pagination.total || 0}
					onParamsChange={(params) => {
						setParams(params);
					}}
					disabledSort={{
						updated_at: true,
					}}
					columns={[
						{
							// @ts-expect-error
							accessor: 'category.name',
							Header: 'Nome',
						},
						{
							accessor: 'model',
							Header: 'Veículo',
						},
						{
							accessor: 'plate',
							Header: 'Placa',
							Cell: ({ value }) => {
								return (
									<div className="w-min rounded-md border-[1px] p-1 text-xs">
										{value}
									</div>
								);
							},
						},
						{
							Header: 'Status',
							accessor: 'status',
							Cell: ({ row }) => {
								const { status } = row.original;

								const statusObj =
									status === 1
										? {
												message: `Aguardando aprovação`,
												className: 'bg-orange',
										  }
										: status === 2
										? {
												message: `Rejeitado`,
												className: 'bg-terracota-500',
										  }
										: {
												message: `Aprovado`,
												className: 'bg-green',
										  };

								return (
									<div
										className={twMerge(
											'w-min rounded px-1.5 py-1 text-center text-xs leading-3 text-white',
											statusObj.className
										)}
									>
										{statusObj.message}
									</div>
								);
							},
						},
						{
							accessor: 'updated_at',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => (
								<TableActions
									row={row}
									actions={[
										{
											icon: 'edit',
											label: 'Editar',
											href: `/negocios/veiculos/${row.original.id}`,
										},
										{
											icon: 'trash',
											label: 'Excluir',
											onAction: (data) => {
												void deleteVehicle(data.id);
											},
										},
									]}
								/>
							),
						},
					]}
				/>
			</WhiteContainer>
		</>
	);
};

export default AngularVehicles;
