import React from 'react';
import { toast } from 'react-toastify';
import Button from '../../../../../components/Button/Button';
import {
	FormHeader,
	Input,
	Select,
	useZodForm,
} from '../../../../../components/FormElements';
import { PageHeader } from '../../../../../components/PageHeader';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { useAlert } from '../../../../../contexts/AlertContext';

import { cpfMask } from '../../../../../helpers/mask/cpfMask';
import { RegexHelper } from '../../../../../helpers/regex/RegexHelper';
import cpfValidation from '../../../../../helpers/validation/cpfValidation';
import useUserState from '../../../../../services/angular/angularUserState';
import { api } from '../../../../../services/angular/axios';
import { AngularAddUserSchema } from '../schemas/AngulasAddUserSchema';
import AngularCompanyUsersTable from './AngularCompanyUsersTable';

const AddNewUserForm: React.FC = () => {
	const alert = useAlert();
	const company = useUserState((state) => state.company);
	const form = useZodForm({
		schema: AngularAddUserSchema,
	});
	const {
		register,
		setError,
		setValue,
		handleSubmit,
		formState: { errors },
	} = form;

	const handleAddUser = async (data: any): Promise<void> => {
		try {
			if (!company) return;
			await api.post(`/companies/${company?.id}/users`, {
				cpf: data.cpf,
				isAdmin: data.isAdmin,
			});
			toast.success('Usuário adicionado com sucesso');
		} catch (error) {
			alert.onCustom({
				title: 'Não cadastrado',
				message: `O CPF informado não está cadastrado em nossa base de dados. Solicite ao usuário que efetue o cadastro e tente novamente.`,
				cancel: {
					label: 'Fechar',
				},
			});
		}
	};

	return (
		<>
			<PageHeader
				title="Cadastro de Usuários"
				description="Informações do usuário autorizado a gerenciar seu negócio"
			/>
			<WhiteContainer>
				<div className="flex flex-col justify-between gap-4 divide-neutral-500 lg:flex-row lg:divide-x-2">
					<div className="flex w-full flex-col gap-4">
						<FormHeader
							title="Dados do usuário"
							description="Preencha os dados do usuário para vinculá-lo"
						/>
						<Input
							className="w-full lg:w-1/2"
							label="CPF"
							{...register('cpf', {
								onChange: (event) => {
									setValue('cpf', cpfMask(event.target.value));
									if (RegexHelper.cpf.test(event.target.value)) {
										cpfValidation(event.target.value)
											? setError('cpf', {
													message: '',
											  })
											: setError('cpf', {
													message: 'CPF inválido',
											  });
									}
								},
							})}
							errorMessage={errors.cpf?.message}
						/>
						<Select
							className="w-full lg:w-1/2"
							label="Permissão de acesso"
							controller={{
								control: form.control,
								name: 'isAdmin',
							}}
							options={[
								{ value: true, label: 'Administrador' },
								{ value: false, label: 'Usuário' },
							]}
						/>
						<Button
							className="w-full lg:w-1/2"
							type="submit"
							variant="blue-primary"
							// eslint-disable-next-line @typescript-eslint/no-misused-promises
							onClick={handleSubmit(handleAddUser)}
						>
							Vincular usuário
						</Button>
						<AngularCompanyUsersTable />
					</div>
					<div className="flex h-full w-full flex-col gap-4 px-4 lg:w-1/2">
						<FormHeader title="Sobre as permissões de acesso" />
						<p>
							Você pode incluir usuários na sua conta empresarial, como:
							funcionários, gestores ou parentes.
						</p>
						<p>
							É necessário que o usuário tenha uma conta previamente cadastrada
							na BeeBee. Para cadastrar acesse:
							https://app.beebee.com.br/cadastro
						</p>
						<p>
							Depois de cadastrado, você pode incluir o usuário em sua conta
							empresarial por meio do CPF.
						</p>
						<p>Escolha o perfil:</p>
						<p className="font-bold">Usuário</p>
						<p>◉ Usuários não podem incluir ou excluir outros usuários</p>
						<p className="font-bold">Administrador</p>
						<p>
							◉ Adminitrador possui acesso pleno, podendo incluir ou excluir
							usuários
						</p>
					</div>
				</div>
			</WhiteContainer>
		</>
	);
};

export default AddNewUserForm;
