import React from 'react';
import { SvgProps } from './SvgPropsInterface';

// import { Container } from './styles';

const TruckIcon: React.FC<SvgProps> = ({ color = '#FCF366', size }) => {
	return (
		<svg
			width="100%"
			height={size ?? '100%'}
			viewBox="0 0 101 101"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M42.1668 71.5H58.8335V21.5H8.8335V71.5H21.3335"
				stroke="currentColor"
				strokeWidth="3.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M83.8335 71.5003H92.1668V57.5837C92.1685 55.3943 91.7389 53.2261 90.9024 51.2028C90.066 49.1796 88.8391 47.341 87.2918 45.792L79.6668 38.167H58.8335"
				stroke="currentColor"
				strokeWidth="3.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M58.8335 71.5H63.0002"
				stroke="currentColor"
				strokeWidth="3.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M31.7502 84.0003C37.5031 84.0003 42.1668 79.3366 42.1668 73.5837C42.1668 67.8307 37.5031 63.167 31.7502 63.167C25.9972 63.167 21.3335 67.8307 21.3335 73.5837C21.3335 79.3366 25.9972 84.0003 31.7502 84.0003Z"
				stroke="currentColor"
				strokeWidth="3.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M73.4167 84.0003C79.1696 84.0003 83.8333 79.3366 83.8333 73.5837C83.8333 67.8307 79.1696 63.167 73.4167 63.167C67.6637 63.167 63 67.8307 63 73.5837C63 79.3366 67.6637 84.0003 73.4167 84.0003Z"
				stroke="currentColor"
				strokeWidth="3.3333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default TruckIcon;
