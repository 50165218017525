import { useAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { RequestFreightForm, updateRouteAtom } from '..';
import { Input } from '../../../../../components/FormElements';
import { OrderTypeIcon } from '../../../../../components/Icon/OrderTypeIcon';
import { Tooltip } from '../../../../../components/Tooltip';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';

interface TypeStepProps {
	form: RequestFreightForm;
}

export const TypeStep = ({ form }: TypeStepProps) => {
	const [updateDraft, triggerUpdateDraft] = useAtom(updateRouteAtom);

	const {
		setValue,
		watch,
		formState: { errors },
	} = form;

	const isFirstRender = useRef(true);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		if (watch('orderTypeId') === 1) {
			setValue('stopsGoal', null);
		}
		setValue('locations', []);
	}, [watch('orderTypeId')]);

	const timerRef = useRef<any>(null);

	const handleStopGoalChange = () => {
		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			triggerUpdateDraft(!updateDraft);
		}, 1000);
	};

	return (
		<WhiteContainer className="flex flex-col items-center justify-center gap-4">
			<div className="flex flex-col items-center justify-center text-center">
				<h1 className="text-xl font-bold text-neutral-900">
					{watch('orderTypeId') !== undefined
						? watch('orderTypeId') === 1
							? 'Rota Fixa'
							: 'Rota Livre'
						: 'Tipo de Rota'}
				</h1>
				<p
					className={twMerge(
						'text-neutral-500',
						watch('orderTypeId') ? 'w-full md:w-2/3' : 'w-full'
					)}
				>
					{watch('orderTypeId') ? (
						watch('orderTypeId') === 1 ? (
							<>
								Mais precisão:
								<br /> Você cria a rota ponto-a-ponto e, o valor final do frete
								é exibido.
							</>
						) : (
							<>
								Mais comodidade:
								<br /> Você chama o fretista para coleta e ele faz a própria
								rota. O{' '}
								<span className="font-semibold text-neutral-700">
									VALOR FINAL
								</span>{' '}
								do frete é valor inicial{' '}
								<span className="font-semibold text-neutral-700">+</span>{' '}
								distância percorrida. Ao finalizar o frete, o valor é
								atualizado.
							</>
						)
					) : (
						'Frete com rota fixa ou livre?'
					)}
				</p>
			</div>
			<div className="flex items-center justify-center gap-12">
				<div
					className={twMerge(
						'group flex flex-col items-center justify-center text-center',
						'cursor-pointer transition-colors hover:text-blue',
						watch('orderTypeId') === 1 ? 'text-blue' : 'text-neutral-200'
					)}
					onClick={() => setValue('orderTypeId', 1)}
				>
					<Tooltip
						triggerClassName="cursor-pointer w-24"
						message={
							<span className="text-sm">
								Monte seu roteiro e saiba o quanto vai pagar{' '}
								<span className="underline">ANTES</span> de confirmar o pedido.
							</span>
						}
					>
						<OrderTypeIcon type="1" size={72} />
						Rota Fixa
					</Tooltip>
				</div>

				<div
					className={twMerge(
						'group relative flex flex-col items-center justify-center text-center',
						'cursor-pointer transition-colors hover:text-blue',
						watch('orderTypeId') === 2 ? 'text-blue' : 'text-neutral-200'
					)}
					onClick={() => setValue('orderTypeId', 2)}
				>
					<Tooltip
						triggerClassName="cursor-pointer w-24"
						message={
							<span className="text-sm">
								Chame um entregador sem montar roteiro. Ao valor inicial é
								somada a distância percorrida{' '}
								<span className="underline">DEPOIS</span> que o frete concluir.
							</span>
						}
					>
						<OrderTypeIcon type="2" size={72} />
						Rota Livre
					</Tooltip>
				</div>
			</div>
			{watch('orderTypeId') === 2 && (
				<Input
					className="w-auto self-center"
					type="number"
					label="Quantidades de Notas Fiscais embarcadas"
					infoMessage="Insira a quantidade de notas fiscais a serem embarcadas no frete. Nosso sistema limita o entregador à quantidade de notas que você inputar. Voce pode alterar este valor durante a corrida em: Meus fretes > Detalhes"
					{...form.register('stopsGoal', {
						onChange: () => {
							handleStopGoalChange();
						},
					})}
					errorMessage={errors.stopsGoal?.message}
				/>
			)}
		</WhiteContainer>
	);
};
