import { z } from 'zod';

export const CompaniesFilterSchema = z.object({
	tradingName: z.string().optional(),
	cellphone: z.string().optional(),
	cnpj: z.string().optional(),
	tagsIds: z
		.object({
			label: z.string().optional(),
			value: z.number(),
		})
		.optional()
		.nullable()
		.transform((value) => value?.value),
	companyTypes: z
		.array(
			z.object({
				value: z.number().nullable(),
				label: z.string().optional(),
			})
		)
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
	status: z
		.array(
			z.object({
				value: z.number().nullable(),
				label: z.string().optional(),
			})
		)
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
});

export type CompaniesFilterSchemaType = z.infer<typeof CompaniesFilterSchema>;
