import React, { useEffect, useRef, useState } from 'react';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { z, ZodSchema } from 'zod';
import Button from '../Button/Button';
import { Form } from '../FormElements/Form';
import { Icon } from '../Icon/Icon';
import { BsFilterRight } from 'react-icons/bs';
import { IoOptions } from 'react-icons/io5';
interface FiltersTabProps<T extends ZodSchema<any>> {
	children: React.ReactNode;
	actionsChildren?: React.ReactNode;
	form: UseFormReturn<z.input<T>>;
	onSubmit: SubmitHandler<z.output<T>>;
	clearFilters: () => void;
	className?: string;
	dropDownFilter?: boolean;
}

export function FiltersTab<T extends ZodSchema<any>>({
	onSubmit,
	clearFilters,
	children,
	actionsChildren,
	dropDownFilter,
	form,
	className,
}: FiltersTabProps<T>): JSX.Element {
	const [isOpen, setIsOpen] = useState(false);

	const filterRef = useRef<HTMLDivElement>(null);

	function handleFocusOut(event: any): void {
		if (!filterRef.current?.contains(event.relatedTarget)) {
			setIsOpen(false);
		}
	}

	useEffect(() => {
		filterRef.current?.focus();

		filterRef.current?.addEventListener('focusout', handleFocusOut);

		return () => {
			filterRef.current?.removeEventListener('focusout', handleFocusOut);
		};
	}, [isOpen]);

	return (
		<div className="flex">
			{dropDownFilter ? (
				<div>
					<IoOptions
						size={44}
						className=" absolute right-8 top-4 z-30 cursor-pointer rounded-full border border-neutral-0 bg-yellow-500 p-2 text-neutral-0 transition-opacity hover:opacity-75"
						onClick={() => setIsOpen(true)}
					/>
					<div
						ref={filterRef}
						tabIndex={-1}
						className={twMerge(
							'absolute right-0 top-0 z-50 h-[calc(100vh-64px)] w-fit overflow-auto  bg-neutral-100 p-8 transition-all duration-300 ease-in-out',
							isOpen ? 'translate-x-0' : 'translate-x-full'
						)}
					>
						<BsFilterRight
							size={32}
							onClick={() => setIsOpen(false)}
							className=" absolute right-8 top-4 cursor-pointer transition-opacity hover:opacity-75 "
						/>
						<Form<T>
							form={form}
							onSubmit={onSubmit}
							className={twMerge(
								'bg-neutral-400] flex flex-col  gap-2  rounded ',
								className
							)}
						>
							{children}

							<div className="flex items-end gap-2">
								<Button className="w-auto py-1" type="submit">
									<Icon name="filter" size={20} />
									Filtrar
								</Button>
								<Button
									className="w-auto py-1"
									type="reset"
									variant="red"
									onClick={clearFilters}
									aria-label="Limpar"
								>
									<Icon name="backspace" size={20} />
								</Button>
							</div>
							{actionsChildren && actionsChildren}
						</Form>
					</div>
				</div>
			) : (
				<Form<T>
					form={form}
					onSubmit={onSubmit}
					className={twMerge(
						'flex w-fit flex-wrap gap-2 rounded bg-neutral-100 px-4 py-2',
						className
					)}
				>
					{children}
					<div className="flex items-end gap-2">
						<Button
							className="w-auto py-1"
							type="submit"
							disabled={form.formState.isSubmitting}
						>
							<Icon name="filter" size={20} />
							Filtrar
						</Button>
						<Button
							className="w-auto py-1"
							type="reset"
							variant="red"
							onClick={clearFilters}
							aria-label="Limpar"
							disabled={form.formState.isSubmitting}
						>
							<Icon name="backspace" size={20} />
						</Button>
					</div>
					{actionsChildren && actionsChildren}
				</Form>
			)}
		</div>
	);
}
