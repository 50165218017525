import React, { useMemo } from 'react';
import Select from 'react-select';
import { MdOutlineNavigateNext, MdOutlineLastPage } from 'react-icons/md';

export interface PaginationProps {
	total: number;
	take: number;
	setTake: React.Dispatch<React.SetStateAction<number>>;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	selectOptions?: Array<{ value: string; label: string }>;
}

export const Pagination = ({
	total,
	take,
	setTake,
	page,
	setPage,
	selectOptions,
}: PaginationProps) => {
	const paginationRange = usePagination({
		currentPage: page,
		siblingCount: 1,
		total,
		take,
	});

	if (page <= 0) return null;

	const lastPage = useMemo(
		() => paginationRange[paginationRange.length - 1],
		[paginationRange]
	);

	const getCurrentItensCount = (): string => {
		const pageMax = page * take;
		const totalMax = pageMax > total ? total : pageMax;
		return `${1 + take * (page - 1)} - ${totalMax} de ${total}`;
	};

	return (
		<div className="mt-2 flex select-none flex-col gap-2 md:flex-row">
			<div className="flex items-center justify-center gap-1 text-sm">
				<MdOutlineLastPage
					className={`rotate-180 cursor-pointer rounded hover:bg-neutral-200 ${
						page === 1 ? 'pointer-events-none opacity-50' : ''
					}`}
					size={24}
					onClick={() => {
						if (page !== 1) setPage(1);
					}}
				/>
				<MdOutlineNavigateNext
					className={`rotate-180 cursor-pointer rounded hover:bg-neutral-200 ${
						page === 1 ? 'pointer-events-none opacity-50' : ''
					}`}
					size={24}
					onClick={() => {
						if (page > 1) setPage((prev) => prev - 1);
					}}
				/>
				{paginationRange.map((item, index) => (
					<span key={index}>
						{item === DOTS ? (
							<span className="flex h-6 w-6 cursor-pointer items-center justify-center rounded">
								...
							</span>
						) : (
							<span
								className={`${
									page === item
										? 'bg-neutral-0 text-white'
										: 'cursor-pointer bg-neutral-100 text-neutral-0'
								} flex h-6 min-w-[1.5rem] items-center justify-center rounded px-1 hover:bg-neutral-0 hover:text-white`}
								onClick={() => page !== item && setPage(Number(item))}
							>
								{item}
							</span>
						)}
					</span>
				))}
				<MdOutlineNavigateNext
					className={`cursor-pointer rounded hover:bg-neutral-200 ${
						page === lastPage ? 'pointer-events-none opacity-50' : ''
					}`}
					size={24}
					onClick={() => {
						if (page * take < total) setPage((prev) => prev + 1);
					}}
				/>
				<MdOutlineLastPage
					className={`cursor-pointer rounded hover:bg-neutral-200 ${
						page === lastPage ? 'pointer-events-none opacity-50' : ''
					}`}
					size={24}
					onClick={() => {
						if (page !== lastPage) setPage(lastPage);
					}}
				/>
			</div>
			<div className="flex w-full flex-row justify-between">
				<div className="flex min-w-fit items-center align-middle font-bold text-neutral-900">
					{getCurrentItensCount()}
				</div>

				<div className="flex w-full items-center justify-end text-neutral-700 ">
					<p className="hidden text-sm sm:block">Itens por página:</p>
					<Select
						className="items-center justify-end text-sm text-neutral-400"
						onChange={(value) => {
							setTake(Number(value?.value));
							setPage(1);
						}}
						isSearchable={false}
						menuPlacement="auto"
						defaultValue={{
							label: String(take),
							value: String(take),
						}}
						options={selectOptions}
						styles={{
							control: (styles) => ({
								...styles,
								border: 'none',
								boxShadow: 'none',
								cursor: 'pointer',
							}),
						}}
					/>
				</div>
			</div>
		</div>
	);
};

interface UsePaginationProps {
	total: number;
	take: number;
	siblingCount?: number;
	currentPage: number;
}

const DOTS = -1;

export const usePagination = ({
	total,
	take,
	siblingCount = 1,
	currentPage,
}: UsePaginationProps) => {
	const range = (start: number, end: number) => {
		const length = end - start + 1;
		return Array.from({ length }, (_, idx) => idx + start);
	};

	const paginationRange = useMemo(() => {
		const totalPageCount = Math.ceil(total / take);

		const totalPageNumbers = siblingCount + 5;

		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(
			currentPage + siblingCount,
			totalPageCount
		);

		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = 3 + 2 * siblingCount;
			const leftRange = range(1, leftItemCount);

			return [...leftRange, DOTS, totalPageCount];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = 3 + 2 * siblingCount;
			const rightRange = range(
				totalPageCount - rightItemCount + 1,
				totalPageCount
			);
			return [firstPageIndex, DOTS, ...rightRange];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}

		return [];
	}, [total, take, siblingCount, currentPage]);

	return paginationRange;
};
