import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../components/Header/Header';
import { SideBar, sideBarAtom } from '../components/SideBar';
import { useAtom } from 'jotai';

const AdminLayout: React.FC = () => {
	const [isSidebarOpen] = useAtom(sideBarAtom);

	return (
		<div className="fixed flex min-h-screen w-full flex-auto flex-shrink-0 flex-col overflow-hidden antialiased">
			<Header />

			<SideBar />

			<div className="fixed mt-16 flex h-full w-full flex-col justify-center bg-neutral-50">
				<div
					className={`mb-14 flex flex-grow flex-col items-center overflow-y-scroll p-4 md:px-8 md:py-6 ${
						isSidebarOpen ? 'md:ml-56' : 'md:ml-16'
					} transition-all duration-200`}
				>
					<div className="flex w-full max-w-[116rem] flex-col">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AdminLayout;
