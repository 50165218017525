/* eslint-disable no-extra-boolean-cast */
import { useQuery } from '@tanstack/react-query';
import {
	FormHeader,
	Input,
	Select,
	SwitchButton,
} from '../../../../components/FormElements';
import { PageHeader } from '../../../../components/PageHeader';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { api } from '../../../../services/angular/axios';
import Button from '../../../../components/Button/Button';
import { useState } from 'react';
import { Category } from '../../../../services/angular/types/Order';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { FaExchangeAlt } from 'react-icons/fa';

export const Reasons = {
	chuva_fraca: 1,
	chuva_mediana: 2,
	chuva_forte: 3,
	chuva_alagamentos: 4,
	semana_vix_moto_17h: 5,
	semana_vix_moto_20h: 6,
	semana_vix_car_16h: 7,
	fim_de_semana_vix_moto_sabado_17h: 8,
	fim_de_semana_vix_car_sabado_16h: 9,
	fim_de_semana_vix_moto_sabado_21h: 10,
	fim_de_semana_vix_domingo: 11,
	fim_de_semana_gyn_sp_poa_domingo: 12,
	fim_de_semana_domingo: 13,
	feriado: 14,
} as const;

export const colorMap = {
	[Reasons.feriado]: 'bg-red',
	[Reasons.chuva_fraca]: 'bg-green',
	[Reasons.chuva_mediana]: 'bg-purple-0',
	[Reasons.chuva_forte]: 'bg-blue',
	[Reasons.chuva_alagamentos]: 'bg-orange',
	[Reasons.semana_vix_moto_17h]: 'bg-black',
	[Reasons.semana_vix_moto_20h]: 'bg-secondary-700',
	[Reasons.semana_vix_car_16h]: 'bg-yellow-500',
	[Reasons.fim_de_semana_vix_moto_sabado_17h]: 'bg-primary-900',
	[Reasons.fim_de_semana_vix_car_sabado_16h]: 'bg-terracota-50',
	[Reasons.fim_de_semana_vix_moto_sabado_21h]: 'bg-purple-200',
	[Reasons.fim_de_semana_vix_domingo]: 'bg-yellow-950',
	[Reasons.fim_de_semana_gyn_sp_poa_domingo]: 'bg-primary-50',
	default: 'bg-neutral-500',
};

export function RequesterMultipliers() {
	const [holydayGeofences, setHolydayGeofences] = useState([]);
	const [rainGeofences, setRainGeofences] = useState([]);
	const [rainType, setRainType] = useState<{
		label: string;
		value: number;
	}>();
	const [driverBonus, setDriverBonus] = useState(0);
	const [driverBonusCategories, setDriverBonusCategories] = useState([]);

	const { data: geofences } = useQuery(
		['geofenceselect'],
		async () => await api.get('/geofence')
	);

	const { data: categories, refetch } = useQuery(
		['categories-rq-multipliers'],
		async () =>
			await api.get<Category[]>(`/categories`, {
				params: {
					geofenceId: geofences?.data
						?.map((geofence: any) => geofence.id)
						.join(','),
					include: 'costs,requesterMultiplierReasons',
				},
			}),
		{
			staleTime: 0,
			cacheTime: 0,
			enabled: !!geofences?.data,
		}
	);

	const handleDynamic = async (
		geofences: any,
		active: boolean,
		reason: number
	) => {
		try {
			await api.put('/categories/requester-multiplier', {
				geofenceIds: geofences,
				reasonId: reason,
				active,
			});
			toast.success('Multiplicadores alterados com sucesso!');
			void refetch();
		} catch (error) {
			toast.error('Erro ao alterar multiplicadores!');
		}
	};

	const handleDriverBonus = async (
		categoriesIds: any[],
		driverBonus: number
	) => {
		try {
			await api.put('/categories/driver/bonus', {
				ids: categoriesIds,
				driverBonus,
			});
			toast.success('Multiplicadores alterados com sucesso!');
			void refetch();
		} catch (error) {
			toast.error((error as any)?.response?.data?.error);
		}
	};

	return (
		<>
			<PageHeader
				title="Multiplicadores de preço"
				description="Inserir ou remover multiplicadores de preço"
			/>
			<WhiteContainer className="flex gap-4">
				<FormHeader title="Bônus de motorista" />
				<span className="flex items-end gap-4">
					<Select
						label="Categorias"
						options={categories?.data?.map((category: any) => ({
							label: `${
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								geofences?.data?.find(
									(geofence: any) => geofence?.id === category?.geofenceId
								)?.name
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								} - ${category.name} - `,
							value: category.id,
						}))}
						isMulti
						onChange={(e: any) =>
							setDriverBonusCategories(
								e?.map((category: any) => category.value)
							)
						}
					/>
					<Input
						label="Bônus de motorista"
						leftInputIcon={
							<span className="font-neutral-500 text-xs">%</span>
						}
						type="number"
						// eslint-disable-next-line @typescript-eslint/no-empty-function
						onChange={(e) => {
							setDriverBonus(Number(e.target.value));
						}}
					/>
					<Button
						className="w-1/5"
						variant="green"
						onClick={() =>
							// eslint-disable-next-line @typescript-eslint/no-misused-promises, no-void
							void handleDriverBonus(driverBonusCategories, driverBonus)
						}
					>
						Alterar
					</Button>
				</span>
			</WhiteContainer>
			<br />
			<WhiteContainer className="flex gap-4">
				<FormHeader title="Feriado" />
				<span className="flex items-end gap-4">
					<Select
						label="Geofence"
						options={geofences?.data?.map((geofence: any) => ({
							label: geofence.name,
							value: geofence.id,
						}))}
						isMulti
						onChange={(e: any) =>
							setHolydayGeofences(e?.map((geofence: any) => geofence.value))
						}
					/>
					<Button
						className="w-1/5"
						variant="red"
						onClick={() =>
							// eslint-disable-next-line @typescript-eslint/no-misused-promises, no-void
							void handleDynamic(holydayGeofences, false, Reasons.feriado)
						}
					>
						Desativar
					</Button>
					<Button
						className="w-1/5"
						variant="green"
						onClick={() =>
							// eslint-disable-next-line @typescript-eslint/no-misused-promises, no-void
							void handleDynamic(holydayGeofences, true, Reasons.feriado)
						}
					>
						Ativar
					</Button>
				</span>
				<span>
					<FormHeader title="Marcados com feriado:" />
					{categories?.data
						?.filter(
							(category: any) =>
								category?.requesterMultiplierReasonId === Reasons.feriado
						)
						.map((category: any) => {
							const geofenceName =
								geofences?.data?.find(
									(geofence: any) => geofence?.id === category?.geofenceId
								)?.name ?? '';
							return (
								<span
									key={category?.id}
									className="flex items-center gap-4 rounded transition-all hover:bg-neutral-50"
								>
									<h1 className="flex gap-1">
										{category?.name} {geofenceName}:{' '}
										<p className="text-black">
											{category?.requesterMultiplier}
										</p>
									</h1>
								</span>
							);
						})}
				</span>
			</WhiteContainer>
			<br />
			<WhiteContainer className="flex gap-4">
				<FormHeader title="Dia de semana" />
				<SwitchButton
					label="Vix moto 17h"
					checked={
						!!categories?.data?.some(
							(category: any) =>
								category?.requesterMultiplierReasonId ===
								Reasons.semana_vix_moto_17h
						)
					}
					onChange={() => {
						void handleDynamic(
							[
								geofences?.data?.find(
									(geofence: any) => geofence?.name === 'ES'
								)?.id,
							],
							// eslint-disable-next-line no-unneeded-ternary
							!!categories?.data?.some(
								(category: any) =>
									category?.requesterMultiplierReasonId ===
									Reasons.semana_vix_moto_17h
							)
								? false
								: true,
							Reasons.semana_vix_moto_17h
						);
					}}
				/>
				<SwitchButton
					label="Vix carro 16h"
					checked={
						!!categories?.data?.some(
							(category: any) =>
								category?.requesterMultiplierReasonId ===
								Reasons.semana_vix_car_16h
						)
					}
					onChange={() => {
						void handleDynamic(
							[
								geofences?.data?.find(
									(geofence: any) => geofence?.name === 'ES'
								)?.id,
							],
							// eslint-disable-next-line no-unneeded-ternary
							!!categories?.data?.some(
								(category: any) =>
									category?.requesterMultiplierReasonId ===
									Reasons.semana_vix_car_16h
							)
								? false
								: true,
							Reasons.semana_vix_car_16h
						);
					}}
				/>
			</WhiteContainer>
			<br />
			<WhiteContainer className="flex gap-4">
				<FormHeader title="Fim de semana" />
				<SwitchButton
					label="Fim de semana Vix moto sábado 17h"
					checked={
						!!categories?.data?.some(
							(category: any) =>
								category?.requesterMultiplierReasonId ===
								Reasons.fim_de_semana_vix_moto_sabado_17h
						)
					}
					onChange={() => {
						void handleDynamic(
							[
								geofences?.data?.find(
									(geofence: any) => geofence?.name === 'ES'
								)?.id,
							],
							// eslint-disable-next-line no-unneeded-ternary
							!!categories?.data?.some(
								(category: any) =>
									category?.requesterMultiplierReasonId ===
									Reasons.fim_de_semana_vix_moto_sabado_17h
							)
								? false
								: true,
							Reasons.fim_de_semana_vix_moto_sabado_17h
						);
					}}
				/>
				<SwitchButton
					label="Fim de semana Vix carro sábado 16h"
					checked={
						!!categories?.data?.some(
							(category: any) =>
								category?.requesterMultiplierReasonId ===
								Reasons.fim_de_semana_vix_car_sabado_16h
						)
					}
					onChange={() => {
						void handleDynamic(
							[
								geofences?.data?.find(
									(geofence: any) => geofence?.name === 'ES'
								)?.id,
							],
							// eslint-disable-next-line no-unneeded-ternary
							!!categories?.data?.some(
								(category: any) =>
									category?.requesterMultiplierReasonId ===
									Reasons.fim_de_semana_vix_car_sabado_16h
							)
								? false
								: true,
							Reasons.fim_de_semana_vix_car_sabado_16h
						);
					}}
				/>
				<SwitchButton
					label="Fim de semana Vix moto sábado 21h"
					checked={
						!!categories?.data?.some(
							(category: any) =>
								category?.requesterMultiplierReasonId ===
								Reasons.fim_de_semana_vix_moto_sabado_21h
						)
					}
					onChange={() => {
						void handleDynamic(
							[
								geofences?.data?.find(
									(geofence: any) => geofence?.name === 'ES'
								)?.id,
							],
							// eslint-disable-next-line no-unneeded-ternary
							!!categories?.data?.some(
								(category: any) =>
									category?.requesterMultiplierReasonId ===
									Reasons.fim_de_semana_vix_moto_sabado_21h
							)
								? false
								: true,
							Reasons.fim_de_semana_vix_moto_sabado_21h
						);
					}}
				/>
				<SwitchButton
					label="Fim de semana Vix domingo"
					checked={
						!!categories?.data?.some(
							(category: any) =>
								category?.requesterMultiplierReasonId ===
								Reasons.fim_de_semana_vix_domingo
						)
					}
					onChange={() => {
						void handleDynamic(
							[
								geofences?.data?.find(
									(geofence: any) => geofence?.name === 'ES'
								)?.id,
							],
							// eslint-disable-next-line no-unneeded-ternary
							!!categories?.data?.some(
								(category: any) =>
									category?.requesterMultiplierReasonId ===
									Reasons.fim_de_semana_vix_domingo
							)
								? false
								: true,
							Reasons.fim_de_semana_vix_domingo
						);
					}}
				/>
				<SwitchButton
					label="Fim de semana Gyn/SP/POA domingo"
					checked={
						!!categories?.data?.some(
							(category: any) =>
								category?.requesterMultiplierReasonId ===
								Reasons.fim_de_semana_gyn_sp_poa_domingo
						)
					}
					onChange={() => {
						void handleDynamic(
							[
								geofences?.data?.find(
									(geofence: any) => geofence?.name === 'GYN'
								)?.id,
								geofences?.data?.find(
									(geofence: any) => geofence?.name === 'SP'
								)?.id,
								geofences?.data?.find(
									(geofence: any) => geofence?.name === 'POA'
								)?.id,
							],
							// eslint-disable-next-line no-unneeded-ternary
							!!categories?.data?.some(
								(category: any) =>
									category?.requesterMultiplierReasonId ===
									Reasons.fim_de_semana_gyn_sp_poa_domingo
							)
								? false
								: true,
							Reasons.fim_de_semana_gyn_sp_poa_domingo
						);
					}}
				/>
				<SwitchButton
					label="Fim de semana domingo demais cidades"
					checked={
						!!categories?.data?.some(
							(category: any) =>
								category?.requesterMultiplierReasonId ===
								Reasons.fim_de_semana_domingo
						)
					}
					onChange={() => {
						void handleDynamic(
							geofences?.data
								?.map((geofence: any) =>
									geofence?.name !== 'ES' &&
										geofence?.name !== 'GYN' &&
										geofence?.name !== 'SP' &&
										geofence?.name !== 'POA'
										? geofence?.id
										: null
								)
								.filter(Boolean),

							// eslint-disable-next-line no-unneeded-ternary
							!!categories?.data?.some(
								(category: any) =>
									category?.requesterMultiplierReasonId ===
									Reasons.fim_de_semana_domingo
							)
								? false
								: true,
							Reasons.fim_de_semana_domingo
						);
					}}
				/>
			</WhiteContainer>
			<br />
			<WhiteContainer className="flex gap-4">
				<FormHeader title="Chuvas" />
				<span className="flex items-end gap-4">
					<Select
						label="Geofence"
						options={geofences?.data?.map((geofence: any) => ({
							label: geofence.name,
							value: geofence.id,
						}))}
						isMulti
						onChange={(e: any) =>
							setRainGeofences(e?.map((geofence: any) => geofence.value))
						}
					/>
					<Select
						label="tipo de chuva"
						options={[
							{
								label: 'Chuva fraca',
								value: Reasons.chuva_fraca,
							},
							{
								label: 'Chuva mediana | Com chuva demais cidades',
								value: Reasons.chuva_mediana,
							},
							{
								label: 'Chuva forte',
								value: Reasons.chuva_forte,
							},
							{
								label: 'Chuva com alagamentos',
								value: Reasons.chuva_alagamentos,
							},
						]}
						onChange={(e: any) => setRainType(e)}
					/>
				</span>
				<span className="flex items-center gap-4">
					<Button
						className="w-1/5"
						variant="red"
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						onClick={() =>
							rainType &&
							// eslint-disable-next-line no-void
							void handleDynamic(rainGeofences, false, rainType.value)
						}
					>
						Desativar
					</Button>
					<Button
						className="w-1/5"
						variant="green"
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						onClick={() =>
							rainType &&
							// eslint-disable-next-line no-void
							void handleDynamic(rainGeofences, true, rainType.value)
						}
					>
						Ativar
					</Button>
				</span>
				<span>
					<FormHeader
						title={`Marcados com ${rainType?.label || '(Selecione um tipo de chuva)'
							}:`}
					/>
					{categories?.data
						?.filter(
							(category: any) =>
								category?.requesterMultiplierReasonId === rainType?.value
						)
						.map((category: any) => {
							const geofenceName =
								geofences?.data?.find(
									(geofence: any) => geofence?.id === category?.geofenceId
								)?.name ?? '';

							return (
								<span
									key={category?.id}
									className="group flex items-center gap-4 rounded transition-all hover:bg-neutral-50"
								>
									<h1 className="flex gap-1">
										{category?.name} {geofenceName}:{' '}
										<p className="text-black">
											{category?.requesterMultiplier}
										</p>
									</h1>
								</span>
							);
						})}
				</span>
			</WhiteContainer>
			<br />
			<WhiteContainer className="gap-4">
				<FormHeader title="Todos os dinâmicos" />
				<span className="flex flex-col flex-wrap gap-4 md:max-h-[55vh]">
					{categories?.data
						?.sort((a: any, b: any) => a.geofenceId - b.geofenceId)
						.filter((category: Category) => category?.active)
						.map((category: any) => {
							const geofenceName =
								geofences?.data?.find(
									(geofence: any) => geofence?.id === category?.geofenceId
								)?.name ?? '';
							return (
								<span
									key={category?.id}
									className="group flex cursor-pointer items-center gap-4 rounded transition duration-200 hover:bg-neutral-50"
									// eslint-disable-next-line @typescript-eslint/no-misused-promises
									onClick={async () => {
										try {
											const value = Number(
												prompt('Qual o novo valor do multiplicador?')?.replace(',', '.')
											);
											const valueDriverBonus = Number(
												prompt('Qual o novo valor do bônus do motorista?')?.replace(',', '.')
											);

											if (
												!Number.isFinite(value) ||
												Number.isNaN(value) ||
												value < 0
											) {
												toast.error('Valor inválido!');
												return;
											}

											// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
											await api.put(`categories/${category?.id}`, {
												...category,
												requesterMultiplier: value,
												driverBonus: valueDriverBonus,
											});
											toast.success(
												// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												`Multiplicador ${category?.name}${geofenceName} alterado com sucesso!`
											);
											void refetch();
										} catch (error) {
											toast.error(
												// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												`Erro ao alterar multiplicador ${category?.name}${geofenceName}!}`
											);
										}
									}}
								>
									<h1 className="flex gap-4">
										<p className="font-extrabold">{geofenceName}</p>
										{category?.name}:{' '}
										<p
											className={twMerge(
												'',
												category?.requesterMultiplier > 0
													? 'text-green'
													: category?.requesterMultiplier < 0
														? 'text-red'
														: 'text-black'
											)}
										>
											x{category?.requesterMultiplier}
										</p>
										<p
											className={twMerge(
												'',
												category?.driverBonus > 0
													? 'text-red'
													: category?.driverBonus < 0
														? 'text-green'
														: 'text-black'
											)}
										>
											+R${category?.driverBonus.toFixed(2)}
										</p>
										{category?.requesterMultiplierReasons?.name && (
											<p
												className={twMerge(
													'align-te rounded px-1 text-sm text-white',
													// @ts-expect-error
													colorMap[category?.requesterMultiplierReasonId] ??
													colorMap.default
												)}
											>
												{category?.requesterMultiplierReasons?.name}
											</p>
										)}
									</h1>
									<span className="hidden cursor-pointer items-center justify-center rounded bg-black p-1 text-white transition duration-200 group-hover:flex">
										<FaExchangeAlt />
									</span>
								</span>
							);
						})}
				</span>
			</WhiteContainer>
		</>
	);
}
