import dayjs from 'dayjs';
import dayJsDuration from 'dayjs/plugin/duration';
import { memo, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { Location } from '../../../../components/Angular/FreightsDetail/Location';
import {
	OrderType,
	OrderTypeIcon,
} from '../../../../components/Icon/OrderTypeIcon';
import { OrderResponseApi } from '../../../../services/angular/types/Order';
import { appState } from '../../../../store/appState';
import { isEqual } from 'lodash';

interface LocationProps {
	order: OrderResponseApi;
}

export const Distance = memo(function Distance({ order }: LocationProps) {
	dayjs.extend(dayJsDuration);

	const distance = useMemo(
		() => `${order.distance > 1 ? Math.round(order.distance) : 1}Km`,
		[order.distance]
	);

	const duration = useMemo(
		() => dayjs.duration(order.duration, 'seconds'),
		[order.duration]
	);

	return (
		<Location order={order} className="group gap-2">
			<OrderTypeIcon type={String(order.orderTypeId) as OrderType} />
			<div className="flex flex-col gap-1 text-sm leading-none text-neutral-800">
				<div className="flex w-max gap-1 self-start text-start font-medium">
					{order.orderTypeId === Number(appState.orderTypes['Rota livre'].value)
						? 'ROTA LIVRE'
						: distance}
					{!!order.invoiceLimit && (
						<p
							className={twMerge(
								'border-l border-neutral-500 pl-1 font-medium',
								order.totalInvoicesRegistered === order.invoiceLimit
									? 'text-green'
									: 'text-orange'
							)}
						>
							{`${order.totalInvoicesRegistered}/${order.invoiceLimit}`}
						</p>
					)}
					{order.stopsGoal && !order.invoiceLimit && (
						<p
							className={twMerge(
								'border-l border-neutral-500 pl-1 font-medium',
								order.totalInvoicesRegistered === order.stopsGoal
									? 'text-green'
									: 'text-orange'
							)}
						>
							{`${order.totalInvoicesRegistered}/${order.stopsGoal}`}
						</p>
					)}
				</div>
				{order.statusId === appState.orderStatus.Finalizado.id ? (
					<div className="flex gap-1 self-start text-sm text-neutral-600">
						{order.orderTypeId ===
							Number(appState.orderTypes['Rota livre'].value) && (
							<>
								{distance}
								{' - '}
							</>
						)}
						{duration.format(
							duration.days() > 0
								? 'D[d] H[h]'
								: duration.hours() > 0
								? 'H[h] m[m]'
								: 'm[m]'
						)}
					</div>
				) : (
					<>
						{order.orderTypeId ===
						Number(appState.orderTypes['Rota livre'].value) ? (
							''
						) : (
							<p className="text-start text-2xs text-neutral-500">
								Distância aproximada
							</p>
						)}
					</>
				)}
			</div>
		</Location>
	);
}, isEqual);
