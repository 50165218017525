import { z } from 'zod';
import { ErrorsMessageHelper } from '../../../../../helpers/errors/ErrorsMessageHelper';
import { phoneRegex } from '../../../../../helpers/mask/phoneMask';
import { RegexHelper } from '../../../../../helpers/regex/RegexHelper';

export const CompanyInfoSchema = z
	.object({
		tradingName: z.string().min(1, { message: ErrorsMessageHelper.required }),
		companyName: z.string().min(1, { message: ErrorsMessageHelper.required }),
		cnpj: z
			.string()
			.min(1, { message: ErrorsMessageHelper.required })
			.min(18, { message: ErrorsMessageHelper.min(14) })
			.max(18, { message: ErrorsMessageHelper.max(14) })
			.regex(RegexHelper.cnpj, {
				message: ErrorsMessageHelper.cnpj,
			}),
		email: z
			.string()
			.email({ message: ErrorsMessageHelper.email })
			.optional()
			.or(z.literal('')),
		supportEmail: z
			.string()
			.email({ message: ErrorsMessageHelper.email })
			.optional()
			.or(z.literal('')),
		phone: z
			.string()
			.regex(phoneRegex, {
				message: ErrorsMessageHelper.phone,
			})
			.optional()
			.or(z.literal('')),
		cellphone: z
			.string()
			.regex(phoneRegex, {
				message: ErrorsMessageHelper.phone,
			})
			.optional()
			.or(z.literal('')),
		zipcode: z.string().optional().or(z.literal('')),
		district: z.string().optional(),
		number: z
			.string()
			.transform((value) => Number(value))
			.optional()
			.or(z.literal('')),
		complement: z.string().optional(),

		stateId: z
			.object({
				value: z.string(),
				label: z.string(),
			})
			.optional()
			.nullable()
			.transform((value) => Number(value?.value)),
		city: z.string().optional().or(z.literal('')),
		address: z.string().optional().or(z.literal('')),
		makeFreights: z.boolean().optional(),
		requestFreights: z.boolean().optional(),
	})
	.refine(
		({ makeFreights, requestFreights }) => makeFreights || requestFreights,
		{
			path: ['makeFreights'],
			message: 'Selecione ao menos uma opção',
		}
	);

export type CompanyInfoSchemaType = z.infer<typeof CompanyInfoSchema>;
