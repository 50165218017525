import { BsArrowLeftShort, BsCheck } from 'react-icons/bs';
import { PageHeader } from '../../../../components/PageHeader';
import { SelectRegion } from './components/SelectRegion';
import { twMerge } from 'tailwind-merge';
import { useEffect, useMemo, useRef, useState } from 'react';
import Button from '../../../../components/Button/Button';
import { RequestFreightSchema } from './schemas/RequestFreight';
import { SwitchButton, useZodForm } from '../../../../components/FormElements';
import { Stepper } from './components/Stepper';
import { VehicleStep } from './components/VehicleStep';
import { appStateStore, useAppState } from '../../../../store/appState';
import { DateStep } from './components/DateStep';
import { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { PathStep } from './components/PathStep';
import {
	useMutation,
	UseMutationResult,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { api } from '../../../../services/angular/axios';
import useUserState from '../../../../services/angular/angularUserState';
import { TypeStep } from './components/TypeStep';
import { Tooltip } from '../../../../components/Tooltip';
import { useStore } from 'zustand';
import { PopInfoModal } from './components/PopInfoModal';
import { AxiosError, AxiosResponse } from 'axios';
import { TbHourglassEmpty, TbWallet } from 'react-icons/tb';
import { timeFormat } from '../../../../helpers/format/dateFormat';
import { OrderTypeIcon } from '../../../../components/Icon/OrderTypeIcon';
import { useNavigate, useLocation } from 'react-router-dom';
import { ConfirmationStep } from './components/ConfirmationStep';
import { toast } from 'react-toastify';
import { CostDropdown } from './components/PathStep/CostDropdown';
import { atom, useAtom } from 'jotai';
import { FaSpinner } from 'react-icons/fa';
import dayjs from 'dayjs';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import {
	isDistanceError,
	isGeofenceError,
	isInsufficientFundsError,
	isOutsideGeofenceError,
	isSchedulingError,
} from './updateErrors';
import { AlertErrorBody, ErrorAlert } from './components/ErrorAlert';
import { VehicleCategory } from '../../../../services/angular/types/Vehicle';
import { canBuyCredits } from '../../../../helpers/permission';
import { useGoogleLoaded } from '../../../../components/GoogleMap/useGoogleLoaded';
import {
	OrderResponseApi,
	PopInfo,
} from '../../../../services/angular/types/Order';
import { useAlert } from '../../../../contexts/AlertContext';
import { StatusTooltip } from '../../../../components/Angular/StatusTooltip';
import { ToastError } from '../../../../helpers/errors/ToastError';

export const optimizeRouteAtom = atom<boolean | undefined>(undefined);
export const updateRouteAtom = atom<boolean>(false);
export const selectGeofenceAtom = atom<boolean>(false);
export const locationModalAtom = atom<boolean>(false);
export const locationIndexAtom = atom<number | undefined>(undefined);

const steps = {
	Veículo: {
		title: 'Selecione o Veículo',
		description: 'Qual tipo de veículo você precisa?',
	},
	Data: {
		title: 'Data do Frete',
		description: 'Escolha quando o frete deve começar',
	},
	Tipo: {
		title: 'Tipo de Frete',
		description: 'Escolha um modelo de contratação',
	},
	Trajeto: {
		title: 'Trajeto',
		description: 'Você ainda pode adicionar',
	},
	Confirmação: {
		title: 'Confirmação do Pedido',
		description: 'Revise o seu pedido antes de confirmá-lo',
	},
} as const;

export type Steps = typeof steps;
export type RequestFreightForm = UseFormReturn<
	z.input<typeof RequestFreightSchema>
>;
export type OrderMutation = UseMutationResult<
	AxiosResponse<OrderResponseApi, any>,
	unknown,
	z.output<typeof RequestFreightSchema>,
	unknown
>;

export const RequestFreightPage = () => {
	useGoogleLoaded();

	const alert = useAlert();
	const navigate = useNavigate();
	const { search, pathname } = useLocation();
	const [optimizeRoute, setOptimizeRoute] = useAtom(optimizeRouteAtom);
	const [updateDraft, triggerUpdateDraft] = useAtom(updateRouteAtom);
	const [, openSelectGeofence] = useAtom(selectGeofenceAtom);
	const [, openLocationModal] = useAtom(locationModalAtom);
	const [, setLocationIndex] = useAtom(locationIndexAtom);

	const { config } = useStore(appStateStore);
	const geofenceId = useAppState((state) => state.geofenceId);
	const setGeofenceId = useAppState((state) => state.setGeofenceId);
	const company = useUserState((state) => state.company);

	const queryParams = useMemo(() => new URLSearchParams(search), [search]);
	const draftId = useMemo(() => queryParams.get('rascunho'), [queryParams]);
	const refId = useMemo(() => queryParams.get('ref'), [queryParams]);

	const queryClient = useQueryClient();
	const [step, setStep] = useState<keyof Steps>('Veículo');
	const [openAlert, setOpenAlert] = useState(false);
	const [alertErrorBody, setAlertErrorBody] = useState<AlertErrorBody>();

	const { data, isLoading, isFetching } = useQuery(
		['vehicles', geofenceId],
		async () => {
			if (!geofenceId) return;
			return await api.get(
				`/categories?include=optionals&active=true&geofenceId=${geofenceId}`
			);
		}
	);

	const { refetch: fetchGeofence } = useQuery(
		['get-geofence'],
		async () => {
			if (!geofenceId) return;
			return await api.get('/geofence/current-id', {
				params: {
					latitude: form.watch('locations')[0].lat,
					longitude: form.watch('locations')[0].lng,
				},
			});
		},
		{
			enabled: false,
			cacheTime: 0,
			staleTime: 0,
			keepPreviousData: false,
			onSuccess: (data) => {
				if (data?.data.currentGeofenceId)
					setGeofenceId(data?.data.currentGeofenceId);
			},
			onError: (error) => {
				ToastError(error);

				setOpenAlert(true);
				setAlertErrorBody({
					title: 'Geofence não encontrada',
					content:
						'Não foi possível selecionar uma geofence para o seu frete. Por favor, selecione uma geofence manualmente.',
					confirm: {
						label: 'Escolher geofence',
						onClick: () => {
							openSelectGeofence(true);
						},
					},
				});
			},
		}
	);

	const { data: popInfo } = useQuery(
		['pop-info', geofenceId],
		async () =>
			await api.get<PopInfo[]>('/pop-info', {
				params: {
					geofenceId,
					active: true,
				},
			})
	);

	const { data: order, isFetching: isLoadingDraft } = useQuery(
		['draft-order', draftId],
		async () => {
			if (!draftId) throw new Error('No draft order found');
			const { data } = await api.get<OrderResponseApi>(
				`/orders/${draftId}?include=category,locations,optionals,requesterCompany,requesterCompany.favoriteDrivers`
			);

			return data;
		},
		{
			enabled: !!draftId,
			onSuccess: (data) => {
				// If order is not a draft, redirect to order page
				if (data.statusId !== 1) {
					setOpenAlert(true);
					setAlertErrorBody({
						title: 'Pedido não encontrado',
						content:
							'O pedido que você está tentando acessar não existe ou não está mais disponível.',
						cancel: {
							label: 'Voltar para rascunhos',
							onClick: () => {
								navigate('/fretes/rascunhos');
							},
						},
						confirm: {
							label: 'Novo pedido',
							onClick: () => {
								navigate(pathname);
							},
						},
					});

					return;
				}
				form.reset({
					...data,
					scheduleDate: data?.scheduleDate
						? dayjs(data.scheduleDate).toISOString()
						: null,
				});
				setGeofenceId(data.geofenceId);

				updateOrder.mutate(RequestFreightSchema.parse(data));
			},
			onError: (error) => {
				ToastError(error);

				navigate(pathname);
			},
		}
	);

	useQuery(
		['copy-order', refId],
		async () => {
			if (!refId) throw new Error('No draft order found');
			toast.info('Copiando pedido...', {
				toastId: 'create-draft',
			});
			const { data } = await api.get<OrderResponseApi>(
				`/orders/${refId}?include=category,locations,optionals,requesterCompany,requesterCompany.favoriteDrivers`
			);

			return data;
		},
		{
			enabled: !!refId,
			staleTime: 0,
			cacheTime: 0,
			onSuccess: (data) => {
				try {
					const { scheduleDate, ...createCopy } =
						RequestFreightSchema.parse(data);
					orderMutation.mutate(createCopy);
					toast.update('create-draft', {
						render: 'Pedido copiado com sucesso',
						type: 'success',
						autoClose: 1000,
					});
				} catch (error) {
					toast.update('create-draft', {
						render:
							'O pedido que você está tentando copiar está com informações inválidas. Por favor, utilize outro pedido como referência.',
						type: 'error',
						autoClose: 3000,
					});
					navigate(pathname);
				}
			},
			onError: (error) => {
				ToastError(error);
				toast.update('create-draft', {
					render: 'Erro ao copiar pedido',
					type: 'error',
					autoClose: 1000,
				});
				navigate(pathname);
			},
		}
	);

	const orderMutation = useMutation(
		async (data: z.infer<typeof RequestFreightSchema>) =>
			await api.post<OrderResponseApi>('/orders', {
				...data,
				requesterCompanyId: company?.id,
				statusId: 1, // Draft status
			}),
		{
			onMutate: () => {
				toast.info('Criando rascunho do pedido...', {
					toastId: 'create-draft',
					autoClose: false,
				});
			},
			onSuccess: (data) => {
				queryClient.setQueryData(['draft-order', draftId], data.data);
				toast.update('create-draft', {
					render: 'Rascunho criado com sucesso',
					type: 'success',
					autoClose: 1000,
				});
				form.reset({
					...form.getValues(),
					locations: data.data.locations,
				});

				navigate(`${pathname}?rascunho=${data.data.id}`);
			},
			onError: (error: AxiosError) => {
				toast.done('create-draft');
				orderErrorHandler(error);
			},
		}
	);

	const updateOrder = useMutation(
		async (data: z.infer<typeof RequestFreightSchema>) => {
			if (!order?.id) throw new Error('No draft order found');

			return await api.put<OrderResponseApi>(
				`/orders/${order.id}/update-draft`,
				{
					...data,
					id: order.id,
					requesterCompanyId: company?.id,
					statusId: 1, // Draft status
				},
				{
					params: {
						optimizeRoute,
					},
				}
			);
		},
		{
			onMutate: () => {
				toast.info(
					<div className="flex justify-between">
						{optimizeRoute ? 'Otimizando rota..' : 'Atualizando rota...'}
						<FaSpinner className="animate-spin" size={20} />
					</div>,
					{
						toastId: 'update-route',
						autoClose: false,
					}
				);
			},
			onSuccess: (data) => {
				queryClient.setQueryData(['draft-order', draftId], data.data);
				toast.update('update-route', {
					render: optimizeRoute
						? 'Rota otimizada com sucesso!'
						: 'Rota atualizada com sucesso!',
					type: 'success',
					autoClose: 1000,
				});
				form.setValue('locations', data.data.locations);
				setOptimizeRoute(undefined);
			},
			onError: (error: AxiosError) => {
				toast.done('update-route');
				orderErrorHandler(error);
			},
		}
	);

	const orderErrorHandler = (error: AxiosError<any>) => {
		if (isSchedulingError(error.response?.data)) {
			const selectedVehicle: VehicleCategory = data?.data.find(
				(vehicle: VehicleCategory) => vehicle.id === form.watch('categoryId')
			);

			const minScheduleDate = dayjs()
				.add(selectedVehicle.scheduleAdvance + 5, 'minutes')
				.format('DD/MM/YYYY HH:mm');

			setOpenAlert(true);
			setAlertErrorBody({
				title: 'Data de agendamento inválida',
				content: (
					<>
						Enquanto você montava seu frete, o prazo de antecedência mínimo para
						o agendamento foi ultrapassado. Você pode ajustar manualmente ou
						então podemos usar o agendamento mínimo permitido para{' '}
						<span className="font-bold">{selectedVehicle.name || ''}</span>, que
						é <span className="font-bold">{minScheduleDate}.</span>
					</>
				),
				cancel: {
					label: 'Refazer agendamento',
				},
				confirm: {
					label: 'Usar agendamento mínimo',
					onClick: () => {
						form.setValue(
							'scheduleDate',
							dayjs()
								.add(selectedVehicle.scheduleAdvance + 5, 'minutes')
								.toISOString()
						);
						triggerUpdateDraft(!updateDraft);
					},
				},
			});
		} else if (isGeofenceError(error.response?.data)) {
			setOpenAlert(true);
			setAlertErrorBody({
				title: 'Geofence não condiz com ponto de coleta',
				content:
					'A geofence que está selecionada não é a mesma do ponto de coleta. Por favor, altere a geofence para o frete ser feito corretamente.',
				cancel: {
					label: 'Escolher geofence',
					onClick: () => {
						openSelectGeofence(true);
					},
				},
				confirm: {
					label: 'Ajustar geofence automaticamente',
					onClick: () => {
						void fetchGeofence();
					},
				},
			});
		} else if (isDistanceError(error.response?.data)) {
			setOpenAlert(true);
			setAlertErrorBody({
				title: 'A distância desse pedido excedeu',
				content:
					'Este pedido ultrapassa o limite de distância da categoria selecionada.',
				cancel: {
					label: 'Trocar categoria',
					onClick: () => {
						setStep('Veículo');
					},
				},
				confirm: {
					label: 'Limpar pontos de entrega',
					onClick: () => {
						form.setValue('locations', []);
					},
				},
			});
		} else if (isOutsideGeofenceError(error.response?.data)) {
			setOpenAlert(true);
			setAlertErrorBody({
				title: 'Ponto fora da área permitida',
				content: <>{error.response?.data.error}.</>,
				cancel: {
					label: 'Ajustar ponto',
					onClick: () => {
						openLocationModal(true);
						const locationIndex =
							Number(error.response?.data.error.match(/\d+/g)[0]) - 1;
						setLocationIndex(locationIndex);
					},
				},
				confirm: {
					label: 'Remover ponto',
					onClick: () => {
						const locationIndex =
							Number(error.response?.data.error.match(/\d+/g)[0]) - 1;
						const locations = form.getValues('locations');
						locations.splice(Number(locationIndex), 1);
						form.setValue('locations', locations);

						triggerUpdateDraft(!updateDraft);
					},
				},
			});
		} else if (isInsufficientFundsError(error.response?.data)) {
			if (canBuyCredits(company)) {
				setOpenAlert(true);
				setAlertErrorBody({
					title: 'Saldo insuficiente',
					content:
						'Você não possui saldo suficiente para realizar o pedido. Deseja comprar créditos?',
					cancel: {
						label: 'Cancelar',
					},
					confirm: {
						label: 'Adicionar créditos',
						onClick: () => {
							window.open(`carteira/comprar-creditos`, '_blank');
						},
					},
				});
			} else {
				alert.onCustom({
					title: 'Saldo insuficiente',
					message: (
						<>
							A empresa não possui créditos suficientes para realizar o pedido.
							<br />
							Entre em contato com o um usuário administrador da empresa para
							adicionar créditos.
						</>
					),
					cancel: {
						label: 'Ok',
						icon: <></>,
					},
				});
			}
		} else {
			toast.error(
				<>
					<p>
						Ocorreu um erro inesperado, recarregue a página e tente novamente
					</p>
					<p>Se o erro persistir, entre em contato com o suporte</p>
				</>,
				{
					autoClose: false,
				}
			);
		}
		console.error('🚀 ~ file: Request 509\n', error);
	};

	// effect to handle update order mutation
	useEffect(() => {
		if (
			order &&
			((form.watch('orderTypeId') === 2 &&
				form.watch('locations').length > 0) ||
				(form.watch('orderTypeId') === 1 && form.watch('locations').length > 1))
		) {
			void form.handleSubmit((data) => {
				updateOrder.mutate(data);
			})();
		} else {
			if (
				(form.watch('orderTypeId') === 2 &&
					form.watch('locations').length === 1) ||
				(form.watch('orderTypeId') === 1 &&
					form.watch('locations').length === 2)
			)
				void form.handleSubmit((data) => {
					orderMutation.mutate(data);
				})();
		}
	}, [updateDraft]);

	const confirmOrder = useMutation(
		async () => {
			if (!order) throw new Error('No draft order');
			return await api.put<OrderResponseApi>(`/orders/${order?.id}/submit`, {
				...order,
			});
		},
		{
			onSuccess: ({ data }) => {
				queryClient.removeQueries(['draft-order']);

				toast.success('Pedido enviado com sucesso!');
				navigate(`/fretes/detalhes/${data.id}`);
			},
			onError: (error: AxiosError) => {
				orderErrorHandler(error);
			},
		}
	);

	// Sempre que editar esse schema, lembre-se de editar o schema em schemas/RequestFreight.ts
	const form = useZodForm({
		schema: z
			.object({
				statusId: z.number().optional(),
				categoryId: z.number(),
				categoryType: z.number(),
				geofenceId: z.number(),
				scheduleDate: z
					.any()
					.nullable()
					.optional()
					.transform((value, ctx) => {
						if (!value) return value;
						if (typeof value === 'object') {
							const date = dayjs(value);

							return date.toISOString();
						}
						return value;
					}),
				locations: z
					.array(
						z.object({
							id: z.string().or(z.number()).optional(),
							sequence: z.number().optional(),
							lat: z.number().optional(),
							lng: z.number().optional(),
							address: z.coerce.string({
								required_error: 'Endereço é obrigatório',
								invalid_type_error: 'Endereço inválido',
							}),
							number: z.coerce.string().default('0'),
							name: z.string().optional().nullable(),
							contact: z.coerce.string().optional().nullable(),
							responsible: z.string().optional().nullable(),
							observations: z.string().optional().nullable(),
						})
					)
					.max(Number(config?.freights.maxLocationsLength || 200)),
				orderTypeId: z.number().default(1),
				stopsGoal: z.string().or(z.number()).nullable().optional(),
				searchOnlyFavoriteDrivers: z.boolean().default(false),
				paymentTypeId: z.number().nullable().optional(),
			})
			.superRefine((data, ctx) => {
				if (data.orderTypeId === 2) {
					if (Number(data.stopsGoal) < 1)
						ctx.addIssue({
							code: 'custom',
							message: 'Pelo menos 1 Nota Fiscal',
							path: ['stopsGoal'],
						});

					if (Number(data.stopsGoal) > (config?.freights.maxStopsGoal || 999))
						ctx.addIssue({
							code: 'custom',
							message: `Número máximo de Notas Fiscais ${
								config?.freights.maxStopsGoal || 999
							}`,
							path: ['stopsGoal'],
						});
				}
			}),
	});

	const {
		formState: { errors },
	} = form;

	const isFirstRender = useRef(true);

	useEffect(() => {
		if (isFirstRender.current) {
			if (geofenceId) form.setValue('geofenceId', geofenceId);
			queryClient.removeQueries(['draft-order']);

			isFirstRender.current = false;
			return;
		}
		form.reset({
			categoryId: undefined,
			locations: undefined,
			categoryType: undefined,
			orderTypeId: undefined,
			stopsGoal: null,
			scheduleDate: undefined,
		});

		if (geofenceId) form.setValue('geofenceId', geofenceId);

		orderMutation.reset();
		queryClient.removeQueries(['draft-order', draftId]);
		queryClient.setQueryData(['draft-order', draftId], undefined);
		setStep('Veículo');
		navigate(pathname);
	}, [company?.id, geofenceId]);

	return (
		<div className="flex w-full flex-col gap-6">
			<Stepper step={step} steps={steps} />

			<PageHeader
				title={
					step === 'Trajeto'
						? form.watch('orderTypeId') === 2
							? 'Ponto de Partida'
							: 'Trajeto'
						: steps[step].title
				}
				description={
					step === 'Trajeto'
						? form.watch('orderTypeId') === 2
							? 'Escolha o local onde o frete deve iniciar'
							: `${steps[step].description} ${
									Number(config?.freights.maxLocationsLength) -
									(form.watch('locations')?.length || 0)
							  } locais`
						: steps[step].description
				}
				className="mb-0 items-center justify-center"
			>
				{company?.hasFavoriteDrivers &&
					company?.allowFavoriteDriver &&
					step === 'Veículo' && (
						<div className="self-center">
							<Tooltip message="Por padrão, nosso sistema sempre busca seus entregadores favoritos antes dos demais. Habilitando este recurso a busca é restrita a somente entregadores favoritos, excluindo o resto da nuvem">
								<SwitchButton
									label="Buscar somente entregadores favoritos"
									{...form.register('searchOnlyFavoriteDrivers', {
										value: company.searchOnlyFavoriteDrivers,
									})}
								/>
							</Tooltip>
						</div>
					)}
				{step === 'Trajeto' && order && (
					<span className="flex flex-wrap gap-6 divide-neutral-200 text-neutral-0">
						<Tooltip
							message={
								order.orderTypeId === 2 &&
								'O valor INICIAL será atualizado quando o frete for finalizado e nele serão acrescentados o KM do percurso'
							}
						>
							<div className="flex flex-row items-center justify-center gap-2">
								<TbWallet size={40} />
								<div className="flex flex-col gap-1">
									<span className="flex justify-between gap-2 font-semibold">
										{Number(order.total).toLocaleString('pt-BR', {
											style: 'currency',
											currency: 'BRL',
										})}
										{order.requesterMultiplier > 1 && (
											<span className="self-start rounded-full bg-orange px-2 py-px text-sm font-bold text-white">
												{order.requesterMultiplier}X
											</span>
										)}
										<CostDropdown order={order} />
									</span>
									{order.orderTypeId === 1 ? (
										<span className="text-sm">
											{Number(
												order.total / (order.locations.length - 1)
											).toLocaleString('pt-BR', {
												style: 'currency',
												currency: 'BRL',
											})}{' '}
											por ponto
										</span>
									) : (
										<span className="text-sm">Valor inicial</span>
									)}
								</div>
							</div>
						</Tooltip>
						{order.orderTypeId === 1 && (
							<div className="flex flex-row items-center justify-center gap-2">
								<OrderTypeIcon type="1" />
								<div className="flex flex-col gap-1">
									<span className="font-semibold">
										{Math.ceil(Number(order.distance))}KM
									</span>
									<span className="text-start text-sm">
										Distância aproximada
									</span>
								</div>
							</div>
						)}
						<div className="flex flex-row items-center justify-center gap-2">
							<TbHourglassEmpty size={40} />
							<div className="flex flex-col gap-1">
								<span className="font-semibold">
									{timeFormat(Number(order.duration))}
								</span>
								<span className="text-sm">Duração aproximada</span>
							</div>
						</div>
					</span>
				)}

				{step === 'Confirmação' && order?.id && <StatusTooltip order={order} />}
			</PageHeader>

			{step === 'Veículo' && (
				<>
					{isLoadingDraft ? (
						<WhiteContainer>
							<p className="flex items-center justify-center text-xl font-semibold">
								Carregando rascunho
							</p>
							<span className="flex h-[20vh] items-center justify-center">
								<FaSpinner className="animate-slow-spin" size="60%" />
							</span>
						</WhiteContainer>
					) : (
						<VehicleStep
							isLoading={isLoading || isFetching}
							vehicles={data?.data}
							form={form}
							orderMutation={orderMutation}
							order={order}
						/>
					)}
				</>
			)}

			{step === 'Data' && <DateStep vehicles={data?.data} form={form} />}

			{step === 'Tipo' && <TypeStep form={form} />}

			{step === 'Trajeto' && (
				<PathStep
					orderMutation={orderMutation}
					draftOrder={order}
					form={form}
				/>
			)}

			{step === 'Confirmação' && (
				<ConfirmationStep form={form} draftOrder={order} />
			)}

			<div className="pointer-events-none fixed bottom-[1%] left-0 right-0 flex items-center justify-center gap-4 md:left-16">
				<div
					className={twMerge(
						'pointer-events-auto grid grid-cols-2 gap-4 rounded bg-neutral-1 px-4 py-2 ring-1 ring-neutral-200',
						step === 'Veículo' && 'grid-cols-1'
					)}
				>
					{step !== 'Veículo' && (
						<Button
							className="w-auto border-none bg-neutral-600 px-8 py-2 hover:bg-neutral-700"
							onClick={() => {
								if (company?.dynamicRouteEnabled)
									switch (step) {
										case 'Data':
											setStep('Veículo');
											break;
										case 'Tipo':
											setStep('Data');
											break;
										case 'Trajeto':
											setStep('Tipo');
											break;
										case 'Confirmação':
											setStep('Trajeto');
											break;
									}
								else
									switch (step) {
										case 'Data':
											setStep('Veículo');
											break;
										case 'Trajeto':
											setStep('Data');
											break;
										case 'Confirmação':
											setStep('Trajeto');
											break;
									}
							}}
						>
							<BsArrowLeftShort size={24} />
							Voltar
						</Button>
					)}
					<Button
						variant="green"
						className="w-auto px-8 py-2"
						disabled={
							step === 'Veículo'
								? !form.watch('categoryId')
								: step === 'Data'
								? form.watch('scheduleDate') === undefined ||
								  !!errors.scheduleDate?.message ||
								  form.watch('scheduleDate') === 'schedule'
								: step === 'Tipo'
								? form.watch('orderTypeId') === undefined ||
								  (form.watch('orderTypeId') === 2 &&
										Number(form.watch('stopsGoal')) < 1)
								: step === 'Trajeto'
								? !order?.id
								: step !== 'Confirmação'
						}
						onClick={() => {
							switch (step) {
								case 'Veículo':
									setStep('Data');
									break;
								case 'Data':
									company?.dynamicRouteEnabled
										? setStep('Tipo')
										: setStep('Trajeto');
									break;
								case 'Tipo':
									setStep('Trajeto');
									break;
								case 'Trajeto':
									setStep('Confirmação');
									break;
								case 'Confirmação':
									form.watch('searchOnlyFavoriteDrivers')
										? alert.onCustom({
												title: 'Atenção',
												message:
													'Este pedido irá buscar apenas motoristas favoritos, está de acordo?',
												cancel: {
													label: 'Voltar',
												},
												confirm: {
													label: 'Confirmar',
													onClick: () => confirmOrder.mutate(),
												},
										  })
										: confirmOrder.mutate();
									break;
							}
						}}
					>
						{step === 'Confirmação' ? 'Confirmar' : 'Avançar'}
						{step === 'Confirmação' ? (
							<BsCheck size={24} />
						) : (
							<BsArrowLeftShort className="rotate-180" size={24} />
						)}
					</Button>
				</div>
			</div>

			{!draftId && <SelectRegion />}

			<PopInfoModal
				info={popInfo?.data.find((info: any) => info.typeId === 1)}
			/>

			<ErrorAlert
				open={openAlert}
				setOpen={setOpenAlert}
				body={alertErrorBody}
			/>
		</div>
	);
};
