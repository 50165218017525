import React from 'react';
import { twMerge } from 'tailwind-merge';

interface PageHeaderProps {
	title: string;
	children?: React.ReactNode;
	description?: string;
	className?: string;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
	children,
	title,
	description,
	className,
}) => {
	return (
		<div
			className={twMerge(
				'mb-6 flex flex-wrap items-end justify-between gap-2 md:items-start',
				className
			)}
		>
			<div className="mr-auto text-left">
				<h1 className="text-xl font-bold text-neutral-900">{title}</h1>
				{description && <p className="ml-1 text-neutral-500">{description}</p>}
			</div>
			{children}
		</div>
	);
};
