import { PageHeader } from '../../../../../components/PageHeader';
import React, { useMemo, useRef, useState } from 'react';
import { api } from '../../../../../services/angular/axios';
import { useQuery } from '@tanstack/react-query';
import useUserState from '../../../../../services/angular/angularUserState';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { Table, TableActions } from '../../../../../components/Table/Table';
import dayjs from 'dayjs';
import { TbClock, TbTruckDelivery } from 'react-icons/tb';
import { Tooltip } from '../../../../../components/Tooltip';
import { FiltersTab } from '../../../../../components/FiltersTab';
import { appState } from '../../../../../store/appState';
import {
	Date,
	Select,
	useZodForm,
} from '../../../../../components/FormElements';
import { ImDownload3 } from 'react-icons/im';
import { downloadFile } from '../../../../../helpers/downloadFile';
import Button from '../../../../../components/Button/Button';
import { toast } from 'react-toastify';
import { OrderResponseApi } from '../../../../../services/angular/types/Order';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { DriverDropdown } from '../../../../../components/Order/DriverDropdown';
import { AiFillStar } from 'react-icons/ai';
import { CategoryType } from '../../../../../components/Order/CategoryType';
import {
	chargesFilterSchema,
	useChargesFilterState,
} from '../../../../../store/filters/chargesFilter';
import { AddIncidentModal } from '../../../../../components/AddIncidentModal';
import { CgToolbox } from 'react-icons/cg';
import { StatusTooltip } from '../../../../../components/Angular/StatusTooltip';
import { FaExclamationCircle } from 'react-icons/fa';

const Charges: React.FC = () => {
	const [addIncidentModal, setAddIncidentModal] = useState(false);
	const [orderId, setOrderId] = useState<number>();

	const statusOptions = useMemo(
		() => [
			{
				label: appState.orderStatus.Finalizado.name,
				value: appState.orderStatus.Finalizado.id,
			},
			{
				label: appState.orderStatus.Cancelado.name,
				value: appState.orderStatus.Cancelado.id,
			},
		],
		[]
	) as Array<{ label: string; value: number }>;
	const paymentTypeOptions = useMemo(
		() => [
			{
				label: appState.paymentType.Boleto.name,
				value: appState.paymentType.Boleto.id,
			},
			{
				label: appState.paymentType['Cartão de Crédito'].name,
				value: appState.paymentType['Cartão de Crédito'].id,
			},
			{
				label: appState.paymentType['Cartão de Débito'].name,
				value: appState.paymentType['Cartão de Débito'].id,
			},
			{
				label: appState.paymentType['Saldo em Carteira'].name,
				value: appState.paymentType['Saldo em Carteira'].id,
			},
			{
				label: appState.paymentType['Ao motorista'].name,
				value: appState.paymentType['Ao motorista'].id,
			},
		],
		[]
	) as Array<{ label: string; value: number }>;

	const user = useUserState((state) => state.user);
	const [inputValue, setInputValue] = useState('');
	const { params, setParams, setChargesFilter, clearChargesFilter, ...query } =
		useChargesFilterState();

	const form = useZodForm({
		schema: chargesFilterSchema,
		defaultValues: {
			...query,
			freightForwarderName: query?.freightForwarderName
				? {
						value: query.freightForwarderName,
						label: query.freightForwarderName,
				  }
				: null,
			statusId: statusOptions.filter((status) =>
				query?.statusId?.split(',').includes(String(status.value))
			),
			paymentTypeId: paymentTypeOptions.find(
				(paymentType) => query?.paymentTypeId === paymentType.value
			),
		},
	});

	const exportTableData = async () => {
		try {
			const response = await api.get(
				'/orders/finished/export?includeStatus=incident,driver,driverCompany,status,category,paymentType',
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
						...query,
					},
					responseType: 'blob',
				}
			);

			await downloadFile({
				blobFile: response?.data,
				fileName: `financeiro-pagar-${
					query?.freightForwarderName
						? `${query.freightForwarderName.replace(' ', '_')}-`
						: ''
				}${dayjs().format('DD/MM/YYYY_HH:mm')}.xlsx`,
			});
		} catch (err) {
			toast.error('Erro ao gerar arquivo');
		}
	};

	const { data: incomeData } = useQuery<any>(
		['financeiro-pagar', query, params, user?.id],
		async () =>
			await api.get(
				`/orders/finishedTotals?include=incident,driver,driverCompany,status,category,paymentType`,
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
						...query,
					},
				}
			),
		{
			enabled: !!user?.id,
		}
	);

	const { data: users, refetch: usersRefetch } = useQuery<any>(
		['usersOptions', inputValue],
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		async () =>
			await api.get(`/users/names?`, {
				params: {
					name: inputValue,
				},
			}),
		{
			enabled: !!inputValue,
		}
	);

	const timerRef = useRef<any>(null);

	const handleSearch = (value: string) => {
		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			setInputValue(value);
			void usersRefetch();
		}, 1000);
	};

	const {
		data: tableData,
		isLoading: tableLoading,
		isFetching: tableFetching,
	} = useQuery(
		['pedidos-finalizados', query, params, user?.id],
		async () =>
			await api.get(
				'/orders/finished?include=incident,driver,driverCompany,status,category,paymentType',
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
						...query,
					},
				}
			),
		{
			enabled: !!user?.id,
		}
	);

	return (
		<div className="w-full flex-col text-neutral-900">
			<PageHeader
				title="Financeiro"
				description="A pagar"
				className="flex items-center gap-5"
			>
				<div className="flex flex-col p-2 shadow-md">
					<h3 className="text-center text-xs text-primary-600">
						TOTAL
					</h3>
					<p className="text-center text-2xl font-medium text-primary-600">
						{incomeData?.data.total.toLocaleString('pt-br', {
							style: 'currency',
							currency: 'BRL',
						})}
					</p>
					<p className="text-center text-xs text-neutral-600">
						Total de acordo com o filtro
					</p>
				</div>
				<div className="flex flex-col p-2 shadow-md">
					<h3 className="text-center text-xs text-terracota-500">
						TOTAL A PAGAR
					</h3>
					<p className="text-center text-2xl font-medium text-terracota-500">
						{incomeData?.data.driverSubTotal.toLocaleString('pt-br', {
							style: 'currency',
							currency: 'BRL',
						})}
					</p>
					<p className="text-center text-xs text-neutral-600">
						Total de acordo com o filtro
					</p>
				</div>
			</PageHeader>
			<FiltersTab
				actionsChildren={
					<Button
						type="button"
						className="w-auto self-end py-1"
						onClick={() => {
							void exportTableData();
						}}
					>
						<ImDownload3 size={17} />
						Exportar
					</Button>
				}
				form={form}
				clearFilters={() => {
					form.reset({
						completionDate: null,
						initialDate: null,
						endDate: null,
						orderDate: null,
						paymentTypeId: null,
						freightForwarderName: null,
						statusId: null,
					});
					clearChargesFilter();
				}}
				onSubmit={(data) => {
					setChargesFilter(data);
					setParams({
						...params,
						page: 1,
					});
				}}
			>
				<Select
					className="md:w-auto md:min-w-[320px]"
					variant="light"
					label="Fretista"
					controller={{
						control: form.control,
						defaultValue: null,
						name: 'freightForwarderName',
					}}
					options={users?.data
						.filter((user: string) => user)
						.map((user: string) => ({
							label: user,
							value: user,
						}))}
					onInputChange={(e) => {
						handleSearch(e);
					}}
				/>
				<Date
					className="w-full md:w-auto"
					control={form.control}
					label="De"
					name="initialDate"
					variant="light"
				/>
				<Date
					className="w-full md:w-auto"
					control={form.control}
					label="Até"
					name="endDate"
					variant="light"
				/>
				<Date
					className="w-full md:w-auto"
					control={form.control}
					label="Data do Pedido"
					name="orderDate"
					variant="light"
				/>
				<Date
					className="w-full md:w-auto"
					control={form.control}
					label="Data Conclusão"
					name="completionDate"
					variant="light"
				/>
				<Select
					variant="light"
					className="md:w-auto md:min-w-[164px]"
					label="Forma de Pagamento"
					controller={{
						control: form.control,
						defaultValue: null,
						name: 'paymentTypeId',
					}}
					options={paymentTypeOptions}
				/>
				<Select
					variant="light"
					className="md:w-auto md:min-w-[128px]"
					isMulti
					label="Status"
					controller={{
						control: form.control,
						defaultValue: null,
						name: 'statusId',
					}}
					options={statusOptions}
				/>
			</FiltersTab>
			<WhiteContainer>
				<Table<OrderResponseApi>
					params={params}
					isLoading={tableLoading || tableFetching}
					data={tableData?.data.data || []}
					total={tableData?.data.meta.pagination.total || 0}
					onParamsChange={(params) => {
						setParams(params);
					}}
					disabledSort={{
						requester: true,
						paid: true,
						comission: true,
						created_at: true,
					}}
					columns={[
						{
							accessor: 'id',
							Header: 'Id',
							Cell: ({ row }) => {
								const {
									id,
									searchOnlyFavoriteDrivers,
									status,
									incident,
									integrationOrder,
								} = row.original;

								return (
									<div className="flex flex-col">
										<div className="flex gap-2">
											{searchOnlyFavoriteDrivers && (
												<Tooltip
													message="Somente motoristas favoritos"
													triggerClassName="self-start"
												>
													<AiFillStar
														className="text-yellow-submenu"
														size={22}
													/>
												</Tooltip>
											)}
										</div>
										<div className="flex items-center gap-2 text-lg font-medium text-orange">
											#{String(id).padStart(5, '0')}
											{integrationOrder && (
												<Tooltip
													message="Pedido de integração"
													triggerClassName="text-neutral-700"
												>
													<BsFillInfoCircleFill size={16} />
												</Tooltip>
											)}
										</div>
										<StatusTooltip
											order={row.original}
											triggerClassName="self-start"
										>
											<div className="flex flex-col">
												{incident ? (
													<div className="flex gap-1 text-xs text-terracota-0">
														<FaExclamationCircle size={14} /> Incidente
													</div>
												) : (
													<span className="text-xs text-neutral-600">
														{status.name}
													</span>
												)}
											</div>
										</StatusTooltip>
									</div>
								);
							},
						},
						{
							accessor: 'requestDate',
							Header: 'Solicitação',
							Cell: ({ row }) => {
								const { requestDate } = row.original;
								const date = requestDate
									? dayjs(requestDate).format('DD/MM/YYYY HH:mm')
									: '-';

								return (
									<div className="flex flex-col">
										<span className="text-sm text-neutral-900">{date}</span>
										<span className="text-xs text-neutral-500">
											Data da solicitação
										</span>
									</div>
								);
							},
						},
						{
							accessor: 'orderDate',
							Header: 'Data do Frete',
							Cell: ({ row }) => {
								const { orderDate, scheduleDate } = row.original;
								const date = orderDate
									? dayjs(orderDate).format('DD/MM/YYYY HH:mm')
									: 'Começa imediatamente';

								return (
									<div className="flex gap-1">
										{scheduleDate ? (
											<TbClock size={30} />
										) : (
											<TbTruckDelivery size={30} />
										)}
										<div className="flex flex-col">
											<span className="font-medium text-neutral-800">
												{scheduleDate ? 'Agendado' : 'Imediato'}
											</span>
											<span className="text-sm text-neutral-500">{date}</span>
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'completionDate',
							Header: 'Conclusão',
							Cell: ({ value }) => {
								const date = value
									? dayjs(value).format('DD/MM/YYYY HH:mm')
									: '-';
								return (
									<div className="flex flex-col text-sm">
										<span>{date}</span>
										<span className="text-xs text-neutral-500">Conclusão</span>
									</div>
								);
							},
						},
						{
							accessor: 'driver',
							Header: 'Fretista',
							Cell: ({ row }) => <DriverDropdown order={row.original} />,
						},
						{
							accessor: 'categoryId',
							Header: 'Veículo',
							Cell: ({ row }) => (
								<CategoryType
									category={row.original.category}
									categoryType={row.original.categoryType}
									isFavoriteDriver={row.original.isFavoriteDriver}
									defrauded={row.original.defrauded}
								/>
							),
						},
						{
							accessor: 'total',
							Header: 'Valor total',
							Cell: ({ value }) => {
								return (
									<div className="flex flex-col text-sm">
										<span>
											{value.toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL',
											})}
										</span>
									</div>
								);
							},
						},
						{
							accessor: 'driverSubTotal',
							Header: 'A pagar',
							Cell: ({ row }) => {
								const { driverSubTotal, statusId } = row.original;

								return (
									<div className="flex flex-col text-sm text-terracota-500">
										{statusId === appState.orderStatus.Cancelado.id ? (
											<span className="text-neutral-500">-</span>
										) : (
											<span>
												{driverSubTotal && driverSubTotal >= 0
													? driverSubTotal.toLocaleString('pt-br', {
															style: 'currency',
															currency: 'BRL',
													  })
													: '-'}
											</span>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'created_at',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => (
								<TableActions
									row={row}
									actions={[
										{
											icon: 'exclamation-circle',
											label: 'Detalhes',
											href: `/pedidos/${row.original.id}`,
										},
										{
											icon: <CgToolbox size={18} />,
											label: 'Incidente',
											onAction: () => {
												setOrderId(row.original.id);
												setAddIncidentModal(true);
											},
										},
									]}
								/>
							),
						},
					]}
				/>
			</WhiteContainer>

			<AddIncidentModal
				open={addIncidentModal}
				setOpen={setAddIncidentModal}
				orderId={orderId}
			/>
		</div>
	);
};

export default Charges;
