export const carMakers = [
	{
		value: 0,
		label: 'Acura',
	},
	{
		value: 1,
		label: 'Agrale',
	},
	{
		value: 2,
		label: 'Alfa Romeo',
	},
	{
		value: 3,
		label: 'AM Gen',
	},
	{
		value: 4,
		label: 'Asia Motors',
	},
	{
		value: 5,
		label: 'ASTON MARTIN',
	},
	{
		value: 6,
		label: 'Audi',
	},
	{
		value: 7,
		label: 'Baby',
	},
	{
		value: 8,
		label: 'BMW',
	},
	{
		value: 9,
		label: 'BRM',
	},
	{
		value: 10,
		label: 'Bugre',
	},
	{
		value: 11,
		label: 'BYD',
	},
	{
		value: 12,
		label: 'CAB Motors',
	},
	{
		value: 13,
		label: 'Cadillac',
	},
	{
		value: 14,
		label: 'Caoa Chery',
	},
	{
		value: 15,
		label: 'CBT Jipe',
	},
	{
		value: 16,
		label: 'CHANA',
	},
	{
		value: 17,
		label: 'CHANGAN',
	},
	{
		value: 18,
		label: 'Chrysler',
	},
	{
		value: 19,
		label: 'Citroën',
	},
	{
		value: 20,
		label: 'Cross Lander',
	},
	{
		value: 21,
		label: 'Daewoo',
	},
	{
		value: 22,
		label: 'Daihatsu',
	},
	{
		value: 23,
		label: 'Dodge',
	},
	{
		value: 24,
		label: 'EFFA',
	},
	{
		value: 25,
		label: 'Engesa',
	},
	{
		value: 26,
		label: 'Envemo',
	},
	{
		value: 27,
		label: 'Ferrari',
	},
	{
		value: 28,
		label: 'Fiat',
	},
	{
		value: 29,
		label: 'Fibravan',
	},
	{
		value: 30,
		label: 'Ford',
	},
	{
		value: 31,
		label: 'FOTON',
	},
	{
		value: 32,
		label: 'Fyber',
	},
	{
		value: 33,
		label: 'GEELY',
	},
	{
		value: 34,
		label: 'GM - Chevrolet',
	},
	{
		value: 35,
		label: 'GREAT WALL',
	},
	{
		value: 36,
		label: 'Gurgel',
	},
	{
		value: 37,
		label: 'GWM',
	},
	{
		value: 38,
		label: 'HAFEI',
	},
	{
		value: 39,
		label: 'HITECH ELECTRIC',
	},
	{
		value: 40,
		label: 'Honda',
	},
	{
		value: 41,
		label: 'Hyundai',
	},
	{
		value: 42,
		label: 'Isuzu',
	},
	{
		value: 43,
		label: 'IVECO',
	},
	{
		value: 44,
		label: 'JAC',
	},
	{
		value: 45,
		label: 'Jaguar',
	},
	{
		value: 46,
		label: 'Jeep',
	},
	{
		value: 47,
		label: 'JINBEI',
	},
	{
		value: 48,
		label: 'JPX',
	},
	{
		value: 49,
		label: 'Kia Motors',
	},
	{
		value: 50,
		label: 'Lada',
	},
	{
		value: 51,
		label: 'LAMBORGHINI',
	},
	{
		value: 52,
		label: 'Land Rover',
	},
	{
		value: 53,
		label: 'Lexus',
	},
	{
		value: 54,
		label: 'LIFAN',
	},
	{
		value: 55,
		label: 'LOBINI',
	},
	{
		value: 56,
		label: 'Lotus',
	},
	{
		value: 57,
		label: 'Mahindra',
	},
	{
		value: 58,
		label: 'Maserati',
	},
	{
		value: 59,
		label: 'Matra',
	},
	{
		value: 60,
		label: 'Mazda',
	},
	{
		value: 61,
		label: 'Mclaren',
	},
	{
		value: 62,
		label: 'Mercedes-Benz',
	},
	{
		value: 63,
		label: 'Mercury',
	},
	{
		value: 64,
		label: 'MG',
	},
	{
		value: 65,
		label: 'MINI',
	},
	{
		value: 66,
		label: 'Mitsubishi',
	},
	{
		value: 67,
		label: 'Miura',
	},
	{
		value: 68,
		label: 'Nissan',
	},
	{
		value: 69,
		label: 'Peugeot',
	},
	{
		value: 70,
		label: 'Plymouth',
	},
	{
		value: 71,
		label: 'Pontiac',
	},
	{
		value: 72,
		label: 'Porsche',
	},
	{
		value: 73,
		label: 'RAM',
	},
	{
		value: 74,
		label: 'RELY',
	},
	{
		value: 75,
		label: 'Renault',
	},
	{
		value: 76,
		label: 'Rolls-Royce',
	},
	{
		value: 77,
		label: 'Rover',
	},
	{
		value: 78,
		label: 'Saab',
	},
	{
		value: 79,
		label: 'Saturn',
	},
	{
		value: 80,
		label: 'Seat',
	},
	{
		value: 81,
		label: 'SHINERAY',
	},
	{
		value: 82,
		label: 'smart',
	},
	{
		value: 83,
		label: 'SSANGYONG',
	},
	{
		value: 84,
		label: 'Subaru',
	},
	{
		value: 85,
		label: 'Suzuki',
	},
	{
		value: 86,
		label: 'TAC',
	},
	{
		value: 87,
		label: 'Toyota',
	},
	{
		value: 88,
		label: 'Troller',
	},
	{
		value: 89,
		label: 'Volvo',
	},
	{
		value: 90,
		label: 'VW - VolksWagen',
	},
	{
		value: 91,
		label: 'Wake',
	},
	{
		value: 92,
		label: 'Walk',
	},
	{
		value: 93,
		label: 'ADLY',
	},
	{
		value: 94,
		label: 'AGRALE',
	},
	{
		value: 95,
		label: 'AMAZONAS',
	},
	{
		value: 96,
		label: 'APRILIA',
	},
	{
		value: 97,
		label: 'ATALA',
	},
	{
		value: 98,
		label: 'AVELLOZ',
	},
	{
		value: 99,
		label: 'BAJAJ',
	},
	{
		value: 100,
		label: 'BEE',
	},
	{
		value: 101,
		label: 'Benelli',
	},
	{
		value: 102,
		label: 'BETA',
	},
	{
		value: 103,
		label: 'BIMOTA',
	},
	{
		value: 104,
		label: 'BRANDY',
	},
	{
		value: 105,
		label: 'BRAVA',
	},
	{
		value: 106,
		label: 'BRP',
	},
	{
		value: 107,
		label: 'BUELL',
	},
	{
		value: 108,
		label: 'BUENO',
	},
	{
		value: 109,
		label: 'BULL',
	},
	{
		value: 110,
		label: 'byCristo',
	},
	{
		value: 111,
		label: 'CAGIVA',
	},
	{
		value: 112,
		label: 'CALOI',
	},
	{
		value: 113,
		label: 'DAELIM',
	},
	{
		value: 114,
		label: 'DAFRA',
	},
	{
		value: 115,
		label: 'DAYANG',
	},
	{
		value: 116,
		label: 'DAYUN',
	},
	{
		value: 117,
		label: 'DERBI',
	},
	{
		value: 118,
		label: 'DUCATI',
	},
	{
		value: 119,
		label: 'EMME',
	},
	{
		value: 120,
		label: 'FOX',
	},
	{
		value: 121,
		label: 'FUSCO MOTOSEGURA',
	},
	{
		value: 122,
		label: 'FYM',
	},
	{
		value: 123,
		label: 'GARINNI',
	},
	{
		value: 124,
		label: 'GAS GAS',
	},
	{
		value: 125,
		label: 'GREEN',
	},
	{
		value: 126,
		label: 'HAOBAO',
	},
	{
		value: 127,
		label: 'HAOJUE',
	},
	{
		value: 128,
		label: 'HARLEY-DAVIDSON',
	},
	{
		value: 129,
		label: 'HARTFORD',
	},
	{
		value: 130,
		label: 'HERO',
	},
	{
		value: 131,
		label: 'HONDA',
	},
	{
		value: 132,
		label: 'HUSABERG',
	},
	{
		value: 133,
		label: 'HUSQVARNA',
	},
	{
		value: 134,
		label: 'INDIAN',
	},
	{
		value: 135,
		label: 'IROS',
	},
	{
		value: 136,
		label: 'JIAPENG VOLCANO',
	},
	{
		value: 137,
		label: 'JOHNNYPAG',
	},
	{
		value: 138,
		label: 'JONNY',
	},
	{
		value: 139,
		label: 'KAHENA',
	},
	{
		value: 140,
		label: 'KASINSKI',
	},
	{
		value: 141,
		label: 'KAWASAKI',
	},
	{
		value: 142,
		label: 'KTM',
	},
	{
		value: 143,
		label: 'KYMCO',
	},
	{
		value: 144,
		label: 'LANDUM',
	},
	{
		value: 145,
		label: "L'AQUILA",
	},
	{
		value: 146,
		label: 'LAVRALE',
	},
	{
		value: 147,
		label: 'LERIVO',
	},
	{
		value: 148,
		label: 'Lon-V',
	},
	{
		value: 149,
		label: 'MAGRÃO TRICICLOS',
	},
	{
		value: 150,
		label: 'Malaguti',
	},
	{
		value: 151,
		label: 'MIZA',
	},
	{
		value: 152,
		label: 'MOTO GUZZI',
	},
	{
		value: 153,
		label: 'MOTOCAR',
	},
	{
		value: 154,
		label: 'MOTORINO',
	},
	{
		value: 155,
		label: 'MRX',
	},
	{
		value: 156,
		label: 'MV AGUSTA',
	},
	{
		value: 157,
		label: 'MVK',
	},
	{
		value: 158,
		label: 'NIU',
	},
	{
		value: 159,
		label: 'ORCA',
	},
	{
		value: 160,
		label: 'PEGASSI',
	},
	{
		value: 161,
		label: 'PEUGEOT',
	},
	{
		value: 162,
		label: 'PIAGGIO',
	},
	{
		value: 163,
		label: 'POLARIS',
	},
	{
		value: 164,
		label: 'REGAL RAPTOR',
	},
	{
		value: 165,
		label: 'RIGUETE',
	},
	{
		value: 166,
		label: 'Royal Enfield',
	},
	{
		value: 167,
		label: 'SANYANG',
	},
	{
		value: 168,
		label: 'SIAMOTO',
	},
	{
		value: 169,
		label: 'SUNDOWN',
	},
	{
		value: 170,
		label: 'SUPER SOCO',
	},
	{
		value: 171,
		label: 'SUZUKI',
	},
	{
		value: 172,
		label: 'TARGOS',
	},
	{
		value: 173,
		label: 'TIGER',
	},
	{
		value: 174,
		label: 'TRAXX',
	},
	{
		value: 175,
		label: 'TRIUMPH',
	},
	{
		value: 176,
		label: 'VENTO',
	},
	{
		value: 177,
		label: 'VOLTZ',
	},
	{
		value: 178,
		label: 'WUYANG',
	},
	{
		value: 179,
		label: 'YAMAHA',
	},
	{
		value: 180,
		label: 'BEPOBUS',
	},
	{
		value: 181,
		label: 'CHEVROLET',
	},
	{
		value: 182,
		label: 'CICCOBUS',
	},
	{
		value: 183,
		label: 'DAF',
	},
	{
		value: 184,
		label: 'EFFA-JMC',
	},
	{
		value: 185,
		label: 'FIAT',
	},
	{
		value: 186,
		label: 'FORD',
	},
	{
		value: 187,
		label: 'GMC',
	},
	{
		value: 188,
		label: 'HYUNDAI',
	},
	{
		value: 189,
		label: 'MAN',
	},
	{
		value: 190,
		label: 'MARCOPOLO',
	},
	{
		value: 191,
		label: 'MASCARELLO',
	},
	{
		value: 192,
		label: 'MAXIBUS',
	},
	{
		value: 193,
		label: 'MERCEDES-BENZ',
	},
	{
		value: 194,
		label: 'NAVISTAR',
	},
	{
		value: 195,
		label: 'NEOBUS',
	},
	{
		value: 196,
		label: 'PUMA-ALFA',
	},
	{
		value: 197,
		label: 'SAAB-SCANIA',
	},
	{
		value: 198,
		label: 'SCANIA',
	},
	{
		value: 199,
		label: 'SHACMAN',
	},
	{
		value: 200,
		label: 'SINOTRUK',
	},
	{
		value: 201,
		label: 'VOLKSWAGEN',
	},
	{
		value: 202,
		label: 'VOLVO',
	},
	{
		value: 203,
		label: 'WALKBUS',
	},
	{
		value: 204,
		label: 'MOTTU',
	},
];
