import { useEffect, useState, useMemo, useCallback } from 'react';
import { OrderResponseApi } from '../../../../../services/angular/types/Order';
import dayjs from 'dayjs';
import {
	getCurrentPendingLocation,
	hasCheckout,
	isInCourse,
} from '../../../../../helpers/utils';
import RouteIcon from '../../../../../components/RouteIcon/RouteIcon';
import { twMerge } from 'tailwind-merge';
import { dateFormat } from '../../../../../helpers/format/dateFormat';

interface NextLocationProps {
	order: OrderResponseApi;
}

const NextLocation = ({ order }: NextLocationProps) => {
	const { locations, acceptanceDate, scheduleDate } = order;

	const handleInterval = useCallback(() => {
		return dayjs().diff(scheduleDate || acceptanceDate, 'minute');
	}, [scheduleDate, acceptanceDate]);

	const [diff, setDiff] = useState(handleInterval());

	const pendingLocation = useMemo(
		() => getCurrentPendingLocation(order),
		[order]
	);

	const showDelay = useMemo(() => {
		return (
			locations?.[0] &&
			!hasCheckout(locations[0]) &&
			isInCourse(order) &&
			diff >= 10
		);
	}, [locations, order, diff]);

	useEffect(() => {
		const interval = setInterval(() => {
			const diff = handleInterval();
			setDiff(diff);
		}, 60 * 1000); // 1 minute
		return () => clearInterval(interval);
	}, [scheduleDate, acceptanceDate]);

	const delay = useMemo(() => {
		return dayjs(scheduleDate || acceptanceDate)
			.locale('pt-br')
			.fromNow(true);
	}, [scheduleDate, acceptanceDate]);

	return (
		<div
			className={twMerge(
				'flex min-w-max max-w-fit flex-col items-center justify-center px-2 py-1',
				showDelay && 'rounded bg-terracota-0 text-white'
			)}
		>
			{pendingLocation ? (
				<div className="flex justify-center">
					<RouteIcon
						number={pendingLocation ? pendingLocation?.sequence + 1 : 1}
						checkoutDate={pendingLocation?.checkoutDate}
						checkinDate={pendingLocation?.checkinDate}
						reasonDate={pendingLocation?.reasonDate}
					/>
					<div className="flex min-w-[5rem] flex-col text-xs">
						{!pendingLocation.checkinDate && (
							<span className="font-medium">
								Motorista <br /> a caminho
							</span>
						)}

						{pendingLocation.checkinDate &&
							!pendingLocation.checkoutDate &&
							!pendingLocation.reasonDate && (
								<div className="flex flex-col text-xs">
									<span className="font-medium">
										Checkin -{' '}
										{pendingLocation ? pendingLocation?.sequence + 1 : 1}/
										{locations.length}
									</span>
									<span>{dateFormat(pendingLocation?.checkinDate)}</span>
								</div>
							)}

						{pendingLocation.checkoutDate && !pendingLocation.reasonDate && (
							<div className="flex flex-col text-xs">
								<span className="font-medium">
									Checkout -{' '}
									{pendingLocation ? pendingLocation?.sequence + 1 : 1}/
									{locations.length}
								</span>
								<span>{dateFormat(pendingLocation?.checkoutDate)}</span>
							</div>
						)}

						{pendingLocation.reasonDate && (
							<div className="flex flex-col text-xs">
								<span className="font-medium">
									Não entregue -{' '}
									{pendingLocation ? pendingLocation?.sequence + 1 : 1}/
									{locations.length}
								</span>
								<span>{dateFormat(pendingLocation?.reasonDate)}</span>
							</div>
						)}
					</div>
				</div>
			) : (
				<div className="text-center text-xs font-medium">-</div>
			)}
			{showDelay ? (
				<span className="pt-1 text-center text-xs font-medium">
					Atrasado para coleta <br /> há {delay}
				</span>
			) : (
				<span className="w-full pt-1 text-left text-xs font-medium">
					Próxima parada
				</span>
			)}
		</div>
	);
};

export default NextLocation;
