import { z } from 'zod';

export const RegionSchema = z.object({
	geofenceId: z
		.object({
			label: z.any(),
			value: z.number(),
		})
		.transform((value) => value.value),
	remember: z.boolean(),
});

export type Region = z.infer<typeof RegionSchema>;
