import { z } from 'zod';
import { ErrorsMessageHelper } from '../../../../../helpers/errors/ErrorsMessageHelper';
import validateCpf from '../../../../../helpers/validation/cpfValidation';

export const AddReceiptFormSchema = z.object({
	account: z.string().min(1, { message: ErrorsMessageHelper.required }),
	accountOwner: z.string().min(1, { message: ErrorsMessageHelper.required }),
	accountOwnerCpf: z
		.string()
		.min(1, { message: ErrorsMessageHelper.required })
		.refine((value) => validateCpf(value), {
			message: 'CPF inválido',
		}),
	agency: z.string().min(1, { message: ErrorsMessageHelper.required }),
	bankNumber: z
		.object({
			label: z.string(),
			value: z.number(),
		})
		.transform((value) => value?.value),
	digit: z.string().min(1, { message: ErrorsMessageHelper.required }),
	title: z.string().min(1, { message: ErrorsMessageHelper.required }),
});

export type AddReceiptFormSchemaType = z.infer<typeof AddReceiptFormSchema>;
