import React, { useState, useEffect, useRef } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import { api } from '../../services/angular/axios';

interface PdfUploaderProps {
	onPdfPick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	pdfSelected?: () => void;
	setImageName: (name: string) => void; // New prop to set imageName
	unsetPreview?: () => void;
	externalPreview?: string;
}

function PdfUploader({
	onPdfPick,
	pdfSelected,
	setImageName,
	unsetPreview,
}: PdfUploaderProps) {
	const [preview, setPreview] = useState<string | undefined>();
	const [imageName, setImageNameInternal] = useState<string | undefined>();
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		pdfSelected?.();
	}, [preview]);

	const handlePdfUpload = async (file: File) => {
		const formData = new FormData();
		formData.append('image', file);

		try {
			const response = await api.post('/assets', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			setImageNameInternal(response.data.imageName);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (imageName) {
			setImageName(imageName);
		}
	}, [imageName]);

	return (
		<span className="w-fit">
			<div
				onClick={() => {
					!preview && inputRef?.current?.click();
				}}
				className={`bg-gray-100 relative w-40 cursor-pointer rounded-full ${
					preview ? 'h-40' : 'h-10'
				}`}
			>
				{preview ? (
					<>
						<embed
							className="h-full w-full overflow-hidden"
							src={`${preview}#toolbar=0&navpanes=0&scrollbar=0`}
							type="application/pdf"
							style={{ width: '100%', height: '100%', borderRadius: '6px' }}
						/>
						<div
							className="absolute inset-0 top-0 flex h-1/3 w-full cursor-pointer items-center justify-center gap-2 rounded bg-black bg-opacity-50 text-white hover:brightness-150 "
							onClick={() => {
								setPreview(undefined);
								unsetPreview?.();
							}}
						>
							<FaFilePdf size={25} />
							<span className="text-xs">Remover</span>
						</div>
					</>
				) : (
					<div className="absolute inset-0 flex items-center justify-center gap-2">
						<FaFilePdf size={25} /> Escolher PDF
					</div>
				)}
			</div>
			<input
				ref={inputRef}
				type="file"
				accept="application/pdf"
				className="hidden"
				onChange={(event) => {
					onPdfPick?.(event);
					const file = event.target.files?.[0];
					if (file) {
						setPreview(URL.createObjectURL(file));
						onPdfPick?.(event);
						void handlePdfUpload(file);
					}
				}}
			/>
		</span>
	);
}

export default PdfUploader;
