import { match } from 'ts-pattern';
import { useInviteCampaignStore } from '../inviteCampaignStore';

const ContainerTitle = () => {
	const { step } = useInviteCampaignStore((state) => state.formState);

	return (
		<div className="pb-4">
			<h1 className="self-center text-center text-xl font-bold text-neutral-900">
				{match(step)
					.with('phone', () => 'Para começar, precisamos que informe o seu número de telefone.')
					.with(
						'phoneValidation',
						() =>
							'Agora, precisamos que você confirme o código de verificação que enviamos por SMS.'
					)
					.with(
						'info',
						() => 'Agora, precisamos que preencha as suas informações básicas'
					)
					.with('senha', () => 'Crie sua senha')
					.with('foto', () => 'Hora de mostrar o sorriso na foto do perfil!')
					.with(
						'termos e política',
						() =>
							'Para seguir com o cadastro você precisa ler e aceitar os Termos de uso e a Politica de Privacidade'
					)
					.otherwise(() => '')}
			</h1>
		</div>
	);
};

export default ContainerTitle;
