import { ChangeEvent, ComponentProps, useEffect, useState } from "react";

interface InputMoneyProps extends ComponentProps<"input"> {
  value: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const DECIMAL_SIZE = 2;

const InputMoney = ({ value, onChange, ...props }: InputMoneyProps) => {
  const [currentValue, setCurrentValue] = useState<string>(`${value}`);

  useEffect(() => {
    const valueString = `${value}`;

    if (!/\D/.test(valueString.replace(".", ""))) {
      setCurrentValue(value.toFixed(DECIMAL_SIZE).toString().replace(".", ","));
    }
  }, [value]);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const valueRemoved = event.target.value.replace(",", "");

    const sizeSlice = valueRemoved.length - DECIMAL_SIZE;
    const newValue = [
      valueRemoved.slice(0, sizeSlice),
      ".",
      valueRemoved.slice(sizeSlice),
    ].join("");

    onChange({
      ...event,
      target: {
        ...event.target,
        value: newValue,
      },
    });
  };

  return (
    <div className="px-3 py-2 relative flex w-full rounded border border-neutral-100 text-sm text-neutral-900 placeholder-neutral-500 outline-2 -outline-offset-2 focus-within:outline">
      <div className="mr-1 flex select-none items-center">R$</div>
      <input
        placeholder={"Valor"} // Use a label fornecida ou 'Valor' se não houver label
        className="w-full bg-white text-sm text-neutral-900 placeholder-neutral-500"
        style={{ outline: "none" }}
        value={currentValue}
        onChange={handleOnChange}
        disabled={props.disabled}
        maxLength={props.maxLength}
        {...props}
      />
    </div>
  );
};

export default InputMoney;
