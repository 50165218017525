import { PageHeader } from '../../../../components/PageHeader';
import React, { useState } from 'react';
import { FiltersTab } from '../../../../components/FiltersTab';
import {
	Date,
	Input,
	Select,
	useZodForm,
} from '../../../../components/FormElements';
import { appState, useAppState } from '../../../../store/appState';

import useUserState, {
	User,
} from '../../../../services/angular/angularUserState';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../services/angular/axios';
import WhiteContainer, {
	WhiteContainerMenuInterface,
} from '../../../../components/WhiteContainer/WhiteContainer';
import {
	Actions,
	Table,
	TableActions,
} from '../../../../components/Table/Table';
import { Avatar } from '../../../../components/Avatar';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import dayjs from 'dayjs';
import { Tooltip } from '../../../../components/Tooltip';
import { HiLockClosed, HiLockOpen } from 'react-icons/hi';
import { useAlert } from '../../../../contexts/AlertContext';
import { toastMessage } from '../../../../helpers/toastMessage';
import { toast } from 'react-toastify';
import { MdAdminPanelSettings, MdPendingActions } from 'react-icons/md';
import { match, P } from 'ts-pattern';
import { phoneMask, removePhoneMask } from '../../../../helpers/mask/phoneMask';
import { useCopy } from '../../../../hooks/useCopy';
import {
	useUsersFilterState,
	usersFilterSchema,
} from '../../../../store/filters/usersFilter';
import {
	activeOptions,
	availability,
	blockedOptions,
	defaultMenu,
	driverStatusOptions,
	roleIdOptions,
	wasTrainedOptions,
} from './constants';
import { ToggleGroup } from '../../../../components/ToggleGroup';
import { Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Pending } from './pending';
import { TbSteeringWheel } from 'react-icons/tb';
import { BsBuildingFill } from 'react-icons/bs';
interface Menu extends WhiteContainerMenuInterface {
	value: number;
}

export const Users: React.FC = () => {
	const alert = useAlert();
	const copy = useCopy();
	const user = useUserState((userState) => userState.user);
	const geofence = useAppState((appState) => appState.geofence);

	const { params, setParams, setUsersFilter, clearUsersFilter, ...query } =
		useUsersFilterState();

	const [tableMenu, setTableMenu] = useState<Menu[]>(
		defaultMenu.map((item) =>
			query?.userTypes
				? {
						...item,
						selected: query?.userTypes === `${item.value}`,
				  }
				: item
		)
	);

	const form = useZodForm({
		schema: usersFilterSchema,
		defaultValues: {
			cellphone: undefined,
			cpf: undefined,
			name: undefined,
			...query,
			tagsIds: undefined,
			geofenceId: undefined,
			userAvailability: query.userAvailability
				? availability
						.map((x) => {
							return query.userAvailability?.includes(x.value) ? x : undefined;
						})
						.filter(Boolean)
				: [],
			roleId: query.roleId ? roleIdOptions[query.roleId] : undefined,
			// @ts-expect-error
			blocked: blockedOptions[query.blocked] ?? '',
			// @ts-expect-error
			wasTrained: wasTrainedOptions[query.wasTrained] ?? '',
			// @ts-expect-error
			active: activeOptions[query.active] ?? '',
			// @ts-expect-error
			driverStatus: driverStatusOptions[query.driverStatus] ?? '',
		},
	});

	const changeMenu = (index: number): void => {
		const menus = tableMenu.map((menu, i) => {
			if (i === index) {
				menu.selected = true;
			} else {
				menu.selected = false;
			}
			return menu;
		});

		setUsersFilter({
			...query,
			userTypes: `${menus[index].value}`,
		});

		setTableMenu(menus);
	};

	const {
		data: accounts,
		isLoading,
		isFetching,
		refetch,
	} = useQuery<any>({
		queryKey: ['usuarios', query, params, user?.id],
		queryFn: async () =>
			await api.get(
				'/users?include=companies,activeFreights,tags,companies.vehicles',
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
						availability: query?.userAvailability,
						...query,
					},
				}
			),
		enabled: !!user?.id,
		keepPreviousData: true,
	});

	const handleBlock = (idToBlock: number, user: string) => {
		alert.onCustom({
			title: 'Atenção',
			message: `Deseja mesmo bloquear o usuário ${user}?`,
			confirm: {
				onClick: async () => {
					try {
						await api.put(`/users/${idToBlock}/block`);
						toast.success(toastMessage.success.updated);
						await refetch();
					} catch (err) {
						toast.error(toastMessage.error.updated);
					}
				},
			},
		});
	};

	const handleUnblock = (idToUnblock: number, user: string) => {
		alert.onCustom({
			title: 'Atenção',
			message: `Deseja mesmo desbloquear o usuário ${user}?`,
			confirm: {
				onClick: async () => {
					try {
						await api.put(`/users/${idToUnblock}/unblock`);
						toast.success(toastMessage.success.updated);
						await refetch();
					} catch (err) {
						toast.error(toastMessage.error.updated);
					}
				},
			},
		});
	};

	const handleDelete = (idToDelete: number, user: string) => {
		alert.onCustom({
			title: 'Atenção',
			message: `Deseja mesmo excluir o usuário ${user}?`,
			confirm: {
				onClick: async () => {
					try {
						await api.delete(`/users/${idToDelete}`);
						toast.success(toastMessage.success.deleted);
						void refetch();
					} catch (err) {
						toast.error(toastMessage.error.deleted);
					}
				},
			},
		});
	};

	return (
		<div className="w-full flex-col text-neutral-900">
			<PageHeader
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				title={`Usuários - ${accounts?.data.meta.pagination.total || 0}`}
				description="Relação de usuários cadastrados"
			/>
			<FiltersTab
				className="w-full md:w-[320px]"
				dropDownFilter
				form={form}
				clearFilters={() => {
					form.reset({
						roleId: '',
						tagsIds: [],
						driverStatus: '',
						active: '',
						blocked: '',
						wasTrained: '',
						initialDate: '',
						endDate: '',
						geofenceId: null,
						userAvailability: [],
					});
					setUsersFilter({
						active: '',
						cellphone: undefined,
						cpf: undefined,
						driverStatus: undefined,
						geofenceId: undefined,
						name: undefined,
						endDate: undefined,
						initialDate: undefined,
						roleId: undefined,
						tagsIds: undefined,
						userAvailability: undefined,
						blocked: '',
						wasTrained: '',
						userTypes: query?.userTypes,
					});
				}}
				onSubmit={(data) => {
					setUsersFilter({
						...data,
						userTypes: query?.userTypes,
					});
				}}
			>
				<Input
					variant="light"
					label="Nome"
					className="w-auto"
					{...form.register('name')}
				/>

				<Input
					variant="light"
					label="CPF"
					className="w-auto"
					{...form.register('cpf')}
				/>
				<Input
					variant="light"
					label="Celular"
					className="w-auto"
					{...form.register('cellphone')}
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="initialDate"
					label="Data de criação (De)"
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="endDate"
					label="Data de criação (Até)"
				/>
				<Controller
					name="driverStatus"
					control={form.control}
					render={({ field }) => (
						<ToggleGroup
							label="Cadastro de motorista"
							options={['Aprovado', 'Aguardando', 'Rejeitado']}
							setValue={field.onChange}
							value={field.value ?? ''}
							itemClassName={[
								'data-[state=on]:bg-green data-[state=on]:text-neutral-900',
								'data-[state=on]:bg-orange data-[state=on]:text-neutral-900',
								'data-[state=on]:bg-red data-[state=on]:text-neutral-900',
							]}
						/>
					)}
				/>
				<Controller
					name="active"
					control={form.control}
					render={({ field }) => (
						<ToggleGroup
							label="Status da conta"
							options={Object.values(activeOptions)}
							setValue={field.onChange}
							value={field.value ?? ''}
							itemClassName={[
								'data-[state=on]:bg-green data-[state=on]:text-neutral-900',
								'data-[state=on]:bg-red data-[state=on]:text-neutral-900',
							]}
						/>
					)}
				/>
				<Controller
					name="blocked"
					control={form.control}
					render={({ field }) => (
						<ToggleGroup
							label="Acesso"
							options={Object.values(blockedOptions)}
							setValue={field.onChange}
							value={field.value ?? ''}
							itemClassName={[
								'data-[state=on]:bg-green data-[state=on]:text-neutral-900',
								'data-[state=on]:bg-red data-[state=on]:text-neutral-900',
							]}
						/>
					)}
				/>
				<Controller
					name="wasTrained"
					control={form.control}
					render={({ field }) => (
						<ToggleGroup
							label="Treinamento"
							options={Object.values(wasTrainedOptions)}
							setValue={field.onChange}
							value={field.value ?? ''}
						/>
					)}
				/>
				<Controller
					name="roleId"
					control={form.control}
					render={({ field }) => (
						<ToggleGroup
							label="Conta de usuário"
							options={Object.values(roleIdOptions)}
							setValue={field.onChange}
							value={field.value ?? ''}
						/>
					)}
				/>
				<Select
					variant="light"
					className="md:w-auto md:min-w-[128px]"
					label="Tags"
					isMulti
					isTagField
					controller={{
						control: form.control,
						name: 'tagsIds',
					}}
					options={Object.values(appState.tags).map((tag) => ({
						label: tag.label,
						value: tag.value,
					}))}
				/>
				<Select
					variant="light"
					className="md:w-auto md:min-w-[128px]"
					label="Disponibilidade do entregador"
					isMulti
					controller={{
						control: form.control,
						name: 'userAvailability',
					}}
					options={availability}
				/>
				<Select
					className="md:w-auto md:min-w-[128px]"
					variant="light"
					label="Geofence da disponibilidade"
					controller={{
						control: form.control,
						name: 'geofenceId',
					}}
					options={geofence?.map((opt) => ({
						label: opt.name,
						value: opt.id,
					}))}
				/>
			</FiltersTab>

			<WhiteContainer
				className="text-sm"
				menu={tableMenu}
				onClickMenu={changeMenu}
			>
				<Table<User>
					isLoading={isLoading || isFetching}
					triggerPageChange={form.formState.isSubmitting}
					data={accounts?.data.data || []}
					total={accounts?.data.meta.pagination.total || 0}
					disabledSort={{
						online: true,
						trainingDate: true,
						avatarUrl: true,
						requestFreights: true,
						driverStatus: true,
						active: true,
					}}
					params={params}
					onParamsChange={(params) => {
						setParams(params);
					}}
					columns={[
						{
							Header: '',
							accessor: 'requestFreights',
							width: '0%',
							Cell: ({ row }) => {
								return (
									<div className="flex flex-col gap-1 text-sm font-semibold text-orange">
										{(row.original.roleId === 2 ||
											row.original.roleId === 1) && (
											<Tooltip message="Admin">
												<MdAdminPanelSettings size={20} />
											</Tooltip>
										)}
										{row.original.makeFreight && row.original.roleId === 3 && (
											<Tooltip message="Fretista">
												<TbSteeringWheel className="text-fretista" size={20} />
											</Tooltip>
										)}
										{row.original.requestFreights &&
											row.original.roleId === 3 && (
												<Tooltip message="Empresa">
													<BsBuildingFill className="text-empresa" size={20} />
												</Tooltip>
											)}
									</div>
								);
							},
						},
						{
							accessor: 'name',
							Header: 'Nome',
							Cell: ({ row }) => {
								const { name, tags, id, avatarUrl } = row.original;

								const { data: avatar } = useQuery(
									['avatar', avatarUrl],
									async () =>
										await api.get(
											avatarUrl?.substring(avatarUrl.indexOf('/companies'))
										),
									{
										enabled: !!avatarUrl,
									}
								);

								return (
									<div className="flex flex-row items-center gap-2">
										<Avatar
											avatarProps={{ className: 'min-w-[40px] min-h-[40px]' }}
											name={name}
											src={avatar?.data.url}
											isLoading={isLoading || isFetching}
										/>
										<Link to={`/usuarios/${id}`} target="_blank">
											<p className="line-clamp-3">{name}</p>
											<div className="flex flex-wrap gap-1.5 p-px">
												{tags?.map(({ name, id }) => (
													<p
														key={id}
														className="rounded bg-neutral-100 px-1 text-2xs leading-4 text-neutral-900 ring-1 ring-neutral-200"
													>
														{name}
													</p>
												))}
											</div>
										</Link>
									</div>
								);
							},
						},
						{
							accessor: 'cpf',
							Header: 'CPF',
							Cell: ({ value }) => {
								return <div className="min-w-max">{value || '-'}</div>;
							},
						},
						{
							accessor: 'cellphone',
							Header: 'Celular',
							Cell: ({ row }) => {
								const { cellphone, name } = row.original;
								return (
									<div className="flex min-w-max justify-between gap-1">
										<span
											className="cursor-copy"
											onClick={() => {
												void copy(removePhoneMask(cellphone, '+55'));
											}}
										>
											{phoneMask(removePhoneMask(cellphone))}
										</span>
										<Tooltip message="Abir whatsapp">
											<a
												href={`https://api.whatsapp.com/send?phone=55${cellphone?.replace(
													/\D/g,
													''
												)}&text=Ol%C3%A1,%20${name}!`}
												target="_blank"
												rel="noreferrer"
											>
												<AiOutlineWhatsApp
													size={20}
													className="cursor-pointer text-green hover:text-primary-400"
												/>
											</a>
										</Tooltip>
									</div>
								);
							},
						},
						{
							accessor: 'email',
							Header: 'Email',
							Cell: ({ value }) => (
								<div className="max-w-[192px]">
									<p className="truncate">{value || '-'}</p>
								</div>
							),
						},
						{
							accessor: 'observation',
							Header: 'Observação',
							Cell: ({ value }) => (
								<div className="max-w-[192px]">
									<p className="truncate">{value || '-'}</p>
								</div>
							),
						},
						{
							accessor: 'created_at',
							Header: 'Criado',
							Cell: ({ value }) => (
								<span className="flex">
									{dayjs(value).format('DD/MM/YYYY HH:mm')}
								</span>
							),
						},
						{
							accessor: 'updated_at',
							Header: 'Atualizado',
							Cell: ({ value }) => (
								<span className="flex">
									{dayjs(value).format('DD/MM/YYYY HH:mm')}
								</span>
							),
						},
						{
							accessor: 'trainingDate',
							Header: 'Treinado',
							Cell: ({ row }) => {
								const { trainingDate } = row.original;
								const date = dayjs(trainingDate).format('DD/MM/YYYY HH:mm');

								return (
									<span className="text-sm text-neutral-900">
										{trainingDate !== null ? date : '-'}
									</span>
								);
							},
						},
						{
							accessor: 'online',
							Header: 'Disponibilidade',
							Cell: ({ row }) => {
								return (
									<div className="flex gap-4">
										<Pending user={row.original} />

										{match(row.original)
											.with(
												{
													online: true,
													awaitingFreight: true,
													activeFreights: P.when((x) => !x?.length),
													driverStatus: 3,
												},
												() => {
													return (
														<Tooltip message={`Disponível e online`}>
															<div className="h-4 w-4 rounded-full bg-green shadow-inner shadow-neutral-700" />
														</Tooltip>
													);
												}
											)
											.with(
												{
													awaitingFreight: true,
													activeFreights: P.when((x) => !x?.length),
													online: false,
													driverStatus: 3,
												},
												({ latestOnline }) => {
													const date =
														dayjs(latestOnline).format('DD/MM/YYYY HH:mm');
													return (
														<Tooltip
															message={`Disponível e offline, Estava online até ${date}`}
														>
															<div className="h-4 w-4 rounded-full bg-blue shadow-inner shadow-neutral-700" />
														</Tooltip>
													);
												}
											)
											.with(
												{
													activeFreights: P.when((x) => !!x?.length),
													driverStatus: 3,
												},
												({ activeFreights }) => (
													<Tooltip
														message={`Entregando pedido #${activeFreights[0].id}`}
													>
														<div className="h-4 w-4 rounded-full bg-yellow-500 shadow-inner shadow-neutral-700" />
													</Tooltip>
												)
											)
											.with(
												{
													stagingArea: true,
													driverStatus: 3,
												},
												() => (
													<Tooltip message="Pendência">
														<MdPendingActions
															className="text-neutral-400"
															size={20}
														/>
													</Tooltip>
												)
											)
											.with(
												{
													awaitingFreight: false,
													driverStatus: 3,
												},
												() => (
													<Tooltip message="Indisponível">
														<div className="h-4 w-4 rounded-full bg-terracota-500 shadow-inner shadow-neutral-700" />
													</Tooltip>
												)
											)
											.otherwise(() => null)}
									</div>
								);
							},
						},
						{
							accessor: 'active',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => {
								const { original } = row;

								const actions: Array<Actions<typeof original> | undefined> = [
									{
										icon: 'edit',
										label: 'Editar',
										href: `/usuarios/${original.id}`,
									},
									{
										icon: original.blocked ? (
											<HiLockOpen size={18} />
										) : (
											<HiLockClosed size={18} />
										),
										label: original.blocked ? 'Desbloquear' : 'Bloquear',
										onAction: (data) => {
											original.blocked
												? handleUnblock(data?.id, data?.name)
												: handleBlock(data?.id, data?.name);
										},
									},
									{
										icon: 'trash',
										label: 'Excluir',
										onAction: (data) => {
											handleDelete(data?.id, data?.name);
										},
									},
								];

								return (
									<TableActions
										row={row}
										actions={
											actions.filter((action) => action) as Array<
												Actions<typeof original>
											>
										}
									/>
								);
							},
						},
					]}
					rowClassName={(row) => {
						const { blocked } = row;

						return blocked
							? 'odd:bg-terracota-100/50 even:bg-terracota-100/50 hover:bg-terracota-100/70'
							: '';
					}}
				/>
			</WhiteContainer>
		</div>
	);
};
