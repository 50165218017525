import { z } from 'zod';
import Button from '../../../../components/Button/Button';
import { Input, useZodForm } from '../../../../components/FormElements';
import { cpfMask, cpfRegex } from '../../../../helpers/mask/cpfMask';
import { useInviteCampaignStore } from '../inviteCampaignStore';
import { useMutation } from '@tanstack/react-query';
import { useApiVersionStore } from '../../../../services/angular/phpVersion';
import { api } from '../../../../services/angular/axios';
import validateCpf from '../../../../helpers/validation/cpfValidation';
import { useState } from 'react';

const schema = z.object({
	cpf: z
		.string({
			required_error: 'CPF obrigatório',
		})
		.regex(cpfRegex, {
			message: 'CPF inválido',
		})
		.refine((cpf) => validateCpf(cpf), {
			message: 'CPF inválido',
		}),
	name: z.string({
		required_error: 'Nome obrigatório',
	}),
	email: z
		.string({
			required_error: 'E-mail obrigatório',
		})
		.email({
			message: 'E-mail inválido',
		}),
});

export const InfoStep = () => {
	const setVersion = useApiVersionStore((state) => state.setVersion);
	const setStep = useInviteCampaignStore((state) => state.setStep);
	const formState = useInviteCampaignStore((state) => state.formState);
	const upsertForm = useInviteCampaignStore((state) => state.upsertForm);

	const [error, setError] = useState('');

	const form = useZodForm({
		schema,
		defaultValues:
			'cpf' in formState
				? {
						cpf: formState?.cpf,
						email: formState?.email,
						name: formState?.name,
				  }
				: undefined,
	});

	const { mutate: verifyCpf } = useMutation({
		mutationFn: async (cpf: string) => {
			setVersion('v2');

			return await api.get<{
				active: boolean;
				cpf: string;
			}>(`/verify-cpf/${cpf}`);
		},
		onSuccess: ({ data }) => {
			if (data.active) setError('CPF já cadastrado');
			else setError('');
		},
		onSettled: () => {
			setVersion('v1');
		},
	});

	if (formState.step !== 'info') return null;

	const onSubmit = form.handleSubmit((data) => {
		upsertForm({
			...formState,
			...data,
			step: 'senha',
		});
	});

	return (
		<div className="flex flex-col gap-4">
			<Input
				autoFocus
				type='tel'
				errorMessage={form.formState.errors.cpf?.message || error}
				{...form.register('cpf', {
					onChange: (e) => {
						e.target.value = cpfMask(e.target.value);

						if (e.target.value.length === 14) {
							if (validateCpf(e.target.value)) {
								verifyCpf(e.target.value);
							} else {
								form.setError('cpf', {
									message: 'CPF inválido',
								});
							}
						}
					},
				})}
				label="CPF"
				placeholder="000.000.000-00"
			/>
			<Input
				errorMessage={form.formState.errors.name?.message}
				{...form.register('name')}
				label="Nome completo"
				placeholder="Nome"
			/>
			<Input
				errorMessage={form.formState.errors.email?.message}
				{...form.register('email')}
				label="E-mail"
				placeholder="E-mail"
			/>
			<div className="flex flex-col items-center gap-4">
				<Button
					type="submit"
					disabled={!form.formState.isValid || !!error}
					// eslint-disable-next-line @typescript-eslint/no-misused-promises
					onClick={onSubmit}
				>
					Avançar
				</Button>
				<Button onClick={() => setStep('phoneValidation')} variant="secondary">
					Voltar
				</Button>
			</div>
		</div>
	);
};
