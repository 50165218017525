import { z } from 'zod';
import {
	Form,
	Input,
	SwitchButton,
	useZodForm,
} from '../../../../../components/FormElements';
import Button from '../../../../../components/Button/Button';
import { useGetMerchant } from '../../../../../services/integration/ifood/useGetMerchant';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { integrationApi } from '../../../../../services/angular/axios';
import useUserState from '../../../../../services/angular/angularUserState';
import { ToastError } from '../../../../../helpers/errors/ToastError';
import { toast } from 'react-toastify';
import { UseFormReturn } from 'react-hook-form';

const schema = z.object({
	merchantId: z.string(),
	activate: z.boolean(),
	automaticOrders: z.boolean(),
	preparationTime: z.coerce.number(),
});
const formSchema = z.array(schema);

type IFoodSettingsProps = z.infer<typeof formSchema>;

export const IFoodSettings = () => {
	const companyId = useUserState((state) => state?.company?.id);
	const { data } = useGetMerchant();

	const { mutate: updateActivateIntegration } = useActivateIntegration();
	const { mutate: updateAutomaticOrders } = useAutomaticOrders();
	const { mutate: updatePreparationTime } = usePreparationTime();

    const form: UseFormReturn<IFoodSettingsProps> = useZodForm({
        schema: formSchema,
        values: data,
    });

	const { register, setValue, watch } = form;

	const handleSubmit = (formData: IFoodSettingsProps) => {
		if (!companyId) return;

        formData.forEach(({ merchantId, activate, automaticOrders, preparationTime }, index) => {
            const currentMerchant = (data)?.find((m) => m.merchantId === merchantId);
            if (!currentMerchant) return;

            if (currentMerchant.activate !== activate) {
                updateActivateIntegration(
                    {
                        companyId,
                        merchantId,
                        activate,
                    },
                    {
                        onError: (error) => {
                            ToastError(error);
                            setValue(`${index}.activate`, currentMerchant.activate);
                        },
                    }
                );
            }

            if (currentMerchant.automaticOrders !== automaticOrders) {
                updateAutomaticOrders(
                    {
                        companyId,
                        merchantId,
                        automaticOrders,
                    },
                    {
                        onError: (error) => {
                            ToastError(error);
                            setValue(`${index}.automaticOrders`, currentMerchant.automaticOrders);
                        },
                    }
                );
            }

            if (currentMerchant.preparationTime !== preparationTime) {
                updatePreparationTime(
                    {
                        companyId,
                        merchantId,
                        preparationTime,
                    },
                    {
                        onError: (error) => {
                            ToastError(error);
                            setValue(`${index}.preparationTime`, currentMerchant.preparationTime);
                        },
                    }
                );
            }
        });

        toast.success('Configurações salvas com sucesso!');
    };
	if (!data) return null;

	return (
            <Form form={form} onSubmit={handleSubmit}>
            <div className="flex flex-col gap-6 rounded-md bg-white px-8 py-6">
                <h1 className="text-lg text-center font-bold text-neutral-800">Configurações</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                {data.map((merchant, index) => (
                    <div key={index} className="bg-gray-50 p-4 rounded-xl shadow-xl">

                    <h1 className="text-xl font-bold text-center text-neutral-800">
                    {merchant.name}
                    </h1>
                    <p className="text-sm leading-4 text-center text-neutral-400">
                    Merchant ID: <span className="font-bold">{merchant.merchantId}</span>
                    </p>
                    <div className="flex flex-col gap-4 mt-4">
                        <SwitchButton
                        label="Integração"
                        defaultChecked={merchant.activate}
                        {...register(`${index}.activate`)}
                        />
                        <SwitchButton
                        label="Pedidos automáticos"
                        defaultChecked={merchant.automaticOrders}
                        {...register(`${index}.automaticOrders`)}
                        />
                        {watch(`${index}.automaticOrders`) && (
                        <Input
                            className="w-full"
                            label="Tempo de preparo (minutos)"
                            type="number"
                            defaultValue={merchant.preparationTime}
                            {...register(`${index}.preparationTime`)}
                        />
                        )}
                    </div>
                    </div>
                ))}
                </div>
                <div className="flex justify-center">
                <Button variant="blue-primary" className="max-w-fit" type="submit">
                    Salvar
                </Button>
                </div>
            </div>
            </Form>
	);
};

const useActivateIntegration = () => {
    const companyId = useUserState((state) => state?.company?.id);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (data: { companyId: number; merchantId: string; activate: boolean }) => {
            await integrationApi.put('/ifood/merchant/activate', data);
        },
        onMutate: async ({ merchantId, activate }) => {
            await queryClient.cancelQueries({
                queryKey: ['ifood-merchant', companyId],
            });

            const prev = queryClient.getQueryData(['ifood-merchant', companyId]);

            queryClient.setQueryData(['ifood-merchant', companyId], (old: any) => {
				// @ts-expect-error
                return old.map((merchant) =>
                    merchant.merchantId === merchantId ? { ...merchant, activate } : merchant
                );
            });

            return { prev };
        },
    });
};

const useAutomaticOrders = () => {
    const companyId = useUserState((state) => state?.company?.id);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (data: {
            companyId: number;
            merchantId: string;
            automaticOrders: boolean;
        }) => {
            await integrationApi.put('/ifood/merchant/automatic-orders', data);
        },
        onMutate: async ({ merchantId, automaticOrders }) => {
            await queryClient.cancelQueries({
                queryKey: ['ifood-merchant', companyId],
            });

            const prev = queryClient.getQueryData(['ifood-merchant', companyId]);

            queryClient.setQueryData(['ifood-merchant', companyId], (old: any) => {
				// @ts-expect-error
                return old.map((merchant) =>
                    merchant.merchantId === merchantId ? { ...merchant, automaticOrders } : merchant
                );
            });

            return { prev };
        },
    });
};

const usePreparationTime = () => {
    const companyId = useUserState((state) => state?.company?.id);
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (data: {
            companyId: number;
            merchantId: string;
            preparationTime: number;
        }) => {
            await integrationApi.put('/ifood/merchant/preparation-time', data);
        },
        onMutate: async ({ merchantId, preparationTime }) => {
            await queryClient.cancelQueries({
                queryKey: ['ifood-merchant', companyId],
            });

            const prev = queryClient.getQueryData(['ifood-merchant', companyId]);

            queryClient.setQueryData(['ifood-merchant', companyId], (old: any) => {
				// @ts-expect-error
                return old.map((merchant) =>
                    merchant.merchantId === merchantId ? { ...merchant, preparationTime } : merchant
                );
            });

            return { prev };
        },
    });
};