/* eslint-disable no-unused-vars */
import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Avatar } from '../../Avatar';
import { BsWhatsapp } from 'react-icons/bs';
import { Tooltip } from '../../Tooltip';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../services/angular/axios';
import { phoneMask, removePhoneMask } from '../../../helpers/mask/phoneMask';
import {
	Driver as DriverType,
	DriverCompany,
} from '../../../services/angular/types/Order';
import { useCopy } from '../../../hooks/useCopy';

interface Props {
	children: React.ReactNode;
	orderId: number;
	driver: DriverType;
	driverCompany: DriverCompany;
}

export const Driver: React.FC<Props> = ({
	children,
	orderId,
	driver,
	driverCompany,
}) => {
	const copy = useCopy();

	const {
		data: driverAvatar,
		isFetched,
		refetch: refetchAvatar,
	} = useQuery(
		['user-avatar', driver.avatar],
		async () => {
			if (!driver?.avatar) return;
			return await api.get('/assets', {
				params: {
					imageName: driver.avatar,
				},
			});
		},
		{
			enabled: false,
		}
	);

	const { data: companyAvatar, refetch: refetchCompanyAvatar } = useQuery(
		['company-avatar', driverCompany.avatar],
		async () => {
			if (!driverCompany?.avatar) return;
			return await api.get('/assets', {
				params: {
					imageName: driverCompany.avatar,
				},
			});
		},
		{
			enabled: false,
		}
	);

	return (
		<DropdownMenu.Root
			onOpenChange={() => {
				if (!isFetched) {
					void refetchAvatar();
					void refetchCompanyAvatar();
				}
			}}
		>
			<DropdownMenu.Trigger className="flex items-start focus:outline-none">
				{children}
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content className="ml-2 w-[calc(100vw-32px)] min-w-[256px] rounded bg-white p-4 text-neutral-0 shadow shadow-neutral-0 md:ml-0 md:w-auto">
					<div className="flex items-end justify-between font-bold">
						<h1 className="text-lg ">Fretista</h1>
						<p className="text-lg text-orange">#{orderId}</p>
					</div>
					<hr className="my-2 text-neutral-500" />
					<div className="flex items-center gap-4">
						<span className="h-16 w-16 ">
							<Avatar
								src={companyAvatar?.data.url}
								avatarProps={{
									className: ' h-16 w-16',
								}}
							/>
						</span>
						<div className="flex flex-col">
							<span className="font-bold">
								{driverCompany?.tradingName || driverCompany?.companyName}
							</span>
							<span className="truncate pb-1 text-xs">
								{driverCompany?.email || driverCompany?.supportEmail}
							</span>
							<span
								className="cursor-copy text-sm"
								onClick={() => {
									void copy(
										removePhoneMask(
											driverCompany?.cellphone || driverCompany?.phone
										)
									);
								}}
							>
								{phoneMask(
									removePhoneMask(
										driverCompany?.cellphone || driverCompany?.phone
									)
								)}
							</span>
						</div>
					</div>
					<hr className="mb-2 mt-1 text-neutral-500" />
					<div className="flex items-center gap-4">
						<span className="h-16 w-16 ">
							<Avatar
								src={driverAvatar?.data.url}
								avatarProps={{
									className: ' h-16 w-16',
								}}
							/>
						</span>

						<div className="flex flex-col text-sm">
							<span className="text-base font-bold">{driver.name}</span>
							<span className="truncate pb-1 text-xs">{driver.email}</span>
							<span className="flex gap-2">
								<span
									className="cursor-copy"
									onClick={() => {
										void copy(removePhoneMask(driver?.cellphone, '+55'));
									}}
								>
									{phoneMask(removePhoneMask(driver?.cellphone))}
								</span>
								{driver?.cellphone && (
									<Tooltip message="Abrir whatsapp">
										<BsWhatsapp
											onClick={() => {
												window.open(
													`https://api.whatsapp.com/send?phone=55${driver.cellphone.replace(
														/\D/g,
														''
													)}&text=${encodeURI(
														`Olá ${driver.name.split(' ')[0]}`
													)}`,
													'_blank'
												);
											}}
											className="cursor-pointer text-green"
											size={16}
										/>
									</Tooltip>
								)}
							</span>
						</div>
					</div>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};
