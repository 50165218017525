import React from 'react';
import { SvgProps } from '../../../assets/svg/SvgPropsInterface';

const ProcessIcon: React.FC<SvgProps> = () => {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 40 40"
			enableBackground="new 0 0 40 40"
			xmlSpace="preserve"
			fill="currentColor"
		>
			<g>
				<path
					d="M27.383,9.585c-0.393,0-0.777,0.075-1.143,0.224c-1.553,0.628-2.303,2.404-1.674,3.963
		c0.469,1.152,1.576,1.896,2.822,1.896l0,0c0.39,0,0.771-0.074,1.133-0.221c0.756-0.306,1.346-0.886,1.662-1.635
		c0.318-0.749,0.326-1.573,0.021-2.326C29.738,10.331,28.627,9.585,27.383,9.585z M28.827,13.235
		c-0.165,0.386-0.47,0.686-0.858,0.844c-0.187,0.074-0.383,0.113-0.58,0.113c-0.644,0-1.215-0.383-1.454-0.977
		c-0.325-0.801,0.062-1.717,0.86-2.04c0.188-0.076,0.387-0.115,0.588-0.115c0.642,0,1.214,0.385,1.456,0.978
		C28.994,12.425,28.99,12.85,28.827,13.235z"
				/>
				<path
					d="M14.458,21.109c-2.441,0-4.429,1.986-4.429,4.43c0,2.438,1.987,4.422,4.429,4.422c2.44,0,4.426-1.984,4.426-4.422
		C18.884,23.096,16.898,21.109,14.458,21.109z M14.458,28.486c-1.628,0-2.954-1.322-2.954-2.947c0-1.629,1.326-2.954,2.954-2.954
		s2.951,1.325,2.951,2.954C17.409,27.164,16.086,28.486,14.458,28.486z"
				/>
				<path
					d="M23.614,23.695l-1.813-0.663c-0.106-0.313-0.233-0.618-0.379-0.916l0.811-1.754c0.193-0.415,0.127-0.909-0.168-1.255
		c-0.355-0.422-0.752-0.818-1.177-1.177c-0.212-0.179-0.482-0.279-0.762-0.279c-0.167,0-0.329,0.035-0.482,0.102
		c-0.003,0.002-0.007,0.004-0.011,0.006l-1.754,0.813c-0.294-0.144-0.6-0.271-0.913-0.376l-0.67-1.821
		c-0.158-0.425-0.553-0.724-1.005-0.763c-0.549-0.048-1.116-0.048-1.664,0c-0.453,0.039-0.848,0.338-1.008,0.767l-0.665,1.813
		c-0.311,0.107-0.617,0.234-0.915,0.38l-1.757-0.813c-0.003-0.002-0.006-0.004-0.009-0.006c-0.154-0.066-0.316-0.102-0.483-0.102
		c-0.281,0-0.552,0.101-0.763,0.28c-0.426,0.361-0.821,0.757-1.173,1.172c-0.296,0.346-0.363,0.838-0.172,1.257l0.813,1.754
		c-0.144,0.294-0.271,0.6-0.377,0.914l-1.816,0.667c-0.428,0.157-0.729,0.553-0.769,1.012c-0.043,0.55-0.043,1.106,0,1.658
		c0.039,0.455,0.34,0.851,0.768,1.007l1.815,0.668c0.106,0.312,0.233,0.617,0.379,0.913l-0.814,1.763
		c-0.188,0.409-0.122,0.897,0.169,1.249c0.355,0.421,0.753,0.818,1.18,1.18c0.003,0.002,0.005,0.004,0.007,0.006
		c0.212,0.175,0.479,0.269,0.753,0.269c0.172,0,0.344-0.038,0.494-0.109l1.75-0.813c0.294,0.146,0.601,0.272,0.917,0.38l0.668,1.815
		c0.16,0.433,0.557,0.732,1.012,0.765c0.279,0.023,0.558,0.036,0.827,0.036c0.27,0,0.548-0.013,0.828-0.036
		c0.455-0.032,0.852-0.332,1.011-0.767l0.666-1.81c0.31-0.107,0.616-0.236,0.914-0.38l1.765,0.816
		c0.152,0.067,0.314,0.102,0.482,0.102l0,0c0.272,0,0.54-0.094,0.751-0.267c0.004-0.002,0.008-0.006,0.012-0.01
		c0.424-0.359,0.82-0.755,1.174-1.172c0.299-0.346,0.367-0.842,0.172-1.257l-0.811-1.757c0.145-0.298,0.271-0.604,0.377-0.917
		l1.815-0.666c0.429-0.158,0.729-0.554,0.769-1.009c0.044-0.55,0.044-1.108-0.001-1.664C24.344,24.248,24.041,23.853,23.614,23.695z
		 M22.926,26.054l-1.977,0.729c-0.221,0.082-0.391,0.263-0.455,0.489c-0.13,0.454-0.312,0.896-0.54,1.311
		c-0.114,0.205-0.122,0.453-0.023,0.667l0.883,1.909c-0.23,0.259-0.478,0.505-0.737,0.735l-1.91-0.886
		c-0.212-0.098-0.46-0.088-0.666,0.023c-0.42,0.232-0.862,0.415-1.311,0.548c-0.226,0.063-0.407,0.232-0.488,0.453l-0.722,1.969
		c-0.354,0.023-0.689,0.023-1.042,0l-0.727-1.973c-0.082-0.221-0.263-0.39-0.488-0.455c-0.464-0.133-0.905-0.317-1.309-0.542
		c-0.207-0.115-0.455-0.125-0.669-0.025L8.837,31.89c-0.262-0.23-0.508-0.479-0.735-0.733l0.883-1.913
		c0.099-0.214,0.089-0.461-0.025-0.667c-0.23-0.417-0.413-0.856-0.542-1.308c-0.065-0.226-0.234-0.41-0.456-0.49L5.99,26.054
		c-0.02-0.346-0.02-0.695,0-1.039l1.976-0.727c0.22-0.08,0.388-0.263,0.455-0.487c0.133-0.463,0.315-0.903,0.541-1.311
		c0.114-0.207,0.124-0.455,0.025-0.668l-0.883-1.906c0.228-0.257,0.475-0.503,0.737-0.737l1.91,0.884
		c0.213,0.1,0.461,0.09,0.667-0.023c0.418-0.232,0.858-0.415,1.307-0.543c0.227-0.065,0.409-0.234,0.49-0.456l0.723-1.973
		c0.346-0.021,0.696-0.021,1.042,0l0.727,1.977c0.081,0.224,0.263,0.393,0.489,0.456c0.458,0.132,0.899,0.313,1.309,0.539
		c0.205,0.113,0.453,0.123,0.667,0.023l1.906-0.884c0.261,0.23,0.507,0.477,0.737,0.737l-0.883,1.912
		c-0.099,0.213-0.09,0.461,0.024,0.666c0.229,0.415,0.412,0.855,0.543,1.311c0.064,0.225,0.234,0.407,0.455,0.487l1.973,0.723
		C22.945,25.36,22.945,25.71,22.926,26.054z"
				/>
				<path
					d="M33.979,13.039c0.013-0.24,0.017-0.483,0.004-0.726l1.154-1.049c0.315-0.284,0.44-0.734,0.313-1.147
		c-0.141-0.455-0.319-0.895-0.532-1.312c-0.19-0.371-0.569-0.602-0.981-0.602c-0.016,0-0.031,0-0.039,0l-1.556,0.052
		c-0.157-0.179-0.329-0.352-0.509-0.517l0.074-1.56c0-0.006,0-0.014,0.002-0.021c0.008-0.425-0.229-0.822-0.594-1.009
		c-0.412-0.217-0.847-0.401-1.302-0.553c-0.109-0.034-0.225-0.054-0.341-0.054c-0.303,0-0.597,0.127-0.807,0.35l-1.063,1.139
		c-0.244-0.015-0.484-0.017-0.727-0.005l-1.049-1.157c-0.207-0.23-0.507-0.362-0.819-0.362c-0.111,0-0.221,0.017-0.328,0.05
		c-0.447,0.14-0.887,0.316-1.307,0.53c-0.385,0.193-0.623,0.597-0.605,1.021l0.051,1.558c-0.182,0.162-0.354,0.331-0.517,0.51
		l-1.559-0.075C20.51,8.079,20.11,8.31,19.914,8.688c-0.218,0.413-0.404,0.851-0.553,1.301c-0.001,0.005-0.003,0.011-0.005,0.015
		c-0.123,0.405-0.012,0.84,0.302,1.14l1.138,1.061c-0.016,0.243-0.018,0.487-0.006,0.729l-1.158,1.049
		c-0.314,0.29-0.435,0.737-0.311,1.138c0.067,0.223,0.145,0.441,0.238,0.672c0.094,0.23,0.19,0.44,0.292,0.64
		c0.188,0.377,0.566,0.609,0.987,0.609c0.012,0,0.021,0,0.037-0.002l1.553-0.052c0.16,0.182,0.332,0.355,0.512,0.519l-0.075,1.565
		c-0.018,0.423,0.214,0.824,0.587,1.021c0.399,0.212,0.836,0.398,1.303,0.554c0.111,0.038,0.229,0.056,0.346,0.056
		c0.307,0,0.601-0.129,0.807-0.354l1.062-1.134c0.242,0.014,0.484,0.016,0.729,0.004l1.047,1.151c0.21,0.232,0.51,0.364,0.82,0.364
		c0.109,0,0.22-0.015,0.325-0.048c0.45-0.14,0.892-0.318,1.304-0.528c0.385-0.189,0.625-0.592,0.611-1.027l-0.052-1.552
		c0.181-0.16,0.354-0.333,0.517-0.512l1.559,0.075c0.019,0.002,0.036,0.002,0.055,0.002c0.414,0,0.786-0.227,0.975-0.59
		c0.217-0.407,0.403-0.843,0.553-1.299c0.133-0.403,0.02-0.854-0.293-1.15L33.979,13.039z M32.486,12.089
		c0.043,0.386,0.039,0.773-0.008,1.15c-0.03,0.234,0.057,0.469,0.228,0.63l1.226,1.148c-0.081,0.222-0.172,0.435-0.273,0.64
		l-1.675-0.082c-0.236-0.01-0.461,0.09-0.608,0.272c-0.246,0.304-0.525,0.578-0.828,0.816c-0.185,0.145-0.289,0.369-0.281,0.605
		l0.057,1.671c-0.21,0.098-0.427,0.187-0.646,0.264l-1.129-1.245c-0.141-0.153-0.34-0.24-0.545-0.24c-0.027,0-0.055,0-0.08,0.004
		c-0.393,0.042-0.781,0.038-1.154-0.008c-0.232-0.03-0.469,0.056-0.629,0.229l-1.146,1.224c-0.225-0.082-0.438-0.175-0.643-0.272
		l0.081-1.677c0.012-0.236-0.091-0.461-0.273-0.609c-0.303-0.246-0.575-0.522-0.81-0.822c-0.145-0.188-0.37-0.292-0.607-0.286
		l-1.674,0.058c-0.047-0.104-0.094-0.209-0.14-0.322c-0.045-0.109-0.085-0.218-0.123-0.323l1.243-1.129
		c0.174-0.158,0.264-0.391,0.237-0.623c-0.04-0.38-0.036-0.772,0.009-1.162c0.028-0.232-0.057-0.465-0.229-0.626l-1.225-1.145
		c0.08-0.22,0.17-0.433,0.272-0.641l1.68,0.079c0.235,0.012,0.46-0.09,0.608-0.272c0.24-0.296,0.52-0.572,0.826-0.816
		c0.184-0.145,0.287-0.367,0.279-0.602l-0.055-1.675c0.211-0.097,0.428-0.185,0.646-0.262l1.127,1.245
		c0.159,0.173,0.39,0.261,0.622,0.238c0.384-0.041,0.776-0.039,1.164,0.008c0.232,0.026,0.466-0.058,0.624-0.23l1.144-1.222
		c0.219,0.08,0.435,0.173,0.646,0.272l-0.08,1.675c-0.012,0.234,0.088,0.459,0.268,0.607c0.311,0.251,0.582,0.532,0.814,0.828
		c0.146,0.184,0.369,0.288,0.605,0.282l1.678-0.056c0.096,0.209,0.184,0.424,0.261,0.647l-1.245,1.129
		C32.551,11.622,32.462,11.854,32.486,12.089z"
				/>
			</g>
		</svg>
	);
};

export default ProcessIcon;
