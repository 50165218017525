import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { BsHexagonFill } from 'react-icons/bs';
import { TbBuilding, TbCar, TbSteeringWheel, TbUsers } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { FormHeader } from '../../../../components/FormElements';
import { PageHeader } from '../../../../components/PageHeader';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import { RequesterCompany } from '../../../../services/angular/types/Order';
import AngularNewCompanyRegister from '../../Companies/CreateCompany';
import AddNewDriverForm from '../../Companies/Drivers/components/addNewDriverForm';
import AddVehicle from '../../Companies/Vehicles/components/AddVehicle';

const MakeFreights = () => {
	const navigate = useNavigate();
	const company = useUserState((state) => state.company);

	useQuery(
		['company', company?.id],
		async () =>
			(await api.get<RequesterCompany>(`/companies/${company!.id}`)).data,
		{
			enabled: !!company,
			refetchOnMount: 'always',
			onSuccess: (data) => {
				setStep(
					!data?.vehicles?.length
						? 'Cadastro de veículo'
						: !data?.drivers?.length
						? 'Cadastro de motorista'
						: 'Fazer fretes'
				);
			},
		}
	);

	const [step, setStep] = useState<
		| 'Cadastro de negócio'
		| 'Cadastro de veículo'
		| 'Cadastro de motorista'
		| 'Fazer fretes'
	>('Cadastro de negócio');

	return (
		<div className="flex flex-col items-center">
			<div className="mb-4 flex w-2/3 items-center justify-around">
				<div className="relative flex flex-col items-center text-sm font-bold text-neutral-900">
					<BsHexagonFill
						className={twMerge(
							'h-8 w-8 rotate-90 md:h-10 md:w-10',
							step === 'Cadastro de negócio'
								? 'text-[#e8b21a]'
								: 'text-neutral-500'
						)}
					/>
					<p className="absolute mt-2 text-lg  text-white">1</p>
					Negócio
				</div>

				<span className="mb-2 h-1 w-1/3 rounded bg-neutral-300" />
				<div className="relative flex flex-col items-center text-sm font-bold text-neutral-900">
					<BsHexagonFill
						className={twMerge(
							'h-8 w-8 rotate-90 md:h-10 md:w-10',
							step === 'Cadastro de veículo'
								? 'text-[#e8b21a]'
								: 'text-neutral-500'
						)}
					/>
					<p className="absolute mt-2 text-lg text-white">2</p>
					Veículo
				</div>
				<span className="mb-2 h-1 w-1/3 rounded bg-neutral-300" />
				<div className="relative flex flex-col items-center text-sm font-bold text-neutral-900">
					<BsHexagonFill
						className={twMerge(
							'h-8 w-8 rotate-90 md:h-10 md:w-10',
							step === 'Cadastro de motorista'
								? 'text-[#e8b21a]'
								: 'text-neutral-500'
						)}
					/>
					<p className="absolute mt-2 text-lg text-white">3</p>
					Motorista
				</div>
				<span className="mb-2 h-1 w-1/3 rounded bg-neutral-300" />
				<div className="relative flex flex-col items-center text-sm font-bold text-neutral-900">
					<BsHexagonFill
						className={twMerge(
							'h-8 w-8 rotate-90 md:h-10 md:w-10',
							step === 'Fazer fretes' ? 'text-[#e8b21a]' : 'text-neutral-500'
						)}
					/>
					<p className="absolute mt-2 text-lg text-white">4</p>
					Fretes
				</div>
			</div>
			<div className="w-full">
				{step === 'Cadastro de negócio' && <AngularNewCompanyRegister />}
				{step === 'Cadastro de veículo' && <AddVehicle />}
				{step === 'Cadastro de motorista' && (
					<AddNewDriverForm
						makeDriverRefetch={() => {
							setStep('Fazer fretes');
						}}
					/>
				)}
				{step === 'Fazer fretes' && (
					<div>
						<PageHeader
							title="Cadastros finalizados"
							description="Agora você já poderá realizar fretes"
						/>
						<WhiteContainer>
							<FormHeader title="Verifique e administre o status do seu negócio" />
							<div className="mt-4 flex justify-around text-neutral-900">
								<div
									onClick={() => navigate('/negocios/perfil')}
									className="flex flex-col items-center transition-all hover:text-[#e8b21a]"
								>
									<TbBuilding size={50} />
									Perfíl da empresa
								</div>
								<div
									onClick={() => navigate('/negocios/usuarios')}
									className="flex flex-col items-center transition-all hover:text-[#e8b21a]"
								>
									<TbUsers size={50} />
									Usuários
								</div>
								<div
									onClick={() => navigate('/negocios/veiculos')}
									className="flex flex-col items-center transition-all hover:text-[#e8b21a]"
								>
									<TbCar size={50} />
									Veículos
								</div>
								<div
									onClick={() => navigate('/negocios/motoristas')}
									className="flex flex-col items-center transition-all hover:text-[#e8b21a]"
								>
									<TbSteeringWheel size={50} />
									Motoristas
								</div>
							</div>
						</WhiteContainer>
					</div>
				)}
			</div>
		</div>
	);
};

export default MakeFreights;
