import { useQuery } from '@tanstack/react-query';
import { integrationApi } from '../../angular/axios';
import useUserState from '../../angular/angularUserState';

export const useGetIfoodOrders = () => {
	const companyId = useUserState((state) => state.company?.id);

	const query = useQuery({
		queryKey: ['ifood-orders', companyId],
		queryFn: async ({ signal }) => {
			const { data } = await integrationApi.get<IFoodOrder[]>(
				`/ifood/orders/${companyId || ''}`,
				{
					signal,
				}
			);

			return data || [];
		},
		refetchInterval: 30 * 1000, // 30 seconds
		enabled: !!companyId,
		refetchOnWindowFocus: true,
	});

	return query;
};

export interface IFoodOrder {
	orderId: string;
	companyId: number;
	companyName: string;
	processed: boolean;
	cnpj: string;
	delivery: Delivery;
	orderTiming: string;
	displayId: string;
	merchant: Merchant;
	customer: Customer;
	items: string[];
	paymentsMethods: PaymentsMethod[];
	deliveryStartTime: string | null;
}

interface PaymentsMethod {
	value: number;
	currency: string;
	method: string;
	type: string;
	card?: Card;
	prepaid: boolean;
	cash?: Cash;
}

interface Cash {
	changeFor: number;
}

interface Card {
	brand: string;
}

interface Customer {
	name: string;
	phone: string;
	document: string;
}

interface Merchant {
	id: string;
	name: string;
}

interface Delivery {
	observations: string;
	deliveryAddress: DeliveryAddress;
}

interface DeliveryAddress {
	streetName: string;
	streetNumber: string;
	formattedAddress: string;
	neighborhood: string;
	complement: string;
	postalCode: string;
	city: string;
	state: string;
	country: string;
	reference: string;
	coordinates: Coordinates;
}

interface Coordinates {
	latitude: number;
	longitude: number;
}
