/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-undef */
import dayjs from 'dayjs';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useEffect, useMemo, useRef, useState } from 'react';
import { TbCircleCheck, TbFiles, TbMap2 } from 'react-icons/tb';
import { CgToolbox } from 'react-icons/cg';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import dayJsDuration from 'dayjs/plugin/duration';
import { PageHeader } from '../../../../components/PageHeader';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import useUserState from '../../../../services/angular/angularUserState';
import { api, integrationApi } from '../../../../services/angular/axios';
import { FaShare, FaSyncAlt } from 'react-icons/fa';
import { MarkerF } from '@react-google-maps/api';
import { appState, appStateStore } from '../../../../store/appState';
import { toast } from 'react-toastify';
import { GoogleMap } from '../../../../components/GoogleMap';
import { Loading } from '../../../../components/Loading';
import { OrderResponseApi } from '../../../../services/angular/types/Order';
import Pusher from 'pusher-js';
import { useStore } from 'zustand';
import { SocketResponse } from '../Follow';
import { useAlert } from '../../../../contexts/AlertContext';
import { CancelOrderModal } from '../../../../components/Order/CancelOrderModal';
import { AddIncidentModal } from '../../../../components/AddIncidentModal';
import { useGoogleLoaded } from '../../../../components/GoogleMap/useGoogleLoaded';
import { FreightSummary } from '../../../../components/Order/FreightSummary';
import { Form, Input, useZodForm } from '../../../../components/FormElements';
import { Order } from '../../../../components/Order';
import { StatusTooltip } from '../../../../components/Angular/StatusTooltip';
// @ts-expect-error
import bottle from '../../../../assets/sounds/bottle.wav';
import { ImBlocked } from 'react-icons/im';
import { canCancelOrder } from '../../../../helpers/canCancelOrder';
import { z } from 'zod';
import { canEditStopsGoal } from '../../../../helpers/utils';
import { ToastError } from '../../../../helpers/errors/ToastError';
import { Marker } from 'react-leaflet';
import { LeafletMap } from '../../../../components/Leaflet';
import { customMarkerIcon } from '../../../../components/Leaflet/icons/customIcon';
import { useNewMaps } from '../../../../hooks/useMaps';

dayjs.extend(dayJsDuration);

const updateNFSchema = z.object({
	stopsGoal: z.coerce
		.number()
		.int({
			message: 'O valor deve ser um número inteiro',
		})
		.positive({
			message: 'O valor deve ser maior que 0',
		}),
});

type UpdateNFType = z.infer<typeof updateNFSchema>;

const OrderDetails = () => {
	const alert = useAlert();
	const isLoaded = useGoogleLoaded();
	const navigate = useNavigate();
	const { orderId } = useParams<{ orderId: string }>();
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [cancelModalReason, setCancelModalReason] = useState(false);
	const [addIncidentModal, setAddIncidentModal] = useState(false);
	const token = useUserState((state) => state.token);
	const company = useUserState((state) => state.company);
	const user = useUserState((state) => state.user);
	const pusherRef = useRef<Pusher>();
	const markersRef = useRef<google.maps.Marker[]>([]);
	const { config } = useStore(appStateStore);
	const { toggleMaps } = useNewMaps();

	const form = useZodForm({
		schema: updateNFSchema,
	});

	// Pusher configuration
	useEffect(() => {
		const pusher = new Pusher(config.pusherAppKey, {
			cluster: config.pusherCluster,
			authEndpoint: config.pusherAuth,
			auth: {
				headers: {
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					Authorization: `Bearer ${token}`,
				},
			},
		});

		pusherRef.current = pusher;

		const channel = pusher.subscribe(`private-ORDER-${orderId!}`);

		channel.bind('ORDER_ACCEPTED', async function (data: SocketResponse) {
			void new Audio(bottle).play();
			await refetch();
			alert.onCustom({
				title: 'Frete aceito',
				message: `O frete #${data.notId} foi aceito, deseja acompanhar a entrega?`,
				confirm: {
					label: 'Sim',
					icon: <></>,
					onClick: () => {
						navigate(`/fretes/acompanhar/${data.notId}`);
					},
				},
			});
		});
		channel.bind('ORDER_STARTED', async function (data: SocketResponse) {
			await refetch();
			toast.info(
				<>
					<h1 className="font-semibold">Frete #{data.notId}</h1>
					<p>Frete iniciado</p>
				</>
			);
		});
		channel.bind('ORDER_FINISHED', async function (data: SocketResponse) {
			void refetch();
		});
		channel.bind('ORDER_CANCELED', async function (data: SocketResponse) {
			alert.onCustom({
				title: 'Frete cancelado',
				message: `O frete #${data.notId} foi cancelado`,
				cancel: {
					label: 'Ok',
					icon: <></>,
				},
			});
			void refetch();
		});
		channel.bind('ORDER_TIMEOUT', async function (data: SocketResponse) {
			await refetch();
		});
		channel.bind('ORDER_DRIVER_REMOVED', async function (data: SocketResponse) {
			await refetch();

			toast.info(
				<>
					<h1 className="font-semibold">Frete #{data.notId}</h1>
					<p>O frete será transferido para outro motorista</p>
				</>
			);
		});
		channel.bind('ORDER_REALLOCATING', async function (data: SocketResponse) {
			await refetch();

			toast.info(
				<>
					<h1 className="font-semibold">Frete #{data.notId}</h1>
					<p>O frete será transferido para outro motorista</p>
				</>
			);
		});
		channel.bind('ORDER_PROTOCOL', async function (data: SocketResponse) {
			await refetch();

			toast.info(
				<>
					<h1 className="font-semibold">Frete #{data.notId}</h1>
					<p>Houve uma alteração nos protocolos do frete</p>
				</>
			);
		});
		channel.bind('ORDER_CHECKIN_V2', async function (data: SocketResponse) {
			const { data: updatedOrder } = await refetch();

			const location = updatedOrder?.locations
				.sort((a, b) => a.sequence - b.sequence)
				.reverse()
				.find((location) => !!location.checkinDate);

			if (location)
				toast.info(
					<>
						<h1 className="font-semibold">Frete #{data.notId}</h1>
						<p>Entregador chegou no ponto {location.sequence + 1}</p>
					</>
				);
		});
		channel.bind(
			'ORDER_CHECKOUT_FAILED_V2',
			async function (data: SocketResponse) {
				const { data: updatedOrder } = await refetch();

				const location = updatedOrder?.locations
					.sort((a, b) => a.sequence - b.sequence)
					.reverse()
					.find((location) => !!location?.reason);

				if (location?.reason)
					toast.error(
						<>
							<h1 className="font-semibold">Frete #{data.notId}</h1>
							<p>
								Não foi possível realizar a entrega no ponto{' '}
								{location.sequence + 1}: {location.reason}
							</p>
						</>
					);
			}
		);
		channel.bind('ORDER_CHECKOUT_V2', async function (data: SocketResponse) {
			const { data: updatedOrder } = await refetch();

			const location = updatedOrder?.locations
				.sort((a, b) => a.sequence - b.sequence)
				.reverse()
				.find((location) => !!location.checkoutDate);

			if (location)
				toast.info(
					<>
						<h1 className="font-semibold">Frete #{data.notId}</h1>
						<p>Entrega confirmada no ponto {location.sequence + 1}</p>
					</>
				);
		});
		channel.bind(
			'ORDER_LOCATION_REMOVED',
			async function (data: SocketResponse) {
				await refetch();

				toast.info(
					<>
						<h1 className="font-semibold">Frete #{data.notId}</h1>
						<p>Um local foi removido do frete</p>
					</>
				);
			}
		);
		channel.bind(
			'ORDER_LOCATIONS_REORDERED',
			async function (data: SocketResponse) {
				void refetch();

				toast.info(
					<>
						<h1 className="font-semibold">Frete #{data.notId}</h1>
						<p>O motorista alterou a ordem dos pontos</p>
					</>
				);
			}
		);
		channel.bind('ORDER_TIMEOUT', async function (data: SocketResponse) {
			void refetch();

			toast.info(
				<>
					<h1 className="font-semibold">Frete #{data.notId}</h1>
					<p>O frete foi cancelado por tempo excedido</p>
				</>
			);
		});

		return () => {
			channel.unbind_all();

			pusher.disconnect();
		};
	}, []);

	const {
		data: order,
		isLoading,
		refetch,
	} = useQuery(
		['follow-order', orderId],
		async () => {
			const { data } = await api.get<OrderResponseApi>(`/orders/${orderId}`, {
				params: {
					include:
						'category,status,locations,locations.protocols,locations.receiverType,optionals,requesterCompany,requester,driver,driverCompany.owner,integrationOrder,integrationOrder.integrationCompany,integrationOrder.integrationCompany.companyCalculatorCosts',
					companyId: company?.id,
				},
			});

			return data;
		},
		{
			onError: (error) => {
				navigate(-1);
				ToastError(error);
			},
			onSuccess: (data) => {
				form.reset({
					...data,
				});
			},
		}
	);

	const bounds = useMemo(() => {
		if (!order?.locations || !isLoaded) return;
		const bounds = new google.maps.LatLngBounds();
		order?.locations?.forEach((point) => {
			bounds.extend(new google.maps.LatLng(point.lat, point.lng));
		});
		return bounds;
	}, [order?.locations, isLoaded]);

	const reIndexOrderMutation = useMutation(
		async () => {
			await api.post(`/orders/${orderId}/reindex`);
		},
		{
			onSuccess: () => {
				toast.success('Pedido reindexado com sucesso');
			},
		}
	);

	const updateNF = useMutation(
		async (data: UpdateNFType) => {
			await api.put(`/orders/${orderId}/update-stops-goal`, data);
		},
		{
			onSuccess: () => {
				setDropdownOpen(false);
				toast.success('NF atualizada com sucesso');
				void refetch();
			},
		}
	);

	useEffect(() => {
		if (!order || !markersRef.current.length) return;

		order?.locations
			?.sort((a, b) => a.sequence - b.sequence)
			?.forEach((location, index) => {
				markersRef.current[index].setIcon({
					url:
						location.checkinDate && location.checkoutDate
							? '/markerGreen.png'
							: location.checkinDate && location.reasonDate
							? '/markerRed.png'
							: location.checkinDate
							? '/markerYellow.png'
							: '/markerBlue.png',
				});

				markersRef.current[index].setLabel({
					text: `${location.sequence + 1}`,
					fontWeight: 'bold',
					color: 'black',
					className:
						'absolute top-[38px] left-[50%] transform -translate-x-1/2',
				});

				markersRef.current[index].setPosition({
					lat: location.lat,
					lng: location.lng,
				});
			});
	}, [order]);

	const { mutate, isLoading: mutating } = useMutation({
		mutationFn: async (data: any) =>
			await api.put(`/orders/${data?.id}/submit`, data),
	});

	const { isIfood, isFetching } = useGetIsIfoodOrder(order?.id);

	if (isLoading || !isLoaded)
		return <Loading title="Carregando detalhes do frete" />;

	return (
		<>
			<PageHeader
				title={`Pedido #${orderId}`}
				description={
					order?.integrationOrder?.displayId
						? `Id integração: ${order?.integrationOrder?.displayId}`
						: 'Informações detalhadas do frete'
				}
			>
				<StatusTooltip order={order} />
			</PageHeader>
			<div className="mb-4 flex w-full flex-wrap items-center gap-4">
				{user?.role.id !== Number(appState.userRoles.user.value) && (
					<Button
						className="w-auto"
						variant="primary"
						onClick={() => {
							reIndexOrderMutation.mutate();
						}}
						disabled={reIndexOrderMutation.isSuccess}
					>
						<FaSyncAlt size={16} />
						Reindexar pedido
					</Button>
				)}
				{order?.statusId === appState.orderStatus['Em andamento'].id && (
					<Button
						className="w-auto"
						onClick={() => {
							user?.role.id === Number(appState.userRoles.user.value)
								? navigate(`/fretes/acompanhar/${orderId}`)
								: navigate(`/pedidos/acompanhar/${orderId}`);
						}}
					>
						<TbMap2 size={20} />
						Acompanhar
					</Button>
				)}
				{canEditStopsGoal(order) &&
					order?.requesterCompany.id === company?.id &&
					user?.role.id === Number(appState.userRoles.user.value) && (
						<DropdownMenu.Root
							open={dropdownOpen}
							onOpenChange={setDropdownOpen}
						>
							<DropdownMenu.Trigger className="focus:outline-none">
								<Button className="w-auto">
									<TbFiles size={20} />
									Alterar estimativa de Notas Fiscais
								</Button>
							</DropdownMenu.Trigger>
							<DropdownMenu.Portal>
								<DropdownMenu.Content className="max-h-[80vh] w-fit max-w-xs overflow-y-auto rounded bg-neutral-50 p-6 text-neutral-0 shadow-md shadow-neutral-500">
									<Form
										form={form}
										onSubmit={(data) => {
											updateNF.mutate(data);
										}}
										className="flex flex-col gap-4"
									>
										<Input
											autoFocus
											label="Estimativa de Notas Fiscais"
											type="number"
											{...form.register('stopsGoal')}
											errorMessage={form.formState.errors.stopsGoal?.message}
										/>
										<Button type="submit">Alterar estimativa</Button>
									</Form>
								</DropdownMenu.Content>
							</DropdownMenu.Portal>
						</DropdownMenu.Root>
					)}
				{order?.statusId === appState.orderStatus.Rascunho.id &&
					user?.role.id === Number(appState.userRoles.user.value) &&
					!order?.externalCalculatorUsed &&
					!isIfood && (
						<Button
							className="w-auto"
							onClick={() => {
								navigate(`/fretes/pedir?rascunho=${orderId}`);
							}}
						>
							<FaShare size={20} />
							Atualizar pedido
						</Button>
					)}
				{canCancelOrder(order) &&
					((order?.requesterCompany.id === company?.id &&
						user?.role.id === Number(appState.userRoles.user.value)) ||
						user?.role.id !== Number(appState.userRoles.user.value)) && (
						<Button
							className="w-auto"
							variant="red"
							onClick={() => setCancelModalReason(true)}
						>
							<ImBlocked size={18} />
							Cancelar pedido
						</Button>
					)}
				{(user?.role.id === Number(appState.userRoles.admin.value) ||
					user?.role.id === Number(appState.userRoles.sadmin.value)) &&
					order?.statusId !== appState.orderStatus.Rascunho.id &&
					order?.statusId !==
						appState.orderStatus['Aguardando motorista'].id && (
						<Button
							className="w-auto"
							variant="red"
							onClick={() => {
								setAddIncidentModal(true);
							}}
						>
							<CgToolbox size={20} />
							Finalizar com Incidente
						</Button>
					)}
				{order?.statusId === appState.orderStatus.Rascunho.id &&
					user?.role.id === Number(appState.userRoles.user.value) &&
					!isFetching && (
						<>
							{isIfood ? (
								<IFoodOrder id={order.id} refetch={refetch} />
							) : (
								<Button
									variant="blue-primary"
									className="ml-auto w-auto"
									onClick={() => {
										mutate(order, {
											onSuccess: () => {
												toast.success('Pedido lançado com sucesso');
												void refetch();
											},
											onError: (error) => {
												ToastError(error);
											},
										});
									}}
									loading={mutating}
									disabled={mutating}
								>
									<TbCircleCheck size={20} />
									Lançar pedido
								</Button>
							)}
						</>
					)}
			</div>

			<div className="flex flex-col gap-4">
				<FreightSummary
					order={order}
					isAdmin={user?.role.id !== Number(appState.userRoles.user.value)}
				/>
				<WhiteContainer className="flex gap-4 lg:flex-row">
					<div className="w-full lg:w-1/2">
						<Order.LocationsAccordion header search order={order} />
					</div>

					<div className="sticky top-0 h-[35vh] w-full rounded md:h-[50vh]">
					{
					!toggleMaps?.useLeaflet ? (
						<GoogleMap
						center={bounds}
						legs={order?.routes[0]?.directions?.legs}
						options={{
							mapTypeControl: false,
							streetViewControl: false,
							fullscreenControl: false,
							keyboardShortcuts: false,
							zoomControl: false,
							disableDefaultUI: true,
							clickableIcons: false,
							disableDoubleClickZoom: true,
							panControl: false,
							gestureHandling: "none",
						}}
						>
						{order?.locations.map((item, index) => (
							<MarkerF
							onLoad={(marker) => {
								markersRef.current.push(marker);
							}}
							clickable={false}
							key={index}
							position={{
								lat: item.lat,
								lng: item.lng,
							}}
							label={{
								text: `${item.sequence + 1}`,
								fontWeight: "bold",
								color: "black",
								className:
								"absolute top-[38px] left-[50%] transform -translate-x-1/2",
							}}
							icon={{
								url:
								item.checkinDate && item.checkoutDate
									? "/markerGreen.png"
									: item.checkinDate && item.reasonDate
									? "/markerRed.png"
									: item.checkinDate
									? "/markerYellow.png"
									: "/markerBlue.png",
							}}
							/>
						))}
						</GoogleMap>
					) : (
						<LeafletMap
							center={order?.locations}
							polylineString={order?.routes[0]?.directions?.overview_polyline.points ?? ""}
							dynamicMap={false}
							layers={toggleMaps.layers}
						>
						{order?.locations.map((item) => (
							<Marker
								key={item.id}
								position={[item.lat, item.lng]}
								icon={
								item.checkinDate && item.checkoutDate
									? customMarkerIcon("#00FF00", `${item.sequence + 1}`)
									: item.checkinDate && item.reasonDate
									? customMarkerIcon("#FF0000", `${item.sequence + 1}`)
									: item.checkinDate
									? customMarkerIcon("#FFFF00", `${item.sequence + 1}`)
									: customMarkerIcon("#00FFFF", `${item.sequence + 1}`)
								}
								draggable={false}
							>
							</Marker>
							))}
						</LeafletMap>
					)}

					</div>
				</WhiteContainer>
				<div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
					<Order.ClientDetails order={order} />
					{order?.driver && <Order.DriverDetails order={order} />}
					{order?.vehicle && <Order.VehicleDetails order={order} />}
				</div>
			</div>

			<CancelOrderModal
				open={cancelModalReason}
				setOpen={setCancelModalReason}
				orderId={Number(orderId)}
			/>
			<AddIncidentModal
				onSuccess={() => {
					void refetch();
				}}
				open={addIncidentModal}
				setOpen={setAddIncidentModal}
				orderId={Number(orderId)}
			/>
		</>
	);
};

export default OrderDetails;

const IFoodOrder = ({
	id,
	refetch,
}: {
	id: number;
	refetch: () => Promise<any>;
}) => {
	const { mutate, isLoading } = useMutation({
		mutationFn: async (id: number | string) =>
			await integrationApi.put(`/ifood/orders/submit/${id}`),
	});

	return (
		<Button
			variant="blue-primary"
			className="ml-auto w-auto"
			onClick={() => {
				mutate(id, {
					onSuccess: () => {
						toast.success('Pedido lançado com sucesso');
						void refetch();
					},
					onError: (error) => {
						ToastError(error);
					},
				});
			}}
			loading={isLoading}
			disabled={isLoading}
		>
			<TbCircleCheck size={20} />
			Lançar pedido ifood
		</Button>
	);
};

const useGetIsIfoodOrder = (id: number | undefined) => {
	const { data, ...rest } = useQuery({
		queryKey: ['ifood-orders', id],
		queryFn: async () =>
			await integrationApi.get<IsIfoodResponse>(`/ifood/orders/validate/${id}`),
		enabled: !!id,
		retry: false,
	});

	return {
		isIfood: data?.data?.integrationIfood,
		...rest,
	};
};

interface IsIfoodResponse {
	message: string;
	integrationIfood: boolean;
}
