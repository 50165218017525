import React, { ChangeEvent } from 'react';
import { Cropper, CropperRef } from 'react-advanced-cropper';
import { BsFillEyeFill, BsImage } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Root, Fallback, Image as RadixImage } from '@radix-ui/react-avatar';
import { Modal } from '../../../../../components/Modal';
import Button from '../../../../../components/Button/Button';
import { api } from '../../../../../services/angular/axios';
import { validateFileSize } from '../../../../../helpers/validateFileSize';

interface FileInputProps {
	onImageLoad?: (imageUrl: string) => void;
}

interface Image {
	type?: string;
	src: string;
}

export const FileInput: React.FC<FileInputProps> = ({ onImageLoad }) => {
	const cropperRef = React.useRef<CropperRef>(null);
	const [image, setImage] = React.useState<Image>();
	const [open, setOpen] = React.useState<boolean>(false);
	const [previewOpen, setPreviewOpen] = React.useState<boolean>(false);
	const [cropped, setCropped] = React.useState<string | undefined>();

	const onLoadImage = (event: ChangeEvent<HTMLInputElement>): void => {
		const { files } = event.target;

		if (files?.[0]) {
			if (!files[0].type.match('image.*')) {
				toast.error('Arquivo inválido, selecione uma imagem');
				event.target.value = '';
				return;
			}
			const blob = URL.createObjectURL(files[0]);

			setImage({ src: blob, type: files[0].type });
			setOpen(true);
		}
		event.target.value = '';
	};

	const onCrop = () => {
		if (cropperRef.current) {
			cropperRef.current.getCanvas()?.toBlob(
				(blob) => {
					if (blob && !validateFileSize(blob)) {
						return;
					}

					const formData = new FormData();
					formData.append('image', blob as Blob);

					void handleUpdate(formData);
					setOpen(false);
				},
				'image/jpeg',
				0.95
			);
		}
	};

	const handleUpdate = async (file: any) => {
		try {
			const response = await api.post('/assets', file, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			if (response?.data?.imageName) {
				const croppedImage = await api.get(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`/assets?imageName=${response.data.imageName}`
				);
				setCropped(croppedImage.data.url);

				onImageLoad?.(response.data.imageName);
			}
		} catch (error) {
			toast.error('Erro ao atualizar imagem');
		}
	};

	return (
		<>
			<div className="flex gap-1">
				<label className="flex cursor-pointer gap-2 rounded bg-neutral-50 p-1 text-neutral-800 transition-all hover:text-neutral-0">
					<BsImage size={20} />
					<input
						onChange={onLoadImage}
						type="file"
						accept="image/*"
						className="hidden"
					/>
				</label>
				{!!cropped && (
					<button
						onClick={() => setPreviewOpen(true)}
						className="flex cursor-pointer gap-2 rounded bg-neutral-50 p-1 text-neutral-800 transition-all hover:text-neutral-0"
					>
						<BsFillEyeFill size={20} />
					</button>
				)}
			</div>

			<Modal
				className="max-h-[95vh] overflow-hidden"
				title="Visualizar imagem"
				open={previewOpen}
				setOpen={setPreviewOpen}
			>
				<div className="flex items-center justify-center ">
					<Root className="inline-flex h-full w-full select-none items-center justify-center overflow-hidden  bg-neutral-100 align-middle focus:outline-none">
						<RadixImage src={cropped} className="h-full w-full object-cover" />
						<Fallback className="flex h-full w-full items-center justify-center bg-neutral-0 font-bold text-yellow-500"></Fallback>
					</Root>
				</div>
			</Modal>
			<Modal title="Cortar imagem" open={open} setOpen={setOpen}>
				<div className="flex max-h-[65vh] flex-col align-bottom">
					<Cropper
						ref={cropperRef}
						src={image?.src}
						stencilProps={{
							grid: true,
						}}
					/>
					<Button
						className="mt-4 w-auto self-end"
						variant="blue-primary"
						onClick={onCrop}
					>
						Salvar
					</Button>
				</div>
			</Modal>
		</>
	);
};
