import { match } from 'ts-pattern';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Avatar } from '../../../components/Avatar';
import { RegisterButton } from '../../../components/Button/RegisterButton';
import { PageHeader } from '../../../components/PageHeader';
import WhiteContainer from '../../../components/WhiteContainer/WhiteContainer';
import useUserState from '../../../services/angular/angularUserState';
import { api } from '../../../services/angular/axios';
import { CloseModal, Modal } from '../../../components/Modal';
import Button from '../../../components/Button/Button';

const AngularCompaniesManager: React.FC = () => {
	const navigate = useNavigate();
	const { companies, company, setCompany, user } = useUserState();
	const [open, setOpen] = useState(false);

	const { data, isLoading, isFetching } = useQuery(
		['companies-avatar', companies.map((c) => c.avatar)],
		async () =>
			await Promise.all(
				companies.map(async (c) => {
					if (!c.avatar) return;

					const { data } = await api.get('/assets', {
						params: {
							imageName: c.avatar,
						},
					});

					const imageUrl: string = data?.url;

					return imageUrl;
				})
			)
	);

	const { data: userAvatar } = useQuery(
		['user-avatar', user?.avatar],
		async () => {
			if (!user?.avatar) return;

			return await api.get('/assets', {
				params: {
					imageName: user.avatar,
				},
			});
		},
		{
			enabled: !!user?.avatar,
		}
	);

	useEffect(() => {
		if (!companies.length) setOpen(true);
	}, []);

	return (
		<div>
			<PageHeader
				title="Gerenciador de Negócios"
				description="Aqui você pode selecionar ou cadastrar empresas
				 pelas quais você deseja usar a BeeBee"
			>
				<RegisterButton
					className="w-auto"
					label="Cadastrar novo negócio"
					onClick={() => navigate('/negocios/novo')}
				/>
			</PageHeader>

			<div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
				{companies.map((c, index) => {
					return (
						<WhiteContainer
							key={`${c.id}-${c.type}`}
							className={twMerge(
								'flex h-full flex-row gap-4 rounded-md border-r-8 shadow-md md:px-4',
								'cursor-pointer transition-all hover:bg-yellow-200',
								company?.id === c.id &&
									company.type === c.type &&
									'bg-yellow-200',
								match(c)
									.with({ type: 'Empresa' }, () => 'border-empresa')
									.with({ type: 'Fretista' }, () => 'border-fretista')
									.with({ type: 'Motorista' }, () => 'border-neutral-700')
									.with({ type: 'Usuário' }, () => 'border-primary-0')
									.with({ type: 'Usuário Admin' }, (c) =>
										c.makeFreights ? 'border-fretista' : 'border-empresa'
									)
									.otherwise(() => 'border-neutral-900')
							)}
							onClick={() => {
								setCompany(c);
								(c.type === 'Empresa' ||
									c.type === 'Fretista' ||
									c.type === 'Usuário Admin' ||
									c.type === 'Usuário') &&
								c.requestFreights
									? navigate('/fretes/pedir')
									: navigate('/fretes/em-andamento');
							}}
						>
							<span className="h-20 w-20 self-center">
								<Avatar
									avatarProps={{ className: 'w-20 h-20' }}
									fallbackProps={{ className: 'text-3xl' }}
									name={c.tradingName || c.companyName}
									src={data?.[index]}
									isLoading={isLoading || isFetching}
								/>
							</span>

							<div className="relative w-full truncate">
								<h1 className="truncate font-bold">{c.tradingName}</h1>
								<h1 className="truncate text-sm">{c.companyName}</h1>
								<h1 className="text-sm font-semibold">CNPJ: {c.cnpj}</h1>

								<div className="mt-1 flex gap-2 text-center text-sm font-semibold text-white">
									{match(c)
										.with({ type: 'Empresa' }, () => (
											<div className="rounded bg-empresa px-1 py-px">
												Empresa
											</div>
										))
										.with({ type: 'Fretista' }, () => (
											<div className="rounded bg-fretista px-1 py-px">
												Fretista
											</div>
										))
										.with({ type: 'Motorista' }, () => (
											<div className="rounded bg-neutral-700 px-1 py-px">
												Motorista
											</div>
										))
										.with({ type: 'Usuário' }, () => (
											<div className="rounded bg-primary-0 px-1 py-px">
												Usuário
											</div>
										))
										.with({ type: 'Usuário Admin' }, (c) => (
											<div
												className={twMerge(
													'rounded px-1 py-px',
													c.makeFreights ? 'bg-fretista' : 'bg-empresa'
												)}
											>
												Usuário Admin
											</div>
										))
										.otherwise(() => (
											<div className="rounded bg-neutral-900 px-1 py-px">
												Acesso Pessoal
											</div>
										))}
								</div>
							</div>
						</WhiteContainer>
					);
				})}
				{companies.length === 0 && (
					<WhiteContainer
						className={twMerge(
							'flex h-full flex-row gap-4 rounded-md border-r-8 border-neutral-900 shadow-md md:px-4',
							'cursor-pointer bg-yellow-200 hover:bg-yellow-200'
						)}
					>
						<span className="h-20 w-20 self-center">
							<Avatar
								avatarProps={{ className: 'w-20 h-20' }}
								fallbackProps={{ className: 'text-3xl' }}
								name={user?.name}
								src={userAvatar?.data.url}
							/>
						</span>

						<div className="w-full truncate">
							<h1 className="truncate font-bold">{user?.name}</h1>
							<h1 className="text-sm font-semibold">CPF: {user?.cpf}</h1>

							<div className="mt-6 flex justify-end gap-2 text-center text-xs font-semibold text-white">
								<div className="rounded bg-neutral-0 px-1 py-px">
									Acesso pessoal
								</div>
							</div>
						</div>
					</WhiteContainer>
				)}
			</div>

			<Modal
				open={open}
				setOpen={setOpen}
				title={`Olá, ${user?.name || 'cliente'}`}
				className="max-w-md"
				titleClassName="flex flex-col items-center justify-center"
			>
				<div className="flex flex-col items-center justify-center gap-4">
					<h1 className="text-center text-xl font-bold text-neutral-0">
						Verificamos que você ainda não concluiu o cadastro da sua empresa.
						Deseja fazer isso agora?
					</h1>
					<div className="grid grid-cols-2 gap-4">
						<Button
							className="w-auto"
							variant="blue-primary"
							onClick={() => {
								navigate('/negocios/novo');
							}}
						>
							Cadastrar empresa
						</Button>
						<CloseModal>
							<Button className="w-auto">Depois</Button>
						</CloseModal>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default AngularCompaniesManager;
