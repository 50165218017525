/* eslint-disable no-undef */
import {
	GoogleMap as GoogleMapF,
	PolylineF,
	GoogleMapProps,
} from '@react-google-maps/api';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Leg } from '../../services/angular/types/Order';
import { useGoogleLoaded } from './useGoogleLoaded';

const containerStyle = {
	width: '100%',
	height: '100%',
};

interface MyGoogleMapProps extends Omit<GoogleMapProps, 'center' | 'onLoad'> {
	children?: React.ReactNode;
	legs?: Leg[];
	disableCenter?: boolean;
	center?:
		| google.maps.LatLng
		| google.maps.LatLngLiteral
		| google.maps.LatLngBounds;
	onMapLoad?: (map: google.maps.Map) => void;
}

export const GoogleMap = ({
	children,
	legs,
	options,
	disableCenter = false,
	center,
	onMapLoad,
	...rest
}: MyGoogleMapProps) => {
	const isLoaded = useGoogleLoaded();
	const mapRef = useRef<google.maps.Map>();

	const isFirstRender = useRef(true);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}
		centerMap();
	}, [center]);

	const onLoad = useCallback((map: google.maps.Map) => {
		mapRef.current = map;

		onMapLoad?.(map);

		!disableCenter && centerMap();
	}, []);

	const centerMap = () => {
		if (center) {
			if (center instanceof google.maps.LatLngBounds) {
				mapRef.current?.fitBounds(center);
			} else {
				mapRef.current?.setCenter(center);
				mapRef.current?.setZoom(16);
			}
		} else
			navigator.geolocation.getCurrentPosition(
				(position) => {
					mapRef.current?.setCenter({
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					});
				},
				() => {
					const brazilBounds = [
						-73.9872354804, -33.7683777809, -34.7299934555, 5.24448639569,
					];
					mapRef.current?.fitBounds(
						new google.maps.LatLngBounds(
							new google.maps.LatLng(brazilBounds[1], brazilBounds[0]),
							new google.maps.LatLng(brazilBounds[3], brazilBounds[2])
						)
					);
				}
			);
	};

	const drawDirectionalPath = useMemo(() => {
		if (!legs) return {};

		let legPath: google.maps.LatLng[] = [];

		legs.forEach((leg) => {
			leg?.steps?.forEach((step) => {
				if (step?.polyline?.points)
					legPath = [
						...legPath,
						...google.maps.geometry.encoding.decodePath(step.polyline.points),
					];
			});
		});
		const arrows = {
			repeat: '25px',
			icon: {
				path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
				scale: 2.1,
				fillColor: '#3776b9',
				fillOpacity: 1,
				strokeColor: '#3776b9',
				strokeWeight: 1,
				strokeOpacity: 1,
			},
		};

		return {
			path: legPath,
			geodesic: true,
			strokeColor: '#67aff7',
			strokeOpacity: 0.8,
			strokeWeight: 6,
			icons: [arrows],
		};
	}, [legs]);

	return (
		<>
			{isLoaded && (
				<GoogleMapF
					onLoad={onLoad}
					mapContainerStyle={containerStyle}
					zoom={12}
					options={{
						streetViewControl: false,
						disableDefaultUI: true,
						mapTypeControl: false,
						styles: [
							// https://developers.google.com/maps/documentation/javascript/style-reference?hl=pt-br#style-features
							{
								featureType: 'poi',
								elementType: 'labels',
								stylers: [{ visibility: 'off' }],
							},
							{
								featureType: 'transit',
								elementType: 'labels',
								stylers: [{ visibility: 'off' }],
							},
							{
								featureType: 'landscape',
								elementType: 'labels',
								stylers: [{ visibility: 'off' }],
							},
						],
						...options,
					}}
					{...rest}
				>
					{children}
					{legs && (
						<PolylineF
							options={{
								clickable: false,
								...drawDirectionalPath,
							}}
						/>
					)}
				</GoogleMapF>
			)}
		</>
	);
};
