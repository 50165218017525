// @ts-nocheck
import React from 'react';
import { ReactComponent as BikerBag } from './bikerBag.svg';
import { ReactComponent as Car } from './car.svg';
import { ReactComponent as Motorcycle } from './motorcycle.svg';
import { ReactComponent as MotorcycleTrunk } from './motorcycleTrunk.svg';
import { ReactComponent as PickupTruck } from './pickupTruck.svg';
import { ReactComponent as PickupTruckOpened } from './pickupTruckOpened.svg';
import { ReactComponent as Truck } from './truck.svg';
import { ReactComponent as TruckOpened } from './truckOpened.svg';
import { ReactComponent as Van } from './van.svg';
import { ReactComponent as VanOpened } from './vanOpened.svg';
import { ReactComponent as Bike } from './bike.svg';
import { ObjectValues } from '../../../helpers/types';

export interface Icon {
	id: number;
	svgReference: VehiclesNames;
	apiReqIcon: string;
	label: string;
	subIcon: {
		svgReference: VehiclesNames;
		apiReqIcon: string;
		label: string;
	};
}
export const oldIcons = {
	'icon-motorcycle': 'motorcycleTrunk',
	'icon-motorcycle-2': 'motorcycle',
	'icon-car': 'car',
	'icon-truck-2': 'truck',
	'icon-mini-van-1': 'pickupTruckOpened',
	'icon-mini-van-2': 'pickupTruck',
	'icon-van-1': 'vanOpened',
	'icon-van-2': 'van',
	'icon-bike': 'bike',
} as const;

export const mainIcons = {
	'icon-motorcycle': {
		icon: 'motorcycleTrunk',
		label: 'Moto',
		sub: { 'icon-motorcycle-2': 'motorcycle' },
	},
	'icon-car': {
		icon: 'car',
		label: 'Carro',
	},
	'icon-truck-2': {
		icon: 'truck',
		label: 'Caminhão',
	},
	'icon-van-2': {
		icon: 'van',
		label: 'Van Fechada',
		sub: { 'icon-van-1': 'vanOpened' },
	},
	'icon-mini-van-2': {
		icon: 'pickupTruck',
		label: 'Mini-Van Fechada',
		sub: { 'icon-mini-van-1': 'pickupTruckOpened' },
	},
	'icon-bike': {
		icon: 'bike',
		label: 'Bicicleta',
	},
} as const;

export const icons: Icon[] = [
	{
		id: 1,
		svgReference: 'motorcycleTrunk',
		apiReqIcon: 'icon-motorcycle',
		label: 'Moto',
		subIcon: {
			svgReference: 'motorcycle',
			apiReqIcon: 'icon-motorcycle-2',
			label: 'Mochila Térmica',
		},
	},
	{
		id: 2,
		svgReference: 'car',
		apiReqIcon: 'icon-car',
		label: 'Carro',
	},
	{
		id: 3,
		svgReference: 'truck',
		apiReqIcon: 'icon-truck-2',
		label: 'Caminhão',
	},
	{
		id: 4,
		svgReference: 'van',
		apiReqIcon: 'icon-van-2',
		label: 'Van Fechada',
		subIcon: {
			svgReference: 'vanOpened',
			apiReqIcon: 'icon-van-1',
			label: 'Van Aberta',
		},
	},
	{
		id: 5,
		svgReference: 'pickupTruck',
		apiReqIcon: 'icon-mini-van-2',
		label: 'Mini-Van',
		subIcon: {
			svgReference: 'pickupTruckOpened',
			apiReqIcon: 'icon-mini-van-1',
			label: 'Mini-Van Aberta',
		},
	},
	{
		id: 6,
		svgReference: 'bike',
		apiReqIcon: 'icon-bike',
		label: 'Bicicleta',
	},
] as const;

export type VehiclesNames = ObjectValues<typeof oldIcons>;

export const VehicleIcon: React.FC<{
	name: VehiclesNames | 'bikerBag';
	size?: number;
}> = ({ name, size = 64 }) => {
	const icons = {
		bikerBag: <BikerBag width={size} height={size} />,
		car: <Car width={size} height={size} />,
		motorcycle: <Motorcycle width={size} height={size} />,
		motorcycleTrunk: <MotorcycleTrunk width={size} height={size} />,
		pickupTruck: <PickupTruck width={size} height={size} />,
		pickupTruckOpened: <PickupTruckOpened width={size} height={size} />,
		truck: <Truck width={size} height={size} />,
		truckOpened: <TruckOpened width={size} height={size} />,
		van: <Van width={size} height={size} />,
		vanOpened: <VanOpened width={size} height={size} />,
		bike: <Bike width={size} height={size} />,
	};

	return icons[name];
};
