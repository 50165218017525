import { z } from 'zod';
import { dateFilterSchema } from '../../../../../helpers/dateFilterSchema';

export const FilterSchema = dateFilterSchema.extend({
	operation: z
		.object({
			value: z.string(),
			label: z.string().optional(),
		})
		.nullable()
		.optional()
		.transform((value) => value?.value),
	orderTypeId: z
		.object({
			value: z.string(),
			label: z.string().optional(),
		})
		.nullable()
		.optional()
		.transform((value) => value?.value),
	statusId: z
		.array(
			z.object({
				value: z.string(),
				label: z.string().optional(),
			})
		)
		.nullable()
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
	query: z.string().optional(),
});

export type FilterSchemaType = z.infer<typeof FilterSchema>;
