import React from 'react';
import {
	Root,
	Fallback,
	Image,
	AvatarProps,
	AvatarImageProps,
	AvatarFallbackProps,
} from '@radix-ui/react-avatar';
import Bee from '../../assets/svg/Bee';
import { twMerge } from 'tailwind-merge';
import { FaSpinner } from 'react-icons/fa';

interface Props {
	avatarProps?: AvatarProps;
	imageProps?: AvatarImageProps;
	fallbackProps?: AvatarFallbackProps;
	src?: string;
	name?: string;
	isLoading?: boolean;
}

export const Avatar: React.FC<Props> = ({
	avatarProps,
	fallbackProps,
	imageProps,
	src,
	name,
	isLoading,
}) => {
	return (
		<Root
			{...avatarProps}
			className={twMerge(
				'inline-flex h-10 w-10 select-none items-center justify-center overflow-hidden rounded-full bg-neutral-100 align-middle focus:outline-none',
				avatarProps?.className
			)}
		>
			<Image
				src={src}
				{...imageProps}
				className={twMerge('h-full w-full object-cover', imageProps?.className)}
			/>
			<Fallback
				{...fallbackProps}
				className={twMerge(
					'flex h-full w-full items-center justify-center bg-neutral-400 font-bold text-white',
					fallbackProps?.className
				)}
			>
				<>
					{isLoading ? (
						<span className="flex h-[85%] max-w-[85%] items-center justify-center">
							<FaSpinner className="animate-slow-spin" size="60%" />
						</span>
					) : name ? (
						name
							.split(' ')
							.map((word) => word[0])
							.join('')
							.slice(0, 2)
							.toUpperCase()
					) : (
						<span className="p-2">
							<Bee />
						</span>
					)}
				</>
			</Fallback>
		</Root>
	);
};
