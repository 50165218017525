import { TbSteeringWheel, TbUser } from 'react-icons/tb';

interface TotalsComponentProps {
	driverSubTotal: number;
	total: number;
}

const TotalsComponent = ({ driverSubTotal, total }: TotalsComponentProps) => {
	return (
		<div className="flex flex-col gap-1">
			<div className="flex items-center gap-1">
				<TbUser className="text-neutral-800" size={20} />
				<p className="text-sm font-medium text-neutral-0">
					{total.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					})}
				</p>
			</div>
			<div className="flex items-center gap-1">
				<TbSteeringWheel className="text-neutral-800" size={20} />
				<p className="text-sm font-medium text-neutral-0">
					{driverSubTotal.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					})}
				</p>
			</div>
		</div>
	);
};

export default TotalsComponent;
