import dayjs from 'dayjs';
import dayJsDuration from 'dayjs/plugin/duration';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { TbClock, TbTruckDelivery, TbWallet } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';
import {
	RequestFreightForm,
	updateRouteAtom,
} from '../../../pages/Angular/Freights/Request';
import { CostDropdown } from '../../../pages/Angular/Freights/Request/components/PathStep/CostDropdown';
import useUserState from '../../../services/angular/angularUserState';
import { OrderResponseApi } from '../../../services/angular/types/Order';
import { OrderType, OrderTypeIcon } from '../../Icon/OrderTypeIcon';
import { oldIcons, VehicleIcon } from '../../Icon/vehicles';
import { Tooltip } from '../../Tooltip';
import WhiteContainer from '../../WhiteContainer/WhiteContainer';

dayjs.extend(dayJsDuration);

interface FreightSummaryProps {
	order?: OrderResponseApi;
	form?: RequestFreightForm;
	isAdmin?: boolean;
}

export const FreightSummary = ({
	order,
	form,
	isAdmin,
}: FreightSummaryProps) => {
	const [updateDraft, triggerUpdateDraft] = useAtom(updateRouteAtom);
	const company = useUserState((state) => state.company);

	const _distance = useMemo(() => {
		if (!order?.distance) return '0 Km';
		return `${order?.distance > 1 ? Math.round(order?.distance) : 1} Km`;
	}, [order?.distance]);

	const _duration = useMemo(() => {
		if (!order?.duration) return dayjs.duration(0, 'seconds');
		return dayjs.duration(order?.duration, 'seconds');
	}, [order?.duration]);

	const time = useMemo(() => {
		const hours = _duration.hours();
		const minutes = _duration.minutes();
		const time = `${hours ? `${hours}h` : ''}${minutes ? `${minutes}min` : ''}`;

		return time;
	}, [_duration]);

	return (
		<WhiteContainer
			className={`flex flex-row flex-wrap justify-evenly gap-x-8 gap-y-4 ${
				order?.scheduleDate ? 'bg-blue/20' : ''
			}`}
		>
			<div className="flex w-fit items-center gap-2">
				{company?.hasFavoriteDrivers && form ? (
					<Tooltip
						message={
							form.watch('searchOnlyFavoriteDrivers')
								? 'Frete exibido apenas para motoristas favoritos'
								: 'Frete exibido para todos motoristas'
						}
						triggerClassName={twMerge(
							'cursor-pointer self-start',
							form.watch('searchOnlyFavoriteDrivers')
								? 'text-yellow-submenu'
								: 'text-neutral-200'
						)}
						onClick={() => {
							form.setValue(
								'searchOnlyFavoriteDrivers',
								!form.watch('searchOnlyFavoriteDrivers')
							);

							triggerUpdateDraft(!updateDraft);
						}}
					>
						<AiFillStar size={22} />
					</Tooltip>
				) : order?.searchOnlyFavoriteDrivers ? (
					<Tooltip
						message="Frete exibido apenas para motoristas favoritos"
						triggerClassName="self-start text-yellow-submenu"
					>
						<AiFillStar size={22} />
					</Tooltip>
				) : null}
				<VehicleIcon
					/* @ts-expect-error */
					name={oldIcons[order?.category.icon]}
					size={50}
				/>
				<div className="flex flex-col">
					<span className="text-lg font-medium text-neutral-0">
						{order?.category.name}
					</span>
				</div>
			</div>
			<div className="flex w-fit items-center gap-2">
				{order?.scheduleDate ? (
					<TbClock size={36} />
				) : (
					<TbTruckDelivery size={36} />
				)}
				<div className="flex flex-col">
					<span className="font-medium text-neutral-0">
						{order?.scheduleDate ? 'Agendado' : 'Imediato'}
					</span>
					<span className="text-sm text-neutral-500">
						{order?.scheduleDate
							? dayjs(order?.scheduleDate).format('DD/MM/YYYY HH:mm')
							: order?.orderDate
							? dayjs(order?.orderDate).format('DD/MM/YYYY HH:mm')
							: 'Começa imediatamente'}
					</span>
				</div>
			</div>
			<div className="flex w-fit items-center gap-2">
				<OrderTypeIcon
					size={48}
					type={String(order?.orderTypeId) as OrderType}
				/>
				<div className="flex w-full flex-col justify-between gap-1">
					<div className="flex gap-1 self-start">
						<span className="font-medium text-neutral-0">
							{String(order?.type).toUpperCase()}
						</span>
						{Boolean(order?.invoiceLimit) && (
							<p
								className={`${
									order?.totalInvoicesRegistered === order?.invoiceLimit
										? 'text-green'
										: 'text-orange'
								}`}
							>
								{`${order?.totalInvoicesRegistered || ''}/${
									order?.invoiceLimit || ''
								}`}
							</p>
						)}
					</div>
					<div className="flex gap-1 text-sm text-neutral-600">
						{_distance}
						{' - '}
						{time}
					</div>
				</div>
			</div>
			<div className="flex w-fit items-center gap-2">
				<TbWallet size={40} />
				<div className="flex flex-col gap-1">
					<span className="flex justify-between gap-2 font-semibold">
						<span
							className={twMerge(
								order?.statusId === 7 && 'text-terracota-500 line-through'
							)}
						>
							{Number(order?.total).toLocaleString('pt-BR', {
								style: 'currency',
								currency: 'BRL',
							})}
						</span>
						{Number(order?.requesterMultiplier) > 1 && (
							<span className="self-start rounded-full bg-orange px-2 py-px text-sm font-medium text-white">
								{order?.requesterMultiplier}X
							</span>
						)}
						{order && <CostDropdown order={order} isAdmin={isAdmin} />}
					</span>
					<span className="text-sm">Valor do frete</span>
				</div>
			</div>
		</WhiteContainer>
	);
};
