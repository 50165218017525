import React from 'react';
import { Icons as IconsList, IconsNames } from './IconInterfaces';

interface IconProps extends React.HTMLAttributes<HTMLDivElement> {
	name: IconsNames;
	size: number;
	color?: string;
	stroke?: string;
	className?: string;
}

export const Icon: React.FC<IconProps> = ({
	name,
	size,
	stroke = '3.33333',
	className,
	...props
}) => {
	const iconName = name
		.split('-')
		.map((item) => {
			return item.charAt(0).toUpperCase() + item.slice(1);
		})
		.join('');

	return (
		<div className={className} style={{ width: size, height: size }} {...props}>
			{Object.keys(IconsList).map((key) => {
				return (
					name === key &&
					React.createElement(IconsList[key], {
						stroke,
						key: `${iconName}Icon`,
					})
				);
			})}
		</div>
	);
};
