import { useQuery } from '@tanstack/react-query';
import { integrationApi } from '../../angular/axios';
import useUserState from '../../angular/angularUserState';

export const useGetMerchant = () => {
    const companyId = useUserState((state) => state?.company?.id);

    return useQuery<Merchant[]>({
        queryKey: ['ifood-merchant', companyId],
        queryFn: async () => {
            const { data } = await integrationApi.get<Merchant[]>(
                `/ifood/merchant/${companyId || ''}`
            );
            return data;
        },
        enabled: !!companyId,
        retry: false,
    });
};

export interface Merchant {
    merchantId: string;
    companyId: number;
    activate: boolean;
    automaticOrders: boolean;
    preparationTime: number;
	name: string;
}