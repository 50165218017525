import { z } from 'zod';

export const VehicleInviteRegisterSchema = z
	.object({
		id: z.number().optional(),
		companyId: z.number().optional(),
		categoryId: z.number({
			required_error: 'Categoria é obrigatória',
			invalid_type_error: 'Erro ao selecionar categoria',
		}),
		featureOneActive: z.boolean().default(false),
		featureTwoActive: z.boolean().default(false),
		plate: z.string().min(1, 'Placa é obrigatória').min(7, 'Placa inválida'),
		front: z.string({
			required_error: 'Foto frontal é obrigatória',
			invalid_type_error: 'Erro com a foto frontal',
		}),
		left: z.string({
			required_error: 'Foto lateral é obrigatória',
			invalid_type_error: 'Erro com a foto lateral',
		}),
		rear: z.string({
			required_error: 'Foto traseira é obrigatória',
			invalid_type_error: 'Erro com a foto lateral',
		}),
		crv: z.string({
			required_error: 'Foto do documento é obrigatória',
			invalid_type_error: 'Erro com a foto do documento',
		}),
		frontFile: z.any(),
		leftFile: z.any(),
		rearFile: z.any(),
		crvFile: z.any(),
	})
	.transform(({ frontFile, leftFile, rearFile, crvFile, ...data }) => data);

export type VehicleInviteRegisterSchemaType = z.infer<
	typeof VehicleInviteRegisterSchema
>;
