export const STATES = [
	{
		value: '1',
		label: 'AC',
		name: 'Acre',
	},
	{
		value: '2',
		label: 'AL',
		name: 'Alagoas',
	},
	{
		value: '3',
		label: 'AM',
		name: 'Amazonas',
	},
	{
		value: '4',
		label: 'AP',
		name: 'Amapá',
	},
	{
		value: '5',
		label: 'BA',
		name: 'Bahia',
	},
	{
		value: '6',
		label: 'CE',
		name: 'Ceará',
	},
	{
		value: '7',
		label: 'DF',
		name: 'Distrito Federal',
	},
	{
		value: '8',
		label: 'ES',
		name: 'Espírito Santo',
	},
	{
		value: '9',
		label: 'GO',
		name: 'Goiás',
	},
	{
		value: '10',
		label: 'MA',
		name: 'Maranhão',
	},
	{
		value: '11',
		label: 'MG',
		name: 'Minas Gerais',
	},
	{
		value: '12',
		label: 'MS',
		name: 'Mato Grosso do Sul',
	},
	{
		value: '13',
		label: 'MT',
		name: 'Mato Grosso',
	},
	{
		value: '14',
		label: 'PA',
		name: 'Pará',
	},
	{
		value: '15',
		label: 'PB',
		name: 'Paraíba',
	},
	{
		value: '16',
		label: 'PE',
		name: 'Pernambuco',
	},
	{
		value: '17',
		label: 'PI',
		name: 'Piauí',
	},
	{
		value: '18',
		label: 'PR',
		name: 'Paraná',
	},
	{
		value: '19',
		label: 'RJ',
		name: 'Rio de Janeiro',
	},
	{
		value: '20',
		label: 'RN',
		name: 'Rio Grande do Norte',
	},
	{
		value: '21',
		label: 'RO',
		name: 'Rondônia',
	},
	{
		value: '22',
		label: 'RR',
		name: 'Roraima',
	},
	{
		value: '23',
		label: 'RS',
		name: 'Rio Grande do Sul',
	},
	{
		value: '24',
		label: 'SC',
		name: 'Santa Catarina',
	},
	{
		value: '25',
		label: 'SE',
		name: 'Sergipe',
	},
	{
		value: '26',
		label: 'SP',
		name: 'São Paulo',
	},
	{
		value: '27',
		label: 'TO',
		name: 'Tocantins',
	},
];
