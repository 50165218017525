import { z } from 'zod';
import { onlyDigits } from '../../../../../../helpers/format/onlyDigits';

export const TransactionsAdjustmentsSchema = z.object({
	addValue: z
		.object(
			{
				label: z.string(),
				value: z.boolean(),
			},
			{
				required_error: 'Campo obrigatório',
				invalid_type_error: 'Campo obrigatório',
			}
		)
		.transform((value) => value?.value),
	companyId: z
		.object(
			{
				label: z.string(),
				value: z.number(),
			},
			{
				required_error: 'Campo obrigatório',
				invalid_type_error: 'Campo obrigatório',
			}
		)
		.transform((value) => value?.value),
	observation: z.string().min(1, {
		message: 'Campo obrigatório',
	}),
	value: z
		.string()
		.min(1, {
			message: 'Campo obrigatório',
		})
		.transform((value) => Number(onlyDigits(value)) / 100),
});

export type TransactionsAdjustmentsSchemaType = z.infer<
	typeof TransactionsAdjustmentsSchema
>;
