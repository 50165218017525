export const zodMessage = {
	required: 'Obrigatório',
	email: 'E-mail inválido',
	cnpj: 'CNPJ inválido',
	cpf: 'CPF inválido',
	cep: 'CEP inválido',
	phone: 'Telefone inválido',
	passwordMatch: 'As senhas não conferem',
	lowercase: 'Deve conter pelo menos um caractere minúsculo',
	uppercase: 'Deve conter pelo menos um caractere maiúsculo',
	number: 'Deve conter pelo menos um número',
	specialCharacter: 'Deve conter um caractere especial',
	min: (length: number) => `Mínimo ${length} caracteres`,
	max: (length: number) => `Máximo ${length} caracteres`,
};
