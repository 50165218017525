import React, { useEffect, useState } from 'react';
import Button from '../../../../components/Button/Button';
import { onlyDigits } from '../../../../helpers/format/onlyDigits';
import { ToastError } from '../../../../helpers/errors/ToastError';
import {
	Form,
	Input,
	InputCode,
	useZodForm,
} from '../../../../components/FormElements';
import { toast } from 'react-toastify';
import {
	VerificationCodeSchema,
	VerificationCodeSchemaType,
} from '../schemas/VerificationCodeSchema';
import { api } from '../../../../services/angular/axios';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { cpfMask } from '../../../../helpers/mask/cpfMask';
import { useNavigate } from 'react-router-dom';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

interface FormVerificationCodeProps {
	cpf: string;
	phone: string;
}

const ForgotVerificationForm: React.FC<FormVerificationCodeProps> = ({
	cpf,
	phone,
}) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [seconds, setSeconds] = useState(60);
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] =
		useState(false);

	const form = useZodForm({
		schema: VerificationCodeSchema,
	});

	const {
		formState: { errors },
		setValue,
		register,
	} = form;

	useEffect(() => {
		let interval: any = null;
		if (seconds > 0) {
			interval = setInterval(() => {
				setSeconds((prev) => prev - 1);
			}, 1000);
		} else {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [seconds]);

	const onSubmit = async (data: VerificationCodeSchemaType): Promise<void> => {
		try {
			setLoading(true);
			VerificationCodeSchema.parse(data);
			await api.post('/reset', {
				code: data.code,
				cpf: onlyDigits(data.cpf),
				password: data.password,
				passwordConfirmation: data.passwordConfirmation,
			});
			toast.success('Senha alterada com sucesso');
			navigate('/login');
			setLoading(false);
			setSeconds(60);
		} catch (error) {
			toast.error('Código inválido');
			setLoading(false);
		}
	};

	const hidePhone = (phone: string): string => {
		const phoneArray = phone.split('');
		const phoneArrayLength = phoneArray.length;

		const phoneArrayHidden = phoneArray.map((item, index) => {
			if (!phone[index].match(/\D/g) && index < phoneArrayLength - 4) {
				return '*';
			}
			return item;
		});
		return phoneArrayHidden.join('');
	};

	const handleResendCode = async (): Promise<void> => {
		try {
			setLoading(true);
			await api.post('/forgot', {
				cpf: onlyDigits(cpf),
			});
			setLoading(false);
			setSeconds(60);
		} catch (error) {
			ToastError(error);
			setLoading(false);
		}
	};

	return (
		<WhiteContainer>
			<div className="flex w-full flex-col gap-4">
				<div className="text-start text-2xl font-bold text-neutral-900">
					Informe o código enviado para{' '}
					<span className="text-left text-2xl font-bold text-neutral-500">
						{phone ? hidePhone(phone) : ''}
					</span>
				</div>
				<Form
					className="flex flex-col gap-4"
					form={form}
					onSubmit={async (data) => await onSubmit(data)}
				>
					<InputCode
						onCompleted={(value) => setValue('code', value)}
						autoFocus
						length={4}
					/>
					<Input
						label="CPF"
						defaultValue={cpfMask(cpf)}
						{...register('cpf')}
						errorMessage={errors.cpf?.message}
					/>
					<Input
						label="Nova senha"
						{...register('password')}
						type={isPasswordVisible ? 'text' : 'password'}
						inputIcon={
							isPasswordVisible ? (
								<BsFillEyeFill onClick={() => setIsPasswordVisible(false)} />
							) : (
								<BsFillEyeSlashFill
									onClick={() => setIsPasswordVisible(true)}
								/>
							)
						}
						errorMessage={errors.password?.message}
					/>
					<Input
						label="Confirmar senha"
						{...register('passwordConfirmation')}
						type={isPasswordConfirmationVisible ? 'text' : 'password'}
						inputIcon={
							isPasswordConfirmationVisible ? (
								<BsFillEyeFill
									onClick={() => setIsPasswordConfirmationVisible(false)}
								/>
							) : (
								<BsFillEyeSlashFill
									onClick={() => setIsPasswordConfirmationVisible(true)}
								/>
							)
						}
						errorMessage={errors.passwordConfirmation?.message}
					/>

					<div className="flex justify-center">
						{seconds ? (
							<span className="text-neutral-400">
								Reenviar o código SMS em {seconds} segundos
							</span>
						) : (
							<span
								className="cursor-pointer font-bold text-neutral-0 hover:text-neutral-700" // eslint-disable-next-line @typescript-eslint/no-misused-promises
								onClick={handleResendCode}
							>
								Reenviar o código SMS
							</span>
						)}
					</div>

					<Button type="submit" className="py-1 text-lg" loading={loading}>
						Avançar
					</Button>
					<Button
						className="py-1 text-lg"
						onClick={() => navigate('/login')}
						variant="secondary"
					>
						Voltar
					</Button>
				</Form>
			</div>
		</WhiteContainer>
	);
};

export default ForgotVerificationForm;
