import React from 'react';

const CancelOrder: React.FC = () => {
	return (
		<svg
			width="180"
			height="50"
			viewBox="0 0 124 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 8.56341L15.6916 0.766602L31.3832 8.56335V9.59314L15.6916 17.3899L0 9.61773V8.56341Z"
				fill="#EFC75E"
			/>
			<path
				d="M0 8.56299L15.6916 16.3597L31.3832 8.56299V24.181L15.6426 31.9778L0 24.1811V8.56299Z"
				fill="#E7BF55"
			/>
			<path
				d="M15.6916 31.9536V16.3601L0.0980485 8.6123H0V24.1813L15.6426 31.978L15.6916 31.9536Z"
				fill="#DBB551"
			/>
			<path
				d="M6.39923 5.39965L22.0663 13.1964L25.2537 11.6273L9.53753 3.83057L6.39923 5.39965Z"
				fill="#F7E3AF"
			/>
			<path
				d="M22.0663 13.1971L25.2537 11.6279V16.5315L24.3955 16.3844L23.4884 17.4877L22.0663 17.3897C22.0663 17.3896 22.0663 13.1971 22.0663 13.1971Z"
				fill="#F1D999"
			/>
			<path
				d="M12.4552 22.5877L7.1593 19.9397C6.86509 19.7926 6.61987 19.9643 6.61987 20.3321V23.8872C6.61987 24.255 6.86503 24.6718 7.1593 24.8189L12.4797 27.4668C12.774 27.6139 13.0192 27.4423 13.0192 27.0745V23.4948C12.9946 23.127 12.7494 22.7348 12.4552 22.5877ZM11.2538 25.5053L7.87029 23.8136C7.72318 23.74 7.60061 23.5194 7.60061 23.3233C7.60061 23.1271 7.72318 23.029 7.87029 23.1026L11.2538 24.7943C11.4009 24.8679 11.5235 25.0885 11.5235 25.2847C11.5235 25.4808 11.4009 25.5789 11.2538 25.5053ZM11.7442 24.2794L7.87029 22.3179C7.72318 22.2443 7.60061 22.0237 7.60061 21.8276C7.60061 21.6314 7.72318 21.5334 7.87029 21.6069L11.7442 23.5683C11.8913 23.6419 12.0138 23.8626 12.0138 24.0587C12.0139 24.2794 11.8913 24.3529 11.7442 24.2794Z"
				fill="#F2F2F2"
			/>
			<path
				d="M94.7443 18.6202H40.6487C39.5618 18.6202 38.6832 17.9551 38.6832 17.1323C38.6832 16.3096 39.5618 15.6445 40.6487 15.6445H94.7424C95.8293 15.6445 96.7079 16.3096 96.7079 17.1323C96.7079 17.9551 95.8293 18.6202 94.7443 18.6202Z"
				fill="#B2ABAB"
			/>
			<path
				d="M72.5891 17.1163L78.2396 11.4666C79.0207 10.6836 79.0207 9.4209 78.2396 8.64184L75.4143 5.81503C74.6332 5.03597 73.3703 5.03396 72.5891 5.81503L66.9367 11.4666L61.2882 5.81905C60.505 5.03597 59.2402 5.03597 58.459 5.81503L55.6338 8.64184C54.8526 9.4209 54.8546 10.6876 55.6378 11.4687L61.2862 17.1163L55.6476 22.7538C54.8664 23.5349 54.8664 24.7975 55.6476 25.5786L58.4728 28.4054C59.254 29.1845 60.5169 29.1865 61.2981 28.4054L66.9364 22.7679L72.6148 28.4474C73.398 29.2305 74.6629 29.2305 75.4441 28.4495L78.2693 25.6246C79.0505 24.8436 79.0485 23.5789 78.2673 22.7958L72.5891 17.1163Z"
				fill="#E2574C"
			/>
			<path
				d="M113.33 3.43994C118.57 3.43994 122.814 7.6844 122.814 12.9248C122.814 21.058 113.33 29.5232 113.33 29.5232C113.33 29.5232 103.845 21.058 103.845 12.9248C103.845 7.6844 108.089 3.43994 113.33 3.43994ZM116.886 12.9248C116.886 10.9567 115.285 9.36795 113.33 9.36795C111.361 9.36795 109.773 10.9567 109.773 12.9248C109.773 14.8929 111.361 16.4816 113.33 16.4816C115.285 16.4816 116.886 14.8929 116.886 12.9248Z"
				fill="#ED5565"
			/>
			<path
				d="M113.33 30.7088C113.047 30.7088 112.764 30.6081 112.54 30.4077C112.136 30.0473 102.659 21.4849 102.659 12.9248C102.659 7.04067 107.445 2.25439 113.33 2.25439C119.214 2.25439 124 7.04067 124 12.9248C124 21.4849 114.523 30.0473 114.119 30.4077C113.895 30.6081 113.612 30.7088 113.33 30.7088ZM113.33 4.6256C108.754 4.6256 105.03 8.34957 105.03 12.9248C105.03 19.1018 111.117 25.6937 113.328 27.8835C115.54 25.6914 121.629 19.0899 121.629 12.9248C121.629 8.34957 117.905 4.6256 113.33 4.6256Z"
				fill="black"
			/>
			<path
				d="M113.322 17.6669C110.707 17.6669 108.58 15.5388 108.58 12.9234C108.58 10.3091 110.707 8.18213 113.322 8.18213C115.938 8.18213 118.065 10.3091 118.065 12.9234C118.065 15.5388 115.938 17.6669 113.322 17.6669ZM113.322 10.5533C112.015 10.5533 110.951 11.6156 110.951 12.9234C110.951 14.2323 112.015 15.2957 113.322 15.2957C114.63 15.2957 115.694 14.2323 115.694 12.9234C115.694 11.6156 114.63 10.5533 113.322 10.5533Z"
				fill="black"
			/>
		</svg>
	);
};

export default CancelOrder;
