import { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';
import Button from '../../../components/Button/Button';
import { Checkbox, Form, useZodForm } from '../../../components/FormElements';
import ImageInput from '../../../components/ImageInputCamera/ImageInputCamera';
import useUserState from '../../../services/angular/angularUserState';
import { api } from '../../../services/angular/axios';
// @ts-expect-error
import { ReactComponent as Logo } from '../../../assets/Logo.svg';

import ContainerTitle from './components/ContainerTitle';
import { InviteCampaignSchema } from './schema/InviteCampaignSchema';
import { useApiVersionStore } from '../../../services/angular/phpVersion';
import { PhoneStep } from './components/PhoneStep';
import { useInviteCampaignStore } from './inviteCampaignStore';
import { PhoneValidationStep } from './components/PhoneValidationStep';
import { InfoStep } from './components/InfoStep';
import { PasswordStep } from './components/PasswordStep';
import { useAlert } from '../../../contexts/AlertContext';

const InviteCampaign = () => {
	const alert = useAlert();
	const setVersion = useApiVersionStore((state) => state.setVersion);
	const { formState, setStep } = useInviteCampaignStore();
	const login = useUserState((state) => state.login);
	const navigate = useNavigate();

	const form = useZodForm({
		schema: InviteCampaignSchema,
	});

	const onSubmit = async (data: z.infer<typeof InviteCampaignSchema>) => {
		if ('token' in formState)
			try {
				setVersion('v2');
				const createAccount = await api.post('/store', data, {
					headers: {
						Authorization: `Bearer ${formState.token}`,
					},
				});

				login(createAccount?.data?.token);

				alert.onCustom({
					title: 'Cadastro realizado com sucesso!',
					message:
						'Precisamos de mais algumas informações para finalizar seu cadastro.',
					cancel: {
						label: 'Ok',
						icon: <></>,
					},
				});
				navigate('/pendencias');
			} catch (error: any) {
				if (typeof error?.response?.data?.error === 'string') {
					toast.error(error.response?.data.error);
				} else {
					toast.error('Erro ao realizar cadastro');
				}
			} finally {
				setVersion('v1');
			}
	};

	useEffect(() => {
		if (formState.step === 'termos e política') {
			form.setValue('cellphone', formState.cellphone);
			form.setValue('cpf', formState.cpf);
			form.setValue('code', formState.code);
			form.setValue('email', formState.email);
			form.setValue('name', formState.name);
			form.setValue('password', formState.password);
			form.setValue('confirm', formState.confirm);
			void form.trigger();
		}
	}, [formState.step]);

	return (
		<div className="flex min-h-screen w-screen flex-col items-center justify-between bg-gradient-to-t from-yellow-50 to-yellow-400 to-75% px-8">
			<Link to="/">
				<Logo className="mt-8 h-20 min-h-[80px] w-56 min-w-[192px] text-neutral-0" />
			</Link>

			<div className="max-w-lg flex-1 py-10">
				<Form form={form} onSubmit={onSubmit}>
					<ContainerTitle />
					{formState.step === 'phone' && <PhoneStep />}
					{formState.step === 'phoneValidation' && <PhoneValidationStep />}
					{formState.step === 'info' && <InfoStep />}
					{formState.step === 'senha' && <PasswordStep />}
					{formState.step === 'foto' && (
						<div className="flex flex-col gap-4">
							<h2 className="text-center">
								Você pode escolher uma foto da sua galeria ou tirar uma agora,
								em um local bem iluminado.
							</h2>
							<ImageInput
								setImageName={(name) => form.setValue('avatar', name)}
								token={formState.token || ''}
								capture="user"
							/>
							<div>
								<Button
									type="button"
									disabled={!form.watch('avatar')}
									onClick={() => setStep('termos e política')}
								>
									Avançar
								</Button>
								<Button
									className="mt-4"
									onClick={() => setStep('senha')}
									variant="secondary"
								>
									Voltar
								</Button>
							</div>
						</div>
					)}
					{formState.step === 'termos e política' && (
						<div className="flex flex-col gap-4">
							<h2 className="text-center">
								Para ler basta clicar no link, e depois clicar nos quadradinhos
								abaixo e em &quot;confirmar&quot;.
							</h2>
							<Checkbox
								className="self-start"
								controller={{
									control: form.control,
									name: 'useTerms',
								}}
								goTo="termos-entregadores"
								label={
									<h1>
										Li e aceito os{' '}
										<span className="font-bold underline">Termos de uso</span>
									</h1>
								}
							/>
							<Checkbox
								className="self-start"
								controller={{
									control: form.control,
									name: 'privacyTerms',
								}}
								goTo="politica-privacidade"
								label={
									<h1>
										Li e aceito a{' '}
										<span className="font-bold underline">
											Política de privacidade
										</span>
									</h1>
								}
							/>
							<div>
								<Button type="submit" disabled={!form.formState.isValid}>
									Avançar
								</Button>
								<Button
									className="mt-4"
									onClick={() => setStep('foto')}
									variant="secondary"
								>
									Voltar
								</Button>
							</div>
						</div>
					)}
				</Form>
			</div>

			<div className="mb-10 self-center text-center text-sm text-neutral-0 md:mb-20">
				<h1>Já possui uma conta?</h1>
				<a href="/login" className="cursor-pointer font-bold hover:underline">
					Entre agora mesmo
				</a>
			</div>
		</div>
	);
};

export default InviteCampaign;
