import { Controller, Control, Path } from 'react-hook-form';
import { Label } from '../Label';
import { Variants } from '../variants';
import { WYSIWYGEditor } from './WYSIWYGEditor';

interface EditorProps<T extends object> {
	control: Control<T>;
	name: Path<T>;
	errorMessage?: string;
	placeholder?: string;
	label?: string;
	variant?: Variants;
	disabled?: boolean;
}

export function Editor<T extends object>({
	control,
	name,
	errorMessage,
	placeholder,
	label,
	disabled,
	variant = 'primary',
}: EditorProps<T>): JSX.Element {
	return (
		<>
			{label != null && <Label label={label} variant={variant} />}
			<Controller
				name={name}
				control={control}
				render={({ field: { onChange, value } }) => (
					<WYSIWYGEditor
						disabled={disabled}
						placeholder={placeholder}
						errorMessage={errorMessage}
						value={value}
						onChange={onChange}
					/>
				)}
			/>
		</>
	);
}
