import { z } from 'zod';

export const VehiclesSchema = z
	.object({
		categoryId: z.number({ required_error: 'Categoria obrigatória' }),
		crv: z.string().min(1, { message: 'foto do CRLV obrigatória' }),
		featureOneActive: z.boolean().optional(),
		featureTwoActive: z.boolean().optional(),
		geofenceId: z
			.object(
				{
					label: z.string().optional(),
					value: z.number(),
				},
				{
					required_error: 'Geofence obrigatória',
					invalid_type_error: 'Geofence obrigatória',
				}
			)
			.transform((value) => value?.value),
		make: z
			.object({
				label: z.string(),
				value: z.any(),
			})
			.transform((value) => value?.label)
			.optional()
			.nullable(),
		model: z.string().optional().nullable(),
		plate: z.string().min(7, { message: 'Placa obrigatória' }),
		left: z.string().min(1, { message: 'Foto da esquerda obrigatória' }),
		front: z.string().min(1, { message: 'Foto da frente obrigatória' }),
		right: z.string().optional().nullable(),
		rear: z.string().optional().nullable(),
		renavam: z.string().optional().nullable(),
		observation: z.string().optional().nullable(),
		year: z.coerce
			.number()
			.transform((value) => value || null)
			.optional()
			.nullable(),
		activeFeature: z.union([
			z.literal('featureOne'),
			z.literal('featureTwo'),
			z.literal(''),
		]),
	})
	.transform((value) => {
		const { activeFeature, ...rest } = value;

		return {
			...rest,
			featureOneActive: value.activeFeature === 'featureOne',
			featureTwoActive: value.activeFeature === 'featureTwo',
		};
	});

export type VehiclesSchemaType = z.infer<typeof VehiclesSchema>;
