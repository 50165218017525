import React from 'react';
import { BiLoaderCircle } from 'react-icons/bi';
import { twMerge } from 'tailwind-merge';

const variants = {
	disabled: {
		button: 'bg-neutral-50 border-0 cursor-not-allowed',
		text: 'text-neutral-200',
	},
	primary: {
		button:
			'transition-all enabled:hover:bg-neutral-900/80 enabled:active:bg-neutral-900 border-neutral-900 bg-neutral-900 disabled:bg-neutral-500/80 disabled:border-neutral-500',
		text: 'text-neutral-1',
	},
	secondary: {
		button:
			'transition-all enabled:hover:bg-neutral-0/20 enabled:active:bg-neutral-200 disabled:bg-neutral-400/10',
		text: 'text-neutral-900 disabled:text-neutral-900/50',
	},
	green: {
		button:
			'transition-all bg-green enabled:hover:bg-green/80 border-green disabled:bg-green/50 disabled:border-green/0',
		text: 'text-neutral-50',
	},
	'green-outline': {
		button:
			'transition-all enabled:hover:bg-green enabled:hover:border-green enabled:active:bg-green/90 border-neutral-900 bg-neutral-900 disabled:bg-neutral-100/80 disabled:border-neutral-0',
		text: 'text-neutral-50',
	},
	'blue-secondary': {
		button:
			'transition-all enabled:hover:bg-neutral-100 enabled:active:bg-neutral-50 border-blue',
		text: 'text-blue',
	},
	'blue-primary': {
		button:
			'transition-all enabled:hover:bg-blue/80 enabled:active:bg-blue bg-blue outline-secondary-0 border-blue disabled:bg-blue/50 disabled:border-blue/0',
		text: 'text-neutral-50',
	},
	red: {
		button:
			'transition-all bg-terracota-0 enabled:hover:bg-terracota-0/80 border-terracota-0 enabled:active:bg-terracota-0 disabled:bg-terracota-0/50 disabled:border-terracota-300',
		text: 'text-neutral-1',
	},
	'red-outline': {
		button:
			'transition-all bg-terracota-0/10 enabled:hover:bg-terracota-0/20 border-terracota-0 enabled:active:bg-terracota-0/30  disabled:opacity-80 disabled:border-terracota-300',
		text: 'text-terracota-0',
	},
};

export type ButtonVariants = keyof typeof variants;

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
	variant?: ButtonVariants;
	children?: React.ReactNode;
	loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
	{
		children,
		className,
		color = 'neutral-900',
		variant = 'primary',
		type = 'button',
		loading,
		...props
	},
	ref
) {
	return (
		<button
			ref={ref}
			type={type}
			{...props}
			className={twMerge(
				'flex w-full items-center justify-center gap-1 rounded border px-3 py-2 text-end align-text-bottom text-sm font-bold',
				variants[variant].button,
				variants[variant].text,
				className
			)}
		>
			{loading ? (
				<>
					<BiLoaderCircle className="mr-1 animate-slow-spin" size={20} />
					Carregando
				</>
			) : (
				<>{children}</>
			)}
		</button>
	);
});

export default Button;
