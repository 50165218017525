interface DownloadFileProps {
	blobFile: Blob;
	fileName: string;
}

export const downloadFile = async ({
	blobFile,
	fileName,
}: DownloadFileProps) => {
	if (blobFile !== undefined) {
		const url = window.URL.createObjectURL(new Blob([blobFile]));
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', fileName);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}
};
