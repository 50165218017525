import { Table, TableActions } from '../../../../components/Table/Table';
import { Tooltip } from '../../../../components/Tooltip';
import { useGetFreights } from '../../../../services/angular/useOrders';
import { TbClock, TbTruckDelivery } from 'react-icons/tb';
import dayjs from 'dayjs';
import { Row } from 'react-table';
import { Driver } from '../../../../components/Angular/FreightsDetail/Driver';
import useUserState from '../../../../services/angular/angularUserState';
import { SortOptions } from '../../../../services/angular/types/Params';
import { useState } from 'react';
import { Distance } from '../Historic/Distance';
import {
	scheduledFilterSchema,
	ScheduledFilterSchemaType,
} from './schema/scheduledFilterSchema';
import { FaExclamationCircle, FaShare } from 'react-icons/fa';
import { Date, Select, useZodForm } from '../../../../components/FormElements';
import { PageHeader } from '../../../../components/PageHeader';
import { FiltersTab } from '../../../../components/FiltersTab';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { twMerge } from 'tailwind-merge';
import { appState } from '../../../../store/appState';
import { OrderResponseApi } from '../../../../services/angular/types/Order';
import { CancelOrderModal } from '../../../../components/Order/CancelOrderModal';
import { AiFillStar } from 'react-icons/ai';
import { CategoryType } from '../../../../components/Order/CategoryType';

export const Scheduled = () => {
	const company = useUserState((state) => state.company);
	const [query, setQuery] = useState<ScheduledFilterSchemaType>();
	const [cancelModalReason, setCancelModalReason] = useState(false);
	const [orderToCancelId, setOrderToCancelId] = useState<number>();

	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});

	const { data, isLoading, isFetching } = useGetFreights(
		{
			companyId: company?.id,
			companyType: company?.type,
			status: 'scheduled',
			query,
			...params,
		},
		{
			refetchOnMount: 'always',
		}
	);

	const form = useZodForm({
		schema: scheduledFilterSchema,
	});

	return (
		<div>
			<PageHeader
				title="Fretes agendados"
				description="Fretes aguardando a data de agendamento para serem iniciados"
			/>

			<FiltersTab
				form={form}
				clearFilters={() => {
					form.reset();
					setQuery(undefined);
				}}
				onSubmit={(data) => {
					setQuery(data);
				}}
			>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="initialDate"
					label="De"
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="endDate"
					label="Até"
				/>
				<Select
					variant="light"
					className="w-full md:w-auto md:min-w-[128px]"
					label="Tipo"
					controller={{
						control: form.control,
						name: 'orderTypeId',
						defaultValue: null,
					}}
					options={[
						{
							label: 'Por hora',
							value: '3',
						},
						{
							label: 'Rota fixa',
							value: '1',
						},
						{
							label: 'Rota livre',
							value: '2',
						},
					]}
				/>
			</FiltersTab>
			<WhiteContainer>
				<Table<OrderResponseApi>
					triggerPageChange={form.formState.isSubmitting}
					isLoading={isLoading || isFetching}
					columns={[
						{
							accessor: 'id',
							Header: 'ID',
							Cell: ({ row }) => {
								const { id, searchOnlyFavoriteDrivers } = row.original;

								return (
									<div className="flex flex-col">
										{searchOnlyFavoriteDrivers && (
											<Tooltip message="Somente motoristas favoritos">
												<AiFillStar className="text-yellow-submenu" size={22} />
											</Tooltip>
										)}
										<p className="text-lg font-bold text-orange">
											#{String(id).padStart(5, '0')}
										</p>
									</div>
								);
							},
						},
						{
							accessor: 'orderDate',
							Header: 'Data',
							Cell: ({ row }) => {
								const { orderDate, scheduleDate } = row.original;
								const date = dayjs(orderDate).format('DD/MM/YYYY HH:mm');

								return (
									<div className="flex gap-1">
										{scheduleDate ? (
											<TbClock size={30} />
										) : (
											<TbTruckDelivery size={30} />
										)}
										<div className="flex flex-col">
											<span className="font-bold text-neutral-800">
												{scheduleDate ? 'Agendado' : 'Imediato'}
											</span>
											<span className="text-sm text-neutral-500">{date}</span>
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'completionPrevisionDate',
							Header: 'Prev. término',
							Cell: ({ value }) => {
								const date = dayjs(value).format('DD/MM/YYYY HH:mm');
								return (
									<div className="flex flex-col text-sm">
										<span>{date}</span>
										<span className="text-xs text-neutral-500">
											Previsão de términio
										</span>
									</div>
								);
							},
						},
						{
							// @ts-expect-error
							accessor: 'driverCompany.id',
							Header: 'Motorista',
							Cell: ({ row }: { row: Row<OrderResponseApi> }) => {
								return (
									<div className="flex justify-center">
										{row.original.driver && row.original.driverCompany ? (
											<Driver
												orderId={row.original.id}
												driver={row.original.driver}
												driverCompany={row.original.driverCompany}
											>
												<div className="flex flex-col items-start">
													<div className="line-clamp-2 text-left font-medium leading-none">
														{row.original.driver.name}
													</div>
													<span className="mt-1 text-xs text-neutral-600">
														Fretista
													</span>
												</div>
											</Driver>
										) : (
											<div className="flex flex-col">-</div>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'categoryId',
							Header: 'Veículo',
							Cell: ({ row }) => (
								<CategoryType
									category={row.original.category}
									categoryType={row.original.categoryType}
									isFavoriteDriver={row.original.isFavoriteDriver}
									defrauded={row.original.defrauded}
								/>
							),
						},
						{
							accessor: 'distance',
							Header: 'Distância',
							Cell: ({ row }) => <Distance order={row.original} />,
						},
						{
							accessor: 'total',
							Header: 'Valor',
							Cell: ({ row }) => {
								const { total, delivery, driverSubTotal } = row.original;
								return (
									<div
										className={twMerge(
											'flex flex-col',
											delivery ? 'text-green' : 'text-terracota-500'
										)}
									>
										{delivery
											? driverSubTotal.toLocaleString('pt-br', {
													style: 'currency',
													currency: 'BRL',
											  })
											: total.toLocaleString('pt-br', {
													style: 'currency',
													currency: 'BRL',
											  })}
									</div>
								);
							},
						},
						{
							accessor: 'statusId',
							Header: 'Status',
							Cell: ({ row }) => {
								const { incident, status } = row.original;

								return (
									<div className="flex">
										{status.id === appState.orderStatus.Finalizado.id ? (
											<div className="text-sm font-bold">
												{incident ? (
													<span className="flex items-center gap-1 text-terracota-500">
														<FaExclamationCircle size={16} />
														Incidente
													</span>
												) : (
													status.name
												)}
											</div>
										) : (
											<div className="text-sm font-bold">
												{incident ? (
													<span className="flex items-center gap-1 text-terracota-500">
														<FaExclamationCircle size={16} />
														Incidente
													</span>
												) : (
													status.name
												)}
											</div>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'created_at',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => (
								<TableActions
									row={row}
									actions={[
										{
											icon: 'trash',
											label: 'Cancelar pedido',
											onAction: (data) => {
												setCancelModalReason(true);
												setOrderToCancelId(data.id);
											},
										},
										{
											icon: <FaExclamationCircle size={16} />,
											label: 'Detalhes',
											href: `/fretes/detalhes/${row.original.id}`,
										},
										{
											icon: <FaShare size={16} />,
											label: 'Pedir novamente',
											href: `/fretes/pedir?ref=${row.original.id}`,
										},
									]}
								/>
							),
						},
					]}
					disabledSort={{
						created_at: true,
					}}
					data={data?.data.data || []}
					total={data?.data.meta.pagination.total || 0}
					onParamsChange={(params) => {
						setParams(params);
					}}
				/>
				<CancelOrderModal
					open={cancelModalReason}
					setOpen={setCancelModalReason}
					orderId={orderToCancelId}
				/>
			</WhiteContainer>
		</div>
	);
};

export default Scheduled;
