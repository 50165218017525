import { z } from 'zod';
import { ErrorsMessageHelper } from '../../../../../helpers/errors/ErrorsMessageHelper';
import { phoneRegex } from '../../../../../helpers/mask/phoneMask';

export const CardPaymentSchema = z.object({
	cardId: z
		.string()
		.optional()
		.transform((value) => Number(value)),
	cardHolderName: z
		.string()
		.min(3, ErrorsMessageHelper.min(3))
		.max(50, ErrorsMessageHelper.max(50)),
	expirationMonth: z
		.string()
		.min(2, ErrorsMessageHelper.min(2))
		.max(2, ErrorsMessageHelper.max(2))
		.refine((value) => Number(value) >= 1 && Number(value) <= 12, {
			message: 'Mês de expiração inválido',
		}),
	expirationYear: z
		.string()
		.min(2, ErrorsMessageHelper.min(2))
		.max(2, ErrorsMessageHelper.max(2))
		.refine((value) => Number(value) > 22, {
			message: 'Ano de expiração inválido',
		}),
	cardNumber: z
		.string()
		.min(19, ErrorsMessageHelper.min(19))
		.max(19, ErrorsMessageHelper.max(19))
		.transform((value) => value.replace(/\D/g, '')),
	securityCode: z
		.string()
		.min(3, ErrorsMessageHelper.min(3))
		.max(3, ErrorsMessageHelper.max(3)),
	value: z.number().min(1, ErrorsMessageHelper.min(1)),
	saveCardData: z.boolean(),
	mainCard: z.boolean(),
	brand: z.string().optional(),
	name: z
		.string()
		.min(3, ErrorsMessageHelper.min(3))
		.max(50, ErrorsMessageHelper.max(50)),
	lastName: z
		.string()
		.min(3, ErrorsMessageHelper.min(3))
		.max(50, ErrorsMessageHelper.max(50)),
	cpf: z
		.string()
		.min(14, ErrorsMessageHelper.min(14))
		.max(14, ErrorsMessageHelper.max(14)),
	cellphone: z
		.string()
		.regex(phoneRegex, {
			message: ErrorsMessageHelper.phone,
		})
		.or(z.literal('')),
	email: z
		.string()
		.email({ message: ErrorsMessageHelper.email })
		.or(z.literal('')),
	address: z
		.string()
		.min(3, ErrorsMessageHelper.min(3))
		.max(50, ErrorsMessageHelper.max(50)),
	zipcode: z
		.string()
		.min(9, ErrorsMessageHelper.min(9))
		.max(10, ErrorsMessageHelper.max(10)),
	number: z
		.string()
		.min(1, ErrorsMessageHelper.min(1))
		.max(10, ErrorsMessageHelper.max(10))
		.transform((value) => Number(value)),
	district: z
		.string()
		.min(3, ErrorsMessageHelper.min(3))
		.max(50, ErrorsMessageHelper.max(50)),
	city: z.string().optional().or(z.literal('')),
	complement: z.string().optional(),
	stateId: z
		.object({
			value: z.string(),
			label: z.string(),
		})
		.optional()
		.nullable()
		.transform((value) => Number(value?.value)),
});

export type CardPaymentSchemaType = z.infer<typeof CardPaymentSchema>;
