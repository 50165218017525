/* eslint-disable no-undef */
import React, { useRef, useCallback, useEffect } from 'react';
import { GoogleMap, PolygonF } from '@react-google-maps/api';
import { useGoogleLoaded } from '../../../../components/GoogleMap/useGoogleLoaded';

const containerStyle = {
	width: '100%',
	height: '100%',
};

export interface Path {
	lat: number;
	lng: number;
}

interface GeoMapProps {
	paths: Path[];
}

const GeoPolygon: React.FC<GeoMapProps> = ({ paths }) => {
	const mapRef = useRef<google.maps.Map>();
	const polygonRef = useRef<google.maps.Polygon>();

	useEffect(() => {
		if (mapRef.current && paths.length > 3) {
			const bounds = new google.maps.LatLngBounds();
			paths.forEach((path) => {
				bounds.extend(new google.maps.LatLng(path.lat, path.lng));
			});
			mapRef.current?.fitBounds(bounds);
		}
	}, [mapRef.current, paths]);

	const isLoaded = useGoogleLoaded();

	const onPolygonLoad = useCallback((polygon: google.maps.Polygon) => {
		polygonRef.current = polygon;
		const bounds = new google.maps.LatLngBounds();
		paths.forEach((path) => {
			bounds.extend(new google.maps.LatLng(path.lat, path.lng));
		});
		mapRef.current?.fitBounds(bounds);
	}, []);

	const onMapLoad = useCallback((map: google.maps.Map) => {
		mapRef.current = map;
	}, []);

	return (
		<>
			{isLoaded && (
				<GoogleMap
					mapContainerStyle={containerStyle}
					zoom={12}
					options={{
						mapTypeControl: false,
						streetViewControl: false,
						fullscreenControl: false,
						keyboardShortcuts: false,
						zoomControl: false,
						disableDefaultUI: true,
						clickableIcons: false,
						disableDoubleClickZoom: true,
						panControl: false,
						gestureHandling: 'none',
					}}
					onLoad={onMapLoad}
				>
					<PolygonF
						options={{
							fillColor: 'gray',
							fillOpacity: 0.4,
							strokeColor: 'black',
							strokeWeight: 2,
							draggable: false,
							geodesic: false,
							zIndex: 1,
						}}
						path={paths}
						onLoad={onPolygonLoad}
					/>
				</GoogleMap>
			)}
		</>
	);
};

export default React.memo(GeoPolygon);
