import React from 'react';

const LoginImageMan: React.FC = () => {
	return (
		<svg
			width="419"
			height="276"
			viewBox="0 0 419 276"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M195.46 275.636C274.542 275.636 338.65 270.671 338.65 264.546C338.65 258.421 274.542 253.456 195.46 253.456C116.378 253.456 52.27 258.421 52.27 264.546C52.27 270.671 116.378 275.636 195.46 275.636Z"
				fill="#EBEBEB"
			/>
			<path
				d="M413.1 183.396C405.1 194.296 396.67 206.936 394.63 220.596C394.627 220.639 394.64 220.682 394.667 220.717C394.693 220.752 394.731 220.776 394.774 220.784C394.817 220.793 394.862 220.786 394.899 220.764C394.937 220.742 394.966 220.707 394.98 220.666C395.91 216.94 397.099 213.285 398.54 209.726C403.83 208.276 409.02 207.076 411.79 201.826C414.56 196.576 414.27 189.396 413.73 183.516C413.69 183.206 413.26 183.186 413.1 183.396Z"
				fill="#FFC727"
			/>
			<path
				d="M398.62 209.626C402.23 201.516 406.87 193.376 412.77 186.706C412.77 186.706 412.87 186.706 412.83 186.766C409.149 191.521 405.915 196.606 403.17 201.956C405.84 199.246 408.76 196.826 411.28 193.956C411.28 193.956 411.41 193.956 411.36 194.046C408.85 197.046 406.12 200.316 402.86 202.536C401.98 204.156 401.1 205.776 400.2 207.396C400.42 207.266 400.65 207.166 400.87 207.046C400.87 207.046 400.95 207.046 400.87 207.106C400.62 207.236 400.37 207.366 400.12 207.476L398.84 209.776C398.78 209.946 398.54 209.806 398.62 209.626Z"
				fill="#263238"
			/>
			<path
				d="M401.31 206.806C403.47 205.594 405.533 204.216 407.48 202.686C407.53 202.686 407.61 202.686 407.56 202.756C405.674 204.387 403.607 205.796 401.4 206.956C401.3 206.996 401.21 206.856 401.31 206.806Z"
				fill="#263238"
			/>
			<path
				d="M393.7 218.566C393.708 218.598 393.727 218.626 393.754 218.646C393.78 218.666 393.812 218.677 393.845 218.677C393.878 218.677 393.91 218.666 393.937 218.646C393.963 218.626 393.982 218.598 393.99 218.566C393.99 214.066 393.99 209.566 394.06 205.026C395.21 202.696 397.69 201.406 399.41 199.546C400.649 198.185 401.726 196.685 402.62 195.076C404.703 191.234 405.979 187.008 406.37 182.656C406.84 177.846 405.02 161.966 402.99 162.516C401.99 162.806 398.53 170.666 397.63 173.266C396.165 177.556 395.094 181.971 394.43 186.456C392.75 197.036 392.89 207.916 393.7 218.566Z"
				fill="#FFC727"
			/>
			<path
				d="M393.66 199.156C393.91 193.466 395.19 187.716 396.39 182.156C397.69 176.206 399.2 170.606 402.26 165.306C402.32 165.206 402.46 165.306 402.41 165.396C400.37 169.52 398.791 173.856 397.7 178.326C397.9 178.006 398.15 177.706 398.37 177.406C398.45 177.286 398.65 177.406 398.56 177.516C398.287 177.995 397.969 178.447 397.61 178.866C396.55 183.116 395.8 187.466 395.15 191.806L395.3 191.636C395.3 191.636 395.38 191.636 395.36 191.686C395.34 191.736 395.19 191.976 395.1 192.116C394.78 194.316 394.48 196.516 394.17 198.696C398.188 195.464 401.336 191.281 403.33 186.526C401.47 191.576 398.56 196.066 394.07 199.126C393.99 199.716 393.9 200.296 393.82 200.886C393.82 201.006 393.61 200.976 393.61 200.886C393.61 200.366 393.61 199.886 393.66 199.326C393.649 199.315 393.64 199.301 393.634 199.287C393.628 199.272 393.625 199.257 393.625 199.241C393.625 199.225 393.628 199.209 393.634 199.195C393.64 199.18 393.649 199.167 393.66 199.156Z"
				fill="#263238"
			/>
			<path
				d="M395.51 190.906C398.576 186.567 401.254 181.966 403.51 177.156C403.51 177.096 403.63 177.156 403.61 177.156C401.815 182.205 399.125 186.89 395.67 190.986C395.59 191.106 395.45 190.996 395.51 190.906Z"
				fill="#263238"
			/>
			<path
				d="M399.49 176.046C400.73 173.856 402.04 171.716 403.21 169.476C403.21 169.416 403.33 169.476 403.3 169.536C402.34 171.872 401.126 174.095 399.68 176.166C399.672 176.178 399.662 176.189 399.65 176.198C399.638 176.206 399.624 176.212 399.61 176.215C399.595 176.219 399.58 176.219 399.566 176.217C399.551 176.214 399.537 176.209 399.525 176.201C399.513 176.193 399.502 176.183 399.493 176.171C399.485 176.159 399.479 176.145 399.475 176.131C399.472 176.116 399.472 176.101 399.474 176.087C399.477 176.072 399.482 176.058 399.49 176.046Z"
				fill="#263238"
			/>
			<path
				d="M390.65 205.556C390.603 208.534 390.268 211.502 389.65 214.416C389.65 214.736 389.74 215.056 389.79 215.416C390.711 212.187 391.241 208.86 391.37 205.506C391.4 205.461 391.416 205.409 391.416 205.356C391.416 205.302 391.4 205.25 391.37 205.206C391.492 200.717 391.157 196.227 390.37 191.806C388.97 183.536 385.93 175.606 380.16 169.406C380.112 169.366 380.055 169.337 379.994 169.322C379.934 169.307 379.87 169.306 379.809 169.318C379.748 169.33 379.69 169.356 379.64 169.394C379.59 169.432 379.549 169.48 379.52 169.536C377.2 175.456 378.01 182.536 379.99 188.416C381.068 191.622 382.608 194.654 384.56 197.416C386.52 200.166 389.04 202.516 390.65 205.556Z"
				fill="#FFC727"
			/>
			<path
				d="M380.82 172.176C386.29 181.046 390.09 191.556 390.56 202.036C390.56 202.226 390.25 202.276 390.23 202.036C390.1 201.086 389.95 200.156 389.8 199.226C387.426 197.124 385.341 194.716 383.6 192.066C383.6 192.066 383.6 191.966 383.68 192.006C385.551 194.307 387.554 196.498 389.68 198.566C389.29 196.246 388.83 193.966 388.28 191.726L387.85 191.216C387.75 191.106 387.91 190.946 388.01 191.056L388.14 191.196C387.372 188.117 386.417 185.088 385.28 182.126C383.753 180.591 382.41 178.883 381.28 177.036C381.28 177.036 381.28 176.946 381.36 177.036C382.17 178.176 382.96 179.326 383.88 180.386C384.25 180.806 384.65 181.206 385.04 181.616C383.777 178.4 382.28 175.282 380.56 172.286C380.53 172.166 380.74 172.046 380.82 172.176Z"
				fill="#263238"
			/>
			<path
				d="M381.93 183.116C383.7 185.496 385.71 187.766 387.35 190.216C387.35 190.286 387.29 190.366 387.24 190.306C385.143 188.166 383.319 185.774 381.81 183.186C381.76 183.106 381.88 183.046 381.93 183.116Z"
				fill="#263238"
			/>
			<path
				d="M387.6 190.806L387.67 190.886C387.72 190.886 387.67 191.016 387.59 190.966L387.52 190.886C387.46 190.806 387.54 190.716 387.6 190.806Z"
				fill="#263238"
			/>
			<path
				d="M372.26 189.226C372.897 194.197 374.894 198.896 378.03 202.806C379.32 204.33 380.91 205.572 382.7 206.456C384.7 207.456 386.85 208.606 388.95 209.456C389.946 211.992 390.687 214.622 391.16 217.306C391.22 217.076 391.29 216.836 391.36 216.606C390.01 205.056 382.54 195.176 372.97 188.846C372.901 188.801 372.821 188.776 372.739 188.772C372.657 188.769 372.575 188.787 372.503 188.826C372.43 188.865 372.37 188.923 372.327 188.993C372.284 189.063 372.261 189.144 372.26 189.226Z"
				fill="#FFC727"
			/>
			<path
				d="M375.57 192.636C375.5 192.576 375.57 192.456 375.66 192.516C380.918 196.414 385.11 201.572 387.85 207.516C387.92 207.676 387.7 207.816 387.61 207.656C387.17 206.876 386.71 206.126 386.25 205.376C384.03 204.774 382.006 203.602 380.38 201.976C380.38 201.976 380.38 201.866 380.45 201.906C381.283 202.668 382.211 203.32 383.21 203.846C384.1 204.296 385.06 204.586 385.99 204.946C385.18 203.666 384.33 202.436 383.45 201.236C381.092 200.338 378.881 199.095 376.89 197.546C376.83 197.546 376.89 197.406 376.96 197.446C378.931 198.64 380.964 199.728 383.05 200.706C381.721 198.952 380.285 197.282 378.75 195.706C378.01 195.316 377.25 194.916 376.53 194.506C376.53 194.506 376.53 194.366 376.58 194.386C377.179 194.594 377.75 194.877 378.28 195.226C377.37 194.336 376.52 193.476 375.57 192.636Z"
				fill="#263238"
			/>
			<path
				d="M375.77 196.626C375.9 196.716 376.02 196.786 376.14 196.886V196.956C376 196.886 375.89 196.816 375.76 196.736C375.63 196.656 375.7 196.586 375.77 196.626Z"
				fill="#263238"
			/>
			<path
				d="M406.28 242.246H380.08L382.19 218.386L382.66 213.126H403.7L404.17 218.386L406.28 242.246Z"
				fill="#455A64"
			/>
			<path
				d="M404.17 218.386H382.19L382.66 213.126H403.7L404.17 218.386Z"
				fill="#263238"
			/>
			<path d="M404.95 209.446H381.4V214.656H404.95V209.446Z" fill="#455A64" />
			<path
				d="M0 242.246L52.34 242.006L104.68 241.916L209.37 241.746L314.06 241.916L366.4 242.006L418.74 242.246L366.4 242.496L314.06 242.576L209.37 242.746L104.68 242.576L52.34 242.496L0 242.246Z"
				fill="#263238"
			/>
			<path
				d="M40.5899 242.166L198.59 242.846C236.99 212.696 240.95 168.026 213.59 140.956C197.01 124.576 175.96 127.886 155.92 125.276H155.86C148.778 124.513 141.891 122.481 135.53 119.276C114.89 108.386 103.26 82.6957 85.8899 61.0957C83.7299 58.3857 81.4699 55.7457 79.0999 53.2157C64.8199 37.9657 36.3999 37.4657 21.5299 58.9057C-16.3101 113.396 0.999908 194.456 40.5899 242.166Z"
				fill="#FFC727"
			/>
			<path
				opacity="0.1"
				d="M37.0899 117.546C41.2299 182.356 116.09 206.546 162.81 187.136C203.46 170.246 190.15 131.726 155.81 125.276H155.75C148.668 124.513 141.781 122.481 135.42 119.276C114.78 108.386 103.15 82.6958 85.7799 61.0958C59.9599 47.6858 34.0499 70.0958 37.0899 117.546Z"
				fill="black"
			/>
			<path
				d="M165.99 269.806C163.6 268.806 110.55 222.586 109.64 219.546C109.29 218.396 115.57 210.276 123.44 200.676C125.14 198.606 126.9 196.476 128.71 194.326C140.42 180.326 153.18 165.766 153.18 165.766L188.76 196.586L163.31 224.296L157.73 230.386C157.73 230.386 170.13 257.166 170.73 260.606C171.33 264.046 168.37 270.806 165.99 269.806Z"
				fill="#AD6359"
			/>
			<path
				d="M165.99 269.806C163.6 268.806 110.55 222.586 109.64 219.546C109.22 218.136 118.58 206.446 128.71 194.326L163.31 224.326L157.73 230.416C157.73 230.416 170.13 257.196 170.73 260.636C171.33 264.076 168.37 270.806 165.99 269.806Z"
				fill="white"
			/>
			<path
				d="M165.99 269.806C163.6 268.806 110.55 222.586 109.64 219.546C109.29 218.396 115.57 210.276 123.44 200.676L157.73 230.386C157.73 230.386 170.13 257.166 170.73 260.606C171.33 264.046 168.37 270.806 165.99 269.806Z"
				fill="#37474F"
			/>
			<path
				d="M167.08 266.976C158.38 258.976 122.52 227.696 113.32 220.476C113.24 220.416 113.2 220.476 113.32 220.536C121.77 228.616 157.88 259.636 167.01 267.116C167.15 267.276 167.27 267.136 167.08 266.976Z"
				fill="#263238"
			/>
			<path
				d="M169.01 255.676C168.045 254.787 166.906 254.11 165.664 253.686C164.423 253.263 163.107 253.102 161.8 253.216C159.449 253.389 157.257 254.464 155.68 256.216C155.63 256.276 155.73 256.356 155.79 256.316C157.625 254.816 159.897 253.952 162.265 253.855C164.633 253.757 166.968 254.431 168.92 255.776C168.926 255.785 168.933 255.793 168.943 255.798C168.952 255.804 168.962 255.808 168.973 255.808C168.984 255.809 168.995 255.808 169.005 255.804C169.015 255.8 169.024 255.794 169.031 255.786C169.039 255.777 169.044 255.768 169.047 255.757C169.05 255.747 169.05 255.736 169.048 255.725C169.046 255.715 169.041 255.705 169.035 255.696C169.028 255.687 169.02 255.681 169.01 255.676Z"
				fill="#263238"
			/>
			<path
				d="M160.37 234.226C156.85 231.076 151.63 228.736 146.88 229.716C146.7 229.716 146.75 230.016 146.88 230.056C151.415 231.132 155.82 232.695 160.02 234.716C160.085 234.762 160.166 234.781 160.244 234.768C160.323 234.755 160.394 234.711 160.44 234.646C160.486 234.581 160.505 234.5 160.492 234.421C160.479 234.342 160.435 234.272 160.37 234.226Z"
				fill="#263238"
			/>
			<path
				d="M162.22 237.686C158.71 234.536 153.48 232.196 148.73 233.186C148.55 233.186 148.61 233.476 148.73 233.516C153.262 234.601 157.667 236.163 161.87 238.176C161.935 238.222 162.016 238.241 162.094 238.228C162.173 238.215 162.244 238.171 162.29 238.106C162.336 238.041 162.355 237.96 162.342 237.881C162.329 237.803 162.285 237.732 162.22 237.686Z"
				fill="#263238"
			/>
			<path
				d="M164.04 241.156C160.52 238.006 155.3 235.666 150.55 236.646C150.37 236.646 150.42 236.946 150.55 236.986C155.084 238.063 159.49 239.626 163.69 241.646C163.722 241.669 163.758 241.685 163.797 241.694C163.835 241.703 163.875 241.704 163.914 241.698C163.953 241.691 163.991 241.677 164.024 241.656C164.058 241.635 164.087 241.608 164.11 241.576C164.133 241.544 164.149 241.507 164.158 241.469C164.167 241.43 164.168 241.39 164.162 241.351C164.155 241.312 164.141 241.275 164.12 241.241C164.099 241.208 164.072 241.179 164.04 241.156Z"
				fill="#263238"
			/>
			<path
				d="M165.85 244.616C162.33 241.466 157.11 239.126 152.36 240.116C152.18 240.116 152.24 240.416 152.36 240.446C156.892 241.531 161.297 243.093 165.5 245.106C165.565 245.152 165.646 245.171 165.724 245.158C165.803 245.145 165.874 245.101 165.92 245.036C165.966 244.971 165.985 244.89 165.972 244.811C165.959 244.732 165.915 244.662 165.85 244.616Z"
				fill="#263238"
			/>
			<path
				d="M122.94 214.006C118.94 210.536 113.54 216.756 117.55 220.226C121.56 223.696 126.95 217.476 122.94 214.006Z"
				fill="white"
			/>
			<path
				d="M172.53 228.076C172.03 225.016 168.53 224.496 166.02 225.076C161.813 226.159 157.965 228.328 154.86 231.366C154.845 231.377 154.834 231.391 154.825 231.408C154.817 231.424 154.813 231.442 154.813 231.461C154.813 231.479 154.817 231.497 154.825 231.514C154.834 231.53 154.845 231.545 154.86 231.556C154.816 231.635 154.791 231.724 154.787 231.815C154.784 231.906 154.802 231.997 154.84 232.08C154.878 232.162 154.934 232.235 155.006 232.292C155.077 232.348 155.161 232.387 155.25 232.406C159.31 232.856 163.6 233.846 167.68 233.046C170.08 232.576 173 230.976 172.53 228.076ZM161.53 231.836C159.53 231.656 157.53 231.386 155.53 231.286C157.668 229.997 159.871 228.819 162.13 227.756C163.23 227.26 164.363 226.84 165.52 226.496C167.32 225.986 173.4 226.056 170.38 230.236C168.77 232.546 163.96 232.046 161.57 231.806L161.53 231.836Z"
				fill="#263238"
			/>
			<path
				d="M144.87 224.976C147.55 228.156 151.52 230.056 154.97 232.236C155.051 232.278 155.141 232.301 155.233 232.301C155.325 232.302 155.415 232.281 155.497 232.24C155.579 232.199 155.65 232.139 155.704 232.066C155.759 231.992 155.795 231.906 155.81 231.816C155.829 231.819 155.849 231.818 155.867 231.812C155.886 231.807 155.903 231.796 155.917 231.783C155.931 231.769 155.941 231.752 155.947 231.733C155.952 231.715 155.954 231.695 155.95 231.676C155.418 227.364 153.763 223.267 151.15 219.796C149.59 217.796 146.5 215.996 144.2 218.076C141.9 220.156 143.3 223.126 144.87 224.976ZM144.48 221.076C144.73 215.926 149.48 219.676 150.62 221.186C151.311 222.179 151.932 223.218 152.48 224.296C153.578 226.538 154.562 228.834 155.43 231.176C153.8 229.996 152.08 228.976 150.43 227.856C148.37 226.526 144.37 223.916 144.48 221.076Z"
				fill="#263238"
			/>
			<path
				d="M228.37 141.866C225.03 160.866 175.54 214.106 169.82 220.216L168.52 221.596L130.77 189.976L131.24 189.416L167.49 145.906L65.08 140.906L105.26 112.326C105.26 112.326 108.34 114.276 114.94 113.596C137.68 111.246 188.94 106.086 200.94 107.086C216.44 108.416 231.37 124.406 228.37 141.866Z"
				fill="#263238"
			/>
			<path
				d="M180.54 129.716C179.2 131.097 177.951 132.564 176.8 134.106C175.52 135.646 174.22 137.166 172.94 138.706V138.756C173.18 138.296 173.41 137.836 173.65 137.386C174.48 135.836 175.16 134.226 176.13 132.746C176.142 132.727 176.146 132.705 176.141 132.683C176.137 132.662 176.123 132.643 176.105 132.631C176.086 132.619 176.064 132.615 176.042 132.619C176.021 132.624 176.002 132.637 175.99 132.656C174.99 134.116 173.86 135.506 172.99 137.056C172.58 137.786 172.19 138.516 171.83 139.276C171.63 139.686 171.43 140.096 171.25 140.516C171.155 140.711 171.071 140.911 171 141.116C170.42 141.836 169.86 142.556 169.3 143.286C168.08 144.916 166.98 146.636 165.74 148.286C165.67 148.366 165.81 148.456 165.88 148.376C167.13 146.866 168.52 145.466 169.79 143.966C171.06 142.466 172.31 140.866 173.53 139.276C174.75 137.686 175.89 136.166 177.07 134.616C178.366 133.096 179.565 131.496 180.66 129.826C180.66 129.736 180.59 129.666 180.54 129.716Z"
				fill="#37474F"
			/>
			<path
				d="M174.1 212.806C171.95 210.806 169.65 208.986 167.39 207.136C165.13 205.286 162.84 203.426 160.54 201.596C155.96 197.926 151.4 194.226 146.76 190.596C144.22 188.596 141.66 186.656 139.01 184.816C138.89 184.736 138.76 184.926 138.86 185.016C143.2 189.016 147.86 192.576 152.41 196.236C156.96 199.896 161.6 203.556 166.24 207.156C168.78 209.156 171.31 211.156 173.96 212.966C174.07 213.036 174.19 212.876 174.1 212.806Z"
				fill="#37474F"
			/>
			<path
				d="M93.8499 55.5358C99.4599 66.3458 119.5 96.6358 135.37 102.536C144.12 105.756 188.92 113.876 195.6 109.216C203.42 103.766 201.29 79.2758 195.76 78.3358C178.88 75.4658 147.02 78.4258 139.4 75.6758C135.95 74.4258 118.73 59.8558 99.9099 47.5458C92.7899 42.8058 90.2299 48.5658 93.8499 55.5358Z"
				fill="#AD6359"
			/>
			<path
				d="M179.61 75.9058C179.61 75.9058 179.38 112.906 177.61 112.366C175.84 111.826 137.8 106.896 128.24 101.426C118.68 95.9557 103.6 73.9557 94.8799 59.4257C86.1599 44.8957 94.4699 41.8358 103.69 49.1358C112.91 56.4358 138.78 73.7858 140.45 73.9458C142.12 74.1058 179.61 75.9058 179.61 75.9058Z"
				fill="#455A64"
			/>
			<path
				d="M172.49 108.286C172.3 103.286 172.18 98.2858 172.28 93.2858C172.33 90.7958 172.41 88.2857 172.54 85.8057C172.67 83.3257 173.04 80.8057 173.13 78.3557C173.13 78.2157 173.36 78.2357 173.34 78.3557C173.12 80.8357 173.24 83.3558 173.16 85.8458C173.08 88.3358 173 90.8457 172.94 93.3257C172.83 98.3257 172.71 103.326 172.71 108.276C172.71 108.436 172.5 108.416 172.49 108.286Z"
				fill="#263238"
			/>
			<path
				d="M125.6 99.5857C116.07 92.0857 102.85 72.6657 94.9099 59.4757C88.6899 49.1457 91.1399 44.5957 96.3999 45.4757L125.6 99.5857Z"
				fill="#37474F"
			/>
			<path d="M177.99 110.376H112.05V113.336H177.99V110.376Z" fill="#C7C7C7" />
			<path
				d="M126.16 110.376L123.9 113.336H177.99V110.376H126.16Z"
				fill="#A6A6A6"
			/>
			<path
				d="M128.98 113.326H193.24L198.59 69.6858H134.33L128.98 113.326Z"
				fill="#C7C7C7"
			/>
			<path
				d="M159.87 91.5058C159.858 92.0171 159.948 92.5256 160.136 93.0014C160.324 93.4772 160.605 93.9106 160.963 94.2759C161.321 94.6413 161.748 94.9313 162.22 95.1287C162.692 95.3262 163.198 95.4272 163.71 95.4257C164.757 95.4259 165.763 95.0155 166.51 94.2826C167.258 93.5496 167.689 92.5527 167.71 91.5058C167.721 90.9953 167.629 90.4878 167.441 90.0132C167.252 89.5386 166.971 89.1064 166.613 88.7421C166.256 88.3778 165.829 88.0887 165.357 87.8918C164.886 87.695 164.381 87.5943 163.87 87.5957C162.824 87.5955 161.82 88.0045 161.073 88.7353C160.325 89.4661 159.893 90.4606 159.87 91.5058Z"
				fill="white"
			/>
			<path
				d="M198.07 73.9058C200.49 77.6458 206.47 82.0158 207.29 85.2258C207.521 86.0088 207.493 86.8454 207.21 87.6112C206.927 88.3769 206.404 89.0309 205.72 89.4758C205.72 89.4758 209.19 90.6757 209.45 93.7057C209.59 95.2657 207.22 96.8557 207.22 96.8557C207.22 96.8557 209.95 97.9358 210.02 100.646C210.08 102.806 207.69 104.076 207.69 104.076C207.69 104.076 209.84 106.326 209.19 108.546C207.87 113.046 189.48 115.486 185.74 109.086C184 106.086 188.52 105.746 188.52 105.746C188.52 105.746 181.89 104.676 181.3 100.896C180.71 97.1158 186.61 97.0258 186.61 97.0258C186.61 97.0258 180.19 95.9258 179.97 91.9458C179.73 87.8058 185.66 88.7158 185.66 88.7158C185.66 88.7158 181.2 86.4858 181.73 83.3958C182.59 78.2958 193.08 83.7158 197 82.2258L198.07 73.9058Z"
				fill="#AD6359"
			/>
			<path
				d="M207.37 104.326C207.383 104.326 207.396 104.331 207.405 104.34C207.415 104.35 207.42 104.363 207.42 104.376C207.42 104.389 207.415 104.402 207.405 104.411C207.396 104.42 207.383 104.426 207.37 104.426C201.289 106.806 194.628 107.283 188.27 105.796C188.07 105.746 188.07 105.396 188.27 105.416C194.656 105.98 201.09 105.613 207.37 104.326Z"
				fill="#263238"
			/>
			<path
				d="M207.31 96.8857C207.38 96.8857 207.41 96.9857 207.31 97.0157C201.26 99.3257 193.21 99.8957 186.38 97.2157C186.15 97.1257 186.03 96.8057 186.31 96.8957C191.01 98.3257 201.45 98.1757 207.31 96.8857Z"
				fill="#263238"
			/>
			<path
				d="M185.22 88.3658C191.87 90.6758 198.59 90.6258 205.48 89.5858C205.57 89.5858 205.58 89.7058 205.48 89.7358C202.24 91.1658 197.78 91.3658 194.29 91.2358C191.052 91.1736 187.886 90.2633 185.11 88.5958C185.03 88.5358 185.08 88.3158 185.22 88.3658Z"
				fill="#263238"
			/>
			<path
				d="M118.29 126.346C118.29 126.346 44.6299 137.526 44.2899 137.066C43.9499 136.606 54.7599 57.6757 80.0399 45.0657C84.0399 43.0657 93.5899 42.0657 97.5299 46.3657C108.73 58.5057 119.29 125.306 118.29 126.346Z"
				fill="#455A64"
			/>
			<path
				d="M117.73 119.806L45.21 129.946C45.55 127.776 45.99 125.186 46.53 122.276L116.62 112.426C117.07 115.206 117.44 117.686 117.73 119.806Z"
				fill="#37474F"
			/>
			<path
				d="M115.96 108.536L47.2999 118.186C47.5533 116.892 47.8233 115.559 48.1099 114.186L115.29 104.806C115.53 106.046 115.75 107.316 115.96 108.536Z"
				fill="#37474F"
			/>
			<path
				d="M94.6399 60.4658C90.5499 60.6358 84.9899 53.3758 83.2599 48.0258C83.1499 47.6658 84.3299 44.2558 85.5599 40.0258C86.3099 37.4758 87.0699 34.6157 87.5599 31.9557L103.46 39.2057C101.876 42.3489 100.68 45.6734 99.8999 49.1058C99.8332 49.4754 99.7998 49.8502 99.7999 50.2258C99.8044 50.2857 99.8044 50.3458 99.7999 50.4058C99.7499 52.5758 98.5899 60.2958 94.6399 60.4658Z"
				fill="#AD6359"
			/>
			<path
				d="M103.49 39.2357C101.899 42.3806 100.7 45.7088 99.9199 49.1457C99.8479 49.5112 99.8143 49.8833 99.8199 50.2557C99.8244 50.3157 99.8244 50.3758 99.8199 50.4357C99.237 50.4094 98.6579 50.3291 98.0899 50.1957C88.4299 48.1157 87.9899 35.5757 88.0899 32.1957L103.49 39.2357Z"
				fill="#263238"
			/>
			<path
				d="M89.2099 10.4257C86.2099 15.9857 86.0699 33.6757 89.5299 38.2957C94.5299 44.9957 104.44 47.4657 110.53 41.0457C116.45 34.8257 116.29 11.7757 112.26 7.39574C106.37 0.965737 93.5699 2.21574 89.2099 10.4257Z"
				fill="#AD6359"
			/>
			<path
				d="M104.22 24.3857C104.22 24.3857 104.13 24.4457 104.13 24.4957C104.18 25.6757 104.03 27.0357 102.97 27.4457C102.97 27.4457 102.97 27.5157 102.97 27.5057C104.28 27.3157 104.45 25.5057 104.22 24.3857Z"
				fill="#263238"
			/>
			<path
				d="M103.15 23.1657C101.24 23.0357 101.15 26.8557 102.9 26.9757C104.65 27.0957 104.74 23.2657 103.15 23.1657Z"
				fill="#263238"
			/>
			<path
				d="M111.16 24.5757C111.16 24.5757 111.25 24.6357 111.25 24.6857C111.18 25.8657 111.3 27.2357 112.35 27.6857C112.35 27.6857 112.35 27.7557 112.35 27.7457C111.04 27.4957 110.91 25.6857 111.16 24.5757Z"
				fill="#263238"
			/>
			<path
				d="M112.26 23.3758C114.16 23.2858 114.2 27.1058 112.43 27.1858C110.66 27.2658 110.66 23.4458 112.26 23.3758Z"
				fill="#263238"
			/>
			<path
				d="M101.69 21.3757C102.199 21.246 102.7 21.0857 103.19 20.8957C103.737 20.7997 104.238 20.5297 104.62 20.1257C104.714 19.97 104.753 19.7878 104.734 19.6072C104.714 19.4265 104.635 19.2574 104.51 19.1257C104.229 18.9091 103.896 18.7709 103.545 18.7252C103.194 18.6795 102.836 18.728 102.51 18.8657C101.804 19.0487 101.189 19.4824 100.78 20.0857C100.692 20.229 100.649 20.3946 100.654 20.5624C100.66 20.7301 100.714 20.8925 100.811 21.0297C100.908 21.1668 101.043 21.2726 101.199 21.3341C101.355 21.3956 101.526 21.4101 101.69 21.3757Z"
				fill="#263238"
			/>
			<path
				d="M113.79 21.4958C113.278 21.3796 112.774 21.2326 112.28 21.0558C111.727 20.9793 111.216 20.7186 110.83 20.3158C110.727 20.165 110.679 19.9836 110.694 19.8017C110.708 19.6198 110.785 19.4483 110.91 19.3158C111.185 19.0892 111.515 18.9401 111.866 18.8839C112.218 18.8276 112.578 18.8663 112.91 18.9958C113.624 19.1539 114.252 19.5752 114.67 20.1758C114.764 20.317 114.814 20.4828 114.813 20.6523C114.813 20.8218 114.763 20.9875 114.669 21.1285C114.575 21.2696 114.441 21.3797 114.285 21.445C114.129 21.5103 113.956 21.528 113.79 21.4958Z"
				fill="#263238"
			/>
			<path
				d="M102.67 34.8058C102.88 35.1358 103.07 35.5658 103.48 35.6958C103.912 35.7858 104.358 35.7858 104.79 35.6958C104.79 35.6958 104.85 35.6958 104.79 35.7658C104.573 35.9527 104.311 36.0782 104.03 36.1292C103.748 36.1802 103.458 36.1548 103.19 36.0558C102.968 35.9404 102.785 35.7622 102.664 35.5434C102.543 35.3246 102.49 35.075 102.51 34.8258C102.54 34.8058 102.63 34.8058 102.67 34.8058Z"
				fill="#263238"
			/>
			<path
				d="M104.25 31.4458C104.538 31.9218 104.921 32.3334 105.376 32.6551C105.83 32.9768 106.345 33.2016 106.89 33.3158C107.362 33.4186 107.848 33.4523 108.33 33.4158C108.511 33.4145 108.692 33.3977 108.87 33.3658C108.933 33.3539 108.992 33.3225 109.037 33.2759C109.081 33.2292 109.11 33.1698 109.12 33.1058V30.5758C109.45 30.7558 111.12 31.5758 111.12 31.0858C111.132 26.9611 110.72 22.846 109.89 18.8058C109.89 18.6758 109.67 18.7058 109.67 18.8058C109.67 22.5958 110.28 26.3758 110.3 30.1858C109.707 29.8853 109.074 29.6731 108.42 29.5558C108.35 29.5558 108.5 32.6558 108.5 32.6858C107.751 32.7389 107 32.6406 106.29 32.3966C105.581 32.1526 104.927 31.768 104.37 31.2658C104.28 31.2658 104.18 31.3458 104.25 31.4458Z"
				fill="#263238"
			/>
			<path
				d="M107.97 33.2158C107.39 33.8529 106.657 34.3313 105.84 34.6058C105.395 34.7509 104.915 34.7509 104.47 34.6058C103.47 34.2758 103.54 33.3658 103.79 32.6058C103.92 32.2026 104.098 31.8164 104.32 31.4557C105.32 32.4135 106.598 33.0296 107.97 33.2158Z"
				fill="#263238"
			/>
			<path
				d="M105.84 34.6058C105.395 34.7509 104.915 34.7509 104.47 34.6058C103.47 34.2758 103.54 33.3658 103.79 32.6058C104.305 32.6633 104.787 32.8913 105.158 33.2536C105.53 33.6159 105.77 34.0918 105.84 34.6058Z"
				fill="#FF9CBD"
			/>
			<path
				d="M86.2898 26.1757C90.8398 26.9057 93.7198 21.6557 93.0398 17.1757C93.0398 17.1757 105.04 19.8757 106.54 10.1757C107.391 11.6042 108.551 12.8236 109.936 13.7443C111.32 14.665 112.893 15.2635 114.54 15.4958L115.06 19.0058C116.34 18.7558 117.99 15.3158 115.18 12.2658C115.18 12.2658 115.41 7.36575 112.08 4.11575C111.182 3.19134 110.037 2.54509 108.781 2.25426C107.526 1.96343 106.213 2.04033 105 2.47575C103.018 0.751629 100.442 -0.130997 97.8198 0.0157611C96.4481 0.111902 95.1191 0.53407 93.9434 1.24718C92.7676 1.96028 91.7791 2.94372 91.0598 4.11575C91.0598 4.11575 84.9298 4.72575 83.5198 8.92575C83.0978 10.1082 82.9278 11.3658 83.0208 12.6179C83.1138 13.8699 83.4677 15.0887 84.0598 16.1958C84.0598 16.1958 82.3998 17.7358 82.8398 21.4658C83.1698 23.8058 84.9198 25.9557 86.2898 26.1757Z"
				fill="#263238"
			/>
			<path
				d="M106.37 9.24576C106.391 10.0465 106.331 10.8472 106.19 11.6358C106.067 12.4161 105.848 13.1784 105.54 13.9058C104.947 15.3497 103.829 16.5149 102.41 17.1658C100.955 17.7652 99.3793 18.0152 97.81 17.8958C97.03 17.7958 96.23 17.7558 95.44 17.6258C94.65 17.4958 93.8699 17.2958 93.0699 17.1658C93.8299 17.4258 94.6099 17.6458 95.3799 17.8958C96.1499 18.1458 96.98 18.1758 97.78 18.3258C99.4289 18.5262 101.102 18.3024 102.64 17.6758C104.173 16.9541 105.358 15.6568 105.94 14.0658C106.485 12.5205 106.633 10.8631 106.37 9.24576Z"
				fill="#263238"
			/>
			<path
				d="M112.12 15.4557C110.825 14.8846 109.635 14.1004 108.6 13.1358C108.098 12.6613 107.664 12.1194 107.31 11.5258C106.949 10.9146 106.66 10.2636 106.45 9.58575C106.452 10.3245 106.612 11.0543 106.92 11.7258C107.226 12.4115 107.664 13.03 108.21 13.5458C108.747 14.061 109.361 14.4894 110.03 14.8158C110.685 15.147 111.392 15.3635 112.12 15.4557Z"
				fill="#263238"
			/>
			<path
				d="M101.42 30.1357C100.466 30.011 99.5663 29.621 98.8231 29.0102C98.0799 28.3994 97.523 27.5923 97.2158 26.6806C96.9086 25.769 96.8634 24.7895 97.0853 23.8534C97.3072 22.9174 97.7874 22.0624 98.4712 21.3857C99.155 20.709 100.015 20.2378 100.953 20.0257C101.892 19.8136 102.871 19.869 103.779 20.1857C104.687 20.5025 105.489 21.0678 106.092 21.8173C106.695 22.5669 107.075 23.4706 107.19 24.4257C107.351 25.7808 106.967 27.1443 106.123 28.2168C105.28 29.2892 104.045 29.983 102.69 30.1457C102.268 30.1975 101.841 30.1942 101.42 30.1357ZM102.66 20.6257C102.291 20.5806 101.919 20.5806 101.55 20.6257C100.419 20.767 99.3838 21.3342 98.6561 22.2118C97.9285 23.0894 97.5628 24.2114 97.6336 25.3493C97.7043 26.4871 98.2062 27.5552 99.037 28.3359C99.8677 29.1166 100.965 29.5512 102.105 29.5512C103.245 29.5512 104.342 29.1166 105.173 28.3359C106.004 27.5552 106.506 26.4871 106.576 25.3493C106.647 24.2114 106.281 23.0894 105.554 22.2118C104.826 21.3342 103.791 20.767 102.66 20.6257Z"
				fill="#FFC727"
			/>
			<path
				d="M113.22 30.2358C111.27 29.9758 109.76 27.7458 109.79 25.0658C109.784 23.7823 110.183 22.5297 110.93 21.4858C111.221 21.0173 111.627 20.6319 112.111 20.367C112.595 20.102 113.138 19.9665 113.69 19.9735C114.241 19.9806 114.781 20.1299 115.258 20.4072C115.734 20.6844 116.131 21.08 116.41 21.5558C117.111 22.6256 117.461 23.8877 117.41 25.1658C117.41 28.0058 115.62 30.2858 113.51 30.2558L113.22 30.2358ZM113.94 20.6858C113.854 20.6755 113.766 20.6755 113.68 20.6858C113.241 20.6981 112.812 20.8164 112.429 21.0306C112.046 21.2448 111.72 21.5485 111.48 21.9158C110.824 22.8459 110.474 23.9575 110.48 25.0958C110.48 27.5558 111.83 29.5658 113.56 29.5958C115.29 29.6258 116.73 27.5958 116.76 25.1758C116.797 24.0403 116.479 22.9215 115.85 21.9758C115.641 21.6375 115.363 21.3476 115.034 21.1251C114.704 20.9027 114.332 20.7529 113.94 20.6858Z"
				fill="#FFC727"
			/>
			<path
				d="M107.01 25.0258L106.57 24.4858C107.36 23.8458 109.18 22.9858 110.42 24.3458L109.91 24.8158C108.8 23.5858 107.08 24.9658 107.01 25.0258Z"
				fill="#FFC727"
			/>
			<path
				d="M85.0899 26.2458L84.4899 25.9158C84.6399 25.6358 86.0099 23.1458 87.4899 22.9158C88.9699 22.6858 97.1199 23.4958 97.4899 23.5358L97.4199 24.2158C94.1534 23.8332 90.8685 23.6295 87.5799 23.6058C86.6299 23.8058 85.4599 25.5558 85.0899 26.2458Z"
				fill="#FFC727"
			/>
			<path
				d="M90.6399 26.2157C90.6399 26.2157 87.7199 20.0057 84.9699 20.9557C82.2199 21.9057 83.7399 30.4357 86.4999 32.0457C86.8377 32.2828 87.2209 32.4475 87.6254 32.5297C88.0298 32.6119 88.4469 32.6098 88.8505 32.5235C89.2541 32.4371 89.6355 32.2684 89.971 32.0279C90.3064 31.7874 90.5886 31.4803 90.7999 31.1257L90.6399 26.2157Z"
				fill="#AD6359"
			/>
			<path
				d="M85.47 23.3857C85.47 23.3857 85.4 23.3857 85.47 23.4657C87.39 24.6657 88.15 26.7657 88.57 28.8957C88.4866 28.667 88.3545 28.4591 88.1828 28.2865C88.0111 28.1139 87.804 27.9806 87.5757 27.896C87.3474 27.8113 87.1035 27.7773 86.8607 27.7962C86.618 27.8152 86.3823 27.8867 86.17 28.0057C86.11 28.0057 86.17 28.1357 86.17 28.1257C86.554 28.0742 86.9446 28.1426 87.2883 28.3217C87.632 28.5007 87.912 28.7815 88.09 29.1257C88.3887 29.7006 88.6202 30.3079 88.78 30.9357C88.85 31.1457 89.2 31.1057 89.17 30.8757C89.67 28.1957 88.25 24.1457 85.47 23.3857Z"
				fill="#263238"
			/>
			<path
				d="M100.37 53.1358C100.37 56.4658 100.13 64.1958 94.9499 63.3958C92.8599 63.0758 91.0299 61.6458 89.5099 60.2558C87.9627 58.819 86.5747 57.2197 85.3699 55.4857C83.9557 53.5932 82.7014 51.5863 81.6199 49.4857C81.6199 49.3957 81.4399 49.4857 81.4799 49.5657C82.5899 51.6757 83.4799 53.8558 84.7999 55.8558C86.0387 57.808 87.5393 59.5812 89.2599 61.1258C90.8899 62.5558 92.8399 63.9957 95.0599 64.2357C96.0265 64.3425 97.0002 64.1122 97.8165 63.5838C98.6329 63.0554 99.2416 62.2613 99.5399 61.3358C100.43 58.6996 100.713 55.8967 100.37 53.1358Z"
				fill="#263238"
			/>
			<path
				d="M70.6 62.0657C65.6 90.4057 70.4399 111.636 75.2499 114.266C88.2499 121.346 119.34 106.986 128.12 104.136C131.67 102.976 115.49 75.8357 111.87 77.5157C103.33 81.4557 91.3799 88.1557 90.8699 88.1057C89.8699 88.0057 88.7899 80.7657 83.8699 59.6857C79.8499 42.6257 72.65 50.4757 70.6 62.0657Z"
				fill="#AD6359"
			/>
			<path
				d="M156.37 64.0858C157.26 67.6458 148.3 69.0858 148.3 69.0858C149.791 69.6204 151.047 70.6607 151.85 72.0258C153.16 74.4858 149.85 77.6158 149.85 77.6158C151.281 78.4893 152.414 79.7757 153.1 81.3058C154.1 83.7858 151.24 87.4157 151.24 87.4157C152.11 88.9463 152.401 90.7386 152.06 92.4658C150.96 97.0358 143.96 98.8358 139.84 100.806C135.43 102.916 128.13 103.696 124.84 105.186L110.52 78.9058C110.52 78.9058 124.38 64.2258 132.29 61.9058C136.68 60.6858 155.06 58.6458 156.37 64.0858Z"
				fill="#AD6359"
			/>
			<path
				d="M148.24 69.0058C143.24 68.7858 138.24 69.7658 133.37 70.7658C133.27 70.7658 133.3 70.9558 133.37 70.9258C138.18 69.9258 143.37 70.0958 148.22 69.2458C148.252 69.2484 148.283 69.2383 148.308 69.2177C148.332 69.1971 148.347 69.1676 148.35 69.1357C148.353 69.1039 148.342 69.0724 148.322 69.048C148.301 69.0236 148.272 69.0084 148.24 69.0058Z"
				fill="#263238"
			/>
			<path
				d="M149.68 77.2858C148.544 77.342 147.42 77.5506 146.34 77.9058C145.17 78.2058 144.01 78.5258 142.85 78.9058C140.53 79.5958 138.23 80.3258 135.95 81.1258C135.85 81.1258 135.88 81.3257 135.95 81.2957C138.26 80.6157 140.58 79.9758 142.95 79.4058C144.117 79.1124 145.287 78.8324 146.46 78.5658C147.584 78.3817 148.684 78.0731 149.74 77.6457C149.763 77.6418 149.786 77.6333 149.806 77.6206C149.827 77.6079 149.844 77.5913 149.858 77.5718C149.872 77.5523 149.882 77.5303 149.888 77.5069C149.893 77.4836 149.894 77.4594 149.89 77.4358C149.886 77.4121 149.877 77.3895 149.865 77.3692C149.852 77.3488 149.835 77.3312 149.816 77.3173C149.796 77.3033 149.774 77.2934 149.751 77.288C149.728 77.2825 149.703 77.2818 149.68 77.2858Z"
				fill="#263238"
			/>
			<path
				d="M151.19 87.4458C150.155 87.5058 149.139 87.7495 148.19 88.1657L145.04 89.1657C142.93 89.8057 140.83 90.4458 138.7 91.0058C138.59 91.0058 138.63 91.2058 138.7 91.1758C140.87 90.6158 143.04 90.0758 145.2 89.4958L148.37 88.6158C149.361 88.4335 150.312 88.0781 151.18 87.5658C151.18 87.5658 151.24 87.4458 151.19 87.4458Z"
				fill="#263238"
			/>
			<path
				d="M90.8899 88.1057C94.2799 86.1057 97.7299 84.2658 101.18 82.4058L106.4 79.7057L109.03 78.3758C109.926 77.9166 110.847 77.5092 111.79 77.1558L111.61 77.2558C113.93 75.0058 116.25 72.7758 118.7 70.6858C119.92 69.6358 121.16 68.5958 122.44 67.6158C123.693 66.6085 125.032 65.7119 126.44 64.9358C125.1 65.8217 123.827 66.8043 122.63 67.8758C121.41 68.9258 120.21 69.9858 119.04 71.0858C116.71 73.2958 114.36 75.4857 112.1 77.7657L112.05 77.8158L111.93 77.8658C111.13 78.1558 110.2 78.6457 109.33 79.0457L106.69 80.3257L101.44 82.9557C97.9499 84.6857 94.4399 86.4357 90.8899 88.1057Z"
				fill="#263238"
			/>
			<path
				d="M109.44 76.8957L125.06 108.806C125.06 108.806 82.83 126.046 74.74 116.696C66.65 107.346 66.93 76.0158 70.74 58.8858C74.55 41.7558 82.87 50.0558 84.74 59.5158C86.61 68.9758 90.07 87.0558 90.91 86.8458C91.75 86.6358 109.44 76.8957 109.44 76.8957Z"
				fill="#455A64"
			/>
			<path
				d="M104.96 82.7057C107.25 86.8057 109.48 90.9358 111.52 95.1757C112.52 97.2857 113.52 99.4058 114.52 101.556C115.52 103.706 116.21 105.936 117.2 108.056C117.211 108.081 117.211 108.109 117.2 108.135C117.19 108.16 117.17 108.18 117.145 108.191C117.12 108.201 117.091 108.202 117.066 108.191C117.041 108.181 117.021 108.161 117.01 108.136C116.14 105.966 115.01 103.896 114.01 101.776C113.01 99.6558 112.01 97.5257 111.01 95.4157C109.01 91.1857 107.01 86.9558 104.85 82.7758C104.72 82.6658 104.9 82.5957 104.96 82.7057Z"
				fill="#263238"
			/>
			<path
				d="M110.08 78.1958L109.35 76.9358L109.49 76.9857C107.34 78.3457 105.14 79.6157 102.96 80.9157C100.78 82.2157 98.54 83.4257 96.31 84.6357C94.08 85.8457 91.83 87.0458 89.56 88.1858C87.29 89.3258 85.02 90.4658 82.7 91.5058C84.83 90.1158 87.0099 88.8158 89.2099 87.5058C91.4099 86.1958 93.61 84.9758 95.83 83.7458C98.05 82.5158 100.3 81.3258 102.55 80.1558C104.8 78.9858 107.08 77.8558 109.39 76.7858L109.49 76.7357V76.8257L110.08 78.1958Z"
				fill="#263238"
			/>
			<path
				d="M86.1799 66.9258C87.1799 70.2158 87.9399 73.5458 88.6699 76.9258L89.6699 81.9258L90.1799 84.4358C90.3254 85.2739 90.5566 86.0949 90.8699 86.8857C90.3733 86.1725 89.9825 85.3911 89.7099 84.5658C89.4199 83.7558 89.1499 82.9358 88.9199 82.1158C88.4299 80.4658 88.0299 78.7958 87.6699 77.1158C86.9422 73.7569 86.4443 70.3523 86.1799 66.9258Z"
				fill="#263238"
			/>
			<path
				d="M88.06 68.3058C88.3438 69.2548 88.4489 70.2483 88.3699 71.2357C88.3263 72.2315 88.0525 73.2036 87.5699 74.0757C87.6299 73.5757 87.7099 73.0758 87.7599 72.6258C87.8099 72.1758 87.8499 71.6758 87.8799 71.1958C87.9499 70.2458 87.97 69.2858 88.06 68.3058Z"
				fill="#263238"
			/>
			<path
				d="M68.6899 72.8658C68.6899 74.8658 68.6899 76.8658 68.6899 78.8658C68.6899 80.8658 68.6899 82.8658 68.7799 84.8658C68.8999 88.8658 69.0499 92.8658 69.4799 96.8658C69.7734 100.829 70.4057 104.76 71.3699 108.616C71.8505 110.539 72.5484 112.401 73.4499 114.166C74.2918 115.973 75.6575 117.485 77.3699 118.506C75.5211 117.632 73.9952 116.197 73.0099 114.406C72.0016 112.644 71.1997 110.771 70.6199 108.826C70.029 106.893 69.5713 104.921 69.2499 102.926C68.8899 100.926 68.6299 98.9258 68.4299 96.9258C68.0899 92.9258 67.9199 88.9258 67.9599 84.8758C67.9999 80.8258 68.2399 76.8558 68.6899 72.8658Z"
				fill="#263238"
			/>
			<path
				d="M67.7899 75.3958C67.996 76.0479 68.1433 76.7173 68.2299 77.3958C68.3297 78.0786 68.3932 78.7662 68.4199 79.4557C68.0499 78.8459 67.8146 78.164 67.7299 77.4557C67.6356 76.7705 67.6559 76.0743 67.7899 75.3958Z"
				fill="#263238"
			/>
			<path
				d="M311 213.806C310 216.196 263.6 269.106 260.56 270.006C259.41 270.346 251.31 264.006 241.74 256.146C239.67 254.436 237.55 252.666 235.4 250.846C221.45 239.086 206.92 226.286 206.92 226.286L237.85 190.806L265.48 216.346L271.55 221.946C271.55 221.946 298.37 209.606 301.82 208.976C305.27 208.346 312 211.386 311 213.806Z"
				fill="#AD6359"
			/>
			<path
				d="M311 213.806C310 216.196 263.6 269.106 260.56 270.006C259.15 270.416 247.49 261.006 235.4 250.846L265.48 216.346L271.55 221.946C271.55 221.946 298.37 209.606 301.82 208.976C305.27 208.346 312 211.386 311 213.806Z"
				fill="white"
			/>
			<path
				d="M311 213.806C310 216.196 263.6 269.106 260.56 270.006C259.41 270.346 251.31 264.006 241.74 256.146L271.55 221.946C271.55 221.946 298.37 209.606 301.82 208.976C305.27 208.346 312 211.386 311 213.806Z"
				fill="#37474F"
			/>
			<path
				d="M308.22 212.676C300.22 221.356 268.8 257.116 261.55 266.296C261.5 266.376 261.55 266.416 261.61 266.346C269.72 257.916 300.86 221.906 308.37 212.806C308.52 212.616 308.37 212.496 308.22 212.676Z"
				fill="#263238"
			/>
			<path
				d="M296.93 210.716C296.039 211.678 295.358 212.815 294.931 214.054C294.504 215.294 294.34 216.609 294.45 217.916C294.621 220.27 295.696 222.466 297.45 224.046C297.51 224.096 297.59 223.996 297.55 223.946C296.063 222.101 295.21 219.826 295.12 217.459C295.03 215.091 295.707 212.758 297.05 210.806C297.056 210.798 297.06 210.789 297.063 210.779C297.065 210.77 297.066 210.76 297.064 210.75C297.063 210.74 297.06 210.731 297.054 210.723C297.049 210.714 297.043 210.707 297.035 210.701C297.027 210.695 297.018 210.691 297.009 210.688C296.999 210.686 296.989 210.685 296.979 210.687C296.97 210.688 296.96 210.691 296.952 210.696C296.943 210.701 296.936 210.708 296.93 210.716Z"
				fill="#263238"
			/>
			<path
				d="M275.45 219.246C272.29 222.756 269.93 227.966 270.9 232.726C270.9 232.906 271.2 232.846 271.24 232.726C272.333 228.197 273.909 223.798 275.94 219.606C275.988 219.541 276.008 219.459 275.995 219.38C275.983 219.3 275.94 219.228 275.875 219.181C275.81 219.133 275.729 219.113 275.649 219.125C275.569 219.137 275.498 219.181 275.45 219.246Z"
				fill="#263238"
			/>
			<path
				d="M278.92 217.446C275.76 220.956 273.4 226.166 274.37 230.916C274.37 231.106 274.67 231.046 274.71 230.916C275.793 226.393 277.355 221.998 279.37 217.806C279.395 217.746 279.399 217.679 279.383 217.616C279.367 217.553 279.331 217.497 279.28 217.457C279.229 217.416 279.167 217.393 279.102 217.391C279.037 217.389 278.973 217.408 278.92 217.446Z"
				fill="#263238"
			/>
			<path
				d="M282.37 215.646C279.21 219.146 276.85 224.366 277.82 229.116C277.82 229.296 278.12 229.246 278.16 229.116C279.253 224.587 280.829 220.189 282.86 215.996C282.906 215.931 282.925 215.85 282.912 215.771C282.899 215.693 282.855 215.622 282.79 215.576C282.725 215.529 282.644 215.511 282.565 215.524C282.487 215.537 282.416 215.581 282.37 215.646Z"
				fill="#263238"
			/>
			<path
				d="M285.86 213.806C282.7 217.316 280.35 222.526 281.31 227.286C281.31 227.466 281.61 227.406 281.65 227.286C282.745 222.755 284.328 218.356 286.37 214.166C286.418 214.098 286.437 214.014 286.423 213.933C286.408 213.851 286.363 213.778 286.295 213.731C286.227 213.683 286.143 213.664 286.062 213.678C285.98 213.692 285.908 213.738 285.86 213.806Z"
				fill="#263238"
			/>
			<path
				d="M255.11 256.646C251.63 260.646 257.83 266.056 261.32 262.056C264.81 258.056 258.6 252.646 255.11 256.646Z"
				fill="white"
			/>
			<path
				d="M269.37 207.106C266.31 207.596 265.78 211.106 266.37 213.606C267.425 217.816 269.562 221.677 272.57 224.806C272.582 224.82 272.597 224.832 272.615 224.84C272.632 224.848 272.651 224.853 272.67 224.853C272.689 224.853 272.708 224.848 272.725 224.84C272.742 224.832 272.758 224.82 272.77 224.806C272.849 224.85 272.938 224.875 273.029 224.878C273.12 224.882 273.211 224.864 273.294 224.826C273.376 224.788 273.449 224.731 273.506 224.66C273.562 224.589 273.602 224.505 273.62 224.416C274.09 220.356 275.09 216.076 274.3 211.996C273.84 209.566 272.24 206.646 269.37 207.106ZM273.1 218.106C272.92 220.106 272.64 222.106 272.53 224.106C271.32 221.926 270.02 219.806 269.02 217.496C268.53 216.394 268.112 215.262 267.77 214.106C267.27 212.306 267.36 206.216 271.53 209.246C273.8 210.886 273.28 215.696 273.07 218.076L273.1 218.106Z"
				fill="#263238"
			/>
			<path
				d="M266.15 234.806C269.34 232.126 271.25 228.166 273.45 224.716C273.49 224.635 273.511 224.546 273.511 224.455C273.51 224.365 273.489 224.276 273.448 224.195C273.407 224.115 273.348 224.045 273.276 223.991C273.203 223.937 273.119 223.901 273.03 223.886C273.033 223.867 273.032 223.847 273.027 223.828C273.021 223.81 273.011 223.793 272.997 223.779C272.983 223.765 272.966 223.755 272.947 223.749C272.929 223.743 272.909 223.742 272.89 223.746C268.579 224.259 264.478 225.897 261 228.496C259 230.056 257.18 233.136 259.25 235.496C261.32 237.856 264.29 236.326 266.15 234.806ZM262.25 235.176C257.1 234.906 260.87 230.116 262.38 229.036C263.378 228.35 264.42 227.732 265.5 227.186C267.742 226.092 270.038 225.114 272.38 224.256C271.2 225.886 270.17 227.596 269.05 229.256C267.72 231.216 265.09 235.276 262.25 235.136V235.176Z"
				fill="#263238"
			/>
			<path
				d="M264.18 210.706L231.87 250.646L131.76 174.696L122.11 167.366C122.11 167.366 119.21 169.146 114.87 171.306C108.87 174.306 100.04 178.066 92.36 178.866C79.48 180.216 60.18 179.196 50 164.306C41.42 151.766 45 136.036 45 136.036L59.32 130.596C59.32 130.596 93.77 104.286 115.32 104.366C124.49 104.366 137.52 111.896 149.56 119.666C161.6 127.436 264.18 210.706 264.18 210.706Z"
				fill="#263238"
			/>
			<path
				d="M122.11 167.366C122.11 167.366 119.21 169.146 114.87 171.306C107.76 162.016 92.5899 145.046 92.1299 144.446L122.11 167.366Z"
				fill="#37474F"
			/>
			<path
				d="M71.8599 121.806C69.8599 123.106 67.8599 124.496 65.8599 125.806C63.8599 127.116 61.8599 128.346 59.9199 129.806C57.9799 131.266 56.1499 132.856 54.3199 134.446C53.4399 135.216 52.5699 135.996 51.7099 136.796C50.8097 137.563 49.9705 138.399 49.1999 139.296C49.1399 139.376 49.2599 139.486 49.3399 139.436C50.3 138.826 51.2217 138.158 52.0999 137.436L54.9799 135.186C56.8299 133.736 58.7199 132.336 60.5699 130.886C62.4199 129.436 64.1199 127.886 65.9399 126.406C67.7599 124.926 69.9399 123.406 71.9399 121.926L71.8599 121.806Z"
				fill="#37474F"
			/>
			<path
				d="M257.07 208.926C254.99 210.986 253.07 213.206 251.07 215.386C249.07 217.566 247.18 219.776 245.25 221.986C241.39 226.416 237.5 230.816 233.7 235.286C231.61 237.746 229.55 240.226 227.6 242.796C227.51 242.906 227.69 243.056 227.79 242.956C231.92 238.786 235.72 234.306 239.57 229.886C243.42 225.466 247.28 221.026 251.07 216.536C253.15 214.076 255.28 211.636 257.21 209.066C257.37 208.956 257.16 208.806 257.07 208.926Z"
				fill="#37474F"
			/>
			<path
				d="M178.58 210.096C173.58 205.836 162.28 197.446 157.12 193.426C151.96 189.406 146.86 185.426 141.64 181.526C140.15 180.406 138.64 179.316 137.12 178.216C135.6 177.116 134.04 176.096 132.57 174.956C132.48 174.896 132.4 175.036 132.48 175.106C133.83 176.166 135.1 177.336 136.43 178.436C137.76 179.536 138.96 180.546 140.23 181.576C142.73 183.576 145.23 185.576 147.77 187.576C152.877 191.576 158.03 195.552 163.23 199.506C166.23 201.716 175.31 208.296 178.33 210.406C178.51 210.526 178.75 210.246 178.58 210.096Z"
				fill="#37474F"
			/>
			<path
				d="M212.75 168.856C208.18 164.726 197.69 156.716 192.87 152.856C188.05 148.996 183.16 145.046 178.26 141.186L163.7 129.686C159 125.976 154.32 122.206 149.32 118.876C146.59 117.056 143.78 115.356 140.88 113.816C138.075 112.237 135.141 110.899 132.11 109.816C132.09 109.806 132.067 109.805 132.047 109.813C132.026 109.821 132.009 109.836 132 109.856C131.991 109.876 131.99 109.898 131.997 109.919C132.005 109.94 132.02 109.956 132.04 109.966C134.51 111.486 137.11 112.786 139.61 114.256C142.11 115.726 144.51 117.256 146.9 118.826C151.9 122.126 156.51 125.826 161.19 129.506C170.91 137.146 180.61 144.816 190.41 152.356C195.89 156.576 206.8 165.276 212.52 169.176C212.69 169.286 212.92 169.006 212.75 168.856Z"
				fill="#37474F"
			/>
		</svg>
	);
};

export default LoginImageMan;
