import { toast } from 'react-toastify';

export const useCopy =
	() =>
	async (text: string, message = 'Texto copiado') => {
		if (!navigator.clipboard || !text) return;

		await navigator.clipboard.writeText(text);

		toast.info(message, {
			autoClose: 600,
			toastId: 'copy',
		});
	};
