export const formatTime = (timeInMinutes: number | undefined): string => {
    // Verifica se timeInMinutes é um número válido
    if (typeof timeInMinutes !== 'number' || isNaN(timeInMinutes)) {
        return '0min 0seg';
    }

    const hours = Math.floor(timeInMinutes / 60);
    const minutes = Math.floor(timeInMinutes % 60);
    const seconds = Math.floor((timeInMinutes * 60) % 60);

    let formattedTime = '';
    if (hours > 0) {
        formattedTime += `${hours}h `;
    }
    formattedTime += `${minutes}min ${seconds}seg`;

    return formattedTime;
};