import dayjs from 'dayjs';
import { create } from 'zustand';
import { z } from 'zod';
import { dateFilterSchema } from '../../helpers/dateFilterSchema';
import { Params } from '../../services/angular/types/Params';

type ChargesFilterState = {
	params: Params;
	setParams: (params: Params) => void;
	setChargesFilter: (state: ChargesFilterSchemaType) => void;
	clearChargesFilter: () => void;
} & ChargesFilterSchemaType;

export const useChargesFilterState = create<ChargesFilterState>((set) => ({
	params: {
		page: 1,
		take: 50,
	},
	setParams: (params) => set({ params }),
	freightForwarderName: null,
	completionDate: null,
	orderDate: null,
	initialDate: dayjs().date(1).hour(0).minute(0).second(0).toISOString(),
	endDate: dayjs().hour(0).minute(0).second(0).toISOString(),
	setChargesFilter: (state) => set(state),
	clearChargesFilter: () =>
		set({
			statusId: undefined,
			completionDate: null,
			orderDate: null,
			initialDate: dayjs().date(1).hour(0).minute(0).second(0).toISOString(),
			endDate: dayjs().hour(0).minute(0).second(0).toISOString(),
			paymentTypeId: undefined,
			freightForwarderName: null,
		}),
}));

export const chargesFilterSchema = dateFilterSchema.extend({
	completionDate: z
		.any()
		.optional()
		.transform(
			(value) => value && dayjs(value).hour(0).minute(0).second(0).toISOString()
		),
	orderDate: z
		.any()
		.optional()
		.transform(
			(value) => value && dayjs(value).hour(0).minute(0).second(0).toISOString()
		),
	freightForwarderName: z
		.object({ value: z.string(), label: z.string().optional() })
		.nullable()
		.optional()
		.transform((value) => (value ? value.value : null)),
	statusId: z
		.array(
			z.object({
				value: z.number(),
				label: z.string().optional(),
			})
		)
		.nullable()
		.transform((value) => value?.map((item) => item.value).join(',')),
	paymentTypeId: z
		.object({
			value: z.number().nullable(),
			label: z.string().optional(),
		})
		.nullable()
		.optional()
		.transform((value) => value?.value),
});

export type ChargesFilterSchemaType = z.infer<typeof chargesFilterSchema>;
