import { FaSpinner } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';

interface LoadingProps {
	title?: string;
	description?: string;
	className?: string;
	size?: string;
}

export const Loading = ({
	title,
	description,
	size = '60%',
	className,
}: LoadingProps) => {
	return (
		<div
			className={twMerge(
				'flex h-[20vh] w-full flex-col items-center justify-center rounded bg-white',
				className
			)}
		>
			{title && (
				<div className="mb-6 flex flex-col items-center justify-center gap-2">
					<h1 className="text-xl font-bold text-neutral-900">{title}</h1>
					{description && <p className="text-neutral-500">{description}</p>}
				</div>
			)}
			<FaSpinner className="animate-slow-spin" size={size} />
		</div>
	);
};
