import { BsBuildingFill } from 'react-icons/bs';
import { Tooltip } from '../../../../components/Tooltip';
import { User } from '../../../../services/angular/angularUserState';
import { FaAddressCard, FaMotorcycle } from 'react-icons/fa';

const companyStatus = {
	0: (
		<Tooltip message="Sem empresa">
			<BsBuildingFill className="text-neutral-400" size={20} />
		</Tooltip>
	),
	1: (
		<Tooltip message="Aguardando">
			<BsBuildingFill className="text-orange" size={20} />
		</Tooltip>
	),
	2: (
		<Tooltip message="Rejeitada">
			<BsBuildingFill className="text-red" size={20} />
		</Tooltip>
	),
	3: (
		<Tooltip message="Aprovada">
			<BsBuildingFill className="text-green" size={20} />
		</Tooltip>
	),
} as const;

const vehicleStatus = {
	0: (
		<Tooltip message="Sem veículo">
			<FaMotorcycle className="text-neutral-400" size={20} />
		</Tooltip>
	),
	1: (
		<Tooltip message="Aguardando">
			<FaMotorcycle className="text-orange" size={20} />
		</Tooltip>
	),
	2: (
		<Tooltip message="Rejeitado">
			<FaMotorcycle className="text-red" size={20} />
		</Tooltip>
	),
	3: (
		<Tooltip message="Aprovado">
			<FaMotorcycle className="text-green" size={20} />
		</Tooltip>
	),
} as const;

const driverStatus = {
	0: (
		<Tooltip message="Sem CNH">
			<FaAddressCard className="text-neutral-400" size={20} />
		</Tooltip>
	),
	1: (
		<Tooltip message="Aguardando">
			<FaAddressCard className="text-orange" size={20} />
		</Tooltip>
	),
	2: (
		<Tooltip message="Rejeitada">
			<FaAddressCard className="text-red" size={20} />
		</Tooltip>
	),
	3: (
		<Tooltip message="Aprovada">
			<FaAddressCard className="text-green" size={20} />
		</Tooltip>
	),
} as const;

interface Props {
	user?: User;
}

export const Pending = ({ user }: Props) => {
	return (
		<div className="flex gap-4">
			{driverStatus[user?.driverLicenseImg ? user?.driverStatus ?? 0 : 0]}
			{companyStatus[user?.companies?.[0]?.status ?? 0]}
			{vehicleStatus[user?.companies?.[0]?.vehicles?.[0]?.status ?? 0]}
		</div>
	);
};
