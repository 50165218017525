import { zodResolver } from '@hookform/resolvers/zod';
import { ComponentProps } from 'react';
import { z, ZodSchema } from 'zod';
import {
	useForm,
	UseFormProps as UseHookFormProps,
	FormProvider,
	UseFormReturn,
	SubmitHandler,
	UseFormHandleSubmit,
} from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface UseFormProps<T extends ZodSchema<any>>
	extends UseHookFormProps<z.input<T>> {
	schema: T;
}

export const useZodForm = <T extends ZodSchema<any>>({
	schema,
	...formConfig
}: UseFormProps<T>) => {
	const { handleSubmit, ...form } = useForm({
		...formConfig,
		resolver: zodResolver(schema),
		mode: 'all',
		reValidateMode: 'onChange',
	});

	return {
		...form,
		handleSubmit: handleSubmit as UseFormHandleSubmit<z.output<T>>,
	};
};

interface FormProps<T extends ZodSchema<any>>
	extends Omit<ComponentProps<'form'>, 'onSubmit'> {
	form: UseFormReturn<z.input<T>>;
	onSubmit: SubmitHandler<z.output<T>>;
	className?: string;
}

export const Form = <T extends ZodSchema<any>>({
	form,
	onSubmit,
	children,
	className,
	...props
}: FormProps<T>): JSX.Element => {
	return (
		<FormProvider {...form}>
			<form
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				onSubmit={form.handleSubmit(onSubmit)}
				{...props}
			>
				<fieldset
					className={twMerge('w-full', className)}
					disabled={form.formState.isSubmitting}
				>
					{children}
				</fieldset>
			</form>
		</FormProvider>
	);
};
