import React, { useState } from 'react';
import { PageHeader } from '../../../../../components/PageHeader';
import { FiltersTab } from '../../../../../components/FiltersTab';
import { Select } from '../../../../../components/FormElements/Select';
import { useAppState } from '../../../../../store/appState';
import { useZodForm } from '../../../../../components/FormElements/Form';
import {
	FilterCategoriesSchema,
	FilterCategoriesSchemaType,
} from './schema/FilterCategoriesSchema';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../../services/angular/axios';
import { CategoryCard } from './components/CategoryCard';
import { RegisterButton } from '../../../../../components/Button/RegisterButton';
import { CategoryForm } from './components/CategoryForm';
import { Category } from '../../../../../services/angular/types/Order';

export const ConfigVehicles: React.FC = () => {
	const geofence = useAppState((appState) => appState.geofence);
	const [query, setQuery] = useState<FilterCategoriesSchemaType>();
	const [isCreating, setIsCreating] = useState<boolean>(false);

	const form = useZodForm({
		schema: FilterCategoriesSchema,
	});

	const { data: categories, refetch } = useQuery(
		['vehicle-category', { ...query }],
		async () =>
			await api.get<Category[]>(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/categories`,
				{
					params: query,
				}
			)
	);

	return (
		<div>
			<PageHeader
				title={isCreating ? 'Categoria de Veículo' : 'Categoria de Veículos'}
				description={
					isCreating
						? 'Definições referentes à categoria de veículo'
						: 'Relação de categorias de veículos disponíveis'
				}
			>
				{!isCreating && (
					<RegisterButton
						onClick={() => setIsCreating(true)}
						className="w-auto"
					/>
				)}
			</PageHeader>
			{isCreating ? (
				<CategoryForm />
			) : (
				<>
					<FiltersTab
						className="w-full"
						form={form}
						clearFilters={() => {
							form.reset({
								geofenceId: [],
							});
							setQuery(undefined);
						}}
						onSubmit={(data) => {
							setQuery(data);
						}}
					>
						<Select
							variant="light"
							label="Geofence"
							isMulti
							className="w-full md:w-auto md:min-w-[128px]"
							controller={{
								control: form.control,
								name: 'geofenceId',
							}}
							options={geofence?.map((state) => ({
								label: state.name,
								value: state.id,
							}))}
						/>
					</FiltersTab>

					<div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
						{categories?.data.map((category, index) => {
							return (
								<CategoryCard
									category={category}
									refetch={() => {
										void refetch();
									}}
									key={`${category.id}-${index}`}
								/>
							);
						})}
					</div>
				</>
			)}
		</div>
	);
};
