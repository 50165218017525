import { useMutation, useQuery } from '@tanstack/react-query';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { BsFillEyeSlashFill } from 'react-icons/bs';
import { IoInformationCircleSharp } from 'react-icons/io5';
import { TbArrowBackUp, TbClock, TbTruckDelivery } from 'react-icons/tb';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import Bee from '../../../../assets/svg/Bee';
import { StatusTooltip } from '../../../../components/Angular/StatusTooltip';
import Button from '../../../../components/Button/Button';
import {
	Date,
	Form,
	FormButtons,
	FormHeader,
	Input,
	Label,
	Select,
	useZodForm,
} from '../../../../components/FormElements';
import { oldIcons, VehicleIcon } from '../../../../components/Icon/vehicles';
import { Loading } from '../../../../components/Loading';
import { PageHeader } from '../../../../components/PageHeader';
import { Tooltip } from '../../../../components/Tooltip';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import useUserState, {
	CompanyModel,
} from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import {
	// Driver,
	OrderResponseApi,
	Requester,
	RequesterCompany,
	Vehicle,
} from '../../../../services/angular/types/Order';
import { useAppState } from '../../../../store/appState';
import { VehicleCategory } from '../Settings/Categories/VehicleCategory';
import { editOrderSchema, EditOrderSchemaType } from './editOrderSchema';
import { Combobox, Transition } from '@headlessui/react';
import {
	HiOutlineChevronUpDown,
	HiOutlineExclamationTriangle,
} from 'react-icons/hi2';
import dayjs from 'dayjs';
import { ImSpinner } from 'react-icons/im';
import { canSendToPool } from '../../../../helpers/utils';
import { Avatar } from '../../../../components/Avatar';
import { AxiosError } from 'axios';
import { ErrorTooltip } from '../../../../components/Tooltip/ErrorTooltip';
import { debounce } from 'lodash';
import { CgSpinner } from 'react-icons/cg';
import { ToastError } from '../../../../helpers/errors/ToastError';

export const EditOrder = () => {
	const [companyAvatarState, setCompanyAvatar] = useState<string>();
	const [requesterAvatarState, setRequesterAvatar] = useState<string>();
	const [driverCompanyAvatarState, setDriverCompanyAvatar] = useState<string>();
	const [driverAvatarState, setDriverAvatar] = useState<string>();
	const [driverName, setDriverName] = useState<string>();
	const [requesterCompany, setRequesterCompany] = useState<string>();
	const [driverCompany, setDriverCompany] = useState<string>();
	const company = useUserState((state) => state.company);
	const geofence = useAppState((appState) => appState.geofence);

	const { orderId } = useParams();
	const { search } = useLocation();
	const queryParams = useMemo(() => new URLSearchParams(search), [search]);
	const navigate = useNavigate();

	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	const duplicatedOrder = useMemo(
		() => queryParams.get('duplicatedOrder'),
		[queryParams]
	);

	const form = useZodForm({
		schema: editOrderSchema,
	});

	const {
		reset,
		register,
		control,
		watch,
		setValue,
		getValues,
		formState: { errors, isSubmitting, isValid },
	} = form;

	const { data: order, isFetching } = useQuery(
		['edit-order', orderId],
		async () => {
			const { data } = await api.get<OrderResponseApi>(`/orders/${orderId!}`, {
				params: {
					companyId: company?.id,
					include:
						'category,status,locations,locations.protocols,locations.receiverType,optionals,requesterCompany,requester,driver,driverCompany.owner',
				},
			});

			return data;
		},
		{
			enabled: !!orderId,
			refetchOnMount: 'always',
			onError: (error) => {
				navigate('/pedidos');
				ToastError(error);
			},
			onSuccess: (data) => {
				reset({
					...data,
					requesterCompanyId: {
						...data.requesterCompany,
						owner: undefined,
						users: [data.requester],
					},
					driverCompanyId: {
						...data.driverCompany,
					},
					driverId: {
						...data.driver,
					},
					vehicleId: {
						...data.vehicle,
					},
					requesterId: { ...data.requester },
					geofenceId: {
						value: data.geofenceId,
						label: geofence?.find((g) => g.id === data.geofenceId)?.name || '',
					},
					scheduleDate: data?.scheduleDate
						? dayjs(data.scheduleDate).toISOString()
						: data?.scheduleDate,
				});
				setRequesterCompany(
					data.requesterCompany?.tradingName ||
						data.requesterCompany?.companyName
				);
				setDriverCompany(
					data.driverCompany?.tradingName || data.driverCompany?.companyName
				);
				setRequesterAvatar(data?.requester?.avatar);
				setCompanyAvatar(data?.requesterCompany?.avatar);
				setDriverAvatar(data?.driver?.avatar);
				setDriverCompanyAvatar(data?.driverCompany?.avatar);
			},
		}
	);

	const { data: vehicles } = useQuery(
		['vehicles', watch('geofenceId')],
		async () => {
			if (!watch('geofenceId')) return;
			const { data } = await api.get<VehicleCategory[]>(`/categories`, {
				params: {
					include: 'optionals',
					geofenceId: watch('geofenceId').value,
				},
			});

			return data;
		},
		{
			enabled: !!watch('geofenceId'),
		}
	);

	const { data: companyWithVehicles } = useQuery(
		['edit-company', Number(form.getValues('driverCompanyId')?.id)],
		async () =>
			await api.get<CompanyModel>(
				`companies/${
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					form.getValues('driverCompanyId')?.id
				}?include=vehicles.category,vehicles.activeOrders,vehicles.currentDriver,favoriteDrivers`
			),
		{
			enabled: !!form.watch('driverCompanyId.id'),
		}
	);

	useEffect(() => {
		if (companyWithVehicles?.data?.vehicles?.length === 1) {
			setValue('vehicleId', companyWithVehicles?.data?.vehicles[0]);
		} else {
			setValue('vehicleId', null);
		}
	}, [companyWithVehicles]);

	const { data: companies, isFetching: fetchingCompanies } = useQuery(
		['companies', requesterCompany],
		async () => {
			const { data } = await api.get<RequesterCompany[]>(`/companies`, {
				params: {
					name: requesterCompany,
					include: 'users,owner',
					approved: true,
				},
			});

			return data;
		},
		{
			enabled: !!requesterCompany,
			cacheTime: 0,
			staleTime: 0,
			onSuccess: (data) => {
				const company = data.find(
					(c) => c.id === getValues('requesterCompanyId').id
				);

				if (company) setValue('requesterCompanyId', company as any);
			},
		}
	);

	const { data: driverCompanies } = useQuery(
		['driver-companies', driverCompany],
		async () => {
			const { data } = await api.get<RequesterCompany[]>(`/companies`, {
				params: {
					name: driverCompany,
					include: 'drivers.vehicle,vehicles',
					approved: true,
				},
			});

			return data;
		},
		{
			enabled: !!driverCompany,
			cacheTime: 0,
			staleTime: 0,
			onSuccess: (data) => {
				const company = data.find(
					(c) => c.id === getValues('driverCompanyId')?.id
				);

				if (company) {
					setValue('driverCompanyId', company as any);
				}
			},
		}
	);

	const { data: companyAvatar } = useQuery(
		['company-avatar', companyAvatarState],
		async () => {
			if (!companyAvatarState) return;
			return await api.get(`/assets?`, {
				params: {
					imageName: companyAvatarState,
				},
			});
		},
		{
			enabled: !!companyAvatarState,
		}
	);

	const { data: userAvatar } = useQuery(
		['user-avatar', requesterAvatarState],
		async () => {
			if (!requesterAvatarState) return;
			return await api.get(`/assets?`, {
				params: {
					imageName: requesterAvatarState,
				},
			});
		},
		{
			enabled: !!requesterAvatarState,
		}
	);

	const { data: driverAvatar } = useQuery(
		['driver-avatar', driverAvatarState],
		async () => {
			if (!driverAvatarState) return;
			return await api.get(`/assets?`, {
				params: {
					imageName: driverAvatarState,
				},
			});
		},
		{
			enabled: !!driverAvatarState,
		}
	);

	const { data: driverCompanyAvatar } = useQuery(
		['driver-company-avatar', driverCompanyAvatarState],
		async () => {
			if (!driverCompanyAvatarState) return;
			return await api.get(`/assets?`, {
				params: {
					imageName: driverCompanyAvatarState,
				},
			});
		},
		{
			enabled: !!driverCompanyAvatarState,
		}
	);

	const { mutate: sendToPool } = useMutation(
		async (orderId: number) => {
			await api.put(`/orders/${orderId}/backToPool`);
		},
		{
			onSuccess: () => {
				toast.success('Frete enviado para a pool!');
				navigate('/operacoes');
			},
			onError: (error) => {
				ToastError(error);
			},
		}
	);

	const { mutate: updateOrder } = useMutation(
		async (order: EditOrderSchemaType) => {
			await api.patch(`/orders/${order.id}`, order);

			if (duplicatedOrder === 'true') void sendToPool(order.id);
		},
		{
			onError: (error: AxiosError<any>) => {
				ToastError(error);
			},
			onSuccess: () => {
				toast.success('Pedido atualizado com sucesso!');
				navigate('/operacoes');
			},
		}
	);

	const selectedVehicle = useMemo(
		() => vehicles?.find((v) => v.id === watch('categoryId')),
		[watch('categoryId'), vehicles]
	);

	const requesters = useMemo(() => {
		const requesterCompany = getValues('requesterCompanyId');

		if (!requesterCompany?.owner) return;

		return [requesterCompany.owner, ...requesterCompany.users].filter(
			(r) => !!r
		) as Requester[];
	}, [watch('requesterCompanyId')]);

	const requesterRef = useRef<any>(null);

	const searchCompanies = (value: string) => {
		clearTimeout(requesterRef.current);
		requesterRef.current = setTimeout(() => {
			setRequesterCompany(value);
		}, 1000);
	};

	const {
		data: drivers,
		isLoading: driverIsLoading,
		isFetching: driverIsFetching,
	} = useQuery(
		['drivers', driverName],
		async () =>
			// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
			await api.get(`/deliverers?include=companies&name=${driverName}`)
	);

	const selectedDriverCompanies = drivers?.data.find(
		(driver: any) => driver.id === watch('driverId')?.id
	)?.companies;

	const driverRef = useRef<any>(null);

	const searchDriverCompanies = (value: string) => {
		clearTimeout(driverRef.current);
		driverRef.current = setTimeout(() => {
			setDriverCompany(value);
		}, 1000);
	};

	const vehicleIcon = useMemo(() => {
		const vehicleType = vehicles?.find(
			(v) => v.id === watch('vehicleId')?.categoryId
		);

		return vehicleType?.icon;
	}, [watch('vehicleId')]);

	const { data: companiesAvatar } = useQuery(
		['companies-avatar', companies?.map((c) => c.avatar)],
		async () =>
			await Promise.all(
				companies!.map(async (c) => {
					if (!c.avatar) return;

					const { data } = await api.get('/assets', {
						params: {
							imageName: c.avatar,
						},
					});

					const imageUrl: string = data?.url;

					return imageUrl;
				})
			),
		{
			enabled: !!companies,
		}
	);

	const { data: requestersAvatar } = useQuery(
		['requesters-avatar', requesters?.map((c) => c.avatar)],
		async () =>
			await Promise.all(
				requesters!.map(async (c) => {
					if (!c.avatar) return;

					const { data } = await api.get('/assets', {
						params: {
							imageName: c.avatar,
						},
					});

					const imageUrl: string = data?.url;

					return imageUrl;
				})
			),
		{
			enabled: !!requesters,
		}
	);

	const { data: driverCompaniesAvatar } = useQuery(
		['driver-companies-avatar', driverCompanies?.map((c) => c.avatar)],
		async () =>
			await Promise.all(
				driverCompanies!.map(async (c) => {
					if (!c.avatar) return;

					const { data } = await api.get('/assets', {
						params: {
							imageName: c.avatar,
						},
					});

					const imageUrl: string = data?.url;

					return imageUrl;
				})
			),
		{
			enabled: !!driverCompanies,
		}
	);

	const { data: driversAvatar } = useQuery(
		[
			'drivers-avatar',
			getValues('driverCompanyId')?.drivers?.map((c) => c.avatar),
		],
		async () =>
			await Promise.all(
				getValues('driverCompanyId')!.drivers!.map(async (c) => {
					if (!c.avatar) return;

					const { data } = await api.get('/assets', {
						params: {
							imageName: c.avatar,
						},
					});

					const imageUrl: string = data?.url;

					return imageUrl;
				})
			),
		{
			enabled: !!getValues('driverCompanyId')?.drivers,
		}
	);

	const debouncedHandleInputChange = debounce((value) => {
		setDriverName(value);
	}, 2000);

	if (!orderId) {
		navigate(-1);
		toast.error('Pedido não encontrado');
		return null;
	}

	if (isFetching) return <Loading title="Carregando pedido a ser editado" />;

	return (
		<Form
			form={form}
			onSubmit={(data) => {
				updateOrder(data);
			}}
			className="mb-20 flex flex-col gap-4"
		>
			<PageHeader
				className="mb-2"
				title={`Edição de Pedido #${orderId}`}
				description="Alterar dos dados do frete"
			>
				<StatusTooltip order={order} />
			</PageHeader>
			<div className="flex flex-wrap items-center gap-4">
				<Button
					className="w-auto"
					onClick={() => navigate(`/pedidos/${orderId}`)}
				>
					<IoInformationCircleSharp size={20} /> Ver detalhes
				</Button>
				{canSendToPool(order) && (
					<Button
						className="w-auto"
						onClick={() => {
							sendToPool(Number(orderId));
						}}
					>
						<TbArrowBackUp size={20} />
						Enviar para pool
					</Button>
				)}
			</div>
			<div className="flex flex-col gap-4 rounded bg-white px-4 py-4 md:px-8">
				<div className="flex flex-col gap-4 md:flex-row">
					<div className="flex w-full flex-col items-center gap-1">
						<FormHeader
							title="Geofence do pedido"
							description="Selecione a geofence do pedido"
						/>
						<Select
							controller={{
								control,
								name: 'geofenceId',
							}}
							className="max-w-[256px]"
							options={geofence?.map((opt) => ({
								label: opt.name,
								value: opt.id,
							}))}
							errorMessage={errors.geofenceId?.message}
						/>
					</div>
					<hr className="my-2 block text-neutral-200 md:hidden" />
					<div className="flex w-full flex-col items-center justify-center gap-4">
						<div className="text-center">
							<h1 className="text-xl font-bold text-neutral-900">
								{watch('scheduleDate') !== undefined
									? watch('scheduleDate')
										? 'Agendado'
										: 'Imediato'
									: 'Quando começar'}
							</h1>
							<p className="ml-1 text-neutral-500">
								{watch('scheduleDate')
									? watch('scheduleDate')
										? 'Frete começa na data e hora programadas.'
										: 'O frete começa imediatamente'
									: 'Frete imediato ou agendado?'}
							</p>
						</div>
						<div className="flex items-center justify-center gap-12">
							<div
								className={twMerge(
									'group flex flex-col items-center justify-center text-center',
									'cursor-pointer transition-colors hover:text-neutral-0',
									watch('scheduleDate') === null
										? 'text-neutral-0'
										: 'text-neutral-200'
								)}
								onClick={() => {
									setValue('scheduleDate', null);
								}}
							>
								<TbTruckDelivery
									className={twMerge(
										'transition-colors group-hover:text-neutral-0',
										watch('scheduleDate') === null
											? 'text-neutral-0'
											: 'text-neutral-200'
									)}
									size={72}
								/>
								Imediato
							</div>

							<div
								className={twMerge(
									'group relative flex flex-col items-center justify-center text-center',
									'cursor-pointer transition-colors hover:text-neutral-0',
									watch('scheduleDate') ? 'text-neutral-0' : 'text-neutral-200'
								)}
								onClick={() => {
									setValue('scheduleDate', 'schedule');
								}}
							>
								{!!selectedVehicle?.scheduledDiscount && (
									<div className="absolute -right-8 bottom-6 w-full">
										<Tooltip message="Economize agendando seu frete">
											<span
												className={twMerge(
													'rounded-full px-2 py-1 text-xs font-bold text-white',
													'transition-colors group-hover:bg-terracota-600',
													watch('scheduleDate')
														? 'bg-terracota-600'
														: 'bg-terracota-400'
												)}
											>
												{selectedVehicle.scheduledDiscount}% OFF
											</span>
										</Tooltip>
									</div>
								)}
								<TbClock size={72} />
								Agendado
							</div>
						</div>
						<span className={watch('scheduleDate') ? '' : 'hidden'}>
							<Date
								className="w-full max-w-[256px]"
								label="Data do Frete"
								control={control}
								name="scheduleDate"
								minDate={dayjs().toDate()}
								time
								errorMessage={errors?.scheduleDate?.message?.toString()}
							/>
						</span>
					</div>
				</div>
				<hr className="my-2 text-neutral-200" />
				<div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
					<Controller
						control={control}
						name="categoryId"
						render={({ field }) => (
							<>
								{vehicles
									?.sort((a, b) => Number(a.weight) - Number(b.weight))
									.map((vehicle) => {
										return (
											<WhiteContainer
												key={vehicle.id}
												className={twMerge(
													'group flex h-full cursor-pointer items-center shadow transition-all hover:bg-yellow-100/50 hover:opacity-100 hover:ring-2',
													field.value === vehicle.id
														? 'ring-2 ring-blue'
														: 'opacity-50'
												)}
												onClick={(e) => {
													e.stopPropagation();
													field.value !== vehicle.id &&
														field.onChange(vehicle.id);

													setValue('vehicleId', null);
												}}
											>
												{!vehicle.active && (
													<Tooltip
														message="Esta categoria está desativada e não aparecerá para novos pedidos."
														triggerClassName="absolute right-2 top-1 text-neutral-300"
													>
														<BsFillEyeSlashFill size={22} />
													</Tooltip>
												)}
												{field.value === vehicle.id &&
												vehicle.typeOneActive &&
												vehicle.typeTwoActive ? (
													<Controller
														control={control}
														name="categoryType"
														defaultValue={0}
														shouldUnregister
														render={({ field }) => (
															<>
																<p className="mb-2 text-center text-lg font-bold">
																	Selecione uma subcategoria
																</p>
																<div className="grid h-full w-full grid-cols-3 place-items-center gap-2">
																	<Tooltip
																		message={
																			vehicle.typeOneTooltip ||
																			vehicle.typeOneDescription
																		}
																		triggerClassName="cursor-pointer self-start"
																	>
																		<div
																			className={twMerge(
																				'flex flex-col items-center justify-center text-center transition-colors hover:text-blue',
																				field.value === 1
																					? 'text-blue'
																					: 'text-neutral-200'
																			)}
																			onClick={(e) => {
																				e.stopPropagation();
																				field.onChange(1);
																			}}
																		>
																			<VehicleIcon
																				/* @ts-expect-error */
																				name={oldIcons[vehicle.typeOneIcon]}
																				size={72}
																			/>
																			{vehicle.typeOneDescription}
																		</div>
																	</Tooltip>
																	<Tooltip
																		message="Mais entregadores, menor espera"
																		triggerClassName="cursor-pointer self-start"
																	>
																		<div
																			className={twMerge(
																				'flex flex-col items-center justify-center text-center transition-colors hover:text-blue',
																				field.value === 0
																					? 'text-blue'
																					: 'text-neutral-400'
																			)}
																			onClick={(e) => {
																				e.stopPropagation();
																				field.onChange(0);
																			}}
																		>
																			<span className="h-[72px] w-[72px]">
																				<Bee />
																			</span>
																			Tanto faz
																		</div>
																	</Tooltip>
																	<Tooltip
																		message={
																			vehicle.typeTwoTooltip ||
																			vehicle.typeTwoDescription
																		}
																		triggerClassName="cursor-pointer self-start"
																	>
																		<div
																			className={twMerge(
																				'flex flex-col items-center justify-center text-center transition-colors hover:text-blue',
																				field.value === 2
																					? 'text-blue'
																					: 'text-neutral-400'
																			)}
																			onClick={(e) => {
																				e.stopPropagation();
																				field.onChange(2);
																			}}
																		>
																			<VehicleIcon
																				/* @ts-expect-error */
																				name={oldIcons[vehicle.typeTwoIcon]}
																				size={72}
																			/>
																			{vehicle.typeTwoDescription}
																		</div>
																	</Tooltip>
																</div>
															</>
														)}
													/>
												) : (
													<>
														<p className="text-lg font-bold text-neutral-0 ">
															{vehicle.name}
														</p>
														<VehicleIcon
															/* @ts-expect-error */
															name={oldIcons[vehicle.icon]}
															size={72}
														/>
														<div className="flex w-full flex-col items-center justify-center text-center text-xs">
															<span>
																Dimensões:{' '}
																{`${vehicle.height || ''} x ${
																	vehicle.width || ''
																} x ${vehicle.length || ''}`}
															</span>

															<span>
																Capacidade máxima: {vehicle.weight || ''}kg
															</span>
															<span>{vehicle.weightDetails}</span>
														</div>
													</>
												)}
											</WhiteContainer>
										);
									})}
							</>
						)}
					/>
				</div>
				<hr className="my-2 text-neutral-200" />
				<div className="flex flex-col gap-4">
					<FormHeader
						title="Valores"
						description="Valor do frete, comissão do motorista"
					/>
					<Input
						className="w-full md:w-1/4"
						label="Dinâmico (Motorista):"
						disabled
						{...register('driverBonus')}
						leftInputIcon={<span className="text-sm text-neutral-500">R$</span>}
					/>
					<div className="flex w-full flex-col gap-4 md:flex-row ">
						<Input
							label="Valor total cobrado"
							{...register('total')}
							type="number"
							step="any"
							leftInputIcon={
								<span className="text-sm text-neutral-500">R$</span>
							}
							errorMessage={errors.total?.message}
						/>
						<Input
							label="Comissão do motorista"
							{...register('driverComission')}
							type="number"
							step="any"
							leftInputIcon={
								<span className="text-sm text-neutral-500">R$</span>
							}
							errorMessage={errors.driverComission?.message}
						/>
						<div className="flex w-full flex-col">
							<Input
								label="Bônus do motorista"
								{...register('individualDriverBonus')}
								type="number"
								step="any"
								leftInputIcon={
									<span className="text-sm text-neutral-500">R$</span>
								}
								errorMessage={errors.individualDriverBonus?.message}
							/>
							<p className="text-sm text-terracota-500">
								Diferença entre total e comissão{' '}
								{(
									form.getValues('total') - form.getValues('driverComission')
								).toLocaleString('pt-br', {
									style: 'currency',
									currency: 'BRL',
								})}
							</p>
						</div>
					</div>
				</div>
				<hr className="my-2 text-neutral-200" />
				{order?.statusId !== 1 && (
					<>
						<div className="relative flex flex-col gap-4">
							<FormHeader
								title="Cliente"
								description="Informações do cliente do frete"
							/>
							<div className="flex flex-col gap-4 md:flex-row">
								<Controller
									control={control}
									name="requesterCompanyId"
									render={({ field }) => (
										<Combobox
											as="span"
											className="w-full"
											value={field.value}
											onChange={(value: any) => {
												field.onChange(value);
												setValue('requesterId', null);
												setCompanyAvatar(value?.avatar);
											}}
										>
											<div className="flex gap-2">
												<Label label="Empresa" variant="primary" />
												{errors.requesterCompanyId?.message && (
													<ErrorTooltip
														message={errors.requesterCompanyId?.message}
													/>
												)}
											</div>
											<div className="relative mt-1 w-full">
												<div className="flex w-full rounded border border-neutral-100 px-3 py-2 text-sm text-neutral-700 placeholder-neutral-500 outline-2 -outline-offset-2 focus-within:outline">
													<Avatar
														avatarProps={{
															className: 'w-6 h-6 mr-2',
														}}
														src={companyAvatar?.data?.url}
													/>
													<Combobox.Input
														className="bg-transparent w-full focus:outline-none"
														onChange={(event) => {
															searchCompanies(event.target.value);
														}}
														displayValue={(company: RequesterCompany) =>
															company?.tradingName || company?.companyName
														}
													/>
													<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
														<HiOutlineChevronUpDown
															className="h-5 w-5 text-neutral-400"
															aria-hidden="true"
														/>
													</Combobox.Button>
												</div>
												<Transition
													as={Fragment}
													leave="transition ease-in duration-100"
													leaveFrom="opacity-100"
													leaveTo="opacity-0"
												>
													<Combobox.Options className="absolute bottom-11 z-auto max-h-48 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
														{fetchingCompanies ? (
															<>
																<Combobox.Option
																	value={null}
																	className={({ active }) =>
																		`relative flex cursor-pointer select-none gap-4 py-2 pl-10 pr-4 ${
																			active
																				? 'bg-neutral-400 text-white'
																				: 'text-neutral-700'
																		}`
																	}
																>
																	<ImSpinner className="h-5 w-5 animate-slow-spin" />
																	<span className="text-sm">
																		Buscando empresas...
																	</span>
																</Combobox.Option>
															</>
														) : (
															<>
																{companies?.length ? (
																	companies?.map((company, index) => (
																		<Combobox.Option
																			key={company.id}
																			value={company}
																			className={({ active }) =>
																				`relative cursor-pointer select-none py-2 pl-10 pr-4 ${
																					active
																						? 'bg-neutral-400 text-white'
																						: 'text-neutral-700'
																				}`
																			}
																		>
																			<Avatar
																				avatarProps={{
																					className: 'w-6 h-6 mr-2',
																				}}
																				src={companiesAvatar?.[index]}
																			/>
																			{company.tradingName ||
																				company.companyName}
																		</Combobox.Option>
																	))
																) : (
																	<>
																		<Combobox.Option
																			value={null}
																			className={({ active }) =>
																				`relative flex cursor-pointer select-none gap-4 py-2 pl-10 pr-4 ${
																					active
																						? 'bg-neutral-400 text-white'
																						: 'text-neutral-700'
																				}`
																			}
																		>
																			<HiOutlineExclamationTriangle className="h-5 w-5" />
																			<span className="text-sm">
																				Nenhuma empresa encontrada
																			</span>
																		</Combobox.Option>
																	</>
																)}
															</>
														)}
													</Combobox.Options>
												</Transition>
											</div>
										</Combobox>
									)}
								/>

								<Controller
									control={control}
									name="requesterId"
									render={({ field }) => (
										<Combobox
											as="span"
											className="w-full"
											value={field.value}
											onChange={field.onChange}
											nullable
										>
											<div className="flex gap-2">
												<Label label="Usuário" variant="primary" />
												{errors.requesterId?.message && (
													<ErrorTooltip message={errors.requesterId?.message} />
												)}
											</div>
											<div className="relative mt-1 w-full">
												<div className="flex w-full rounded border border-neutral-100 px-3 py-2 text-sm text-neutral-700 placeholder-neutral-500 outline-2 -outline-offset-2 focus-within:outline">
													<Avatar
														avatarProps={{
															className: 'w-6 h-6 mr-2',
														}}
														src={userAvatar?.data?.url}
													/>
													<Combobox.Input
														className="bg-transparent w-full focus:outline-none"
														onChange={(event) => {}}
														displayValue={(requester: Requester) =>
															requester?.name || ''
														}
													/>
													<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
														<HiOutlineChevronUpDown
															className="h-5 w-5 text-neutral-400"
															aria-hidden="true"
														/>
													</Combobox.Button>
												</div>
												<Transition
													as={Fragment}
													leave="transition ease-in duration-100"
													leaveFrom="opacity-100"
													leaveTo="opacity-0"
												>
													<Combobox.Options className="absolute bottom-11 z-auto max-h-48 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
														{requesters?.map((requester, index) => (
															<Combobox.Option
																key={requester.id}
																value={requester}
																className={({ active }) =>
																	`relative cursor-pointer select-none py-2 pl-10 pr-4 ${
																		active
																			? 'bg-neutral-400 text-white'
																			: 'text-neutral-700'
																	}`
																}
															>
																<Avatar
																	avatarProps={{
																		className: 'w-6 h-6 mr-2',
																	}}
																	src={requestersAvatar?.[index]}
																/>
																{requester.name}
															</Combobox.Option>
														))}
													</Combobox.Options>
												</Transition>
											</div>
										</Combobox>
									)}
								/>
							</div>
						</div>

						<hr className="my-2 text-neutral-200" />

						<div className="relative flex flex-col gap-4">
							<FormHeader
								title="Fretista"
								description="Empresa, motorista e veículo usado para entregar o frete"
							/>
							<div className="flex flex-col gap-4 md:flex-row">
								<Controller
									control={control}
									name="driverId"
									render={({ field }) => (
										<Combobox
											as="span"
											className="w-full"
											value={field.value}
											onChange={(value) => {
												field.onChange(value);
												// @ts-expect-error
												if (value?.companies?.length === 1) {
													// @ts-expect-error
													setValue('driverCompanyId', value.companies[0]);
												} else {
													setValue('driverCompanyId', null);
													setValue('vehicleId', null);
												}
											}}
											nullable
										>
											<div className="flex gap-2">
												<Label label="Motorista" variant="primary" />
												{errors.driverId?.message && (
													<ErrorTooltip message={errors.driverId?.message} />
												)}
											</div>
											<div className="relative mt-1 w-full">
												<div className="flex w-full rounded border border-neutral-100 px-3 py-2 text-sm text-neutral-700 placeholder-neutral-500 outline-2 -outline-offset-2 focus-within:outline">
													<Avatar
														avatarProps={{
															className: 'w-6 h-6 mr-2',
														}}
														src={driverAvatar?.data?.url}
													/>
													<Combobox.Input
														className="bg-transparent w-full focus:outline-none"
														onChange={(event) => {
															const inputValue = event.target.value;
															debouncedHandleInputChange(inputValue);
														}}
														displayValue={(requester: Requester | undefined) =>
															requester?.name || ''
														}
													/>
													{(driverIsLoading || driverIsFetching) && (
														<span className="m-auto text-blue">
															<CgSpinner
																className="mr-4 animate-spin"
																size={20}
															/>
														</span>
													)}
													<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
														<HiOutlineChevronUpDown
															className="h-5 w-5 text-neutral-400"
															aria-hidden="true"
														/>
													</Combobox.Button>
												</div>
												<Transition
													as={Fragment}
													leave="transition ease-in duration-100"
													leaveFrom="opacity-100"
													leaveTo="opacity-0"
												>
													<Combobox.Options className="absolute bottom-11 z-auto max-h-48 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
														{drivers?.data ? (
															drivers?.data.map(
																(driver: any, index: number) => (
																	<Combobox.Option
																		key={driver.id}
																		value={driver}
																		className={({ active }) =>
																			`relative cursor-pointer select-none py-2 pl-10 pr-4 ${
																				active
																					? 'bg-neutral-400 text-white'
																					: 'text-neutral-700'
																			}`
																		}
																	>
																		<Avatar
																			avatarProps={{
																				className: 'w-6 h-6 mr-2',
																			}}
																			src={driversAvatar?.[index]}
																		/>
																		{driver.name}
																	</Combobox.Option>
																)
															)
														) : (
															<>
																<Combobox.Option
																	value={null}
																	className={({ active }) =>
																		`relative flex cursor-pointer select-none gap-4 py-2 pl-10 pr-4 ${
																			active
																				? 'bg-neutral-400 text-white'
																				: 'text-neutral-700'
																		}`
																	}
																>
																	<HiOutlineExclamationTriangle className="h-5 w-5" />
																	<span className="text-sm">
																		Nenhum motorista encontrado
																	</span>
																</Combobox.Option>
															</>
														)}
													</Combobox.Options>
												</Transition>
											</div>
										</Combobox>
									)}
								/>
								<Controller
									control={control}
									name="driverCompanyId"
									render={({ field }) => (
										<Combobox
											as="span"
											className="w-full"
											value={field.value}
											onChange={(value: any) => {
												field.onChange(value);
												if (companyWithVehicles?.data?.vehicles?.length === 1) {
													setValue(
														'vehicleId',
														companyWithVehicles?.data?.vehicles[0]
													);
												} else {
													setValue('vehicleId', null);
												}
											}}
										>
											<div className="flex gap-2">
												<Label label="Empresa fretista" variant="primary" />
												{errors.driverCompanyId?.message && (
													<ErrorTooltip
														message={errors.driverCompanyId?.message}
													/>
												)}
											</div>
											<div className="relative mt-1 w-full">
												<div className="flex w-full rounded border border-neutral-100 px-3 py-2 text-sm text-neutral-700 placeholder-neutral-500 outline-2 -outline-offset-2 focus-within:outline">
													<Avatar
														avatarProps={{
															className: 'w-6 h-6 mr-2',
														}}
														src={driverCompanyAvatar?.data?.url}
													/>
													<Combobox.Input
														className="bg-transparent w-full focus:outline-none"
														onChange={(event) => {
															searchDriverCompanies(event.target.value);
														}}
														displayValue={(company: RequesterCompany) =>
															company?.tradingName || company?.companyName
														}
													/>
													<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
														<HiOutlineChevronUpDown
															className="h-5 w-5 text-neutral-400"
															aria-hidden="true"
														/>
													</Combobox.Button>
												</div>
												<Transition
													as={Fragment}
													leave="transition ease-in duration-100"
													leaveFrom="opacity-100"
													leaveTo="opacity-0"
												>
													<Combobox.Options className="absolute bottom-11 z-auto max-h-48 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
														{selectedDriverCompanies?.map(
															(company: any, index: number) => (
																<Combobox.Option
																	key={company.id}
																	value={company}
																	className={({ active }) =>
																		`relative cursor-pointer select-none py-2 pl-10 pr-4 ${
																			active
																				? 'bg-neutral-400 text-white'
																				: 'text-neutral-700'
																		}`
																	}
																>
																	<Avatar
																		avatarProps={{
																			className: 'w-6 h-6 mr-2',
																		}}
																		src={driverCompaniesAvatar?.[index]}
																	/>
																	{company.tradingName || company.companyName}
																</Combobox.Option>
															)
														)}
													</Combobox.Options>
												</Transition>
											</div>
										</Combobox>
									)}
								/>

								<Controller
									control={control}
									name="vehicleId"
									render={({ field }) => (
										<Combobox
											as="span"
											className="w-full"
											value={field.value}
											onChange={(value) => {
												field.onChange(value);
												setValue('categoryId', value?.categoryId || null);
											}}
											nullable
										>
											<div className="flex gap-2">
												<Label label="Veículo" variant="primary" />
												{errors.vehicleId?.message && (
													<ErrorTooltip message={errors.vehicleId?.message} />
												)}
											</div>
											<div className="relative mt-1 w-full">
												<div className="flex w-full rounded border border-neutral-100 px-3 py-2 text-sm text-neutral-700 placeholder-neutral-500 outline-2 -outline-offset-2 focus-within:outline">
													{vehicleIcon && (
														<span className="mr-2">
															<VehicleIcon
																/* @ts-expect-error */
																name={oldIcons[vehicleIcon]}
																size={24}
															/>
														</span>
													)}
													<Combobox.Input
														className="bg-transparent w-full focus:outline-none"
														onChange={(event) => {}}
														displayValue={(vehicle: Vehicle | undefined) =>
															vehicle?.plate
																? `${vehicle.make || ''} - ${
																		vehicle.model || ''
																  } - ${vehicle.plate || ''}`
																: ''
														}
													/>
													<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
														<HiOutlineChevronUpDown
															className="h-5 w-5 text-neutral-400"
															aria-hidden="true"
														/>
													</Combobox.Button>
												</div>
												<Transition
													as={Fragment}
													leave="transition ease-in duration-100"
													leaveFrom="opacity-100"
													leaveTo="opacity-0"
												>
													<Combobox.Options className="absolute bottom-11 z-auto max-h-48 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
														{companyWithVehicles?.data?.vehicles ? (
															companyWithVehicles?.data?.vehicles.map(
																(vehicle: Vehicle, index: number) => (
																	<Combobox.Option
																		key={vehicle.id}
																		value={vehicle}
																		className={({ active }) =>
																			`relative flex cursor-pointer select-none items-center gap-4 py-2 pl-10 pr-4 ${
																				active
																					? 'bg-neutral-400 text-white'
																					: 'text-neutral-700'
																			}`
																		}
																	>
																		<VehicleIcon
																			/* @ts-expect-error */
																			name={oldIcons[vehicle.category.icon]}
																			size={24}
																		/>

																		{`${vehicle.make || ''} - ${
																			vehicle.model || ''
																		} - ${vehicle.plate || ''}`}
																	</Combobox.Option>
																)
															)
														) : (
															<>
																<Combobox.Option
																	value={null}
																	className={({ active }) =>
																		`relative flex cursor-pointer select-none gap-4 py-2 pl-10 pr-4 ${
																			active
																				? 'bg-neutral-400 text-white'
																				: 'text-neutral-700'
																		}`
																	}
																>
																	<HiOutlineExclamationTriangle className="h-5 w-5" />
																	<span className="text-sm">
																		Nenhum veículo encontrado
																	</span>
																</Combobox.Option>
															</>
														)}
													</Combobox.Options>
												</Transition>
											</div>
										</Combobox>
									)}
								/>
							</div>
						</div>
					</>
				)}
			</div>
			<FormButtons
				onCancel={() => {
					navigate('/operacoes');
				}}
				saveProps={{
					disabled: !isValid && isSubmitting,
				}}
				saveLabel="Salvar alterações"
			/>
		</Form>
	);
};
