import axios from 'axios';
import useUserState from './angularUserState';
import { ToastError } from '../../helpers/errors/ToastError';
import { useApiVersionStore } from './phpVersion';

export const api = axios.create({
	baseURL: process.env.REACT_APP_PHP_API_URL,
	headers: {
		'Content-type': 'application/json',
		'Content-Encoding': 'identity',
	},
});

api.interceptors.request.use((config) => {
	const { token } = useUserState.getState();
	const { version } = useApiVersionStore.getState();

	config.baseURL =
		version === 'v1'
			? config.baseURL?.replace('v2', 'v1')
			: config.baseURL?.replace('v1', 'v2');

	if (token) config.headers.Authorization = `Bearer ${token}`;

	return config;
});

api.interceptors.response.use(
	async (response) => {
		return response;
	},
	async (error) => {
		if (error.response?.status === 401) {
			const { logout, isLogged } = useUserState.getState();
			if (isLogged) {
				ToastError(error);
				void logout();
			}
		} else return await Promise.reject(error);
	}
);

export const integrationApi = axios.create({
	baseURL: process.env.REACT_APP_INTEGRATION_API_URL,
	headers: {
		'Content-type': 'application/json',
	},
});

integrationApi.interceptors.request.use((config) => {
	const { token } = useUserState.getState();

	if (token) config.headers.Authorization = `Bearer ${token}`;

	return config;
});

integrationApi.interceptors.response.use(
	async (response) => {
		return response;
	},
	async (error) => {
		if (error.response?.status === 401) {
			ToastError(error);
			// const { logout, isLogged } = useUserState.getState();
			// if (isLogged) {
			// 	void logout();
			// }
		} else return await Promise.reject(error);
	}
);
