import { BsFillStarFill } from 'react-icons/bs';
import { useQuery } from '@tanstack/react-query';
import Button from '../../../../components/Button/Button';
import { toast } from 'react-toastify';
import { RegisterButton } from '../../../../components/Button/RegisterButton';
import { PageHeader } from '../../../../components/PageHeader';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import { useState } from 'react';
import AddReceiptForm from './components/AddReceiptForm';
import { selectBanks } from '../../../../store/bankCodes';

export const Receipt = () => {
	const [addReceiptForm, setAddReceiptForm] = useState(false);
	const company = useUserState((state) => state.company);

	const { data, refetch } = useQuery(
		['receipt', company?.id],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/companies/${company?.id}/receipt-accounts`
			)
	);

	const deleteReceipt = async (id: number) => {
		try {
			await api.delete(
				`/companies/${company?.id || ''}/receipt-accounts/${id}`
			);
			toast.success('Meio de recebimento excluído com sucesso');
			void refetch();
		} catch (error) {
			toast.error('Erro ao excluir meio de recebimento');
		}
	};

	return (
		<div>
			<PageHeader
				title="Meios de Recebimento"
				description="Relação de contas bancárias cadastradas"
			>
				{!addReceiptForm && (
					<RegisterButton
						className="w-auto"
						onClick={() => setAddReceiptForm(true)}
					/>
				)}
			</PageHeader>
			{addReceiptForm ? (
				<AddReceiptForm setAddReceiptForm={setAddReceiptForm} />
			) : (
				<div className="grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
					{data?.data.map((item: any) => (
						<WhiteContainer
							key={item.id}
							className="relative flex flex-col gap-4"
						>
							<BsFillStarFill
								size={20}
								className="absolute right-4 top-4 text-neutral-300"
							/>
							<h1 className="text-bold text-lg text-orange">{item.title}</h1>
							<div>
								<span className="flex items-center gap-1">
									Banco:
									<p className="font-bold">
										{selectBanks.find(
											(bank) => bank.value === Number(item.bankNumber)
										)?.label || item.bankNumber}
									</p>
								</span>
								<span className="flex items-center gap-1">
									Agencia:
									<p className="font-bold">{item.agency}</p>
								</span>
								<span className="flex items-center gap-1">
									Conta: <p className="font-bold">{item.account}</p>
								</span>
							</div>
							<div>
								<span className="flex items-center gap-1">
									Titular:
									<p className="font-bold">{item.accountOwner}</p>
								</span>
								<span className="flex items-center gap-1">
									CPF: <p className="font-bold">{item.accountOwnerCpf}</p>
								</span>
							</div>
							<div className="flex justify-end gap-4">
								<Button
									className="w-auto"
									variant="red"
									onClick={() => {
										void deleteReceipt(item.id);
									}}
								>
									Remover
								</Button>
								<Button className="w-auto">Favoritar</Button>
							</div>
						</WhiteContainer>
					))}
				</div>
			)}
		</div>
	);
};
