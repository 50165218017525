import RSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import { Controller, UseControllerProps } from 'react-hook-form';
import { Variants } from './variants';
import { ErrorTooltip } from '../Tooltip/ErrorTooltip';
import { Label } from './Label';
import { InfoTooltip } from '../Tooltip/InfoTooltip';
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager';
import { AsyncAdditionalProps } from 'react-select/dist/declarations/src/useAsync';
import { twMerge } from 'tailwind-merge';

export interface Item {
	label?: string | JSX.Element;
	value: any;
	disabled?: boolean;
}

interface SelectProps<T extends object> extends StateManagerProps {
	label?: string;
	infoMessage?: string;
	variant?: Variants;
	className?: string;
	containerClassName?: string;
	controller?: UseControllerProps<T>;
	errorMessage?: string;
	asyncProps?: AsyncAdditionalProps<any, any>;
	isTagField?: boolean;
	truncate?: boolean;
}

const customStyle = {
	option: (styles: any, { isFocused }: any) => {
		return {
			...styles,
			backgroundColor: isFocused ? '#C6C6C6' : '',
			cursor: 'pointer',
		};
	},
	control: (provided: any) => ({
		...provided,
		minHeight: 'fit-content',
	}),
};

export function Select<T extends object>({
	label,
	infoMessage,
	variant = 'primary',
	className,
	containerClassName,
	errorMessage,
	controller,
	isMulti,
	placeholder = '',
	isDisabled,
	asyncProps,
	isTagField,
	truncate = true,
	...props
}: SelectProps<T>) {
	const classNames = {
		control: () => 'border border-neutral-100 rounded outline-2',
		multiValue: () =>
			`${
				isTagField
					? 'bg-neutral-900 text-white rounded outline-2 m-1'
					: 'bg-neutral-100  text-neutral-700 rounded outline-2 m-1 '
			}  `,
		//

		container: () =>
			`w-full text-sm rounded ${isDisabled ? 'bg-neutral-100' : 'bg-white'}`,
		clearIndicator: () => 'text-neutral-400 hover:text-neutral-900 px-1',
		dropdownIndicator: () =>
			`text-neutral-400 hover:text-neutral-900 px-1 ${
				errorMessage ? 'invisible' : ''
			}`,
		multiValueLabel: () => 'pl-2 align-text-bottom',
		multiValueRemove: () =>
			`${
				isTagField
					? 'text-neutral-100 rounded-md outline-2 hover:text-neutral-200 px-1 ml-1 hover:bg-neutral-700'
					: 'text-neutral-700 rounded-md outline-2 hover:text-neutral-200 px-1 ml-1 hover:bg-neutral-700'
			}`,
		menu: () =>
			`bg-white border border-neutral-100 rounded ${
				variant === 'light' ? 'p-1 px-2' : 'p-2 px-3'
			} mt-2`,
		menuPortal: () => 'px-2',
		indicatorSeparator: () =>
			`bg-neutral-200 h-1/2 my-auto ${errorMessage ? 'invisible' : ''}`,
		indicatorsContainer: () => '',
		input: () =>
			`text-neutral-900 ${variant === 'light' ? 'p-1 px-2' : 'p-2 px-2'}`,
		placeholder: () => 'text-neutral-500 px-2 mr-2',
		valueContainer: () => `${truncate ? 'truncate' : 'break-words'}}`,
		singleValue: () => 'px-2',
		option: () =>
			`rounded hover:bg-neutral-100 px-2 my-px ${
				truncate ? 'truncate' : 'break-words'
			}`,
		noOptionsMessage: () => 'text-neutral-500',
		group: () => '',
		menuList: () => '',
		groupHeading: () => 'text-neutral-900 bg-neutral-50',
		loadingIndicator: () => '',
		loadingMessage: () => '',
	};

	return (
		<div className={twMerge('z-auto w-full text-left', className)}>
			<div className="flex items-center ">
				{label != null && (
					<div className="flex items-center gap-1">
						<Label label={label} variant={variant} />
						{infoMessage && <InfoTooltip message={infoMessage} />}
					</div>
				)}
			</div>

			<div
				className={twMerge(
					`relative rounded outline-2 -outline-offset-1 focus-within:outline ${
						isDisabled ? 'cursor-not-allowed' : ''
					}`,
					containerClassName
				)}
			>
				{controller ? (
					<Controller
						{...controller}
						render={({ field }) => (
							<RSelect
								unstyled
								isClearable
								isMulti={isMulti}
								closeMenuOnSelect={!isMulti}
								placeholder={placeholder}
								tabSelectsValue={false}
								styles={customStyle}
								noOptionsMessage={() => 'Nenhum item encontrado'}
								classNames={classNames}
								isDisabled={isDisabled}
								{...props}
								{...field}
							/>
						)}
					/>
				) : asyncProps ? (
					<AsyncSelect
						unstyled
						isClearable
						isMulti={isMulti}
						closeMenuOnSelect={!isMulti}
						placeholder={placeholder}
						cacheOptions
						defaultOptions
						styles={customStyle}
						noOptionsMessage={() => 'Nenhum item encontrado'}
						classNames={classNames}
						onInputChange={props.onInputChange}
						{...asyncProps}
						{...props}
					/>
				) : (
					<RSelect
						unstyled
						isClearable
						isMulti={isMulti}
						closeMenuOnSelect={!isMulti}
						placeholder={placeholder}
						tabSelectsValue={false}
						styles={customStyle}
						noOptionsMessage={() => 'Nenhum item encontrado'}
						classNames={classNames}
						isDisabled={isDisabled}
						{...props}
					/>
				)}

				{errorMessage && (
					<div
						className={twMerge(
							'absolute right-3 top-[15px] -translate-y-1/2 transform',
							variant === 'light' ? 'top-[15px]' : 'top-[19px]'
						)}
					>
						<ErrorTooltip offset={8} message={errorMessage} />
					</div>
				)}
			</div>
		</div>
	);
}
