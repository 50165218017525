import { z } from 'zod';
import { EditGlobalSystemSchema } from '../schema/EditGlobalSystemSchema';

export const editSystemSchema = EditGlobalSystemSchema.extend({
	autoCheckinRadius: z.coerce.number().min(0),
	autoCheckoutRadius: z.number().min(0).or(z.string()),
	canPushGetOnline: z.boolean(),
	delayToSendSMS: z.number().min(0).or(z.string()),
	id: z.number().or(z.string()),
	rejectTimeout: z.number().min(0).or(z.string()),
	socketLocationInterval: z.number().min(0).or(z.string()),
	stopDuration: z.number().min(0).or(z.string()),
	timeToCancel: z.number().min(0).or(z.string()),
	userLocationInterval: z.number().min(0).or(z.string()),
});

export type EditSystemSchemaSchemaType = z.infer<typeof editSystemSchema>;

// export const editSystemSchema = z.object({
// 	currentAppVersion: z.string(),
// 	fidelityCosumeBonus: z.number().min(0).or(z.string()),
// 	fidelityDriverBonus: z.number().min(0).or(z.string()),
// 	fidelityParticularBonus: z.number().min(0).or(z.string()),
// 	fidelityProducitivityBonus: z.number().min(0).or(z.string()),
// 	geofenceId: z.number().or(z.string()),
// 	intervalTimeToMatch: z.number().min(0).or(z.string()),
// 	intervalTimeToMatchFavorites: z.number().min(0).or(z.string()),
// 	matchFavoritesMaxRadius: z.number().min(0).or(z.string()),
// 	matchMaxRadius: z.number().min(0).or(z.string()),
// 	minimumAppVersion: z.string(),
// 	minutesToConsiderOnline: z.number().min(0).or(z.string()),
// 	timeToCancel: z.number().min(0).or(z.string()),
// 	timeToExpire: z.number().min(0).or(z.string()),
// 	timeToExpireScheduled: z.number().min(0).or(z.string()),
// });
