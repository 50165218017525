import { useEffect, useRef, useState } from 'react';

export const useCountDown = (initialValue: number) => {
	const [seconds, setSeconds] = useState(initialValue);
	const interval = useRef<ReturnType<typeof setInterval>>();

	useEffect(() => {
		if (seconds > 0) {
			interval.current = setInterval(() => {
				setSeconds((prev) => prev - 1);
			}, 1000);
		} else {
			clearInterval(interval.current);
		}
		return () => clearInterval(interval.current);
	}, [seconds]);

	return {
		seconds,
		setSeconds,
	};
};
