/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { MarkerF } from '@react-google-maps/api';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { toast } from 'react-toastify';
import { GoogleMap } from '../../../../../components/GoogleMap';
import { Modal } from '../../../../../components/Modal';
import { useAlert } from '../../../../../contexts/AlertContext';

import { api } from '../../../../../services/angular/axios';
import CloseDriver from './closeDriver';
import { OrderResponseApi } from '../../../../../services/angular/types/Order';
import { LeafletMap } from '../../../../../components/Leaflet';
import { Marker, Tooltip } from 'react-leaflet';
import { iconDefault } from '../../../../../components/Leaflet/icons/customIcon';
import L from 'leaflet';
import { useNewMaps } from '../../../../../hooks/useMaps';

const vehicleIcon = {
	green: {
		'icon-motorcycle': '/onlineMoto.png',
		'icon-motorcycle-2': '/onlineMoto.png',
		'icon-car': '/onlineCar.png',
		'icon-truck-2': '/onlineTruck.png',
		'icon-mini-van-1': '/onlineMiniVan.png',
		'icon-mini-van-2': '/onlineMiniVan.png',
		'icon-van-1': '/onlineVan.png',
		'icon-van-2': '/onlineVan.png',
		'icon-bike': '/onlineBike.png',
		default: '/onlineGreen.png',
	},
	red: {
		'icon-motorcycle': '/onlineMotoRed.png',
		'icon-motorcycle-2': '/onlineMotoRed.png',
		'icon-car': '/onlineCarRed.png',
		'icon-truck-1': '/onlineTruckRed.png',
		'icon-truck-2': '/onlineTruckRed.png',
		'icon-mini-van-1': '/onlineMiniVanRed.png',
		'icon-mini-van-2': '/onlineMiniVanRed.png',
		'icon-van-1': '/onlineVanRed.png',
		'icon-van-2': '/onlineVanRed.png',
		'icon-bike': '/onlineBikeRed.png',
		default: '/onlineRed.png',
	},
};


interface ClosestDriverModalProps {
	order?: OrderResponseApi;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ClosestDriverModal = React.memo(
	function ClosestDriverModal({
		open,
		setOpen,
		order,
	}: ClosestDriverModalProps) {
		const alert = useAlert();

		const { data: closestDrivers } = useQuery(
			['closest-drivers', order?.id],
			async () =>
				await api.get(
					`/drivers/closestToOrder/${order!.id}?include=activeFreights`
				),
			{
				enabled: !!order?.id,
			}
		);
		const { toggleMaps } = useNewMaps();

		function customVehicleIcon(iconUrl: string): L.Icon {
			return new L.Icon({
				iconUrl,
				iconSize: [50, 36],
				iconAnchor: [12, 24],
				popupAnchor: [7, -16],
			});
		}
		

		return (
			<Modal
				title="Motoristas próximos"
				open={open}
				setOpen={setOpen}
				className="min-h-[80vh] min-w-[80vw]"
				headless
			>
				<div className="flex gap-4">
					<div className="h-[calc(80vh-32px)] w-full">
					{
					!toggleMaps?.useLeaflet ? (
						<GoogleMap
							center={{
								lat: order?.locations[0].lat ? order?.locations[0].lat : 0,
								lng: order?.locations[0].lng ? order?.locations[0].lng : 0,
							}}
							zoom={15}
						>
							{closestDrivers?.data.map((onlineDriver: any) => (
								<MarkerF
									key={onlineDriver.user.id}
									onClick={() => {
										let titleLabel = 'Atribuir entregador?';
										let messageLabel = `Deseja atribuir o entregador ${onlineDriver.user.name} à esta entrega?`;
										let toastSuccessLabel = 'Entregador atribuído com sucesso';
										let toastErrorLabel = 'Erro ao atribuir entregador';

										if (onlineDriver.user.activeFreights?.length) {
											titleLabel = 'Enviar tela de Match?';
											messageLabel = `Deseja enviar tela de match para o entregador ${onlineDriver.user.name}?`;
											toastSuccessLabel = 'Tela de match enviado com sucesso.';
											toastErrorLabel = 'Erro ao enviar tela de Match.';
										}
										alert.onCustom({
											title: titleLabel,
											// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
											message: messageLabel,
											confirm: {
												onClick: async () => {
													try {
														await api.put(
															`/orders/${order!.id}/addDriver/${
																// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
																onlineDriver.user.id
															}?include=driver.tags`
														);
														toast.success(toastSuccessLabel);
														setOpen(false);
													} catch (error) {
														toast.error(toastErrorLabel);
													}
												},
											},
										});
									}}
									position={{
										lat: onlineDriver.user?.position?.latitude,
										lng: onlineDriver.user?.position?.longitude,
									}}
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									label={{
										text: onlineDriver.user?.name,
										color: 'black',
										className:
											'absolute -translate-y-[14px] shadow-lg py-1 px-px transform translate-x-[22px] rounded-r bg-white ',
										fontWeight: 'bold',
										fontSize: '12px',
									}}
									icon={{
										url:
											onlineDriver.user?.activeFreights?.length === 0
												? // @ts-expect-error
												  vehicleIcon.green[order?.category?.icon] ||
												  vehicleIcon.green?.default // @ts-expect-error
												: vehicleIcon.red[order?.category?.icon] ||
												  vehicleIcon.red.default,
										scaledSize: new window.google.maps.Size(50, 30),
									}}
								/>
							))}
							<MarkerF
								position={{
									lat: order?.locations[0].lat ? order?.locations[0].lat : 0,
									lng: order?.locations[0].lng ? order?.locations[0].lng : 0,
								}}
							/>
						</GoogleMap>
						):(
							<LeafletMap layers={toggleMaps.layers} center={{
								lat: order?.locations[0]?.lat ? order?.locations[0].lat : 0,
								lng: order?.locations[0]?.lng ? order?.locations[0].lng : 0
							}}>
								{closestDrivers?.data.map((onlineDriver: any) => (
									onlineDriver.user?.position?.latitude && onlineDriver.user?.position?.longitude ? (
										<Marker
										key={onlineDriver.user.id}
										position={{
											lat: onlineDriver.user.position.latitude,
											lng: onlineDriver.user.position.longitude,
										}}
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										icon={customVehicleIcon(
											onlineDriver.user.activeFreights?.length === 0
											// @ts-expect-error
											? vehicleIcon.green[order?.category?.icon] || vehicleIcon.green.default
											// @ts-expect-error
											: vehicleIcon.red[order?.category?.icon] || vehicleIcon.red.default
										)}
										eventHandlers={{
											click: () => {
											let titleLabel = 'Atribuir entregador?';
											let messageLabel = `Deseja atribuir o entregador ${onlineDriver.user.name} à esta entrega?`;
											let toastSuccessLabel = 'Entregador atribuído com sucesso';
											let toastErrorLabel = 'Erro ao atribuir entregador';

											if (onlineDriver.user.activeFreights?.length) {
												titleLabel = 'Enviar tela de Match?';
												messageLabel = `Deseja enviar tela de match para o entregador ${onlineDriver.user.name}?`;
												toastSuccessLabel = 'Tela de match enviado com sucesso.';
												toastErrorLabel = 'Erro ao enviar tela de Match.';
											}

											alert.onCustom({
												title: titleLabel,
												message: messageLabel,
												confirm: {
												onClick: async () => {
													try {
													await api.put(
														`/orders/${order!.id}/addDriver/${onlineDriver.user.id}?include=driver.tags`
													);
													toast.success(toastSuccessLabel);
													setOpen(false);
													} catch (error) {
													toast.error(toastErrorLabel);
													}
												},
												},
											});
											},
										}}
										>
										<Tooltip direction="right" offset={[30, -8]} opacity={1} permanent interactive>
											{onlineDriver.user.name}
										</Tooltip>
										</Marker>
									) : null
									))}
								<Marker
									position={{
										lat: order?.locations[0]?.lat ? order?.locations[0].lat : 0,
										lng: order?.locations[0]?.lng ? order?.locations[0].lng : 0
									}}
									icon={iconDefault()}
									draggable={false}
								/>
							</LeafletMap>
						)}
					</div>

					<div className="flex max-h-[calc(80vh-32px)] min-w-[320px] flex-col gap-2 overflow-y-auto p-px pr-2">
						<div className="mb-1 flex w-[85%] items-center justify-between border-b-2 border-neutral-500">
							<h1>Entregadores próximos</h1>
							<h1 className="font-bold text-orange">#{order?.id}</h1>
						</div>
						{closestDrivers?.data?.length ? (
							<>
								{closestDrivers?.data.map((onlineDriver: any) => (
									<CloseDriver
										key={onlineDriver.user.id}
										order={order}
										onlineDriver={onlineDriver}
										setOpen={setOpen}
									/>
								))}
							</>
						) : (
							<div className="flex gap-2 rounded p-2 shadow-md shadow-neutral-100 ring-1 ring-neutral-100 transition-all hover:bg-neutral-50 hover:shadow-neutral-200 hover:ring-2">
								<p className="text-center">Não há entregadores disponíveis</p>
							</div>
						)}
					</div>
				</div>
			</Modal>
		);
	},
	(prevProps, nextProps) => {
		return prevProps.open === nextProps.open;
	}
);
