import React, { useEffect } from 'react';
import Router from './routes/Router';
import { AlertProvider } from './contexts/AlertContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Provider } from '@radix-ui/react-tooltip';

import * as Sentry from '@sentry/react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router';

Sentry.init({
	dsn: 'https://657e4c1d02407c5800cb42278c75b907@o4504136429797376.ingest.sentry.io/4505988051435520',
	integrations: [
		new Sentry.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
		new Sentry.Replay({
			maskAllInputs: false,
			maskAllText: false,
			blockAllMedia: false,
			mask: ['password'],
		}),
	],
	tracesSampleRate: 0.5,
	tracePropagationTargets: [process.env.REACT_APP_PHP_API_URL || ''],
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 0.5,
});

const queryCache = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
			retry: 2,
			staleTime: 1000 * 30,
		},
	},
});

const App: React.FC = () => {
	return (
		<QueryClientProvider client={queryCache}>
			<AlertProvider>
				<Provider delayDuration={100}>
					<Router />
				</Provider>
			</AlertProvider>
			<ToastContainer
				hideProgressBar={false}
				autoClose={2500}
				position="top-right"
				newestOnTop
				closeOnClick
				pauseOnFocusLoss
			/>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
};

export default App;
