import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const ChevronDownIcon: React.FC<SvgProps> = ({
	color = '#393939',
	size,
	stroke = '2',
}) => {
	return (
		<svg
			width="100%"
			height={size ?? '100%'}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6 9L12 15L18 9"
				stroke="currentColor"
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ChevronDownIcon;
