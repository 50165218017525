import dayjs from 'dayjs';
import { z } from 'zod';

export const editDriverSchema = z.object({
	driverLicense: z.string().optional(),
	driverLicenseExpiration: z
		.any()
		.transform((value) => value && dayjs(value).toISOString())
		.nullable()
		.optional(),
	rg: z.string().optional().nullable(),
	id: z.number().or(z.string()).optional(),
	driverLicenseImg: z.string().optional(),
});

export type EditDriverSchemaType = z.infer<typeof editDriverSchema>;
