import { z } from 'zod';
import { ErrorsMessageHelper } from '../../../../helpers/errors/ErrorsMessageHelper';

export const ChangePasswordSchema = z.object({
	currentPassword: z.string().min(1, { message: ErrorsMessageHelper.required }),
	password: z
		.string()
		.min(1, { message: ErrorsMessageHelper.required })
		.min(8, { message: ErrorsMessageHelper.min(8) })
		.max(20, { message: ErrorsMessageHelper.max(20) })
		.regex(/[a-z]/, {
			message: ErrorsMessageHelper.password.regex.lowercase,
		})
		.regex(/[A-Z]/, {
			message: ErrorsMessageHelper.password.regex.uppercase,
		})
		.regex(/[0-9]/, { message: ErrorsMessageHelper.password.regex.number })
		.regex(/[^a-zA-Z0-9]/, {
			message: ErrorsMessageHelper.password.regex.specialCharacter,
		}),
	confirmPassword: z
		.string()
		.min(1, { message: ErrorsMessageHelper.required })
		.min(8, { message: ErrorsMessageHelper.min(8) })
		.max(20, { message: ErrorsMessageHelper.max(20) })
		.regex(/[a-z]/, {
			message: ErrorsMessageHelper.password.regex.lowercase,
		})
		.regex(/[A-Z]/, {
			message: ErrorsMessageHelper.password.regex.uppercase,
		})
		.regex(/[0-9]/, { message: ErrorsMessageHelper.password.regex.number })
		.regex(/[^a-zA-Z0-9]/, {
			message: ErrorsMessageHelper.password.regex.specialCharacter,
		}),
});

export type ChangePasswordSchemaType = z.infer<typeof ChangePasswordSchema>;
