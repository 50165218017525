import { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
	Checkbox,
	Form,
	FormButtons,
	Input,
	useZodForm,
} from '../../../../../components/FormElements';
import { PageHeader } from '../../../../../components/PageHeader';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { api } from '../../../../../services/angular/axios';
import { Data } from '../System';
import {
	editSystemSchema,
	EditSystemSchemaSchemaType,
} from './EditSystemSchema';

export const EditSystem = ({
	system,
	shouldBack,
}: {
	system: Data;
	shouldBack: () => void;
}) => {
	const onSubmit = async (formData: EditSystemSchemaSchemaType) => {
		try {
			await api.put(`/configs/${system?.id}?include=geofence`, formData);

			toast.success(`Sistema alterado com sucesso!`);
			shouldBack();
		} catch (error) {
			if (error) toast.error(`Houve algum problema ao editar o sistema.`);
		}
	};
	const form = useZodForm({
		schema: editSystemSchema,
	});

	useEffect(() => {
		if (system) {
			form.reset({
				...system,
			});
		}
	}, [system]);
	return (
		<>
			<PageHeader
				title={'Configurações do Sistema - ' + system.geofence.name}
				description="Definições referentes às configurações do sistema"
			/>
			<Form form={form} onSubmit={onSubmit}>
				<WhiteContainer className="flex w-full flex-col gap-4">
					<div className="flex w-full flex-col gap-4 md:flex-row">
						<div className="flex w-full flex-col gap-4">
							<Input
								label="Tempo para cancelamento do pedido (segundos):"
								type="number"
								{...form.register('timeToCancel')}
							/>
							<Input
								label="Tempo por parada na duração do pedido (minutos):"
								type="number"
								{...form.register('stopDuration')}
							/>

							<Input
								label="Atraso no envio do PUSH (minutos):"
								type="number"
								{...form.register('delayToSendSMS')}
							/>
							<Input
								label="Intervalo de envio de socket de localização (segundos):"
								type="number"
								{...form.register('socketLocationInterval')}
							/>
						</div>
						<div className="flex w-full flex-col gap-4">
							<Input
								label="Tempo para limpar rejeição:"
								type="number"
								{...form.register('rejectTimeout')}
							/>
							<Input
								label="Raio do checkin automático (metros):"
								type="number"
								{...form.register('autoCheckinRadius')}
							/>
							<Input
								label="Raio do checkout automático (metros):"
								type="number"
								{...form.register('autoCheckoutRadius')}
							/>

							<Input
								label="Intervalo de tempo para salvar localizações no banco (segundos):"
								disabled
								type="number"
								{...form.register('userLocationInterval')}
							/>
						</div>
					</div>
					<Checkbox
						label="Habilitar push de novos fretes para motoristas?"
						controller={{
							control: form.control,
							name: 'canPushGetOnline',
						}}
					/>
				</WhiteContainer>
				<FormButtons onCancel={() => shouldBack()} className="mt-4" />
			</Form>
		</>
	);
};
