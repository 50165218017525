export const cepRegex = /^\d{5}-\d{3}$/;

export const cepMask = (value?: string): string => {
	if (!value) return '';
	return value
		.replace(/\D/g, '')
		.replace(/(\d{5})(\d)/, '$1-$2')
		.replace(/(-\d{3})\d+?$/, '$1');
};

export const removeCepMask = (value: string | undefined): string => {
	if (!value) return '';
	return value.replace(/\D/g, '');
};
