import { z } from 'zod';
import { dateFilterSchema } from '../../../../../../helpers/dateFilterSchema';

export const TransactionsFilterSchema = dateFilterSchema.extend({
	clientName: z.string().optional(),
	transactionId: z.string().optional().nullable(),
	adminId: z
		.object({
			label: z.string().optional(),
			value: z.number().nullable(),
		})
		.optional()
		.nullable()
		.transform((value) => value?.value),
	paymentTypeId: z
		.object({
			label: z.string().optional(),
			value: z.number().nullable(),
		})
		.optional()
		.nullable()
		.transform((value) => value?.value),
	transactionStatusId: z
		.object({
			label: z.string().optional(),
			value: z.number().nullable(),
		})
		.optional()
		.nullable()
		.transform((value) => value?.value),
});

export type TransactionsFilterSchemaType = z.infer<
	typeof TransactionsFilterSchema
>;
