import {
	Actions,
	Table,
	TableActions,
} from '../../../../components/Table/Table';
import { Tooltip } from '../../../../components/Tooltip';
import { useGetFreights } from '../../../../services/angular/useOrders';
import { TbClock, TbTruckDelivery } from 'react-icons/tb';
import dayjs from 'dayjs';
import { Row } from 'react-table';
import { Driver } from '../../../../components/Angular/FreightsDetail/Driver';
import useUserState from '../../../../services/angular/angularUserState';
import { SortOptions } from '../../../../services/angular/types/Params';
import { useState } from 'react';
import { api } from '../../../../services/angular/axios';
import { oldIcons, VehicleIcon } from '../../../../components/Icon/vehicles';
import { Distance } from '../Historic/Distance';
import {
	draftFilterSchema,
	DraftFilterSchemaType,
} from './schema/draftFilterSchema';
import { FaExclamationCircle, FaShare } from 'react-icons/fa';
import {
	Date,
	Input,
	Select,
	useZodForm,
} from '../../../../components/FormElements';
import { PageHeader } from '../../../../components/PageHeader';
import { FiltersTab } from '../../../../components/FiltersTab';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { twMerge } from 'tailwind-merge';
import { appState } from '../../../../store/appState';
import { toast } from 'react-toastify';
import { useAlert } from '../../../../contexts/AlertContext';
import { OrderResponseApi } from '../../../../services/angular/types/Order';
import { AiFillStar } from 'react-icons/ai';
import { TiWarning } from 'react-icons/ti';

export const Draft = () => {
	const role = useUserState((state) => state.userRole);
	const company = useUserState((state) => state.company);
	const alert = useAlert();
	const [query, setQuery] = useState<DraftFilterSchemaType>();

	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});

	const { data, isLoading, isFetching, refetch } = useGetFreights(
		{
			companyId: company?.id,
			companyType: company?.type,
			status: 'draft',
			query,
			...params,
		},
		{
			refetchOnMount: 'always',
		}
	);

	const handleDelete = async (data: OrderResponseApi) => {
		alert.onCustom({
			title: 'Excluir pedido',
			message: `Deseja mesmo excluir o rascunho do pedido #${data?.id}?`,
			confirm: {
				onClick: async () => {
					try {
						await api.delete(`/orders/${data.id}/delete-draft`);
						toast.success('Pedido excluído com sucesso!');
						await refetch();
					} catch (error: any) {
						toast.error(error?.response.data.error);
					}
				},
			},
		});
	};

	const form = useZodForm({
		schema: draftFilterSchema,
	});

	return (
		<div>
			<PageHeader
				title="Rascunhos de frete"
				description="Fretes aguardando finalização do pedido"
			/>

			<FiltersTab
				form={form}
				clearFilters={() => {
					form.reset();
					setQuery(undefined);
					setParams({
						...params,
						page: 1,
					});
				}}
				onSubmit={(data) => {
					setQuery(data);
					setParams({
						...params,
						page: 1,
					});
				}}
			>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="initialDate"
					label="De"
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="endDate"
					label="Até"
				/>
				<Select
					variant="light"
					className="w-full md:w-auto md:min-w-[128px]"
					label="Data"
					controller={{
						control: form.control,
						name: 'operation',
						defaultValue: null,
					}}
					options={[
						{
							label: 'Imediato',
							value: 'immediate',
						},
						{
							label: 'Agendado',
							value: 'scheduled',
						},
					]}
				/>
				<Select
					variant="light"
					className="w-full md:w-auto md:min-w-[128px]"
					label="Tipo"
					controller={{
						control: form.control,
						name: 'orderTypeId',
						defaultValue: null,
					}}
					options={[
						{
							label: 'Por hora',
							value: '3',
						},
						{
							label: 'Rota fixa',
							value: '1',
						},
						{
							label: 'Rota livre',
							value: '2',
						},
					]}
				/>
				<Input
					variant="light"
					label="Pesquisar"
					className="w-full md:w-auto"
					{...form.register('query')}
				/>
			</FiltersTab>
			<WhiteContainer>
				<Table<OrderResponseApi>
					triggerPageChange={form.formState.isSubmitting}
					isLoading={isLoading || isFetching}
					disabledSort={{
						completionPrevisionDate: true,
						created_at: true,
					}}
					columns={[
						{
							accessor: 'id',
							Header: 'ID',
							Cell: ({ row }) => {
								const { id, searchOnlyFavoriteDrivers } = row.original;

								return (
									<div className="flex flex-col">
										{searchOnlyFavoriteDrivers && (
											<Tooltip message="Somente motoristas favoritos">
												<AiFillStar className="text-yellow-submenu" size={22} />
											</Tooltip>
										)}
										<p className="text-lg font-bold text-orange">
											#{String(id).padStart(5, '0')}
										</p>
									</div>
								);
							},
						},
						{
							accessor: 'scheduleDate',
							Header: 'Data',
							Cell: ({ row }) => {
								const { scheduleDate } = row.original;
								const date =
									scheduleDate &&
									dayjs(scheduleDate).format('DD/MM/YYYY HH:mm');

								return (
									<div className="flex gap-1">
										{scheduleDate ? (
											<TbClock size={30} />
										) : (
											<TbTruckDelivery size={30} />
										)}
										<div className="flex flex-col">
											<span className="font-medium text-neutral-800">
												{scheduleDate ? 'Agendado' : 'Imediato'}
											</span>
											{date && (
												<span className="text-sm text-neutral-500">{date}</span>
											)}
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'completionPrevisionDate',
							Header: 'Prev. término',
							Cell: ({ value }) => {
								const date = value && dayjs(value).format('DD/MM/YYYY HH:mm');
								return (
									<div className="flex flex-col text-sm">
										<span>{date ?? '-'}</span>
									</div>
								);
							},
						},
						{
							// @ts-expect-error
							accessor: 'driverCompany.id',
							Header: 'Motorista',
							Cell: ({ row }: { row: Row<OrderResponseApi> }) => {
								return (
									<div className="flex">
										{row.original.driver && row.original.driverCompany ? (
											<Driver
												orderId={row.original.id}
												driver={row.original.driver}
												driverCompany={row.original.driverCompany}
											>
												<div className="flex flex-col">
													<div className="h-auto break-words font-medium leading-none">
														{row.original.driver.name}
													</div>
													{/* <div className="h-auto w-16 break-words font-medium leading-none">
														{row.original.driver.name
															.split(' ')
															.map((word, index) => (
																<div
																	key={index}
																	className="flex"
																>
																	{word}
																</div>
															))}
													</div> */}
													<span className="mt-1 text-xs text-neutral-600">
														Fretista
													</span>
												</div>
											</Driver>
										) : (
											<div className="flex flex-col">
												-
												<span className="text-xs text-neutral-600">
													Fretista
												</span>
											</div>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'categoryId',
							Header: 'Veículo',
							Cell: ({ row }) => {
								const { category, categoryType, isFavoriteDriver, defrauded } =
									row.original;
								return (
									<div className="flex flex-col justify-center">
										<div className="flex items-center gap-2">
											<VehicleIcon
												name={
													/* @ts-expect-error */
													oldIcons[
														categoryType === 1
															? category.typeOneIcon
															: categoryType === 2
															? category.typeTwoIcon
															: category.icon
													]
												}
												size={36}
											/>
											<span className="flex flex-col leading-3">
												{row.original.category.name}
												<p className="text-2xs text-neutral-500">
													{categoryType === 1 &&
														row.original.category.typeOneActive &&
														row.original.category.typeOneDescription}
													{categoryType === 2 &&
														row.original.category.typeTwoActive &&
														row.original.category.typeTwoDescription}
													{categoryType === 0 &&
														row.original.category.typeTwoActive &&
														row.original.category.typeTwoActive &&
														'Sem preferência'}
												</p>
											</span>
										</div>
										<div className="flex gap-2">
											{isFavoriteDriver && (
												<Tooltip message="Motorista favorito">
													<AiFillStar
														className="text-yellow-submenu"
														size={22}
													/>
												</Tooltip>
											)}
											{defrauded &&
												role?.id !== Number(appState.userRoles.user.value) && (
													<Tooltip message="Fake GPS detectado">
														<TiWarning className="text-terracota-0" size={22} />
													</Tooltip>
												)}
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'distance',
							Header: 'Distância',
							Cell: ({ row }) => <Distance order={row.original} />,
						},
						{
							accessor: 'total',
							Header: 'Valor',
							Cell: ({ row }) => {
								const { total, delivery, statusId } = row.original;
								return (
									<div className="flex flex-col">
										{statusId === appState.orderStatus.Cancelado.id ? (
											'-'
										) : (
											<span
												className={twMerge(
													'text-sm',
													!delivery ? 'text-terracota-500' : 'text-green'
												)}
											>
												{total.toLocaleString('pt-br', {
													style: 'currency',
													currency: 'BRL',
												})}
											</span>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'statusId',
							Header: 'Status',
							Cell: ({ row }) => {
								const { incident, status } = row.original;

								return (
									<>
										{status.id === appState.orderStatus.Finalizado.id ? (
											<div className="flex text-sm font-medium">
												{incident ? (
													<span className="flex items-center gap-1 text-terracota-500">
														<FaExclamationCircle size={16} />
														Incidente
													</span>
												) : (
													status.name
												)}
											</div>
										) : (
											<div
												className={twMerge(
													'flex text-sm font-medium',
													status.id === appState.orderStatus.Cancelado.id &&
														'text-terracota-500'
												)}
											>
												{incident ? (
													<span className="flex items-center gap-1 text-terracota-500">
														<FaExclamationCircle size={16} />
														Incidente
													</span>
												) : (
													status.name
												)}
											</div>
										)}
									</>
								);
							},
						},
						{
							accessor: 'created_at',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => {
								const { original } = row;

								const actions: Array<Actions<OrderResponseApi> | undefined> = [
									{
										icon: <FaExclamationCircle size={16} />,
										label: 'Detalhes',
										href: `/fretes/detalhes/${original.id}`,
									},
									original.statusId === appState.orderStatus.Rascunho.id &&
									!original.externalCalculatorUsed
										? {
												icon: <FaShare size={16} />,
												label: 'Continuar pedido',
												href: `/fretes/pedir?rascunho=${original.id}`,
										  }
										: original.statusId === appState.orderStatus.Cancelado.id
										? {
												icon: <FaShare size={16} />,
												label: 'Pedir novamente',
												href: `/fretes/pedir?ref=${original.id}`,
										  }
										: undefined,
									{
										icon: 'trash',
										label: 'Excluir',
										onAction: (data) => {
											void handleDelete(data);
										},
									},
								];

								return (
									<TableActions
										row={row}
										actions={
											actions.filter((action) => action) as Array<
												Actions<OrderResponseApi>
											>
										}
									/>
								);
							},
						},
					]}
					data={data?.data.data || []}
					total={data?.data.meta.pagination.total || 0}
					onParamsChange={(params) => {
						setParams(params);
					}}
				/>
			</WhiteContainer>
		</div>
	);
};

export default Draft;
