import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import {
	BsFillCheckCircleFill,
	BsExclamationOctagonFill,
	BsTrashFill,
} from 'react-icons/bs';
import { FaUndoAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { match } from 'ts-pattern';
import { z } from 'zod';
import Button from '../../../../../components/Button/Button';
import {
	Input,
	Label,
	Textarea,
	useZodForm,
} from '../../../../../components/FormElements';
import { Modal } from '../../../../../components/Modal';
import RouteIcon from '../../../../../components/RouteIcon/RouteIcon';
import { Tooltip } from '../../../../../components/Tooltip';
import {
	allowCheckin,
	allowUndoCheckin,
	allowUndoCheckout,
	canBeDeleted,
	canBeFinished,
	getCurrentPendingLocation,
	getPreviousCompletedLocation,
	isFreeRoute,
	isInCourse,
	isScheduled,
	isStaticRoute,
} from '../../../../../helpers/utils';
import useUserState from '../../../../../services/angular/angularUserState';
import { api } from '../../../../../services/angular/axios';
import { OrderResponseApi } from '../../../../../services/angular/types/Order';
import { FileInput } from './fileInput';

interface CurrentLocationProps {
	order?: OrderResponseApi;
}

const LocationSchema = z.object({
	textarea: z.string().optional(),
	protocol: z
		.object({
			protocolNumbers: z
				.array(
					z.object({
						protocolNumber: z.string().min(1, 'Informe o protocolo'),
					})
				)
				.optional()
				.transform((value) => value?.map((item) => item.protocolNumber)),
			protocolAssets: z
				.array(
					z.object({
						asset: z.string(),
					})
				)
				.optional()
				.transform((value) => value?.map((item) => item.asset)),
		})
		.optional(),
});

export const CurrentLocation = ({ order }: CurrentLocationProps) => {
	const role = useUserState((state) => state.userRole);
	const [openModal, setOpenModal] = useState(false);
	const [modalBody, setModalBody] = useState<{
		title: string;
		message: string;
		address: string;
		point: number;
		id: number;
		type:
			| 'checkout'
			| 'checkin'
			| 'reason'
			| 'undo-checkin'
			| 'undo-checkout'
			| 'delete'
			| 'finish';
	}>();

	const {
		reset,
		handleSubmit,
		register,
		setValue,
		control,
		formState: { errors, isSubmitting },
	} = useZodForm({
		schema: LocationSchema,
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'protocol.protocolNumbers',
	});

	const { append: appendAsset, remove: removeAsset } = useFieldArray({
		control,
		name: 'protocol.protocolAssets',
	});

	if (!order || !(isInCourse(order) || isScheduled(order))) return null;

	const handleUpdate = async ({
		textarea,
		protocol,
	}: z.infer<typeof LocationSchema>) => {
		try {
			if (modalBody?.type === 'delete') {
				await api.delete(`/orders/${order?.id}/location/${modalBody?.id}`);
			} else if (modalBody?.type === 'finish') {
				await api.put(`/orders/${order?.id}/${modalBody?.type}`);
			} else if (modalBody) {
				const data =
					modalBody?.type === 'checkout'
						? {
								checkoutNotes: textarea,
								protocolAssets: protocol?.protocolAssets,
								protocolNumbers: protocol?.protocolNumbers,
						  }
						: {
								reason: textarea,
								protocolAssets: protocol?.protocolAssets,
								protocolNumbers: protocol?.protocolNumbers,
						  };

				await api.put(
					`/orders/${order?.id}/location/${modalBody?.id}/${modalBody?.type}`,
					data
				);
			}

			reset({
				textarea: '',
				protocol: {
					protocolNumbers: [],
					protocolAssets: [],
				},
			});
		} catch (error: any) {
			if (typeof error?.response?.data?.error === 'string') {
				toast.error(error.response?.data.error);
			} else {
				toast.error('Erro ao atualizar localização');
			}
		} finally {
			setOpenModal(false);
		}
	};

	const currentLocation = getCurrentPendingLocation(order);

	const previousCompletedLocation = getPreviousCompletedLocation(order);

	return (
		<div className="pointer-events-none absolute left-0 right-0 top-4 flex items-center justify-center gap-4 px-px">
			<div className="pointer-events-auto flex items-center justify-center gap-4 rounded-xl bg-neutral-50 p-4 shadow shadow-neutral-600">
				<div className="flex items-center gap-4">
					{isFreeRoute(order) && (
						<div className="w-min text-base font-semibold text-neutral-0">
							{previousCompletedLocation ? 'Parada anterior:' : 'Origem:'}
						</div>
					)}
					{previousCompletedLocation && isFreeRoute(order) && (
						<>
							<RouteIcon
								number={previousCompletedLocation.sequence + 1}
								checkoutDate={previousCompletedLocation.checkoutDate}
								checkinDate={previousCompletedLocation.checkinDate}
								reasonDate={previousCompletedLocation.reasonDate}
							/>
							<div className="max-w-xs break-words text-base font-medium text-neutral-0">
								{previousCompletedLocation.name}
							</div>
						</>
					)}
					{currentLocation && (
						<>
							<RouteIcon
								number={currentLocation.sequence + 1}
								checkoutDate={currentLocation.checkoutDate}
								checkinDate={currentLocation.checkinDate}
								reasonDate={currentLocation.reasonDate}
							/>
							<div className="max-w-xs truncate font-medium text-neutral-0">
								{currentLocation.name}
							</div>
						</>
					)}
				</div>
				{role?.name !== 'user' && (
					<div className="flex gap-2">
						{currentLocation && (
							<>
								{isStaticRoute(order) &&
									allowUndoCheckin(order, currentLocation) && (
										<Tooltip
											message={`Confirmar entrega no ponto ${
												currentLocation.sequence + 1
											}`}
											triggerClassName="w-auto rounded-full bg-white p-3 cursor-pointer shadow hover:shadow-neutral-200"
											onClick={() => {
												setOpenModal(true);
												setModalBody({
													title: `Confirmar entrega`,
													message: `Deseja confirmar a entrega no ponto ${
														currentLocation.sequence + 1
													}?`,
													address: currentLocation.address,
													point: currentLocation.sequence + 1,
													id: currentLocation.id,
													type: 'checkout',
												});
											}}
										>
											<BsFillCheckCircleFill size={20} className="text-green" />
										</Tooltip>
									)}
								{isStaticRoute(order) &&
									allowUndoCheckin(order, currentLocation) && (
										<Tooltip
											message={`Justificar impossibilidade de entrega no ponto ${
												currentLocation.sequence + 1
											}`}
											triggerClassName="w-auto rounded-full bg-white p-3 cursor-pointer shadow hover:shadow-neutral-200"
											onClick={() => {
												setOpenModal(true);
												setModalBody({
													title: `Impossibilidade de entrega`,
													message: `Por que não foi possível realizar a entrega no ponto ${
														currentLocation.sequence + 1
													}?`,
													address: currentLocation.address,
													point: currentLocation.sequence + 1,
													id: currentLocation.id,
													type: 'reason',
												});
											}}
										>
											<BsExclamationOctagonFill
												size={20}
												className="text-terracota-500"
											/>
										</Tooltip>
									)}
								{allowUndoCheckin(order, currentLocation) && (
									<Tooltip
										message={`Desfazer checkin no ponto ${
											currentLocation.sequence + 1
										}`}
										triggerClassName="w-auto rounded-full bg-white p-3 cursor-pointer shadow hover:shadow-neutral-200"
										onClick={() => {
											setOpenModal(true);
											setModalBody({
												title: `Desfazer checkin`,
												message: `Deseja desfazer o checkin no ponto ${
													currentLocation.sequence + 1
												}?`,
												address: currentLocation.address,
												point: currentLocation.sequence + 1,
												id: currentLocation.id,
												type: 'undo-checkin',
											});
										}}
									>
										<FaUndoAlt size={20} className="text-terracota-500" />
									</Tooltip>
								)}
								{allowCheckin(order, currentLocation) && (
									<Tooltip
										message={`Confirmar que o entregador chegou no ponto ${
											currentLocation.sequence + 1
										}`}
										triggerClassName="w-auto rounded-full bg-white p-3 cursor-pointer shadow hover:shadow-neutral-200"
										onClick={() => {
											setOpenModal(true);
											setModalBody({
												title: `Confirmar checkin`,
												message: `Confirmar que o entregador chegou no ponto ${
													currentLocation.sequence + 1
												}?`,
												address: currentLocation.address,
												point: currentLocation.sequence + 1,
												id: currentLocation.id,
												type: 'checkin',
											});
										}}
									>
										<BsFillCheckCircleFill size={20} className="text-orange" />
									</Tooltip>
								)}
								{(isFreeRoute(order) || allowCheckin(order, currentLocation)) &&
									previousCompletedLocation &&
									allowUndoCheckout(order, previousCompletedLocation) && (
										<Tooltip
											message={`Desfazer checkout no ponto anterior (Ponto ${
												previousCompletedLocation.sequence + 1
											})`}
											triggerClassName="w-auto rounded-full bg-white p-3 cursor-pointer shadow hover:shadow-neutral-200"
											onClick={() => {
												setOpenModal(true);
												setModalBody({
													title: `Desfazer checkout`,
													message: `Deseja desfazer o checkout no ponto ${
														previousCompletedLocation.sequence + 1
													}?`,
													address: previousCompletedLocation.address,
													point: previousCompletedLocation.sequence,
													id: previousCompletedLocation.id,
													type: 'undo-checkout',
												});
											}}
										>
											<FaUndoAlt size={20} className="text-terracota-500" />
										</Tooltip>
									)}
								{(isFreeRoute(order) || allowCheckin(order, currentLocation)) &&
									allowUndoCheckout(order, currentLocation) && (
										<Tooltip
											message={`Desfazer checkout no ponto (Ponto ${
												currentLocation.sequence + 1
											})`}
											triggerClassName="w-auto rounded-full bg-white p-3 cursor-pointer shadow hover:shadow-neutral-200"
											onClick={() => {
												setOpenModal(true);
												setModalBody({
													title: `Desfazer checkout`,
													message: `Deseja desfazer o checkout no ponto ${
														currentLocation.sequence + 1
													}?`,
													address: currentLocation.address,
													point: currentLocation.sequence,
													id: currentLocation.id,
													type: 'undo-checkout',
												});
											}}
										>
											<FaUndoAlt size={20} className="text-terracota-500" />
										</Tooltip>
									)}
							</>
						)}

						{previousCompletedLocation &&
							canBeDeleted(order, previousCompletedLocation) && (
								<Tooltip
									message={`Excluir o ponto ${
										previousCompletedLocation.sequence + 1
									}`}
									triggerClassName="w-auto rounded-full bg-white p-3 cursor-pointer shadow hover:shadow-neutral-200"
									onClick={() => {
										setOpenModal(true);
										setModalBody({
											title: `Excluir ponto`,
											message: `Deseja excluir o ponto ${
												previousCompletedLocation.sequence + 1
											}?`,
											address: previousCompletedLocation.address,
											point: previousCompletedLocation.sequence + 1,
											id: previousCompletedLocation.id,
											type: 'delete',
										});
									}}
								>
									<BsTrashFill size={20} className="text-terracota-500" />
								</Tooltip>
							)}
						{canBeFinished(order) && (
							<Tooltip
								message="Finalizar pedido"
								triggerClassName="w-auto rounded-full bg-white p-3 cursor-pointer shadow hover:shadow-neutral-200"
								onClick={() => {
									setOpenModal(true);
									setModalBody({
										title: `Finalizar pedido`,
										message: `Deseja mesmo finalizar o pedido #${order.id}?`,
										address: '',
										point: 0,
										id: 0,
										type: 'finish',
									});
								}}
							>
								<BsFillCheckCircleFill size={20} className="text-green" />
							</Tooltip>
						)}
					</div>
				)}
			</div>

			<Modal
				open={openModal}
				setOpen={setOpenModal}
				title={modalBody?.title || ''}
				className="max-w-md"
				onClose={() => {
					reset({
						textarea: '',
						protocol: {
							protocolNumbers: [],
							protocolAssets: [],
						},
					});
				}}
			>
				<div className="flex flex-col gap-4 px-4">
					<h1 className="text-center text-lg font-bold text-neutral-0 lg:text-xl xl:text-2xl">
						{modalBody?.message}
					</h1>
					{modalBody?.address && (
						<p className="text-center text-neutral-500">
							Endereço: {modalBody?.address}
						</p>
					)}
					{(modalBody?.type === 'checkout' || modalBody?.type === 'reason') && (
						<>
							<Textarea autoFocus rows={5} {...register('textarea')} />
							{order.requesterCompany.storeProtocolsInStaticRoute && (
								<div className="flex flex-col gap-1">
									<div className="flex justify-between gap-4">
										<Label label="Protocolos" variant="primary" />
										<button
											className="rounded-md bg-primary-500 px-2 py-1 text-xs text-white"
											onClick={() => {
												append({
													protocolNumber: '',
												});
												appendAsset({
													asset: '',
												});
											}}
										>
											Adicionar
										</button>
									</div>
									<div className="flex flex-col items-center gap-1">
										{fields.map((field, index) => (
											<div
												className="flex w-full items-center justify-between gap-2"
												key={field.id}
											>
												<Input
													variant="light"
													{...register(
														`protocol.protocolNumbers.${index}.protocolNumber`
													)}
													errorMessage={
														errors?.protocol?.protocolNumbers?.[index]
															?.protocolNumber?.message
													}
												/>
												<FileInput
													key={`asset-${field.id}`}
													onImageLoad={(file) => {
														setValue(
															`protocol.protocolAssets.${index}.asset`,
															file
														);
													}}
												/>
												<button
													className="rounded-md bg-terracota-500 px-2 py-1 text-xs text-white"
													onClick={() => {
														remove(index);
														removeAsset(index);
													}}
												>
													Remover
												</button>
											</div>
										))}
									</div>
								</div>
							)}
						</>
					)}
					<div className="grid grid-cols-2 gap-4 px-8">
						<Button
							variant="secondary"
							type="button"
							onClick={() => setOpenModal(false)}
						>
							Cancelar
						</Button>
						<Button
							variant="blue-primary"
							// eslint-disable-next-line @typescript-eslint/no-misused-promises
							onClick={handleSubmit(handleUpdate)}
							disabled={isSubmitting}
						>
							{match(modalBody)
								.with({ type: 'checkout' }, () => 'Confirmar entrega')
								.with({ type: 'undo-checkin' }, () => 'Desfazer checkin')
								.with({ type: 'checkin' }, () => 'Confirmar checkin')
								.with({ type: 'undo-checkout' }, () => 'Desfazer checkout')
								.with({ type: 'delete' }, () => 'Excluir ponto')
								.with({ type: 'finish' }, () => 'Finalizar pedido')
								.with({ type: 'reason' }, () => 'Salvar Justificativa')
								.otherwise(() => 'Salvar Justificativa')}
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};
