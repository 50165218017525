import { z } from 'zod';

export const GeofenceCoordinatesParser = z
	.array(
		z.array(z.number()).min(2, {
			message: 'Geofence precisa ter no mínimo 3 pontos',
		})
	)
	.transform((value) => {
		// const parsedValue = JSON.parse(value) as number[][];
		return value.map((item) => {
			return {
				lng: item[0],
				lat: item[1],
			};
		});
	})
	.optional();

export type GeofenceCoordinatesParserType = z.infer<
	typeof GeofenceCoordinatesParser
>;

export const GeofenceCenterOfBoundsParser = z
	.array(z.number())
	.min(2, {
		message: 'Geofence precisa ter no mínimo 3 pontos',
	})
	.transform((value) => {
		// const parsedValue = JSON.parse(value) as number[];
		return {
			lng: value[0],
			lat: value[1],
		};
	})
	.optional();
