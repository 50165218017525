import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { FiHelpCircle } from 'react-icons/fi';
import { TbSteeringWheel, TbUser } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';
import { OrderResponseApi } from '../../../../../../services/angular/types/Order';

interface CostDropdownProps {
	order: OrderResponseApi;
	isAdmin?: boolean;
}

export const CostDropdown = ({ order, isAdmin }: CostDropdownProps) => {
	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger className="self-start focus:outline-none">
				<FiHelpCircle size={20} />
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content className="w-screen max-w-md rounded bg-white p-6 text-neutral-0 shadow-md shadow-neutral-500 md:mr-4 md:p-4">
					<div className="flex flex-col gap-4">
						{order.requesterMultiplier > 1 && (
							<div className="flex justify-between gap-2 text-lg font-semibold text-orange">
								Tarifa dinâmica
								<span className="self-start rounded-full bg-orange px-2 py-px text-base font-bold text-white">
									{order.requesterMultiplier}X
								</span>
							</div>
						)}
						<div className="flex flex-col">
							{isAdmin ? (
								<>
									{order.costs.map((cost) => (
										<span key={cost.id}>
											{cost?.driverValue && cost?.requesterValue ? (
												<div>
													<span>
														<div className="flex justify-between gap-2 text-sm font-semibold text-neutral-600">
															<div className="flex gap-1">
																<TbSteeringWheel
																	className="text-neutral-0"
																	size={20}
																/>
																Pagamento ao motorista
															</div>
															<span className="self-start font-semibold text-neutral-800">
																{Number(cost.driverValue).toLocaleString(
																	'pt-BR',
																	{
																		style: 'currency',
																		currency: 'BRL',
																	}
																)}
															</span>
														</div>
														<hr className="my-2 w-full text-neutral-200" />
													</span>
													<span>
														<div className="flex justify-between gap-2 text-sm font-semibold text-neutral-600">
															<div className="flex gap-1">
																<TbUser className="text-neutral-0" size={20} />
																{cost.description}
															</div>
															<span className="self-start font-semibold text-neutral-800">
																{Number(cost.requesterValue).toLocaleString(
																	'pt-BR',
																	{
																		style: 'currency',
																		currency: 'BRL',
																	}
																)}
															</span>
														</div>
														<hr className="my-2 w-full text-neutral-200" />
													</span>
												</div>
											) : (
												<span>
													<div className="flex justify-between gap-2 text-sm font-semibold text-neutral-600">
														<div className="flex gap-1">
															{cost.driverValue ? (
																<TbSteeringWheel
																	className="text-neutral-0"
																	size={20}
																/>
															) : (
																<TbUser className="text-neutral-0" size={20} />
															)}
															{cost.description}
														</div>
														<span className="self-start font-semibold text-neutral-800">
															{Number(cost.value).toLocaleString('pt-BR', {
																style: 'currency',
																currency: 'BRL',
															})}
														</span>
													</div>
													<hr className="my-2 w-full text-neutral-200" />
												</span>
											)}
										</span>
									))}
								</>
							) : (
								<>
									{order.costs.map((cost, index) => (
										<span key={`${cost.id}-$`}>
											<div className="flex justify-between gap-2 text-sm font-semibold text-neutral-600">
												{cost.description}
												<span className="self-start font-semibold text-neutral-800">
													{Number(cost.value).toLocaleString('pt-BR', {
														style: 'currency',
														currency: 'BRL',
													})}
												</span>
											</div>
											<hr className="my-2 w-full text-neutral-200" />
										</span>
									))}
								</>
							)}
						</div>
						<div className="flex justify-between gap-2 text-lg font-semibold text-neutral-800">
							Total
							<span
								className={twMerge(
									'self-start font-semibold text-neutral-800',
									order.statusId === 7 && 'text-terracota-500 line-through'
								)}
							>
								{order?.total?.toLocaleString('pt-BR', {
									style: 'currency',
									currency: 'BRL',
								})}
							</span>
						</div>
					</div>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};
