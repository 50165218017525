import {
	Location,
	OrderResponseApi,
} from '../../../services/angular/types/Order';
import * as Accordion from '@radix-ui/react-accordion';
import { IoChatboxEllipsesOutline, IoPersonOutline } from 'react-icons/io5';
import {
	BsExclamationOctagonFill,
	BsFillCheckCircleFill,
	BsTelephone,
} from 'react-icons/bs';
import { AccordionContent, AccordionTrigger } from '../../Accordion';
import RouteIcon from '../../RouteIcon/RouteIcon';
import { dateFormat } from '../../../helpers/format/dateFormat';
import { FreightImage } from '../../Angular/FreightsDetail/Image';
import { appState } from '../../../store/appState';
import { Tooltip } from '../../Tooltip';
import { Modal } from '../../Modal';
import { useEffect, useMemo, useState } from 'react';
import {
	FormHeader,
	Input,
	Label,
	Textarea,
	useZodForm,
} from '../../FormElements';
import { toast } from 'react-toastify';
import { api } from '../../../services/angular/axios';
import { z } from 'zod';
import { MdOpenInFull, MdCloseFullscreen } from 'react-icons/md';
import { useCopy } from '../../../hooks/useCopy';
import { useFieldArray } from 'react-hook-form';
import { FileInput } from '../../../pages/Angular/Freights/Follow/components/fileInput';
import Button from '../../Button/Button';
import { FaMapMarker } from 'react-icons/fa';
import { removePhoneMask } from '../../../helpers/mask/phoneMask';

interface LocationsAccordionProps {
	order?: OrderResponseApi;
	isAdmin?: boolean;
	header?: boolean;
	search?: boolean;
}

const LocationSchema = z.object({
	textarea: z.string().optional(),
	protocol: z
		.object({
			protocolNumbers: z
				.array(
					z.object({
						protocolNumber: z.string().min(1, 'Informe o protocolo'),
					})
				)
				.optional()
				.transform((value) => value?.map((item) => item.protocolNumber)),
			protocolAssets: z
				.array(
					z.object({
						asset: z.string(),
					})
				)
				.optional()
				.transform((value) => value?.map((item) => item.asset)),
		})
		.optional(),
});

export const LocationsAccordion = ({
	order,
	isAdmin,
	header,
}: LocationsAccordionProps) => {
	const copy = useCopy();
	const [highlightValues, setHighlightValues] = useState<string[]>();
	const [openModal, setOpenModal] = useState(false);
	const [modalBody, setModalBody] = useState<{
		title: string;
		message: string;
		address: string;
		point: number;
		id: number;
		type: 'checkout' | 'reason';
	}>();

	const {
		reset,
		handleSubmit,
		register,
		control,
		setValue,
		formState: { errors, isSubmitting },
	} = useZodForm({
		schema: LocationSchema,
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'protocol.protocolNumbers',
	});

	const { append: appendAsset, remove: removeAsset } = useFieldArray({
		control,
		name: 'protocol.protocolAssets',
	});

	const handleUpdate = async ({
		textarea,
		protocol,
	}: z.infer<typeof LocationSchema>) => {
		try {
			const data =
				modalBody?.type === 'checkout'
					? {
							checkoutNotes: textarea,
							protocolAssets: protocol?.protocolAssets,
							protocolNumbers: protocol?.protocolNumbers,
					  }
					: {
							reason: textarea,
							protocolAssets: protocol?.protocolAssets,
							protocolNumbers: protocol?.protocolNumbers,
					  };

			if (order?.id && modalBody?.id && modalBody?.type) {
				await api.put(
					`/orders/${order.id}/location/${modalBody.id}/${modalBody.type}`,
					data
				);
			} else {
				throw new Error('Erro ao atualizar a localização');
			}
		} catch (error: any) {
			toast.error(error.response.data.error);
		}

		setOpenModal(false);
	};

	const allValues = useMemo(
		() => order?.locations.map((location) => String(location.id)),
		[order]
	);

	const hasAllValues = useMemo(
		() => highlightValues?.length === order?.locations.length,
		[highlightValues, order]
	);

	useEffect(() => {
		function handleKeyDown(event: any) {
			if (event.key === 'f' && (event.ctrlKey || event.metaKey)) {
				setHighlightValues(allValues);
			}
		}
		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	function OpenMaps(location: Location) {
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		const route = `${order?.locations[0].lat},${order?.locations[0].lng}/ ${location.lat},${location.lng}`;
		window.open(`https://www.google.com.br/maps/dir/${route}`);
	}

	return (
		<>
			{header && (
				<div className="flex flex-wrap items-center justify-between gap-2">
					<FormHeader
						title="Trajeto"
						description="Paradas no trajeto do frete"
					/>
					<button
						className="flex items-center gap-2 self-start rounded-full bg-neutral-50 px-2 py-1 text-sm font-medium text-neutral-800 transition-all hover:bg-neutral-100"
						onClick={() => {
							hasAllValues
								? setHighlightValues([])
								: setHighlightValues(allValues);
						}}
					>
						{hasAllValues ? (
							<>
								Fechar todas as paradas
								<MdCloseFullscreen size={18} />
							</>
						) : (
							<>
								Expandir todas as paradas
								<MdOpenInFull size={18} />
							</>
						)}
					</button>
				</div>
			)}
			<Accordion.Root
				id="highLight"
				className="divide-y divide-neutral-500 border-b border-neutral-400 text-sm"
				type="multiple"
				value={highlightValues}
				onValueChange={setHighlightValues}
			>
				{order?.locations
					.sort((a, b) => a.sequence - b.sequence)
					.map((location, index) => (
						<Accordion.Item key={index} value={String(location.id)}>
							<AccordionTrigger className="rounded p-2 transition-all hover:bg-neutral-50 hover:text-neutral-0">
								<div className="flex w-[calc(100%-32px)] flex-row items-start gap-2">
									<RouteIcon
										labelClassName="bottom-2.5"
										checkinDate={location.checkinDate}
										checkoutDate={location.checkoutDate}
										reasonDate={location.reasonDate}
										number={location.sequence + 1}
									/>
									<div className="flex w-[calc(100%-32px)] flex-col items-start gap-1">
										<div className="flex w-full justify-between gap-2">
											<p className="max-w-full truncate font-semibold">
												{location?.name}
											</p>
											{!!location?.extraStoppedTime && (
												<div className="font-semibold  text-terracota-0">
													+{location?.extraStoppedTime} min
												</div>
											)}
										</div>
										<p className="w-full select-text text-start text-xs">
											{location?.address}
										</p>
									</div>
								</div>
							</AccordionTrigger>
							<AccordionContent className="bg-transparent">
								{index !== 0 && (
									<div
										className="my-2 ml-12 flex transform cursor-pointer items-center gap-1 transition-transform hover:scale-105"
										onClick={() => OpenMaps(location)}
									>
										<FaMapMarker size={16} />
										Rota desde a origem
									</div>
								)}
								<div className="mt-2 flex w-full flex-wrap justify-start gap-x-6 gap-y-2 pb-2 pl-12">
									{location?.responsible && (
										<div className="flex items-center gap-1">
											<IoPersonOutline size={16} />
											{location.responsible}
										</div>
									)}
									<div className="flex items-center gap-1">
										<BsTelephone size={16} />
										<span
											className="cursor-copy"
											onClick={() => {
												void copy(removePhoneMask(location?.contact));
											}}
										>
											{location?.contact}
										</span>
									</div>
									<div className="w-ful flex items-center gap-1">
										<span className="w-4">
											<IoChatboxEllipsesOutline size={16} />
										</span>
										{location.observations}
									</div>
								</div>
								<div className="pb-2 pl-14">
									{location.checkinDate && (
										<div className="relative flex flex-col border-l-2 border-neutral-400 px-4 pt-4">
											<div className="absolute -left-[6px] top-5 h-2.5 w-2.5 rounded-full bg-orange" />
											<div className=" text-neutral-400">
												{dateFormat(location.checkinDate)}
											</div>
											<div>Entregador chegando</div>
										</div>
									)}
									{location.checkoutDate && (
										<div className="relative flex flex-wrap justify-between gap-2 border-l-2 border-neutral-400 px-4 py-4">
											<div>
												<div className="absolute -left-[6px] top-5 h-2.5 w-2.5 rounded-full bg-green" />
												<div className=" text-neutral-400">
													{dateFormat(location.checkoutDate)}
												</div>
												Entrega realizada
												{location?.checkoutNotes && (
													<div className="text-neutral-500">
														{location.checkoutNotes}
													</div>
												)}
												{location.receiverType && location.receiverName && (
													<div className="flex flex-col gap-px text-neutral-400">
														<span>{location.receiverType.name}</span>
														<span>
															{location?.receiverName}{' '}
															{location?.receiverLastName}
														</span>
													</div>
												)}
											</div>
											{location?.protocols && (
												<FreightImage protocols={location.protocols} />
											)}
										</div>
									)}
									{location.reasonDate && (
										<div className="relative flex flex-wrap justify-between gap-2 border-l-2 border-neutral-400 px-4 py-4">
											<div>
												<div className="absolute -left-[6px] top-5 h-2.5 w-2.5 rounded-full bg-terracota-500" />
												<div className=" text-neutral-400">
													{dateFormat(location.reasonDate)}
												</div>
												<div>Problemas na entrega</div>
												<div className="text-neutral-400">
													{location.reason}
												</div>
											</div>
											{location?.protocols && (
												<FreightImage protocols={location.protocols} />
											)}
										</div>
									)}
									{!!location?.protocols?.length && (
										<Accordion.Root
											collapsible
											type="single"
											id={`nfs-${index}`}
											className="py-1"
											defaultValue={String(location.id)}
										>
											<Accordion.Item key={index} value={String(location.id)}>
												<AccordionTrigger
													side="left"
													className="flex items-center rounded p-2 transition-all hover:bg-neutral-50 hover:text-neutral-0"
												>
													<div className="flex items-center justify-center">
														<span className="flex items-center ">
															NFs ou cupons
														</span>
													</div>
												</AccordionTrigger>
												<AccordionContent className="bg-transparent pl-10">
													<ol className="list-decimal">
														{location.protocols
															.filter((p) => p?.number)
															.map((protocol, index) => (
																<li key={index}>{protocol.number}</li>
															))}
													</ol>
												</AccordionContent>
											</Accordion.Item>
										</Accordion.Root>
									)}
									{location.checkinDate &&
										order.orderTypeId ===
											Number(appState.orderTypes['Rota fixa'].value) &&
										isAdmin && (
											<div className="my-2 flex items-center justify-evenly rounded-lg bg-neutral-50 p-2">
												{(location.reason || !location.checkoutDate) && (
													<Tooltip
														message={`Confirmar entrega no ponto ${
															location.sequence + 1
														}`}
														triggerClassName="w-auto rounded-full bg-white p-4 cursor-pointer shadow hover:shadow-lg"
														onClick={() => {
															setOpenModal(true);
															setModalBody({
																title: `Confirmar entrega`,
																message: `Deseja confirmar a entrega no ponto ${
																	location.sequence + 1
																}?`,
																address: location.address,
																point: index + 1,
																id: location.id,
																type: 'checkout',
															});
														}}
													>
														<BsFillCheckCircleFill
															size={24}
															className="text-green"
														/>
													</Tooltip>
												)}
												{(location.checkoutDate || !location.reason) && (
													<Tooltip
														message={`Justificar impossibilidade de entrega no ponto ${
															index + 1
														}`}
														triggerClassName="w-auto rounded-full bg-white p-4 cursor-pointer shadow hover:shadow-lg"
														onClick={() => {
															setOpenModal(true);
															setModalBody({
																title: `Impossibilidade de entrega`,
																message: `Por que não foi possível realizar a entrega no ponto ${
																	index + 1
																}?`,
																address: location.address,
																point: index + 1,
																id: location.id,
																type: 'reason',
															});
														}}
													>
														<BsExclamationOctagonFill
															size={24}
															className="text-terracota-500"
														/>
													</Tooltip>
												)}
											</div>
										)}
								</div>
							</AccordionContent>
						</Accordion.Item>
					))}
			</Accordion.Root>
			<Modal
				open={openModal}
				setOpen={setOpenModal}
				title={modalBody?.title || ''}
				className="max-w-md"
				onClose={() => {
					reset({
						textarea: '',
						protocol: {
							protocolNumbers: [],
							protocolAssets: [],
						},
					});
				}}
			>
				<div className="flex flex-col gap-2">
					<h1 className="text-center text-2xl font-bold text-neutral-0">
						{modalBody?.message}
					</h1>
					<p className="text-center text-neutral-500">
						Endereço: {modalBody?.address}
					</p>
					<Textarea
						autoFocus
						rows={5}
						{...register('textarea')}
						errorMessage={errors.textarea?.message}
					/>
					{order?.requesterCompany?.storeProtocolsInStaticRoute && (
						<div className="flex flex-col gap-1">
							<div className="flex justify-between gap-4">
								<Label label="Protocolos" variant="primary" />
								<button
									className="rounded-md bg-primary-500 px-2 py-1 text-xs text-white"
									onClick={() => {
										append({
											protocolNumber: '',
										});
										appendAsset({
											asset: '',
										});
									}}
								>
									Adicionar
								</button>
							</div>
							<div className="flex flex-col items-center gap-1">
								{fields.map((field, index) => (
									<div
										className="flex w-full items-center justify-between gap-2"
										key={field.id}
									>
										<Input
											variant="light"
											{...register(
												`protocol.protocolNumbers.${index}.protocolNumber`
											)}
											errorMessage={
												errors?.protocol?.protocolNumbers?.[index]
													?.protocolNumber?.message
											}
										/>
										<FileInput
											key={`asset-${field.id}`}
											onImageLoad={(file) => {
												setValue(
													`protocol.protocolAssets.${index}.asset`,
													file
												);
											}}
										/>
										<button
											className="rounded-md bg-terracota-500 px-2 py-1 text-xs text-white"
											onClick={() => {
												remove(index);
												removeAsset(index);
											}}
										>
											Remover
										</button>
									</div>
								))}
							</div>
						</div>
					)}
					<div className="grid grid-cols-2 gap-4 px-8">
						<Button
							variant="secondary"
							type="button"
							onClick={() => setOpenModal(false)}
						>
							Cancelar
						</Button>
						<Button
							variant="blue-primary"
							// eslint-disable-next-line @typescript-eslint/no-misused-promises
							onClick={handleSubmit(handleUpdate)}
							disabled={isSubmitting}
						>
							{modalBody?.type === 'checkout'
								? 'Confirmar entrega'
								: 'Salvar Justificativa'}
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};
