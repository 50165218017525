import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { OrderResponseApi } from '../../../../../services/angular/types/Order';

const DelayComponent = ({
	requestDate,
	scheduleDate,
}: Pick<OrderResponseApi, 'requestDate' | 'scheduleDate'>) => {
	const [diff, setDiff] = useState(dayjs().diff(requestDate, 'minute'));

	useEffect(() => {
		if (scheduleDate) return;
		const interval = setInterval(() => {
			const diff = dayjs().diff(requestDate, 'minute');
			setDiff(diff);
		}, 60 * 1000); // 1 minute
		return () => clearInterval(interval);
	}, []);

	const delay = useMemo(
		() =>
			dayjs(requestDate)
				.locale('pt-br')
				.fromNow()
				.replace(/^\w/, (c) => c.toUpperCase()),
		[diff]
	);

	return (
		<>
			{scheduleDate ? (
				<div className="flex items-center justify-center">-</div>
			) : (
				<div
					className={`flex min-w-[5rem] flex-col items-center rounded p-1 text-center text-sm font-medium ${
						diff >= 0
							? diff <= 2
								? 'bg-yellow-500 text-neutral-800'
								: 'bg-terracota-0 text-white'
							: 'bg-blue/50 text-white'
					}`}
				>
					{delay}
				</div>
			)}
		</>
	);
};

export default DelayComponent;
