import {
	EmailShareButton,
	FacebookShareButton,
	LinkedinShareButton,
	TelegramShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	EmailIcon,
	FacebookIcon,
	LinkedinIcon,
	TelegramIcon,
	TwitterIcon,
	WhatsappIcon,
} from 'react-share';
import { Link, useParams } from 'react-router-dom';
// @ts-expect-error
import { ReactComponent as Logo } from '../../../assets/Logo.svg';
import { useCopy } from '../../../hooks/useCopy';
import { useMemo } from 'react';
import { TbCopy } from 'react-icons/tb';

const socialMediaPlatforms = [
	{ platform: 'Email', button: EmailShareButton, icon: EmailIcon },
	{ platform: 'Facebook', button: FacebookShareButton, icon: FacebookIcon },
	{ platform: 'Linkedin', button: LinkedinShareButton, icon: LinkedinIcon },
	{ platform: 'Telegram', button: TelegramShareButton, icon: TelegramIcon },
	{ platform: 'Twitter', button: TwitterShareButton, icon: TwitterIcon },
	{ platform: 'WhatsApp', button: WhatsappShareButton, icon: WhatsappIcon },
];

const ShareLink = () => {
	const copy = useCopy();
	const { hash } = useParams();

	const link = useMemo(
		() =>
			hash
				? `${window.location.origin.toString()}/i/${hash}`
				: `${window.location.origin.toString()}/cadastro-entregador`,
		[hash]
	);

	return (
		<div className="flex min-h-screen w-screen flex-col items-center bg-gradient-to-t from-yellow-50 to-yellow-400 to-75% px-8 text-neutral-0">
			<Link to="/">
				<Logo className="mt-4 min-h-[64px] w-36 text-neutral-0 md:mt-8 md:min-h-[80px] md:w-56 md:min-w-[192px]" />
			</Link>

			<div className="flex max-w-lg flex-col items-center justify-center gap-4 py-1 md:py-8">
				{hash ? (
					<>
						<h1 className="self-center text-center text-xl font-bold text-neutral-900">
							Campanha Indique a BeeBee
						</h1>
						<p className="self-center text-center ">
							Ganhe R$100,00 ao indicar entregadores de Bag. A pessoa indicada
							deve realizar no mínimo 20 entregas em até 30 dias após a
							aprovação do cadastro, para você receber os R$100,00
						</p>
					</>
				) : (
					<h1 className="self-center text-center text-xl font-bold text-neutral-900">
						Indique a BeeBee
					</h1>
				)}

				<div className="flex flex-col items-center justify-center gap-8">
					<div className="flex flex-col items-center justify-center gap-1">
						<label className="text-center font-bold text-neutral-700">
							Compartilhe o link com outros entregadores
						</label>
						<button
							className="flex items-center gap-3 rounded-md bg-white px-4 py-2 shadow hover:underline"
							onClick={() => {
								void copy(link, 'Link copiado');
							}}
						>
							<p className="max-w-[75vw] flex-1 truncate border-r border-neutral-200 pr-3 text-neutral-700 md:max-w-md">
								{link}
							</p>
							<TbCopy size={22} />
						</button>
					</div>
					<div className="flex gap-4">
						{socialMediaPlatforms.map(
							({ platform, button: ButtonComponent, icon: IconComponent }) => (
								<ButtonComponent key={platform} url={link}>
									<IconComponent size={32} round />
								</ButtonComponent>
							)
						)}
					</div>
				</div>
				{/* <h2 className="self-center text-center text-xs">
					Você recebera uma mensagem de confirmação quando a pessoa indicada
					realizar o cadastro, e quando finalizar as entregas.
				</h2> */}
				{hash && (
					<h2 className="self-center text-center text-xs">
						Campanha válida apenas para as cidades: Belo Horizonte; Contagem;
						Campinas; São Paulo; Santo André, São Bernardo; São Caetano; Rio de
						Janeiro e Ribeirão Preto.
					</h2>
				)}
			</div>
		</div>
	);
};

export default ShareLink;
