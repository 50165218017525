import React, { useEffect } from 'react';
import {
	FormButtons,
	FormHeader,
	Input,
	Select,
	useZodForm,
} from '../../../../components/FormElements';
import { PageHeader } from '../../../../components/PageHeader';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { Modal } from '../../../../components/Modal';
import useUserState from '../../../../services/angular/angularUserState';
import {
	CompanyInfoSchema,
	CompanyInfoSchemaType,
} from './schema/CompanyInfoSchema';
import { STATES } from '../../../../helpers/states';
import { phoneMask, removePhoneMask } from '../../../../helpers/mask/phoneMask';
import { api } from '../../../../services/angular/axios';
import { toast } from 'react-toastify';
import { toastMessage } from '../../../../helpers/toastMessage';
import { Tooltip } from '../../../../components/Tooltip';
import HexBtnCompany from '../../../../assets/svg/HexBtnCompany';
import HexBtnFreighter from '../../../../assets/svg/HexBtnFreighter';
import { useNavigate } from 'react-router-dom';
import { Controller } from 'react-hook-form';
import { AngularUpdateLogo } from './components/AngularUpdateLogo';
import { RegisterButton } from '../../../../components/Button/RegisterButton';
import { cepMask } from '../../../../helpers/mask/cepMask';
import axios from 'axios';

export const CompanyProfile = () => {
	const navigate = useNavigate();
	const company = useUserState((state) => state.company);
	const triggerRefetch = useUserState((state) => state.triggerRefetch);

	const [isInvalidFileModalOpen, setIsInvalidFileModalOpen] =
		React.useState(false);

	const {
		reset,
		register,
		control,
		handleSubmit,
		setValue,
		setError,
		clearErrors,
		watch,
		formState: { errors, isDirty },
	} = useZodForm({
		schema: CompanyInfoSchema,
	});

	const { makeFreights, requestFreights } = watch();

	useEffect(() => {
		if (!makeFreights && !requestFreights) {
			setError('makeFreights', {
				message: 'Selecione ao menos uma opção de serviço.',
			});
		} else {
			clearErrors('makeFreights');
		}
	}, [makeFreights, requestFreights, errors]);

	useEffect(() => {
		reset({
			...company,
			zipcode: company?.zipcode,
			email: company?.email || '',
			supportEmail: company?.supportEmail || '',
			phone: phoneMask(removePhoneMask(company?.phone)),
			cellphone: phoneMask(removePhoneMask(company?.cellphone)),
			complement: company?.complement || '',
			stateId: STATES.find((state) => {
				return state.value === String(company?.stateId);
			}) || {
				label: 'Selecione um estado',
				value: '',
			},
		});
	}, [company]);

	const handleUpdate = async (data: CompanyInfoSchemaType): Promise<void> => {
		try {
			await api.put(`/companies/${String(company?.id)}`, {
				...company,
				...data,
			});
			triggerRefetch();
			toast.success(toastMessage.success.updated);
		} catch (err) {
			toast.error('Erro ao atualizar os dados.');
		}
	};

	return (
		<div className="w-full text-neutral-900">
			<PageHeader title="Meu Negócio" description="Informações da empresa.">
				<RegisterButton
					className="w-auto"
					label="Cadastrar novo negócio"
					onClick={() => navigate('/negocios/novo')}
				/>
			</PageHeader>

			<WhiteContainer className="flex gap-4">
				<div className="mb-4">
					<FormHeader
						className="mb-4"
						title="Perfil da Empresa"
						description="A empresa usará a BeeBee para pedir ou fazer fretes?"
					/>

					<div className="flex gap-4">
						<Controller
							control={control}
							name="requestFreights"
							render={({ field }) => (
								<Tooltip message="Se sua empresa utiliza nossos serviços para Pedir Fretes">
									<div
										className="w-32 min-w-[128px] hover:opacity-80"
										onClick={() => {
											field.onChange(!field.value);
										}}
									>
										<HexBtnCompany
											backgroundColor={requestFreights ? '#1671E4' : '#868686'}
											textColor="#fff"
											borderColor={requestFreights ? '#1671E4' : '#868686'}
										/>
									</div>
								</Tooltip>
							)}
						/>
						<Controller
							control={control}
							name="makeFreights"
							render={({ field }) => (
								<Tooltip message="Coloque seu veículo para fazer fretes pela BeeBee">
									<div
										className="w-32 min-w-[128px] hover:opacity-80"
										onClick={() => {
											field.onChange(!field.value);
										}}
									>
										<HexBtnFreighter
											backgroundColor={makeFreights ? '#fcf366' : '#868686'}
											textColor={makeFreights ? '#393939' : '#fff'}
											borderColor={makeFreights ? '#fcf366' : '#868686'}
										/>
									</div>
								</Tooltip>
							)}
						/>
					</div>
					{errors?.makeFreights?.message && (
						<p className="mt-1 text-terracota-500">
							{errors?.makeFreights?.message}
						</p>
					)}
				</div>

				<div className="flex flex-col items-center gap-4 md:flex-row lg:w-[74%]">
					<AngularUpdateLogo />
					<div className="flex w-full flex-col gap-4">
						<Input
							label="Nome Fantasia"
							disabled
							{...register('tradingName')}
						/>
						<Input label="Razão Social" {...register('companyName')} disabled />
						<div className="flex items-center gap-4">
							<Input
								className="w-full md:w-1/3"
								label="CNPJ"
								{...register('cnpj')}
								disabled
							/>
						</div>
					</div>
				</div>

				<FormHeader title="Localização" />
				<div className="flex flex-col gap-4 break-words">
					<Input
						className="w-full md:w-1/5"
						label="CEP"
						{...register('zipcode', {
							onChange: (e) => {
								e.target.value = cepMask(e.target.value);
								if (e.target.value.length === 9)
									axios
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										.get(`https://viacep.com.br/ws/${e.target.value}/json/`)
										.then((res) => {
											setValue('address', res.data.logradouro);
											setValue('district', res.data.bairro);
											setValue('city', res.data.localidade);
											setValue(
												'stateId',
												STATES.find((state) => state.label === res.data.uf)
											);
										})
										.catch(() => {
											setError('zipcode', {
												message: 'CEP inválido',
											});
										});
							},
						})}
						errorMessage={errors.zipcode?.message}
					/>
					<div className="flex flex-col gap-4 md:flex-row md:items-center">
						<Input
							label="Endereço"
							{...register('address')}
							errorMessage={errors.address?.message}
						/>
						<div className="flex items-center gap-2">
							<Input
								className="w-1/2"
								label="Numero"
								{...register('number')}
								errorMessage={errors.number?.message}
							/>
							<Input
								label="Complemento"
								{...register('complement')}
								errorMessage={errors.complement?.message}
							/>
						</div>
					</div>
					<div className="flex flex-col gap-2 md:flex-row md:items-center">
						<Input
							className="md:1/3"
							label="Bairro"
							{...register('district')}
							errorMessage={errors.district?.message}
						/>
						<div className="flex items-center gap-2 md:w-full">
							<Input
								className="w-2/3 md:w-1/2"
								label="Cidade"
								{...register('city')}
								errorMessage={errors.city?.message}
							/>
							<Select
								label="Estado"
								className="w-1/3 md:w-1/2"
								controller={{
									control,
									name: 'stateId',
								}}
								options={STATES}
								errorMessage={errors.stateId?.message}
							/>
						</div>
					</div>
				</div>
			</WhiteContainer>

			<WhiteContainer className="mb-4">
				<FormHeader title="Informações de Contato" />

				<div className="my-4 grid grid-cols-12 gap-4">
					<Input
						className="col-span-full md:col-span-6 lg:col-span-4"
						label="Email para faturamento"
						{...register('email')}
						errorMessage={errors.email?.message}
					/>
					<Input
						className="col-span-full md:col-span-6 lg:col-span-4"
						label="Email da Operação e Suporte"
						{...register('supportEmail')}
						errorMessage={errors.supportEmail?.message}
					/>
					<Input
						className="col-span-full md:col-span-6 lg:col-span-2"
						label="Telefone"
						{...register('phone', {
							onChange: (e: any) => {
								e.target.value = phoneMask(e.target.value);
							},
						})}
						errorMessage={errors.phone?.message}
					/>
					<Input
						className="col-span-full md:col-span-6 lg:col-span-2"
						label="Celular"
						{...register('cellphone', {
							onChange: (e: any) => {
								e.target.value = phoneMask(e.target.value);
							},
						})}
						errorMessage={errors.cellphone?.message}
					/>
				</div>
				<FormHeader title="Metas" />
				<Input
					className="my-4 w-full md:w-1/4"
					placeholder="R$"
					label="Meta de gastos com pedidos"
				/>
			</WhiteContainer>
			<FormButtons
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				onSave={handleSubmit(handleUpdate)}
				onCancel={() => reset()}
				saveProps={{
					disabled: !isDirty,
				}}
			/>

			<Modal
				title="Arquivo inválido"
				className="w-1/2"
				open={isInvalidFileModalOpen}
				setOpen={setIsInvalidFileModalOpen}
			>
				<p>
					O arquivo enviado não é um arquivo de imagem válido. Por favor, envie
					um arquivo de imagem no formato PNG ou JPG.
				</p>
			</Modal>
		</div>
	);
};
