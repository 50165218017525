import dayjs from 'dayjs';
import { useMemo, useRef, useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FiltersTab } from '../../../../../components/FiltersTab';
import {
	Date,
	FormButtons,
	Input,
	Select,
	Textarea,
	useZodForm,
} from '../../../../../components/FormElements';
import { PageHeader } from '../../../../../components/PageHeader';
import { Table } from '../../../../../components/Table/Table';
import { Tooltip } from '../../../../../components/Tooltip';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { useAlert } from '../../../../../contexts/AlertContext';
import { api } from '../../../../../services/angular/axios';
import { appState } from '../../../../../store/appState';
import { TransactionsFilterSchema } from './schemas/TransactionsFilterSchema';
import { TransactionModel } from './TransactionsModel';
import { RegisterButton } from '../../../../../components/Button/RegisterButton';
import { Modal } from '../../../../../components/Modal';
import { TransactionsAdjustmentsSchema } from './schemas/TransactionsAdjustmentsSchema';
import { cnpjMask } from '../../../../../helpers/mask/cnpjMask';
import { brCurrencyMask } from '../../../../../helpers/mask/brCurrencyMask';
import { Link } from 'react-router-dom';
import { useTransactionsFilterState } from './schemas/transactionsFilterState';

const Transactions = () => {
	const transactionsStatus = useMemo(
		() => [
			{ label: 'Todos', value: null },
			{ label: 'Realizado', value: 2 },
			{ label: 'Pendente', value: 6 },
			{ label: 'Aguardando', value: 7 },
			{ label: 'Bloqueado', value: 3 },
			{ label: 'Cancelado', value: 1 },
		],
		[]
	);

	const paymentTypes = useMemo(
		() => [
			{ label: 'Todos', value: null },
			{ label: 'Pix', value: 6 },
			{ label: 'Boleto', value: 1 },
			{ label: 'Cartão de crédito', value: 2 },
			{ label: 'Voucher 1ª', value: 7 },
			{ label: 'Ajuste', value: 8 },
		],
		[]
	);

	const alert = useAlert();
	const [inputValue, setInputValue] = useState('');
	const [openAdjustmentModal, setOpenAdjustmentModal] = useState(false);
	const [cnpjInputValue, setCNPJInputValue] = useState('');

	const {
		params,
		setParams,
		setTransactionsFilter,
		clearTransactionsFilter,
		...query
	} = useTransactionsFilterState();

	const adjustmentForm = useZodForm({
		schema: TransactionsAdjustmentsSchema,
	});

	const form = useZodForm({
		schema: TransactionsFilterSchema,
		defaultValues: {
			...query,
			paymentTypeId: query.paymentTypeId
				? {
						label: paymentTypes.find(
							(type) => type.value === query.paymentTypeId
						)?.label,
						value: query.paymentTypeId,
				  }
				: null,
			adminId: null,
			transactionStatusId: query.transactionStatusId
				? {
						label: transactionsStatus.find(
							(status) => status.value === query.transactionStatusId
						)?.label,
						value: query.transactionStatusId,
				  }
				: null,
		},
	});

	const { data, isLoading, isFetching, refetch } = useQuery(
		['transactions', query, params],
		async () =>
			await api.get('/transactions', {
				params: {
					...query,
					...params,
					pageSize: params.take,
					sort: params.sortBy
						? `${params.sortBy?.id}:${params.sortBy?.order}`
						: undefined,
				},
			}),
		{
			refetchOnMount: 'always',
		}
	);

	const { data: searchedUsers, refetch: usersRefetch } = useQuery(
		['searched-users', inputValue],
		async () =>
			await api.get('/users/admins?', {
				params: {
					name: inputValue,
				},
			})
	);

	const handleCancelTransaction = async (id: number) => {
		alert.onCustom({
			title: 'Deseja cancelar a transação?',
			message:
				'O valor será devolvido ao cliente. Esta ação não poderá ser desfeita.',
			confirm: {
				label: 'Excluir',
				onClick: async () => {
					try {
						await api.delete(`/transactions/${id}`);
						void refetch();
						toast.success('Transação cancelada com sucesso');
					} catch (error) {
						toast.error('Erro ao cancelar transação');
					}
				},
			},
		});
	};

	const timerRef = useRef<any>(null);

	const handleSearch = (value: string) => {
		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			setInputValue(value);
			void usersRefetch();
		}, 1000);
	};

	const { data: prepaid, refetch: refetchPrepaid } = useQuery(
		['prepaid'],
		async () =>
			await api.get(`/companies/prepaid?`, {
				params: {
					cnpj: cnpjInputValue,
				},
			})
	);

	const handleCNPJSearch = () => {
		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			void refetchPrepaid();
		}, 1000);
	};

	return (
		<div>
			<div className="flex flex-row items-center justify-between">
				<PageHeader
					className="flex w-full flex-row"
					title="Transações"
					description="Gestão de Transações de Clientes"
				>
					<RegisterButton
						className="w-auto"
						onClick={() => setOpenAdjustmentModal(true)}
					/>
				</PageHeader>
			</div>
			<Modal
				title="Transação de reajuste"
				open={openAdjustmentModal}
				setOpen={setOpenAdjustmentModal}
			>
				<div className="flex flex-col gap-4">
					<Select
						label="CNPJ (somente números)"
						controller={{
							control: adjustmentForm.control,
							name: 'companyId',
						}}
						options={prepaid?.data?.map((item: any) => ({
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							label: `${item.name} | ${cnpjMask(item.cnpj)}`,
							value: item.id,
						}))}
						inputValue={cnpjInputValue}
						onInputChange={(e) => {
							setCNPJInputValue(cnpjMask(e));
							handleCNPJSearch();
						}}
						errorMessage={adjustmentForm.formState.errors.companyId?.message}
					/>

					<div className="flex items-center gap-4">
						<Select
							label="Tipo da transação"
							controller={{
								control: adjustmentForm.control,
								name: 'addValue',
							}}
							options={[
								{
									label: 'Creditar / adicionar',
									value: true,
								},
								{
									label: 'Debitar / retirar',
									value: false,
								},
							]}
							errorMessage={adjustmentForm.formState.errors.addValue?.message}
						/>

						<Input
							label="Valor"
							placeholder="R$"
							{...adjustmentForm.register('value', {
								onChange: (e) => {
									const value = brCurrencyMask(e.target.value);

									e.target.value = value;
								},
							})}
							errorMessage={adjustmentForm.formState.errors.value?.message}
						/>
					</div>
					<Textarea
						label="Observação"
						{...adjustmentForm.register('observation')}
						errorMessage={adjustmentForm.formState.errors.observation?.message}
					/>

					<FormButtons
						// eslint-disable-next-line @typescript-eslint/no-misused-promises
						onSave={adjustmentForm.handleSubmit(async (data) => {
							try {
								await api.post('/transactions', data);
								toast.success('Transação realizada com sucesso');
								void refetch();
								setOpenAdjustmentModal(false);
							} catch (error) {
								toast.error('Erro ao realizar transação');
							}
						})}
						onCancel={() => setOpenAdjustmentModal(false)}
					/>
				</div>
			</Modal>

			<FiltersTab
				form={form}
				clearFilters={() => {
					form.reset({
						transactionStatusId: null,
						paymentTypeId: null,
						adminId: null,
						initialDate: null,
						endDate: null,
						clientName: undefined,
						transactionId: null,
					});
					clearTransactionsFilter();
				}}
				onSubmit={(data) => {
					setTransactionsFilter(data);
					setParams({
						...params,
						page: 1,
					});
				}}
			>
				<Input
					variant="light"
					className="w-full md:w-auto"
					label="Cliente"
					{...form.register('clientName')}
				/>
				<Input
					variant="light"
					className="w-full md:w-auto"
					label="Transação"
					{...form.register('transactionId')}
				/>
				<Select
					label="Usuário"
					variant="light"
					className="w-full md:w-auto md:min-w-[200px]"
					controller={{
						control: form.control,
						name: 'adminId',
					}}
					options={searchedUsers?.data.map(
						(user: { name: string; id: number }) => ({
							label: user.name,
							value: user.id,
						})
					)}
					onInputChange={(e) => {
						handleSearch(e);
					}}
				/>
				<Select
					label="Tipo de movimentação"
					variant="light"
					className="md:w-auto md:min-w-[200px]"
					controller={{
						control: form.control,
						name: 'paymentTypeId',
					}}
					options={paymentTypes}
				/>
				<Select
					label="Status"
					variant="light"
					className="md:w-auto md:min-w-[200px]"
					controller={{
						control: form.control,
						name: 'transactionStatusId',
					}}
					options={transactionsStatus}
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="initialDate"
					label="De"
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="endDate"
					label="Até"
				/>
			</FiltersTab>
			<WhiteContainer>
				<Table<TransactionModel>
					params={params}
					data={data?.data.data || []}
					isLoading={isLoading || isFetching}
					total={data?.data.meta.pagination.total || 0}
					onParamsChange={(params) => {
						setParams(params);
					}}
					disabledSort={{
						cancellationDate: true,
						cancellationOperatorId: true,
						companyId: true,
						companyName: true,
						date: true,
						externalId: true,
						id: true,
						observation: true,
						orderId: true,
						paymentType: true,
						paymentTypeId: true,
						transactionStatusId: true,
						userBuyerName: true,
						value: true,
					}}
					columns={[
						{
							Header: 'Transação',
							accessor: 'id',
							Cell: ({ row }) => {
								const { observation, id } = row.original;
								return (
									<span className="flex text-lg font-bold text-orange">
										{observation ? (
											<Tooltip
												triggerClassName="flex items-center gap-2"
												message={`Motivo: ${observation}`}
											>
												#{String(id).padStart(5, '0')}
												<BsFillInfoCircleFill
													className="text-neutral-800"
													size={16}
												/>
											</Tooltip>
										) : (
											<>#{String(id).padStart(5, '0')}</>
										)}
									</span>
								);
							},
						},
						{
							Header: 'Cliente',
							accessor: 'companyName',
						},
						{
							Header: 'Data',
							accessor: 'date',
							Cell: ({ value }) => {
								return <>{dayjs(value).format('DD/MM/YYYY HH:mm')}</>;
							},
						},
						{
							Header: 'Movimentação',
							accessor: 'paymentType',
							Cell: ({ row }) => {
								const { orderId, paymentType } = row.original;
								return (
									<div>
										{orderId ? (
											<Link
												to={`/pedidos/${orderId}`}
												className="flex w-fit rounded bg-neutral-100 px-2 font-bold transition-all hover:bg-neutral-200"
											>
												Frete {orderId}
											</Link>
										) : (
											<div className="flex">{paymentType}</div>
										)}
									</div>
								);
							},
						},
						{
							Header: 'Valor',
							accessor: 'value',
							Cell: ({ value }) => {
								return (
									<div className="flex text-white">
										{parseFloat(value) < 0 ? (
											<div className="flex w-fit gap-1 rounded px-2 text-terracota-500">
												<AiOutlineArrowDown />
												{parseFloat(value).toLocaleString('pt-br', {
													style: 'currency',
													currency: 'BRL',
												})}
											</div>
										) : (
											<div className="flex w-fit items-center gap-1 rounded px-2 text-green">
												<AiOutlineArrowUp />
												{parseFloat(value).toLocaleString('pt-br', {
													style: 'currency',
													currency: 'BRL',
												})}
											</div>
										)}
									</div>
								);
							},
						},
						{
							Header: 'Responsável',
							accessor: 'userBuyerName',
						},
						{
							Header: 'Status',
							accessor: 'transactionStatusId',
							Cell: ({ value }) => (
								<div className="flex">
									{value === appState.transactionStatus.Approved ||
									value === appState.transactionStatus.Confirmed ? (
										<div className="w-fit rounded bg-green px-1.5 py-px text-center text-xs text-white">
											Realizado
										</div>
									) : value === appState.transactionStatus.Pending ||
									  value === appState.transactionStatus.NotProcessed ||
									  value === appState.transactionStatus.Authorized ? (
										<div className="w-fit rounded bg-orange px-1.5 py-px text-center text-xs text-white">
											Pendente
										</div>
									) : value === appState.transactionStatus.Waiting ? (
										<div className="w-fit rounded bg-orange px-1.5 py-px text-center text-xs text-white">
											Aguardando
										</div>
									) : value === appState.transactionStatus.Denied ? (
										<div className="w-fit rounded bg-terracota-500 px-1.5 py-px text-center text-xs text-white">
											Recusado
										</div>
									) : value === appState.transactionStatus.Canceled ? (
										<div className="w-fit rounded bg-terracota-500 px-1.5 py-px text-center text-xs text-white">
											Cancelado
										</div>
									) : value === appState.transactionStatus.Error ? (
										<div className="w-fit rounded bg-terracota-500 px-1.5 py-px text-center text-xs text-white">
											Erro
										</div>
									) : value === appState.transactionStatus.Expired ? (
										<div className="w-fit rounded bg-blue px-1.5 py-px text-center text-xs text-white">
											Expirado
										</div>
									) : (
										<div className="w-fit rounded bg-terracota-500 px-1.5 py-px text-center text-xs text-white">
											Desconhecido
										</div>
									)}
								</div>
							),
						},
						{
							Header: 'Ações',
							accessor: 'paymentTypeId',
							width: '0%',
							Cell: ({ row }) => {
								const { paymentTypeId, transactionStatusId } = row.original;
								if (paymentTypeId === 2 || paymentTypeId === 8) {
									return (
										<span className="flex cursor-pointer">
											{(paymentTypeId === 2 && transactionStatusId === 2) ||
											(paymentTypeId === 8 && transactionStatusId !== 1) ? (
												<span className="text-terracota-500">
													<MdCancel
														size={25}
														// eslint-disable-next-line @typescript-eslint/no-misused-promises
														onClick={async () =>
															await handleCancelTransaction(row.original.id)
														}
													/>
												</span>
											) : (
												<span className="text-terracota-500/30">
													<MdCancel size={25} />
												</span>
											)}
										</span>
									);
								} else {
									return <div></div>;
								}
							},
						},
					]}
				/>
			</WhiteContainer>
		</div>
	);
};

export default Transactions;
