import { z } from 'zod';
import { ErrorsMessageHelper } from '../../../../../helpers/errors/ErrorsMessageHelper';
import { cellphoneOnlyRegex } from '../../../../../helpers/mask/phoneMask';
import { RegexHelper } from '../../../../../helpers/regex/RegexHelper';

export const editUserSchema = z.object({
	id: z.number().or(z.string()).optional(),
	name: z.string().optional().nullable(),
	email: z
		.string()
		.email({ message: ErrorsMessageHelper.email })
		.optional()
		.or(z.literal('')),
	avatar: z.string().optional().nullable(),
	cpf: z
		.string()
		.min(1, { message: ErrorsMessageHelper.required })
		.min(14, { message: ErrorsMessageHelper.min(14) })
		.max(18, { message: ErrorsMessageHelper.max(18) })
		.regex(RegexHelper.cpf, {
			message: ErrorsMessageHelper.cpf,
		}),
	cellphone: z
		.string()
		.regex(cellphoneOnlyRegex, {
			message: ErrorsMessageHelper.phone,
		})
		.optional()
		.or(z.literal(''))
		.nullable(),
	code: z.string().optional().nullable(),
	pix: z.string().optional().nullable(),
});

export type EditUserSchemaType = z.infer<typeof editUserSchema>;
