import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
	FormHeader,
	Input,
	Select,
	Textarea,
	useZodForm,
} from '../../../../../components/FormElements';
import { PageHeader } from '../../../../../components/PageHeader';
import WhiteContainer, {
	WhiteContainerMenuInterface,
} from '../../../../../components/WhiteContainer/WhiteContainer';
import useUserState from '../../../../../services/angular/angularUserState';
import { api } from '../../../../../services/angular/axios';
import { useAppState } from '../../../../../store/appState';

import {
	NotificationSchema,
	NotificationSchemaType,
} from './schemas/NotificationSchema';
import Button from '../../../../../components/Button/Button';

const Notifications = () => {
	const geofence = useAppState((state) => state.geofence);
	const company = useUserState((state) => state.company);
	const [tableMenu, setTableMenu] = useState<WhiteContainerMenuInterface[]>([
		{
			name: 'Usuários',
			selected: true,
		},
		{
			name: 'Telefones',
			selected: false,
		},
	]);

	const {
		register,
		control,
		watch,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useZodForm({
		schema: NotificationSchema,
		defaultValues: {
			geofenceId: {
				label: geofence?.[0]?.name,
				value: geofence?.[0]?.id,
			},
			wasTrained: { value: -1, label: 'Todos' },
			blocked: { value: '', label: 'Todos' },
			signupStatus: { value: -1, label: 'Todos' },
		},
	});

	const changeMenu = useCallback(
		(index: number): void => {
			const menus = [...tableMenu];

			menus.map((menuItem) => {
				menuItem.selected = false;
				return true;
			});

			menus[index].selected = true;
			setTableMenu(menus);

			reset(
				{
					message: watch('message'),
					title: watch('title'),
					types: watch('types'),
				},
				{
					keepDefaultValues: true,
				}
			);
			setValue('formType', index === 0 ? 'users' : 'phones');
		},
		[watch('message'), watch('title'), watch('types')]
	);

	const { data: vehicleCategories } = useQuery(
		['vehicles', company?.id, watch('geofenceId')],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/categories`,
				{
					params: {
						// @ts-expect-error
						geofenceId: watch('geofenceId').value,
					},
				}
			),
		{
			enabled: !!watch('geofenceId'),
		}
	);

	const sendNotification = async ({
		formType,
		...data
	}: NotificationSchemaType) => {
		try {
			const response =
				formType === 'users'
					? await api.post('/users/send-notification-users', {
							...data,
					  })
					: await api.post('/users/send-notification-phones', {
							...data,
					  });
			toast.success(response.data.message);
		} catch (error) {
			toast.error('Não foi possível enviar as notificações');
		}
	};

	const subCategories = useMemo(() => {
		const sub = vehicleCategories?.data.find(
			(category: { id: number }) =>
				category.id === watch('categories')?.[0]?.value
		);
		return sub?.typeOneActive && sub?.typeTwoActive
			? [
					{ value: 1, label: sub.typeOneDescription },
					{ value: 2, label: sub.typeTwoDescription },
			  ]
			: [];
	}, [watch('categories')]);

	return (
		<>
			<PageHeader title="Notificações" description="Envio de Notificações" />

			<WhiteContainer menu={tableMenu} onClickMenu={changeMenu}>
				<FormHeader
					title={
						tableMenu[0].selected
							? 'Enviar para Usuários'
							: 'Enviar para telefones específicos'
					}
				/>
				<div className="mt-4 flex flex-col gap-4 md:flex-row">
					<div className="flex w-full flex-col gap-4 md:w-1/3">
						{tableMenu[0].selected ? (
							<>
								<Select
									label="Status"
									controller={{
										control,
										name: 'active',
									}}
									options={[
										{ value: true, label: 'Ativo' },
										{ value: false, label: 'Inativo' },
									]}
								/>
								<Select
									label="Status bloqueado"
									controller={{
										control,
										name: 'blocked',
									}}
									options={[
										{ value: '', label: 'Todos' },
										{ value: 'blocked', label: 'Bloqueado' },
										{ value: 'unblocked', label: 'Desbloqueado' },
									]}
								/>
								<Select
									label="Geofence"
									controller={{
										control,
										name: 'geofenceId',
									}}
									options={geofence?.map((state) => ({
										label: state.name,
										value: state.id,
									}))}
								/>
								<Select
									label="Situação de cadastro do usuário"
									controller={{
										control,
										name: 'signupStatus',
									}}
									options={[
										{ value: -1, label: 'Todos' },
										{ value: 1, label: 'Pendente' },
										{ value: 2, label: 'Rejeitado' },
										{ value: 3, label: 'Aprovado' },
									]}
								/>
								<Select
									label="Treinado"
									controller={{
										control,
										name: 'wasTrained',
									}}
									options={[
										{ value: -1, label: 'Todos' },
										{ value: 1, label: 'Sim' },
										{ value: 0, label: 'Não' },
									]}
								/>
								<Select
									label="Perfil"
									controller={{
										control,
										name: 'userTypes',
									}}
									isMulti
									options={[
										{ value: 0, label: 'Motorista' },
										{ value: 4, label: 'Biker' },
										{ value: 1, label: 'Empresa' },
										{ value: 2, label: 'Fretista' },
										{ value: 3, label: 'Pessoa física' },
									]}
								/>
								<Select
									label="Disponibilidade"
									controller={{
										control,
										name: 'userAvailabilities',
									}}
									isMulti
									options={[
										{ value: 0, label: 'Indisponível' },
										{ value: 1, label: 'Fazendo frete' },
										{ value: 2, label: 'Disponível e offline' },
										{ value: 3, label: 'Disponível e online' },
									]}
								/>
								{watch('userTypes')?.find(
									({ value }: any) => value === 2 || value === 0
								) && (
									<>
										<Select
											label="Categoria de veículo"
											controller={{
												control,
												name: 'categories',
											}}
											isMulti
											options={vehicleCategories?.data.map(
												(category: { name: string; id: number }) => ({
													label: category.name,
													value: category.id,
												})
											)}
										/>
										{watch('categories')?.length === 1 && (
											<>
												<Select
													label="SubCategoria"
													controller={{
														control,
														name: 'subCategory',
													}}
													defaultValue={{
														value: -1,
														label: 'Todas',
													}}
													options={[
														{
															value: -1,
															label: 'Todas',
														},
														...subCategories,
													]}
												/>
											</>
										)}
									</>
								)}
							</>
						) : (
							<Textarea
								rows={3}
								label="Telefones (separados por ,)"
								{...register('phones')}
							/>
						)}
					</div>

					<div className="flex w-full flex-col gap-4 md:w-2/3">
						<Select
							label="Tipo de envio"
							controller={{
								control,
								name: 'types',
							}}
							isMulti
							options={[
								{ value: 0, label: 'SMS' },
								{ value: 1, label: 'Push' },
							]}
						/>
						<Input
							label="Título"
							{...register('title')}
							errorMessage={errors.title?.message}
						/>

						<Textarea
							rows={8}
							label="Mensagem"
							{...register('message')}
							errorMessage={errors.message?.message}
						/>
					</div>
				</div>
			</WhiteContainer>
			<Button
				variant="blue-primary"
				className="mt-4 w-auto items-end self-end"
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				onClick={handleSubmit(sendNotification)}
			>
				Enviar Notificação
			</Button>
		</>
	);
};

export default Notifications;
