import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import React from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { IoLogOutOutline } from 'react-icons/io5';
import { MdAddBusiness } from 'react-icons/md';
import { RiUserLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { Avatar } from '../../Avatar';
import { twMerge } from 'tailwind-merge';
import useUserState from '../../../services/angular/angularUserState';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../services/angular/axios';
import { appState } from '../../../store/appState';
import { match } from 'ts-pattern';
import { TbMessageDots } from 'react-icons/tb';

const DropDownMenu: React.FC = () => {
	const { logout, setCompany, company, companies, user, userRole } =
		useUserState((state) => ({
			logout: state.logout,
			setCompany: state.setCompany,
			company: state.company,
			companies: state.companies,
			user: state.user,
			userRole: state.userRole,
		}));
	const navigate = useNavigate();

	const { data: userAvatar, isFetching } = useQuery(
		['user-avatar', user?.avatar],
		async () => {
			if (!user?.avatar) return;
			return await api.get('/assets', {
				params: {
					imageName: user.avatar,
				},
			});
		},
		{
			enabled: !!user?.avatar,
		}
	);

	const { data: companyAvatar, isFetching: isCompanyAvatarLoading } = useQuery(
		['company-avatar', company?.avatar],
		async () => {
			if (!company?.avatar) return;
			return await api.get('/assets', {
				params: {
					imageName: company.avatar,
				},
			});
		},
		{
			enabled: !!company?.avatar,
		}
	);

	const { data: avatarUrls, isFetching: urlsLoading } = useQuery(
		['companies-avatar', companies.map((c) => c.avatar)],
		async () =>
			await Promise.all(
				companies.map(async (c) => {
					if (!c.avatar) return;

					const { data } = await api.get('/assets', {
						params: {
							imageName: c.avatar,
						},
					});

					const imageUrl: string = data?.url;

					return imageUrl;
				})
			)
	);

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger className="focus:outline-none">
				<div
					className={twMerge(
						'flex gap-4 bg-white/5 p-1 transition-all hover:bg-white/10',
						'rounded-xl border border-solid border-white/20 backdrop-blur-sm'
					)}
				>
					{userRole?.id === Number(appState.userRoles.user.value) &&
						company && (
							<span className="hidden flex-col items-end pl-1 text-yellow-400 md:flex">
								<h1 className="max-w-xs truncate font-semibold ">
									{company?.tradingName || company?.companyName}
								</h1>
								<h1 className="text-xs ">{company?.cnpj}</h1>
							</span>
						)}
					<Avatar
						isLoading={isCompanyAvatarLoading}
						avatarProps={{
							className: 'w-10 h-10',
						}}
						name={company?.tradingName || company?.companyName || user?.name}
						src={
							userRole?.id === Number(appState.userRoles.user.value)
								? companyAvatar?.data.url
								: userAvatar?.data.url
						}
					/>
				</div>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content className="max-h-[80vh] w-screen overflow-y-auto rounded bg-neutral-100 p-6 text-neutral-0 shadow-md shadow-neutral-500 md:mr-4 md:max-w-lg md:p-4">
					<div
						className={twMerge(
							'flex items-center gap-3 bg-white p-3',
							userRole?.id === Number(appState.userRoles.user.value)
								? 'rounded-t'
								: 'rounded'
						)}
					>
						<Avatar
							isLoading={isFetching}
							avatarProps={{
								className: 'min-w-[80px] min-h-[80px] text-3xl',
							}}
							name={user?.name}
							src={userAvatar?.data.url}
						/>
						<div className="flex max-w-full flex-col gap-1">
							<h1 className="text-lg font-semibold">
								{user?.name
									.split(' ')
									.map((word) =>
										word ? word[0]?.toUpperCase() + word.slice(1) : ''
									)
									.join(' ')}
							</h1>
							{userRole?.id === Number(appState.userRoles.user.value) && (
								<DropdownMenu.Item
									onClick={() => {
										navigate('/negocios/gerenciador');
									}}
									className="mt-1 cursor-pointer self-start rounded border border-neutral-0 px-1 outline-none hover:bg-neutral-300"
								>
									Gerenciar Empresas
								</DropdownMenu.Item>
							)}
						</div>
					</div>

					{userRole?.id === Number(appState.userRoles.user.value) && (
						<div className="mt-px flex max-h-72 flex-col gap-2 rounded-b bg-white p-3 md:max-h-96">
							<DropdownMenu.Item
								onClick={() => {
									navigate('/minha-conta');
								}}
								className="flex cursor-pointer items-center gap-4 rounded px-4 py-1 outline-none hover:bg-neutral-100"
							>
								<span className="flex w-8 justify-center text-neutral-0">
									<RiUserLine size={24} />
								</span>
								Minha conta
							</DropdownMenu.Item>

							<DropdownMenu.Item
								onClick={() => {
									window.open(
										'https://beebeelogistica.freshdesk.com/support/home',
										'_blank'
									);
								}}
								className="flex cursor-pointer items-center gap-4 rounded px-4 py-1 outline-none hover:bg-neutral-100"
							>
								<span className="-my-1 flex w-8 justify-center text-neutral-0">
									<TbMessageDots size={26} />
								</span>
								Suporte
							</DropdownMenu.Item>

							<div className="flex items-center gap-3 text-neutral-500">
								Empresas
								<hr className="w-full" />
							</div>

							<div className="overflow-y-auto">
								{companies.map((c, index) => (
									<DropdownMenu.Item
										key={`${c.id}-${c.type}`}
										className={twMerge(
											'flex cursor-pointer items-center justify-between gap-4 px-4 py-1 text-sm focus:outline-none hover:bg-neutral-100',
											'select-none rounded border-none',
											`${
												company?.id === c.id && company.type === c.type
													? 'bg-yellow-500/50'
													: ''
											}`
										)}
										onClick={() => {
											setCompany(c);
										}}
									>
										<div className="flex items-center gap-2">
											<Avatar
												avatarProps={{ className: 'w-8 h-8' }}
												name={c.tradingName || c.companyName}
												src={avatarUrls?.[index]}
												isLoading={urlsLoading}
											/>
											<h1 className="max-w-[248px] truncate">
												{c.tradingName || c.companyName}
											</h1>
										</div>

										<div className="hidden gap-2 text-center text-xs font-semibold text-white sm:flex">
											{match(c)
												.with({ type: 'Empresa' }, () => (
													<div className="rounded bg-empresa px-1 py-px">
														Empresa
													</div>
												))
												.with({ type: 'Fretista' }, () => (
													<div className="rounded bg-fretista px-1 py-px">
														Fretista
													</div>
												))
												.with({ type: 'Motorista' }, () => (
													<div className="rounded bg-neutral-700 px-1 py-px">
														Motorista
													</div>
												))
												.with({ type: 'Usuário' }, () => (
													<div className="rounded bg-primary-0 px-1 py-px">
														Usuário
													</div>
												))
												.with({ type: 'Usuário Admin' }, (c) => (
													<div
														className={twMerge(
															'rounded px-1 py-px',
															c.makeFreights ? 'bg-fretista' : 'bg-empresa'
														)}
													>
														Usuário Admin
													</div>
												))
												.otherwise(() => (
													<div className="rounded bg-neutral-900 px-1 py-px">
														Acesso Pessoal
													</div>
												))}
										</div>
									</DropdownMenu.Item>
								))}
							</div>

							<DropdownMenu.Item
								onClick={() => {
									navigate('/negocios/novo');
								}}
								className="flex cursor-pointer items-center gap-4 rounded px-4 py-1 outline-none hover:bg-neutral-100"
							>
								<span className="flex w-8 justify-center">
									<MdAddBusiness size={24} />
								</span>
								Cadastrar novo negócio
							</DropdownMenu.Item>
						</div>
					)}

					<DropdownMenu.Item
						onClick={() => {
							void logout();
						}}
						className="my-2 flex cursor-pointer items-center gap-6 rounded px-3 py-1 outline-none hover:bg-neutral-200"
					>
						<IoLogOutOutline size={24} className="text-neutral-0" /> Sair
					</DropdownMenu.Item>

					<hr className="w-full" />
					<div className="flex items-center justify-center gap-4 pt-3 text-xs text-neutral-800">
						<a
							href="/termos-de-uso"
							target="_blank"
							className="flex items-center gap-1 hover:underline"
							rel="noreferrer"
						>
							Termos de uso <FiExternalLink />
						</a>
						<span>●</span>
						<a
							href="/politica-privacidade"
							target="_blank"
							className="flex items-center gap-1 hover:underline"
							rel="noreferrer"
						>
							Politica de privacidade <FiExternalLink />
						</a>
					</div>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};
export default DropDownMenu;
