const Privacy = () => {
	const html = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
	<html xmlns="http://www.w3.org/1999/xhtml" xml:lang="pt" lang="pt">
		<head>
			<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
			<title>file_1697480360173</title>
			<style type="text/css">
				* {
					margin: 0;
					padding: 0;
					text-indent: 0;
				}
				.paddingDiv {
					display: flex;
					max-width: 100vw;
					flex-direction: column;
					padding: 24px 140px;
					overflow: hidden;
				}
	
				@media (max-width: 1024px) {
					.paddingDiv {
						padding: 24px 24px;
					}
				}
				.h1,
				h1 {
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: bold;
					text-decoration: none;
					font-size: 12pt;
				}
				.p,
				p {
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: none;
					font-size: 12pt;
					margin: 0pt;
				}
				.s2 {
					color: #fff;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: bold;
					text-decoration: none;
					font-size: 12pt;
				}
				.s3 {
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: bold;
					text-decoration: none;
					font-size: 12pt;
				}
				.s4 {
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: none;
					font-size: 12pt;
				}
				.s5 {
					color: black;
					font-family: Calibri, sans-serif;
					font-style: italic;
					font-weight: bold;
					text-decoration: none;
					font-size: 12pt;
				}
				.s7 {
					color: #00f;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: underline;
					font-size: 12pt;
				}
				.a,
				a {
					color: #1f1f1f;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: none;
					font-size: 12pt;
				}
				.s8 {
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: none;
					font-size: 12pt;
				}
				.s9 {
					color: #111;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: none;
					font-size: 12pt;
				}
				li {
					display: block;
				}
				#l1 {
					padding-left: 0pt;
					counter-reset: c1 1;
				}
				#l1 > li > *:first-child:before {
					counter-increment: c1;
					content: counter(c1, decimal) '. ';
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: bold;
					text-decoration: none;
					font-size: 12pt;
				}
				#l1 > li:first-child > *:first-child:before {
					counter-increment: c1 0;
				}
				#l2 {
					padding-left: 0pt;
				}
				#l2 > li > *:first-child:before {
					content: '● ';
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: none;
					font-size: 9.5pt;
				}
				#l3 {
					padding-left: 0pt;
					counter-reset: d1 1;
				}
				#l3 > li > *:first-child:before {
					counter-increment: d1;
					content: counter(d1, upper-latin) ') ';
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: bold;
					text-decoration: none;
					font-size: 12pt;
				}
				#l3 > li:first-child > *:first-child:before {
					counter-increment: d1 0;
				}
				#l4 {
					padding-left: 0pt;
					counter-reset: d2 1;
				}
				#l4 > li > *:first-child:before {
					counter-increment: d2;
					content: counter(d1, upper-latin) '.' counter(d2, decimal) ') ';
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: bold;
					text-decoration: none;
					font-size: 12pt;
				}
				#l4 > li:first-child > *:first-child:before {
					counter-increment: d2 0;
				}
				#l5 {
					padding-left: 0pt;
				}
				#l5 > li > *:first-child:before {
					content: '- ';
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: none;
					font-size: 12pt;
				}
				#l6 {
					padding-left: 0pt;
				}
				#l6 > li > *:first-child:before {
					content: '● ';
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: none;
					font-size: 12pt;
				}
				#l7 {
					padding-left: 0pt;
					counter-reset: f1 1;
				}
				#l7 > li > *:first-child:before {
					counter-increment: f1;
					content: '(' counter(f1, lower-roman) ') ';
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: none;
					font-size: 12pt;
				}
				#l7 > li:first-child > *:first-child:before {
					counter-increment: f1 0;
				}
				#l8 {
					padding-left: 0pt;
				}
				#l8 > li > *:first-child:before {
					content: '– ';
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: none;
					font-size: 12pt;
				}
				#l9 {
					padding-left: 0pt;
					counter-reset: h1 1;
				}
				#l9 > li > *:first-child:before {
					counter-increment: h1;
					content: counter(h1, decimal) ') ';
					color: black;
					font-family: Calibri, sans-serif;
					font-style: normal;
					font-weight: normal;
					text-decoration: none;
					font-size: 12pt;
				}
				#l9 > li:first-child > *:first-child:before {
					counter-increment: h1 0;
				}
				table,
				tbody {
					vertical-align: top;
					overflow: visible;
				}
			</style>
		</head>
		<body>
			<h1
				style="
					text-indent: 0pt;
					text-align: center;
					background-color: #fcf366;
					height: 128px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16pt;
					font-size: 42px;
					color: #393939;
				"
			>
				Política de Privacidade
			</h1>
			<div class="paddingDiv">
				<p style="text-indent: 0pt; text-align: left"><br /></p>
				<p
					style="
						padding-left: 6pt;
						text-indent: 0pt;
						line-height: 114%;
						text-align: justify;
					"
				>
					Por meio desta política, demonstramos nossa preocupação com a
					privacidade, proteção, segurança e transparência de como os dados são
					utilizados por nós. Assim, ao longo desta política, descreveremos como
					obtemos, armazenamos, utilizamos e compartilhamos os seus dados
					pessoais.
				</p>
				<p style="text-indent: 0pt; text-align: left"><br /></p>
				<p
					style="
						padding-left: 6pt;
						text-indent: 0pt;
						line-height: 114%;
						text-align: justify;
					"
				>
					Esta política é aplicável para todos os nossos canais de relacionamento.
					Caso não esteja de acordo com os termos definidos nesta política, você
					poderá descontinuar o acesso ou solicitar a exclusão de sua conta.
				</p>
				<p style="text-indent: 0pt; text-align: left"><br /></p>
				<ol id="l1">
					<li data-list-text="1.">
						<h1 style="padding-left: 42pt; text-indent: -18pt; text-align: left">
							DADOS COLETADOS, FORMA DE COLETA E FINALIDADE
						</h1>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 114%;
								text-align: justify;
							"
						>
							Quando você interage eletrônica ou fisicamente com a BeeBee, podemos
							precisar receber ou coletar informações sobre você. Coletamos o
							mínimo de dados possível para atingir nossos objetivos e apenas
							utilizamos os dados para as finalidades para as quais foram
							coletados.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 117%;
								text-align: justify;
							"
						>
							De modo geral, coletamos, usamos, armazenamos e processamos os seus
							dados pessoais para que a entrega dos nossos serviços seja possível,
							bem como para manter e melhorar nossas atividades. A finalidade do
							tratamento poderá ser alterada de acordo com o serviço desejado.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p style="padding-left: 6pt; text-indent: 0pt; text-align: left">
							A coleta de dados é realizada com os seguintes objetivos:
						</p>
						<ul id="l2">
							<li data-list-text="●">
								<p
									style="
										padding-top: 1pt;
										padding-left: 55pt;
										text-indent: -18pt;
										text-align: left;
									"
								>
									Garantir a eficácia na prestação dos nossos serviços;
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-top: 2pt;
										padding-left: 55pt;
										text-indent: -18pt;
										line-height: 117%;
										text-align: left;
									"
								>
									Adequar a aparência e o conteúdo das nossas Aplicações de
									Internet às preferências do Titular, a fim de proporcionar-lhe
									acesso mais rápido, agradável, personalizado e eficaz;
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-left: 55pt;
										text-indent: -18pt;
										line-height: 13pt;
										text-align: left;
									"
								>
									Autenticar acessos às nossas Aplicações de Internet;
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-top: 2pt;
										padding-left: 55pt;
										text-indent: -18pt;
										line-height: 112%;
										text-align: justify;
									"
								>
									Buscar segurança adequada aos serviços oferecidos e meios de
									identificação no caso de usos impróprios ou ilícitos;
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-left: 55pt;
										text-indent: -18pt;
										line-height: 112%;
										text-align: justify;
									"
								>
									Resolver dúvidas, problemas e formular notificações relacionadas
									ao uso das nossas Aplicações de Internet;
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-left: 55pt;
										text-indent: -18pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									Manter os Titulares informados sobre os serviços, alterações nos
									Termos de Uso ou na Política de Privacidade e de Proteção de
									Dados, atualizações ou melhorias nas nossas Aplicações de
									Internet, ou ainda sobre o status de suas contas e operações;
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-left: 55pt;
										text-indent: -18pt;
										line-height: 112%;
										text-align: justify;
									"
								>
									Manter contato por telefone, e-mail, SMS, WhatsApp, notificações
									ou outros meios de comunicação;
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-left: 55pt;
										text-indent: -18pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									Divulgar ações promocionais outras iniciativas de publicidade da
									BeeBee, sempre disponibilizando meios para o Titular cancelar o
									recebimento de e-mails, mensagens, notificações e ligações
									promocionais (<i>opt out</i>);
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-left: 55pt;
										text-indent: -18pt;
										text-align: justify;
									"
								>
									Determinar a eficácia da nossa publicidade;
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-top: 1pt;
										padding-left: 55pt;
										text-indent: -18pt;
										text-align: justify;
									"
								>
									Avaliar a qualidade do atendimento de nossos colaboradores;
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-top: 2pt;
										padding-left: 55pt;
										text-indent: -18pt;
										line-height: 117%;
										text-align: justify;
									"
								>
									Realizar cancelamento de compras, estorno de valores ou trocas
									de produtos, observado o disposto na legislação vigente;
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-left: 55pt;
										text-indent: -18pt;
										line-height: 13pt;
										text-align: justify;
									"
								>
									Cumprir com obrigações legais e regulatórias, e com os contratos
									firmados com os
								</p>
								<p
									style="
										padding-top: 2pt;
										padding-left: 55pt;
										text-indent: 0pt;
										text-align: left;
									"
								>
									Titulares;
								</p>
							</li>
							<li data-list-text="●">
								<p
									style="
										padding-top: 1pt;
										padding-left: 55pt;
										text-indent: -18pt;
										text-align: left;
									"
								>
									Exercer regularmente direitos da BeeBee
								</p>
								<p
									style="
										padding-top: 1pt;
										padding-left: 6pt;
										text-indent: 0pt;
										line-height: 117%;
										text-align: justify;
									"
								>
									Em nosso site, a coleta de Dados Pessoais poderá ocorrer de duas
									formas: uma ativa e uma automática.
								</p>
								<ol id="l3">
									<li data-list-text="A)" style="max-width: 80vw; overflow-x: scroll">
										<h1
											style="
												padding-left: 42pt;
												text-indent: -18pt;
												line-height: 13pt;
												text-align: left;
											"
										>
											Coleta ativa
										</h1>
										<p style="text-indent: 0pt; text-align: left"><br /></p>
										<p
											style="
												padding-left: 6pt;
												text-indent: 0pt;
												line-height: 118%;
												text-align: justify;
											"
										>
											A coleta ativa dos dados pessoais ocorre quando o próprio
											Titular insere suas informações no site nas seguintes
											situações:
										</p>
										<p style="text-indent: 0pt; text-align: left"><br /></p>
										<span style="max-width:100px; overflow: hidden; background-color: #222222;">
											<table
											style="border-collapse: collapse; margin-left: 5.725pt"
											cellspacing="0"
										>
											<tr style="height: 15pt">
												<td
													style="
														width: 151pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
													bgcolor="#7E7E7E"
												>
													<p
														class="s2"
														style="
															padding-left: 20pt;
															padding-right: 19pt;
															text-indent: 0pt;
															text-align: center;
														"
													>
														FORMA DE COLETA
													</p>
												</td>
												<td
													style="
														width: 150pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
													bgcolor="#7E7E7E"
												>
													<p
														class="s2"
														style="
															padding-left: 29pt;
															text-indent: 0pt;
															text-align: left;
														"
													>
														DADOS COLETADOS
													</p>
												</td>
												<td
													style="
														width: 151pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
													bgcolor="#7E7E7E"
												>
													<p
														class="s2"
														style="
															padding-left: 47pt;
															text-indent: 0pt;
															text-align: left;
														"
													>
														FINALIDADE
													</p>
												</td>
											</tr>
											<tr style="height: 78pt">
												<td
													style="
														width: 151pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
												>
													<p
														class="s3"
														style="
															padding-left: 21pt;
															padding-right: 19pt;
															text-indent: 0pt;
															text-align: center;
														"
													>
														Cadastro de nova conta
													</p>
												</td>
												<td
													style="
														width: 150pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
												>
													<p
														class="s4"
														style="
															padding-left: 26pt;
															padding-right: 25pt;
															text-indent: 0pt;
															line-height: 118%;
															text-align: center;
														"
													>
														Nome completo; Email;
													</p>
													<p
														class="s4"
														style="
															padding-left: 26pt;
															padding-right: 25pt;
															text-indent: 0pt;
															line-height: 13pt;
															text-align: center;
														"
													>
														CPF;
													</p>
													<p
														class="s4"
														style="
															padding-top: 2pt;
															padding-left: 26pt;
															padding-right: 25pt;
															text-indent: 0pt;
															text-align: center;
														"
													>
														Telefone celular;
													</p>
												</td>
												<td
													style="
														width: 151pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
												>
													<p
														class="s4"
														style="
															padding-left: 48pt;
															text-indent: -39pt;
															line-height: 118%;
															text-align: left;
														"
													>
														Cadastro inicial para acesso à plataforma.
													</p>
												</td>
											</tr>
											<tr style="height: 62pt">
												<td
													style="
														width: 151pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
												>
													<p
														class="s3"
														style="
															padding-left: 21pt;
															padding-right: 19pt;
															text-indent: 0pt;
															text-align: center;
														"
													>
														Cadastro de CNH
													</p>
												</td>
												<td
													style="
														width: 150pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
												>
													<p
														class="s4"
														style="
															padding-left: 27pt;
															padding-right: 26pt;
															text-indent: 0pt;
															line-height: 114%;
															text-align: center;
														"
													>
														Número da CNH; Vencimento da CNH; RG;
													</p>
												</td>
												<td
													style="
														width: 151pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
												>
													<p
														class="s4"
														style="
															padding-left: 15pt;
															padding-right: 14pt;
															text-indent: 0pt;
															line-height: 114%;
															text-align: center;
														"
													>
														Validação de pré-requisito para atuação como
														entregador.
													</p>
												</td>
											</tr>
											<tr style="height: 62pt">
												<td
													style="
														width: 151pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
												>
													<p
														class="s3"
														style="
															padding-left: 20pt;
															padding-right: 19pt;
															text-indent: 0pt;
															text-align: center;
														"
													>
														Cartão de crédito
													</p>
												</td>
												<td
													style="
														width: 150pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
												>
													<p
														class="s4"
														style="
															padding-left: 23pt;
															padding-right: 23pt;
															text-indent: 0pt;
															line-height: 114%;
															text-align: center;
														"
													>
														Número do cartão; Nome do proprietário; Validade;
													</p>
													<p
														class="s4"
														style="
															padding-left: 26pt;
															padding-right: 25pt;
															text-indent: 0pt;
															line-height: 13pt;
															text-align: center;
														"
													>
														Código de segurança.
													</p>
												</td>
												<td
													style="
														width: 151pt;
														border-top-style: solid;
														border-top-width: 1pt;
														border-top-color: #bebebe;
														border-left-style: solid;
														border-left-width: 1pt;
														border-left-color: #bebebe;
														border-bottom-style: solid;
														border-bottom-width: 1pt;
														border-bottom-color: #bebebe;
														border-right-style: solid;
														border-right-width: 1pt;
														border-right-color: #bebebe;
													"
												>
													<p
														class="s4"
														style="
															padding-left: 19pt;
															padding-right: 20pt;
															text-indent: 1pt;
															line-height: 114%;
															text-align: center;
														"
													>
														Cadastro de cartão para pagamento dos serviços
														solicitados.
													</p>
												</td>
											</tr>
										</table>
										</span>
										
										<p style="text-indent: 0pt; text-align: left"><br /></p>
									</li>
									<li data-list-text="B)">
										<h1
											style="
												padding-left: 42pt;
												text-indent: -18pt;
												text-align: left;
											"
										>
											Coleta automática
										</h1>
										<p style="text-indent: 0pt; text-align: left"><br /></p>
										<p
											style="
												padding-left: 6pt;
												text-indent: 0pt;
												line-height: 114%;
												text-align: justify;
											"
										>
											A coleta automática dos dados acontece por meio da
											utilização dos <i>Cookies</i>. Os dados são capturados
											quando você acessa os serviços no site e da rede em que o
											acessou, como por exemplo, dados de navegação e
											identificação do estabelecimento comercial utilizado.
										</p>
										<p style="text-indent: 0pt; text-align: left"><br /></p>
										<ol id="l4">
											<li data-list-text="B.1)">
												<h1
													style="
														padding-left: 26pt;
														text-indent: -20pt;
														text-align: left;
													"
												>
													O QUE SÃO COOKIES?
												</h1>
												<p style="text-indent: 0pt; text-align: left"><br /></p>
												<p
													style="
														padding-left: 6pt;
														text-indent: 0pt;
														line-height: 112%;
														text-align: justify;
													"
												>
													Cookies são arquivos ou informações que podem ser
													armazenadas em seu navegador quando você visita o nosso
													site.
												</p>
												<p style="text-indent: 0pt; text-align: left"><br /></p>
												<p
													style="
														padding-left: 6pt;
														text-indent: 0pt;
														line-height: 115%;
														text-align: justify;
													"
												>
													Por meio de Cookies, o site armazena informações sobre
													as atividades do navegador, incluindo endereço IP e a
													página acessada. Esses registros de atividades (logs)
													são utilizados apenas para fins estatísticos e de
													métricas dos serviços disponibilizados, não tendo como
													finalidade o fornecimento dos dados a terceiros sem
													autorização expressa do Usuário.
												</p>
												<p style="text-indent: 0pt; text-align: left"><br /></p>
											</li>
											<li data-list-text="B.2)" style="max-width: 80vw; overflow-x: scroll;">
												<h1
													style="
														padding-left: 26pt;
														text-indent: -20pt;
														text-align: left;
													"
												>
													QUAIS TIPOS DE COOKIES A BEEBEE UTILIZA E COMO SÃO
													UTILIZADOS?
												</h1>
												<p style="text-indent: 0pt; text-align: left"><br /></p>
												<h1
													style="
														padding-left: 100pt;
														text-indent: 0pt;
														text-align: center;
													"
												>
													CONCEITO/FINALIDADE
												</h1>
												<h1
													style="
														padding-left: 21pt;
														text-indent: 0pt;
														text-align: left;
													"
												>
													TIPOS DE COOKIES
												</h1>
												<p
													style="
														padding-left: 6pt;
														text-indent: 0pt;
														text-align: left;
													"
												/>
												<p style="text-indent: 0pt; text-align: left"><br /></p>
												<table
													style="border-collapse: collapse; margin-left: 6.475pt"
													cellspacing="0"
												>
													<tr style="height: 81pt">
														<td
															style="
																width: 134pt;
																border-left-style: solid;
																border-left-width: 1pt;
																border-bottom-style: solid;
																border-bottom-width: 1pt;
																border-right-style: solid;
																border-right-width: 1pt;
															"
														>
															<p style="text-indent: 0pt; text-align: left">
																<br />
															</p>
															<p
																class="s5"
																style="
																	padding-right: 19pt;
																	text-indent: 0pt;
																	text-align: right;
																"
															>
																Cookies <span class="s3">Necessários</span>
															</p>
														</td>
														<td
															style="
																width: 317pt;
																border-left-style: solid;
																border-left-width: 1pt;
																border-bottom-style: solid;
																border-bottom-width: 1pt;
																border-right-style: solid;
																border-right-width: 1pt;
															"
														>
															<p style="text-indent: 0pt; text-align: left">
																<br />
															</p>
															<p
																class="s4"
																style="
																	padding-left: 4pt;
																	padding-right: 3pt;
																	text-indent: 0pt;
																	line-height: 114%;
																	text-align: justify;
																"
															>
																Cookies essenciais são aqueles utilizados para que
																o
																<i>website </i>da empresa carregue corretamente e
																permita que você navegue, bem como faça o uso de
																todas as funcionalidades disponíveis.
															</p>
														</td>
													</tr>
													<tr style="height: 154pt">
														<td
															style="
																width: 134pt;
																border-top-style: solid;
																border-top-width: 1pt;
																border-left-style: solid;
																border-left-width: 1pt;
																border-bottom-style: solid;
																border-bottom-width: 1pt;
																border-right-style: solid;
																border-right-width: 1pt;
															"
														>
															<p style="text-indent: 0pt; text-align: left">
																<br />
															</p>
															<p
																class="s5"
																style="
																	padding-left: 25pt;
																	text-indent: 0pt;
																	text-align: left;
																"
															>
																Cookies <span class="s3">Analytics</span>
															</p>
														</td>
														<td
															style="
																width: 317pt;
																border-top-style: solid;
																border-top-width: 1pt;
																border-left-style: solid;
																border-left-width: 1pt;
																border-bottom-style: solid;
																border-bottom-width: 1pt;
																border-right-style: solid;
																border-right-width: 1pt;
															"
														>
															<p style="text-indent: 0pt; text-align: left">
																<br />
															</p>
															<p
																class="s4"
																style="
																	padding-left: 4pt;
																	padding-right: 3pt;
																	text-indent: 0pt;
																	line-height: 114%;
																	text-align: justify;
																"
															>
																Nos ajudam a entender como os visitantes interagem
																com a página do site, assim, eles informam as
																áreas visitadas, o tempo de visita e quaisquer
																problemas encontrados, como mensagens de erro.
																Usamos o Google Analytics para monitorar o
																tráfego, medir e melhorar o desempenho do site.
															</p>
															<p style="text-indent: 0pt; text-align: left">
																<br />
															</p>
															<p
																class="s4"
																style="
																	padding-left: 4pt;
																	padding-right: 4pt;
																	text-indent: 0pt;
																	line-height: 112%;
																	text-align: justify;
																"
															>
																Para mais informações sobre cookies do Google
																Analytics, acesse:
																<span class="s7"
																	>https://policies.google.com/technologies/partner-sites</span
																>:
															</p>
														</td>
													</tr>
													<tr style="height: 80pt">
														<td
															style="
																width: 134pt;
																border-top-style: solid;
																border-top-width: 1pt;
																border-left-style: solid;
																border-left-width: 1pt;
																border-bottom-style: solid;
																border-bottom-width: 1pt;
																border-right-style: solid;
																border-right-width: 1pt;
															"
														>
															<p style="text-indent: 0pt; text-align: left">
																<br />
															</p>
															<p
																class="s5"
																style="
																	padding-left: 22pt;
																	text-indent: 0pt;
																	text-align: left;
																"
															>
																Cookies <span class="s3">Estatísticos</span>
															</p>
														</td>
														<td
															style="
																width: 317pt;
																border-top-style: solid;
																border-top-width: 1pt;
																border-left-style: solid;
																border-left-width: 1pt;
																border-bottom-style: solid;
																border-bottom-width: 1pt;
																border-right-style: solid;
																border-right-width: 1pt;
															"
														>
															<p style="text-indent: 0pt; text-align: left">
																<br />
															</p>
															<p
																class="s4"
																style="
																	padding-left: 4pt;
																	padding-right: 3pt;
																	text-indent: 0pt;
																	line-height: 114%;
																	text-align: justify;
																"
															>
																São utilizados para ajudar os proprietários de
																sites a entender como os visitantes interagem com
																os sites, coletando e relatando informações
																anonimamente.
															</p>
														</td>
													</tr>
													<tr style="height: 145pt">
														<td
															style="
																width: 134pt;
																border-top-style: solid;
																border-top-width: 1pt;
																border-left-style: solid;
																border-left-width: 1pt;
																border-bottom-style: solid;
																border-bottom-width: 1pt;
																border-right-style: solid;
																border-right-width: 1pt;
															"
														>
															<p style="text-indent: 0pt; text-align: left">
																<br />
															</p>
															<p
																class="s5"
																style="
																	padding-right: 16pt;
																	text-indent: 0pt;
																	text-align: right;
																"
															>
																Cookies <span class="s3">de Marketing</span>
															</p>
														</td>
														<td
															style="
																width: 317pt;
																border-top-style: solid;
																border-top-width: 1pt;
																border-left-style: solid;
																border-left-width: 1pt;
																border-bottom-style: solid;
																border-bottom-width: 1pt;
																border-right-style: solid;
																border-right-width: 1pt;
															"
														>
															<p style="text-indent: 0pt; text-align: left">
																<br />
															</p>
															<p
																class="s4"
																style="
																	padding-left: 4pt;
																	padding-right: 3pt;
																	text-indent: 0pt;
																	line-height: 115%;
																	text-align: justify;
																"
															>
																São utilizados para fornecer conteúdo mais
																relevante e do interesse dos Usuários. Podem ser
																utilizados para apresentar publicidade com um
																maior direcionamento ou limitar o número que é
																direcionado aos Usuários. Também, permitem a
																medição da eficácia de uma campanha publicitária
																lançada.
															</p>
														</td>
													</tr>
												</table>
												<p style="text-indent: 0pt; text-align: left"><br /></p>
											</li>
											<li data-list-text="B.3)">
												<h1
													style="
														padding-top: 2pt;
														padding-left: 26pt;
														text-indent: -20pt;
														text-align: left;
													"
												>
													COMO GERENCIAR SUAS PREFERÊNCIAS DE COOKIES?
												</h1>
											</li>
										</ol>
									</li>
								</ol>
								<p style="text-indent: 0pt; text-align: left"><br /></p>
								<p
									style="
										padding-left: 6pt;
										text-indent: 0pt;
										line-height: 115%;
										text-align: justify;
									"
								>
									Na BeeBee, você, como titular de dados, tem total e pleno
									direito de decidir se aceita os Cookies ou não. A qualquer
									momento você pode definir ou alterar os controles do seu
									navegador para aceitar ou recusar Cookies. No entanto, ajustar
									as preferências do seu navegador pode afetar sua experiência em
									nosso site.
								</p>
								<p style="text-indent: 0pt; text-align: left"><br /></p>
							</li>
						</ul>
					</li>
					<li data-list-text="2.">
						<h1 style="padding-left: 42pt; text-indent: -18pt; text-align: left">
							DADOS RELATIVOS A OPERAÇÕES FINANCEIRAS
						</h1>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 117%;
								text-align: justify;
							"
						>
							Os dados referentes a transações financeiras são objeto de
							mecanismos adicionais de segurança visando a sua autenticidade,
							confidencialidade e integridade. Recursos técnicos como certificação
							digital e criptografia são utilizados neste sentido. As informações
							que trafegam através do nosso
						</p>
						<p
							style="
								padding-top: 1pt;
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 117%;
								text-align: justify;
							"
						>
							processo de compra e venda online são protegidas por HTTPS
							(usualmente, um cadeado fechado no navegador indica que a conexão é
							segura).
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p style="padding-left: 6pt; text-indent: 0pt; text-align: justify">
							O processo de Identificação (Login e Senha) é obrigatório em todo
							processo de compra online.
						</p>
						<p
							style="
								padding-top: 1pt;
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 117%;
								text-align: justify;
							"
						>
							Os números do cartão de pagamento (crédito ou débito) informados
							pelo Titular são utilizados exclusivamente para fins de autorização
							das transações de compra e/ou pagamento, sendo criptografados e
							mascarados de acordo com as melhores práticas de mercado.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 113%;
								text-align: justify;
							"
						>
							A BeeBee está em conformidade com as normas de segurança
							estabelecidas pelas bandeiras (Visa, MasterCard e outras), que
							estabelece o modelo de segurança para proteção dos dados de cartões
							de crédito e débito dos Titulares, e que se aplica tanto às lojas
							físicas como aos processos de compra e venda online.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
					</li>
					<li data-list-text="3.">
						<h1 style="padding-left: 42pt; text-indent: -18pt; text-align: left">
							COMPARTILHAMENTO
						</h1>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 113%;
								text-align: justify;
							"
						>
							Nós somente compartilhamos suas informações com empresas,
							organizações ou terceiros nos casos em que você consinta com o
							compartilhamento, em que haja necessidade para realização do
							negócio, caso seja estabelecido em leis e regulamentos aplicáveis,
							ou quando seja possível melhorarmos da sua experiência com nossos
							produtos e serviços.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p style="padding-left: 6pt; text-indent: 0pt; text-align: justify">
							Assim, podemos compartilhar suas informações nas seguintes
							situações:
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<ul id="l5">
							<li data-list-text="-">
								<h1
									style="
										padding-left: 42pt;
										text-indent: -18pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									Internamente entre nossos colaboradores<span class="p"
										>: para que seja possível a eficiente prestação do serviço
										para o qual fomos contratados, bem como seja possibilitado
										identificar outras necessidades do titular dos dados;</span
									>
								</h1>
							</li>
							<li data-list-text="-">
								<h1
									style="
										padding-left: 42pt;
										text-indent: -18pt;
										line-height: 113%;
										text-align: justify;
									"
								>
									Provedores de serviços<span class="p"
										>: para ajudar nas nossas operações de negócios e fornecer
										serviços melhores, podemos compartilhar suas informações com
										empresas prestadoras de serviços, por exemplo, mas não
										somente: de armazenamento em nuvem e banco de dados, de e
										-mail, de marketing, entre outros;</span
									>
								</h1>
							</li>
							<li data-list-text="-">
								<h1
									style="
										padding-left: 42pt;
										text-indent: -18pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									Outras empresas, organizações ou terceiros externos à BeeBee com
									seu consentimento:
									<span class="p"
										>podemos compartilhar informações pessoais fora da BeeBee
										quando tivermos seu consentimento. Nós sempre solicitaremos
										seu consentimento explícito para compartilhar quaisquer
										informações pessoais sensíveis.</span
									>
								</h1>
							</li>
							<li data-list-text="-">
								<h1
									style="
										padding-left: 42pt;
										text-indent: -18pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									Por motivos legais<span class="p"
										>: compartilharemos informações pessoais se acreditarmos, de
										boa-fé, que o acesso, o uso, a conservação ou divulgação das
										informações sejam razoavelmente necessários para:</span
									>
								</h1>
								<ul id="l6">
									<li data-list-text="●">
										<p
											style="
												padding-left: 66pt;
												text-indent: -18pt;
												line-height: 117%;
												text-align: left;
											"
										>
											Cumprir qualquer legislação, regulação, ordem judicial ou
											solicitação de autoridade governamental ou administrativa
											competente para a requisição;
										</p>
									</li>
									<li data-list-text="●">
										<p
											style="
												padding-left: 66pt;
												text-indent: -18pt;
												line-height: 13pt;
												text-align: left;
											"
										>
											Responder a reclamações ou processos judiciais reais ou
											potenciais;
										</p>
									</li>
									<li data-list-text="●">
										<p
											style="
												padding-top: 2pt;
												padding-left: 66pt;
												text-indent: -18pt;
												text-align: left;
											"
										>
											Cumprir Termos de Serviços aplicáveis, inclusive
											investigação de possíveis violações;
										</p>
									</li>
									<li data-list-text="●">
										<p
											style="
												padding-top: 1pt;
												padding-left: 66pt;
												text-indent: -18pt;
												line-height: 117%;
												text-align: justify;
											"
										>
											Detectar, impedir ou lidar de alguma forma com fraudes,
											problemas técnicos ou de segurança;
										</p>
									</li>
									<li data-list-text="●">
										<p
											style="
												padding-left: 66pt;
												text-indent: -18pt;
												line-height: 114%;
												text-align: justify;
											"
										>
											Quando necessário para o legítimo interesse da empresa, ou
											de terceiros, em matéria de segurança nacional, proteção da
											segurança de qualquer pessoa, ou para impedir a morte ou
											danos físicos eminentes, desde que considerado que tal
											interesse não
										</p>
										<p
											style="
												padding-top: 1pt;
												padding-left: 66pt;
												text-indent: 0pt;
												line-height: 117%;
												text-align: justify;
											"
										>
											prevalece sobre os interesses ou direitos e liberdades
											fundamentais do titular que exijam a proteção dos seus dados
											pessoais;
										</p>
									</li>
									<li data-list-text="●">
										<p
											style="
												padding-left: 66pt;
												text-indent: -18pt;
												line-height: 115%;
												text-align: justify;
											"
										>
											Quando necessário para proteger nossos direitos, interesses
											e/ou propriedade (incluindo, sem limitação, para assegurar o
											cumprimento dos nossos contratos), os direitos, interesses
											e/ou propriedade dos nossos agentes, subcontratados
											independentes, clientes e outros;
										</p>
									</li>
								</ul>
							</li>
						</ul>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 114%;
								text-align: justify;
							"
						>
							Nas hipóteses de compartilhamento de Dados Pessoais com terceiros,
							todos os sujeitos acima mencionados deverão utilizar os Dados
							Pessoais partilhados de maneira consistente e de acordo com os
							propósitos para os quais foram coletados (ou com os quais o Titular
							consentiu previamente) e de acordo com o que foi determinado por
							este Aviso de Privacidade, outras declarações de privacidade de
							website ou países, e todas as leis de privacidade e proteção de
							dados aplicáveis.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 112%;
								text-align: justify;
							"
						>
							Nós também nos comprometemos a não vender, alugar ou repassar suas
							informações para empresas, organizações ou terceiros.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
					</li>
					<li data-list-text="4.">
						<h1 style="padding-left: 42pt; text-indent: -18pt; text-align: left">
							ARMAZENAMENTO E EXCLUSÃO DE DADOS
						</h1>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 117%;
								text-align: justify;
							"
						>
							Os seus dados pessoais serão armazenados pelo tempo necessário para
							o cumprimento das finalidades às quais foram coletados. Por isso,
							quando atingirem sua finalidade, eles poderão ser eliminados.
						</p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 114%;
								text-align: justify;
							"
						>
							Você também poderá solicitar a exclusão de seus dados, exceto nas
							hipóteses de necessidade de cumprimento de obrigação legal ou
							regulatória e para o exercício de direitos em processos judiciais,
							extrajudiciais ou administrativos.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
					</li>
					<li data-list-text="5.">
						<h1 style="padding-left: 42pt; text-indent: -18pt; text-align: left">
							LINKS DE TERCEIROS
						</h1>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 114%;
								text-align: justify;
							"
						>
							O nosso site pode incluir links para <i>sites </i>de terceiros cujas
							práticas de privacidade podem ser diferentes das nossas. Assim,
							quando você acessar nosso site, poderá ser encaminhado para alguma
							de nossas páginas nas redes sociais (Facebook, Instagram e
							Whatsapp).
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 114%;
								text-align: justify;
							"
						>
							O uso desses <i>sites </i>é regido pelas políticas de privacidade
							dos mesmos e não pelo presente Aviso de Privacidade. Você deve
							analisar cuidadosamente a política de privacidade de qualquer
							<i>site </i>que visitar. Consideraremos sua decisão de usar esses
							produtos e serviços de terceiros com os nossos Produtos e Serviços
							como uma declaração para nós de que você consentiu com os termos e
							práticas de terceiros.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
					</li>
					<li data-list-text="6.">
						<h1 style="padding-left: 42pt; text-indent: -18pt; text-align: left">
							MEDIDAS DE SEGURANÇA
						</h1>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 114%;
								text-align: justify;
							"
						>
							Tratamos a segurança dos seus dados pessoais com o máximo de
							cuidado. Temos uma equipe responsável por garantir que a BeeBee
							adote as melhores práticas de segurança, visando sempre a melhoria e
							aperfeiçoamento de nossos serviços de proteção.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 112%;
								text-align: justify;
							"
						>
							Para tanto, nós adotamos diversas precauções, em observância às
							diretrizes sobre padrões de segurança estabelecidas nas legislações,
							tais como:
						</p>
						<ol id="l7">
							<li data-list-text="(i)">
								<p
									style="padding-left: 60pt; text-indent: -36pt; text-align: left"
								>
									Possui proteção contra acesso não autorizado a seus sistemas;
								</p>
							</li>
							<li data-list-text="(ii)">
								<p
									style="
										padding-top: 1pt;
										padding-left: 60pt;
										text-indent: -36pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									Somente autoriza o acesso de pessoas específicas ao local onde
									são armazenadas as suas informações pessoais, desde que este
									acesso seja essencial ao desenvolvimento da atividade
									pretendida;
								</p>
							</li>
							<li data-list-text="(iii)">
								<p
									style="
										padding-left: 60pt;
										text-indent: -36pt;
										line-height: 115%;
										text-align: justify;
									"
								>
									Garante que aqueles agentes, funcionários internosou parceiros
									externosque realizarem o tratamento de dados pessoais deverão se
									comprometer a manter o sigilo absoluto das informações
									acessadas, bem como de adotar as melhores práticas para manuseio
									destas informações, conforme determinado nas políticas e
									procedimentos;
								</p>
							</li>
							<li data-list-text="(iv)">
								<p
									style="
										padding-left: 60pt;
										text-indent: -36pt;
										line-height: 13pt;
										text-align: justify;
									"
								>
									O acesso às informações coletadas é restrito aos colaboradores e
									às pessoas autorizadas.
								</p>
								<p
									style="
										padding-top: 2pt;
										padding-left: 60pt;
										text-indent: 0pt;
										line-height: 112%;
										text-align: justify;
									"
								>
									Aqueles que se utilizarem indevidamente dessas informações, em
									violação desta Política de Privacidade, estarão sujeitos a
									sanções disciplinares e legais cabíveis.
								</p>
							</li>
							<li data-list-text="(v)">
								<p
									style="
										padding-left: 60pt;
										text-indent: -36pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									Além dos esforços técnicos, nós também adotamos medidas
									institucionais visando a proteção de dados pessoais, de modo que
									mantém o programa de privacidade aplicado às suas atividades,
									constantemente atualizado.
								</p>
							</li>
						</ol>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<h1
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 115%;
								text-align: justify;
							"
						>
							Atenção!
							<span class="p"
								>Embora nós adotemos os melhores esforços no sentido de preservar
								a sua privacidade e proteger os seus dados pessoais, nenhuma
								transmissão de informaçõesé totalmente segura, de modo que é
								impossível garantir integralmente que todas as informações que
								recebe e/ou envia não sejam alvo de acessos não autorizados
								perpetrados por meio de métodos desenvolvidos para obter
								informações de forma indevida, como falhas técnicas, vírus ou
								invasões do banco de dados do site. De qualquer forma, na remota
								hipótese de incidência de episódios desta natureza, nós garantimos
								o pleno esforço para remediar tais consequências.</span
							>
						</h1>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
					</li>
					<li data-list-text="7.">
						<h1
							style="padding-left: 42pt; text-indent: -18pt; text-align: justify"
						>
							SEUS DIREITOS
						</h1>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 115%;
								text-align: justify;
							"
						>
							Nós nos comprometemos a cumprir as normas previstas na Lei Geral de
							Proteção de Dados (LGPD), em respeito aos princípios e direitos dos
							titulares de dados pessoais, especialmente os princípios da
							licitude, lealdade, transparência, limitação das finalidades,
							minimização dos dados, exatidão, limitação da conservação,
							integridade e confidencialidade.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 117%;
								text-align: justify;
							"
						>
							Além disso, são assegurados aos titulares dos dados pessoais os
							direitos elencados a seguir, nos termos da LGPD:
						</p>
						<ul id="l8">
							<li data-list-text="–">
								<p
									style="
										padding-left: 60pt;
										text-indent: -18pt;
										line-height: 115%;
										text-align: justify;
									"
								>
									<span class="h1">Direito de confirmação e acesso: </span
									><span class="p"
										>é o direito do titular do dado pessoal de obter da </span
									><span class="h1">BeeBee </span
									><a
										href="mailto:suporte@beebee.com.br"
										class="s8"
										target="_blank"
										>a confirmação de que os dados pessoais que lhe digam respeito
										são ou não objeto de tratamento e, se for esse o caso, o
										direito de acessar os seus dados pessoais mediante solicitação
										junto ao site ou ao e-mail </a
									><a
										href="mailto:suporte@beebee.com.br"
										class="a"
										target="_blank"
										>suporte@beebee.com.br </a
									><a href="mailto:suporte@beebee.com.br" target="_blank">.</a>
								</p>
							</li>
							<li data-list-text="–">
								<h1
									style="
										padding-left: 60pt;
										text-indent: -18pt;
										line-height: 112%;
										text-align: justify;
									"
								>
									Direito de retificação:
									<span class="p"
										>é o direito do titular do dado pessoal de obter da </span
									>BeeBee<span class="p"
										>, sem demora injustificada, a retificação dos dados pessoais
										inexatos que lhe digam respeito.</span
									>
								</h1>
							</li>
							<li data-list-text="–">
								<h1
									style="
										padding-left: 60pt;
										text-indent: -18pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									Direito à eliminação dos dados:
									<span class="p"
										>é o direito do titular do dado pessoal de ter seus dados
										apagados dos sistemas da BeeBee desde que essa solicitação
										esteja amparada pela LGPD, ou não haja um motivo legal que
										justifique sua manutenção, mesmo que anonimizada.</span
									>
								</h1>
							</li>
							<li data-list-text="–">
								<h1
									style="
										padding-left: 60pt;
										text-indent: -18pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									Direito ao bloqueio dos dados:
									<span class="p"
										>é o direito do titular do dado pessoal de ter bloqueio do uso
										dos seus dados pessoais desnecessários, excessivos ou tratados
										em desconformidade com a LGPD.</span
									>
								</h1>
							</li>
							<li data-list-text="–">
								<h1
									style="
										padding-top: 1pt;
										padding-left: 60pt;
										text-indent: -18pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									Direito à anonimização ou ao bloqueio de dados:
									<span class="p"
										>é o direito do titular do dado pessoal de tê-lo dissociado
										direta ou indiretamente de si, através da utilização de meios
										técnicos razoáveis e disponíveis no momento do
										tratamento.</span
									>
								</h1>
							</li>
							<li data-list-text="–">
								<h1
									style="
										padding-left: 60pt;
										text-indent: -18pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									Direito à limitação do tratamento dos dados:
									<span class="p"
										>é o direito do titular do dado pessoal de limitar o
										tratamento de seus dados pessoais, podendo obtê-la quando
										contesta a exatidão dos dados, quando o tratamento for
										ilícito, quando a </span
									>BeeBee
									<span class="p"
										>não precisar mais dos dados para as finalidades propostas e
										quando tiver se oposto ao tratamento dos dados e em caso de
										tratamento de dados desnecessários.</span
									>
								</h1>
							</li>
							<li data-list-text="–">
								<p
									style="
										padding-left: 60pt;
										text-indent: -18pt;
										line-height: 114%;
										text-align: justify;
									"
								>
									<b>Direito de não ser submetido a decisões automatizadas: </b>é
									o direito do titular do dado pessoal de não ficar sujeito a
									nenhuma decisão tomada exclusivamente com base no tratamento
									automatizado, incluindo a definição de perfis (
									<i>profiling</i>), que produza efeitos na sua esfera jurídica ou
									que o afete significativamente de forma similar. O titular do
									dado pessoal será informado em caso de retificação ou eliminação
									dos seus dados.
								</p>
							</li>
						</ul>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 114%;
								text-align: justify;
							"
						>
							<a href="mailto:suporte@beebee.com.br" class="s8" target="_blank"
								>Esses direitos são garantidos e poderão ser exercidos conforme
								previsto na LGPD, exclusivamente pelo titular dos dados pessoais
								ou por seu representante legal, se for o caso, por e -mail a ser
								enviado para </a
							><a href="mailto:suporte@beebee.com.br" class="a" target="_blank"
								>suporte@beebee.com.br</a
							>, com as seguintes informações:
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<ol id="l9">
							<li data-list-text="1)">
								<p
									style="padding-left: 60pt; text-indent: -18pt; text-align: left"
								>
									Nome completo do titular de dados;
								</p>
							</li>
							<li data-list-text="2)">
								<p
									style="
										padding-top: 1pt;
										padding-left: 60pt;
										text-indent: -18pt;
										text-align: left;
									"
								>
									Número do RG ou CPF do titular de dados;
								</p>
							</li>
							<li data-list-text="3)">
								<p
									style="
										padding-top: 2pt;
										padding-left: 56pt;
										text-indent: -14pt;
										text-align: left;
									"
								>
									Qual a condição de representante legal, se for o caso;
								</p>
							</li>
							<li data-list-text="4)">
								<p
									style="
										padding-top: 1pt;
										padding-left: 60pt;
										text-indent: -18pt;
										text-align: left;
									"
								>
									Descrição da solicitação;
								</p>
							</li>
							<li data-list-text="5)">
								<p
									style="
										padding-top: 2pt;
										padding-left: 53pt;
										text-indent: -11pt;
										text-align: left;
									"
								>
									Outras informações ou anexação de documentos para o atendimento
									da requisição.
								</p>
							</li>
						</ol>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							class="s9"
							style="padding-left: 6pt; text-indent: 0pt; text-align: justify"
						>
							Nossa equipe estará pronta para atender eventuais solicitações!
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
					</li>
					<li data-list-text="8.">
						<h1 style="padding-left: 42pt; text-indent: -18pt; text-align: left">
							ALTERAÇÃO DO AVISO DE PRIVACIDADE
						</h1>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 117%;
								text-align: justify;
							"
						>
							Sempre que decidirmos mudar a forma que tratamos os dados pessoais,
							este Aviso de Privacidade será atualizado. Nós nos reservamos ao
							direito de fazer alterações em suas práticas e no Aviso de
							Privacidade a qualquer tempo, desde que mantida a conformidade com a
							legislação vigente.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 114%;
								text-align: justify;
							"
						>
							Mas fique tranquilo (a), nós prezamos pela transparência: toda vez
							que uma alteração relevante for feita, nós a divulgaremos em nosso
							site. Por isso, orientamos que clientes e parceiros acessem esse
							Aviso frequentemente ou sempre que houver dúvidas.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 115%;
								text-align: justify;
							"
						>
							Nas hipóteses em que as alterações a este Aviso de Privacidade
							resultarem em mudanças nas práticas de tratamento de dados pessoais
							que dependem do seu consentimento, solicitaremos o seu consentimento
							com os novos termos do Aviso de Privacidade em relação ao tratamento
							de dados e finalidades indicados.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 114%;
								text-align: justify;
							"
						>
							Ao continuar a usar os serviços da BeeBee após uma alteração no
							Aviso de Privacidade, você estará concordando com as novas condições
							– mas você sempre pode manifestar a sua discordância por meio dos
							nossos canais de atendimento, se for o caso.
						</p>
					</li>
					<li data-list-text="9.">
						<h1
							style="
								padding-top: 1pt;
								padding-left: 42pt;
								text-indent: -18pt;
								text-align: left;
							"
						>
							ADERÊNCIA À LEGISLAÇÃO
						</h1>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
						<p
							style="
								padding-left: 6pt;
								text-indent: 0pt;
								line-height: 115%;
								text-align: justify;
							"
						>
							Esta Política foi elaborada para atendimento e em conformidade com a
							Lei Federal nº 13.709/2018 (Lei Geral de Proteção de Dados
							Pessoais), Lei Federal nº 12.965/2014 (Marco Civil da Internet) e
							Lei Federal nº 8.078/1990 (Código de Defesa do Consumidor), sem
							prejuízo de observância das demais legislações aplicáveis à
							<b>Bee Bee</b>.
						</p>
						<p style="text-indent: 0pt; text-align: left"><br /></p>
					</li>
					<li data-list-text="10.">
						<h1 style="padding-left: 42pt; text-indent: -18pt; text-align: left">
							CONTATO
						</h1>
					</li>
				</ol>
				<p style="text-indent: 0pt; text-align: left"><br /></p>
				<p
					style="
						padding-left: 6pt;
						text-indent: 0pt;
						line-height: 114%;
						text-align: justify;
					"
				>
					<a href="mailto:suporte@beebee.com.br" class="s8" target="_blank"
						>Para mais informações sobre este Aviso de Privacidade, o Usuário
						poderá entrar em contato com o Encarregado pelo tratamento de dados
						pessoais (DPO) da BeeBee, através do endereço de e -mail: </a
					><a href="mailto:suporte@beebee.com.br" target="_blank"
						>suporte@beebee.com.br.</a
					>
				</p>
				<p style="text-indent: 0pt; text-align: left"><br /></p>
				<h1 style="text-indent: 0pt; text-align: right">
					Em vigor desde: <span class="p">abril de 2023.</span>
				</h1>
			</div>
		</body>
	</html>
	`;

	return (
		<div>
			<div
				dangerouslySetInnerHTML={{
					__html: html,
				}}
			/>
		</div>
	);
};

export default Privacy;
