import React from 'react';
import { Form, Input, Textarea, useZodForm } from '../FormElements';
import { z } from 'zod';
import { api } from '../../services/angular/axios';
import { toast } from 'react-toastify';
import { CloseModal, Modal } from '../Modal';
import Button from '../Button/Button';

const AddIncidentSchema = z.object({
	total: z.coerce.number(),
	driverComission: z.coerce.number(),
	reason: z.string(),
	newOrderId: z
		.string()
		.optional()
		.transform((value) => {
			if (value === '') return undefined;
			return Number(value);
		}),
});

type AddIncidentSchemaType = z.infer<typeof AddIncidentSchema>;

interface AddIncidentModalProps {
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	open: boolean;
	orderId?: number;
	onSuccess?: () => void;
}

export const AddIncidentModal = ({
	setOpen,
	open,
	orderId,
	onSuccess,
}: AddIncidentModalProps) => {
	const form = useZodForm({
		schema: AddIncidentSchema,
	});

	const handleSubmit = async (data: AddIncidentSchemaType) => {
		try {
			if (!orderId) throw new Error('Order id is required');

			await api.put(`/orders/${orderId}/incident`, data);

			toast.success('Incidente adicionado com sucesso!');
			setOpen(false);
			onSuccess?.();
		} catch {
			toast.error('Erro ao adicionar incidente!');
		}
	};

	return (
		<Modal
			title="Adicionar incidente"
			setOpen={setOpen}
			open={open}
			onClose={() => {
				form.reset();
			}}
		>
			<Form form={form} onSubmit={handleSubmit} className="flex flex-col gap-4">
				<div className="flex flex-col gap-4 md:flex-row">
					<Input
						type="number"
						step="any"
						label="Novo valor cobrado"
						{...form.register('total')}
					/>
					<Input
						type="number"
						step="any"
						label="Comissão do motorista"
						{...form.register('driverComission')}
					/>
				</div>
				<Input label="Código do novo pedido" {...form.register('newOrderId')} />
				<Textarea label="Motivo do cancelamento" {...form.register('reason')} />

				<div className="flex justify-end gap-4">
					<CloseModal>
						<Button className="w-auto">Cancelar</Button>
					</CloseModal>
					<Button variant="blue-primary" className="w-auto" type="submit">
						Confirmar
					</Button>
				</div>
			</Form>
		</Modal>
	);
};
