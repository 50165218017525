import { useQuery } from '@tanstack/react-query';
import useUserState from '../../../../../services/angular/angularUserState';
import { api } from '../../../../../services/angular/axios';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { IndicatorsFilterSchemaType } from '../schema/IndicatorsFilterSchema';
import { FormHeader } from '../../../../../components/FormElements';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
		},
	},
};

const IndicatorCharts = ({ query }: { query: IndicatorsFilterSchemaType | null | undefined }) => {
	const company = useUserState((state) => state.company);

	const { data: Indicators, isLoading, isError } = useQuery(
		['dashboard', company?.id, query],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${company?.id}/indicators?initialDate=${query?.initialDate ?? ""}&endDate=${query?.endDate ?? ""}&geofenceId=${query?.geofenceId ?? ""}&categories=${query?.categories ?? ""}&driverId=${query?.driverId ?? ""}`
			)
	);

	if (isLoading) return <div>Loading...</div>;
	if (isError) return <div>Error loading data</div>;
	if (!Indicators || !Indicators.data.operationalPerformanceDays || !Indicators.data.avgTicketsDays || !Indicators.data.performanceDays) {
		return <div>No data available</div>;
	}

	const labels = Object.keys(Indicators.data.operationalPerformanceDays).sort();
	const operationalPerformanceData = labels.map(date => Indicators.data.operationalPerformanceDays[date]);
	const avgTicketData = labels.map(date => Indicators.data.avgTicketsDays[date] || 0);
	const performanceData = labels.map(date => Indicators.data.performanceDays[date] || 0);

	return (
		<div className="flex flex-col items-center justify-between gap-4 md:flex-row">
			<WhiteContainer>
				<FormHeader title="Performance da Beebee" description="Pedidos de fretes atendidos" />
				<Line
					data={{
						labels,
						datasets: [
							{
								fill: true,
								label: 'Consumo',
								data: operationalPerformanceData,
								borderColor: '#FCF366',
								backgroundColor: '#FCF3664d',
							},
						],
					}}
					options={options}
				/>
			</WhiteContainer>
			<WhiteContainer>
				<FormHeader
					title="Ticket médio das entregas"
					description="Custo médio por entrega"
				/>
				<Line
					data={{
						labels,
						datasets: [
							{
								fill: true,
								label: 'Ticket Médio',
								data: avgTicketData,
								borderColor: '#04ca2f',
								backgroundColor: '#04ca2f4d',
							},
						],
					}}
					options={options}
				/>
			</WhiteContainer>
			<WhiteContainer>
				<FormHeader
					title="Performance das entregas"
					description="Percentual das entregas com sucesso"
				/>
				<Line
					data={{
						labels,
						datasets: [
							{
								fill: true,
								label: 'Performance',
								data: performanceData,
								borderColor: 'blue',
								backgroundColor: '#1671E44d',
							},
						],
					}}
					options={options}
				/>
			</WhiteContainer>
		</div>
	);
};

export default IndicatorCharts;