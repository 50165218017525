import { PageHeader } from '../../../../components/PageHeader';
import { IFoodOrders } from './components/orders';
import { IFoodSettings } from './components/settings';

export const IfoodPage = () => {
	return (
		<div className="w-full text-neutral-900">
			<PageHeader title="iFood" description="Integração com iFood" />
			<div className="flex flex-col gap-4">
				<IFoodSettings />
				<IFoodOrders />
			</div>
		</div>
	);
};
