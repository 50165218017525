import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Params } from '../../../../../../services/angular/types/Params';
import { TransactionsFilterSchemaType } from './TransactionsFilterSchema';

type TransactionsFilterState = {
	params: Params;
	setParams: (params: Params) => void;
	setTransactionsFilter: (state: TransactionsFilterSchemaType) => void;
	clearTransactionsFilter: () => void;
} & TransactionsFilterSchemaType;

export const useTransactionsFilterState = create(
	persist<TransactionsFilterState>(
		(set) => ({
			params: {
				page: 1,
				take: 50,
			},
			adminId: null,
			paymentTypeId: null,
			transactionStatusId: null,
			setParams: (params) => set({ params }),
			setTransactionsFilter: (state) => set(state),
			clearTransactionsFilter: () =>
				set({
					adminId: null,
					clientName: undefined,
					paymentTypeId: null,
					transactionId: undefined,
					transactionStatusId: null,
				}),
		}),
		{
			name: 'TransactionsFilterState',
		}
	)
);
