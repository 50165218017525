import { create } from 'zustand';

type Form =
	| {
			step: 'phone';
			cellphone?: string;
			userId?: number;
	  }
	| {
			step: 'phoneValidation';
			cellphone: string;
			userId: number;
	  }
	| {
			step: 'info';
			cellphone: string;
			code: string;
			userId: number;
			token: string;
	  }
	| {
			step: 'senha';
			cellphone: string;
			code: string;
			cpf: string;
			email: string;
			name: string;
			userId: number;
			token: string;
	  }
	| {
			step: 'foto';
			cellphone: string;
			code: string;
			cpf: string;
			email: string;
			name: string;
			password: string;
			confirm: string;
			userId: number;
			token: string;
	  }
	| {
			step: 'termos e política';
			cellphone: string;
			code: string;
			cpf: string;
			email: string;
			name: string;
			password: string;
			confirm: string;
			avatar: string;
			userId: number;
			token: string;
	  };

type Step = Form['step'];

interface InviteCampaignStore {
	formState: Form;

	upsertForm: (form: Form) => void;
	setStep: (step: Step) => void;
}

export const useInviteCampaignStore = create<InviteCampaignStore>(
	(set, get) => ({
		formState: {
			step: 'phone',
		},
		upsertForm: (data) => {
			set({ formState: { ...get().formState, ...data } });
		},
		setStep: (step) => {
			// @ts-expect-error
			set({ formState: { ...get().formState, step } });
		},
	})
);
