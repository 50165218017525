/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import {
	IFoodOrder,
	useGetIfoodOrders,
} from '../../../../../services/integration/ifood/useGetIfoodOrders';
import { Checkbox, SwitchButton } from '../../../../../components/FormElements';
import dayjs from 'dayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { integrationApi } from '../../../../../services/angular/axios';
import useUserState from '../../../../../services/angular/angularUserState';
import Button from '../../../../../components/Button/Button';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
	DndContext,
	closestCenter,
	useSensor,
	useSensors,
	DragEndEvent,
	PointerSensor,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { Modal } from '../../../../../components/Modal';
import { useAlert } from '../../../../../contexts/AlertContext';
import { ToastError } from '../../../../../helpers/errors/ToastError';

const limitOrders = 30;

export const IFoodOrders = () => {
	const [open, setOpen] = useState(false);
	const { data } = useGetIfoodOrders();
	const [checkedRows, setCheckedRows] = useState<IFoodOrder[]>([]);

	const handleCheckboxChange = (order: IFoodOrder) => {
		if (
			checkedRows.find((checkedOrder) => checkedOrder.orderId === order.orderId)
		) {
			setCheckedRows(
				checkedRows.filter(({ orderId }) => orderId !== order.orderId)
			);

			return true;
		}

		if (checkedRows.length < limitOrders) {
			setCheckedRows([...checkedRows, order]);

			return true;
		}

		toast.update('max-orders', {
			render: `Você pode selecionar no máximo ${limitOrders} pedidos por vez`,
		});

		toast.info(
			`Você pode selecionar no máximo ${limitOrders} pedidos por vez`,
			{
				toastId: 'max-orders',
			}
		);

		return false;
	};

	return (
		<div className="flex flex-col gap-4 rounded-md bg-white px-8 py-4">
			<div className="flex justify-between">
				<h1 className="text-lg font-bold text-neutral-800 ">
					Pedidos - {data?.length}
				</h1>
				{Boolean(checkedRows.length) && (
					<Button
						variant="blue-primary"
						className="w-auto min-w-fit px-1.5 py-0.5"
						onClick={() => setOpen((prev) => !prev)}
					>
						Visualizar pedido
					</Button>
				)}
			</div>
			<div className="flex w-full flex-col gap-2">
				<div className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4">
					{data?.map((order) => (
						<Card
							key={order.orderId}
							order={order}
							onSelect={handleCheckboxChange}
						/>
					))}
				</div>
			</div>

			<Modal
				setOpen={setOpen}
				open={open}
				title="Pedidos selecionados"
				headless
				className="max-w-5xl"
			>
				<CreatedOrder
					data={checkedRows.map((order) => ({ ...order, id: order.orderId }))}
				/>
			</Modal>
		</div>
	);
};

const useCreateOrder = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data: {
			companyId: number;
			orders: Array<{ orderId: string }>;
			optimize: boolean;
		}) => {
			return await integrationApi.post<Response>('/ifood/orders', data);
		},
		onSuccess: (data) => {
			void queryClient.invalidateQueries({
				queryKey: ['ifood-orders'],
				exact: false,
			});

			toast.success('Pedido criado com sucesso!');

			navigate(`/fretes/detalhes/${data?.data.orderId}`);
		},
		onError: (error: any) => {
			ToastError(error);
		},
	});
};

interface Response {
	orderId: number;
	externalOrderId: string;
	status: Status;
	price: string;
	forecast?: any;
	trackingUrl?: any;
	integrationCompany: IntegrationCompany;
	locations: Location[];
}

interface Location {
	id: number;
	sequence: number;
	latitude: number;
	longitude: number;
	checkinDate?: any;
	checkoutDate?: any;
	reasonDate?: any;
	checkoutNotes?: any;
	reasonId?: any;
	reason?: any;
	receiverName?: any;
	receiverLastName?: any;
}

interface IntegrationCompany {
	companyName: string;
	tradingName: string;
	avatarUrl?: any;
	phone?: any;
	cellphone: string;
}

interface Status {
	id: number;
	name: string;
}

const CreatedOrder = ({
	data,
}: {
	data: Array<IFoodOrder & { id: string }>;
}) => {
	const [optimize, setOptimize] = useState(true);
	const companyId = useUserState((state) => state.company?.id);
	const sensors = useSensors(useSensor(PointerSensor));

	const [items, setItems] = useState(data);

	useEffect(() => {
		setItems(data);
	}, [data]);

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (active.id !== over?.id) {
			setItems((items) => {
				const oldIndex = items.findIndex((item) => item.id === active.id);
				const newIndex = items.findIndex((item) => item.id === over?.id);

				return arrayMove(items, oldIndex, newIndex);
			});
		}
	};

	const { mutate, isLoading } = useCreateOrder();

	return (
		<div className="flex flex-col gap-4">
			<p className="mr-8">
				Caso deseje alterar a ordem dos pedidos, basta arrastar e soltar. Em
				caso de retorno a rota será otimizada.
			</p>
			<div className="flex items-center gap-2">
				<span>Otimizar rota automaticamente</span>
				<SwitchButton
					checked={optimize}
					onChange={() => {
						setOptimize((prev) => !prev);
					}}
				/>
			</div>
			<div className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4">
				<DndContext
					sensors={sensors}
					collisionDetection={closestCenter}
					onDragEnd={handleDragEnd}
				>
					<SortableContext items={items}>
						{items?.map((item, index) => (
							<SortableItem key={item.orderId} index={index + 1} order={item} />
						))}
					</SortableContext>
				</DndContext>
			</div>
			<Button
				variant="blue-primary"
				onClick={() =>
					mutate({
						companyId: companyId!,
						orders: items.map(({ orderId }) => ({ orderId })),
						optimize,
					})
				}
				loading={isLoading}
				disabled={isLoading}
			>
				Criar pedido
			</Button>
		</div>
	);
};

interface Props {
	order: IFoodOrder & { id: string };
	index: number;
}

const SortableItem = ({ order, index }: Props) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: order.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<div
			className="flex"
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
		>
			<Card order={order} index={index} />
		</div>
	);
};

const Card = ({
	order,
	onSelect,
	index,
}: {
	order: IFoodOrder;
	onSelect?: (order: IFoodOrder) => boolean;
	index?: number;
}) => {
	const queryClient = useQueryClient();
	const companyId = useUserState((state) => state.company?.id);
	const alert = useAlert();
	const [checked, setChecked] = useState(false);
	const {
		companyName,
		displayId,
		delivery: { deliveryAddress },
		orderTiming,
		deliveryStartTime,
	} = order;

	const handleCheckboxChange = () => {
		const val = onSelect?.(order);

		setChecked(val ? !checked : checked);
	};

	const { mutate } = useMutation({
		mutationFn: async (data: { companyId: number; orderId: string }) =>
			await integrationApi.post('/ifood/orders/ignore', data),
		onSuccess: () => {
			void queryClient.invalidateQueries({
				queryKey: ['ifood-orders'],
				exact: false,
			});
			toast.success('Pedido ignorado com sucesso!');
		},
	});

	const handleDelete = () => {
		alert.onCustom({
			title: 'Ignorar pedido',
			message: `Deseja realmente ignorar o pedido ${order.displayId}?`,
			confirm: {
				label: 'Limpar',
				onClick: async () => {
					mutate({
						orderId: order.orderId,
						companyId: companyId!,
					});
				},
			},
		});
	};

	return (
		<div
			className={`flex w-full flex-col rounded-md border border-neutral-100 bg-white p-4 ${
				onSelect ? 'cursor-pointer' : ''
			}`}
			onClick={handleCheckboxChange}
		>
			<div className="flex justify-between">
				<div className="mb-2 font-bold">ID: {displayId}</div>

				{onSelect && (
					<div className="flex items-center gap-2">
						<button
							className="flex h-5 w-5 items-center justify-center rounded bg-red text-white hover:opacity-80"
							onClick={(e) => {
								e.stopPropagation();

								handleDelete();
							}}
						>
							<IoClose size={16} />
						</button>
						<Checkbox
							label=""
							checked={checked}
							// onCheckedChange={handleCheckboxChange}
						/>
					</div>
				)}
				{index && <div className="mb-2 font-bold">{index}</div>}
			</div>
			<div className="text-md font-bold">{companyName}</div>
			<div className="mb-2">
				{orderTiming === 'IMMEDIATE' && (
					<div className="font-medium">Imediato</div>
				)}
				{orderTiming === 'SCHEDULED' && (
					<>
						<div className="text-sm font-medium">Agendado</div>
						{dayjs(deliveryStartTime).format('DD/MM/YYYY - HH:mm')}
					</>
				)}
			</div>

			<div className="text-sm font-medium">Endereço:</div>
			<div className="mb-2">{deliveryAddress.formattedAddress}</div>

			<div className="mb-2 flex gap-4">
				<div>
					<div className="text-sm font-medium">Bairro:</div>
					<div className="">{deliveryAddress.neighborhood}</div>
				</div>

				<div>
					<div className="text-sm font-medium">Cidade:</div>
					<div className="">{deliveryAddress.city}</div>
				</div>
			</div>

			<div className="font-medium">
				{order.paymentsMethods.map((payment, index) => (
					<span key={index}>
						{payment.type === 'ONLINE' ? (
							<p>Pago via iFood</p>
						) : (
							<>
								{payment.card && (
									<p>
										{payment.method} - {payment.card.brand}
									</p>
								)}

								{payment.cash && (
									<p>
										Troco para:{' '}
										{payment.cash.changeFor.toLocaleString('pt-BR', {
											style: 'currency',
											currency: 'BRL',
										})}
									</p>
								)}
							</>
						)}
					</span>
				))}
			</div>
		</div>
	);
};
