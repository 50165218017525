import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const CopyIcon: React.FC<SvgProps> = ({ color = '#FCF366', size }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20 9.80518H11C9.89543 9.80518 9 10.7006 9 11.8052V20.8052C9 21.9097 9.89543 22.8052 11 22.8052H20C21.1046 22.8052 22 21.9097 22 20.8052V11.8052C22 10.7006 21.1046 9.80518 20 9.80518Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 15.8052H4C3.46957 15.8052 2.96086 15.5945 2.58579 15.2194C2.21071 14.8443 2 14.3356 2 13.8052V4.80518C2 4.27474 2.21071 3.76603 2.58579 3.39096C2.96086 3.01589 3.46957 2.80518 4 2.80518H13C13.5304 2.80518 14.0391 3.01589 14.4142 3.39096C14.7893 3.76603 15 4.27474 15 4.80518V5.80518"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CopyIcon;
