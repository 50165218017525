export const isSchedulingError = (data: any) => {
	return (
		data?.error &&
		(data.error.includes(
			'A data do agendamento deve ter antecedência mínima'
		) ||
			data.error.includes('A data do pedido não pode estar no passado') ||
			data.error.includes('Agendamento mínimo para'))
	);
};

export const isGeofenceError = (data: any) => {
	return data?.error?.includes('Ponto de coleta não está na');
};

export const isDistanceError = (data: any) => {
	return data?.error?.includes(
		'Este pedido ultrapassa o limite de distância de'
	);
};

export const isOutsideGeofenceError = (data: any) => {
	return (
		data?.error &&
		typeof data.index === 'number' &&
		data.error.includes('está fora da área permitida')
	);
};

export const isInsufficientFundsError = (data: any) => {
	return data?.error?.includes('A empresa não tem saldo suficiente');
};
