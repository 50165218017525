import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

interface RequirePermissionProps {
	required: boolean;
	path: string;
	children?: React.ReactNode;
}

export const RequirePermission = ({
	required,
	children,
	path,
}: RequirePermissionProps) =>
	required ? (
		<Navigate to={path} replace />
	) : children ? (
		<>{children}</>
	) : (
		<Outlet />
	);
