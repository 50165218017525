import { z } from 'zod';

export const PopFilterSchema = z.object({
	geofenceId: z
		.array(
			z.object({
				value: z.number().nullable(),
				label: z.string().optional(),
			})
		)
		.optional()
		.nullable()
		.transform((value) => value?.map((item) => item.value).join(',')),
});

export type PopFilterSchemaType = z.infer<typeof PopFilterSchema>;
