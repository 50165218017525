import React from 'react';
import { PageHeader } from '../../../components/PageHeader';
import { ChangePassword } from './components/ChangePassword';
import { DriverInfo } from './components/DriverInfo';
import { PersonalInfo } from './components/PersonalInfo';

export const MyProfile = () => {
	return (
		<div className="w-full">
			<PageHeader
				title="Minha conta"
				description="Gerencie suas informações pessoais e preferencias"
			/>
			<div className="flex flex-col gap-4 lg:flex-row">
				<div className="flex w-full flex-col gap-4 lg:w-3/4">
					<PersonalInfo />
					<DriverInfo />
				</div>
				<div className="flex w-full flex-col lg:w-1/4">
					<ChangePassword className="sticky top-0 flex w-full flex-col gap-2" />
				</div>
			</div>
		</div>
	);
};
