import { AiFillStar } from 'react-icons/ai';
import { TiWarning } from 'react-icons/ti';
import useUserState from '../../../services/angular/angularUserState';
import { Category } from '../../../services/angular/types/Order';
import { appState } from '../../../store/appState';
import { oldIcons, VehicleIcon } from '../../Icon/vehicles';
import { Tooltip } from '../../Tooltip';
import { memo } from 'react';
import { isEqual } from 'lodash';

interface CategoryTypeProps {
	category: Category;
	categoryType: number;
	isFavoriteDriver?: boolean;
	defrauded?: boolean;
}

export const CategoryType = memo(function CategoryType({
	category,
	categoryType,
	isFavoriteDriver,
	defrauded,
}: CategoryTypeProps) {
	const role = useUserState((state) => state.userRole);

	return (
		<div className="flex flex-col">
			<div className="flex gap-2">
				{isFavoriteDriver && (
					<Tooltip message="Motorista favorito">
						<AiFillStar className="text-yellow-submenu" size={20} />
					</Tooltip>
				)}
				{defrauded && role?.id !== Number(appState.userRoles.user.value) && (
					<Tooltip message="Fake GPS detectado">
						<TiWarning className="text-terracota-0" size={20} />
					</Tooltip>
				)}
			</div>
			<div className="flex gap-2">
				<span className="w-10">
					<VehicleIcon
						name={
							/* @ts-expect-error */
							oldIcons[
								categoryType === 1
									? category.typeOneIcon
									: categoryType === 2
									? category.typeTwoIcon
									: category.icon
							]
						}
						size={40}
					/>
				</span>
				<div className="flex flex-col justify-center gap-1 text-sm leading-none">
					<div className="line-clamp-2 h-auto font-medium leading-none">
						{category.name}
					</div>
					{
						<p className="line-clamp-2 text-2xs text-neutral-500">
							{categoryType === 1 &&
								category.typeOneActive &&
								category.typeOneDescription}
							{categoryType === 2 &&
								category.typeTwoActive &&
								category.typeTwoDescription}
							{categoryType === 0 &&
								category.typeTwoActive &&
								category.typeTwoActive &&
								'Sem preferência'}
						</p>
					}
				</div>
			</div>
		</div>
	);
},
isEqual);
