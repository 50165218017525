import { useEffect, useState } from 'react';
import { FiltersTab } from '../../../../components/FiltersTab';
import { PageHeader } from '../../../../components/PageHeader';
import { SortOptions } from '../../../../services/angular/types/Params';
import { useAppState } from '../../../../store/appState';
import {
	Form,
	FormHeader,
	Input,
	Select,
	useZodForm,
} from '../../../../components/FormElements';

import { useQuery } from '@tanstack/react-query';
import { api } from '../../../../services/angular/axios';
import {
	systemFilterSchema,
	SystemFilterSchemaType,
} from './schema/SystemFilterSchema';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { Table } from '../../../../components/Table/Table';
import { Data } from './System';
import { EditSystem } from './EditSystem';
import { toast } from 'react-toastify';
import {
	EditGlobalSystemSchema,
	EditGlobalSystemSchemaType,
} from './schema/EditGlobalSystemSchema';
import Button from '../../../../components/Button/Button';

export const ConfigSystem = () => {
	const [systemToEdit, setSystem] = useState<Data>();
	const [query, setQuery] = useState<SystemFilterSchemaType>();
	const geofence = useAppState((appState) => appState.geofence);
	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});
	const form = useZodForm({
		schema: systemFilterSchema,
	});

	const { data, isLoading, refetch, isFetching } = useQuery<any>(
		['system', query, params],
		async () =>
			await api.get('/configs?&include=geofence', {
				params: {
					page: params.page,
					pageSize: params.take,
					sort: params.sortBy
						? `${params.sortBy?.id}:${params.sortBy?.order}`
						: undefined,
					...query,
				},
			})
	);

	const globalSystemForm = useZodForm({
		schema: EditGlobalSystemSchema,
	});

	const onSubmit = async (formData: EditGlobalSystemSchemaType) => {
		try {
			await api.put(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/configs/${data?.data.data[0].id}?include=geofence`,
				{
					...formData,
					autoCheckinRadius: data?.data.data[0].autoCheckinRadius,
					autoCheckoutRadius: data?.data.data[0].autoCheckoutRadius,
					delayToSendSMS: data?.data.data[0].delayToSendSMS,
					rejectTimeout: data?.data.data[0].rejectTimeout,
					socketLocationInterval: data?.data.data[0].socketLocationInterval,
					stopDuration: data?.data.data[0].stopDuration,
					timeToCancel: data?.data.data[0].timeToCancel,
					userLocationInterval: data?.data.data[0].userLocationInterval,
				}
			);

			toast.success(`Sistema alterado com sucesso!`);
		} catch (error) {
			if (error) toast.error(`Houve algum problema ao editar o sistema.`);
		}
	};

	useEffect(() => {
		if (data) {
			globalSystemForm.reset({
				currentAppVersion: data?.data.data[0].currentAppVersion,
				minimumAppVersion: data?.data.data[0].minimumAppVersion,
				timeToExpire: data?.data.data[0].timeToExpire,
				timeToExpireScheduled: data?.data.data[0].timeToExpireScheduled,
				minutesToConsiderOnline: data?.data.data[0].minutesToConsiderOnline,
			});
		}
	}, [data]);

	return (
		<div className="w-full flex-col text-neutral-900">
			{systemToEdit ? (
				<EditSystem
					system={systemToEdit}
					shouldBack={() => {
						void refetch();
						setSystem(undefined);
					}}
				/>
			) : (
				<>
					<PageHeader
						title="Configurações do Sistema"
						description="Lista de definições referentes às configurações do sistema"
					/>
					<FiltersTab
						form={form}
						clearFilters={() => {
							form.reset({
								geofenceId: [],
							});
							setQuery(undefined);
						}}
						onSubmit={(data) => {
							setQuery(data);
						}}
						// eslint-disable-next-line react/jsx-no-comment-textnodes
					>
						<Select
							variant="light"
							label="Geofence"
							controller={{
								control: form.control,
								name: 'geofenceId',
							}}
							isMulti
							options={geofence?.map((state) => ({
								label: state.name,
								value: state.id,
							}))}
						/>
					</FiltersTab>
					<WhiteContainer className="text-sm">
						<Table<Data>
							isLoading={isLoading || isFetching}
							triggerPageChange={form.formState.isSubmitting}
							data={data?.data.data || []}
							total={data?.data.meta.pagination.total || 0}
							onParamsChange={(params) => {
								setParams(params);
							}}
							actions={[
								{
									icon: 'edit',
									label: 'Editar',
									onAction: (data) => {
										setSystem(data);
									},
								},
							]}
							columns={[
								{
									accessor: 'geofenceId',
									Header: 'Geofence',
									Cell: ({ value }) => {
										const region = geofence?.map((g) => {
											return value && value === g.id ? g.name : '';
										});
										return <div>{region}</div>;
									},
								},
								{
									accessor: 'currentAppVersion',
									Header: 'Versão atual do app',
									Cell: ({ value }) => {
										return <div className="flex justify-center">{value}</div>;
									},
								},
								{
									accessor: 'minimumAppVersion',
									Header: 'Versão mínima do app',
									Cell: ({ value }) => {
										return <div className="flex justify-center">{value}</div>;
									},
								},
							]}
						/>
					</WhiteContainer>
					<WhiteContainer className="mt-4">
						<FormHeader title="Configurações Globais" />
						<Form form={globalSystemForm} onSubmit={onSubmit} className="mt-4">
							<span className="flex flex-wrap gap-4">
								<Input
									className="max-w-md"
									label="Tempo de expiração de pedidos imediatos (GLOBAL):"
									type="number"
									{...globalSystemForm.register('timeToExpire', {
										onChange: (e) => {
											if (e.target.value.length > 10) {
												e.target.value = e.target.value.slice(0, 10);
											}
										},
									})}
								/>
								<Input
									className="max-w-md"
									label="Tempo de expiração de pedidos agendados (GLOBAL):"
									type="number"
									{...globalSystemForm.register('timeToExpireScheduled', {
										onChange: (e) => {
											if (e.target.value.length > 10) {
												e.target.value = e.target.value.slice(0, 10);
											}
										},
									})}
								/>
								<Input
									className="max-w-md"
									label="Versão atual do app (GLOBAL):"
									{...globalSystemForm.register('currentAppVersion', {
										onChange: (e) => {
											if (e.target.value.length > 10) {
												e.target.value = e.target.value.slice(0, 10);
											}
										},
									})}
								/>
								<Input
									className="max-w-md"
									label="Versão mínima do app (GLOBAL):"
									{...globalSystemForm.register('minimumAppVersion', {
										onChange: (e) => {
											if (e.target.value.length > 10) {
												e.target.value = e.target.value.slice(0, 10);
											}
										},
									})}
								/>
								<Input
									className="max-w-md"
									label="Minutos para considerar fretista online (GLOBAL):"
									type="number"
									{...globalSystemForm.register('minutesToConsiderOnline', {
										onChange: (e) => {
											if (e.target.value.length > 10) {
												e.target.value = e.target.value.slice(0, 10);
											}
										},
									})}
								/>
							</span>

							<Button className="mt-4 w-auto px-8" type="submit">
								Salvar
							</Button>
						</Form>
					</WhiteContainer>
				</>
			)}
		</div>
	);
};
