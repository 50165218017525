export const ErrorsMessageHelper: ErrorsMessageHelperInterface = {
	required: 'Obrigatório',
	email: 'E-mail inválido',
	cnpj: 'CNPJ inválido',
	cpf: 'CPF inválido',
	cep: 'CEP inválido',
	phone: 'Telefone inválido',
	min: (length) => `Mínimo ${length} caracteres`,
	max: (length) => `Máximo ${length} caracteres`,
	password: {
		min: 'Mínimo 8 caracteres',
		max: 'Máximo 20 caracteres',
		regex: {
			lowercase: 'Deve conter pelo menos um caractere minúsculo',
			uppercase: 'Deve conter pelo menos um caractere maiúsculo',
			number: 'Deve conter pelo menos um número',
			specialCharacter: 'Deve conter um caractere especial',
		},
	},
};

interface ErrorsMessageHelperInterface {
	required: string;
	email: string;
	cnpj: string;
	cep: string;
	cpf: string;
	phone: string;
	min: (length: number) => string;
	max: (length: number) => string;
	password: {
		min: string;
		max: string;
		regex: {
			lowercase: string;
			uppercase: string;
			number: string;
			specialCharacter: string;
		};
	};
}
