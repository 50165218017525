/* eslint-disable @typescript-eslint/no-misused-promises */
import { useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Button from '../../../../../components/Button/Button';
import { FormHeader, Input } from '../../../../../components/FormElements';
import { PageHeader } from '../../../../../components/PageHeader';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { cpfMask } from '../../../../../helpers/mask/cpfMask';
import { toastMessage } from '../../../../../helpers/toastMessage';
import useUserState from '../../../../../services/angular/angularUserState';
import { api } from '../../../../../services/angular/axios';

const AddNewDriverForm = ({
	makeDriverRefetch,
}: {
	makeDriverRefetch?: () => void;
}) => {
	const company = useUserState((state) => state.company);
	const [cpf, setCpf] = useState('');

	const addNewDriver = async (): Promise<void> => {
		try {
			if (!company || !cpf) {
				return;
			}
			await api.post(`companies/${company?.id}/drivers`, {
				cpf,
			});
			toast.success(toastMessage.success.created);
			makeDriverRefetch?.();
		} catch (error) {
			toast.error(toastMessage.error.created);
		}
	};

	return (
		<>
			<PageHeader
				title="Cadastro de Motorista"
				description="Informações do motorista autorizado a fazer fretes pelo seu negócio"
			/>
			<WhiteContainer>
				<FormHeader
					title="Dados do motorista"
					description="Preencha os dados do usuário para vinculá-lo"
				/>
				<div className="mt-4 flex flex-col gap-4 md:flex-row md:gap-0">
					<div className="w-3/5">
						<Input
							label="CPF"
							className="md:w-1/2"
							value={cpf}
							onChange={(event) => setCpf(cpfMask(event.target.value))}
						/>

						<Button
							className="mt-4 w-auto"
							type="submit"
							onClick={addNewDriver}
						>
							<BsCheckLg />
							Vincular motorista
						</Button>
					</div>
					<h1 className="w-2/5 border-l-2 border-neutral-200 pl-4">
						Você pode incluir usuários na sua conta empresarial, como:
						funcionários, gestores ou parentes.
						<br />
						<br />
						É necessário que o motorista tenha uma conta previamente cadastrada
						na BeeBee. Para cadastrar acesse: https://app.beebee.com.br/cadastro
						<br />
						<br />
						Depois de cadastrado, você pode incluir o motorista em sua conta
						empresarial por meio do CPF.
					</h1>
				</div>
			</WhiteContainer>
		</>
	);
};

export default AddNewDriverForm;
