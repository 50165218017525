import { twMerge } from 'tailwind-merge';
// @ts-expect-error
import { ReactComponent as MapMarker } from '../../assets/marker.svg';

interface RouteIconProps {
	size?: number;
	number: number;
	checkinDate?: string;
	checkoutDate?: string;
	reasonDate?: string;
	labelClassName?: string;
}

const RouteIcon = ({
	number,
	size = 34,
	checkinDate,
	checkoutDate,
	reasonDate,
	labelClassName,
}: RouteIconProps) => {
	const color = () => {
		if (checkinDate && checkoutDate) return 'text-[#91D130]';
		if (checkinDate && reasonDate) return 'text-[#DF7E7C]';
		if (checkinDate) return 'text-[#EDD045]';
		return 'text-[#95E8F0]';
	};

	return (
		<div className="relative flex h-fit items-center justify-center">
			<span className={color()}>
				<MapMarker width={size} height={size} />
			</span>
			<span
				className={twMerge(
					'absolute bottom-2 font-medium text-black',
					labelClassName
				)}
			>
				{number}
			</span>
		</div>
	);
};

export default RouteIcon;
