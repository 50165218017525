import React, { ComponentProps, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { ErrorTooltip } from '../Tooltip/ErrorTooltip';
import { Label } from './Label';
import { variants, Variants } from './variants';

interface InputProps extends ComponentProps<'textarea'> {
	label?: string;
	variant?: Variants;
	errorMessage?: string;
}

export const Textarea = forwardRef<HTMLTextAreaElement, InputProps>(
	function Input(
		{ label, className, variant = 'primary', errorMessage, disabled, ...props },
		ref
	) {
		const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
			event.target.style.height = 'auto';
			event.target.style.height = `${event.target.scrollHeight}px`;
		};

		return (
			<div className={twMerge('w-full', className)}>
				{label != null && <Label label={label} variant={variant} />}
				<div
					className={`flex h-auto w-full rounded border border-neutral-100 text-base text-neutral-900 placeholder-neutral-500 shadow outline-2 -outline-offset-2 focus-within:outline ${
						variants[variant].input
					} ${disabled ? 'bg-neutral-100' : 'bg-white'}`}
				>
					<textarea
						rows={2}
						disabled={disabled}
						className={`block h-auto w-full resize-y text-sm focus:outline-none ${
							disabled ? 'bg-neutral-100' : 'bg-white'
						}`}
						ref={ref}
						onInput={handleInput}
						onFocus={handleInput}
						{...props}
					/>
					{errorMessage && <ErrorTooltip message={errorMessage} />}
				</div>
			</div>
		);
	}
);
