import { TbArrowBackUp, TbListDetails, TbMap2 } from 'react-icons/tb';
import { OrderResponseApi } from '../../../../../services/angular/types/Order';
import { Actions, TableActions } from '../../../../../components/Table/Table';
import { useAlert } from '../../../../../contexts/AlertContext';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { api } from '../../../../../services/angular/axios';
import { toast } from 'react-toastify';
import { ImBlocked } from 'react-icons/im';
import { AiOutlineClear } from 'react-icons/ai';
import { useQueryClient } from '@tanstack/react-query';
import { RequestFreightSchema } from '../../../Freights/Request/schemas/RequestFreight';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { CancelOrderModal } from '../../../../../components/Order/CancelOrderModal';
import { canSendToPool } from '../../../../../helpers/utils';

interface OperationsActionsComponentProps {
	order: OrderResponseApi;
}

export const OperationsActionsComponent = ({
	order,
}: OperationsActionsComponentProps) => {
	const alert = useAlert();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [cancelModalReason, setCancelModalReason] = useState(false);
	const [orderId, setOrderId] = useState<number>();

	const actions: Array<Actions<typeof order> | undefined> = [
		{
			icon: <TbListDetails size={16} />,
			label: 'Detalhes',
			href: `/pedidos/${order?.id}`,
		},
		{
			icon: 'copy',
			label: 'Duplicar',
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			onAction: async () => {
				alert.onCustom({
					title: 'Duplicar pedido',
					message: `Deseja duplicar o frete #${order?.id}?`,
					confirm: {
						label: 'Sim',
						onClick: async () => {
							try {
								const dataToCreate = RequestFreightSchema.parse(order);
								const response = await api.post('/orders', {
									...dataToCreate,
									requesterCompanyId: order?.requesterCompanyId,
									statusId: 1,
								});
								if (response?.data?.id)
									navigate(
										`/pedidos/editar/${
											response?.data?.id as string
										}?duplicatedOrder=true`
									);
								toast.success('Frete duplicado com sucesso!');
							} catch (err) {
								toast.error('Erro ao duplicar frete!');
							}
						},
					},
				});
			},
		},
		{
			icon: <TbMap2 size={18} />,
			label: 'Acompanhar',
			href: `/pedidos/acompanhar/${order?.id}`,
		},
		{
			icon: 'edit',
			label: 'Editar',
			href: `/pedidos/editar/${order?.id}`,
		},
		{
			icon: <ImBlocked size={16} />,
			label: 'Cancelar',
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			onAction: async () => {
				setCancelModalReason(true);
				setOrderId(order?.id);
			},
		},
		{
			icon: <AiOutlineClear size={16} />,
			label: 'Limpar rejeites',
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			onAction: async (): Promise<void> => {
				alert.onCustom({
					title: 'Limpar rejeições',
					message: 'Deseja limpar as rejeições desse frete?',
					confirm: {
						label: 'Sim',
						onClick: async () => {
							try {
								await api.delete(`/orders/${order?.id}/clear-rejections`);
								toast.success('Rejeições limpas com sucesso');
								void queryClient.invalidateQueries({
									queryKey: ['closest-drivers'],
									exact: false,
								});
							} catch (error) {
								toast.error('Erro ao limpar rejeições');
							}
						},
					},
				});
			},
		},
	];

	return (
		<>
			<TableActions
				row={order as any}
				actions={
					actions.filter((action) => action) as Array<Actions<typeof order>>
				}
			/>
			<CancelOrderModal
				open={cancelModalReason}
				setOpen={setCancelModalReason}
				orderId={orderId}
			/>
		</>
	);
};

export const OperationsAceptedActionsComponent = ({
	order,
}: OperationsActionsComponentProps) => {
	const alert = useAlert();
	const navigate = useNavigate();
	const [cancelModalReason, setCancelModalReason] = useState(false);
	const [orderId, setOrderId] = useState<number>();

	const actions: Array<Actions<typeof order> | undefined> = [
		{
			icon: <TbListDetails size={16} />,
			label: 'Detalhes',
			href: `/pedidos/${order?.id}`,
		},
		{
			icon: 'copy',
			label: 'Duplicar',
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			onAction: async () => {
				alert.onCustom({
					title: 'Duplicar pedido',
					message: `Deseja duplicar o frete #${order?.id}?`,
					confirm: {
						label: 'Sim',
						onClick: async () => {
							try {
								const dataToCreate = RequestFreightSchema.parse(order);
								const response = await api.post('/orders', {
									...dataToCreate,
									requesterCompanyId: order?.requesterCompanyId,
									statusId: 1,
								});
								if (response?.data?.id)
									navigate(
										`/pedidos/editar/${
											response?.data?.id as string
										}?duplicatedOrder=true`
									);
								toast.success('Frete duplicado com sucesso!');
							} catch (err) {
								toast.error('Erro ao duplicar frete!');
							}
						},
					},
				});
			},
		},
		{
			icon: <TbMap2 size={18} />,
			label: 'Acompanhar',
			href: `/pedidos/acompanhar/${order?.id}`,
		},
		{
			icon: 'edit',
			label: 'Editar',
			href: `/pedidos/editar/${order?.id}`,
		},
		order?.statusId === 5 &&
		order?.locations.length >= 2 &&
		order?.locations.every((location) => location.checkoutDate)
			? {
					icon: <IoCheckmarkOutline size={20} />,
					label: 'Finalizar',
					onAction: () => {
						alert.onCustom({
							title: `Finalizar pedido #${order?.id}`,
							message: 'Deseja finalizar o frete?',
							confirm: {
								label: 'Sim',
								onClick: async () => {
									try {
										await api.put(`/orders/${order.id}/finish`);
										toast.success('Frete finalizado com sucesso!');
									} catch (err) {
										toast.error('Erro ao finalizar frete!');
									}
								},
							},
						});
					},
			  }
			: undefined,
		{
			icon: <ImBlocked size={16} />,
			label: 'Cancelar',
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			onAction: async () => {
				setCancelModalReason(true);
				setOrderId(order?.id);
			},
		},
		canSendToPool(order)
			? {
					icon: <TbArrowBackUp size={18} />,
					label: 'Enviar para pool',
					// eslint-disable-next-line @typescript-eslint/no-misused-promises
					onAction: async (): Promise<void> => {
						alert.onCustom({
							title: 'Enviar para pool',
							message: 'Deseja enviar o frete para a pool?',
							confirm: {
								label: 'Sim',
								onClick: async () => {
									try {
										await api.put(`/orders/${order.id}/backToPool`);
										toast.success('Frete enviado para a pool!');
									} catch (err) {
										toast.error('Erro ao enviar para a pool!');
									}
								},
							},
						});
					},
			  }
			: undefined,
	];

	return (
		<>
			<TableActions
				row={order as any}
				actions={
					actions.filter((action) => action) as Array<Actions<typeof order>>
				}
			/>
			<CancelOrderModal
				open={cancelModalReason}
				setOpen={setCancelModalReason}
				orderId={orderId}
			/>
		</>
	);
};
