import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const HamburgerIcon: React.FC<SvgProps> = () => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 40 40"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9 10H31"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 20H35"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9 30H31"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default HamburgerIcon;
