import React, { ChangeEvent } from 'react';
import { Cropper, CropperRef } from 'react-advanced-cropper';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { Avatar } from '../../../../components/Avatar';
import Button from '../../../../components/Button/Button';
import { Label } from '../../../../components/FormElements';
import { Icon } from '../../../../components/Icon/Icon';
import { Modal } from '../../../../components/Modal';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import './updateAvatar.css';
import { validateFileSize } from '../../../../helpers/validateFileSize';

interface UpdateCNHProps {
	disabled?: boolean;
	setCNH: (cnh: string) => void;
	src?: string;
	className?: string;
}

interface Image {
	type?: string;
	src: string;
}

export const UpdateCNH: React.FC<UpdateCNHProps> = ({
	setCNH,
	className,
	disabled,
}) => {
	const user = useUserState((state) => state.user);
	const cropperRef = React.useRef<CropperRef>(null);
	const [image, setImage] = React.useState<Image>();
	const [open, setOpen] = React.useState<boolean>(false);
	const [preview, setPreview] = React.useState<string | undefined>(undefined);

	const { data, isFetching } = useQuery(
		['driver-cnh', user?.driverLicenseImg],
		async () => {
			if (!user?.driverLicenseImg) return;
			return await api.get('/assets', {
				params: {
					imageName: user.driverLicenseImg,
				},
			});
		},
		{
			enabled: !!user?.driverLicenseImg,
		}
	);

	const onLoadImage = (event: ChangeEvent<HTMLInputElement>): void => {
		const { files } = event.target;

		if (files?.[0]) {
			if (!files[0].type.match('image.*')) {
				toast.error('Entre em contato com o suporte para trocar a imagem');
				event.target.value = '';
				return;
			}
			const blob = URL.createObjectURL(files[0]);

			setImage({ src: blob, type: files[0].type });
			setOpen(true);
		}
		event.target.value = '';
	};

	const onCrop = () => {
		if (cropperRef.current) {
			cropperRef.current.getCanvas()?.toBlob(
				(blob) => {
					if (blob && !validateFileSize(blob)) {
						return;
					}

					setPreview(URL.createObjectURL(blob as Blob));

					const formData = new FormData();
					formData.append('image', blob as Blob);

					void handleUpdate(formData);
					setOpen(false);
				},
				'image/jpeg',
				0.95
			);
		}
	};

	const handleUpdate = async (formData: FormData): Promise<void> => {
		const response = await api.post('/assets', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		setCNH(response.data.imageName);
	};

	return (
		<div
			className={twMerge('relative h-fit w-fit sm:w-1/2 md:w-auto', className)}
		>
			<Label label="Foto da CNH" variant="primary" />
			<Avatar
				avatarProps={{
					className: 'h-full w-auto md:h-40 md:w-40 rounded-lg',
				}}
				src={preview || data?.data.url}
				isLoading={isFetching}
			/>
			{!disabled && (
				<label className="absolute bottom-1 right-1 cursor-pointer items-center rounded-lg bg-neutral-0 p-1 text-white hover:text-yellow-500">
					<Icon name="edit" size={20} />
					<input onChange={onLoadImage} type="file" className="hidden" />
				</label>
			)}
			<Modal title="Cortar imagem" open={open} setOpen={setOpen}>
				<div className="flex max-h-[65vh] flex-col align-bottom">
					<Cropper
						ref={cropperRef}
						src={image?.src}
						stencilProps={{
							grid: true,
							aspectRatio: 1,
						}}
					/>
					<Button
						className="mt-4 w-auto self-end"
						variant="blue-primary"
						onClick={onCrop}
					>
						Salvar
					</Button>
				</div>
			</Modal>
		</div>
	);
};
