import React from 'react';
import { IoMdSave } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';
import { twMerge } from 'tailwind-merge';
import Button, { ButtonProps } from '../Button/Button';

interface FormButtonsProps extends React.ComponentPropsWithoutRef<'div'> {
	className?: string;
	cancelLabel?: string;
	onCancel?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	cancelProps?: ButtonProps;
	saveLabel?: string;
	onSave?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	saveProps?: ButtonProps;
}

export const FormButtons: React.FC<FormButtonsProps> = ({
	className,
	cancelLabel = 'Cancelar',
	onCancel,
	cancelProps,
	saveLabel = 'Salvar',
	onSave,
	saveProps,
	...props
}) => {
	return (
		<div className={twMerge('flex justify-end', className)} {...props}>
			<div className={`grid ${onCancel ? 'grid-cols-2' : 'grid-col'}  gap-2`}>
				{onCancel && (
					<Button
						type="button"
						onClick={(e) => onCancel(e)}
						variant="primary"
						{...cancelProps}
					>
						{cancelLabel}
						<IoClose size={20} />
					</Button>
				)}
				<Button
					type="submit"
					variant="blue-primary"
					onClick={(e) => onSave?.(e)}
					{...saveProps}
				>
					{saveLabel}
					<IoMdSave size={20} />
				</Button>
			</div>
		</div>
	);
};
