import { z } from 'zod';
import { ErrorsMessageHelper } from '../../../../../helpers/errors/ErrorsMessageHelper';
import { phoneRegex } from '../../../../../helpers/mask/phoneMask';
import { RegexHelper } from '../../../../../helpers/regex/RegexHelper';

export const CompanySchema = z
	.object({
		makeFreights: z.boolean().default(false),
		requestFreights: z.boolean().default(false),
		tradingName: z.string().optional(),
		companyName: z.string().min(1, { message: ErrorsMessageHelper.required }),
		cnpj: z
			.string()
			.min(1, { message: ErrorsMessageHelper.required })
			.min(18, { message: ErrorsMessageHelper.min(14) })
			.max(18, { message: ErrorsMessageHelper.max(14) })
			.regex(RegexHelper.cnpj, {
				message: ErrorsMessageHelper.cnpj,
			}),
		email: z
			.string()
			.email({ message: ErrorsMessageHelper.email })
			.optional()
			.or(z.literal('')),
		supportEmail: z
			.string()
			.email({ message: ErrorsMessageHelper.email })
			.optional()
			.or(z.literal('')),
		cellphone: z
			.string()
			.regex(phoneRegex, {
				message: ErrorsMessageHelper.phone,
			})
			.optional()
			.or(z.literal(''))
			.nullable(),
		phone: z
			.string()
			.regex(phoneRegex, {
				message: ErrorsMessageHelper.phone,
			})
			.optional()
			.or(z.literal(''))
			.nullable(),
		address: z.string().optional().or(z.literal('')),
		zipcode: z.string().optional().or(z.literal('')),
		number: z
			.string()
			.transform((value) => (value ? Number(value) : null))
			.optional()
			.or(z.literal('')),
		district: z.string().optional(),
		city: z.string().optional().or(z.literal('')),
		stateId: z
			.object({
				value: z.string().or(z.number()).optional(),
				label: z.string().optional(),
			})
			.optional()
			.nullable()
			.transform((value) => (value?.value ? String(value?.value) : '')),
		spendingGoal: z.coerce.number().optional().nullable(),
		complement: z.string().optional(),
		avatar: z.string().optional().nullable(),
		leadId: z
			.object({
				value: z.string().or(z.number()).optional(),
				label: z.string().optional(),
			})
			.optional()
			.nullable()
			.refine((value) => value !== null, {
				message: 'Obrigatório',
			})
			.transform((value) => (value?.value ? String(value?.value) : '')),
		businessSegmentId: z
			.object({
				value: z.string().or(z.number()).optional(),
				label: z.string().optional(),
			})
			.optional()
			.nullable()
			.refine((value) => value !== null, {
				message: 'Obrigatório',
			})
			.transform((value) => (value?.value ? String(value?.value) : '')),
		observation: z.string().optional().nullable(),
		discount: z.coerce.number().optional(),
		increase: z.coerce.number().optional(),
		dynamicRouteEnabled: z.boolean().optional(),
		allowFavoriteDriver: z.boolean().optional(),
		allowBankingBill: z.boolean().optional(),
		storeProtocolsInStaticRoute: z.boolean().optional(),
	})
	.refine(
		({ makeFreights, requestFreights }) => makeFreights || requestFreights,
		{
			path: ['makeFreights'],
			message: 'Selecione ao menos uma opção',
		}
	);

export type CompanySchemaType = z.infer<typeof CompanySchema>;
