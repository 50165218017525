export const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

export const cpfMask = (value?: string): string => {
	if (!value) return '';
	return value
		.replace(/\D/g, '')
		.slice(0, 11)
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
		.replace(/(-\d{2})\d$/, '$1');
};

export const removeCpfMask = (value: string | undefined): string => {
	if (!value) return '';
	return value.replace(/\D/g, '');
};
