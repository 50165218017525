import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { RegisterButton } from '../../../../components/Button/RegisterButton';
import { PageHeader } from '../../../../components/PageHeader';
import { Table } from '../../../../components/Table/Table';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import { SortOptions } from '../../../../services/angular/types/Params';
import { CompanyUsers } from '../CompanyUsers';
import AddNewUserForm from './components/AddNewUserForm';
import { useAlert } from '../../../../contexts/AlertContext';
import { phoneMask, removePhoneMask } from '../../../../helpers/mask/phoneMask';
import { useCopy } from '../../../../hooks/useCopy';

const AngularCompanyUsers: React.FC = () => {
	const alert = useAlert();
	const copy = useCopy();
	const company = useUserState((state) => state.company);
	const [addNewUserForm, setAddNewUserForm] = useState(false);
	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});

	const { data, isLoading, isFetching, refetch } = useQuery(
		['companyUsers', params, company?.id],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/companies/${company?.id}/users`,
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
					},
				}
			),
		{
			refetchOnMount: 'always',
		}
	);

	const deleteCompanyUser = async (id: number) => {
		alert.onCustom({
			title: 'Excluir usuário',
			message: 'Deseja realmente excluir o usuário?',
			confirm: {
				label: 'Excluir',
				onClick: async () => {
					try {
						await api.delete(`/companies/${company?.id || ''}/users/${id}`);
						toast.success('Usuário excluído com sucesso');
						void refetch();
					} catch (error) {
						toast.error('Erro ao excluir usuário');
					}
				},
			},
		});
	};

	return (
		<>
			{addNewUserForm ? (
				<AddNewUserForm />
			) : (
				<>
					<PageHeader
						title="Usuários"
						description="Relação de usuários cadastrados na empresa"
					>
						<RegisterButton
							className="w-auto"
							onClick={() => setAddNewUserForm(true)}
						/>
					</PageHeader>
					<WhiteContainer className="mt-8">
						<Table<CompanyUsers>
							isLoading={isLoading || isFetching}
							data={data?.data.data || []}
							total={data?.data.meta.pagination.total || 0}
							sort
							onParamsChange={(params) => {
								setParams(params);
							}}
							actions={[
								{
									icon: 'trash',
									label: 'Excluir',
									onAction: (data) => {
										void deleteCompanyUser(data.id);
									},
								},
							]}
							disabledSort={{
								isAdmin: true,
							}}
							columns={[
								{
									accessor: 'name',
									Header: 'Nome',
								},
								{
									accessor: 'cpf',
									Header: 'CPF',
								},
								{
									accessor: 'cellphone',
									Header: 'Celular',
									Cell: ({ value }) => (
										<div
											className=" flex cursor-copy"
											onClick={() => {
												void copy(removePhoneMask(value, '+55'));
											}}
										>
											{phoneMask(removePhoneMask(value))}
										</div>
									),
								},
								{
									accessor: 'email',
									Header: 'Email',
								},
								{
									accessor: 'blocked',
									Header: 'Status',
									width: '10%',
									Cell: ({ value }) => (
										<div className="flex">
											{value ? (
												<div className="w-fit rounded bg-terracota-500 px-1.5 py-px text-xs text-white">
													Bloqueado
												</div>
											) : (
												<div className="w-fit rounded bg-green px-1.5 py-px text-xs text-white">
													Desbloqueado
												</div>
											)}
										</div>
									),
								},
								{
									accessor: 'isAdmin',
									Header: 'Perfil',
									width: '10%',
									Cell: ({ value }) => (
										<div className="flex">
											{value ? (
												<div className="w-fit rounded bg-blue px-1.5 py-px text-xs text-white">
													Administrador
												</div>
											) : (
												<div className="w-fit rounded bg-neutral-400 px-1.5 py-px text-xs text-white">
													Usuário Comum
												</div>
											)}
										</div>
									),
								},
							]}
						/>
					</WhiteContainer>
				</>
			)}
		</>
	);
};

export default AngularCompanyUsers;
