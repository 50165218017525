import { z } from 'zod';
import Button from '../../../../components/Button/Button';
import { Input, useZodForm } from '../../../../components/FormElements';
import {
	cellPhoneOnlyMask,
	cellphoneOnlyRegex,
	removePhoneMask,
} from '../../../../helpers/mask/phoneMask';
import { useMutation } from '@tanstack/react-query';
import { api } from '../../../../services/angular/axios';
import { useEffect, useState } from 'react';
import { useApiVersionStore } from '../../../../services/angular/phpVersion';
import { useInviteCampaignStore } from '../inviteCampaignStore';
import { ToastError } from '../../../../helpers/errors/ToastError';

const schema = z.object({
	cellphone: z
		.string({
			required_error: 'Número de telefone obrigatório',
		})
		.regex(cellphoneOnlyRegex, {
			message: 'Número de telefone inválido',
		}),
});

type Form = z.infer<typeof schema>;

interface VerifyPhoneResponse {
	cellphone: string;
	active: boolean;
}

interface SendCodeResponse {
	id: number;
	cellphone: string;
	cpf: string | null;
}

export const PhoneStep = () => {
	const setVersion = useApiVersionStore((state) => state.setVersion);
	const upsertForm = useInviteCampaignStore((state) => state.upsertForm);
	const [disableButton, setDisableButton] = useState(true);

	const form = useZodForm({
		schema,
		mode: 'onChange',
	});

	const { mutate: verifyPhone, isLoading: verifying } = useMutation({
		mutationFn: async ({ cellphone }: Form) => {
			setVersion('v2');

			return await api.get<VerifyPhoneResponse>(
				`/verify-phone/${removePhoneMask(cellphone)}`
			);
		},
		onSuccess: ({ data }) => {
			if (data.active) {
				form.setError('cellphone', { message: 'Número de telefone já em uso' });
				setVersion('v1');
				setDisableButton(true);
			}
			else {
				form.setError('cellphone', { message: undefined});
				setDisableButton(false);
			}
		},
		onError: () => {
			setDisableButton(true);
			setVersion('v1');
		},
	});

	const { mutate: sendCode, isLoading } = useMutation({
		mutationFn: async ({ cellphone }: Form) => {
			setVersion('v2');

			return await api.post<SendCodeResponse>(`/register`, {
				cellphone: removePhoneMask(cellphone),
				bikerStatus: 0,
				clientStatus: 0,
				driverStatus: 1,
			});
		},
		onSuccess: ({ data }) => {
			upsertForm({
				step: 'phoneValidation',
				cellphone: form.getValues('cellphone'),
				userId: data.id,
			});
		},
		onError: (error) => {
			ToastError(error);
		},
		onSettled: () => {
			setVersion('v1');
		},
	});

	useEffect(() => {
		if (form.formState.isValid) verifyPhone(form.getValues());
	}, [form.formState.isValid]);

	useEffect(() => {
		if (form.watch('cellphone').length < 16) setDisableButton(true);
	}, [form.watch('cellphone')]);

	const onSubmit = form.handleSubmit((data) => sendCode(data));

	return (
		<div className="flex flex-col gap-4">
			<Input
				autoFocus
				type="tel"
				placeholder="(00) 0 0000-0000"
				errorMessage={form.formState.errors.cellphone?.message}
				{...form.register('cellphone', {
					onChange: (e) => {
						e.target.value = cellPhoneOnlyMask(e.target.value);
					},
				})}
				onPaste={(e) => {
					e.preventDefault();
					const pastedText = e.clipboardData.getData('text/plain');
					form.setValue('cellphone', cellPhoneOnlyMask(pastedText));
				}}
			/>
			<Button
				type="submit"
				disabled={disableButton}
				// eslint-disable-next-line @typescript-eslint/no-misused-promises
				onClick={onSubmit}
				loading={isLoading || verifying}
			>
				Avançar
			</Button>
		</div>
	);
};
