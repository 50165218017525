import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const GridIcon: React.FC<SvgProps> = ({
	color = '#868686',
	size,
	stroke = '2',
}) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 5.33333V0H5.33333V5.33333H0ZM0 12V6.66667H5.33333V12H0ZM6.66667 5.33333V0H12V5.33333H6.66667ZM6.66667 12V6.66667H12V12H6.66667ZM1.33333 4H4V1.33333H1.33333V4ZM8 4H10.6667V1.33333H8V4ZM8 10.6667H10.6667V8H8V10.6667ZM1.33333 10.6667H4V8H1.33333V10.6667Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default GridIcon;
