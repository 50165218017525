import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { IoCheckmark } from 'react-icons/io5';
import Button from '../../../../components/Button/Button';
import {
	Date,
	Form,
	FormHeader,
	Input,
	useZodForm,
} from '../../../../components/FormElements';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import { toastMessage } from '../../../../helpers/toastMessage';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import {
	DriverInfoSchema,
	DriverInfoSchemaType,
} from '../schemas/DriverInfoSchema';
import { UpdateCNH } from './UpdateCNH';
import { Controller } from 'react-hook-form';
import { appState } from '../../../../store/appState';

interface DriverInfoProps {
	className?: string;
}

export const DriverInfo = ({ className }: DriverInfoProps) => {
	const user = useUserState((state) => state.user);
	const triggerRefetch = useUserState((state) => state.triggerRefetch);

	const form = useZodForm({
		schema: DriverInfoSchema,
		defaultValues: {
			driverLicense: user?.driverLicense || '',
			driverLicenseImg: user?.driverLicenseImg || '',
			driverLicenseExpiration: user?.driverLicenseExpiration || '',
			rg: user?.rg || '',
		},
	});

	const {
		register,
		formState: { errors },
	} = form;

	const handleUpdate = async (data: DriverInfoSchemaType): Promise<void> => {
		try {
			await api.put(`/users/${String(user?.id)}`, {
				id: user?.id,
				cpf: user?.cpf,
				...data,
			});
			toast.success(toastMessage.success.updated);
			triggerRefetch();
		} catch (err) {
			toast.error('Erro ao atualizar os dados.');
		}
	};

	useEffect(() => {
		if (errors.driverLicenseImg) toast.error('Foto da CNH é obrigatória.');
	}, [errors]);

	return (
		<WhiteContainer
			divProps={{
				className,
			}}
		>
			<Form form={form} onSubmit={handleUpdate}>
				<div className="flex flex-wrap justify-between">
					<FormHeader
						className="mb-4"
						title="Motorista"
						description="Dados obrigatórios para Motoristas"
					/>
					<div className="flex items-start gap-2">
						{user?.driverStatus ===
							Number(appState.driverStatus['Motorista Ativo'].value) && (
							<span className="rounded bg-green p-1 text-2xs font-semibold text-white">
								CNH aprovada
							</span>
						)}
						{user?.trainingDate && (
							<span className="rounded bg-green p-1 text-2xs font-semibold text-white">
								Treinado
							</span>
						)}
					</div>
				</div>
				<div className="flex flex-col gap-4 md:flex-row">
					<div className="grid h-fit w-full grid-cols-4 gap-4">
						<Input
							className="col-span-full sm:col-span-2"
							{...register('driverLicense')}
							label="CNH"
							errorMessage={errors.driverLicense?.message}
							disabled={
								user?.driverStatus ===
								Number(appState.driverStatus['Motorista Ativo'].value)
							}
						/>
						<Date
							className="col-span-full sm:col-span-2"
							label="Vencimento da CNH"
							control={form.control}
							name="driverLicenseExpiration"
							errorMessage={errors.driverLicenseExpiration?.message?.toString()}
							disabled={
								user?.driverStatus ===
								Number(appState.driverStatus['Motorista Ativo'].value)
							}
						/>
						<Input
							className="col-span-full sm:col-span-2"
							{...register('rg')}
							label="RG"
							disabled={
								user?.driverStatus ===
								Number(appState.driverStatus['Motorista Ativo'].value)
							}
						/>
					</div>
					<Controller
						name="driverLicenseImg"
						control={form.control}
						render={({ field }) => (
							<UpdateCNH
								setCNH={field.onChange}
								disabled={
									user?.driverStatus ===
									Number(appState.driverStatus['Motorista Ativo'].value)
								}
							/>
						)}
					/>
				</div>
				{user?.driverStatus ===
				Number(appState.driverStatus['Motorista Ativo'].value) ? (
					<div className="text-sm text-neutral-600">
						<a
							href="https://beebeelogistica.freshdesk.com/support/home"
							target="_blank"
							className="cursor-pointer underline hover:text-neutral-700"
							rel="noreferrer"
						>
							Entre em contato
						</a>{' '}
						com Bee Bee para alterar os dados de motorista.
					</div>
				) : (
					<Button
						type="submit"
						className="mt-4 w-auto"
						disabled={!form.formState.isDirty}
					>
						<IoCheckmark size={24} />
						Salvar alterações
					</Button>
				)}
			</Form>
		</WhiteContainer>
	);
};
