import { useAtom } from 'jotai';
import { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { RequestFreightForm, OrderMutation, updateRouteAtom } from '..';
import Bee from '../../../../../assets/svg/Bee';
import { oldIcons, VehicleIcon } from '../../../../../components/Icon/vehicles';
import { Loading } from '../../../../../components/Loading';
import { Tooltip } from '../../../../../components/Tooltip';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { OrderResponseApi } from '../../../../../services/angular/types/Order';
import { VehicleCategory } from '../../../../../services/angular/types/Vehicle';

interface VehicleStepProps {
	form: RequestFreightForm;
	vehicles: VehicleCategory[];
	isLoading: boolean;
	orderMutation: OrderMutation;
	order?: OrderResponseApi;
}

export const VehicleStep = ({
	form,
	vehicles = [],
	isLoading,
	orderMutation,
	order,
}: VehicleStepProps) => {
	const [updateDraft, triggerUpdateDraft] = useAtom(updateRouteAtom);

	const isFirstRender = useRef(true);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		if (orderMutation.isSuccess || order) {
			triggerUpdateDraft(!updateDraft);
			return;
		}

		if (form.watch('scheduleDate') !== undefined)
			form.reset({
				...form.getValues(),
				scheduleDate: undefined,
				orderTypeId: undefined,
				stopsGoal: null,
			});
	}, [form.watch('categoryId'), form.watch('categoryType')]);

	if (isLoading) return <Loading title="Carregando informações..." />;

	return (
		<div className="mb-20 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
			<Controller
				control={form.control}
				name="categoryId"
				render={({ field }) => (
					<>
						{vehicles
							.sort((a, b) => Number(a.weight) - Number(b.weight))
							.map((vehicle) => {
								return (
									<WhiteContainer
										key={vehicle.id}
										className={twMerge(
											'group flex h-full cursor-pointer items-center shadow',
											!field.value || field.value === vehicle.id
												? ''
												: 'opacity-60 transition-all',
											field.value === vehicle.id
												? 'ring-2 ring-blue'
												: 'hover:opacity-100 hover:ring-2 hover:ring-[#e8b21a]'
										)}
										onClick={(e) => {
											e.stopPropagation();
											field.onChange(vehicle.id);
											form.setValue('categoryType', 0);
										}}
									>
										{field.value === vehicle.id &&
										vehicle.typeOneActive &&
										vehicle.typeTwoActive ? (
											<Controller
												control={form.control}
												name="categoryType"
												defaultValue={0}
												render={({ field }) => (
													<>
														<p className="mb-2 text-center text-lg font-bold">
															Selecione uma subcategoria
														</p>
														<div className="grid h-full w-full grid-cols-3 place-items-center gap-2">
															<Tooltip
																message={
																	vehicle.typeOneTooltip ||
																	vehicle.typeOneDescription
																}
																triggerClassName="cursor-pointer self-start"
															>
																<div
																	className={twMerge(
																		'flex flex-col items-center justify-center text-center transition-colors hover:text-blue',
																		field.value === 1
																			? 'text-blue'
																			: 'text-neutral-200'
																	)}
																	onClick={(e) => {
																		e.stopPropagation();
																		field.onChange(1);
																	}}
																>
																	<VehicleIcon
																		/* @ts-expect-error */
																		name={oldIcons[vehicle.typeOneIcon]}
																		size={72}
																	/>
																	{vehicle.typeOneDescription}
																</div>
															</Tooltip>
															<Tooltip
																message="Mais entregadores, menor espera"
																triggerClassName="cursor-pointer self-start"
															>
																<div
																	className={twMerge(
																		'flex flex-col items-center justify-center text-center transition-colors hover:text-blue',
																		field.value === 0
																			? 'text-blue'
																			: 'text-neutral-400'
																	)}
																	onClick={(e) => {
																		e.stopPropagation();
																		field.onChange(0);
																	}}
																>
																	<span className="h-[72px] w-[72px]">
																		<Bee />
																	</span>
																	Tanto faz
																</div>
															</Tooltip>
															<Tooltip
																message={
																	vehicle.typeTwoTooltip ||
																	vehicle.typeTwoDescription
																}
																triggerClassName="cursor-pointer self-start"
															>
																<div
																	className={twMerge(
																		'flex flex-col items-center justify-center text-center transition-colors hover:text-blue',
																		field.value === 2
																			? 'text-blue'
																			: 'text-neutral-400'
																	)}
																	onClick={(e) => {
																		e.stopPropagation();
																		field.onChange(2);
																	}}
																>
																	<VehicleIcon
																		/* @ts-expect-error */
																		name={oldIcons[vehicle.typeTwoIcon]}
																		size={72}
																	/>
																	{vehicle.typeTwoDescription}
																</div>
															</Tooltip>
														</div>
													</>
												)}
											/>
										) : (
											<>
												<p className="text-lg font-bold text-neutral-0 ">
													{vehicle.name}
												</p>
												<VehicleIcon
													/* @ts-expect-error */
													name={oldIcons[vehicle.icon]}
													size={72}
												/>
												<div className="flex w-full flex-col items-center justify-center text-center text-xs">
													<span>
														Dimensões:{' '}
														{`${vehicle.height || ''} x ${
															vehicle.width || ''
														} x ${vehicle.length || ''}`}
													</span>

													<span>
														Capacidade máxima: {vehicle.weight || ''}kg
													</span>
													<span>{vehicle.weightDetails}</span>
												</div>
											</>
										)}
									</WhiteContainer>
								);
							})}
					</>
				)}
			/>
		</div>
	);
};
