import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import WhiteContainer from '../../../components/WhiteContainer/WhiteContainer';
import { cpfMask, cpfRegex } from '../../../helpers/mask/cpfMask';

import { Input } from '../../../components/FormElements/Input';
import LoginImageMan from '../../../assets/svg/LoginImageMan';
import LogoSVGColored from '../../../assets/svg/LogoSVGColored';
import { api } from '../../../services/angular/axios';
import useUserState from '../../../services/angular/angularUserState';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { Form, useZodForm } from '../../../components/FormElements';
import { toast } from 'react-toastify';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require('../../../../package.json');

const loginSchema = z.object({
	cpf: z.string().regex(cpfRegex, {
		message: 'CPF inválido',
	}),
	password: z.string().min(6),
});

type LoginSchemaType = z.infer<typeof loginSchema>;

const Login: React.FC = () => {
	const navigate = useNavigate();
	const login = useUserState((state) => state.login);

	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const form = useZodForm({
		schema: loginSchema,
	});

	const {
		register,
		formState: { errors },
	} = form;

	const { mutate } = useMutation(
		async (data: LoginSchemaType) => (await api.post('/login-slim', data)).data,
		{
			onSuccess: (data) => {
				login(data.token);
				navigate('/');
			},
			onError: () => {
				toast.error(
					'Erro ao fazer login, verifique suas credenciais e tente novamente'
				);
			},
		}
	);

	return (
		<div className="flex items-center justify-center gap-12">
			<div className="hidden gap-4 md:flex md:flex-col">
				<div className="text-left text-5xl font-bold text-white">
					Um <span className="text-yellow-500">jeito novo</span>
					<br />
					de fazer <span className="text-yellow-500">entregas</span>
				</div>
				<LoginImageMan />
			</div>

			<div className="flex flex-col items-center gap-8">
				<div className="w-56">
					<LogoSVGColored />
				</div>

				<Form
					form={form}
					onSubmit={(data) => {
						mutate(data);
					}}
				>
					<WhiteContainer className="flex w-[90vw] flex-col gap-4 sm:w-[70vw] md:w-96">
						<h1 className="text-start text-2xl font-bold text-neutral-900">
							Entrar
						</h1>
						<Input
							{...register('cpf', {
								onChange: (e) => {
									e.target.value = cpfMask(e.target.value);
								},
							})}
							errorMessage={errors.cpf?.message}
							label="CPF"
						/>

						<Input
							{...register('password')}
							errorMessage={errors.password?.message}
							label="Senha"
							type={isPasswordVisible ? 'text' : 'password'}
							maxLength={20}
							inputIcon={
								isPasswordVisible ? (
									<BsFillEyeFill onClick={() => setIsPasswordVisible(false)} />
								) : (
									<BsFillEyeSlashFill
										onClick={() => setIsPasswordVisible(true)}
									/>
								)
							}
						/>

						<p
							onClick={() => navigate('/esqueci-minha-senha')}
							className="cursor-pointer text-sm text-neutral-500 hover:underline"
						>
							Esqueci minha senha
						</p>
						<Button className="py-1 text-lg" type="submit">
							Entrar
						</Button>
						<Button
							className="py-1 text-lg"
							variant="secondary"
							onClick={() => {
								navigate('/cadastro-empresa');
							}}
						>
							Cadastrar
						</Button>
					</WhiteContainer>
				</Form>
			</div>
			<p className="absolute bottom-2 right-2 text-xs">{version}</p>
		</div>
	);
};

export default Login;
