import { z } from 'zod';
import { dateFilterSchema } from '../../../../../helpers/dateFilterSchema';

export const TransactionsFilterSchema = dateFilterSchema.extend({
	transactionStatusId: z
		.object({
			value: z.string(),
			label: z.string().optional(),
		})
		.nullable()
		.optional()
		.transform((value) => value?.value),
	paymentTypeId: z
		.object({
			value: z.string(),
			label: z.string().optional(),
		})
		.nullable()
		.optional()
		.transform((value) => value?.value),
	userBuyerName: z.string().optional(),
});

export type TransactionsFilterSchemaType = z.infer<
	typeof TransactionsFilterSchema
>;
