import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useMemo, useRef } from 'react';
import { TbClock, TbTruckDelivery } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';
import { useStore } from 'zustand';
import { RequestFreightForm, updateRouteAtom } from '..';
import { Date, useZodForm } from '../../../../../components/FormElements';
import { Tooltip } from '../../../../../components/Tooltip';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { VehicleCategory } from '../../../../../services/angular/types/Vehicle';
import { appStateStore } from '../../../../../store/appState';
import { z } from 'zod';

interface DateStepProps {
	form: RequestFreightForm;
	vehicles: VehicleCategory[];
}

export const DateStep = ({ form, vehicles = [] }: DateStepProps) => {
	const [updateDraft, triggerUpdateDraft] = useAtom(updateRouteAtom);
	const { config } = useStore(appStateStore);

	const { watch: watchForm } = form;

	const selectedVehicle = useMemo(
		() => vehicles.find((v) => v.id === watchForm('categoryId')),
		[watchForm('categoryId'), vehicles]
	);

	const timerRef = useRef<any>(null);

	const handleChange = () => {
		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			triggerUpdateDraft(!updateDraft);
		}, 1000);
	};

	const scheduleForm = useZodForm({
		schema: z.object({
			scheduleDate: z
				.any()
				.nullable()
				.optional()
				.transform((value, ctx) => {
					if (typeof value === 'object') {
						const date = dayjs(value);
						const validDate = dayjs().add(
							selectedVehicle?.scheduleAdvance ||
							config.freights.minScheduleHoursAntecedence,
							'minutes'
						);

						if (date.isBefore(validDate)) {
							ctx.addIssue({
								code: 'custom',
								message:
									'A data de agendamento deve ser maior que a data escolhida',
							});
							form.setError('scheduleDate', {
								type: 'custom',
								message:
									'A data de agendamento deve ser maior que a data escolhida',
							});
						} else {
							form.clearErrors('scheduleDate');
						}
					}

					form.setValue('scheduleDate', value);
					return value;
				}),
		}),
		defaultValues: {
			scheduleDate: form.getValues('scheduleDate'),
		},
	});

	const {
		setValue,
		watch,
		formState: { errors },
	} = scheduleForm;

	return (
		<WhiteContainer className="mb-20 flex flex-col items-center justify-center gap-4">
			<div className="text-center">
				<h1 className="text-xl font-medium text-neutral-900">
					{watch('scheduleDate') !== undefined
						? watch('scheduleDate')
							? 'Agendado'
							: 'Imediato'
						: 'Quando começar'}
				</h1>
				<p className="ml-1">
					{watch('scheduleDate') ? (
						watch('scheduleDate') ? (
							<>
								<span className="font-bold text-xl text-terracota-500">
									Agendamento mínimo para
								</span>{' '}
								<span className="font-bold text-xl text-terracota-500">
									{dayjs()
										.add(
											(selectedVehicle?.scheduleAdvance ||
												config.freights.minScheduleHoursAntecedence) + 1,
											'minutes'
										)
										.format('DD/MM/YYYY HH:mm')}
								</span>
							</>
						) : (
							<span className="text-neutral-500">O frete começa imediatamente</span>
						)
					) : (
						<span className="text-neutral-500">Frete imediato ou agendado?</span>
					)}
				</p>
			</div>
			<div className="flex items-center justify-center gap-12">
				{selectedVehicle?.allowImmediate && (
					<div
						className={twMerge(
							'group flex flex-col items-center justify-center text-center',
							'cursor-pointer transition-colors hover:text-blue',
							watch('scheduleDate') === null ? 'text-blue' : 'text-neutral-200'
						)}
						onClick={() => {
							form.setValue('scheduleDate', null);
							setValue('scheduleDate', null);
							triggerUpdateDraft(!updateDraft);
						}}
					>
						<TbTruckDelivery
							className={twMerge(
								'transition-colors group-hover:text-blue',
								watch('scheduleDate') === null
									? 'text-blue'
									: 'text-neutral-200'
							)}
							size={72}
						/>
						Imediato
					</div>
				)}

				{selectedVehicle?.allowScheduled && (
					<div
						className={twMerge(
							'group relative flex flex-col items-center justify-center text-center',
							'cursor-pointer transition-colors hover:text-blue',
							watch('scheduleDate') ? 'text-blue' : 'text-neutral-200'
						)}
						onClick={() => {
							form.setValue('scheduleDate', 'schedule');
							setValue('scheduleDate', 'schedule');
						}}
					>
						{!!selectedVehicle?.scheduledDiscount && (
							<div className="absolute -right-8 bottom-6 w-full">
								<Tooltip message="Economize agendando seu frete">
									<span
										className={twMerge(
											'rounded-full px-2 py-1 text-xs font-medium text-white',
											'transition-colors group-hover:bg-terracota-600',
											watch('scheduleDate')
												? 'bg-terracota-600'
												: 'bg-terracota-400'
										)}
									>
										{selectedVehicle.scheduledDiscount}% OFF
									</span>
								</Tooltip>
							</div>
						)}
						<TbClock size={72} />
						Agendado
					</div>
				)}
			</div>
			{watch('scheduleDate') && (
				<Date
					className="w-full md:w-1/3"
					label="Data do Frete"
					control={scheduleForm.control}
					name="scheduleDate"
					minDate={dayjs()
						.add(
							selectedVehicle?.scheduleAdvance ||
							config.freights.minScheduleHoursAntecedence,
							'minutes'
						)
						.toDate()}
					time
					errorMessage={errors?.scheduleDate?.message?.toString()}
					onDateChange={() => handleChange()}
				/>
			)}
		</WhiteContainer>
	);
};
