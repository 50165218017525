import { PageHeader } from '../../../../components/PageHeader';

const Monitoring = () => {
	return (
		<div>
			<PageHeader
				title="Monitoramento"
				description="Relação de motoristas online"
			/>
		</div>
	);
};

export default Monitoring;
