export const toastMessage = {
	success: {
		created: 'Registro criado com sucesso!',
		updated: 'Registro atualizado com sucesso!',
		deleted: 'Registro excluído com sucesso!',
		deletedAll: 'Registros excluídos com sucesso!',
		sent: 'Email enviado com sucesso!',
	},
	error: {
		default: 'Ocorreu um erro ao processar a requisição!',
		created: 'Erro ao criar registro!',
		updated: 'Erro ao atualizar registro!',
		deleted: 'Erro ao excluir registro!',
		deletedAll: 'Erro ao excluir registros!',
	},
};
