import * as Toggle from '@radix-ui/react-toggle-group';
import { Label } from '../FormElements';
import { twMerge } from 'tailwind-merge';

interface ToggleGroupProps {
	label: string;
	options: string[];
	value: string;
	setValue: (value: string) => void;
	itemClassName?: string[];
}

export const ToggleGroup = ({
	label,
	options,
	setValue,
	value,
	itemClassName,
}: ToggleGroupProps) => (
	<div className="w-full">
		<Label label={label} variant="light" />
		<Toggle.Root
			className="inline-flex w-full space-x-px rounded"
			type="single"
			value={value}
			onValueChange={setValue}
		>
			{options.map((option, index) => {
				return (
					<Toggle.Item
						className={twMerge(
							'flex flex-1 items-center justify-center bg-white px-2 py-1.5 text-sm font-medium leading-4 transition-all duration-200 first:rounded-l last:rounded-r focus:z-10 focus:outline-none data-[state=on]:bg-neutral-800 data-[state=on]:text-neutral-50 hover:scale-105 hover:transform hover:bg-neutral-700 hover:text-neutral-50',
							itemClassName?.[index]
						)}
						value={option}
						aria-label={option}
						key={index}
					>
						{option}
					</Toggle.Item>
				);
			})}
		</Toggle.Root>
	</div>
);
