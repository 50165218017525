import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import Button from '../Button/Button';
import { AlertBody } from '../../contexts/AlertContext';

interface AlertProps {
	body: AlertBody;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Alert = ({ open, setOpen, body }: AlertProps) => {
	return (
		<AlertDialog.Root open={open} onOpenChange={setOpen}>
			<AlertDialog.Portal forceMount>
				<Transition.Root show={open}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-50"
						leave="ease-in duration-200"
						leaveFrom="opacity-50"
						leaveTo="opacity-0"
					>
						<AlertDialog.Overlay className="fixed inset-0 z-50 bg-neutral-0 opacity-50" />
					</Transition.Child>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<AlertDialog.Content
							onEscapeKeyDown={(e) => e.preventDefault()}
							className={`fixed left-1/2 top-1/2 z-50 flex max-h-[85vh] w-full max-w-[90vw] -translate-x-1/2 -translate-y-1/2 flex-col gap-4 overflow-auto rounded-md bg-white p-4 px-6 shadow sm:gap-8 sm:p-8 sm:px-10 md:w-fit md:max-w-md `}
						>
							<AlertDialog.Title className="text-center text-lg font-bold text-neutral-900 sm:text-3xl ">
								{body?.title}
							</AlertDialog.Title>
							<AlertDialog.Description className="text-center text-base text-neutral-800 sm:text-xl">
								{body.message}
							</AlertDialog.Description>
							<div
								className={`grid gap-2 ${
									body.confirm ? 'grid-cols-2' : 'grid-cols-1'
								}`}
							>
								<AlertDialog.Cancel asChild>
									<Button
										className="p-1 text-base sm:text-lg md:px-3 md:py-2"
										variant={body?.cancel?.variant}
										onClick={body?.cancel?.onClick}
									>
										{body?.cancel?.label}
										{body?.cancel?.icon}
									</Button>
								</AlertDialog.Cancel>
								{body.confirm && (
									<AlertDialog.Action asChild>
										<Button
											autoFocus
											className="p-1 text-base sm:text-lg md:px-3 md:py-2"
											variant={body.confirm.variant}
											onClick={body.confirm.onClick}
										>
											{body.confirm.label}
											{body.confirm.icon}
										</Button>
									</AlertDialog.Action>
								)}
							</div>
						</AlertDialog.Content>
					</Transition.Child>
				</Transition.Root>
			</AlertDialog.Portal>
		</AlertDialog.Root>
	);
};
