import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import Button from '../../../../components/Button/Button';
import { Form, Input, useZodForm } from '../../../../components/FormElements';
import { oldIcons, VehicleIcon } from '../../../../components/Icon/vehicles';
import ImageInput from '../../../../components/ImageInputCamera/ImageInputCamera';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import { Category, Vehicle } from '../../../../services/angular/types/Order';
import {
	VehicleInviteRegisterSchema,
	VehicleInviteRegisterSchemaType,
} from '../schema/VehicleInviteRegisterSchema';
import { AxiosError } from 'axios';

interface VehicleStepProps {
	companyId: number;
	refetch: () => void;
	onBack: () => void;
	vehicles?: Vehicle[];
}

const VehicleStep = ({
	companyId,
	refetch,
	onBack,
	vehicles,
}: VehicleStepProps) => {
	const [latitude, setLatitude] = useState<number>();
	const [longitude, setLongitude] = useState<number>();
	const token = useUserState((state) => state.token);
	const [enableButton, setEnableButton] = useState<boolean>(true);
	const [vehicleStep, setVehicleStep] = useState<number>(1);

	const form = useZodForm({
		schema: VehicleInviteRegisterSchema,
		values: vehicles?.length
			? {
					id: vehicles[0].id,
					companyId: vehicles[0].companyId,
					categoryId: vehicles[0].categoryId,
					crv: vehicles[0].crv,
					plate: vehicles[0].plate,
					featureOneActive: vehicles[0].featureOneActive,
					featureTwoActive: vehicles[0].featureTwoActive,
					front: vehicles[0].front,
					left: vehicles[0].left,
					rear: vehicles[0].rear,
			  }
			: undefined,
	});

	useEffect(() => {
		const getLocation = () => {
		  if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
			  (position) => {
				setLatitude(position.coords.latitude);
				setLongitude(position.coords.longitude);
			  },
			  (error) => {
				console.log(error);
			  },
			  {
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 0,
			  }
			);
		  } else {
			console.log('Geolocation is not supported by this browser.');
		  }
		};
	
		getLocation();
	  }, []);

	const { data: geofenceId } = useQuery(
		['geofence-lat-lng', latitude, longitude],
		async () =>
			await api.get(`/geofence/current-id`, {
				params: {
					latitude,
					longitude,
				},
				headers: {
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					Authorization: `Bearer ${token}`,
				},
			}),
		{
			enabled: !!latitude && !!longitude,
			select: ({ data }) => data,
		}
	);

	const { data: vehicleCategories } = useQuery(
		['vehicle-category', token],
		async () =>
			await api.get<Category[]>(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/categories`,
				{
					params: {
						geofenceId: geofenceId?.currentGeofenceId,
					},
					headers: {
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						Authorization: `Bearer ${token}`,
					},
				}
			),
		{
			enabled: !!geofenceId?.currentGeofenceId,
		}
	);

	const { data: crvImage } = useQuery(
		['crv-image', vehicles?.[0]?.crv, form.watch('crv')],
		async () =>
			await api.get('/assets', {
				params: {
					imageName: form.watch('crv') ?? vehicles?.[0]?.crv,
				},
			}),
		{
			enabled: !!form.watch('crv') || !!vehicles?.[0]?.crv,
			select: ({ data }) => data.url,
		}
	);
	const { data: frontImage } = useQuery(
		['front-image', vehicles?.[0]?.front, form.watch('front')],
		async () =>
			await api.get('assets', {
				params: {
					imageName: form.watch('front') ?? vehicles?.[0]?.front,
				},
			}),
		{
			enabled: !!form.watch('front') || !!vehicles?.[0]?.front,
			select: ({ data }) => data.url,
		}
	);
	const { data: leftImage } = useQuery(
		['left-image', vehicles?.[0]?.left, form.watch('left')],
		async () =>
			await api.get('/assets', {
				params: {
					imageName: form.watch('left') ?? vehicles?.[0]?.left,
				},
			}),
		{
			enabled: !!form.watch('left') || !!vehicles?.[0]?.left,
			select: ({ data }) => data.url,
		}
	);
	const { data: rearImage } = useQuery(
		['rear-image', vehicles?.[0]?.rear, form.watch('rear')],
		async () =>
			await api.get('/assets', {
				params: {
					imageName: form.watch('rear') ?? vehicles?.[0]?.rear,
				},
			}),
		{
			enabled: !!form.watch('rear') || !!vehicles?.[0]?.rear,
			select: ({ data }) => data.url,
		}
	);

	const onSubmit = async (data: VehicleInviteRegisterSchemaType) => {
		if (!form.watch('front') || !form.watch('left') || !form.watch('rear')) {
			toast.error('Preencha todos os campos para continuar');
			return;
		}

		try {
			if (data?.id && data?.companyId) {
				await api.put(`companies/${data?.companyId}/vehicles/${data.id}`, data);
			} else {
				await api.post(
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					`/companies/${companyId}/vehicles`,
					data,
					{
						headers: {
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							Authorization: `Bearer ${token}`,
						},
					}
				);
				toast.success('Veículo cadastrado com sucesso');
			}
			refetch();
			onBack();
		} catch (error) {
			if (error instanceof AxiosError) {
				if (typeof error.response?.data.error === 'string') {
					toast.error(error.response?.data.error);
					return;
				}
			}
			toast.error('Erro ao cadastrar veículo');
		}
	};
	const requestLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
			  (position) => {
				setLatitude(position.coords.latitude);
				setLongitude(position.coords.longitude);
			  },
			  (error) => {
				console.log(error);
			  },
			  {
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 0,
			  }
			);
		  } else {
			console.log('Geolocation is not supported by this browser.');
		  }
	  };
	return (
		<Form form={form} onSubmit={onSubmit}>
			{vehicleStep === 1 && (
				<>
					<div className="flex max-h-[100vh] flex-col gap-2">
						{vehicleCategories?.data.length ? (
							<>
								<h1 className="self-center text-center text-xl font-bold text-neutral-900">
									Selecione o tipo de veículo considerando as dimensões de cada categoria
								</h1>

								<div className="flex flex-col gap-4 overflow-y-auto p-2">
									{vehicleCategories?.data
										.filter((category) => category.active)
										.map((category, index) => {
											const isCategorySelected =
												category.id === form.watch('categoryId');
	
											return (
												<div
													key={`${category.id}-${index}`}
													onClick={() => {
														form.setValue('categoryId', category.id);
														if (category.typeOneActive && category.typeTwoActive)
															setEnableButton(true);
														else {
															setEnableButton(false);
														}
														
													}}
													className={twMerge(
														'relative flex cursor-pointer items-center gap-4 rounded-md p-2 px-6 ring-1 ring-neutral-500 hover:bg-neutral-200',
														isCategorySelected && 'bg-neutral-100'
													)}
												>
													{isCategorySelected &&
													category.typeOneActive &&
													category.typeTwoActive ? ( // Render subcategories if the category is selected
														<div className="flex h-full w-full items-center justify-around rounded-md bg-neutral-100 p-2">
															<div
																onClick={(e) => {
																	e.stopPropagation();
																	form.setValue('featureOneActive', true);
																	form.setValue('featureTwoActive', false);
																	setEnableButton(false);
																}}
																className={twMerge(
																	'flex h-full max-h-full w-1/2 flex-col items-center rounded-md py-2 text-xs',
																	form.watch('featureOneActive') &&
																		'bg-neutral-400'
																)}
															>
																<VehicleIcon
																	size={60}
																	name={
																		// @ts-expect-error
																		oldIcons[category.typeOneIcon]
																	}
																/>
																{category.typeOneDescription}
															</div>

															<div
																onClick={(e) => {
																	e.stopPropagation();
																	form.setValue('featureOneActive', false);
																	form.setValue('featureTwoActive', true);
																	setEnableButton(false);
																}}
																className={twMerge(
																	'flex h-full max-h-full w-1/2 flex-col items-center rounded-md py-2 text-xs',
																	form.watch('featureTwoActive') &&
																		'bg-neutral-400'
																)}
															>
																<VehicleIcon
																	size={60}
																	name={
																		// @ts-expect-error
																		oldIcons[category.typeTwoIcon]
																	}
																/>
																{category.typeTwoDescription}
															</div>
														</div>
													) : (
														<>
															<VehicleIcon
																// @ts-expect-error
																name={oldIcons[category.icon]}
																size={80}
															/>
															<div className="h-full w-full text-sm">
																<h1 className="text-base font-bold text-neutral-0">
																	{category.name}
																</h1>
																<p>Dimensões aproximada: </p>
																<p>
																	{category.height} x {category.width} x{' '}
																	{category.weight}
																</p>
																<p>Capacidade máxima: {category.weight}kg</p>
															</div>
														</>
													)}
												</div>
											);
										})}
								</div>
								<div className="mt-4 flex flex-col items-center gap-4">
									<Button
										onClick={() =>{
											if (enableButton){
												toast.error('Selecione uma categoria e subcategoria para continuar');
												return;
											}
											setVehicleStep(2)
										}}
										disabled={enableButton}
										type="button"
									>
										Avançar
									</Button>
									<Button variant="secondary" onClick={onBack}>
										Voltar
									</Button>
								</div>
							</>
						) : (
							<div className="flex flex-col gap-2 font-medium text-neutral-0">
								<p>
									Precisamos de sua localização para informar os tipos de veiculos da sua região.
								</p>
								<p>
									Caso você não tenha permitido o acesso a sua localização, por
									favor, permita e tente novamente.
								</p>
								<p>
									Se você já permitiu, entre em contato com o suporte para que
									possamos te ajudar.
								</p>
								<Button variant="secondary" onClick={requestLocation}>
									Solicitar novamente a localização
								</Button>
								<Button variant="secondary" onClick={onBack}>
									Voltar
								</Button>
							</div>
						)}
					</div>
				</>
			)}
			{vehicleStep === 2 && (
				<>
					<div className="mt-4 flex flex-col gap-4">
						<span className="flex flex-col items-center justify-center">
							<h1 className="font-semibold">Foto do documento do veículo</h1>
							<ImageInput
								setImageName={(name) => form.setValue('crv', name)}
								token={token || ''}
								src={crvImage}
							/>
						</span>
					</div>
					<div className="my-4">
						<Input autoFocus type='text' label="Placa do veículo" {...form.register('plate')} />
					</div>

					<div className="mt-4 flex flex-col items-center gap-4">
						<Button type="button" 
						disabled={!form.watch('crv') || !form.watch('plate')}
						onClick={() => {
							if (!form.watch('crv') || !form.watch('plate')) {
								toast.error('Preencha todos os campos para continuar');
								return;
							}
							setVehicleStep(3)
						}}>
							Avançar
						</Button>
						<Button variant="secondary" onClick={() => setVehicleStep(1)}>
							Voltar
						</Button>
					</div>
				</>
			)}
			{vehicleStep === 3 && (
				<>
					<h1 className="self-center text-center text-xl font-bold text-neutral-900">
						Precisamos de 3 fotos do seu veículo
					</h1>
					<h2 className="text-center">
						Você pode escolher uma foto da sua galeria ou tirar uma agora, em um
						local bem iluminado.
					</h2>

					<div className="flex flex-col sm:flex-row my-4 justify-center gap-6">
						<span className="flex flex-col items-center justify-center">
							<h1 className="font-semibold">Foto da frente do veículo</h1>
							<ImageInput
								setImageName={(name) => form.setValue('front', name)}
								token={token || ''}
								src={frontImage}
							/>
						</span>

						<span className="flex flex-col items-center justify-center">
							<h1 className="font-semibold">Foto da esquerda do veículo</h1>
							<ImageInput
								setImageName={(name) => form.setValue('left', name)}
								token={token || ''}
								src={leftImage}
							/>
						</span>

						<span className="flex flex-col items-center justify-center">
							<h1 className="font-semibold">Foto da traseira do veículo (mostrando a placa)</h1>
							<ImageInput
								setImageName={(name) => form.setValue('rear', name)}
								token={token || ''}
								src={rearImage}
							/>
						</span>
					</div>
					<div className="w-full text-center font-medium text-terracota-500">
						{form.formState.errors.categoryId && (
							<p>{form.formState.errors.categoryId.message}</p>
						)}
						{form.formState.errors.plate && (
							<p>{form.formState.errors.plate.message}</p>
						)}
						{form.formState.errors.crv && (
							<p>{form.formState.errors.crv.message}</p>
						)}
						{form.formState.errors.front && (
							<p>{form.formState.errors.front.message}</p>
						)}
						{form.formState.errors.left && (
							<p>{form.formState.errors.left.message}</p>
						)}
						{form.formState.errors.rear && (
							<p>{form.formState.errors.rear.message}</p>
						)}
						{form.formState.errors.featureOneActive && (
							<p>{form.formState.errors.featureOneActive.message}</p>
						)}
						{form.formState.errors.featureTwoActive && (
							<p>{form.formState.errors.featureTwoActive.message}</p>
						)}
					</div>
					<div className="mt-4 flex flex-col items-center gap-4">
						<Button type="submit">Avançar</Button>
						<Button variant="secondary" onClick={() => setVehicleStep(2)}>
							Voltar
						</Button>
					</div>
				</>
			)}
		</Form>
	);
};

export default VehicleStep;
