import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ErrorTooltip } from '../../Tooltip/ErrorTooltip';

interface WywiwygEditorProps {
	placeholder?: string;
	onChange: (value: string) => void;
	value?: string;
	errorMessage?: string;
	disabled?: boolean;
}

export const WYSIWYGEditor: React.FC<WywiwygEditorProps> = ({
	onChange,
	value,
	placeholder,
	errorMessage,
	disabled,
}) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [updated, setUpdated] = useState(false);

	useEffect(() => {
		if (!updated) {
			const defaultValue = value || '';
			const blocksFromHtml = htmlToDraft(defaultValue);
			const contentState = ContentState.createFromBlockArray(
				blocksFromHtml.contentBlocks,
				blocksFromHtml.entityMap
			);
			const newEditorState = EditorState.createWithContent(contentState);
			setEditorState(newEditorState);
		}
	}, [value]);

	const onEditorStateChange = (editorState: EditorState): void => {
		if (disabled) return;
		setUpdated(true);
		setEditorState(editorState);

		return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
	};

	return (
		<div className="relative editor border border-neutral-100 rounded shadow py-2 px-3 min-h-[300px]">
			{errorMessage && (
				<div className="absolute transform -translate-y-1/2 right-3 top-8">
					<ErrorTooltip offset={8} message={errorMessage} />
				</div>
			)}
			<Editor
				editorState={editorState}
				onEditorStateChange={onEditorStateChange}
				placeholder={placeholder}
				toolbar={{
					inline: { inDropdown: true },

					list: { inDropdown: true },

					textAlign: { inDropdown: true },

					link: { inDropdown: true },

					history: { inDropdown: true },
				}}
			/>
		</div>
	);
};
