/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { match, P } from 'ts-pattern';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import 'dayjs/locale/pt'; // carregar sob demanda
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TbClock, TbTruckDelivery, TbMapSearch } from 'react-icons/tb';
import { useStore } from 'zustand';
import { PageHeader } from '../../../../components/PageHeader';
import { Tooltip } from '../../../../components/Tooltip';
import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import { appStateStore } from '../../../../store/appState';
import Pusher from 'pusher-js';
// @ts-expect-error
import cancel from '../../../../assets/sounds/cancel.wav';
// @ts-expect-error
import alarm from '../../../../assets/sounds/alarm.wav';
// @ts-expect-error
import bottle from '../../../../assets/sounds/bottle.wav';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';
import { EllipsisLoading } from '../../../../components/EllipsisLoading';
import { useAlert } from '../../../../contexts/AlertContext';
import { AiFillStar } from 'react-icons/ai';
import { OrderResponseApi } from '../../../../services/angular/types/Order';
import OnlineByCategory from './components/onlineByCategory';
import { CancelOrderModal } from '../../../../components/Order/CancelOrderModal';
import { ClientDropdown } from '../../../../components/Order/ClientDropdown';
import { DriverDropdown } from '../../../../components/Order/DriverDropdown';
import { SelectGeofence } from '../../../../components/Angular/SelectGeofence';
import { Checkbox, useZodForm } from '../../../../components/FormElements';
import { hasCheckin, isInCourse } from '../../../../helpers/utils';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { OperationsFilter } from './components/operationsFilter';
import { ClosestDriverModal } from './components/closestDriverModal';
import { CategoryType } from '../../../../components/Order/CategoryType';
import LocationsComponent from './components/locationsComponent';
import TotalsComponent from './components/totalsComponent';
import {
	OperationsAceptedActionsComponent,
	OperationsActionsComponent,
} from './components/operationsActionsComponent';
import CurrentLocation from './components/currentLocation';
import NextLocation from './components/nextLocation';
import DelayComponent from './components/delayComponent';

const events = [
	'ORDER_STARTED',
	'ORDER_LOCATION_REMOVED',
	'ORDER_PROTOCOL',
	'ORDER_DRIVER_REMOVED',
	'ORDER_FINISHED',
	'ORDER_REALLOCATING',
	'ORDER_CHECKOUT_FAILED_V2',
	'ORDER_UNDOCHECKOUT',
	'ORDER_UNDOCHECKIN',
	'ORDER_CHECKOUT_V2',
	'ORDER_CHECKIN_V2',
	'ORDER_CANCELED',
	'ORDER_TIMEOUT',
	'ORDER_SUBMITED',
	'ORDER_ACCEPTED',
] as const;

type Events = (typeof events)[number];

dayjs.extend(relativeTime);

const tdClassName = 'px-2 py-1.5 min-w-min';

const Operations = () => {
	const queryClient = useQueryClient();
	const [cancelModalReason, setCancelModalReason] = useState(false);
	const [orderId] = useState<number>();
	const alert = useAlert();

	const [showScheduled, setShowScheduled] = useState(false);
	const [showDelayed, setShowDelayed] = useState(false);
	const [showFood, setShowFood] = useState(false);
	const [exceptFood, setExceptFood] = useState(false);
	const [openClosestDrivers, setOpenClosestDrivers] = useState(false);
	const [modalInfo, setModalInfo] = useState<{
		order: OrderResponseApi;
	}>();

	const { config } = useStore(appStateStore);
	const token = useUserState((state) => state.token);
	const geofences = useUserState((state) => state.geofences);
	const geofenceIds = useMemo(() => geofences.map((g) => g.id), [geofences]);

	const categoriesForm = useZodForm({
		schema: z.object({
			categoryId: z.array(z.number()),
		}),
	});

	const { data: orders } = useQuery(
		['operations', geofenceIds.sort((a, b) => a - b).join(',')],
		async () =>
			(
				await api.get<OrderResponseApi[]>(
					'/dashboard/orders?include=status,category,requester,requesterCompany,requesterCompany.tags,requesterCompany.owner,driver,driver.tags,driverCompany,locations,locations.protocols,locations.receiverType,integrationOrder,integrationOrder.integrationCompany',
					{
						params: {
							geofenceId: geofenceIds?.join(','),
							sort: 'requestDate:desc',
						},
					}
				)
			).data,
		{
			refetchOnMount: true,
			staleTime: 0,
		}
	);

	const { mutate } = useMutation(
		async (id: number) =>
			(
				await api.get<OrderResponseApi>(`/orders/${id}`, {
					params: {
						include: 'driver.tags',
					},
				})
			).data
	);

	const handlePusherEvent = useCallback(
		async (event: SocketResponse) => {
			try {
				if (!event.notId) throw new Error('No order id');

				mutate(event.notId, {
					onSuccess: async (data) => {
						if (geofenceIds.includes(data.geofenceId)) {
							queryClient.setQueryData(
								['operations', geofenceIds.sort((a, b) => a - b).join(',')],
								(oldData: OrderResponseApi[] | undefined) => {
									const orderExists = oldData?.find(
										(order: OrderResponseApi) => order.id === data.id
									);

									if (!orderExists) return [data, ...(oldData || [])];

									if (
										event.action === 'ORDER_ACCEPTED' &&
										orderExists.scheduleDate &&
										dayjs(orderExists.scheduleDate).diff() > 60 * 60 * 3 // 3h
									) {
										return oldData?.filter(
											(order: OrderResponseApi) => order.id !== data.id
										);
									}

									return oldData?.map((order: OrderResponseApi) => {
										if (order.id === data.id) return data;
										return order;
									});
								}
							);

							await match(event)
								.with({ action: 'ORDER_CANCELED' }, async () => {
									await new Audio(cancel).play();
								})
								.with(
									{ action: P.union('ORDER_TIMEOUT', 'ORDER_ACCEPTED') },
									async () => {
										await new Audio(bottle).play();
									}
								)
								.with({ action: 'ORDER_SUBMITED' }, async () => {
									await new Audio(alarm).play();
								})
								.otherwise(() => { });
						}
					},
				});
			} catch (error) {
				console.log(error);
			}
		},
		[geofenceIds]
	);

	const { data: categories } = useQuery(
		['vehicle-category', geofenceIds.sort((a, b) => a - b).join(',')],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/categories`,
				{
					params: {
						geofenceId: geofenceIds?.join(','),
					},
				}
			)
	);

	const ordersWaiting = useMemo(
		() =>
			orders
				?.filter((order) => {
					const categories = categoriesForm.watch('categoryId');
					return (
						(order.statusId === 2 || order.statusId === 4) &&
						geofenceIds.length &&
						(!categories?.length || categories.includes(order.categoryId)) &&
						(!showScheduled || order.scheduleDate === null) &&
						(!showDelayed ||
							(order.locations?.[0] &&
								!hasCheckin(order.locations[0]) &&
								isInCourse(order) &&
								dayjs().diff(
									order.scheduleDate || order.acceptanceDate,
									'minute'
								) >= 10)) &&
						(!showFood || (order.integrationOrder?.displayId !== null && order.integrationOrder?.displayId !== undefined)) &&
						(!exceptFood || (order.integrationOrder?.displayId === null || order.integrationOrder?.displayId === undefined))
					);
				})
				?.reverse(),

		[orders, categoriesForm.watch('categoryId'), showScheduled, showDelayed, showFood, exceptFood]
	);

	const ordersInProcess = useMemo(
		() =>
			orders?.filter((order) => {
				const categories = categoriesForm.watch('categoryId');
				return (
					order.statusId === 5 &&
					geofenceIds.length &&
					(!categories?.length || categories.includes(order.categoryId)) &&
					(!showScheduled || order.scheduleDate === null) &&
					(!showDelayed ||
						(order.locations?.[0] &&
							!hasCheckin(order.locations[0]) &&
							isInCourse(order) &&
							dayjs().diff(
								order.scheduleDate || order.acceptanceDate,
								'minute'
							) >= 10)) &&
					(!showFood || (order.integrationOrder?.displayId !== null && order.integrationOrder?.displayId !== undefined)) &&
					(!exceptFood || (order.integrationOrder?.displayId === null || order.integrationOrder?.displayId === undefined))
				);
			}),

		[orders, categoriesForm.watch('categoryId'), showDelayed, showScheduled, showFood, exceptFood]
	);

	useEffect(() => {
		const pusher = new Pusher(config.pusherAppKey, {
			cluster: config.pusherCluster,
			authEndpoint: config.pusherAuth,
			auth: {
				headers: {
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					Authorization: `Bearer ${token}`,
				},
			},
		});

		const channel = pusher.subscribe('private-ORDERS_ADMIN');

		events.forEach((event) => {
			channel.bind(event, handlePusherEvent);
		});

		return () => {
			channel.unbind_all();
			pusher.disconnect();
		};
	}, [geofenceIds]);

	const { mutate: searchFavoriteDrivers } = useMutation(
		async (id: number) => {
			const { data } = await api.put<OrderResponseApi>(
				`/orders/${id}/search-favorites`,
				{
					searchOnlyFavoriteDrivers: false,
				}
			);
			return data;
		},
		{
			onSuccess: (data) => {
				queryClient.setQueryData(
					['operations', geofenceIds.sort((a, b) => a - b).join(',')],
					(oldData: OrderResponseApi[] | undefined) => {
						const orderExists = oldData?.find(
							(order: OrderResponseApi) => order.id === data.id
						);

						if (!orderExists) return [data, ...(oldData || [])];

						return oldData?.map((order: OrderResponseApi) => {
							if (order.id === data.id) return data;
							return order;
						});
					}
				);
			},
		}
	);

	function ShowScheduled() {
		setShowScheduled(!showScheduled);
	}

	function ShowDelayed() {
		setShowDelayed(!showDelayed);
	}
	function ShowFood() {
		setShowFood(!showFood);
	}

	function ExceptFood() {
		setExceptFood(!exceptFood);
	}
	return (
		<>
			<div className="flex flex-col items-center md:flex-row">
				<span className="flex w-full gap-4">
					<Checkbox
						label="Mostrar apenas fretes imediatos"
						checked={showScheduled}
						onCheckedChange={ShowScheduled}
						className="mb-4"
					/>
					<Checkbox
						label="Mostrar apenas fretes atrasados"
						checked={showDelayed}
						onCheckedChange={ShowDelayed}
						className="mb-4"
					/>
					<Checkbox
						label="Mostrar apenas Food"
						checked={showFood}
						onCheckedChange={ShowFood}
						className=
						"mb-4"
					/>
					<Checkbox
						label="Retirar pedidos Food"
						checked={exceptFood}
						onCheckedChange={ExceptFood}
						className=
						"mb-4"
					/>
				</span>

				<OnlineByCategory />
			</div>

			<PageHeader
				title={`Fretes aguardando entregador: (${ordersWaiting?.length || 0})`}
				description="Fretes aguardando entregador ou agendados para as próximas 3 horas"
			/>
			<WhiteContainer className="gap-4">
				<div className="w-full overflow-x-auto">
					<table className="w-full table-auto">
						<tbody>
							{ordersWaiting?.map((order: OrderResponseApi) => (
								<tr
									key={order.id}
									className={twMerge(
										'odd:bg-neutral-50',
										order?.scheduleDate && 'odd:bg-blue/10 even:bg-blue/20'
									)}
								>
									<td className={tdClassName}>
										<div className="flex w-auto flex-col">
											<div className="flex gap-2">
												{order.searchOnlyFavoriteDrivers && (
													<Tooltip
														triggerClassName="cursor-pointer"
														message="Somente motoristas favoritos"
														onClick={() => {
															alert.onCustom({
																title: 'Remover busca de favoritos',
																message: `Deseja remover a busca de favoritos do frete #${order.id}?`,
																confirm: {
																	onClick: () => {
																		searchFavoriteDrivers(order.id);
																	},
																},
															});
														}}
													>
														<AiFillStar
															className="text-yellow-submenu"
															size={22}
														/>
													</Tooltip>
												)}
												{order?.integrationOrder && (
													<Tooltip
														message={`Pedido de integração ${order.integrationOrder?.displayId
															? `- ${order.integrationOrder?.displayId ?? ''}`
															: ''
															}`}
														triggerClassName="text-neutral-700 flex flex-row gap-x-2 items-center"
													>
														<div className="w-4">
															<BsFillInfoCircleFill size={16} />
														</div>
														<p className="w-full">
															{order.integrationOrder?.displayId}
														</p>
													</Tooltip>
												)}
											</div>
											<p className="flex gap-2 text-lg font-bold text-orange">
												#{String(order.id).padStart(5, '0')}
											</p>
											{order.driver ? (
												<h1 className="text-xs text-neutral-500">Agendado</h1>
											) : (
												<EllipsisLoading
													className="-mt-2 h-3 w-16"
													dotClassName="w-2 h-2"
												/>
											)}
										</div>
									</td>
									<td className={tdClassName}>
										<div className="flex flex-col">
											<div className="flex gap-1 font-medium text-neutral-800">
												{order.scheduleDate ? (
													<TbClock size={24} />
												) : (
													<TbTruckDelivery size={24} />
												)}
												{order.scheduleDate ? 'Agendado' : 'Imediato'}
											</div>
											<p className="min-w-[7.5rem] text-sm text-neutral-500">
												{order.scheduleDate
													? dayjs(order.scheduleDate).format('DD/MM/YYYY HH:mm')
													: dayjs(order.orderDate).format('DD/MM/YYYY HH:mm')}
											</p>
										</div>
									</td>
									<td className={tdClassName}>
										<div className="flex flex-col text-sm">
											<span>
												{dayjs(order.requestDate).format('DD/MM/YYYY HH:mm')}
											</span>
											<span className="text-xs text-neutral-500">
												Solicitação
											</span>
										</div>
									</td>
									<td className={tdClassName}>
										<div className="flex flex-col text-sm">
											<span>
												{dayjs(order.completionPrevisionDate).format(
													'DD/MM/YYYY HH:mm'
												)}
											</span>
											<span className="text-xs text-neutral-500">
												Previsão de término
											</span>
										</div>
									</td>
									<td className={tdClassName}>
										<ClientDropdown
											orderId={order.id}
											companyAvatar={order.requesterCompany?.avatar}
											companyId={order.requesterCompany?.id}
											companyName={order.requesterCompany?.companyName}
											companyPhone={
												order.requesterCompany?.phone ||
												order.requesterCompany?.cellphone
											}
											companyTags={order.requesterCompany?.tags}
											companyTradingName={order.requesterCompany?.tradingName}
											isFirstRequest={order.isFirstRequest}
											userId={order.requester?.id}
											userAvatar={order.requester?.avatar}
											userCellphone={order.requester?.cellphone}
											userName={order.requester?.name}
											userPhone={order.requester?.phone}
										/>
									</td>
									<td className={tdClassName}>
										{!order.driver ? (
											<button
												className="flex w-full cursor-pointer flex-row items-center gap-1 rounded hover:outline-1"
												onClick={() => {
													setOpenClosestDrivers(true);
													setModalInfo({
														order,
													});
												}}
											>
												<TbMapSearch className="text-neutral-800" size={24} />
												<span className="text-left text-xs text-neutral-500">
													Entregadores <br /> próximos
												</span>
											</button>
										) : (
											<DriverDropdown order={order} />
										)}
									</td>
									<td className={tdClassName}>
										<CategoryType
											category={order.category}
											categoryType={order.categoryType}
											isFavoriteDriver={order.isFavoriteDriver}
											defrauded={order.defrauded}
										/>
									</td>
									<td className={tdClassName}>
										<LocationsComponent order={order} />
									</td>
									<td className={tdClassName}>
										<span className="self-center">
											<DelayComponent
												requestDate={order.requestDate}
												scheduleDate={order.scheduleDate}
											/>
										</span>
									</td>
									<td className={tdClassName}>
										<TotalsComponent
											driverSubTotal={order.driverSubTotal}
											total={order.total}
										/>
									</td>
									<td className={tdClassName}>
										<span className="self-center">
											<OperationsActionsComponent order={order} />
										</span>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</WhiteContainer>

			<PageHeader
				title={`Pedidos em andamento: (${ordersInProcess?.length || 0})`}
				description="Fretes aceitos que já estejam em andamento"
				className="mt-4"
			/>
			<WhiteContainer className="mb-16 gap-4 overflow-auto">
				<div className="w-full overflow-x-auto">
					<table className="w-full table-auto">
						<tbody>
							{ordersInProcess?.map((order: OrderResponseApi) => (
								<tr
									key={order.id}
									className={twMerge(
										'odd:bg-neutral-50',
										order?.scheduleDate && 'odd:bg-blue/10 even:bg-blue/20'
									)}
								>
									<td className={tdClassName}>
										<div className="flex flex-col">
											<div className="flex gap-1">
												{order.searchOnlyFavoriteDrivers && (
													<Tooltip
														message="Somente motoristas favoritos"
														triggerClassName="self-start"
													>
														<AiFillStar
															className="text-yellow-submenu"
															size={22}
														/>
													</Tooltip>
												)}
												{order.integrationOrder && (
													<Tooltip
														message={`Pedido de integração ${order.integrationOrder?.displayId
															? `- ${order.integrationOrder?.displayId ?? ''}`
															: ''
															}`}
														triggerClassName="text-neutral-700 flex gap-1 items-center"
													>
														<div className="w-4">
															<BsFillInfoCircleFill size={16} />
														</div>
														<p className="w-full">
															{order.integrationOrder?.displayId}
														</p>
													</Tooltip>
												)}
											</div>
											<div className="flex items-center gap-2 text-lg font-bold text-orange">
												#{String(order.id).padStart(5, '0')}
											</div>
										</div>
									</td>
									<td className={tdClassName}>
										<div className="flex flex-col">
											<div className="flex gap-1 font-medium text-neutral-800">
												{order.scheduleDate ? (
													<TbClock size={24} />
												) : (
													<TbTruckDelivery size={24} />
												)}
												{order.scheduleDate ? 'Agendado' : 'Imediato'}
											</div>
											<p className="min-w-[7.5rem] text-sm text-neutral-500">
												{order.scheduleDate
													? dayjs(order.scheduleDate).format('DD/MM/YYYY HH:mm')
													: dayjs(order.orderDate).format('DD/MM/YYYY HH:mm')}
											</p>
										</div>
									</td>
									<td className={tdClassName}>
										<ClientDropdown
											orderId={order.id}
											companyAvatar={order.requesterCompany?.avatar}
											companyName={order.requesterCompany?.companyName}
											companyId={order.requesterCompany?.id}
											companyPhone={
												order.requesterCompany?.phone ||
												order.requesterCompany?.cellphone
											}
											companyTags={order.requesterCompany?.tags}
											companyTradingName={order.requesterCompany?.tradingName}
											isFirstRequest={order.isFirstRequest}
											userId={order.requester?.id}
											userAvatar={order.requester?.avatar}
											userCellphone={order.requester?.cellphone}
											userName={order.requester?.name}
											userPhone={order.requester?.phone}
										/>
									</td>
									<td className={tdClassName}>
										<DriverDropdown order={order} />
									</td>
									<td className={tdClassName}>
										<CategoryType
											category={order.category}
											categoryType={order.categoryType}
											isFavoriteDriver={order.isFavoriteDriver}
											defrauded={order.defrauded}
										/>
									</td>
									<td className={tdClassName}>
										<LocationsComponent order={order} />
									</td>
									<td className={tdClassName}>
										<CurrentLocation order={order} />
									</td>
									<td className={tdClassName}>
										<NextLocation order={order} />
									</td>
									<td className={tdClassName}>
										<TotalsComponent
											driverSubTotal={order.driverSubTotal}
											total={order.total}
										/>
									</td>
									<td className={tdClassName}>
										<span className="flex items-center justify-center ">
											<OperationsAceptedActionsComponent order={order} />
										</span>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</WhiteContainer>

			<OperationsFilter
				categories={categories}
				categoriesForm={categoriesForm}
			/>

			<SelectGeofence />

			<ClosestDriverModal
				open={openClosestDrivers}
				setOpen={setOpenClosestDrivers}
				order={modalInfo?.order}
			/>

			<CancelOrderModal
				open={cancelModalReason}
				setOpen={setCancelModalReason}
				orderId={orderId}
			/>
		</>
	);
};

export default Operations;

interface SocketResponse {
	'content-available': number;
	'no-cache': number;
	message: string;
	title: string;
	icon: string;
	notId: number;
	action: Events;
	order: OrderResponseApi;
}
