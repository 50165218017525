export const brCurrencyRegex = /^R\$ \d{1,3}(\.\d{3})*,\d{2}$/;

export const brCurrencyMask = (value?: string): string => {
	if (!value) return 'R$ 0,00';
	const valueFormatted = removeBrCurrencyMask(value).replace(/^0+/, '');
	const length = valueFormatted.length;

	if (length === 0) return 'R$ 0,00';
	if (length === 1) return `R$ 0,0${valueFormatted}`;
	if (length === 2) return `R$ 0,${valueFormatted}`;

	return `R$ ${valueFormatted
		.slice(0, -2)
		.replace(/\B(?=(\d{3})+(?!\d))/g, '.')},${valueFormatted.slice(-2)}`;
};

export const removeBrCurrencyMask = (value: string | undefined): string => {
	if (!value) return '';
	return value.replace(/\D/g, '').replace(/^0+/, '');
};
