import { z } from 'zod';
import { dateFilterSchema } from '../../../../../helpers/dateFilterSchema';

export const scheduledFilterSchema = dateFilterSchema.extend({
	orderTypeId: z
		.object({
			value: z.string(),
			label: z.string().optional(),
		})
		.nullable()
		.optional()
		.transform((value) => value?.value),
	query: z.string().optional(),
});

export type ScheduledFilterSchemaType = z.infer<typeof scheduledFilterSchema>;
