/* eslint-disable no-undef */
import { OverlayViewF } from '@react-google-maps/api';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Avatar } from '../../../components/Avatar';
import { GoogleMap } from '../../../components/GoogleMap';
import { useGoogleLoaded } from '../../../components/GoogleMap/useGoogleLoaded';
import Header from '../../../components/Header/Header';
import { oldIcons, VehicleIcon } from '../../../components/Icon/vehicles';
import { api } from '../../../services/angular/axios';
import {
	Category,
	Driver,
	Requester,
	RequesterCompany,
	Vehicle,
	Location,
} from '../../../services/angular/types/Order';
// @ts-expect-error
import { ReactComponent as LocationFotFound } from '../../../assets/locationNotFound.svg';
import { Loading } from '../../../components/Loading';
import { useEffect, useMemo, useRef } from 'react';
import { MdFilterCenterFocus } from 'react-icons/md';
import { Tooltip } from '../../../components/Tooltip';
import { LeafletMap } from '../../../components/Leaflet';
import { Marker, useMap } from 'react-leaflet';
import { customMarkerIcon, driverIcon } from '../../../components/Leaflet/icons/customIcon';
import L from 'leaflet';
import { useNewMaps } from '../../../hooks/useMaps';

interface PulsatingIconProps {
	position: L.LatLngExpression;
	angle: number;
  }


interface FollowFreight {
	id: number;
	categoryId: number;
	category: Category;
	locations: Location[];
	driver: Driver;
	vehicle: Vehicle;
	requester: Requester;
	requesterCompany: RequesterCompany;
}

export const PublicFollowFreight = () => {
	const isLoaded = useGoogleLoaded();
	const { locationId, token } = useParams();
	const mapRef = useRef<google.maps.Map>();
	const markerRef = useRef<google.maps.Marker>();
	const { toggleMaps } = useNewMaps();
	const {
		data: order,
		error,
		isLoading,
		refetch,
	} = useQuery<FollowFreight, AxiosError<any>, FollowFreight>(
		['public-follow-freight', locationId, token],
		async () =>
			(
				await api.get<FollowFreight>('orders/tracking', {
					params: {
						locationId,
						token,
					},
				})
			).data,
		{
			refetchOnWindowFocus: true,
			onSuccess: ({ locations }) => {
				if (markerRef.current && !!locations.length) {
					markerRef.current.setIcon({
						url:
							locations[0].checkinDate && locations[0].checkoutDate
								? '/markerGreen.png'
								: locations[0].checkinDate && locations[0].reasonDate
								? '/markerRed.png'
								: locations[0].checkinDate
								? '/markerYellow.png'
								: '/markerBlue.png',
					});
				}
			},
		}
	);
	  
	const { data: companyAvatar } = useQuery(
		['company-avatar', order?.requesterCompany?.avatar],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: order?.requesterCompany.avatar,
				},
			});
		},
		{
			enabled: !!order?.requesterCompany?.avatar,
		}
	);

	const { data: driverAvatar } = useQuery(
		['driver-avatar', order?.driver?.avatar],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: order?.driver.avatar,
				},
			});
		},
		{
			enabled: !!order?.driver?.avatar,
		}
	);

	useEffect(() => {
		const interval = setInterval(() => {
			if (!error) void refetch();
		}, 20 * 1000); // 20 seconds

		return () => clearInterval(interval);
	}, [order, error]);

	const bounds = useMemo(() => {
		if (!order) return null;

		const bounds = new window.google.maps.LatLngBounds();
		bounds.extend(
			new google.maps.LatLng(order.locations[0].lat, order?.locations[0].lng)
		);
		bounds.extend(
			new google.maps.LatLng(
				order?.driver?.position.latitude,
				order?.driver?.position.longitude
			)
		);

		return bounds;
	}, [order]);

	const PulsatingIcon: React.FC<PulsatingIconProps> = ({ position, angle }) => {
		const map = useMap();
	  
		useEffect(() => {

			const pulsatingIcon = driverIcon(angle)
			const marker = L.marker(position, { icon: pulsatingIcon }).addTo(map);
	  
			return () => {
			map.removeLayer(marker);
		  };
		}, [map, position, angle]);
	  
		return null;
	  };
	return (
		<div className="relative z-0 ">
			<Header
				followComponent={
					<>
						{order ? (
							<>
								<div className="hidden gap-2 leading-3 text-white md:flex">
									<Avatar
										src={companyAvatar?.data.url}
										name={
											order?.requesterCompany?.tradingName ||
											order?.requesterCompany?.companyName
										}
									/>
									<div className="flex flex-col leading-3 text-white">
										<span className="text-sm">Entrega de:</span>
										<span className="font-medium">
											{order?.requesterCompany?.tradingName ||
												order?.requesterCompany?.companyName}
										</span>
									</div>
								</div>
								<div className="flex md:hidden" />
							</>
						) : (
							<div />
						)}
					</>
				}
			/>
			<div className="h-screen w-full bg-gradient-to-b from-yellow-200 from-10% via-yellow-500 via-30% to-neutral-900 to-90% pt-16 text-neutral-900">
				{isLoading && !order && (
					<Loading
						className="bg-transparent"
						title="Carregando informações..."
					/>
				)}

				{error ? (
					<div className="flex h-full flex-col items-center justify-end gap-4">
						<span className="break-words px-2 text-center text-lg font-medium md:text-2xl lg:text-5xl">
							{error.response?.data.error}
						</span>
						<span className="w-full max-w-2xl">
							<LocationFotFound width="auto" height="auto" />
						</span>
					</div>
				) : (
					!toggleMaps?.useLeaflet ? (
						order &&
						isLoaded && (
							<GoogleMap
								disableCenter
								onMapLoad={(map) => {
									mapRef.current = map;

									if (bounds) map.fitBounds(bounds);

									order?.locations
										?.sort((a, b) => a.sequence - b.sequence)
										.forEach(
											(location, index) =>
												(markerRef.current = new google.maps.Marker({
													clickable: false,
													map,
													position: new google.maps.LatLng(
														location?.lat,
														location?.lng
													),
													label: {
														text: `${location.sequence + 1}`,
														fontWeight: 'bold',
														color: 'black',
														className:
															'absolute top-[38px] left-[50%] transform -translate-x-1/2',
													},
													icon: {
														url:
															location.checkinDate && location.checkoutDate
																? '/markerGreen.png'
																: location.checkinDate && location.reasonDate
																? '/markerRed.png'
																: location.checkinDate
																? '/markerYellow.png'
																: '/markerBlue.png',
													},
												}))
										);
								}}
							>
								<div className="pointer-events-none absolute top-4 flex w-full transform flex-wrap items-center justify-center gap-2">
									<div className="flex gap-2 rounded-full bg-white p-2 pr-4 md:hidden">
										<span className="h-8 w-8">
											<Avatar
												src={companyAvatar?.data.url}
												name={
													order?.requesterCompany?.tradingName ||
													order?.requesterCompany?.companyName
												}
												avatarProps={{
													className: 'h-8 w-8',
												}}
											/>
										</span>
										<div className="flex flex-col leading-3 text-neutral-0">
											<span className="text-xs">Entrega de:</span>
											<span className="text-sm font-medium">
												{order?.requesterCompany?.tradingName ||
													order?.requesterCompany?.companyName}
											</span>
										</div>
									</div>
									<div className="flex gap-2 rounded-full bg-white p-2 pr-4">
										<span className="h-10 w-10 md:h-12 md:w-12">
											<Avatar
												src={driverAvatar?.data.url}
												name={order?.driver?.name}
												avatarProps={{
													className: 'h-10 w-10 md:h-12 md:w-12',
												}}
											/>
										</span>
										<div className="flex flex-col justify-center text-base font-medium text-neutral-0 md:text-lg">
											<span className="leading-3">
												{order?.driver?.name || 'Motorista'}
											</span>
											<span className="font-neutral-500 flex items-center gap-1 text-xs md:text-sm">
												<VehicleIcon
													/* @ts-expect-error */
													name={oldIcons[order?.category.icon]}
													size={28}
												/>
												{order?.category.name}
												{' - '}
												{order?.vehicle?.plate || ''}
											</span>
										</div>
									</div>
								</div>

								{/* <MarkerF position={order?.locations[0]} /> */}
								{order?.driver?.position && (
									<OverlayViewF
										mapPaneName="markerLayer"
										position={
											new google.maps.LatLng(
												order?.driver?.position?.latitude,
												order?.driver?.position?.longitude
											)
										}
									>
										<div className="absolute bottom-0 left-0 right-0 flex flex-col items-center">
											<div className="flex h-8 w-8 items-center justify-center rounded-full shadow-lg">
												<div className="absolute h-14 w-14 animate-slow-ping rounded-full bg-blue opacity-50 duration-1000" />
												<img
													src="/bee.png"
													alt="bee"
													className={twMerge(
														'z-10 h-8 w-8',
														order.driver.position?.bearing &&
															`rotate-[${order.driver.position.bearing.toFixed(
																0
															)}deg]`
													)}
												/>
											</div>
										</div>
									</OverlayViewF>
								)}

								{mapRef.current && (
									<div className="absolute bottom-5 right-4">
										<button
											className="rounded-lg bg-white p-1.5 text-neutral-900 shadow transition-transform hover:translate-y-[-2px] hover:scale-105"
											onClick={() => {
												if (bounds) mapRef.current?.fitBounds(bounds);
											}}
										>
											<Tooltip
												message="Centralizar"
												triggerClassName="cursor-pointer"
											>
												<MdFilterCenterFocus size={28} />
											</Tooltip>
										</button>
									</div>
								)}
							</GoogleMap>
						)

				) : (
					<>
						<LeafletMap 
							layers={toggleMaps.layers}
							center={order?.locations} 
						>       
					
						{order?.locations
							?.sort((a, b) => a.sequence - b.sequence)
							.map((item) => (     
							<Marker
								key={item.id}
								position={[item.lat,item.lng]}
								icon={
										item.checkinDate && item.checkoutDate
										? customMarkerIcon("#00FF00", `${item.sequence + 1}`)
										: item.checkinDate && item.reasonDate
										? customMarkerIcon("#FF0000", `${item.sequence + 1}`)
										: item.checkinDate
										? customMarkerIcon("#FFFF00", `${item.sequence + 1}`)
										: customMarkerIcon("#00FFFF", `${item.sequence + 1}`)
									}
								draggable={false}
							/>
						))}
						{order?.driver?.position && (
							<PulsatingIcon position={[order?.driver?.position?.latitude,order?.driver?.position?.longitude]} angle={Number(order.driver.position.bearing.toFixed(0))} />
						)}

						</LeafletMap>

						<div className="pointer-events-none absolute top-20 flex w-full transform flex-wrap items-center justify-center gap-2">
							<div className="flex gap-2 rounded-full bg-white p-2 pr-4 md:hidden">
								<span className="h-8 w-8">
									<Avatar
										src={companyAvatar?.data.url}
										name={
											order?.requesterCompany?.tradingName ||
											order?.requesterCompany?.companyName
										}
										avatarProps={{
											className: 'h-8 w-8',
										}}
									/>
								</span>
								<div className="flex flex-col leading-3 text-neutral-0">
									<span className="text-xs">Entrega de:</span>
									<span className="text-sm font-medium">
										{order?.requesterCompany?.tradingName ||
											order?.requesterCompany?.companyName}
									</span>
								</div>
							</div>
							<div className="flex gap-2 rounded-full bg-white p-2 pr-4">
								<span className="h-10 w-10 md:h-12 md:w-12">
									<Avatar
										src={driverAvatar?.data.url}
										name={order?.driver?.name}
										avatarProps={{
											className: 'h-10 w-10 md:h-12 md:w-12',
										}}
									/>
								</span>
								<div className="flex flex-col justify-center text-base font-medium text-neutral-0 md:text-lg">
									<span className="leading-3">
										{order?.driver?.name || 'Motorista'}
									</span>
									<span className="font-neutral-500 flex items-center gap-1 text-xs md:text-sm">
										<VehicleIcon
											/* @ts-expect-error */
											name={oldIcons[order?.category.icon]}
											size={28}
										/>
										{order?.category.name}
										{' - '}
										{order?.vehicle?.plate || ''}
									</span>
								</div>
							</div>
						</div>
			 		</>
				)
				)}
				
			</div>
		</div>
	);
};

