import React from 'react';

interface FormHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
	title: string;
	description?: string;
}

export const FormHeader: React.FC<FormHeaderProps> = ({
	title,
	description,
	...props
}) => {
	return (
		<div className="text-left" {...props}>
			<h1 className="text-xl font-bold text-neutral-900">{title}</h1>
			{description && <p className="text-neutral-500">{description}</p>}
		</div>
	);
};
