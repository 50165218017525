import DatePicker, {
	CalendarProps,
	DateObject,
	DatePickerProps,
} from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import './blue.css';
import { Controller, Control, Path } from 'react-hook-form';
import { Variants } from '../variants';
import ptBr from './ptBr';
import { Input } from '../Input';
import { Label } from '../Label';
import { twMerge } from 'tailwind-merge';
import { useEffect, useRef, useState } from 'react';
import { TbCalendarEvent } from 'react-icons/tb';

interface DateProps<T extends object> extends CalendarProps, DatePickerProps {
	label?: string;
	placeholder?: string;
	variant?: Variants;
	className?: string;
	control: Control<T>;
	name: Path<T>;
	errorMessage?: string;
	range?: boolean;
	time?: boolean;
	disabled?: boolean;
	minDate?: string | number | Date | DateObject;
	onDateChange?: (date: DateObject | DateObject[] | null) => void;
}

export function Date<T extends object>({
	label,
	placeholder = 'Selecione uma data',
	variant = 'primary',
	className,
	control,
	name,
	errorMessage,
	time,
	disabled,
	minDate,
	onDateChange,
	...props
}: DateProps<T>): JSX.Element {
	const [mustClose, setMustClose] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		function handleClickOutside(e: any) {
			if (
				ref.current &&
				// @ts-expect-error
				!ref.current.contains(e.target) &&
				// @ts-expect-error
				ref.current.isOpen
			) {
				setMustClose(true);

				setTimeout(() => {
					// @ts-expect-error
					ref.current?.closeCalendar();
				}, 50);
			} else if (mustClose) {
				setMustClose(false);
			}
		}

		window.addEventListener('click', handleClickOutside);

		return () => window.removeEventListener('click', handleClickOutside);
	}, [mustClose]);

	return (
		<div className={twMerge('z-auto w-full', className)}>
			{label != null && <Label label={label} variant={variant} />}
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value } }) => (
					<DatePicker
						onClose={() => mustClose}
						ref={ref}
						multiple={false}
						locale={ptBr}
						value={value}
						disabled={disabled}
						onChange={(date) => {
							onChange(date);
							onDateChange?.(date);
						}}
						minDate={minDate}
						format={time ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'}
						containerClassName="w-full"
						className="blue"
						plugins={
							props.range && time
								? [
										<TimePicker
											key="time-picker"
											position="bottom"
											hideSeconds
										/>,
										<DatePanel key="date-panel" markFocused />,
								  ]
								: time
								? [
										<TimePicker
											key="time-picker"
											position="bottom"
											hideSeconds
										/>,
								  ]
								: props.range
								? [<DatePanel key="date-panel" markFocused />]
								: []
						}
						render={
							<CustomInput
								variant={variant}
								disabled={disabled}
								errorMessage={errorMessage}
								placeholder={placeholder}
							/>
						}
						{...props}
					/>
				)}
			/>
		</div>
	);
}

function CustomInput({
	errorMessage,
	placeholder,
	variant,
	...props
}: any): JSX.Element {
	return (
		<Input
			variant={variant}
			value={props.value}
			onChange={(e) => {
				e.target.value = dateMask(e.target.value);

				props.handleValueChange(e);
			}}
			onFocus={props.openCalendar}
			disabled={props.disabled}
			placeholder={placeholder}
			errorMessage={errorMessage}
			inputIcon={
				<TbCalendarEvent
					className="pointer-events-none absolute right-2 select-none text-neutral-700"
					size={18}
				/>
			}
		/>
	);
}

const dateMask = (value: string): string => {
	return value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '$1/$2')
		.replace(/(\d{2})(\d)/, '$1/$2')
		.replace(/(\d{4})(\d)/, '$1');
};
