import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const UserIcon: React.FC<SvgProps> = ({ color = '#FCF366', size }) => {
	return (
		<svg
			width="100%"
			height={size ?? '100%'}
			viewBox="0 0 101 101"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M79.5003 88.334V80.0007C79.5003 75.5804 77.7444 71.3411 74.6188 68.2155C71.4932 65.0899 67.2539 63.334 62.8337 63.334H37.8337C33.4134 63.334 29.1742 65.0899 26.0485 68.2155C22.9229 71.3411 21.167 75.5804 21.167 80.0007V88.334"
				stroke="currentColor"
				strokeWidth="3.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M50.3337 46.6673C59.5384 46.6673 67.0003 39.2054 67.0003 30.0006C67.0003 20.7959 59.5384 13.334 50.3337 13.334C41.1289 13.334 33.667 20.7959 33.667 30.0006C33.667 39.2054 41.1289 46.6673 50.3337 46.6673Z"
				stroke="currentColor"
				strokeWidth="3.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default UserIcon;
