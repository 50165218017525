import { z } from 'zod';
import { ErrorsMessageHelper } from '../../../../helpers/errors/ErrorsMessageHelper';
import validateCpf from '../../../../helpers/validation/cpfValidation';

export const InviteCampaignSchema = z
	.object({
		cellphone: z.string(),
		avatar: z.string().optional().nullable(),
		code: z.string(),
		cpf: z
			.string()
			.min(1, { message: ErrorsMessageHelper.required })
			.refine((value) => validateCpf(value), {
				message: 'CPF inválido',
			}),
		email: z
			.string()
			.min(1, { message: ErrorsMessageHelper.required })
			.email({ message: 'Email inválido' }),
		name: z
			.string()
			.min(1, { message: ErrorsMessageHelper.required })
			.min(3, { message: ErrorsMessageHelper.min(3) }),

		privacyTerms: z.boolean().refine((value) => value, {
			message: 'Você deve aceitar os termos de privacidade',
		}),
		useTerms: z.boolean().refine((value) => value, {
			message: 'Você deve aceitar os termos de uso',
		}),
		password: z
			.string()
			.min(1, { message: ErrorsMessageHelper.required })
			.min(6, { message: ErrorsMessageHelper.min(6) })
			.max(20, { message: ErrorsMessageHelper.max(20) }),

		confirm: z
			.string()
			.min(1, { message: ErrorsMessageHelper.required })
			.min(6, { message: ErrorsMessageHelper.min(6) })
			.max(20, { message: ErrorsMessageHelper.max(20) }),
	})
	.superRefine(({ confirm, password }, ctx) => {
		if (confirm !== password) {
			ctx.addIssue({
				code: 'custom',
				message: 'As senhas não conferem',
			});
		}
	});
