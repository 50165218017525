import React, {
	useMemo,
	useEffect,
	useRef,
	useCallback,
	useState,
} from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTable, Column, usePagination, CellProps, Row } from 'react-table';
import colors from '../../style/colors';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { twMerge } from 'tailwind-merge';
import {
	TbArrowsSort,
	TbSortAscending,
	TbSortDescending,
} from 'react-icons/tb';
import { IconsNames } from '../Icon/IconInterfaces';
import { Icon } from '../Icon/Icon';
import { Pagination } from './Pagination';
import { Params, SortOptions } from '../../services/angular/types/Params';
import { Link } from 'react-router-dom';

export interface Actions<T extends object> {
	label: string;
	icon: IconsNames | React.ReactNode;
	onAction?: (item: T, index: number) => void;
	href?: string;
}

const defaultOptions = [
	{ value: '10', label: '10' },
	{ value: '25', label: '25' },
	{ value: '50', label: '50' },
	{ value: '100', label: '100' },
];

interface TableProps<T extends object> {
	className?: string;
	data: T[];
	columns: Array<Column<T>>;
	sort?: boolean;
	disabledSort?: {
		[K in keyof T]?: boolean;
	};
	actions?: Array<Actions<T>>;
	selectOptions?: Array<{ value: string; label: string }>;
	isLoading?: boolean;
	total?: number;
	rowClassName?: (item: T, index: number) => string | undefined;
	onRowClick?: (item: T, index: number) => void;
	onParamsChange?: (params: Params) => void;
	triggerPageChange?: boolean;
	params?: Params;
	pagination?: boolean;
}

export function Table<T extends object>({
	className,
	data = [],
	columns,
	actions,
	selectOptions = defaultOptions,
	sort = true,
	isLoading,
	total,
	rowClassName,
	onRowClick,
	onParamsChange,
	disabledSort,
	triggerPageChange,
	params,
	pagination = true,
}: TableProps<T>) {
	const [page, setPage] = useState(params?.page || 1);
	const [take, setTake] = useState(
		params?.take || Number(selectOptions[0].value)
	);
	const [sortBy, setSortBy] = useState<SortOptions | undefined>(params?.sortBy);

	const columnsWithAction = useMemo(() => {
		const newCols = [...columns];
		if (actions)
			newCols.push({
				Header: 'Ações',
				id: 'actions',
				width: '0%',
				Cell: ({ row }: CellProps<T>) => (
					<TableActions actions={actions} row={row} />
				),
			});

		return newCols;
	}, [columns]);

	const { headerGroups, rows, prepareRow } = useTable(
		{ columns: columnsWithAction, data },
		usePagination
	);

	const tableIsEmpty = useCallback(() => rows.length === 0, [rows.length]);

	useEffect(() => {
		onParamsChange?.({
			page,
			take,
			sortBy,
		});
	}, [page, take, sortBy]);

	const firstRender = useRef(true);

	useEffect(() => {
		if (firstRender.current) {
			firstRender.current = false;
			return;
		}
		setPage(1);
	}, [triggerPageChange]);

	return (
		<div className="w-full transition-opacity duration-700 ease-in">
			<div className="block w-full overflow-x-auto">
				<table className={twMerge('w-full table-auto text-left', className)}>
					<thead>
						{headerGroups.map((headerGroup, index) => (
							<tr {...headerGroup.getHeaderGroupProps()} key={index}>
								{headerGroup.headers.map((column, index) => (
									<th
										className={`px-2 align-bottom ${
											// !tableIsEmpty() &&
											sort &&
											column.id !== 'actions' &&
											// @ts-expect-error
											!disabledSort?.[column.id]
												? 'cursor-pointer hover:text-blue'
												: 'cursor-default'
										}`}
										onClick={() => {
											if (
												// !tableIsEmpty() &&
												sort &&
												column.id !== 'actions' &&
												// @ts-expect-error
												!disabledSort?.[column.id]
											)
												setSortBy((prev) =>
													prev && prev.id === column.id
														? prev.order === 'desc'
															? undefined
															: {
																	id: column.id,
																	order: 'desc',
															  }
														: {
																id: column.id,
																order: 'asc',
														  }
												);
										}}
										{...column.getHeaderProps({
											style: { width: column?.width },
										})}
										key={index}
									>
										<div className="flex gap-2 text-sm">
											<span className="self-end text-neutral-900">
												{column.render('Header')}
											</span>
											{sort &&
												// @ts-expect-error
												!disabledSort?.[column.id] && (
													<div className="self-end pb-1 pl-1">
														{sortBy?.id === column.id ? (
															sortBy.order === 'asc' ? (
																<TbSortAscending
																	className="text-blue"
																	size={20}
																/>
															) : (
																<TbSortDescending
																	className="text-blue"
																	size={20}
																/>
															)
														) : (
															<>
																{column.id !== 'actions' && (
																	<TbArrowsSort size={20} />
																)}
															</>
														)}
													</div>
												)}
										</div>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody
						className={`${
							tableIsEmpty() || isLoading ? 'hidden' : ''
						} transition-all`}
					>
						{rows.map((row, index) => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps()}
									className={twMerge(
										'border-y border-neutral-100 transition-all odd:bg-neutral-50 even:bg-white hover:bg-yellow-50',
										onRowClick ? 'cursor-pointer' : 'cursor-default',
										rowClassName?.(row.original, index)
									)}
									onClick={
										onRowClick
											? () => onRowClick(row.original, index)
											: () => {}
									}
									key={index}
								>
									{row.cells.map((cell, index) => {
										return (
											<td
												className="max-w-sm px-2 py-1.5 md:max-w-xl"
												{...cell.getCellProps({
													style: { width: cell.column?.width },
												})}
												key={index}
											>
												{cell.render('Cell')}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			{isLoading && (
				<div className={`w-full transition-all`}>
					<SkeletonTheme
						baseColor={colors.neutral[100]}
						highlightColor={colors.neutral[50]}
					>
						<Skeleton
							count={take <= 50 ? take : 50}
							height={30}
							className="mb-1"
						/>
					</SkeletonTheme>
				</div>
			)}

			{tableIsEmpty() && !isLoading && (
				<div className="my-4 flex items-center justify-center">
					<div className="flex-grow border-t border-neutral-500"></div>
					<span className="mx-4 flex-shrink text-neutral-500">
						Nenhum registro encontrado
					</span>
					<div className="flex-grow border-t border-neutral-500"></div>
				</div>
			)}

			{pagination && (
				<Pagination
					page={page}
					setPage={setPage}
					take={take}
					setTake={setTake}
					total={total || 0}
					selectOptions={selectOptions}
				/>
			)}
		</div>
	);
}

interface TableActionsProps<T extends object> {
	actions: Array<Actions<T>>;
	row: Row<T>;
}

export const TableActions = <T extends object>({
	actions,
	row,
}: TableActionsProps<T>) => {
	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger asChild>
				<button
					id={`button-dropdown-${row.index}`}
					className="text-gray-700 my-0.5 flex cursor-pointer select-none items-center rounded border-none bg-neutral-100 px-2 py-0.5 text-sm transition-all focus:outline-none data-[state=open]:bg-neutral-800 data-[state=open]:text-white hover:bg-neutral-200"
					type="button"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 20 20"
						fill="currentColor"
						className="mr-1 h-4 w-4"
					>
						<path d="M10 3.75a2 2 0 10-4 0 2 2 0 004 0zM17.25 4.5a.75.75 0 000-1.5h-5.5a.75.75 0 000 1.5h5.5zM5 3.75a.75.75 0 01-.75.75h-1.5a.75.75 0 010-1.5h1.5a.75.75 0 01.75.75zM4.25 17a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5h1.5zM17.25 17a.75.75 0 000-1.5h-5.5a.75.75 0 000 1.5h5.5zM9 10a.75.75 0 01-.75.75h-5.5a.75.75 0 010-1.5h5.5A.75.75 0 019 10zM17.25 10.75a.75.75 0 000-1.5h-1.5a.75.75 0 000 1.5h1.5zM14 10a2 2 0 10-4 0 2 2 0 004 0zM10 16.25a2 2 0 10-4 0 2 2 0 004 0z" />
					</svg>
					Ações
				</button>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content
					className="flex min-w-[128px] flex-col gap-0.5 rounded border border-neutral-200 bg-white p-1"
					sideOffset={2}
				>
					{actions.map((action, index) => (
						<span key={`${action.label}-${index}`}>
							<DropdownMenu.Item asChild>
								{action.href ? (
									<Link
										to={action.href}
										onClick={(e) => {
											e.stopPropagation();
											action?.onAction?.(row.original, row.index);
										}}
										className="text-gray-700 flex cursor-pointer select-none items-center rounded border-none px-2 py-1 text-sm focus:bg-neutral-100 focus:outline-none hover:bg-neutral-100"
									>
										{typeof action.icon === 'string' ? (
											<Icon
												color={colors.neutral[900]}
												name={action.icon as IconsNames}
												className="mr-2"
												size={18}
											/>
										) : (
											<span className="mr-2 text-neutral-0">{action.icon}</span>
										)}

										{action.label}
									</Link>
								) : (
									<div
										onClick={(e) => {
											e.stopPropagation();
											action?.onAction?.(row.original, row.index);
										}}
										className="text-gray-700 flex cursor-pointer select-none items-center rounded border-none px-2 py-1 text-sm focus:bg-neutral-100 focus:outline-none hover:bg-neutral-100"
									>
										{typeof action.icon === 'string' ? (
											<Icon
												color={colors.neutral[900]}
												name={action.icon as IconsNames}
												className="mr-2"
												size={18}
											/>
										) : (
											<span className="mr-2 text-neutral-0">{action.icon}</span>
										)}

										{action.label}
									</div>
								)}
							</DropdownMenu.Item>
							{index < actions.length - 1 && (
								<hr className="mt-px text-neutral-500" />
							)}
						</span>
					))}
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};
