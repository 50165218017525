export const fieldOptions = [
	// CHECKBOXES
	{
		label: 'Permite fretes imediatos',
		value: 'allowImmediate',
		mask: 'boolean',
	},
	{
		label: 'Permite fretes agendados',
		value: 'allowScheduled',
		mask: 'boolean',
	},
	{
		label: 'Isentar comissão no frete mínimo',
		value: 'noComissionOnMinValue',
		mask: 'boolean',
	},
	{ label: 'Match Bikers', value: 'matchBikers', mask: 'boolean' },
	{ label: 'Comissão BeeBee', value: 'comission', mask: 'percentage' },
	{
		label: 'Custo por minuto parado',
		value: 'costPerStoppedTime',
		mask: 'money',
	},
	{ label: 'Dinâmico (motorista)', value: 'driverBonus', mask: 'money' },
	{
		label: 'Porcentagem de desconto no KM longa distância',
		value: 'longDistancePercentage',
		mask: 'percentage',
	},
	{
		label: 'Tolerância para longas distâncias (em km)',
		value: 'longDistanceTolerance',
	},
	{
		label: 'Multiplicador para clientes',
		value: 'requesterMultiplier',
		mask: 'percentage',
	},
	{
		label: 'Porcentagem do valor do adicional de retorno',
		value: 'returnPercentage',
		mask: 'percentage',
	},
	{
		label: 'Tolerância do adicional de retorno (em km) ',
		value: 'returnTolerance',
	},
	{ label: 'Antecedência mínima (em minutos)', value: 'scheduleAdvance' },
	{
		label: 'Comissão Beebee em fretes agendados',
		value: 'scheduledComission',
		mask: 'percentage',
	},
	{
		label: 'Desconto em fretes agendados',
		value: 'scheduledDiscount',
		mask: 'percentage',
	},
	{
		label: 'Tolerância de tempo parado (em minutos)',
		value: 'stoppedTimeTolerance',
	},
];
