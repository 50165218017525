import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CloseModal, Modal } from '../../../../../components/Modal';
import { api } from '../../../../../services/angular/axios';
import { PopInfo } from '../../../../../services/angular/types/Order';
import Button from '../../../../../components/Button/Button';

interface ModalProps {
	info?: Partial<PopInfo>;
	open?: boolean;
	setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PopInfoModal = ({ info, open, setOpen }: ModalProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const { data, isFetching, isFetched } = useQuery(
		['pop-info-image', info?.imageUrl],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: info?.imageUrl,
				},
			});
		},
		{
			enabled: !!info?.imageUrl,
		}
	);

	useEffect(() => {
		if (isFetched) setIsOpen(true);
	}, [isFetching]);

	if (!info) return null;

	return (
		<Modal
			title={info?.title || ''}
			open={open ?? isOpen}
			setOpen={setOpen ?? setIsOpen}
		>
			<div className="flex flex-col items-center justify-center gap-4 font-semibold">
				{data?.data && (
					<img className="max-h-[50vh]" src={data?.data.url} alt={info.title} />
				)}
				<div
					className="flex w-full flex-col items-center justify-center gap-1 text-center text-neutral-0"
					dangerouslySetInnerHTML={{ __html: info?.html || '' }}
				/>
				<CloseModal>
					<Button className="w-auto px-4 text-lg">
						{info?.confirmButtonText}
					</Button>
				</CloseModal>
			</div>
		</Modal>
	);
};
