import { z } from 'zod';
import { phoneRegex } from '../../../../../../helpers/mask/phoneMask';

export const LocationSchema = z.object({
	address: z.string(),
	name: z.string().min(1, 'Obrigatório').nullable(),
	number: z.string().default('0'),
	contact: z.string().regex(phoneRegex),
	responsible: z.string().optional().nullable(),
	observations: z.string().optional().nullable(),
	favoritePoint: z.boolean().default(false),
});

export type LocationSchemaType = z.infer<typeof LocationSchema>;
