import { useEffect, useMemo } from 'react';
import {
	Checkbox,
	Form,
	Select,
	useZodForm,
} from '../../../../../components/FormElements';
import { twMerge } from 'tailwind-merge';
import useUserState from '../../../../../services/angular/angularUserState';
import Button from '../../../../../components/Button/Button';
import { useAppState } from '../../../../../store/appState';
import { RegionSchema } from '../schemas/RegionSchema';
import { Modal } from '../../../../../components/Modal';
// @ts-expect-error
import { ReactComponent as Hex } from '../../../../../assets/yellowHex.svg';
import { useAtom } from 'jotai';
import { selectGeofenceAtom } from '..';
import { Tooltip } from '../../../../../components/Tooltip';

export const SelectRegion = () => {
	const { geofence, geofenceId, setGeofenceId } = useAppState((state) => ({
		geofence: state.geofence,
		geofenceId: state.geofenceId,
		setGeofenceId: state.setGeofenceId,
	}));

	const [open, setOpen] = useAtom(selectGeofenceAtom);

	const setPersistGeofenceId = useUserState(
		(state) => state.setPersistGeofenceId
	);

	const selectedGeofence = useMemo(
		() => geofence?.find((g) => g.id === geofenceId),
		[geofence, geofenceId]
	);

	const form = useZodForm({
		schema: RegionSchema,
		defaultValues: {
			remember: true,
		},
	});

	useEffect(() => {
		if (selectedGeofence) {
			form.setValue('geofenceId', {
				label: selectedGeofence.name,
				value: selectedGeofence.id,
			});
		} else if (geofence?.length) {
			setOpen(true);
		}
	}, [selectedGeofence, geofence]);

	const filteredGeofences = geofence
		?.filter((g) => g.name !== 'MEX')
		.sort((a, b) => a.name.localeCompare(b.name));

	return (
		<>
			<Tooltip
				// className="absolute -bottom-48 -right-[45vw]"
				triggerClassName="absolute bottom-20 right-5"
				message={selectedGeofence?.description}
				onClick={() => setOpen(true)}
			>
				<div
					className={twMerge(
						'h-12 w-12 scale-75 md:h-16 md:w-20 md:scale-100',
						'flex flex-col items-center justify-center text-neutral-0',
						'cursor-pointer transition-all duration-300 ease-in-out md:hover:scale-105'
					)}
				>
					<span className="absolute -z-10">
						<Hex />
					</span>
					<span className="text-xs font-semibold">Geofence</span>
					<span className="mb-1 overflow-hidden text-2xl font-black">
						{selectedGeofence?.name.toUpperCase().slice(0, 3)}
					</span>
				</div>
			</Tooltip>

			<Modal
				title="Região de origem"
				open={open}
				setOpen={setOpen}
				className="top-1/3 overflow-y-visible md:top-1/2 md:w-1/3"
			>
				<Form
					form={form}
					onSubmit={(data) => {
						if (data.remember) {
							setPersistGeofenceId(data.geofenceId);
						}
						setGeofenceId(data.geofenceId);
						setOpen(false);
					}}
					className="flex flex-col gap-4"
				>
					<p className="text-lg font-bold leading-5 text-neutral-0">
						Selecione a região de onde o pedido sairá
					</p>
					<Select
						controller={{
							control: form.control,
							name: 'geofenceId',
						}}
						label="Região"
						name="geofence"
						maxMenuHeight={164}
						options={filteredGeofences?.map((g) => ({
							label: (
								<div className="flex justify-between">
									<p>{g.name}</p>
									{g.description && <p>{g.description}</p>}
								</div>
							),
							value: g.id,
						}))}
					/>

					<Checkbox
						controller={{
							control: form.control,
							name: 'remember',
						}}
						name="remember"
						label="Lembrar escolha"
					/>
					<Button type="submit" className="w-full">
						Confirmar
					</Button>
				</Form>
			</Modal>
		</>
	);
};
