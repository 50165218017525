import { PageHeader } from '../../../../../components/PageHeader';
import React, { useMemo, useRef, useState } from 'react';
import { api } from '../../../../../services/angular/axios';
import { useQuery } from '@tanstack/react-query';
import useUserState from '../../../../../services/angular/angularUserState';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import { Table, TableActions } from '../../../../../components/Table/Table';
import dayjs from 'dayjs';
import { TbClock, TbListDetails, TbTruckDelivery } from 'react-icons/tb';
import { Tooltip } from '../../../../../components/Tooltip';
import { FiltersTab } from '../../../../../components/FiltersTab';
import { appState } from '../../../../../store/appState';
import {
	Date,
	Select,
	useZodForm,
} from '../../../../../components/FormElements';
import { ImDownload3 } from 'react-icons/im';
import { downloadFile } from '../../../../../helpers/downloadFile';
import Button from '../../../../../components/Button/Button';
import { toast } from 'react-toastify';
import { OrderResponseApi } from '../../../../../services/angular/types/Order';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { ClientDropdown } from '../../../../../components/Order/ClientDropdown';
import { AiFillStar } from 'react-icons/ai';
import { CategoryType } from '../../../../../components/Order/CategoryType';
import {
	earningsFilterSchema,
	useEarningsFilterState,
} from '../../../../../store/filters/earningFilter';

const Earnings: React.FC = () => {
	const statusOptions = useMemo(
		() => [
			{
				label: appState.orderStatus.Finalizado.name,
				value: appState.orderStatus.Finalizado.id,
			},
			{
				label: appState.orderStatus.Cancelado.name,
				value: appState.orderStatus.Cancelado.id,
			},
		],
		[]
	) as Array<{ label: string; value: number }>;
	const paymentTypeOptions = useMemo(
		() => [
			{
				label: appState.paymentType.Boleto.name,
				value: appState.paymentType.Boleto.id,
			},
			{
				label: appState.paymentType['Cartão de Crédito'].name,
				value: appState.paymentType['Cartão de Crédito'].id,
			},
			{
				label: appState.paymentType['Cartão de Débito'].name,
				value: appState.paymentType['Cartão de Débito'].id,
			},
			{
				label: appState.paymentType['Saldo em Carteira'].name,
				value: appState.paymentType['Saldo em Carteira'].id,
			},
			{
				label: appState.paymentType['Ao motorista'].name,
				value: appState.paymentType['Ao motorista'].id,
			},
		],
		[]
	) as Array<{ label: string; value: number }>;

	const {
		params,
		setParams,
		setEarningsFilter,
		clearEarningsFilter,
		...query
	} = useEarningsFilterState();
	const user = useUserState((state) => state.user);
	const [inputValue, setInputValue] = useState('');

	const form = useZodForm({
		schema: earningsFilterSchema,
		defaultValues: {
			...query,
			requesterName: query?.requesterName
				? {
						value: query.requesterName,
						label: query.requesterName,
				  }
				: null,
			statusId: statusOptions.filter((status) =>
				query?.statusId?.split(',').includes(String(status.value))
			),
			paymentTypeId: paymentTypeOptions.find(
				(paymentType) => query?.paymentTypeId === paymentType.value
			),
		},
	});

	const exportTableData = async () => {
		try {
			const response = await api.get(
				'/orders/finished/export?includeStatus=incident,requester,requesterCompany,status,category,paymentType,integrationOrder,integrationOrder.integrationCompany',
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params?.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
						...query,
					},
					responseType: 'blob',
				}
			);

			await downloadFile({
				blobFile: response?.data,
				fileName: `financeiro-receber-${
					query?.requesterName
						? `${query.requesterName.replace(' ', '_')}-`
						: ''
				}${dayjs().format('DD/MM/YYYY_HH:mm')}.xlsx`,
			});
		} catch (err) {
			toast.error('Erro ao gerar arquivo');
		}
	};

	const { data: incomeData } = useQuery(
		['financeiro-receber', query, params, user?.id],
		async () =>
			await api.get(
				`/orders/finishedTotals?include=incident,requester,requesterCompany,status,category,paymentType,integrationOrder,integrationOrder.integrationCompany`,
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
						...query,
					},
				}
			),
		{
			enabled: !!user?.id,
		}
	);

	const { data: users, refetch: usersRefetch } = useQuery(
		['usersOptions', inputValue],
		// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
		async () =>
			await api.get(`/users/names?`, {
				params: {
					name: inputValue,
				},
			}),
		{
			enabled: !!inputValue,
		}
	);

	const {
		data: tableData,
		isLoading: tableLoading,
		isFetching: tableFetching,
	} = useQuery(
		['pedidos-finalizados', query, params, user?.id],
		async () =>
			await api.get(
				'/orders/finished?include=incident,requester,requesterCompany,status,category,paymentType,integrationOrder,integrationOrder.integrationCompany',
				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
						...query,
					},
				}
			),
		{
			enabled: !!user?.id,
		}
	);

	const timerRef = useRef<any>(null);

	const handleSearch = (value: string) => {
		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			setInputValue(value);
			void usersRefetch();
		}, 1000);
	};

	return (
		<div className="w-full flex-col text-neutral-900">
			<PageHeader
				title="Financeiro"
				description="A receber"
				className="flex items-center gap-5"
			>
				<div className="flex flex-col p-2 shadow-md">
					<h3 className="text-center text-xs text-primary-600">
						TOTAL COMISSÃO BEEBEE
					</h3>
					<p className="text-center text-2xl font-bold text-primary-600">
						{incomeData?.data.comission.toLocaleString('pt-br', {
							style: 'currency',
							currency: 'BRL',
						})}
					</p>
					<p className="text-center text-xs text-neutral-600">
						Total de acordo com o filtro
					</p>
				</div>
			</PageHeader>
			<FiltersTab
				actionsChildren={
					<Button
						type="button"
						className="w-auto self-end py-1"
						onClick={() => {
							void exportTableData();
						}}
					>
						<ImDownload3 size={17} />
						Exportar
					</Button>
				}
				form={form}
				clearFilters={() => {
					form.reset({
						completionDate: null,
						initialDate: null,
						endDate: null,
						orderDate: null,
						paymentTypeId: null,
						requesterName: null,
						statusId: null,
					});
					clearEarningsFilter();
				}}
				onSubmit={(data) => {
					setEarningsFilter(data);
					setParams({
						...params,
						page: 1,
					});
				}}
			>
				<Select
					className="md:w-auto md:min-w-[320px]"
					variant="light"
					label="Cliente"
					controller={{
						control: form.control,
						defaultValue: null,
						name: 'requesterName',
					}}
					options={users?.data
						.filter((user: string) => user)
						.map((user: string) => ({
							label: user,
							value: user,
						}))}
					onInputChange={(e) => {
						handleSearch(e);
					}}
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					label="De"
					name="initialDate"
				/>
				<Date
					className="w-full md:w-auto"
					variant="light"
					control={form.control}
					label="Até"
					name="endDate"
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					label="Data do Pedido"
					name="orderDate"
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					label="Data Conclusão"
					name="completionDate"
				/>
				<Select
					variant="light"
					className="md:w-auto md:min-w-[164px]"
					label="Forma de Pagamento"
					controller={{
						control: form.control,
						defaultValue: null,
						name: 'paymentTypeId',
					}}
					options={paymentTypeOptions}
				/>
				<Select
					variant="light"
					className="md:w-auto md:min-w-[128px]"
					isMulti
					label="Status"
					controller={{
						control: form.control,
						defaultValue: null,
						name: 'statusId',
					}}
					options={statusOptions}
				/>
			</FiltersTab>
			<WhiteContainer>
				<Table<OrderResponseApi>
					params={params}
					isLoading={tableLoading || tableFetching}
					data={tableData?.data.data || []}
					total={tableData?.data.meta.pagination.total || 0}
					onParamsChange={(params) => {
						setParams(params);
					}}
					disabledSort={{
						requester: true,
						paid: true,
						comission: true,
						created_at: true,
					}}
					columns={[
						{
							accessor: 'id',
							Header: 'Id',
							Cell: ({ row }) => {
								const {
									id,
									searchOnlyFavoriteDrivers,
									status,
									integrationOrder,
								} = row.original;

								return (
									<div className="flex flex-col">
										<div className="flex gap-2">
											{searchOnlyFavoriteDrivers && (
												<Tooltip
													message="Somente motoristas favoritos"
													triggerClassName="self-start"
												>
													<AiFillStar
														className="text-yellow-submenu"
														size={22}
													/>
												</Tooltip>
											)}
										</div>
										<div className="flex items-center gap-2 text-lg font-bold text-orange">
											#{String(id).padStart(5, '0')}
											{integrationOrder && (
												<Tooltip
													message="Pedido de integração"
													triggerClassName="text-neutral-700"
												>
													<BsFillInfoCircleFill size={16} />
												</Tooltip>
											)}
										</div>
										<span className="text-xs text-neutral-600">
											{status.name}
										</span>
									</div>
								);
							},
						},
						{
							accessor: 'requestDate',
							Header: 'Solicitação',
							Cell: ({ row }) => {
								const { requestDate } = row.original;
								const date = requestDate
									? dayjs(requestDate).format('DD/MM/YYYY HH:mm')
									: '-';

								return (
									<div className="flex flex-col">
										<span className="text-sm text-neutral-900">{date}</span>
										<span className="text-xs text-neutral-500">
											Data da solicitação
										</span>
									</div>
								);
							},
						},
						{
							accessor: 'orderDate',
							Header: 'Data',
							Cell: ({ row }) => {
								const { orderDate, scheduleDate } = row.original;
								const date = orderDate
									? dayjs(orderDate).format('DD/MM/YYYY HH:mm')
									: 'Começa imediatamente';

								return (
									<div className="flex gap-1">
										{scheduleDate ? (
											<TbClock size={30} />
										) : (
											<TbTruckDelivery size={30} />
										)}
										<div className="flex flex-col">
											<span className="font-medium text-neutral-800">
												{scheduleDate ? 'Agendado' : 'Imediato'}
											</span>
											<span className="text-sm text-neutral-500">{date}</span>
										</div>
									</div>
								);
							},
						},
						{
							accessor: 'completionDate',
							Header: 'Conclusão',
							Cell: ({ value }) => {
								const date = value
									? dayjs(value).format('DD/MM/YYYY HH:mm')
									: '-';
								return (
									<div className="flex flex-col text-sm">
										<span>{date}</span>
										<span className="text-xs text-neutral-500">Conclusão</span>
									</div>
								);
							},
						},
						{
							accessor: 'requester',
							Header: 'Cliente',
							Cell: ({ row }) => (
								<ClientDropdown
									orderId={row.original.id}
									companyAvatar={row.original.requesterCompany?.avatar}
									companyId={row.original.requesterCompany?.id}
									companyName={row.original.requesterCompany?.companyName}
									companyPhone={row.original.requesterCompany?.phone}
									companyTags={row.original.requesterCompany?.tags}
									companyTradingName={
										row.original.requesterCompany?.tradingName
									}
									isFirstRequest={row.original.isFirstRequest}
									userId={row.original.requesterCompany?.owner?.id}
									userAvatar={row.original.requesterCompany?.owner?.avatar}
									userCellphone={
										row.original.requesterCompany?.owner?.cellphone
									}
									userName={row.original.requesterCompany?.owner?.name}
									userPhone={row.original.requesterCompany?.owner?.phone}
								/>
							),
						},
						{
							accessor: 'categoryId',
							Header: 'Veículo',
							Cell: ({ row }) => (
								<CategoryType
									category={row.original.category}
									categoryType={row.original.categoryType}
									isFavoriteDriver={row.original.isFavoriteDriver}
									defrauded={row.original.defrauded}
								/>
							),
						},
						{
							accessor: 'total',
							Header: 'Valor total',
							Cell: ({ value }) => {
								return (
									<div className="flex flex-col text-sm">
										<span>
											{value.toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL',
											})}
										</span>
									</div>
								);
							},
						},
						{
							accessor: 'paid',
							Header: 'Comissão',
							Cell: ({ row }) => {
								const { total, comission, statusId } = row.original;
								return (
									<div className="flex flex-col text-sm">
										{statusId === appState.orderStatus.Cancelado.id ? (
											<span className="text-neutral-500">-</span>
										) : (
											<span>{((comission / total) * 100).toFixed() + '%'}</span>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'comission',
							Header: 'A receber',
							Cell: ({ row }) => {
								const { comission, statusId } = row.original;

								return (
									<div className="flex flex-col text-sm">
										{statusId === appState.orderStatus.Cancelado.id ? (
											<span className="text-neutral-500">-</span>
										) : (
											<span
												className={`${
													comission > 0 ? 'text-green' : 'text-terracota-500'
												}`}
											>
												{comission.toLocaleString('pt-br', {
													style: 'currency',
													currency: 'BRL',
												})}
											</span>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'created_at',
							Header: 'Ações',
							width: '0%',
							Cell: ({ row }) => (
								<TableActions
									row={row}
									actions={[
										{
											icon: <TbListDetails size={16} />,
											label: 'Detalhes',
											href: `/pedidos/${row.original.id}`,
										},
									]}
								/>
							),
						},
					]}
				/>
			</WhiteContainer>
		</div>
	);
};

export default Earnings;
