import { z } from 'zod';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Params } from '../../services/angular/types/Params';
import dayjs from 'dayjs';

type UsersFilterState = {
	params: Params;
	setParams: (params: Params) => void;
	setUsersFilter: (state: UsersFilterSchemaType) => void;
	clearUsersFilter: () => void;
} & UsersFilterSchemaType;

export const useUsersFilterState = create<UsersFilterState>()(
	persist(
		(set) => ({
			params: {
				page: 1,
				take: 50,
			},
			blocked: '',
			wasTrained: '',
			active: '',
			setParams: (params) => set({ params }),
			setUsersFilter: (state) => set(state),
			clearUsersFilter: () => set({}),
		}),
		{
			name: 'UsersFilterState',
			partialize: ({
				userTypes,
				wasTrained,
				blocked,
				roleId,
				active,
				driverStatus,
				userAvailability,
			}) => ({
				userTypes,
				wasTrained,
				blocked,
				roleId,
				active,
				driverStatus,
				userAvailability,
			}),
		}
	)
);

export const usersFilterSchema = z.object({
	name: z.string().optional(),
	cpf: z.string().optional(),
	cellphone: z.string().optional(),
	initialDate: z
		.any()
		.optional()
		.transform(
			(value) => value && dayjs(value).hour(0).minute(0).toISOString()
		),
	endDate: z
		.any()
		.optional()
		.transform(
			(value) => value && dayjs(value).hour(0).minute(0).toISOString()
		),
	userAvailability: z
		.array(
			z.object({
				value: z.string(),
				label: z.string().optional(),
			})
		)
		.nullable()
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
	blocked: z
		.string()
		.optional()
		.transform((value) =>
			value === 'Bloqueado' ? true : value === 'Desbloqueado' ? false : ''
		),
	geofenceId: z
		.object({
			value: z.number(),
			label: z.string().optional(),
		})
		.nullable()
		.optional()
		.transform((value) => value?.value),
	wasTrained: z
		.string()
		.optional()
		.transform((value) =>
			value === 'Treinado' ? true : value === 'Sem Treinamento' ? false : ''
		),
	tagsIds: z
		.array(
			z.object({
				value: z.string(),
				label: z.string().optional(),
			})
		)
		.nullable()
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
	driverStatus: z
		.string()
		.optional()
		.transform((value) =>
			value === 'Aguardando'
				? 1
				: value === 'Rejeitado'
				? 2
				: value === 'Aprovado'
				? 3
				: undefined
		),
	roleId: z
		.string()
		.optional()
		.transform((value) =>
			value === 'Super'
				? 1
				: value === 'Admin'
				? 2
				: value === 'Usuário'
				? 3
				: undefined
		),
	userTypes: z.string().optional(),
	active: z
		.string()
		.optional()
		.transform((value) =>
			value === 'Ativa' ? true : value === 'Inativa' ? false : ''
		),
});

export type UsersFilterSchemaType = z.infer<typeof usersFilterSchema>;
