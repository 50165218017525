import { ComponentProps, forwardRef } from 'react';

interface SwitchButtonProps extends ComponentProps<'input'> {
	label?: string;
	isFlexCol?: boolean;
}

export const SwitchButton = forwardRef<HTMLInputElement, SwitchButtonProps>(
	function SwitchButton({ isFlexCol, label, ...props }, ref) {
		const id = Math.random().toString(36).substr(2, 9);
		return (
			<>
				{isFlexCol ? (
					<div className="flex flex-col gap-3">
						{label && (
							<label
								htmlFor={id}
								className="cursor-pointer text-sm font-bold text-neutral-700"
							>
								{label}
							</label>
						)}
						<label className="relative inline-flex cursor-pointer items-center">
							<input
								ref={ref}
								id={id}
								type="checkbox"
								className="peer sr-only"
								{...props}
							/>
							<div className="peer-focus:ring-blue-300 dark:border-gray-600 peer h-3 w-9 rounded-full bg-neutral-200 after:absolute after:top-[-4px] after:left-[0px] after:h-5 after:w-5 after:rounded-full after:border after:border-neutral-100 after:bg-white after:shadow-md after:transition-all after:content-[''] peer-checked:bg-blue peer-checked:after:translate-x-full peer-checked:after:border-neutral-100 peer-focus:outline-none peer-focus:ring-4"></div>
						</label>
					</div>
				) : (
					<div className="flex items-center text-left">
						<label className="relative inline-flex cursor-pointer items-center">
							<input
								id={id}
								ref={ref}
								type="checkbox"
								className="peer sr-only"
								{...props}
							/>
							<div className="peer-focus:ring-blue-300 dark:border-gray-600 peer h-3 w-9 rounded-full bg-neutral-200 after:absolute after:top-[-4px] after:left-[0px] after:h-5 after:w-5 after:rounded-full after:border after:border-neutral-100 after:bg-white after:shadow-md after:transition-all after:content-[''] peer-checked:bg-blue peer-checked:after:translate-x-full peer-checked:after:border-neutral-100 peer-focus:outline-none peer-focus:ring-4"></div>
						</label>
						{label && (
							<label
								htmlFor={id}
								className="ml-2 cursor-pointer text-sm text-neutral-800"
							>
								{label}
							</label>
						)}
					</div>
				)}
			</>
		);
	}
);

export default SwitchButton;
