import dayjs from 'dayjs';
import { z } from 'zod';

export const dateFilterSchema = z.object({
	initialDate: z
		.any()
		.optional()
		.transform(
			(value) => value && dayjs(value).hour(0).minute(0).second(0).toISOString()
		),
	endDate: z
		.any()
		.optional()
		.transform(
			(value) => value && dayjs(value).hour(0).minute(0).second(0).toISOString()
		),
});
