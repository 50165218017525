import React, { useEffect } from 'react';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { appState, useAppState } from '../../../store/appState';
import { Form, Textarea, useZodForm } from '../../FormElements';
import { z } from 'zod';
import { api } from '../../../services/angular/axios';
import { toast } from 'react-toastify';
import { Modal } from '../../Modal';
import CancelOrder from '../../../assets/svg/cancelOrder';
import { Controller } from 'react-hook-form';
import Button from '../../Button/Button';
import useUserState from '../../../services/angular/angularUserState';
import { useAlert } from '../../../contexts/AlertContext';
// @ts-expect-error
import cancelReasonGif from '../../../assets/images/cancelReasonAlert.gif';

const CancelOrderSchema = z.object({
	cancelReasonId: z.coerce.number(),
	observations: z.string().optional(),
});

type CancelOrderForm = z.infer<typeof CancelOrderSchema>;

interface CancelOrderModalProps {
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	open: boolean;
	orderId?: number;
}

export const CancelOrderModal = ({
	setOpen,
	open,
	orderId,
}: CancelOrderModalProps) => {
	const alert = useAlert();
	const user = useUserState((state) => state.user);
	const [modalOpen, setModalOpen] = React.useState(false);
	const cancelReasons = useAppState((state) => state.cancelReasons)?.filter(
		(reason) =>
			user?.role.id === Number(appState.userRoles.user.value)
				? reason.showClient
				: reason.showAdmin
	);

	const cancelForm = useZodForm({
		schema: CancelOrderSchema,
	});

	const cancelOrder = async ({
		cancelReasonId,
		observations,
	}: CancelOrderForm) => {
		try {
			if (!orderId) {
				throw new Error('Pedido não encontrado');
			}
			await api.put(`/orders/${orderId}/cancel`, {
				cancelReasonId,
				observations,
			});
			toast.success('Frete cancelado com sucesso');
			setModalOpen(false);
		} catch (error) {
			toast.error('Erro ao cancelar frete, entre em contato com o suporte');
			setModalOpen(false);
		}
	};

	useEffect(() => {
		open &&
			user?.role.id === Number(appState.userRoles.user.value) &&
			alert.onCustom({
				message: (
					<div className="flex flex-col items-center">
						<p className="font-bold">
							Tem certeza que deseja cancelar o frete?
						</p>
						<img
							src={cancelReasonGif}
							alt="cancelReasonImg"
							className="w-1/2"
						/>
						<p className="text-sm font-bold">
							Nossa equipe intervém em caso de demora no aceite.
							<br />
							Estamos buscando um entregador disponível para você
						</p>
					</div>
				),

				confirm: {
					label: 'Sim',
					onClick: async () => setModalOpen(true),
				},
				cancel: {
					label: 'Não',
					onClick: () => {
						setOpen(false);
					},
				},
			});
	}, [open]);

	return (
		<Modal
			title="Motivo do cancelamento"
			setOpen={
				user?.role.id === Number(appState.userRoles.user.value)
					? setModalOpen
					: setOpen
			}
			open={
				user?.role.id === Number(appState.userRoles.user.value)
					? modalOpen
					: open
			}
			onClose={() => {
				cancelForm.reset();
				setOpen(false);
			}}
		>
			<Form form={cancelForm} onSubmit={cancelOrder}>
				<div className="flex flex-col items-center gap-4 px-4 text-center text-xl font-bold text-neutral-0 md:px-12">
					<CancelOrder />
					{user?.role.name === 'user' ? (
						'Sujeito a cobrança de tarifa caso haja deslocamento do entregador'
					) : (
						<span>
							Por favor, nos informe o motivo do cancelamento do pedido{' '}
							<span className="text-orange">#{orderId}</span>
						</span>
					)}
				</div>

				<div className="flex flex-col gap-2">
					{Number(cancelForm.watch('cancelReasonId')) !== 1001 && (
						<>
							{user?.role.name === 'user' && (
								<h1 className="mt-4 ml-2 text-lg text-neutral-700">
									Informe o motivo:
								</h1>
							)}
							<Controller
								control={cancelForm.control}
								name="cancelReasonId"
								render={({ field }) => (
									<RadioGroup.Root
										value={String(field.value)}
										className="mb-6 flex w-full flex-col items-start gap-2"
										onValueChange={field.onChange}
									>
										{cancelReasons?.map((reason) => (
											<div
												key={reason.id}
												className={`flex w-full items-center gap-4 rounded p-2 ${
													field.value === reason.id ? 'bg-yellow-500/50' : ''
												}`}
											>
												<RadioGroup.Item
													className="h-6 w-6 rounded-full bg-white shadow shadow-neutral-500 hover:bg-neutral-100"
													value={String(reason.id)}
													id={String(reason.id)}
												>
													<RadioGroup.Indicator className="relative flex h-full w-full items-center justify-center after:block after:h-[11px] after:w-[11px] after:rounded-full after:bg-neutral-0" />
												</RadioGroup.Item>
												<label
													htmlFor={String(reason.id)}
													className="tex-neutral-600 relative inline-flex cursor-pointer items-center text-sm"
												>
													<span className="ml-2">{reason.description}</span>
												</label>
											</div>
										))}
									</RadioGroup.Root>
								)}
							/>
						</>
					)}
					{Number(cancelForm.watch('cancelReasonId')) === 1001 && (
						<Textarea
							rows={5}
							label="Motivo"
							{...cancelForm.register('observations')}
						/>
					)}

					<div className="flex gap-2">
						{Number(cancelForm.watch('cancelReasonId')) === 1001 && (
							<Button
								variant="secondary"
								onClick={() => {
									cancelForm.reset();
								}}
							>
								Voltar
							</Button>
						)}
						<Button
							type="submit"
							disabled={
								!cancelForm.watch('cancelReasonId') ||
								(Number(cancelForm.watch('cancelReasonId')) === 1001 &&
									!cancelForm.watch('observations'))
							}
						>
							Cancelar pedido
						</Button>
					</div>
				</div>
			</Form>
		</Modal>
	);
};
