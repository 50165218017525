import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const ExclamationCircleIcon: React.FC<SvgProps> = ({ color, size, stroke }) => {
	return (
		<svg
			width="100%"
			height={size ?? '100%'}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8 14.6667C4.32001 14.6667 1.33334 11.68 1.33334 8C1.33334 4.32 4.32001 1.33333 8 1.33333C11.68 1.33333 14.6667 4.32 14.6667 8C14.6667 11.68 11.68 14.6667 8 14.6667Z"
				fill="currentColor"
			/>
			<path
				d="M8 4.66667C7.46667 4.66667 7.06667 5.06667 7.06667 5.6V8.53333C7.06667 9.06667 7.46667 9.46667 8 9.46667C8.53333 9.46667 8.93333 9.06667 8.93333 8.53333V5.6C8.93333 5.06667 8.53333 4.66667 8 4.66667Z"
				fill="currentColor"
			/>
			<path
				d="M8 10.6667C7.46667 10.6667 7.06667 11.0667 7.06667 11.6C7.06667 12.1333 7.46667 12.5333 8 12.5333C8.53333 12.5333 8.93333 12.1333 8.93333 11.6C8.93333 11.0667 8.53333 10.6667 8 10.6667Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default ExclamationCircleIcon;
