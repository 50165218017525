import { useEffect, useRef } from 'react';
import {
	TbDeviceDesktopAnalytics,
	TbWallet,
	TbBuilding,
	TbTruck,
	TbHexagon,
	TbSettings,
	TbMessageDots,
	TbStar,
	TbTruckDelivery,
	TbTruckLoading,
} from 'react-icons/tb';
import useUserState from '../../services/angular/angularUserState';
import { RiUserLine } from 'react-icons/ri';
import {
	canBuyCredits,
	canManageCompany,
	canManageCompanyDrivers,
	canRequestFreight,
	canSeeFavorites,
	canSeeMyBusiness,
	canSeeReceipt,
	canSeeDashboard,
	canSeeWallet,
	isSuperAdmin,
} from '../../helpers/permission';
import { Accordion } from '../Accordion';
import { atom, useAtom } from 'jotai';
import { twMerge } from 'tailwind-merge';
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2';
import { FiUserCheck, FiUsers } from 'react-icons/fi';
import { BsMegaphone } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useGetMerchant } from '../../services/integration/ifood/useGetMerchant';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require('../../../package.json');

export const sideBarAtom = atom<boolean>(false);

export const SideBar = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useAtom(sideBarAtom);
	const company = useUserState((state) => state.company);
	const user = useUserState((state) => state.user);
	const role = useUserState((state) => state.userRole);
	const IsDeveloper = useUserState((state) => state.IsDeveloper);
	const { data } = useGetMerchant();

	const sidebarRef = useRef<HTMLDivElement>(null);

	function handleFocusOut(event: any): void {
		if (!sidebarRef.current?.contains(event.relatedTarget)) {
			setIsSidebarOpen(false);
		}
	}

	useEffect(() => {
		sidebarRef.current?.focus();

		sidebarRef.current?.addEventListener('focusout', handleFocusOut);

		return () => {
			sidebarRef.current?.removeEventListener('focusout', handleFocusOut);
		};
	}, [isSidebarOpen]);

	return (
		<div
			ref={sidebarRef}
			tabIndex={-1}
			className={twMerge(
				'fixed z-10 mt-16 flex h-[calc(100vh-64px)] flex-col justify-between bg-yellow-300 text-neutral-0 ',
				'overflow-auto scroll-smooth transition-all duration-200 scrollbar-none focus:outline-none',
				isSidebarOpen ? 'w-56' : 'w-0 md:w-14'
			)}
			onMouseEnter={() => setIsSidebarOpen(true)}
			onMouseLeave={() => setIsSidebarOpen(false)}
		>
			<div className="duration-400 relative text-sm transition-all">
				{role?.name !== 'user' ? (
					<Accordion
						items={[
							{
								label: 'Pedidos',
								icon: <TbTruckLoading size={26} />,
								path: '/pedidos',
								options: [],
							},
							{
								label: 'Operações',
								icon: <TbDeviceDesktopAnalytics size={26} />,
								path: '/operacoes',
								options: [],
							},

							{
								label: 'Financeiro',
								icon: <TbWallet size={26} />,
								options: [
									{
										label: 'Receber',
										path: '/financeiro/receber',
									},
									{
										label: 'Pagar',
										path: '/financeiro/pagar',
									},
								],
							},
							{
								label: 'Usuários',
								icon: <FiUsers size={26} />,
								path: '/usuarios',
								options: [],
							},
							{
								label: 'Veículos',
								icon: <TbTruck size={26} />,
								path: '/veiculos',
								options: [],
							},
							{
								label: 'Empresas',
								icon: <TbBuilding size={26} />,
								path: '/empresas',
								options: [],
							},

							{
								label: 'Configurações',
								icon: <TbSettings size={26} />,
								options: [
									{
										label: 'Veículos',
										path: '/configuracoes/veiculos',
									},
									{
										label: 'Multiplicadores',
										path: '/configuracoes/multiplicadores',
									},
									{
										label: 'Sistema',
										path: '/configuracoes/sistema',
									},
									{
										label: 'Funcionalidades',
										path: '/configuracoes/funcionalidades',
										required: !IsDeveloper,
									},
								],
							},
							{
								label: 'Administração',
								icon: <TbHexagon size={26} />,
								options: [
									{
										label: 'Notificações',
										path: '/administracao/notificacoes',
									},
									{
										label: 'Pop Informativo',
										path: '/administracao/pop-informativo',
									},
									{
										label: 'Auditoria',
										path: '/administracao/auditoria',
										required: !isSuperAdmin(role),
									},
									{
										label: 'Transações',
										path: '/administracao/transacoes',
										required: !isSuperAdmin(role),
									},
									{
										label: 'Geofences',
										path: '/administracao/geofences',
										required: !isSuperAdmin(role),
									},
									{
										label: 'Mensagens Automáticas',
										path: '/administracao/mensagens-automaticas',
										required: !isSuperAdmin(role),
									},
								],
							},
						]}
					/>
				) : (
					<>
						<Accordion
							items={[
								{
									label: 'Pedir Frete',
									icon: <TbTruckDelivery size={26} />,
									path: '/fretes/pedir',
									options: [],
									required: !canRequestFreight(company),
								},
								{
									label: 'Dashboard',
									path: '/fretes/dashboard',
									icon: <TbDeviceDesktopAnalytics size={26} />,
									required: !canSeeDashboard(company),
									options: []
								},
								{
									label: 'Meus fretes',
									icon: <HiOutlineClipboardDocumentList size={26} />,
									options: [
										{
											label: 'Rascunhos',
											path: '/fretes/rascunhos',
											required: !canRequestFreight(company),
										},
										{ label: 'Em andamento', path: '/fretes/em-andamento' },
										{ label: 'Agendados', path: '/fretes/agendados' },
										{ label: 'Histórico', path: '/fretes/historico' },
									],
								},
								{
									label: 'Fazer fretes',
									required: !canManageCompanyDrivers(company),
									icon: <FiUserCheck size={25} />,
									options: [],
									path: '/fazer-fretes',
								},
								{
									label: 'Carteira',
									icon: <TbWallet size={26} />,
									required: !canSeeWallet(company),
									options: [
										{ label: 'Resumo', path: '/carteira/resumo' },
										{
											required: !canBuyCredits(company),
											label: 'Comprar Créditos',
											path: '/carteira/comprar-creditos',
										},
										{
											required: !canSeeReceipt(company),
											label: 'Meios de Recebimento',
											path: '/carteira/recebimento',
										},
									],
								},
								{
									label: 'Meu negócio',
									icon: <TbBuilding size={26} />,
									required: !canSeeMyBusiness(company),
									options: [
										{
											required: !canManageCompany(company),
											label: 'Perfil da Empresa',
											path: '/negocios/perfil',
										},
										{
											required: !canManageCompany(company),
											label: 'Usuários',
											path: '/negocios/usuarios',
										},
										{
											required: !canManageCompanyDrivers(company),
											label: 'Veículos',
											path: '/negocios/veiculos',
										},
										{
											required: !canManageCompanyDrivers(company),
											label: 'Motoristas',
											path: '/negocios/motoristas',
										},
										{
											required: !data,
											label: 'iFood',
											path: '/negocios/ifood',
										},
									],
								},
								{
									label: 'Meus Favoritos',
									icon: <TbStar size={26} />,
									required: !canSeeFavorites(company),
									options: [
										{
											label: 'Motoristas',
											path: '/motoristas-favoritos',
										},
									],
								},
								{
									label: 'Minha Conta',
									icon: <RiUserLine size={26} />,
									path: '/minha-conta',
									options: [],
								},
								{
									label: 'Financeiro',
									icon: <TbWallet size={26} />,
									required: !(
										user?.role.name === 'admin' || user?.role.name === 'sadmin'
									),
									options: [
										{
											label: 'Receber',
											path: '/financeiro/receber',
										},
										{
											label: 'Pagar',
											path: '/financeiro/pagar',
										},
									],
								},
							]}
						/>
						<a
							href="https://beebeelogistica.freshdesk.com/support/home"
							target="_blank"
							rel="noreferrer"
							className={`flex items-center gap-2 p-4 transition-all hover:bg-neutral-900 hover:text-yellow-200 `}
							onClick={() => {
								if (isSidebarOpen) setIsSidebarOpen(false);
							}}
						>
							<span className="h-[26px] w-[26px]">
								<TbMessageDots size={26} />
							</span>
							<p
								className={isSidebarOpen ? 'truncate font-semibold' : 'hidden'}
							>
								Suporte
							</p>
						</a>
					</>
				)}
			</div>

			{isSidebarOpen && (
				<>
					<div className="flex w-full flex-col items-center justify-center gap-4 p-2">
						<Link
							to="/convite"
							className="flex w-full gap-2 rounded-md bg-gradient-to-bl from-yellow-400 to-yellow-600 px-4 py-2 shadow shadow-neutral-500"
						>
							<BsMegaphone className="h-full -rotate-12" size={20} />
							<div>
								<p className="font-neutral-0 truncate text-base font-medium leading-tight">
									Indique um Amigo
								</p>
								<p className="font-regular font-neutral-600 truncate text-xs">
									para ser um entregador
								</p>
							</div>
						</Link>
						{/* <img
							src={require('../../assets/images/menu-hexagons.png')}
							className="absolute bottom-0 left-0 w-full bg-yellow-500"
						/> */}
						<a
							href="https://play.google.com/store/apps/details?id=br.com.beebee.app&hl=en&pli=1"
							target="_blank"
							className="flex items-center gap-1 hover:underline"
							rel="noreferrer"
						>
							<img
								className="w-36 cursor-pointer hover:opacity-90"
								src={require('../../assets/images/google-play.png')}
							/>
						</a>
						<a
							href="https://apps.apple.com/br/app/beebee/id1352590141"
							target="_blank"
							className="flex items-center gap-1 hover:underline"
							rel="noreferrer"
						>
							<img
								className="w-36 cursor-pointer hover:opacity-90"
								src={require('../../assets/images/app-store.png')}
							/>
						</a>
					</div>
					<p className="text-xs">Versão: {version}</p>
				</>
			)}
		</div>
	);
};
