import { BiImport } from 'react-icons/bi';
import { RequestFreightForm, OrderMutation, updateRouteAtom } from '../..';
import { Tooltip } from '../../../../../../components/Tooltip';
import { read, utils } from 'xlsx';
import { ChangeEvent } from 'react';
import { useAtom } from 'jotai';
import { useAlert } from '../../../../../../contexts/AlertContext';
import { z } from 'zod';

interface ImportRouteProps {
	form: RequestFreightForm;
	orderMutation: OrderMutation;
}

const locationsSchema = z.array(
	z
		.object({
			id: z.coerce.number().optional(),
			sequence: z.coerce.number(),
			name: z.coerce
				.string()
				.optional()
				.nullable()
				.transform((value) => (value ? String(value) : value)),
			address: z.coerce
				.string()
				.optional()
				.nullable()
				.transform((value) => (value ? String(value) : value)),
			contact: z.coerce
				.string()
				.optional()
				.nullable()
				.transform((value) => (value ? String(value) : value)),
			observations: z.coerce
				.string()
				.optional()
				.nullable()
				.transform((value) => (value ? String(value) : value)),
			responsible: z.coerce
				.string()
				.optional()
				.nullable()
				.transform((value) => (value ? String(value) : value)),
		})
		.superRefine((data, ctx) => {
			if (!data.address) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Endereço do ${
						data.name ||
						data.contact ||
						data.responsible ||
						data.observations ||
						''
					}, ${data.sequence + 1}º local, está vazio.`,
				});

				return false;
			}
			if (!data.contact && !data.responsible && !data.observations) {
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: `Instruções ao entregador, contato no local ou responsável não encontrados no local ${data.address}. Ao menos uma dessas informações deve existir.`,
				});

				return false;
			}

			return true;
		})
);

export const ImportRoute = ({ form, orderMutation }: ImportRouteProps) => {
	const alert = useAlert();
	const [updateDraft, triggerUpdateDraft] = useAtom(updateRouteAtom);

	const handleImport = (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files;
		if (files?.length) {
			const file = files[0];
			const reader = new FileReader();

			reader.onload = (event) => {
				if (!event.target?.result) return;

				const wb = read(event.target.result);
				const sheets = wb.SheetNames;

				if (sheets.length) {
					const rows = utils.sheet_to_json(wb.Sheets[sheets[0]], {
						header: [
							'name',
							'address',
							'contact',
							'observations',
							'responsible',
						],
						range: 1,
					});

					// avoid duplicate id when importing multiple times
					const randomId = Math.random() * 1000;
					const locations = rows.map((row: any, index) => ({
						name: row.name,
						address: row.address,
						contact: row.contact,
						observations: row.observations,
						responsible: row.responsible,
						id: `${randomId + index}`,
						sequence: index,
					}));

					const parsed = locationsSchema.safeParse(locations);

					if (parsed.success) {
						if (form.getValues('locations')?.length)
							alert.onCustom({
								title: 'Importação de Rota',
								message: 'Deseja limpar a rota antes de fazer a importação?',
								cancel: {
									label: 'Limpar',
									icon: <></>,
									onClick: () => {
										form.setValue('locations', locations);
										finalizeImport();
									},
								},
								confirm: {
									label: 'Adicionar á rota',
									icon: <></>,
									onClick: () => {
										const newLocationsOrder = locations.map(
											(location, index) => {
												return {
													...location,
													sequence: form.getValues('locations')?.length + index,
												};
											}
										);

										form.setValue('locations', [
											...form.getValues().locations,
											...newLocationsOrder,
										]);
										finalizeImport();
									},
								},
							});
						else {
							form.setValue('locations', locations);
							finalizeImport();
						}
					} else {
						alert.onCustom({
							title: 'Problema com a importação de Rota',
							message: parsed.error.errors?.[0].message || 'Erro desconhecido',
						});
					}
				}
			};

			reader.readAsArrayBuffer(file);

			event.target.value = '';
			event.target.files = null;
		}
	};

	const finalizeImport = () => {
		if (orderMutation.isSuccess) {
			triggerUpdateDraft(!updateDraft);
			return;
		}

		void form.handleSubmit((data) => {
			// @ts-expect-error - handleSubmit getting the wrong zod
			orderMutation.mutate(data);
		})();
	};

	return (
		<Tooltip
			message="Importar planilha com a rota"
			triggerClassName="self-start bg-white rounded pointer-events-auto"
		>
			<label className="flex w-auto cursor-pointer items-center justify-center gap-4 rounded border-none bg-white px-3 py-2.5 text-sm font-bold text-neutral-0 shadow hover:bg-neutral-100">
				<BiImport size={28} />
				<input
					type="file"
					accept=".xlsx, .xls, .ods"
					className="hidden"
					onChange={handleImport}
				/>
			</label>
		</Tooltip>
	);
};
