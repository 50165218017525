import { createWithEqualityFn } from 'zustand/traditional';
import { persist } from 'zustand/middleware';
import jwt_decode from 'jwt-decode';
import { ObjectValues } from '../../helpers/types';
import { OrderResponseApi, Vehicle } from './types/Order';
import { shallow } from 'zustand/shallow';
import { Geofence } from '../../store/appState';

export const Profiles = {
	// ================ Roles do Profile acesso pessoal do usuário logado ========================/
	AcessoPessoal: 'Acesso Pessoal',
	// ================ Roles do Profile empresarial do usuário logado ========================/
	Fretista: 'Fretista', // usuário que é owner em uma empresa que faz frete
	Empresa: 'Empresa', // usuário que é owner em uma empresa que NÃO faz frete
	Motorista: 'Motorista', // usuário motorista na empresa
	Usuario: 'Usuário', // usuário comum da empresa
	UsuarioAdmin: 'Usuário Admin', // usuário admin da empresa, que possui mesmo acesso do owner da empresa
} as const;

export type ProfileType = ObjectValues<typeof Profiles>;

export interface Role {
	id: number;
	name: string;
}

export interface User {
	id: number;
	active: boolean;
	awaitingFreight: boolean;
	stagingArea: boolean;
	blocked: boolean;
	approved: boolean;
	roleId: number;
	name: string;
	email: string;
	phone?: any;
	cellphone: string;
	avatar: string;
	avatarUrl: string;
	driverIntent: boolean;
	driverStatus: 0 | 1 | 2 | 3;
	bikerStatus: number;
	clientStatus: number;
	updated_at: Date;
	created_at: Date;
	trainingDate?: any;
	picpay?: any;
	pix?: any;
	observation?: any;
	deletionRequestDate?: any;
	reasonDeletionRequest?: any;
	makeFreight: boolean;
	requestFreights: boolean;
	isDriver: boolean;
	isOwner: boolean;
	isAdmin: boolean;
	latestOnline?: any;
	online: boolean;
	rg?: any;
	rgImg?: string;
	rgBackImg?: string;
	cpf: string;
	cpfImg?: string;
	driverLicense: string;
	driverLicenseImg: string;
	currentGeofenceId: number;
	driverLicenseExpiration: string;
	role: Role;
	companies: CompanyModel[];
	activeFreights: OrderResponseApi[];
	tags: any[];
	confirmation?: {
		code: string;
		confirmed: boolean;
	};
}

export interface CompanyModel {
	type: ProfileType;
	id: number;
	active: boolean;
	approved: boolean;
	status: 0 | 1 | 2 | 3;
	requestFreights: boolean;
	makeFreights: boolean;
	ownerId: number;
	tradingName: string;
	companyName: string;
	cnpj: string;
	email: string;
	supportEmail?: any;
	phone: string;
	cellphone: string;
	avatar?: any;
	avatarUrl?: any;
	created_at: Date;
	updated_at: Date;
	executedOrders: number;
	searchOnlyFavoriteDrivers: boolean;
	slaTimeTarget: number;
	slaCostPerDeliveryTarget: number;
	dynamicRouteEnabled: boolean;
	hasFavoriteDrivers: boolean;
	allowFavoriteDriver: boolean;
	allowBankingBill: boolean;
	isOwner: boolean;
	isDriver: boolean;
	isAdminUser: boolean;
	isUser: boolean;
	zipcode: string;
	stateId: number;
	address: string;
	number: string;
	district: string;
	city: string;
	complement: string;
	spendingGoal?: any;
	businessSegmentId?: any;
	leadId?: any;
	observation?: any;
	tags?: Tag[];
	state?: {
		id: number;
		abbr: string;
	};
	owner: User;
	users?: User[];
	drivers?: User[];
	favoriteDrivers?: User[];
	vehicles?: Vehicle[];
}

export interface Tag {
	id: number;
	name: string;
}

interface UserState {
	userId?: number;
	aspNetUserId?: string;
	LoginAd: boolean;
	user?: Omit<User, 'companies'> | undefined;
	userRole?: Role;
	token?: string;
	company?: CompanyModel;
	isLogged: boolean;
	companies: CompanyModel[];
	refetchUserCompanies?: boolean;
	persistCompany?: {
		id?: number;
		type?: ProfileType;
	};
	persistGeofenceId?: number;
	geofences: Geofence[];
	IsDeveloper: boolean;
	showBalance: boolean;
	deliveryTime: number;
	deliveryCost: number;
	toggleShowBalance: () => void;
	setGeofences: (geofences: Geofence[]) => void;
	triggerRefetch: () => void;
	logout: () => void;
	setCompany: (company: CompanyModel) => void;
	login: (token: string) => void;
	setUser: (user: User) => void;
	setPersistGeofenceId: (geofenceId: number) => void;
	setUserId: (userId: number) => void;
	setDeliveryTime: (deliveryTime: number) => void;
	setDeliveryCost: (deliveryCost: number) => void;
}

const useUserState = createWithEqualityFn<UserState>()(
	persist(
		(set, get) => ({
			geofences: [],
			LoginAd: false,
			IsDeveloper: false,
			companies: [],
			isLogged: false,
			refetchUserCompanies: undefined,
			showBalance: false,
			deliveryCost: 0,
			deliveryTime: 0,
			toggleShowBalance: () => {
				set({ showBalance: !get().showBalance });
			},
			setGeofences: (geofences: Geofence[]) => {
				set({ geofences });
			},
			triggerRefetch: () => {
				set({ refetchUserCompanies: !get().refetchUserCompanies });
			},
			setCompany: (company: CompanyModel) => {
				set({
					company,
					persistCompany: {
						id: company.id,
						type: company.type,
					},
				});
			},
			setUser: (data: User) => {
				const { companies, ...user } = data;
				const companiesArr: CompanyModel[] = [];

				companies.forEach((company) => {
					if (company.isDriver) {
						if (company.isOwner || company.isAdminUser || company.isUser)
							companiesArr.push({
								...company,
								isDriver: false,
								type: company.isOwner
									? company.makeFreights
										? Profiles.Fretista
										: Profiles.Empresa
									: company.isAdminUser
									? Profiles.UsuarioAdmin
									: Profiles.Usuario,
							});
						companiesArr.push({
							...company,
							makeFreights: false,
							type: Profiles.Motorista,
						});
					} else if (company.isOwner) {
						companiesArr.push({
							...company,
							type: company.makeFreights ? Profiles.Fretista : Profiles.Empresa,
						});
					} else if (company.isAdminUser) {
						companiesArr.push({
							...company,
							type: Profiles.UsuarioAdmin,
						});
					} else if (company.isUser) {
						companiesArr.push({
							...company,
							type: Profiles.Usuario,
						});
					}
				});

				const company =
					companiesArr.find(
						(company) =>
							company.id === get().persistCompany?.id &&
							company.type === get().persistCompany?.type
					) || companiesArr[0];

				set({ user: { ...user }, companies: companiesArr, company });
			},
			logout: () => {
				set({
					persistCompany: {
						id: get().company?.id,
						type: get().company?.type,
					},
					userId: undefined,
					aspNetUserId: undefined,
					companies: [],
					company: undefined,
					user: undefined,
					token: undefined,
					userRole: undefined,
					isLogged: false,
				});
			},
			login: (token) => {
				const decoded = jwt_decode(token);

				const { id, role, uuid, LoginAd, IsDeveloper } = decoded as any;

				set({
					aspNetUserId: uuid,
					LoginAd,
					IsDeveloper,
					userId: id,
					token,
					isLogged: true,
					userRole: role,
				});
			},
			setPersistGeofenceId: (persistGeofenceId) => {
				set({ persistGeofenceId });
			},
			setUserId: (userId) => {
				set({ userId });
			},
			setDeliveryTime: (deliveryTime) => {
				set({
					deliveryTime,
				})
			},
			setDeliveryCost: (deliveryCost) => {
				set({
					deliveryCost,
				})
			},
		}),
		{
			name: 'beebee@user',
		}
	),
	shallow
);

export default useUserState;
