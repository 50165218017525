export const variants = {
	primary: {
		label: 'text-sm text-neutral-700 font-bold font-inter-regular',
		div: '',
		input: 'px-3 py-2',
	},
	light: {
		label: 'text-sm text-neutral-700 font-bold font-inter-regular',
		div: '',
		input: 'px-2 py-1',
	},
	green: {
		label: '',
		div: '',
		input: '',
	},
};

export type Variants = keyof typeof variants;
