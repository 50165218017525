/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { SwitchButton } from '../../../components/FormElements';
import { PageHeader } from '../../../components/PageHeader';
import { Table } from '../../../components/Table/Table';
import WhiteContainer from '../../../components/WhiteContainer/WhiteContainer';
import { useAlert } from '../../../contexts/AlertContext';
import useUserState from '../../../services/angular/angularUserState';
import { api } from '../../../services/angular/axios';
import { SortOptions } from '../../../services/angular/types/Params';
import { FavoriteDriver } from './FavoriteDriver';
import { Avatar } from '../../../components/Avatar';

export const FavoriteDrivers: React.FC = () => {
	const alert = useAlert();
	const company = useUserState((state) => state.company);
	const triggerRefetch = useUserState((state) => state.triggerRefetch);
	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});

	const handleDelete = async (data: FavoriteDriver) => {
		alert.onCustom({
			title: 'Excluir motorista',
			message: `Deseja realmente excluir o motorista ${data?.name}?`,
			confirm: {
				label: 'Excluir',
				onClick: async () => {
					try {
						await api.delete(
							`/companies/${company?.id}/favorites/drivers/${data?.id}`
						);
						await refetch();
					} catch (error: any) {
						toast.error(error?.response.data.error);
					}
				},
			},
		});
	};

	const handleFavoritesDrivers = async (value: boolean) => {
		try {
			await api.patch(`/companies/${company?.id}/set-search-only-favorite`, {
				searchOnlyFavoriteDrivers: value,
			});
			triggerRefetch();
		} catch (error: any) {
			toast.error(error?.response.data.error);
		}
	};

	const { data, isLoading, isFetching, refetch } = useQuery<any>(
		['motoristas-favoritos', params, company?.id],
		async () =>
			await api.get(
				`/companies/${company?.id}/favorites/drivers?page=${params.page}&pageSize=${params.take}`
			),
		{
			enabled: !!company?.id,
		}
	);

	return (
		<div className="w-full flex-col  text-neutral-900">
			<PageHeader
				title="Motoristas"
				description="Relação de entregadores favoritos"
			/>
			<WhiteContainer className="text-sm">
				<div className="mb-4 flex justify-end">
					<SwitchButton
						label="Sempre buscar exclusivamente entregadores favoritos"
						defaultChecked={company?.searchOnlyFavoriteDrivers}
						onChange={(e) => {
							void handleFavoritesDrivers(e.target.checked);
						}}
					/>
				</div>

				<Table<FavoriteDriver>
					isLoading={isLoading || isFetching}
					data={data?.data.data || []}
					total={data?.data.meta.pagination.total}
					sort={false}
					onParamsChange={(params) => {
						setParams(params);
					}}
					actions={[
						{
							icon: 'trash',
							label: 'Excluir',
							onAction: (data) => {
								void handleDelete(data);
							},
						},
					]}
					columns={[
						{
							accessor: 'avatarUrl',
							Cell: ({ row }) => {
								const { avatarUrl } = row.original;
								const { data: avatar } = useQuery(
									['avatar', avatarUrl],
									async () => await api.get(`/companies/${company?.id}/avatar`),
									{
										enabled: !!avatarUrl,
									}
								);
								return (
									<Avatar
										avatarProps={{ className: 'w-8 h-8' }}
										name={row.original.name}
										src={avatar?.data.url}
										isLoading={isLoading || isFetching}
									/>
								);
							},
						},
						{
							accessor: 'name',
							Header: 'Nome',
							Cell: ({ value }) => {
								return <div className="flex text-lg">{value}</div>;
							},
						},
						{
							accessor: 'companies',
							Header: 'Empresas',
							width: '80%',
							Cell: ({ value }) => {
								return (
									<div className="flex justify-center gap-3">
										{value.map((company) => (
											<div
												className="bg flex items-center rounded-sm bg-primary-400 p-0.5 px-2 text-white"
												key={company?.id}
											>
												{company?.companyName}
											</div>
										))}
									</div>
								);
							},
						},
					]}
				/>
			</WhiteContainer>
		</div>
	);
};
