import { useNavigate, useParams, Link } from 'react-router-dom';
import {
	Date,
	Form,
	FormButtons,
	FormHeader,
	Input,
	Label,
	Select,
	SwitchButton,
	Textarea,
	useZodForm,
} from '../../../../../components/FormElements';
import { PageHeader } from '../../../../../components/PageHeader';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import Pix from '../../../../../assets/svg/Pix';
import { Tags } from '../../../../../components/Angular/Tags';
import { BiCar } from 'react-icons/bi';
import { MdPedalBike } from 'react-icons/md';
import { useMutation, useQuery } from '@tanstack/react-query';
import { api } from '../../../../../services/angular/axios';
import { appState } from '../../../../../store/appState';
import { editUserSchema, EditUserSchemaType } from '../schema/editUserSchema';
import { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { toastMessage } from '../../../../../helpers/toastMessage';
import dayjs from 'dayjs';
import { PopImageInput } from '../../Administration/InformativePopUp/components/ImageInput';
import { twMerge } from 'tailwind-merge';
import { HiLockClosed } from 'react-icons/hi';
import {
	BsFillPersonXFill,
	BsCalendarCheckFill,
	BsExclamation,
	BsCheck,
} from 'react-icons/bs';
import Button from '../../../../../components/Button/Button';
import {
	editDriverSchema,
	EditDriverSchemaType,
} from '../schema/editDriverSchema';

import {
	editBikerSchema,
	EditBikerSchemaType,
} from '../schema/editBikerSchema';
import { phoneMask } from '../../../../../helpers/mask/phoneMask';
import { cpfMask } from '../../../../../helpers/mask/cpfMask';
import { z } from 'zod';
import { FiExternalLink } from 'react-icons/fi';
import { Loading } from '../../../../../components/Loading';
import { CompaniesTable } from '../../../../../components/Angular/CompaniesTable';
import { Tooltip } from '../../../../../components/Tooltip';
import { User } from '../../../../../services/angular/angularUserState';
import { ToastError } from '../../../../../helpers/errors/ToastError';

export const EditUser = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const form = useZodForm({
		schema: editUserSchema,
	});

	const driverForm = useZodForm({
		schema: editDriverSchema,
	});

	const bikerForm = useZodForm({
		schema: editBikerSchema,
	});

	const tagForm = useZodForm({
		schema: z.object({
			observation: z.string().optional(),
		}),
	});

	const {
		data: user,
		refetch: userRefetch,
		isLoading,
		isFetching,
	} = useQuery(
		['user', id],
		async () =>
			await api.get<User>(
				`/users/${id!}?include=confirmation,activeFreights,vehicle.activeOrders,vehicle.category,vehicle.company `
			),
		{
			enabled: !!id,
			staleTime: 0,
		}
	);

	const { data: tags } = useQuery(
		['tags', user?.data.id],
		async () => await api.get(`/tags?filterBy=user`),
		{
			enabled: !!user?.data.id,
		}
	);

	const { data: avatar } = useQuery(
		['avatar', user?.data.id],
		async () => await api.get(`/assets?imageName=${user!.data.avatar}`),
		{
			enabled: !!user?.data.avatar,
		}
	);

	const { data: driverSignUp, refetch: driverRefetch } = useQuery(
		['driveSignUp', user?.data.id],
		async () => await api.get(`/users/${id!}/driver-signup`)
	);

	const { data: cnh } = useQuery(
		['cnh', user?.data.id],
		async () =>
			await api.get(`/assets?imageName=${user!.data.driverLicenseImg}`),
		{
			enabled: !!user?.data.driverLicenseImg,
		}
	);

	const { data: rg } = useQuery(
		['rgImg', user?.data.id],
		async () => await api.get(`/assets?imageName=${user!.data.rgImg!}`),
		{
			enabled: !!user?.data?.rgImg,
		}
	);

	const { data: rgBack } = useQuery(
		['rgBackImg', id],
		async () => await api.get(`/assets?imageName=${user!.data.rgBackImg!}`),
		{
			enabled: !!user?.data?.rgBackImg,
		}
	);

	const { data: cpf } = useQuery(
		['cpfImg', user?.data.id],
		async () => await api.get(`/assets?imageName=${user!.data.cpfImg!}`),
		{
			enabled: !!user?.data?.cpfImg,
		}
	);

	const stagingArea = useMemo(
		() => (user?.data ? user.data.stagingArea : false),
		[user?.data]
	);

	const driver = useMemo(
		() =>
			user?.data?.driverStatus !== 0 ||
			(!!user?.data.driverLicense && !!user?.data.driverLicenseImg),
		[user?.data]
	);

	const biker = useMemo(
		() => (user?.data ? user.data.bikerStatus !== 0 : false),
		[user?.data]
	);

	const isBlocked = useMemo(
		() => (user?.data ? user.data.blocked : false),
		[user?.data]
	);

	const wasTrained = useMemo(
		() => (user?.data ? !!user.data.trainingDate : false),
		[user?.data]
	);

	const handleDriverStatus = async (value: boolean) => {
		try {
			await api.put(`/users/${id!}/status`, {
				driverStatus: value ? 1 : 0,
			});
			void userRefetch();
			void driverRefetch();
		} catch (err) {
			toast.error(toastMessage.error.updated);
		}
	};

	const { mutate: update } = useMutation(
		async (data: EditUserSchemaType) => {
			await api.put(`/users/${id!}`, data);
		},
		{
			onSuccess: () => {
				toast.success(toastMessage.success.updated);
				void userRefetch();
				void driverRefetch();
			},
			onError: (error) => {
				ToastError(error);
			},
		}
	);

	const handleUpdateDriver = async (formData: EditDriverSchemaType) => {
		try {
			await api.put(`/users/${id!}`, {
				id,
				driverLicense: formData.driverLicense,
				driverLicenseExpiration: dayjs(
					formData.driverLicenseExpiration
				).toISOString(),
				rg: formData.rg,
				driverLicenseImg: formData.driverLicenseImg,
			});

			toast.success(toastMessage.success.updated);
			void driverRefetch();
			void userRefetch();
		} catch (err) {
			toast.error(toastMessage.error.updated);
		}
	};

	const handleUpdateBiker = async (formData: EditBikerSchemaType) => {
		try {
			await api.put(`/users/${id!}`, {
				id,
				cpfImg: formData.cpfImg,
				rgBackImg: formData.rgBackImg,
				rgImg: formData.rgImg,
			});

			toast.success(toastMessage.success.updated);
			void driverRefetch();
			void userRefetch();
		} catch (err) {
			toast.error(toastMessage.error.updated);
		}
	};

	const handleBlock = async (value: boolean) => {
		try {
			await api.put(`/users/${id!}/${value ? 'block' : 'unblock'}`);
			toast.success(toastMessage.success.updated);
			void userRefetch();
		} catch (err) {
			toast.error(toastMessage.error.updated);
		}
	};

	const handleStagingArea = async (value: boolean) => {
		try {
			await api.put(
				`/users/${id!}/${value ? 'set-staging-area' : 'unset-staging-area'}`
			);
			toast.success(toastMessage.success.updated);
			void userRefetch();
		} catch (err) {
			toast.error(toastMessage.error.updated);
		}
	};

	const handleBikerStatus = async (value: boolean) => {
		try {
			await api.put(`/users/${id!}/status`, {
				bikerStatus: value ? 1 : 0,
			});

			void userRefetch();
		} catch (err) {
			toast.error(toastMessage.error.updated);
		}
	};

	const handleWasTrained = async (value: boolean) => {
		try {
			await api.put(`/users/${id!}`, {
				id: value && id,
				trainingDate: value ? dayjs().toISOString() : null,
			});
			toast.success(toastMessage.success.updated);
			void userRefetch();
		} catch (err) {
			toast.error(toastMessage.error.updated);
		}
	};

	useEffect(() => {
		if (user && id) {
			form.reset({
				...user?.data,
				cellphone: phoneMask(user?.data.cellphone),
				code: user?.data.confirmation?.code
			});
			driverForm.reset({
				driverLicense: user?.data?.driverLicense || undefined,
				driverLicenseExpiration:
					user?.data?.driverLicenseExpiration || undefined,
				driverLicenseImg: user?.data?.driverLicenseImg || undefined,
				rg: user?.data?.rg || undefined,
			});
			bikerForm.reset({
				...user?.data,
			});
			tagForm.reset({
				observation: user?.data.observation,
			});
		}
	}, [user, id]);

	if (isLoading) return <Loading />;

	return (
		<div>
			<PageHeader
				title="Edição de usuários"
				description="Gerencie informações e preferências do usuário"
			/>
			<div className="flex flex-col gap-4 lg:flex-row">
				<div className="flex w-full flex-col gap-4 lg:w-3/4">
					<WhiteContainer
						divProps={{
							className: 'w-full',
						}}
						className="flex flex-col gap-4"
					>
						<Form<typeof editUserSchema>
							onSubmit={(data) => update(data)}
							form={form}
						>
							<FormHeader
								title="Dados Pessoais"
								description="Informações pessoais"
							/>
							<div className="flex flex-col gap-4 md:flex-row">
								<div className="md:max-h-[240px] md:w-1/2 md:min-w-[240px] md:max-w-[240px]">
									<PopImageInput
										label="Foto"
										currentImage={avatar?.data.url}
										onImageLoad={(imageName) =>
											form.setValue('avatar', imageName)
										}
									/>
								</div>
								<div className="flex w-full flex-col gap-4">
									<div className="flex flex-col gap-2 lg:flex-row">
										<Input label="Nome" {...form.register('name')} />
										<Input label="Email" {...form.register('email')} />
									</div>
									<div className="flex flex-col gap-2 lg:flex-row">
										<Input
											label="CPF"
											{...form.register('cpf', {
												onChange: (e: any) => {
													e.target.value = cpfMask(e.target.value);
												},
											})}
										/>
										<Input
											label="Celular"
											{...form.register('cellphone', {
												onChange: (e: any) => {
													e.target.value = phoneMask(e.target.value);
												},
											})}
										/>
										<div className="flex w-full flex-col">
											<div className="flex items-center">
												<Label
													label="Verificação"
													variant="primary"
													className="w-auto"
												/>
												{user?.data.confirmation?.confirmed ? (
													<Tooltip message="Código verificado">
														<BsCheck size={22} className="mb-1.5 text-green" />
													</Tooltip>
												) : (
													<Tooltip message="Ainda não verificado">
														<BsExclamation
															size={22}
															className="mb-1.5 text-terracota-500"
														/>
													</Tooltip>
												)}
											</div>
											<Input disabled {...form.register('code')} />
										</div>
									</div>
									<div className="flex w-64 items-center justify-start gap-4 rounded lg:flex-col lg:items-start ">
										<div className=" w-28">
											<Pix />
										</div>
										<Input
											placeholder="Chave PIX"
											inputClassName="border-[#32bcac]"
											{...form.register('pix')}
										/>
									</div>
								</div>
							</div>
							<FormButtons
								onCancel={() => navigate('/usuarios')}
								className="mt-2"
							/>
						</Form>
					</WhiteContainer>

					{driver && (
						<WhiteContainer className="gap-4">
							<div className="flex flex-col justify-between gap-2 md:flex-row md:items-center ">
								<FormHeader
									title="Motorista"
									description="Dados obrigatórios para Motoristas"
								/>
								<div className="flex flex-wrap justify-end gap-2 md:items-center">
									{user?.data.driverIntent &&
										driverSignUp?.data.userStatus === 1 && (
											<span className="w-fit rounded bg-terracota-700 p-1 px-2 text-xs text-white ">
												CHN não cadastrada
											</span>
										)}
									{driverSignUp?.data.userStatus === 2 &&
										user?.data.driverIntent && (
											<span className="w-fit rounded bg-yellow-500 p-1 px-2 text-xs font-semibold text-neutral-0 ">
												CHN aguardando aprovação
											</span>
										)}
									{driverSignUp?.data.userStatus === 3 &&
										user?.data.approved && (
											<span className="w-fit rounded bg-green p-1 px-2 text-xs text-white ">
												CHN aprovada
											</span>
										)}
									{user?.data.driverStatus === 2 && (
										<span className="text-neutra-0 rounded bg-terracota-700 p-1 px-2 text-xs font-semibold text-white">
											CHN reprovada
										</span>
									)}
									{driverSignUp?.data.userStatus === 3 &&
										user?.data.approved &&
										!wasTrained && (
											<span className="w-fit rounded bg-terracota-700 p-1 px-2 text-xs text-white ">
												Sem treinamento
											</span>
										)}
									{driverSignUp?.data.userStatus === 3 &&
										user?.data.approved &&
										wasTrained && (
											<span className="w-fit rounded bg-green p-1 px-2 text-xs text-white ">
												Treinado
											</span>
										)}
									{driverSignUp?.data.vehicleStatus === 1 && (
										<span className="w-fit rounded bg-terracota-700 p-1 px-2 text-xs text-white">
											Veículo não cadastrado
										</span>
									)}
									{driverSignUp?.data.vehicleStatus === 2 && (
										<>
											{driverSignUp?.data?.vehicle?.status === 2 ? (
												<Link
													className="flex w-fit cursor-pointer gap-1 rounded bg-terracota-700 p-1 px-2 text-xs text-white hover:bg-terracota-700/80"
													// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
													to={`/veiculos/${driverSignUp?.data?.vehicle?.id}`}
												>
													Veículo reprovado
													<FiExternalLink size={16} />
												</Link>
											) : (
												<Link
													className="flex w-fit cursor-pointer gap-1 rounded bg-orange p-1 px-2 text-xs text-white hover:bg-orange/80"
													// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
													to={`/veiculos/${driverSignUp?.data?.vehicle?.id}`}
												>
													Veículo aguardando aprovação
													<FiExternalLink size={16} />
												</Link>
											)}
										</>
									)}
									{driverSignUp?.data.vehicleStatus === 3 && (
										<span
											className="flex w-fit cursor-pointer gap-1 rounded bg-green p-1 px-2 text-xs text-white transition-opacity hover:bg-green/80"
											onClick={() => {
												// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												navigate(`/veiculos/${driverSignUp?.data.vehicle.id}`);
											}}
										>
											Veículo aprovado
											<FiExternalLink size={16} />
										</span>
									)}
									{driverSignUp?.data.companyStatus === 1 && (
										<span className="w-fit rounded bg-terracota-700 p-1 px-2 text-xs text-white ">
											Empresa não cadastrada
										</span>
									)}
									{driverSignUp?.data.companyStatus === 2 && (
										<>
											{driverSignUp?.data?.company?.status === 2 ? (
												<Link
													className="flex w-fit cursor-pointer gap-1 rounded bg-terracota-700 p-1 px-2 text-xs text-white hover:bg-terracota-700/80"
													// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
													to={`/empresas/${driverSignUp?.data?.company?.id}`}
												>
													Empresa reprovada
													<FiExternalLink size={16} />
												</Link>
											) : (
												<Link
													className="flex w-fit cursor-pointer gap-1 rounded bg-orange p-1 px-2 text-xs text-white hover:bg-orange/80"
													// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
													to={`/empresas/${driverSignUp?.data?.company?.id}`}
												>
													Empresa aguardando aprovação
													<FiExternalLink size={16} />
												</Link>
											)}
										</>
									)}
									{driverSignUp?.data.companyStatus === 3 && (
										<span
											onClick={() =>
												// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												navigate(`/empresas/${driverSignUp?.data.company?.id}`)
											}
											className="flex w-fit cursor-pointer gap-1 rounded bg-green p-1 px-2 text-xs text-white transition-opacity hover:bg-green/80"
										>
											Empresa aprovada
											<FiExternalLink size={16} />
										</span>
									)}
								</div>
							</div>
							<Form
								form={driverForm}
								onSubmit={handleUpdateDriver}
								className="w-full"
							>
								<div className="flex flex-col gap-2 md:flex-row">
									<div className="md:max-h-[240px] md:w-1/2 md:min-w-[240px] md:max-w-[240px]">
										<PopImageInput
											label="Foto da CNH"
											currentImage={cnh?.data.url}
											onImageLoad={(imageName) =>
												driverForm.setValue('driverLicenseImg', imageName)
											}
										/>
									</div>
									<div className="flex w-full flex-col gap-2">
										<Input
											className="w-full lg:w-1/2"
											label="CNH"
											{...driverForm.register('driverLicense')}
										/>
										<Date
											className="w-full lg:w-1/2"
											label="Vencimento da CNH"
											control={driverForm.control}
											name="driverLicenseExpiration"
										/>
										<Input
											className="w-full lg:w-1/2"
											label="RG"
											{...driverForm.register('rg')}
										/>
									</div>
								</div>
								<Button type="submit" className="mt-8 w-fit">
									Salvar alterações
								</Button>
							</Form>
						</WhiteContainer>
					)}
					{biker && (
						<WhiteContainer className="gap-2 ">
							<div className="flex w-full flex-col gap-2 md:flex-row md:items-center">
								<FormHeader
									title="Biker"
									description="Dados obrigatórios para Bikers"
								/>
								<div className="flex items-center gap-2">
									{driverSignUp?.data.user.cpfImg === null &&
										driverSignUp?.data.user.rgImg === null &&
										biker && (
											<span className="w-fit rounded bg-terracota-700 p-1 text-xs text-white ">
												RG e CPF não cadastrados
											</span>
										)}
									{driverSignUp?.data.user.bikerStatus !== 3 &&
										driverSignUp?.data.user.cpfImg !== null &&
										driverSignUp?.data.user.rgImg !== null &&
										biker && (
											<span className="text-neutra-0 w-fit rounded bg-yellow-500 p-1 text-2xs font-semibold ">
												Aguardando Aprovação
											</span>
										)}
									{driverSignUp?.data.user.bikerStatus === 3 && biker && (
										<span className="text-neutra-0 rounded bg-green p-1 text-2xs font-semibold text-white">
											Biker aprovado
										</span>
									)}
									{driverSignUp?.data.user.bikerStatus === 3 &&
										biker &&
										!wasTrained && (
											<span className="text-neutra-0 rounded bg-terracota-700 p-1 text-2xs font-semibold text-white">
												Sem treinamento
											</span>
										)}
									{driverSignUp?.data.user.bikerStatus === 3 &&
										biker &&
										wasTrained && (
											<span className="text-neutra-0 rounded bg-green p-1 text-2xs font-semibold text-white">
												Treinado
											</span>
										)}
								</div>
							</div>
							<Form
								form={bikerForm}
								onSubmit={handleUpdateBiker}
								className="flex w-full flex-col gap-2"
							>
								<div className="flex w-full flex-col gap-4 md:flex-row">
									<PopImageInput
										label="Foto do RG (frente)"
										currentImage={rg?.data.url}
										onImageLoad={(imageName) =>
											bikerForm.setValue('rgImg', imageName)
										}
									/>

									<PopImageInput
										label="Foto do RG (verso)"
										currentImage={rgBack?.data.url}
										onImageLoad={(imageName) =>
											bikerForm.setValue('rgBackImg', imageName)
										}
									/>

									<PopImageInput
										label="Foto do CPF"
										currentImage={cpf?.data.url}
										onImageLoad={(imageName) =>
											bikerForm.setValue('cpfImg', imageName)
										}
									/>
								</div>
								<Button type="submit" className="mt-2 w-fit">
									Salvar alterações
								</Button>
							</Form>
						</WhiteContainer>
					)}
				</div>
				<div className="w-full lg:w-1/4">
					<div className="sticky top-0 flex w-full flex-col gap-4 ">
						<WhiteContainer
							className={twMerge(
								'flex flex-col gap-4 ring-1 ',
								isBlocked ? 'ring-terracota-500' : 'ring-green'
							)}
						>
							<Form
								className="flex flex-col gap-4"
								form={tagForm}
								onSubmit={(data) => update(data)}
							>
								<span
									className={twMerge(
										'w-min self-end  rounded p-1 text-xs text-white',
										isBlocked ? 'bg-terracota-500' : 'bg-green'
									)}
								>
									{isBlocked ? 'Bloqueado' : 'Desbloqueado'}
								</span>
								{user?.data && (
									<Select
										label="Tipo de usuário"
										options={Object.values(appState.userRoles).map((opt) => ({
											label: opt.label,
											value: opt.value,
										}))}
										defaultValue={{
											// @ts-expect-error
											label: appState.userRoles[user?.data.role.name]?.label,
											value: user?.data.role.id,
										}}
										// eslint-disable-next-line @typescript-eslint/no-misused-promises
										onChange={async (e: any) =>
											await api.put(`/users/${id!}`, {
												roleId: e.value,
											})
										}
									/>
								)}

								<span className="flex items-center justify-between gap-1">
									<SwitchButton
										label="Bloqueado"
										checked={isBlocked}
										onChange={(e) => {
											void handleBlock(e.target.checked);
											// setBlocked(e.target.checked);
										}}
									/>
									<HiLockClosed size={18} />
								</span>
								<hr className="text-neutral-100" />

								<span className="flex items-center justify-between gap-1">
									<SwitchButton
										label="Tela de pendências"
										checked={stagingArea}
										onChange={(e) => {
											void handleStagingArea(e.target.checked);
											// setStagingArea(e.target.checked);
										}}
									/>
									<BsFillPersonXFill size={18} />
								</span>
								<hr className="text-neutral-100" />
								<span className="flex items-center justify-between gap-1">
									<SwitchButton
										label="Realizou treinamento?"
										checked={wasTrained}
										onChange={(e) => {
											void handleWasTrained(e.target.checked);
											// setTrained(!!e.target.checked);
										}}
									/>
									<BsCalendarCheckFill />
								</span>

								<Tags
									entity="Usuário"
									entityToUpdate="users"
									id={id}
									tags={tags?.data || []}
									// @ts-expect-error
									defaultValue={user?.data.tags.map((t: any) => {
										return {
											value: t.id,
											label: t.name,
										};
									})}
								/>
								<Textarea
									label="Observações"
									{...tagForm.register('observation')}
								/>
								{tagForm.formState.isDirty && (
									<Button type="submit" className="mt-2 w-fit">
										Salvar alterações
									</Button>
								)}
							</Form>
						</WhiteContainer>
						<WhiteContainer
							className={twMerge(
								'flex flex-col gap-4 ring-1',

								driverSignUp?.data.user.driverStatus === 2 && 'ring-yellow',
								driverSignUp?.data.user.driverStatus === 1 &&
									!user?.data.approved &&
									'ring-terracota-500',
								!user?.data.driverIntent && 'ring-green',
								driverSignUp?.data.user.driverStatus === 3 && 'ring-green'
							)}
						>
							<div className="flex items-center justify-between gap-4">
								<h3 className="flex items-center gap-2 font-semibold">
									Motorista <BiCar size={18} />
								</h3>
								<div className="flex flex-wrap justify-end gap-1 text-xs">
									{user?.data.driverIntent &&
										driverSignUp?.data.userStatus === 1 && (
											<span className="w-fit rounded bg-terracota-700 p-1 text-2xs font-semibold text-white ">
												CHN não cadastrada
											</span>
										)}
									{driverSignUp?.data.userStatus === 2 &&
										user?.data.driverIntent && (
											<span className="text-neutra-0 rounded bg-yellow-500 p-1 text-2xs font-semibold ">
												CHN aguardando aprovação
											</span>
										)}
									{user?.data.driverStatus === 2 && (
										<span className="text-neutra-0 rounded bg-terracota-700 p-1 text-2xs font-semibold text-white">
											CHN reprovada
										</span>
									)}
									{user?.data.driverStatus === 3 && user?.data.approved && (
										<span className="w-fit rounded bg-green p-1 text-white">
											CHN aprovada
										</span>
									)}
									{driverSignUp?.data.userStatus === 3 &&
										user?.data.approved &&
										!wasTrained && (
											<span className="w-fit rounded bg-terracota-700 p-1 text-xs text-white ">
												Sem treinamento
											</span>
										)}
									{driverSignUp?.data.userStatus === 3 &&
										user?.data.approved &&
										wasTrained && (
											<span className="w-fit rounded bg-green p-1 text-xs text-white ">
												Treinado
											</span>
										)}
								</div>
							</div>
							<hr className="text-neutral-100" />
							<SwitchButton
								label="Cadastro em andamento"
								checked={user?.data?.driverIntent}
								onChange={(e) => {
									void handleDriverStatus(e.target.checked);
								}}
							/>

							<div className="flex items-center gap-2">
								{user?.data.driverStatus === 1 &&
									driverSignUp?.data.userStatus === 2 && (
										<Button
											className="w-fit"
											// eslint-disable-next-line @typescript-eslint/no-misused-promises
											onClick={async () => {
												await api.put(`/users/${id!}/status`, {
													driverStatus: 3,
												});
												void driverRefetch();
												void userRefetch();
											}}
											variant="green"
										>
											Aprovar
										</Button>
									)}

								<Button
									className="w-fit"
									// eslint-disable-next-line @typescript-eslint/no-misused-promises
									onClick={async () => {
										await api.put(`/users/${id!}/status`, {
											driverStatus: 2,
										});
										void driverRefetch();
										void userRefetch();
									}}
									variant="red"
								>
									Rejeitar
								</Button>
							</div>
						</WhiteContainer>
						<WhiteContainer
							className={twMerge(
								'flex flex-col gap-4 ring-1',

								driverSignUp?.data.user.bikerStatus !== 3 && biker
									? 'ring-terracota-500'
									: 'ring-green'
							)}
						>
							<div className="flex items-center justify-between">
								<h3 className="flex items-center gap-2 font-semibold">
									Biker <MdPedalBike size={18} />
								</h3>
								<div className="flex flex-col items-end gap-1">
									{driverSignUp?.data.user.cpfImg === null &&
										driverSignUp?.data.user.rgImg === null &&
										biker && (
											<span className="w-fit rounded bg-terracota-700 p-1 text-xs text-white ">
												RG e CPF não cadastrados
											</span>
										)}
									{driverSignUp?.data.user.bikerStatus !== 3 &&
										driverSignUp?.data.user.cpfImg !== null &&
										driverSignUp?.data.user.rgImg !== null &&
										biker && (
											<span className="text-neutra-0 rounded bg-yellow-500 p-1 text-2xs font-semibold ">
												Aguardando Aprovação
											</span>
										)}
									{driverSignUp?.data.user.bikerStatus === 3 && biker && (
										<span className="text-neutra-0 rounded bg-green p-1 text-2xs font-semibold text-white">
											Biker aprovado
										</span>
									)}
									{driverSignUp?.data.user.bikerStatus === 3 &&
										biker &&
										!wasTrained && (
											<span className="text-neutra-0 rounded bg-terracota-700 p-1 text-2xs font-semibold text-white">
												Sem treinamento
											</span>
										)}
									{driverSignUp?.data.user.bikerStatus === 3 &&
										biker &&
										wasTrained && (
											<span className="text-neutra-0 rounded bg-green p-1 text-2xs font-semibold text-white">
												Treinado
											</span>
										)}
								</div>
							</div>
							<hr className="text-neutral-100" />
							<SwitchButton
								label="Cadastro em andamento"
								checked={biker}
								onChange={(e) => {
									void handleBikerStatus(e.target.checked);
								}}
							/>
							{driverSignUp?.data.user.cpfImg !== null &&
								driverSignUp?.data.user.rgImg !== null &&
								driverSignUp?.data.user.bikerStatus !== 3 &&
								biker && (
									<div className="flex items-center gap-2">
										<Button
											className="w-fit"
											// eslint-disable-next-line @typescript-eslint/no-misused-promises
											onClick={async () => {
												await api.put(`/users/${id!}/status`, {
													bikerStatus: 3,
												});
												void driverRefetch();
												void userRefetch();
											}}
											variant="green"
										>
											Aprovar
										</Button>

										<Button
											className="w-fit"
											// eslint-disable-next-line @typescript-eslint/no-misused-promises
											onClick={async () => {
												await api.put(`/users/${id!}/status`, {
													bikerStatus: 2,
												});
												void driverRefetch();
												void userRefetch();
											}}
											variant="red"
										>
											Rejeitar
										</Button>
									</div>
								)}
						</WhiteContainer>
					</div>
				</div>
			</div>
			{!isFetching && !!user?.data.companies?.length && (
				<WhiteContainer className="mt-4 gap-4">
					<FormHeader
						title="Empresas"
						description={`Empresas em que ${user.data.name} é proprietário, funcionário ou motorista`}
					/>
					<CompaniesTable companies={user?.data.companies} />
				</WhiteContainer>
			)}
		</div>
	);
};
