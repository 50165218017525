import dayjs from 'dayjs';
import 'dayjs/locale/pt'; // carregar sob demanda
import { useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { useQuery } from '@tanstack/react-query';
import { FiltersTab } from '../../../../components/FiltersTab';
import { Date, Select, useZodForm } from '../../../../components/FormElements';
import { PageHeader } from '../../../../components/PageHeader';
import { Table } from '../../../../components/Table/Table';
import { Tooltip } from '../../../../components/Tooltip';

import WhiteContainer from '../../../../components/WhiteContainer/WhiteContainer';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import { SortOptions } from '../../../../services/angular/types/Params';
import { appState } from '../../../../store/appState';
import {
	TransactionsFilterSchema,
	TransactionsFilterSchemaType,
} from './schema/TransactionsFilter';
import { Transaction } from './transaction';
import { Link } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import colors from '../../../../style/colors';

export const WalletSummary = () => {
	const company = useUserState((state) => state.company);
	const [query, setQuery] = useState<TransactionsFilterSchemaType>();

	const form = useZodForm({
		schema: TransactionsFilterSchema,
	});

	const [params, setParams] = useState<{
		page: number;
		take: number;
		sortBy?: SortOptions;
	}>({
		page: 1,
		take: 10,
	});

	const { data, isFetching: fetchingSummary } = useQuery(
		['wallet-summary', company?.id],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/companies/${company?.id}/walletSummary`
			),
		{
			enabled: !!company?.id,
		}
	);

	const { data: balanceData, isFetching: fetchingBalance } = useQuery(
		['balance', company?.id],
		async () => {
			if (!company?.id || !(company.isAdminUser || company.isOwner)) return;
			return await api.get(
				`/companies/${company?.id || ''}/walletSummary/get-balance`
			);
		},
		{
			enabled: !!company?.id,
		}
	);

	const {
		data: transactionsData,
		isLoading,
		isFetching,
	} = useQuery(
		['companyUsers', params, company?.id, query],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/company/${company?.id}/transactions`,
				{
					params: {
						...query,
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
					},
				}
			),
		{
			enabled: !!company?.id,
		}
	);

	return (
		<>
			<PageHeader title="Pedidos" description="Resumo dos fretes pedidos" />

			<div className="mb-4 grid grid-cols-1 grid-rows-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
				<WhiteContainer
					divProps={{
						className: 'w-full min-h-fit',
					}}
					className="h-full border border-yellow-submenu p-3 shadow"
				>
					<div className="mb-2 flex h-full justify-between gap-2 border-b-2 border-dashed border-neutral-200 pb-2 text-left text-sm font-medium">
						Total do mês anterior
						{!fetchingSummary && (
							<span className="text-base font-semibold uppercase">
								{dayjs()
									.locale('pt-br')
									.subtract(1, 'month')
									.format('MMM. YYYY')}
							</span>
						)}
					</div>
					<h1 className="text-right text-3xl font-bold">
						{fetchingSummary ? (
							<div className="w-full transition-all">
								<SkeletonTheme
									baseColor={colors.neutral[100]}
									highlightColor={colors.neutral[50]}
								>
									<Skeleton height={30} className="mb-1" />
								</SkeletonTheme>
							</div>
						) : (
							parseFloat(data?.data.orders.lastMonth.accum).toLocaleString(
								'pt-br',
								{
									style: 'currency',
									currency: 'BRL',
								}
							)
						)}
					</h1>
				</WhiteContainer>
				<WhiteContainer
					divProps={{
						className: 'w-full min-h-fit',
					}}
					className="h-full border border-yellow-submenu p-3 shadow"
				>
					<div className="mb-2 flex h-full justify-between gap-2 border-b-2 border-dashed border-neutral-200 pb-2 text-left text-sm font-medium">
						Total do mês atual
						{!fetchingSummary && (
							<span className="text-base font-semibold uppercase">
								{dayjs().locale('pt-br').format('MMM. YYYY')}
							</span>
						)}
					</div>
					<h1 className="text-right text-3xl font-bold">
						{fetchingSummary ? (
							<div className="w-full transition-all">
								<SkeletonTheme
									baseColor={colors.neutral[100]}
									highlightColor={colors.neutral[50]}
								>
									<Skeleton height={30} className="mb-1" />
								</SkeletonTheme>
							</div>
						) : (
							parseFloat(data?.data.orders.thisMonth.accum).toLocaleString(
								'pt-br',
								{
									style: 'currency',
									currency: 'BRL',
								}
							)
						)}
					</h1>
				</WhiteContainer>
				<WhiteContainer
					divProps={{
						className: 'w-full min-h-fit',
					}}
					className="h-full border border-yellow-submenu p-3 shadow"
				>
					<h1 className="mb-2 h-full border-b-2 border-dashed border-neutral-200 pb-2 text-left text-sm font-medium">
						Total acumulado
					</h1>
					<h1 className="text-right text-3xl font-bold">
						{fetchingSummary ? (
							<div className="w-full transition-all">
								<SkeletonTheme
									baseColor={colors.neutral[100]}
									highlightColor={colors.neutral[50]}
								>
									<Skeleton height={30} className="mb-1" />
								</SkeletonTheme>
							</div>
						) : (
							parseFloat(data?.data.orders.total.accum).toLocaleString(
								'pt-br',
								{
									style: 'currency',
									currency: 'BRL',
								}
							)
						)}
					</h1>
				</WhiteContainer>
				<WhiteContainer
					divProps={{
						className: 'w-full min-h-fit',
					}}
					className="h-full border border-yellow-submenu p-3 shadow"
				>
					<h1 className="mb-2 h-full border-b-2 border-dashed border-neutral-200 pb-2 text-left text-sm font-medium">
						Saldo atual
					</h1>
					<h1 className="text-right text-3xl font-bold text-green">
						{fetchingBalance ? (
							<div className="w-full transition-all">
								<SkeletonTheme
									baseColor={colors.neutral[100]}
									highlightColor={colors.neutral[50]}
								>
									<Skeleton height={30} className="mb-1" />
								</SkeletonTheme>
							</div>
						) : (
							parseFloat(balanceData?.data).toLocaleString('pt-br', {
								style: 'currency',
								currency: 'BRL',
							})
						)}
					</h1>
				</WhiteContainer>
			</div>
			{company?.makeFreights && (
				<>
					<PageHeader
						title="Entregas"
						description="Resumo dos fretes realizados"
					/>
					<div className="grid grid-cols-1 grid-rows-1 gap-4 md:grid-cols-3">
						<WhiteContainer className="h-full border border-yellow-submenu p-3 shadow">
							<div className="mb-2 flex h-full justify-between gap-2 border-b-2 border-dashed border-neutral-200 pb-2 text-left text-sm font-medium">
								Total do mês anterior
								<span className="text-base font-semibold uppercase">
									{dayjs()
										.locale('pt-br')
										.subtract(1, 'month')
										.format('MMM. YYYY')}
								</span>
							</div>
							<h1 className="text-right text-3xl font-bold">
								{fetchingSummary ? (
									<div className="w-full transition-all">
										<SkeletonTheme
											baseColor={colors.neutral[100]}
											highlightColor={colors.neutral[50]}
										>
											<Skeleton height={30} className="mb-1" />
										</SkeletonTheme>
									</div>
								) : (
									parseFloat(
										data?.data.freights.lastMonth.accum
									).toLocaleString('pt-br', {
										style: 'currency',
										currency: 'BRL',
									})
								)}
							</h1>
						</WhiteContainer>
						<WhiteContainer className="h-full border border-yellow-submenu p-3 shadow">
							<div className="mb-2 flex h-full justify-between gap-2 border-b-2 border-dashed border-neutral-200 pb-2 text-left text-sm font-medium">
								Total do mês atual
								<span className="text-base font-semibold uppercase">
									{dayjs().locale('pt-br').format('MMM. YYYY')}
								</span>
							</div>
							<h1 className="text-right text-3xl font-bold">
								{fetchingSummary ? (
									<div className="w-full transition-all">
										<SkeletonTheme
											baseColor={colors.neutral[100]}
											highlightColor={colors.neutral[50]}
										>
											<Skeleton height={30} className="mb-1" />
										</SkeletonTheme>
									</div>
								) : (
									parseFloat(
										data?.data.freights.thisMonth.accum
									).toLocaleString('pt-br', {
										style: 'currency',
										currency: 'BRL',
									})
								)}
							</h1>
						</WhiteContainer>
						<WhiteContainer className="h-full border border-yellow-submenu p-3 shadow">
							<h1 className="mb-2 h-full border-b-2 border-dashed border-neutral-200 pb-2 text-left text-sm font-medium">
								Total acumulado
							</h1>
							<h1 className="text-right text-3xl font-bold">
								{fetchingSummary ? (
									<div className="w-full transition-all">
										<SkeletonTheme
											baseColor={colors.neutral[100]}
											highlightColor={colors.neutral[50]}
										>
											<Skeleton height={30} className="mb-1" />
										</SkeletonTheme>
									</div>
								) : (
									parseFloat(data?.data.freights.total.accum).toLocaleString(
										'pt-br',
										{
											style: 'currency',
											currency: 'BRL',
										}
									)
								)}
							</h1>
						</WhiteContainer>
					</div>
				</>
			)}
			<PageHeader
				title="Transações"
				description="Resumo das transações realizadas"
				className="mt-4"
			/>
			<FiltersTab
				form={form}
				clearFilters={() => {
					form.reset();
					setQuery(undefined);
				}}
				onSubmit={(data) => {
					setQuery(data);
				}}
			>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="initialDate"
					label="De"
				/>
				<Date
					variant="light"
					className="w-full md:w-auto"
					control={form.control}
					name="endDate"
					label="Até"
				/>
				<Select
					variant="light"
					className="w-full md:w-auto md:min-w-[128px]"
					label="Status"
					controller={{
						control: form.control,
						name: 'transactionStatusId',
						defaultValue: null,
					}}
					options={[
						{
							label: 'Realizado',
							value: '2',
						},
						{
							label: 'Pendente',
							value: '4',
						},
						{
							label: 'Bloqueado',
							value: '3',
						},
						{
							label: 'Cancelado',
							value: '1',
						},
					]}
				/>
				<Select
					variant="light"
					className="w-full md:w-auto md:min-w-[128px]"
					label="Tipo"
					controller={{
						control: form.control,
						name: 'paymentTypeId',
						defaultValue: null,
					}}
					options={[
						{
							label: 'Pix',
							value: '6',
						},
						{
							label: 'Boleto',
							value: '1',
						},
						{
							label: 'Cartão de crédito',
							value: '2',
						},
					]}
				/>
			</FiltersTab>
			<WhiteContainer className="mb-4">
				<Table<Transaction>
					triggerPageChange={form.formState.isSubmitting}
					isLoading={isLoading || isFetching}
					data={transactionsData?.data.data || []}
					total={transactionsData?.data?.meta?.pagination.total || 0}
					onParamsChange={(params) => {
						setParams(params);
					}}
					disabledSort={{
						id: true,
						date: true,
						value: true,
						paymentType: true,
						userBuyerName: true,
						transactionStatusId: true,
					}}
					columns={[
						{
							accessor: 'id',
							Header: 'Transação',
							Cell: ({ row }) => {
								const { observation, id } = row.original;
								return (
									<span className="flex text-lg font-bold text-orange">
										{observation ? (
											<Tooltip
												triggerClassName="flex gap-1"
												message={`Motivo: ${observation}`}
											>
												<BsFillInfoCircleFill
													className="text-neutral-800"
													size={16}
												/>{' '}
												#{String(id).padStart(5, '0')}
											</Tooltip>
										) : (
											<>#{String(id).padStart(5, '0')}</>
										)}
									</span>
								);
							},
						},
						{
							accessor: 'date',
							Header: 'Data',
							Cell: ({ value }) => (
								<div className="flex">
									{dayjs(value).locale('pt-br').format('DD/MM/YYYY HH:mm')}
								</div>
							),
						},
						{
							accessor: 'value',
							Header: 'Valor',
							Cell: ({ value }) => (
								<div className="flex w-full text-white">
									{parseFloat(value) < 0 ? (
										<div className="flex w-fit gap-1 rounded text-terracota-500">
											<AiOutlineArrowDown />
											{parseFloat(value).toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL',
											})}
										</div>
									) : (
										<div className="flex w-fit gap-1 rounded text-green">
											<AiOutlineArrowUp />
											{parseFloat(value).toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL',
											})}
										</div>
									)}
								</div>
							),
						},
						{
							accessor: 'paymentType',
							Header: 'Movimentação',
							Cell: ({ row }) => {
								const { orderId, paymentType } = row.original;
								return (
									<div className="flex">
										{orderId ? (
											<Link
												to={`/fretes/detalhes/${orderId}`}
												className="flex rounded bg-neutral-100 px-2 font-bold transition-all hover:bg-neutral-200"
											>
												Frete {orderId}
											</Link>
										) : (
											<div className="flex">{paymentType}</div>
										)}
									</div>
								);
							},
						},
						{
							accessor: 'userBuyerName',
							Header: 'Responsável',
							Cell: ({ value }) => <div className="flex">{value}</div>,
						},
						{
							accessor: 'transactionStatusId',
							Header: 'Status',
							Cell: ({ value }) => (
								<div className="flex">
									{value === appState.transactionStatus.Approved ||
									value === appState.transactionStatus.Confirmed ? (
										<div className="w-fit rounded bg-green px-1.5 py-px text-center text-xs text-white">
											Realizado
										</div>
									) : value === appState.transactionStatus.Pending ||
									  value === appState.transactionStatus.NotProcessed ||
									  value === appState.transactionStatus.Authorized ? (
										<div className="w-fit rounded bg-orange px-1.5 py-px text-center text-xs text-white">
											Pendente
										</div>
									) : value === appState.transactionStatus.Waiting ? (
										<div className="w-fit rounded bg-orange px-1.5 py-px text-center text-xs text-white">
											Aguardando
										</div>
									) : value === appState.transactionStatus.Denied ? (
										<div className="w-fit rounded bg-terracota-500 px-1.5 py-px text-center text-xs text-white">
											Recusado
										</div>
									) : value === appState.transactionStatus.Canceled ? (
										<div className="w-fit rounded bg-terracota-500 px-1.5 py-px text-center text-xs text-white">
											Cancelado
										</div>
									) : value === appState.transactionStatus.Error ? (
										<div className="w-fit rounded bg-terracota-500 px-1.5 py-px text-center text-xs text-white">
											Erro
										</div>
									) : value === appState.transactionStatus.Expired ? (
										<div className="w-fit rounded bg-blue px-1.5 py-px text-center text-xs text-white">
											Expirado
										</div>
									) : (
										<div className="w-fit rounded bg-terracota-500 px-1.5 py-px text-center text-xs text-white">
											Desconhecido
										</div>
									)}
								</div>
							),
						},
					]}
				/>
			</WhiteContainer>
		</>
	);
};
