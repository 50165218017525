import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { BsHexagonFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';
import FormImage from '../../../assets/svg/FormImage';
import Button from '../../../components/Button/Button';
import { Form, useZodForm } from '../../../components/FormElements';
import WhiteContainer from '../../../components/WhiteContainer/WhiteContainer';
import useUserState from '../../../services/angular/angularUserState';
import { api } from '../../../services/angular/axios';
import PersonalData from './components/PersonalData';
import {
	crateUserToken,
	PhoneVerification,
} from './components/PhoneVerification';
import RegisterSelect from './components/RegisterSelect';
import { RegisterSchema } from './schema/RegisterSchema';
import { useApiVersionStore } from '../../../services/angular/phpVersion';

export type RegisterForm = UseFormReturn<z.input<typeof RegisterSchema>>;

const CompanyRegister = () => {
	const setVersion = useApiVersionStore((state) => state.setVersion);
	const [step, setStep] = useState('Perfil');
	const navigate = useNavigate();
	const [token] = useAtom(crateUserToken);
	const [phoneNextButtonDisabled, setPhoneNextButtonDisabled] = useState(true);
	const login = useUserState((state) => state.login);
	const [profileSelected, setProfileSelected] = useState<'client' | 'driver'>();

	const form = useZodForm({
		schema: RegisterSchema,
	});

	useEffect(() => {
		setVersion('v2');

		return () => {
			setVersion('v1');
		};
	}, []);

	const onSubmit = async (data: z.infer<typeof RegisterSchema>) => {
		try {
			const createAccount = await api.post('/store', data, {
				headers: {
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					Authorization: `Bearer ${token}`,
				},
			});
			toast.success('Cadastro realizado com sucesso');
			login(createAccount.data.token);
		} catch (error: any) {
			if (typeof error?.response?.data?.error === 'string') {
				toast.error(error.response?.data.error);
			} else {
				toast.error('Erro ao realizar cadastro');
			}
		}
	};

	return (
		<div className="fixed flex min-h-screen w-full flex-auto flex-shrink-0 flex-col overflow-hidden antialiased">
			<div className="fixed flex h-full w-full flex-col items-center bg-[url('/src/assets/MobileBackground.svg')] bg-cover bg-center bg-no-repeat sm:justify-center md:bg-[url('/src/assets/BackgroundMoreHexs.svg')]">
				<div className="w-full overflow-y-scroll p-4 portrait:pt-[10%] portrait:sm:py-0">
					<div className="flex items-center justify-around">
						<div className="sticky bottom-0 top-0 hidden gap-4 md:flex md:flex-col">
							<div className="text-left text-5xl font-bold text-white ">
								{step === 'Perfil' && (
									<div className="max-w-20">
										Precisamos{' '}
										<span className="text-yellow-500">
											dos <br /> seus
										</span>{' '}
										dados
									</div>
								)}
								{step === 'Celular' && (
									<span className="text-white">
										Precisamos
										<br /> <span className="text-yellow-500">
											confirmar{' '}
										</span>{' '}
										seu celular
									</span>
								)}
								{step === 'Dados pessoais' && (
									<span>
										Só <span className="text-yellow-500">faltam </span>alguns{' '}
										<br /> <span className="text-yellow-500">dados </span>
										básicos
									</span>
								)}
								<div className="text-base text-white">
									Já possui uma conta?{' '}
									<span
										className="cursor-pointer font-bold text-yellow-500 hover:underline"
										onClick={() => navigate('/login')}
									>
										Entre agora mesmo
									</span>
								</div>
							</div>
							<div className="max-w-md">
								<FormImage />
							</div>
						</div>
						<div className="flex flex-col items-center">
							<img src="LogoColored.svg" alt="logo" className="w-40" />
							<div className="mb-4 flex w-2/3 items-center justify-around">
								<div className="relative flex flex-col items-center text-sm text-neutral-900">
									<BsHexagonFill
										className={twMerge(
											'h-8 w-8 rotate-90 md:h-10 md:w-10',
											step === 'Perfil' ? 'text-[#e8b21a]' : 'text-neutral-500'
										)}
									/>
									<p className="absolute mt-1 text-lg text-white">1</p>
									Perfil
								</div>

								<span className="mb-2 h-1 w-1/3 rounded bg-neutral-300" />
								<div className="relative flex flex-col items-center text-sm text-neutral-900">
									<BsHexagonFill
										className={twMerge(
											'h-8 w-8 rotate-90 md:h-10 md:w-10',
											step === 'Celular' ? 'text-[#e8b21a]' : 'text-neutral-500'
										)}
									/>
									<p className="absolute mt-1 text-lg text-white">2</p>
									Celular
								</div>
								<span className="mb-2 h-1 w-1/3 rounded bg-neutral-300" />
								<div className="relative flex flex-col items-center text-sm text-neutral-900">
									<BsHexagonFill
										className={twMerge(
											'h-8 w-8 rotate-90 md:h-10 md:w-10',
											step === 'Dados pessoais'
												? 'text-[#e8b21a]'
												: 'text-neutral-500'
										)}
									/>
									<p className="absolute mt-1 text-lg text-white">3</p>
									Dados
								</div>
							</div>
							<WhiteContainer className="flex flex-col items-center gap-4">
								{step === 'Perfil' && (
									<>
										<RegisterSelect
											profile={profileSelected}
											setProfile={setProfileSelected}
										/>
										<span className="flex w-full items-center justify-between gap-4">
											<Button
												onClick={() => navigate('/login')}
												variant="secondary"
											>
												Cancelar
											</Button>
											<Button
												disabled={!profileSelected}
												variant="primary"
												onClick={() => {
													profileSelected === 'client'
														? setStep('Celular')
														: navigate('/cadastro-entregador');
												}}
											>
												Próximo
											</Button>
										</span>
									</>
								)}
								{step === 'Celular' && (
									<>
										<PhoneVerification
											setPhoneNextButtonDisabled={setPhoneNextButtonDisabled}
										/>
										<span className="flex w-full items-center justify-between gap-4">
											<Button
												onClick={() => setStep('Perfil')}
												variant="secondary"
											>
												Voltar
											</Button>
											<Button
												disabled={phoneNextButtonDisabled}
												variant="primary"
												onClick={() => setStep('Dados pessoais')}
											>
												Próximo
											</Button>
										</span>
									</>
								)}
								{step === 'Dados pessoais' && (
									<Form
										form={form}
										onSubmit={onSubmit}
										autoComplete="off"
										className="flex flex-col items-center gap-4"
									>
										<PersonalData form={form} />
										<span className="flex w-full items-center justify-between gap-4">
											<Button
												onClick={() => setStep('Celular')}
												variant="secondary"
											>
												Voltar
											</Button>
											<Button
												disabled={!form.formState.isValid}
												variant="primary"
												type="submit"
												// eslint-disable-next-line @typescript-eslint/no-misused-promises
												// onClick={form.handleSubmit(onSubmit)}
											>
												Finalizar
											</Button>
										</span>
									</Form>
								)}
							</WhiteContainer>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CompanyRegister;
