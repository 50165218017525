import { toast } from 'react-toastify';
import {
	Editor,
	Form,
	FormButtons,
	Input,
	Select,
	SwitchButton,
	useZodForm,
} from '../../../../../../components/FormElements';
import WhiteContainer from '../../../../../../components/WhiteContainer/WhiteContainer';
import { api } from '../../../../../../services/angular/axios';
import {
	InformativePopupSchema,
	InformativePopupSchemaType,
} from '../schemas/InformativePopupSchema';
import { useAppState } from '../../../../../../store/appState';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { PopImageInput } from './ImageInput';
import { PopInfo } from '../../../../../../services/angular/types/Order';
import { PopInfoModal } from '../../../../Freights/Request/components/PopInfoModal';
import Button from '../../../../../../components/Button/Button';
import { BsFillEyeFill } from 'react-icons/bs';
import { AxiosError } from 'axios';
import { ToastError } from '../../../../../../helpers/errors/ToastError';

interface InformativePopupFormProps {
	setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
	popInfoToEdit: PopInfo | undefined;
	setPopInfoToEdit: React.Dispatch<React.SetStateAction<PopInfo | undefined>>;
	refetch: () => void;
}

const InformativePopupForm = ({
	setOpenForm,
	popInfoToEdit,
	setPopInfoToEdit,
	refetch,
}: InformativePopupFormProps) => {
	const geofence = useAppState((state) => state.geofence);
	const [imageUrl, setImageUrl] = useState<string | undefined>();
	const [openPreview, setOpenPreview] = useState(false);

	const form = useZodForm({
		schema: InformativePopupSchema,
		defaultValues: {
			active: popInfoToEdit?.active,
			categoryIds: popInfoToEdit?.categories.map((category) => ({
				label: category.name,
				value: category.id,
			})),
			confirmButtonText: popInfoToEdit?.confirmButtonText,
			html: popInfoToEdit?.html,
			title: popInfoToEdit?.title,
			typeId: popInfoToEdit?.typeId
				? popInfoToEdit?.typeId === 1
					? { label: 'Clientes', value: 1 }
					: { label: 'Motoristas', value: 2 }
				: undefined,
			geofenceIds: popInfoToEdit?.geofenceId
				? [
						{
							label: popInfoToEdit?.geofence?.name,
							value: popInfoToEdit?.geofenceId,
						},
				  ]
				: null,
		},
	});

	const { register, handleSubmit, watch, control } = form;

	const { data } = useQuery(
		['pop-info-image', popInfoToEdit?.imageUrl],
		async () => {
			return await api.get('/assets', {
				params: {
					imageName: popInfoToEdit?.imageUrl,
				},
			});
		},
		{
			enabled: !!popInfoToEdit?.imageUrl,
		}
	);

	const { data: vehicleCategories, refetch: refetchVehicle } = useQuery(
		['vehicle-category', watch('geofenceIds')],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/categories`,
				{
					params: {
						geofenceId: watch('geofenceIds')
							?.map((geofence) => String(geofence.value))
							?.join(','),
					},
				}
			)
	);

	useEffect(() => {
		void refetchVehicle();
	}, [watch('geofenceIds')]);

	const handlePopSubmit = async (data: InformativePopupSchemaType) => {
		popInfoToEdit
			? updatePop({
					...data,
					id: popInfoToEdit.id,
			  })
			: createPop(data);
	};

	const { mutate: createPop } = useMutation(
		async (data: InformativePopupSchemaType) => {
			await api.post('/pop-info', {
				...data,
				imageUrl,
			});
		},
		{
			onSuccess: () => {
				refetch();
				setOpenForm(false);
				setPopInfoToEdit(undefined);
			},
			onError: (error: AxiosError<any>) => {
				ToastError(error);
			},
		}
	);

	const { mutate: updatePop } = useMutation(
		async ({
			id,
			...data
		}: InformativePopupSchemaType & {
			id: number;
		}) => {
			await api.put(`/pop-info/${id}`, {
				...data,
				imageUrl,
			});
		},
		{
			onSuccess: () => {
				refetch();
				setOpenForm(false);
				setPopInfoToEdit(undefined);
			},
			onError: (error: AxiosError<any>) => {
				if (typeof error?.response?.data?.error === 'string') {
					toast.error(error.response?.data.error);
				} else {
					toast.error('Erro ao atualizar pop informativo');
				}
			},
		}
	);

	return (
		<>
			<Form form={form} onSubmit={handleSubmit(handlePopSubmit)}>
				<WhiteContainer className="gap-4">
					<div className="flex w-full flex-col gap-4 md:flex-row">
						<div className="flex w-full flex-row flex-wrap gap-4">
							<div className="flex h-12 w-full items-start">
								<SwitchButton label="Ativo" isFlexCol {...register('active')} />
							</div>
							<Select
								label="Geofence"
								className="h-fit w-full min-w-[192px] md:w-1/3"
								controller={{
									control,
									name: 'geofenceIds',
								}}
								isMulti
								options={geofence?.map((state) => ({
									label: state.name,
									value: state.id,
								}))}
							/>
							<Select
								label="Mostrar para"
								className="h-fit w-full min-w-[192px] md:w-1/3"
								controller={{
									control,
									name: 'typeId',
								}}
								options={[
									{ label: 'Motoristas', value: 2 },
									{ label: 'Clientes', value: 1 },
								]}
							/>
							<Input className="h-fit" label="Título" {...register('title')} />
							<Input
								className="h-fit"
								label="Texto do botão de confirmar"
								{...register('confirmButtonText')}
							/>
							{watch('typeId')?.value === 2 && (
								<Select
									label="Categoria de veículo"
									className=""
									isMulti
									controller={{
										control,
										name: 'categoryIds',
									}}
									options={vehicleCategories?.data.map(
										(category: { name: string; id: number }) => ({
											label: category.name,
											value: category.id,
										})
									)}
								/>
							)}
						</div>
						<div>
							<PopImageInput
								onImageLoad={(url) => {
									setImageUrl(url);
								}}
								currentImage={data?.data?.url}
								label="Imagem"
								className="mb-4 h-48 w-full md:mb-0 md:h-80 md:w-80"
							/>
						</div>
					</div>

					<div className="flex w-full flex-col gap-2">
						<Editor label="Texto principal" name="html" control={control} />
					</div>

					<div className="flex justify-end gap-2">
						<Button
							className="w-auto"
							onClick={() => {
								setOpenPreview(true);
							}}
						>
							<BsFillEyeFill size={22} />
						</Button>
						<FormButtons
							// eslint-disable-next-line @typescript-eslint/no-misused-promises
							onSave={handleSubmit(handlePopSubmit)}
							onCancel={() => {
								form.reset();
								setOpenForm(false);
								setPopInfoToEdit(undefined);
							}}
						/>
					</div>
					<PopInfoModal
						open={openPreview}
						setOpen={setOpenPreview}
						// @ts-expect-error
						info={{
							...popInfoToEdit,
							...form.getValues(),
							imageUrl: imageUrl || popInfoToEdit?.imageUrl,
						}}
					/>
				</WhiteContainer>
			</Form>
		</>
	);
};

export default InformativePopupForm;
