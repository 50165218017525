import { useMutation } from '@tanstack/react-query';
import { useCountDown } from '../../../../hooks/useCountDown';
import { useApiVersionStore } from '../../../../services/angular/phpVersion';
import { api } from '../../../../services/angular/axios';
import { removePhoneMask } from '../../../../helpers/mask/phoneMask';
import { useInviteCampaignStore } from '../inviteCampaignStore';
import { InputCode, useZodForm } from '../../../../components/FormElements';
import { z } from 'zod';
import Button from '../../../../components/Button/Button';
import { Controller } from 'react-hook-form';
import { useEffect } from 'react';
import { ToastError } from '../../../../helpers/errors/ToastError';

interface SendCodeResponse {
	id: number;
	cellphone: string;
	cpf: string | null;
}

interface ConfirmResponse {
	token: string;
}

const schema = z.object({
	code: z.string().min(4),
});

type Form = z.infer<typeof schema>;

export const PhoneValidationStep = () => {
	const setVersion = useApiVersionStore((state) => state.setVersion);
	const setStep = useInviteCampaignStore((state) => state.setStep);
	const formState = useInviteCampaignStore((state) => state.formState);
	const upsertForm = useInviteCampaignStore((state) => state.upsertForm);

	const form = useZodForm({
		schema,
	});

	const { mutate: confirmCode, isLoading } = useMutation({
		mutationFn: async ({
			code,
			id,
		}: Form & {
			id: number;
		}) => {
			setVersion('v2');

			return await api.post<ConfirmResponse>(`/confirm`, {
				code,
				id,
			});
		},
		onSuccess: ({ data }) => {
			if (formState.step !== 'phoneValidation') return;

			upsertForm({
				...formState,
				...form.getValues(),
				step: 'info',
				token: data.token,
			});
		},
		onError: (error) => {
			ToastError(error);
		},
		onSettled: () => {
			setVersion('v1');
		},
	});

	useEffect(() => {
		if (formState.step !== 'phoneValidation') return;

		if (form.formState.isValid) void onSubmit();
	}, [form.formState.isValid]);

	if (formState.step !== 'phoneValidation') return null;

	const onSubmit = form.handleSubmit(({ code }) => {
		confirmCode({
			code,
			id: formState.userId,
		});
	});

	return (
		<div className="flex flex-col gap-6">
			<Controller
				control={form.control}
				name="code"
				render={({ field }) => (
					<InputCode
						value={field.value}
						onChange={field.onChange}
						autoFocus
						length={4}
					/>
				)}
			/>
			<div className="flex justify-center">
				<CountDown cellphone={formState.cellphone} />
			</div>
			<div>
				<Button
					type="submit"
					disabled={!form.formState.isValid}
					// eslint-disable-next-line @typescript-eslint/no-misused-promises
					onClick={onSubmit}
					loading={isLoading}
				>
					Avançar
				</Button>
				<Button
					className="mt-2"
					onClick={() => setStep('phone')}
					variant="secondary"
				>
					Voltar
				</Button>
			</div>
		</div>
	);
};

const CountDown = ({ cellphone }: { cellphone: string }) => {
	const { seconds, setSeconds } = useCountDown(60);
	const setVersion = useApiVersionStore((state) => state.setVersion);

	const { mutate: sendCode } = useMutation({
		mutationFn: async ({ cellphone }: { cellphone: string }) => {
			setVersion('v2');

			return await api.post<SendCodeResponse>(`/register`, {
				cellphone: removePhoneMask(cellphone),
				bikerStatus: 0,
				clientStatus: 0,
				driverStatus: 1,
			});
		},
		onSuccess: () => {
			setSeconds(60);
		},
		onSettled: () => {
			setVersion('v1');
		},
	});

	return (
		<>
			{seconds ? (
				<span className="text-neutral-0">
					Reenviar o código SMS em {seconds} segundos
				</span>
			) : (
				<button
					className="text-neutral-0 hover:underline"
					onClick={() => {
						sendCode({ cellphone });
					}}
				>
					Reenviar o código SMS
				</button>
			)}
		</>
	);
};
