import React, {
	useRef,
	ChangeEvent,
	ComponentPropsWithoutRef,
	useState,
} from 'react';
import { twMerge } from 'tailwind-merge';
import Bee from '../../assets/svg/Bee';
import { Label } from '../FormElements';
import { TbEye, TbPhotoEdit } from 'react-icons/tb';
import { Modal } from '../Modal';
interface ImagePickerProps extends ComponentPropsWithoutRef<'input'> {
	src?: string;
	rounded?: boolean;
	topLabel?: React.ReactNode;
	bottomLabel?: React.ReactNode;
	editable?: boolean;
	openPreview?: boolean;
	onImagePick?: (event: ChangeEvent<HTMLInputElement>) => void;
	isError?: boolean;
}
export const ImagePicker = ({
	src,
	topLabel,
	bottomLabel,
	rounded,
	onImagePick,
	className,
	editable = true,
	openPreview = true,
	isError,
	...props
}: ImagePickerProps) => {
	const [preview, setPreview] = useState<string | undefined>(src);
	const [open, setOpen] = useState(false);
	const inputRef = useRef<HTMLInputElement>(null);

	return (
		<span className="w-fit">
			{typeof topLabel === 'string' ? (
				<Label label={topLabel} variant="primary" />
			) : (
				<>{topLabel}</>
			)}
			<div
				onClick={() => {
					editable && !preview && !src && inputRef?.current?.click();
				}}
				className={twMerge(
					'relative flex h-40 w-40 items-center justify-center border-8 border-double border-white bg-neutral-100',
					isError && 'ring ring-inset ring-terracota-500',
					!preview && 'cursor-pointer',
					!editable && 'cursor-default',
					rounded ? 'rounded-full' : 'rounded-xl',
					className
				)}
			>
				{editable && (
					<input
						className="hidden h-full w-full"
						type="file"
						accept="image/*"
						ref={inputRef}
						onChange={(event) => {
							onImagePick?.(event);
							const image = event.target.files?.item(0);
							image && setPreview(URL.createObjectURL(image));
						}}
						{...props}
					/>
				)}
				{src || preview ? (
					<>
						<img
							className={twMerge(
								'h-full w-full object-cover object-center',
								rounded ? 'rounded-full' : 'rounded'
							)}
							src={preview ?? src}
							alt="image"
						/>
						{openPreview && (
							<button
								type="button"
								className={
									'absolute bottom-1.5 left-1.5 flex flex-row items-center gap-1 rounded-full border border-neutral-100 bg-neutral-0 p-1 text-neutral-100'
								}
								onClick={() => setOpen(true)}
							>
								<TbEye size={18} />
							</button>
						)}
						{editable && (
							<button
								type="button"
								className={
									'absolute bottom-1.5 right-1.5 flex flex-row items-center gap-1 rounded-full border border-neutral-100 bg-neutral-0 p-1 text-neutral-100'
								}
								onClick={() => {
									inputRef?.current?.click();
								}}
							>
								<TbPhotoEdit size={18} />
							</button>
						)}
					</>
				) : (
					<div className="w-4/5 text-white">
						<Bee />
					</div>
				)}
				<Modal
					className="max-h-screen max-w-full lg:w-max"
					open={open}
					setOpen={setOpen}
					headless
				>
					<img
						className="h-full max-h-[94vh] w-full max-w-[94vw] object-contain object-center"
						src={preview ?? src}
						alt="image"
					/>
				</Modal>
			</div>
			{typeof bottomLabel === 'string' ? (
				<Label label={bottomLabel} variant="primary" />
			) : (
				<>{bottomLabel}</>
			)}
		</span>
	);
};
