import React from 'react';
import { SvgProps } from './SvgPropsInterface';

// import { Container } from './styles';

const PlusIcon: React.FC<SvgProps> = ({
	color = '#393939',
	size,
	stroke = '3.33333',
}) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12 5V19"
				stroke="currentColor"
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 12H19"
				stroke="currentColor"
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default PlusIcon;
