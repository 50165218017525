import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { TbBuilding, TbTruck } from 'react-icons/tb';
import { api } from '../../../services/angular/axios';
import { Avatar } from '../../Avatar';
import { Table, TableActions } from '../../Table/Table';
import { Tooltip } from '../../Tooltip';
import { CompanyModel } from '../../../services/angular/angularUserState';
import { twMerge } from 'tailwind-merge';

interface CompanyProps {
	companies: CompanyModel[];
}

export const CompaniesTable = ({ companies = [] }: CompanyProps) => {
	return (
		<Table<CompanyModel>
			data={companies || []}
			total={companies.length || 0}
			sort={false}
			columns={[
				{
					Header: '',
					accessor: 'avatarUrl',
					width: '0%',
					Cell: ({ row }) => {
						const { avatarUrl, tradingName } = row.original;
						const { data: avatar } = useQuery(
							['avatar', avatarUrl],
							async () =>
								await api.get(
									avatarUrl?.substring(avatarUrl.indexOf('/companies'))
								),
							{
								enabled: !!avatarUrl,
							}
						);
						return (
							<Avatar
								avatarProps={{ className: 'w-8 h-8' }}
								name={tradingName}
								src={avatar?.data.url}
							/>
						);
					},
				},
				{
					Header: '',
					accessor: 'requestFreights',
					width: '0%',
					Cell: ({ row }) => {
						const { makeFreights, requestFreights } = row.original;
						return (
							<div className="flex justify-center gap-1 text-orange">
								{requestFreights && (
									<Tooltip message="Empresa">
										<TbBuilding size={20} />
									</Tooltip>
								)}
								{makeFreights && (
									<Tooltip message="Fretista">
										<TbTruck size={20} />
									</Tooltip>
								)}
							</div>
						);
					},
				},
				{
					Header: 'Empresa',
					accessor: 'tradingName',
					Cell: ({ row }) => {
						const { tradingName, companyName } = row.original;
						return (
							<div className="flex flex-col items-center justify-center gap-1">
								{tradingName || companyName}
							</div>
						);
					},
				},
				{
					Header: 'CNPJ',
					accessor: 'cnpj',
				},
				{
					Header: 'Perfil na empresa',
					accessor: 'ownerId',
					Cell: ({ row }) => {
						const { isAdminUser, isDriver, isOwner, isUser } = row.original;

						return (
							<div className="flex flex-wrap items-center justify-center gap-2">
								{isAdminUser && (
									<span className="rounded bg-neutral-100 px-1 text-xs text-neutral-900 ring-1 ring-neutral-200">
										Usuário administrador
									</span>
								)}
								{isDriver && (
									<span className="rounded bg-neutral-100 px-1 text-xs text-neutral-900 ring-1 ring-neutral-200">
										Fretista
									</span>
								)}
								{isOwner && (
									<span className="rounded bg-neutral-100 px-1 text-xs text-neutral-900 ring-1 ring-neutral-200">
										Proprietário
									</span>
								)}
								{isUser && (
									<span className="rounded bg-neutral-100 px-1 text-xs text-neutral-900 ring-1 ring-neutral-200">
										Usuário
									</span>
								)}
							</div>
						);
					},
				},
				{
					Header: 'Telefone',
					accessor: 'cellphone',
					Cell: ({ row }) => {
						const { cellphone } = row.original;
						return <div className="flex justify-center">{cellphone}</div>;
					},
				},
				{
					Header: 'Região',
					accessor: 'city',
					Cell: ({ row }) => {
						const { city } = row.original;
						return <div className="flex justify-center">{city}</div>;
					},
				},
				{
					Header: 'Criado em',
					accessor: 'created_at',
					Cell: ({ value }) => (
						<span className="flex justify-center">
							{dayjs(value).format('DD/MM/YYYY HH:mm')}
						</span>
					),
				},
				{
					Header: 'Atualizado em',
					accessor: 'updated_at',
					Cell: ({ value }) => (
						<span className="flex justify-center">
							{dayjs(value).format('DD/MM/YYYY HH:mm')}
						</span>
					),
				},
				{
					Header: 'Status',
					accessor: 'status',
					Cell: ({ row }) => {
						const { status } = row.original;

						const statusObj =
							status === 1
								? {
										message: `Aguardando aprovação`,
										className: 'bg-orange',
								  }
								: status === 2
								? {
										message: `Rejeitado`,
										className: 'bg-terracota-500',
								  }
								: {
										message: `Aprovado`,
										className: 'bg-green',
								  };

						return (
							<div
								className={twMerge(
									'w-min rounded px-1.5 py-1 text-center text-xs leading-3 text-white',
									statusObj.className
								)}
							>
								{statusObj.message}
							</div>
						);
					},
				},
				{
					accessor: 'active',
					Header: 'Ações',
					width: '0%',
					Cell: ({ row }) => (
						<TableActions
							row={row}
							actions={[
								{
									icon: 'edit',
									label: 'Editar',
									href: `/empresas/${row.original.id}`,
								},
							]}
						/>
					),
				},
			]}
			pagination={false}
		/>
	);
};
