export default {
	neutral: {
		0: '#333333',
		900: '#393939',
		800: '#4B4B4B',
		700: '#5E5E5E',
		600: '#727272',
		500: '#868686',
		400: '#9B9B9B',
		300: '#B0B0B0',
		200: '#C6C6C6',
		100: '#DDDDDD',
		50: '#F3F3F3',
		1: '#ffffff',
	},
	primary: {
		0: '#41B883',
		900: '#00471D',
		800: '#005B2E',
		700: '#006F40',
		600: '#008453',
		500: '#149966',
		400: '#36AF7B',
		300: '#50C58F',
		200: '#68DCA4',
		100: '#7FF3BA',
		50: '#96FFD0',
	},
	secondary: {
		0: '#31475E',
		900: '#243B51',
		800: '#374D65',
		700: '#4B6078',
		600: '#5E748D',
		500: '#7388A2',
		400: '#879DB8',
		300: '#9DB3CE',
		200: '#B2C9E4',
		100: '#C9DFFB',
		50: '#DFF6FF',
	},
	terracota: {
		0: '#CA3214',
		900: '#870000',
		800: '#9F0000',
		700: '#B81F05',
		600: '#D23A1A',
		500: '#EC512E',
		400: '#FF6841',
		300: '#FF7E55',
		200: '#FF9469',
		100: '#FFAB7E',
		50: '#FFC293',
	},
	purple: {
		0: '#9C2BAD',
		900: '#6D0080',
		800: '#830495',
		700: '#9928AA',
		600: '#B040C1',
		500: '#C757D7',
		400: '#DF6DEE',
		300: '#F783FF',
		200: '#FF9AFF',
		100: '#FFB0FF',
		50: '#FFC7FF',
	},
	red: '#B80000',
	yellow: '#FCF366',
	'yellow-secondary': '#F2ED7A',
	'yellow-submenu': '#E6D651',
	white: '#FFFFFF',
	black: '#000000',
	green: '#38A238',
	blue: '#1671E4',
	orange: '#E97E00',
};
