import { z } from 'zod';
import { ErrorsMessageHelper } from '../../../../../../helpers/errors/ErrorsMessageHelper';

export const InformativePopupSchema = z.object({
	imageUrl: z.undefined(),
	title: z
		.string()
		.min(1, { message: ErrorsMessageHelper.required })
		.max(100, { message: ErrorsMessageHelper.max(100) }),
	confirmButtonText: z
		.string()
		.min(1, { message: ErrorsMessageHelper.required })
		.max(50, { message: ErrorsMessageHelper.max(50) }),
	active: z.boolean().default(false),
	geofenceIds: z
		.array(
			z.object({
				value: z.number().nullable(),
				label: z.string().optional(),
			})
		)
		.transform((value) => value.map((item) => item.value))
		.nullable()
		.default(null),
	categoryIds: z
		.array(
			z.object({
				value: z.number(),
				label: z.string(),
			})
		)
		.transform((value) => value.map((item) => item.value))
		.optional()
		.nullable(),
	typeId: z
		.object({
			value: z.number(),
			label: z.string(),
		})
		.transform((value) => value.value),
	html: z
		.string({ required_error: ErrorsMessageHelper.required })
		.min(1, ErrorsMessageHelper.required)
		.refine((value) => {
			if (value === '<p><br></p>' || value === '<p></p>\n') return false;

			return true;
		}, ErrorsMessageHelper.required),
});

export type InformativePopupSchemaType = z.infer<typeof InformativePopupSchema>;
