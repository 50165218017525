import React, { ComponentProps, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { ErrorTooltip } from '../Tooltip/ErrorTooltip';
import { InfoTooltip } from '../Tooltip/InfoTooltip';
import { Label } from './Label';
import { variants, Variants } from './variants';

interface InputProps extends ComponentProps<'input'> {
	label?: string;
	infoMessage?: string;
	variant?: Variants;
	errorMessage?: string;
	inputClassName?: string;
	inputIcon?: React.ReactNode;
	leftInputIcon?: React.ReactNode;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
	{
		label,
		infoMessage,
		className,
		inputClassName,
		inputIcon,
		leftInputIcon,
		variant = 'primary',
		errorMessage,
		disabled,
		maxLength = 100,
		...props
	},
	ref
) {
	return (
		<div className={twMerge('w-full', className)}>
			<div className="flex items-center">
				{label != null && (
					<div className="flex gap-2 text-neutral-700">
						<Label label={label} variant={variant} />
						{infoMessage && <InfoTooltip message={infoMessage} />}
					</div>
				)}
			</div>

			<div
				className={twMerge(
					`${disabled ? 'bg-neutral-100' : 'bg-white'} ${
						variants[variant].input
					} relative flex w-full rounded border border-neutral-100 text-sm text-neutral-900 placeholder-neutral-500 outline-2 -outline-offset-2 focus-within:outline`,
					inputClassName
				)}
			>
				{leftInputIcon && (
					<div className="mr-1 flex select-none items-center">
						{leftInputIcon}
					</div>
				)}
				<input
					className={`${
						disabled ? 'bg-neutral-100' : 'bg-white'
					} bg-transparent w-full focus:outline-none `}
					ref={ref}
					disabled={disabled}
					maxLength={maxLength}
					{...props}
				/>
				{errorMessage && <ErrorTooltip message={errorMessage} />}
				{inputIcon && (
					<div className="flex select-none items-center">{inputIcon}</div>
				)}
			</div>
		</div>
	);
});
