export const cnpjMask = (value?: string): string => {
	if (!value) return '';
	return value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1/$2')
		.replace(/(\d{4})(\d)/, '$1-$2')
		.replace(/(-\d{2})\d$/, '$1');
};

export const removeCnpjMask = (value: string | undefined): string => {
	if (!value) return '';
	return value.replace(/\D/g, '');
};
