import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FaMapMarkerAlt, FaEdit } from 'react-icons/fa';
import { TiThMenu } from 'react-icons/ti';
import { twMerge } from 'tailwind-merge';
import { RequestFreightLocation } from '../../schemas/RequestFreight';
import { BiTrash } from 'react-icons/bi';
// @ts-expect-error
import { ReactComponent as MapMarker } from '../../../../../../assets/marker.svg';

interface SortableItemProps {
	className?: string;
	id: string | number;
	index: number;
	location: RequestFreightLocation;
	onActionsClick: (action: {
		type: 'editInstruction' | 'editMap' | 'delete';
		locationId: string | number;
	}) => void;
}

export function SortableItem({
	id,
	index,
	location,
	className,
	onActionsClick,
}: SortableItemProps) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({
		id,
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<div
			className={twMerge(
				'select-none break-words rounded bg-white ring-1 ring-neutral-200 hover:ring-neutral-500 shadow-md cursor-move touch-none transition-colors duration-200 ease-in-out',
				isDragging && 'bg-neutral-200 opacity-50',
				className
			)}
			ref={setNodeRef}
			style={style}
		>
			<div
				className={twMerge(
					'flex h-full items-center justify-between gap-2 p-1'
				)}
			>			
				<PointDetailsDropdown
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					location={location}
					onActionsClick={onActionsClick}
				>
					<div className="flex items-center justify-center gap-1 ">
						<div className="relative flex items-center justify-center">
							<span className="text-[#95E8F0]">
								<MapMarker width={34} height={34} />
							</span>
							<span className="absolute bottom-2 font-medium text-black">
								{index + 1}
							</span>
						</div>
						<div className="flex flex-col text-left">
							<p className="font-bold">{location?.name}</p>
							<p className="text-sm">{location?.address}</p>
						</div>
					</div>
				</PointDetailsDropdown>
				<div className="flex items-center justify-center ">

					<div
						className="h-5 w-5 cursor-move touch-none text-neutral-500 hover:text-neutral-800"
						{...attributes}
						{...listeners}
					>
						<TiThMenu size={20} />
					</div>
				</div>
			</div>
		</div>
	);
}

interface DetailsDropdownProps {
	children?: React.ReactNode;
	location: RequestFreightLocation;
	onActionsClick: (action: {
		type: 'editInstruction' | 'editMap' | 'delete';
		locationId: string | number;
	}) => void;
}

export const PointDetailsDropdown = ({
	children,
	onActionsClick,
	location,
}: DetailsDropdownProps) => {
	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger className="focus:outline-none">
				{children}
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content className="w-auto max-w-md rounded bg-white p-6 text-neutral-0 shadow-md shadow-neutral-500 md:mr-4 md:p-4">
					<div className="flex flex-col">
						<div className="text-lg font-bold text-neutral-0">
							{location?.name}
						</div>
						<DropdownMenu.Item
							className="flex cursor-pointer items-center gap-2 rounded px-2 py-1 hover:bg-neutral-200"
							onClick={() => {
								onActionsClick({
									type: 'delete',
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									locationId: location.id!,
								});
							}}
						>
							<BiTrash size={20} />
							Remover
						</DropdownMenu.Item>

						<DropdownMenu.Item
							className="flex cursor-pointer items-center gap-2 rounded px-2 py-1 hover:bg-neutral-200"
							onClick={() => {
								onActionsClick({
									type: 'editMap',
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									locationId: location.id!,
								});
							}}
						>
							<FaMapMarkerAlt size={20} />
							Ajustar localização
						</DropdownMenu.Item>

						<DropdownMenu.Item
							className="flex cursor-pointer items-center gap-2 rounded px-2 py-1 hover:bg-neutral-200"
							onClick={() => {
								onActionsClick({
									type: 'editInstruction',
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									locationId: location.id!,
								});
							}}
						>
							<FaEdit size={20} />
							Ajustar instruções
						</DropdownMenu.Item>
					</div>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};
