import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const BuildingIcon: React.FC<SvgProps> = ({ size, stroke = '3.33333' }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 101 100"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M25.5 91.6668V16.6668C25.5 15.5418 25.5 14.3752 25.7917 13.2502C26.0467 12.1278 26.6114 11.0994 27.4216 10.2819C28.2317 9.4643 29.255 8.89034 30.375 8.62516C31.5833 8.3335 36.875 8.3335 38 8.3335H67.1667C68.2917 8.3335 69.4583 8.3335 70.5833 8.62516C71.7057 8.88019 72.7341 9.44487 73.5516 10.2551C74.3692 11.0652 74.9432 12.0885 75.2083 13.2085C75.5 14.3752 75.5 15.5418 75.5 16.6668V91.6668H25.5Z"
				stroke="currentColor"
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.83348 58.3333V83.3333C8.83348 87.9167 12.5835 91.6667 17.1668 91.6667H25.5001V50H17.1668C16.0418 50 14.8751 50 13.7501 50.2917C12.6251 50.5833 11.5835 51.125 10.7501 51.9583C9.95848 52.75 9.41682 53.7917 9.12515 54.9167C8.88994 56.0395 8.792 57.1868 8.83348 58.3333Z"
				stroke="currentColor"
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M87.25 37.7917C86.1271 37.5564 84.9798 37.4585 83.8333 37.5H75.5V91.6667H83.8333C86.0435 91.6667 88.1631 90.7887 89.7259 89.2259C91.2887 87.6631 92.1667 85.5435 92.1667 83.3333V45.8333C92.1667 44.6667 92.1667 43.5417 91.875 42.4167C91.5833 41.2917 91.0417 40.25 90.2083 39.4167C89.4167 38.625 88.375 38.0833 87.25 37.7917V37.7917Z"
				stroke="currentColor"
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M42.1665 25H58.8332"
				stroke="currentColor"
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M42.1665 41.667H58.8332"
				stroke="currentColor"
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M42.1665 58.3335H58.8332"
				stroke="currentColor"
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M42.1665 75H58.8332"
				stroke="currentColor"
				strokeWidth={stroke}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default BuildingIcon;
