import React, { useEffect, useRef, useState } from 'react';
import GeoMap, { Path } from '../components/GeoMap';
import { toast } from 'react-toastify';
import {
	GeofenceCenterOfBoundsParser,
	GeofenceCoordinatesParser,
} from '../schemas/GeofenceCoordinatesParser';
import { useAlert } from '../../../../contexts/AlertContext';
import {
	Form,
	FormButtons,
	Input,
	Select,
	useZodForm,
} from '../../../../components/FormElements';
import { isTouched } from '../../../../helpers/isTouched';
import { api } from '../../../../services/angular/axios';
import { STATES } from '../../../../helpers/states';
import { Geofence } from '../../../../store/appState';
import { z } from 'zod';
import { zodMessage } from '../../../../services/reactQuery/helper/zodMessage';

interface GeofenceFormProps {
	geofence?: Geofence;
	closeForm: () => void;
	refetch: () => void;
}

export const GeofenceForm: React.FC<GeofenceFormProps> = ({
	geofence,
	closeForm,
	refetch,
}) => {
	const alert = useAlert();
	const [paths, setPaths] = useState<Path[]>(
		GeofenceCoordinatesParser.parse(geofence?.coordinates) || []
	);
	const [center, setCenter] = useState<Path | undefined>(
		GeofenceCenterOfBoundsParser.parse(geofence?.centerOfBounds) || {
			lat: 0,
			lng: 0,
		}
	);

	const form = useZodForm({
		schema: MutateGeofenceSchema,
		defaultValues: {
			name: geofence?.name,
			description: geofence?.description,
			state: {
				label: geofence?.state.abbr,
				value: String(geofence?.state.id),
			},
		},
	});

	const {
		formState: { errors, isSubmitting, touchedFields },
		register,
		control,
		setValue,
	} = form;

	register('id', { value: geofence?.id });
	register('coordinates', { value: paths });
	register('centerOfBounds', { value: center });

	const isFirstRender = useRef(true);

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
		} else {
			setValue('id', geofence?.id);
			setValue('coordinates', paths, {
				shouldTouch: true,
			});
			setValue('centerOfBounds', center as Path, {
				shouldTouch: true,
			});
		}
	}, [geofence?.id, paths, center]);

	useEffect(() => {
		if (errors.coordinates) toast.warn(errors.coordinates.message);
	}, [errors.coordinates]);

	const handleOnSubmit = async (
		data: MutateGeofenceOutputType
	): Promise<void> => {
		if (geofence?.id) {
			try {
				await api.put(`/geofence/${geofence.id}`, data);
				closeForm();
				refetch();
				toast.success('Geofence atualizada com sucesso!');
			} catch (err) {
				toast.error('Erro ao atualizar Geofence!');
			}
		} else {
			try {
				await api.post('/geofence', data);
				closeForm();
				refetch();
				toast.success('Geofence criada com sucesso!');
			} catch (err) {
				toast.error('Erro ao criar Geofence!');
			}
		}
	};

	const isTouch = isTouched(touchedFields);

	return (
		<div className="w-full">
			<Form
				form={form}
				onSubmit={handleOnSubmit}
				className="flex flex-wrap items-end gap-2"
			>
				<Select
					label="Estado"
					className="md:w-56 md:min-w-[224px]"
					controller={{
						control,
						name: 'state',
					}}
					options={STATES}
					errorMessage={errors.state?.message}
				/>

				<Input
					className="min-w-[224px] md:max-w-md"
					label="Nome da região"
					errorMessage={errors.name?.message}
					{...register('name', { required: true })}
				/>
				<Input
					className="min-w-[224px] md:max-w-md"
					label="Descrição"
					{...register('description')}
				/>
				<FormButtons
					onCancel={() => {
						if (isTouch) {
							alert.onConfirmCancel(() => {
								closeForm();
								setPaths([]);
							});
						} else closeForm();
					}}
					saveProps={{
						type: 'submit',
						disabled: isSubmitting || !isTouch,
					}}
				/>
			</Form>

			<div className="mt-4 h-[calc(100vh-112px)] max-h-[1280px]">
				<GeoMap
					paths={paths}
					setPaths={setPaths}
					center={center}
					setCenter={setCenter}
					drawMode={!geofence}
				/>
			</div>
		</div>
	);
};

export const GeofenceSchema = z.object({
	id: z.number(),
	name: z
		.string()
		.min(1, {
			message: zodMessage.required,
		})
		.min(2, {
			message: zodMessage.min(2),
		})
		.max(100, {
			message: zodMessage.max(100),
		}),
	state: z
		.object(
			{
				value: z.string(),
				label: z.string(),
			},
			{
				invalid_type_error: zodMessage.required,
				required_error: zodMessage.required,
			}
		)
		.transform((item) => item.value),
	description: z.string().optional().nullable(),
	coordinates: z
		.array(
			z.object(
				{
					lat: z.number(),
					lng: z.number(),
				},
				{
					invalid_type_error: zodMessage.required,
					required_error: zodMessage.required,
				}
			),
			{
				invalid_type_error: zodMessage.required,
				required_error: zodMessage.required,
			}
		)
		.min(3, {
			message: 'Geofence precisa ter no mínimo 3 pontos',
		})
		.transform((value) => value.map((item) => [item.lng, item.lat])),
	centerOfBounds: z
		.object(
			{
				lat: z.number(),
				lng: z.number(),
			},
			{
				invalid_type_error: zodMessage.required,
				required_error: zodMessage.required,
			}
		)
		.transform((value) => [value.lng, value.lat]),
});

export const MutateGeofenceSchema = GeofenceSchema.extend({
	id: z.number().optional(),
}).transform((item) => ({
	...item,
	stateId: item.state,
}));

type MutateGeofenceOutputType = z.output<typeof MutateGeofenceSchema>;
