import dayjs from 'dayjs';

export const dateFormat = (date?: string, short?: boolean): string => {
	if (!date) return '';

	const actualDate = date.endsWith('Z') ? date : `${date}Z`;

	return short
		? dayjs(actualDate).format('DD/MM/YYYY')
		: dayjs(actualDate).format('DD/MM/YYYY HH:mm');
};

export const timeFormat = (time: number) => {
	// time is in seconds

	const hours = Math.floor(time / 3600);
	const minutes = Math.floor((time % 3600) / 60);

	if (hours === 0) return `${minutes}m`;
	if (minutes === 0) return `${hours}h`;
	return `${hours}h ${minutes}m`;
};
