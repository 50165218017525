import React, { useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  LayersControl,
  Polyline,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { decode } from "@mapbox/polyline";
import { Location as OrderLocation } from "../../services/angular/types/Order";

interface LeafletMapProps {
  dynamicMap?: boolean;
  center?: L.LatLngExpression | OrderLocation[] | null;
  onMapLoad?: (map: L.Map) => void;
  children?: React.ReactNode;
  zoom?: number;
  polylineString?: string;
  layers?: Layers[];
}

interface Layers {
  name: string;
  attribution: string;
  url: string;
}
interface Coordinate {
  lat: number;
  lng: number;
}

const mapStyles: React.CSSProperties = {
  height: "100%",
  width: "100%",
  margin: "auto",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  zIndex: 0,
};

const defaultLayers = [
  {
    name: "Google Maps Terrain",
    attribution:
      '&copy; <a href="https://www.google.com.br/maps/">Google Maps</a> contributors',
    url: "http://mt0.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
  }
];

export const LeafletMap: React.FC<LeafletMapProps> = ({
  dynamicMap = true,
  center = null,
  polylineString,
  zoom = 12,
  onMapLoad,
  children,
  layers = defaultLayers,
}) => {
  const mapRef = useRef<L.Map>(null);
  const [polylinePath, setPolylinePath] = React.useState<Coordinate[]>([]);
  
  const Centerer = () => {
    const centerMap = () => {
      if (center !== null) {
        if (Array.isArray(center) && center.length > 0) {
          const bounds = new L.LatLngBounds(
            (center as OrderLocation[])
              .filter((location): location is OrderLocation => location !== undefined)
              .map((location) => [location.lat, location.lng])
          );
          console.log(bounds);
          const paddedBounds = bounds.pad(0.1);
          mapRef.current?.fitBounds(paddedBounds);
        } else {
          mapRef.current?.setView(center as L.LatLngExpression, zoom);
        }
      } else {
        console.log("Getting user location");

        navigator.geolocation.getCurrentPosition(
          (position) => {
            mapRef.current?.setView({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }, zoom);
          },
          () => {
            const brazilBounds: L.LatLngBoundsExpression = [
              [-33.7683777809, -73.9872354804],
              [5.24448639569, -34.7299934555]
            ];
            mapRef.current?.fitBounds(brazilBounds);
          }
        );
      }
      return null
    }

    useEffect(() => {
      centerMap();
    }, [center, zoom]);
    return null;
  }

  useEffect(() => {
    if (polylineString) {
      const decodedPolyline = decode(polylineString).map((point: any[]) => ({
        lat: point[0],
        lng: point[1],
      }));
      setPolylinePath(decodedPolyline);
    }
  }, [polylineString]);
  

  return (
    <>
      <MapContainer ref={mapRef} style={mapStyles} 
        dragging={dynamicMap} // Desativa o recurso de arrastar
        doubleClickZoom={dynamicMap} // Desativa o zoom duplo clique
        scrollWheelZoom={dynamicMap} // Desativa o zoom com a roda do mouse
        touchZoom={dynamicMap} // Desativa o zoom em dispositivos de toque
        keyboard={dynamicMap} // Desativa a interação com o teclado
        zoomControl={dynamicMap} // Desativa o controle de zoom padrão
        >
        <Centerer />
        <LayersControl position="topright">
          {layers.map((layer, index) => {
            return (
              <LayersControl.BaseLayer
                key={index}
                checked={index === 0}
                name={layer.name}
              >
                <TileLayer attribution={layer.attribution} url={layer.url} />
              </LayersControl.BaseLayer>
            );
          })}
        </LayersControl>
        {children}
        {polylinePath.length > 0 && (
          <Polyline
            color={"blue"}
            opacity={0.7}
            weight={10}
            positions={polylinePath}
            stroke={true}
          ></Polyline>
        )}
      </MapContainer>
  </>
  );
};
