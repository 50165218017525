import { z } from 'zod';

export const FilterCategoriesSchema = z.object({
	geofenceId: z
		.array(
			z.object({
				value: z.number(),
				label: z.string().optional(),
			})
		)
		.nullable()
		.optional()
		.transform((value) => value?.map((item) => item.value).join(',')),
});

export type FilterCategoriesSchemaType = z.infer<typeof FilterCategoriesSchema>;
