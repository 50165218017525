import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Params } from '../../../../../services/angular/types/Params';
import { OrdersFilterSchemaType } from './OrdersFilterSchema';

type OrdersFilterState = {
	params: Params;
	setParams: (params: Params) => void;
	setOrdersFilter: (state: OrdersFilterSchemaType) => void;
	clearOrdersFilter: () => void;
} & OrdersFilterSchemaType;

export const useOrdersFilterState = create(
	persist<OrdersFilterState>(
		(set) => ({
			params: {
				page: 1,
				take: 50,
				sortBy: {
					id: 'requestDate',
					order: 'desc',
				},
			},
			categoryId: undefined,
			endDate: undefined,
			geofenceId: undefined,
			id: undefined,
			initialDate: undefined,
			operation: undefined,
			orderDate: undefined,
			orderTypeId: undefined,
			query: undefined,
			statusId: undefined,
			setParams: (params) => set({ params }),
			setOrdersFilter: (state) => set(state),
			clearOrdersFilter: () =>
				set({
					categoryId: undefined,
					endDate: undefined,
					geofenceId: undefined,
					id: undefined,
					initialDate: undefined,
					operation: undefined,
					orderDate: undefined,
					orderTypeId: undefined,
					query: undefined,
					statusId: undefined,
				}),
		}),
		{
			name: 'OrdersFilterState',
		}
	)
);
