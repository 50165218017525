import dayjs from 'dayjs';
import { z } from 'zod';
import { appState } from '../../../../store/appState';

export const editOrderSchema = z
	.object({
		id: z.number(),
		statusId: z.number(),
		geofenceId: z
			.object(
				{
					label: z.string(),
					value: z.number(),
				},
				{
					required_error: 'Obrigatório',
					invalid_type_error: 'Obrigatório',
				}
			)
			.transform((value) => value?.value),
		driverBonus: z.coerce.number(),
		total: z.coerce.number({
			required_error: 'Obrigatório',
		}),
		driverComission: z.coerce.number({
			required_error: 'Obrigatório',
		}),
		individualDriverBonus: z.coerce.number({
			required_error: 'Obrigatório',
		}) /* .refine((value) => value >= 0, {
		message: 'Valor deve ser positivo',
	}) */,
		scheduleDate: z
			.any()
			.transform((value) => value && dayjs(value).toISOString()),
		categoryId: z.number().nullable(),
		categoryType: z.number().nullable().default(0),
		requesterCompanyId: z
			.object({
				id: z.number().optional(),
				tradingName: z.string().optional(),
				companyName: z.string().optional(),
				avatar: z.string().optional().nullable(),
				owner: z.any().optional(),
				users: z.array(z.any()),
			})
			.transform(({ id }) => id),
		requesterId: z
			.object({
				id: z.number().optional(),
				name: z.string().optional(),
				avatar: z.string().optional().nullable(),
			})
			.nullable()
			.transform((value) => (value ? value?.id : value)),
		driverCompanyId: z
			.object({
				id: z.number().optional(),
				tradingName: z.string().optional(),
				companyName: z.string().optional(),
				avatar: z.string().optional().nullable(),
				owner: z.any().optional(),
				users: z.array(z.any()).optional(),
				drivers: z.array(z.any()).optional(),
				vehicles: z
					.array(
						z.object({
							id: z.number().optional(),
							categoryId: z.number().optional().nullable(),
							make: z.string().optional().nullable(),
							model: z.string().optional().nullable(),
							plate: z.string().optional().nullable(),
						})
					)
					.optional(),
			})
			.optional()
			.nullable()
			.transform((value) => (value ? value?.id : value)),
		driverId: z
			.object({
				id: z.number().optional(),
				name: z.string().optional(),
				avatar: z.string().optional().nullable(),
			})
			.optional()
			.nullable()
			.transform((value) => (value ? value?.id : value)),

		vehicleId: z
			.object({
				id: z.number().optional(),
				make: z.string().optional().nullable(),
				model: z.string().optional().nullable(),
				plate: z.string().optional().nullable(),
				categoryId: z.number().optional().nullable(),
			})
			.optional()
			.nullable()
			.transform((value) => (value ? value?.id : value)),
	})
	.superRefine(
		(
			{
				statusId,
				driverCompanyId,
				driverId,
				vehicleId,
				requesterCompanyId,
				requesterId,
			},
			ctx
		) => {
			if (statusId === appState.orderStatus['Em andamento'].id) {
				if (!driverId) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Selecione um motorista',
						path: ['driverId'],
					});
				}

				if (!vehicleId) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Selecione um veículo',
						path: ['vehicleId'],
					});
				}

				if (!driverCompanyId) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Selecione uma empresa',
						path: ['driverCompanyId'],
					});
				}

				if (!requesterCompanyId) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Selecione uma empresa',
						path: ['requesterCompanyId'],
					});
				}

				if (!requesterId) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: 'Selecione um usuário',
						path: ['requesterId'],
					});
				}
			}
		}
	);

export type EditOrderSchemaType = z.infer<typeof editOrderSchema>;
