import React from 'react';
import { SvgProps } from './SvgPropsInterface';

const BarcodeIcon: React.FC<SvgProps> = ({ color = '#FCF366', size }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 40 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.22632 0.752597C0.755759 0.957523 0.262036 1.55104 0.100827 2.10547C0.012323 2.40971 -0.0161885 5.85009 0.00852145 13.2462L0.0442799 23.9467L0.371092 24.4405C0.797814 25.0851 1.44847 25.4142 2.29668 25.4142H2.95482V12.9998V0.585449L2.27174 0.590083C1.8961 0.592696 1.42566 0.665756 1.22632 0.752597ZM3.66761 12.9998V25.4142H4.3804H5.09318V12.9998V0.585449H4.3804H3.66761V12.9998ZM7.11274 12.9998V25.4142H8.59772H10.0827V12.9998V0.585449H8.59772H7.11274V12.9998ZM11.3895 12.9998V25.4142H11.8063H12.2232L12.22 12.9998L12.2168 0.585449H11.8031H11.3895V12.9998ZM13.0526 12.9998V25.4142H13.8248H14.597V12.9998V0.585449H13.8248H13.0526V12.9998ZM16.6166 12.9998V25.4142H18.1015H19.5865V12.9998V0.585449H18.1015H16.6166V12.9998ZM20.6557 12.9998V25.4142H21.3685H22.0813V12.9998V0.585449H21.3685H20.6557V12.9998ZM23.0316 12.9998V25.4142H23.8038H24.576V12.9998V0.585449H23.8038H23.0316V12.9998ZM26.1204 12.9998V25.4142H26.4768H26.8332V12.9998V0.585449H26.4768H26.1204V12.9998ZM29.4467 12.9998V25.4142H29.8031H30.1595V12.9998V0.585449H29.8031H29.4467V12.9998ZM30.7535 12.9998V25.4142H32.2385H33.7234V12.9998V0.585449H32.2385H30.7535V12.9998ZM35.149 12.9998V25.4142H35.8618H36.5746V12.9998V0.585449H35.8618H35.149V12.9998ZM36.931 12.9998V25.4142H37.6062C38.5554 25.4142 39.2199 25.07 39.6353 24.3633L39.9603 23.8104L39.9942 13.6092C40.0141 7.61079 39.9816 3.07248 39.9153 2.59361C39.7233 1.2089 39.0197 0.585449 37.6485 0.585449H36.931V12.9998Z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default BarcodeIcon;
