import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Params } from '../../../../../../services/angular/types/Params';
import { PopFilterSchemaType } from './PopFilterSchema';

type PopFilterState = {
	params: Params;
	setParams: (params: Params) => void;
	setPopFilter: (state: PopFilterSchemaType) => void;
	clearPopFilter: () => void;
} & PopFilterSchemaType;

export const usePopFilterState = create(
	persist<PopFilterState>(
		(set) => ({
			params: {
				page: 1,
				take: 25,
			},
			setParams: (params) => set({ params }),
			geofenceId: undefined,
			setPopFilter: (state) => set(state),
			clearPopFilter: () =>
				set({
					geofenceId: undefined,
				}),
		}),
		{
			name: 'PopFilterState',
		}
	)
);
