import React, { useState } from 'react';
import WhiteContainer from '../../../../../components/WhiteContainer/WhiteContainer';
import InformativePopupForm from './components/InformativePopupForm';
import { FiltersTab } from '../../../../../components/FiltersTab';
import { PageHeader } from '../../../../../components/PageHeader';
import { useZodForm } from '../../../../../components/FormElements/Form';
import { PopFilterSchema } from './schemas/PopFilterSchema';
import { Select } from '../../../../../components/FormElements/Select';
import { BsCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';

import { RegisterButton } from '../../../../../components/Button/RegisterButton';
import { useAppState } from '../../../../../store/appState';
import { api } from '../../../../../services/angular/axios';
import { Table, TableActions } from '../../../../../components/Table/Table';
import { useMutation, useQuery } from '@tanstack/react-query';
import { oldIcons, VehicleIcon } from '../../../../../components/Icon/vehicles';
import { toast } from 'react-toastify';
import { useAlert } from '../../../../../contexts/AlertContext';
import { PopInfo } from '../../../../../services/angular/types/Order';
import { TbCheck, TbX } from 'react-icons/tb';
import { usePopFilterState } from './schemas/informativePopFilterState';
import { ToastError } from '../../../../../helpers/errors/ToastError';

const InformativePopUp: React.FC = () => {
	const alert = useAlert();
	const { geofence } = useAppState();
	const [openForm, setOpenForm] = useState(false);
	const [popInfoToEdit, setPopInfoToEdit] = useState<PopInfo>();
	const { params, setParams, setPopFilter, clearPopFilter, ...query } =
		usePopFilterState();

	const { data, isLoading, isFetching, refetch } = useQuery(
		['pop-info', params, query],
		async () =>
			await api.get(
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				`/pop-info?include=geofence,categories`,

				{
					params: {
						page: params.page,
						pageSize: params.take,
						sort: params.sortBy
							? `${params.sortBy?.id}:${params.sortBy?.order}`
							: undefined,
						...query,
					},
				}
			)
	);

	const handleOpenForm = (): void => {
		setOpenForm(true);
	};

	const handleEdit = (data: PopInfo): void => {
		setPopInfoToEdit(data);
		setOpenForm(true);
	};

	const form = useZodForm({
		schema: PopFilterSchema,
		defaultValues: {
			geofenceId: query.geofenceId?.split(',').map((id) => {
				return {
					value: Number(id),
					label: geofence?.find((state) => state.id === Number(id))?.name,
				};
			}),
		},
	});

	const { mutate: activatePop } = useMutation(
		async (data: PopInfo) => {
			await api.put(`/pop-info/${data.id}/set-active`, data);
		},
		{
			onSuccess: () => {
				void refetch();
				toast.success('Pop Informativo ativado!');
			},
			onError: (error) => {
				ToastError(error);
			},
		}
	);

	const { mutate: deactivatePop } = useMutation(
		async (data: PopInfo) => {
			await api.put(`/pop-info/${data.id}`, {
				...data,
				geofenceIds: data?.geofenceId ? [data?.geofenceId] : null,
				active: false,
			});
		},
		{
			onSuccess: () => {
				void refetch();
				toast.success('Pop Informativo desativado!');
			},
			onError: (error) => {
				ToastError(error);
			},
		}
	);

	return (
		<div className="w-full">
			<PageHeader
				title="Pop Informativo"
				description="Relação de Mensagens transacionais para envio automático do sistema."
			>
				{!openForm && (
					<RegisterButton onClick={handleOpenForm} className="w-auto" />
				)}
			</PageHeader>

			{openForm ? (
				<InformativePopupForm
					setOpenForm={setOpenForm}
					popInfoToEdit={popInfoToEdit}
					setPopInfoToEdit={setPopInfoToEdit}
					refetch={() => {
						void refetch();
					}}
				/>
			) : (
				<>
					<FiltersTab
						form={form}
						clearFilters={() => {
							form.reset({
								geofenceId: null,
							});
							clearPopFilter();
						}}
						onSubmit={(data) => {
							setPopFilter(data);
							setParams({
								...params,
								page: 1,
							});
						}}
					>
						<Select
							label="UF"
							variant="light"
							className="w-auto min-w-[192px]"
							controller={{
								control: form.control,
								name: 'geofenceId',
							}}
							isMulti
							options={geofence?.map((state) => ({
								label: state.name,
								value: state.id,
							}))}
						/>
					</FiltersTab>
					<WhiteContainer>
						<Table<PopInfo>
							params={params}
							onParamsChange={(params) => {
								setParams(params);
							}}
							isLoading={isLoading || isFetching}
							data={data?.data.data || []}
							total={data?.data.meta.pagination.total || 0}
							disabledSort={{
								id: true,
							}}
							columns={[
								{
									accessor: 'geofence',
									Header: 'Geofence',
									Cell: ({ value }) => {
										return <>{value?.name}</>;
									},
								},
								{
									accessor: 'title',
									Header: 'Título',
								},
								{
									accessor: 'typeId',
									Header: 'Mostrar para',
									Cell: ({ value }) => (
										<>{Number(value) === 1 ? 'Clientes' : 'Motoristas'}</>
									),
								},
								{
									accessor: 'categories',
									Header: 'Categoria',
									Cell: ({ row }) => {
										const value = row.original.categories;
										return (
											<div className="flex flex-wrap gap-2">
												{value.map((category, index) => (
													<VehicleIcon
														key={index}
														/* @ts-expect-error */
														name={oldIcons[category.icon]}
														size={36}
													/>
												))}
											</div>
										);
									},
								},
								{
									accessor: 'active',
									Header: 'Ativo',
									Cell: ({ value }) => (
										<>
											{value ? (
												<span className="flex text-green">
													<BsCheckCircleFill size={22} />
												</span>
											) : (
												<span className="flex text-terracota-500">
													<BsFillXCircleFill size={22} />
												</span>
											)}
										</>
									),
								},
								{
									accessor: 'id',
									Header: 'Ações',
									width: '0%',
									Cell: ({ row }) => (
										<TableActions
											row={row}
											actions={[
												row.original.active
													? {
															icon: <TbX size={20} />,
															label: 'Desativar',
															onAction: (data) => {
																alert.onCustom({
																	title: 'Desativar pop informativo',
																	message: `Deseja desativar o pop ${data.title}?`,
																	confirm: {
																		onClick: async () => {
																			deactivatePop(data);
																		},
																	},
																});
															},
													  }
													: {
															icon: <TbCheck size={20} />,
															label: 'Ativar',
															onAction: (data) => {
																alert.onCustom({
																	title: 'Ativar pop informativo',
																	message: `Deseja ativar o pop ${data.title}?`,
																	confirm: {
																		onClick: async () => {
																			activatePop(data);
																		},
																	},
																});
															},
													  },
												{
													icon: 'edit',
													label: 'Editar',
													onAction: (data: PopInfo) => handleEdit(data),
												},
												{
													icon: 'trash',
													label: 'Excluir',
													onAction: (data) => {
														alert.onCustom({
															title: 'Excluir pop informativo',
															message:
																'Deseja realmente excluir o pop informativo?',
															confirm: {
																label: 'Excluir',
																onClick: async () => {
																	try {
																		await api.delete(`/pop-info/${data.id}`);
																		void refetch();
																		toast.success('Pop informativo deletado');
																	} catch (error) {
																		toast.error(
																			'Erro ao deletar pop informativo'
																		);
																	}
																},
															},
														});
													},
												},
											]}
										/>
									),
								},
							]}
						/>
					</WhiteContainer>
				</>
			)}
		</div>
	);
};

export default InformativePopUp;
