import React, { ChangeEvent } from 'react';
import { Cropper, CropperRef } from 'react-advanced-cropper';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';
import { Avatar } from '../../../../components/Avatar';
import Button from '../../../../components/Button/Button';
import { Label } from '../../../../components/FormElements';
import { Icon } from '../../../../components/Icon/Icon';
import { Modal } from '../../../../components/Modal';
import { useAlert } from '../../../../contexts/AlertContext';
import useUserState from '../../../../services/angular/angularUserState';
import { api } from '../../../../services/angular/axios';
import './updateAvatar.css';
import { validateFileSize } from '../../../../helpers/validateFileSize';

interface UpdateAvatarProps {
	src?: string;
	className?: string;
}

interface Image {
	type?: string;
	src: string;
}

export const UpdateAvatar: React.FC<UpdateAvatarProps> = ({
	src,
	className,
}) => {
	const alert = useAlert();
	const user = useUserState((state) => state.user);
	const triggerRefetch = useUserState((state) => state.triggerRefetch);
	const cropperRef = React.useRef<CropperRef>(null);
	const [image, setImage] = React.useState<Image>();
	const [open, setOpen] = React.useState<boolean>(false);

	const queryClient = useQueryClient();

	const { data, isFetching } = useQuery(
		['user-avatar', user?.avatar],
		async () => {
			if (!user?.avatar) return;
			return await api.get('/assets', {
				params: {
					imageName: user.avatar,
				},
			});
		},
		{
			enabled: !!user?.avatar,
		}
	);

	const onLoadImage = (event: ChangeEvent<HTMLInputElement>): void => {
		const { files } = event.target;

		if (files?.[0]) {
			if (!files[0].type.match('image.*')) {
				toast.error('Arquivo inválido, selecione uma imagem');
				event.target.value = '';
				return;
			}
			const blob = URL.createObjectURL(files[0]);

			setImage({ src: blob, type: files[0].type });
			setOpen(true);
		}
		event.target.value = '';
	};

	const onCrop = () => {
		if (cropperRef.current) {
			cropperRef.current.getCanvas()?.toBlob(
				(blob) => {
					if (blob && !validateFileSize(blob)) {
						return;
					}

					const formData = new FormData();
					formData.append('image', blob as Blob);

					void handleUpdate(formData);
					setOpen(false);
				},
				'image/jpeg',
				0.95
			);
		}
	};

	const handleUpdate = async (formData: FormData): Promise<void> => {
		const response = await api.post('/assets', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});

		await api.put(`/users/${String(user?.id)}`, {
			id: user?.id,
			roleId: user?.roleId,
			active: user?.active,
			blocked: user?.blocked,
			name: user?.name,
			email: user?.email,
			phone: user?.phone,
			pix: user?.pix,
			cellphone: user?.cellphone,
			cpf: user?.cpf,
			avatar: response.data.imageName,
		});
		await queryClient.invalidateQueries(['user-avatar', user?.avatar]);

		toast.success('Imagem atualizada com sucesso');
		triggerRefetch();
	};

	const handleDelete = () => {
		alert.onCustom({
			title: 'Atenção',
			message:
				'Tem certeza que deja excluir a imagem? Essa ação não pode ser desfeita.',
			confirm: {
				onClick: async () => {
					await api.put(`/users/${String(user?.id)}`, {
						id: user?.id,
						roleId: user?.roleId,
						active: user?.active,
						blocked: user?.blocked,
						name: user?.name,
						email: user?.email,
						phone: user?.phone,
						pix: user?.pix,
						cellphone: user?.cellphone,
						cpf: user?.cpf,
						avatar: null,
					});
					toast.success('Imagem excluída com sucesso');
					triggerRefetch();
				},
			},
		});
	};

	return (
		<div
			className={twMerge('relative h-fit w-full sm:w-1/2 md:w-auto', className)}
		>
			<Label label="Avatar" variant="primary" />
			<Avatar
				avatarProps={{
					className: 'h-full w-full md:h-40 md:w-40 rounded-lg',
				}}
				src={data?.data.url}
				isLoading={isFetching}
			/>
			{data?.data.url && (
				<label
					className="absolute bottom-1 left-1 cursor-pointer items-center rounded-lg bg-neutral-0 p-1.5 text-white transition-all hover:text-yellow-500"
					onClick={handleDelete}
				>
					<Icon name="trash" size={20} />
				</label>
			)}
			<label className="absolute bottom-1 right-1 cursor-pointer items-center rounded-lg bg-neutral-0 p-1 text-white hover:text-yellow-500">
				<Icon name="edit" size={20} />
				<input
					onChange={onLoadImage}
					type="file"
					accept="image/png, image/gif, image/jpeg"
					className="hidden"
				/>
			</label>
			<Modal title="Cortar imagem" open={open} setOpen={setOpen}>
				<div className="flex max-h-[65vh] flex-col align-bottom">
					<Cropper
						ref={cropperRef}
						src={image?.src}
						stencilProps={{
							grid: true,
							// aspectRatio: 1,
						}}
					/>
					<Button
						className="mt-4 w-auto self-end"
						variant="blue-primary"
						onClick={onCrop}
					>
						Salvar
					</Button>
				</div>
			</Modal>
		</div>
	);
};
