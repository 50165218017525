import React from 'react';
import { Tooltip } from '.';
import { Icon } from '../Icon/Icon';

interface ErrorTooltipProps {
	message: string;
	offset?: number;
}

export const ErrorTooltip: React.FC<ErrorTooltipProps> = ({
	message,
	offset,
}) => {
	return (
		<Tooltip sideOffset={offset} message={message}>
			<Icon className="text-[#FF0000]" name="exclamation-circle" size={16} />
		</Tooltip>
	);
};
